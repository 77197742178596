import React, { useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import { Box } from '@material-ui/core';
const useStyles = makeStyles({
  card: {
    maxWidth: 275,
    // border: '1px solid #000000',
    margin: '5px',
    cursor: 'pointer'

  },
  activeCard: {
    maxWidth: 275,
    border: '2px solid #00808094',
    margin: '5px',
    cursor: 'pointer'

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

interface Props {
  name: string;
  onClick: () => void;
  isActive: boolean;
}
export default function VariableCard ({ name, onClick, isActive }: Props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Box onClick={onClick}>
    <ItemHover>
    <Card className={isActive ? classes.activeCard : classes.card}
    raised

    >
      <CardContent>
        {/* <Typography
          className={classes.title}
          // color='text.secondary'
          gutterBottom>
          Word of the Day
        </Typography> */}
        <Typography variant='h5' component='h2'>
          {name}
        </Typography>
        {/* <Typography className={classes.pos}
        // color='text.secondary'
        >
          adjective
        </Typography> */}
        {/* <Typography variant='body2' component='p'>


          .....
        </Typography> */}
      </CardContent>
      {/* <CardActions>
        <Button size='small'>Learn More</Button>
      </CardActions> */}
    </Card>
    </ItemHover>
    </Box>
  );
}