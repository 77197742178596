import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
// import Fab from '@mui/material/Fab';

import { Box, Button, List, Typography, Tooltip, IconButton, Fab, useMediaQuery } from '@material-ui/core';
import { ShowcaseTag, TagGroup, ACTIONS, OBJECT_TYPES } from 'types/models/home/HomeApp';

import { firestore } from '@crema/services/auth/firebase/firebase';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'redux/store';
import { canEdit } from '@crema/utility/Utils';
import IntlMessages from '@crema/utility/IntlMessages';
import { Fonts } from 'shared/constants/AppEnums';
import { grey, red } from '@material-ui/core/colors';
import TagGroups from './TagGroups';
import { logUserActivity, setTagsViewMode } from 'redux/actions/Home';
import { setPresentationMode } from 'redux/actions/Lesson';
import { onGetTagGroupsList, setCurrentTagGroupId } from 'redux/actions/Step';
import { onStopLesson } from 'redux/actions/Lesson';
import { SET_LESSON_DATA, SET_PRESENTATION_MODE, SET_TAG_GROUP_LIST } from 'types/actions/Home.action';
import { ShowcaseContext } from '../..';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import Layout from '../layout';
import { useHistory } from 'react-router-dom';
import { ViewMode, ViewModeSecondary } from '..';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import KeyboardTabRoundedIcon from '@mui/icons-material/KeyboardTabRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import TagSidebar from '../TagSidebar';
import ThreeDAnnotations from '../ThreeDSidebar/ThreeDAnnotations';
import WorkflowsSidebar from '../WorkflowsSidebar';
import ThreeDSidebar from '../ThreeDSidebar';
import { addTagsToEmptyShowcase } from 'modules/home/SpaceDetail/utils';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import _ from 'lodash';
import { getCurrentSweep } from '../TagSidebar/Tags/ShowcaseUtils';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import AddIcon from '@mui/icons-material/Add';
import Spacer from '@crema/core/Spacer';
import { showMessage } from 'redux/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
      color: 'black',
      // lineHeight: '48px',
    },
    fab: {
      '&	.MuiFab-colorInherit': {
        color: 'blue'
      }

    },
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    createTagsLink: {
      paddingLeft: theme.spacing(4),
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonGroup: {
      alignContent: 'center',
      justifyContent: 'center',
      width: '80%',
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    avatar: {
      cursor: 'pointer',
      backgroundColor: grey[100],
      border: `1px dashed ${grey[400]}`,
    },
    pointer: {
      cursor: 'pointer',
    },
    addIconRoot: {
      color: theme.palette.primary.light,
      fontWeight: Fonts.LIGHT,
    },
    btnRoot: {
      backgroundColor: red[500],
      color: theme.palette.primary.contrastText,
      paddingLeft: 24,
      paddingRight: 24,
      marginTop: 12,
      '&:hover, &:focus': {
        backgroundColor: red[800],
        color: theme.palette.primary.contrastText,
      },
    },
    backButton: {
      height: '90%',
      boxShadow: 'none',
      '& .MuiSvgIcon-root': {
        height: '0.75em',
      },
    },
    minimizedContainer: {
      // display: 'flex',
      // flexDirection: 'row',
      minWidth: '0px !important',
      maxWidth: '0px !important',
    },
    regularContainer: {
      // display: 'flex', flexDirection: 'row',
    }
  }),
);

interface Props {
  setMinimized: (isMinimized: boolean) => void;
  isMinimized: boolean;
}

export default function StepsBar({ setMinimized, isMinimized }: Props) {
  const { currentSpace, currentLesson, tagsViewModeSecondary, tagGroupsList, spaceTags, spaceModels, isWorkflowsSidebarOpen, currentTagGroupId } = useSelector<
    AppState,
    AppState['home']
  >(({ home }) => home);

  const presentationMode = useSelector<AppState, boolean>(({ home }) => home.presentationMode);

  const [showWorkflows, setShowWorkflows] = useState<boolean>(false);
  const { authUser } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const showcaseContext = useContext(ShowcaseContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  // const [isMin, setMin] = useState<boolean>(false);

  useEffect(() => {
    currentLesson && dispatch(onGetTagGroupsList(authUser, currentSpace, currentLesson));
    // return () => {
    //   dispatch({ type: SET_LESSON_DATA, payload: null }); //DUBIOUS
    // };
  }, [currentLesson]);

  // useEffect(() => {}, [])

  const onAddTagGroup = async (tgObj: TagGroup | null) => {
    let docRef = firestore
      .collection(
        `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups`,
      )
      .doc();
    let tg: TagGroup = {
      id: docRef.id,
      name: tgObj?.name || `Step ${tagGroupsList.length + 1}`,
      tagIds: (tgObj && tgObj.tagIds) ? [...tgObj.tagIds] : [],
      sortIndex: tagGroupsList.length,
      sweepAndPose: _.cloneDeep(tagGroupsList.find(tg => tg.sortIndex == tagGroupsList.length - 1)?.sweepAndPose || await getCurrentSweep()),
      ...(tgObj && tgObj.bannerText && { bannerText: tgObj.bannerText }),
      ...(tgObj && tgObj.variableNameForInput && { variableNameForInput: tgObj.variableNameForInput }),
      ...(tgObj && tgObj.jumpToTagId && { jumpToTagId: tgObj.jumpToTagId }),

    };
    await docRef.set(tg);
    let tgs = [...tagGroupsList, tg].sort(
      (a: TagGroup, b: TagGroup) => a.sortIndex - b.sortIndex,
    );
    dispatch({ type: SET_TAG_GROUP_LIST, payload: tgs });
    dispatch(setCurrentTagGroupId(tg.id));
    dispatch(logUserActivity(ACTIONS.ADD, OBJECT_TYPES.TAGGROUP, tg.id));
    addTagsToEmptyShowcase([]);
  };
  const history = useHistory();

  const handleShowAllSpaceTags = () => {
    // showcaseContext.addTagsToEmptyShowcase(Array.from(spaceTags.keys()));

    addTagsToEmptyShowcase([], visibleSpaceTags);
    spaceModels.size > 0 && Simulation.instance.filterVisibleNodes(Array.from(spaceModels.keys()));
    // dispatch(setCurrentTagGroupId(''));
  }
  let visibleSpaceTags: ShowcaseTag[] = Array.from(spaceTags.values());
  // console.log(spaceTags,spaceTags.values(),visibleSpaceTags,"spaceTags")

  console.log(`[st] rendering Stepsbar `);

  if (isWorkflowsSidebarOpen) {
    let tg = tagGroupsList.find(tg => tg.id == currentTagGroupId);
    return (currentSpace && currentLesson && tg) ?
      <WorkflowsSidebar
        // currentSpace={currentSpace} currentLesson={currentLesson}
        tagGroup={tg}
      />
      : null

  } else {
    return (
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'row' }}
        height={'100%'}
        className={presentationMode ? classes.minimizedContainer : classes.regularContainer}>
        <Box style={{ width: '100%', height: '100%' }} maxHeight={matches ? '250px' : '100%'}>
          <Layout>
            {/* {isMinimized ?
          <div onClick={() => setMinimized(false)}
            style={{ marginLeft: '-5px', paddingLeft: '1px', cursor: 'pointer ' }}
          ><KeyboardArrowLeftRoundedIcon /></div> : null} */}
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '10px',
                paddingLeft: '1px',
                paddingTop: '8px',
                paddingBottom: '8px',
                // marginTop: '10px'
              }}>
              <Button
                variant='text'
                className={classes.backButton}
                color='primary'
                size='small'
                type='button'
                onClick={() => {
                  // dispatch({ type: SET_LESSON_DATA, payload: null });
                  // dispatch(setCurrentTagGroupId(''));
                  // dispatch(setTagsViewModeSecondary(''));

                  dispatch(onStopLesson());
                  dispatch(setTagsViewMode(ViewMode.LAYERS));
                  handleShowAllSpaceTags();
                  // dispatch(setCurrentTagGroupId(''));
                  history.push(`/home/space/${currentSpace?.did}/project/${currentSpace?.currentSpaceProject.did}/layers`);
                }}>
                {/* <ArrowBackIosRoundedIcon /> */}
                <Tooltip title={`Back / Exit ${currentSpace?.primaryUseCase?.layerTermSingular || ''}`} ><ArrowBackRoundedIcon /></Tooltip>
              </Button>

              {/* <Button
                variant='text'
                className={classes.backButton}
                color='primary'
                size='small'
                type='button'
                onClick={() => {
                  setMinimized(true)
                }}>*/}
              {/* <ArrowBackIosRoundedIcon /> */}
              {/* <Tooltip title='Minimize'>{<KeyboardTabRoundedIcon />}</Tooltip> */}
              {/* </Button> */}
              {/* <Button
          variant='text'
          className={classes.backButton}
          color='primary'
          size='small'
          type='button'
          onClick={() => {
            //TODO @arpita
          }}>
          <CloseRoundedIcon />
        </Button> */}
            </Box>
            {/* <Typography
      variant='h6'
      align='center'
      className={classes.heading}
      style={{ color: 'black', marginBottom: 5 }}
      gutterBottom
    >
      {`Layer Tags`}
    </Typography> */}

            <Typography
              variant='h6'
              align='center'
              className={classes.heading}
              style={{ color: 'black', marginBottom: 20, padding: 5 }}
              gutterBottom
            >
              {currentLesson?.name}
            </Typography>

            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: "center" }}>
              {canEdit(currentSpace ? currentSpace.spaceMembership.role : '') && (

                <Box
                //py={4} px={6} mb={2} clone
                >
                  <Box display='flex' alignItems='center' justifyContent='center'
                    style={{ marginBottom: 15 }}
                    flexDirection='row'
                  >
                    {/* <Fab size="small" color="secondary" aria-label="add"

              onClick={() => onAddTagGroup()}
            >
              <AddIcon />
            </Fab> */}


                    {/* <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      style={{ height: '90%', margin: '10px' }}
                      type='button'
                      onClick={() => dispatch({ type: SET_PRESENTATION_MODE, payload: true })}>
                      <Tooltip title="Start Presentation">
                        <div>Present</div>
                        </Tooltip>
                    </Button> */}

                    <Tooltip title="Start Presentation">
                      <Fab size="small" aria-label="play" color="secondary"
                        onClick={() => {
                          history.replace({pathname: `/home/space/${currentSpace?.did}/project/${currentSpace?.currentSpaceProject.did}/layers/${currentLesson?.did}`, search:"?mode=present"});
                          dispatch(setPresentationMode(true));
                        }}>
                        <PlayArrowRoundedIcon
                          // style={{color: theme.palette.secondary.main}}

                          fontSize='large' />
                      </Fab>
                    </Tooltip>
                    <Spacer x={4} />

                    {/* <IconButton
                      onClick={() => {
                        // history.push(`/home/space/${currentSpace?.did}/project/${currentSpace?.currentSpaceProject.did}/layers/${lesson.did}`);
                        dispatch({ type: SET_PRESENTATION_MODE, payload: true })
                      }}
                    >
                      <Tooltip title="Start Presentation">
                        <PlayCircleRoundedIcon color="success" />
                      </Tooltip>
                    </IconButton> */}

                    {/* <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      style={{ height: '90%', margin: '10px' }}
                      type='button'
                      onClick={() => onAddTagGroup(null)}>
                      Add Step
                    </Button> */}

                    <Tooltip title="Add Step">
                      <Fab size="small"
                        // color="secondary"
                        // className={classes.fab}
                        // style={{color: "green"}}
                        // color="inherit"
                        aria-label="add"
                        onClick={() => {
                          onAddTagGroup(null);
                          dispatch(showMessage("Step added as the last step. Scroll down to view..."));
                        }}
                      >

                        <AddIcon style={{ color: theme.palette.secondary.main }}
                        //color="secondary"
                        // color="success"
                        />
                      </Fab>
                    </Tooltip>

                    {/* <Button
                variant='contained'
                color='secondary'
                size='small'
                style={{ height: '90%', marginBottom: '10px' }}
                type='button'
                onClick={() => showBannerInput(!bannerInput)}>
                Add Banner to Step
              </Button> */}
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                paddingLeft: '15px',
              }}>
              <Button
                variant='text'
                color='primary'
                size='small'
                style={{ height: '90%' }}
                type='button'
                disableElevation={true}
                onClick={() => {
                  // showcaseContext.addTagsToEmptyShowcase(Array.from(spaceTags.keys()));
                  // spaceModels.size > 0 && Simulation.instance.filterVisibleNodes(Array.from(spaceModels.keys()));
                  handleShowAllSpaceTags();
                }}>
                See All Tags & 3D
              </Button>
            </Box>
            <List style={{ padding: 3 }}>
              <TagGroups onDuplicateTagGroups={(tg: TagGroup) => {
                onAddTagGroup(tg);
              }} />
            </List>

            {tagGroupsList.length >= 2 && <div style={{ display: "block", textAlign: 'center', }}>


              <Tooltip title="Add Step">
                <Fab size="small"
                  // color="secondary"
                  // className={classes.fab}
                  // style={{color: "green"}}
                  // color="inherit"
                  aria-label="add"
                  onClick={() => {
                    onAddTagGroup(null);
                    dispatch(showMessage("Step added as the last step"));
                  }}
                >
                  <AddIcon style={{ color: theme.palette.secondary.main }}
                  //color="secondary"
                  // color="success"
                  />
                </Fab>
              </Tooltip>
            </div>}

          </Layout>
        </Box>
        {/* <Box height={'100%'} maxHeight={matches ? '200px' : '100%'}>
          {tagsViewModeSecondary == ViewModeSecondary.LAYERS_TAGS && <TagSidebar />}
        </Box> */}
        <Box height={'100%'} maxHeight={matches ? '200px' : '100%'}>
          {tagsViewModeSecondary == ViewModeSecondary.LAYERS_PROP && <ThreeDSidebar />}
        </Box>
      </Box>
    );
  }
}
