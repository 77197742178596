import Simulation from "../core/Simulation";

export enum GizmoModes {
    TRANSLATE_GIZMO_MODE = "translate",
    ROTATE_GIZMO_MODE = "rotate",
    SCALE_GIZMO_MODE = "scale",
}

export default class GizmoInteroparabilitySubSystem {    
    private static _instance:GizmoInteroparabilitySubSystem|null = null;

    public static get instance():GizmoInteroparabilitySubSystem {
        if (!GizmoInteroparabilitySubSystem._instance) {
            GizmoInteroparabilitySubSystem._instance = new GizmoInteroparabilitySubSystem();
        }
        return GizmoInteroparabilitySubSystem._instance;
    }

    private constructor() {

    }

    public async ChangeGizmoMode(gizmoMode: GizmoModes) {
        if(Simulation.instance.propertyPanelShowOverride[0]) { 
            await Simulation.instance.scene.setGizmoToNode(Simulation.instance.lastSelectedNode);
            await Simulation.instance.scene.showTransformGizmo();
            await Simulation.instance.scene.changeGizmoTransformMode(gizmoMode);
        }
    }
}