import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, useContext, useEffect, useState } from 'react';

interface Props {
    style: any,
    save: (value: string) => void,
    multiline: boolean,
    initValue: string,
    placeholder: string
}

const EditableTextField: React.FC<Props> = ({ save, style, multiline, initValue, placeholder }) => {

    const [value, setValue] = useState<string>('');
    useEffect(() => setValue(initValue), [initValue])

    return (<TextField
        id='outlined-dense-multiline'
        // label='Banner Text'
        // className={clsx(classes.textField, classes.dense)}
        // margin='dense'
        variant='standard'
        multiline={multiline}
        rowsMax='4'
        // defaultValue={tg.bannerText}
        // value={currentTagGroupId === tg.id ? bannerTextBeingEdited : tg.bannerText}
        value={value}
        // onFocus={() => focusOnTagGroup(tg)}

        // onMouseOut={() => console.log(`[st] on m out`)}

        InputProps={{
            style: style
        }}
        placeholder={placeholder}
        onChange={(event: any) => {
            // setEditTagGroup(tg);
            // alert(event.target.value)
            setValue(event.target.value);
        }}
        onMouseOut={() => {


            save(value);
            // handleEditTagGroupsInLesson(editTagGroup.name, bannerTextBeingEdited, editTagGroup?.variableNameForInput || '')
            //   editTagGroup && handleEditTagGroupsInLesson(editTagGroup.name, bannerTextBeingEdited, editTagGroup?.variableNameForInput || '')
            //   setEditTagGroup(null);
        }}
    />)
}

export default EditableTextField;