import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Scrollbar } from '@crema';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { onSetVariable } from 'redux/actions/Home';
import { ILesson } from 'types/models/home/HomeApp';
import VariablesButtonBar from './VariablesButtonBar';
import { OverflowMenu } from 'modules/home/components/OverflowMenu';
import Spacer from '@crema/core/Spacer';

const useStyles = makeStyles((theme) => ({

    container: {
        position: 'absolute',
        bottom: "12%",
        right: "5%",
        paddingLeft: "20px",
        paddingRight: "20px",
        maxHeight: "70%",
        overflow: "scroll",
        borderRadius: "16px",
        backgroundColor: 'transparent',
        color: "white"
    },

    scrollbarRoot: {
        position: 'relative',
        perspective: '200px',
        perspectiveOrigin: 'center',
    },

    variablesBox:{
        paddingRight: '50px',
        position: 'relative',
        transform: 'rotateY(-5deg)',
        boxShadow: 'rgb(0 0 0 / 40%) 0px 0px 9px 0px',
        borderRadius: '16px'
    }

}));

// const VariableControl: React.FC<{ name: any, handleChange: any, values: any[] }> = (name, handleChange, values) => {
//     return <Box display="flex" flexDirection="column" alignItems='center' style={{ paddingBottom: 10 }}>
//         <Typography variant="subtitle2"> {name} </Typography>

//         <ToggleButtons size={"small"} children={values}
//             handleChange={handleChange} />
//     </Box>
// }

interface Props {
    showVariablesPanel: boolean;
    setShowVariablesPanel: (show: boolean) => void;
}


const VariablesPanel: React.FC<Props> = ({ showVariablesPanel, setShowVariablesPanel }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const variableValues = useSelector<AppState, any[]>(({ home }) => {
        return home.variableValues;
    });
    const currentLesson = useSelector<AppState, ILesson | null>(({ home }) => home.currentLesson);

    function isComponentHidden() {

        //if this lesson was hidden in this session, then hide
        if ((!showVariablesPanel && (localStorage.getItem('hideVariablesPanel') || '').includes(currentLesson?.id || '')) || !(variableValues || []).filter(v => v.allowControl)) {
            return true;
        }

        if (!!currentLesson) {
            return false;
            //currentLesson.hideVariablesPanel; //TODO complete this implementation
        }
        else {
            return true;
        }
    }


    return isComponentHidden() ? null : (console.log(`[st] rendering VariablesPanel`),
        <Box className={classes.container}>

            <OverflowMenu buttonStyle={{
                color: "white",
                // height: '80%',
                position: 'absolute',
                right: 0,
                top: 0,
                padding: 0,
                margin: 0
            }} menuItems={[
                {
                    title: "Hide", onClick: () => {
                        setShowVariablesPanel(false);
                        localStorage.setItem('hideVariablesPanel', (localStorage.getItem('hideVariablesPanel') || '').concat(currentLesson?.id || ''));
                    }
                },
                // {
                //     title: `${currentLesson?.hideVariablesPanel ? 'Always show' : 'Always hide'} for this Experience`, onClick: () => {
                //         let spaceId = store.getState().home.currentSpace?.id;
                //         if (spaceId) {
                //             setShowVariablesPanel(!!currentLesson?.hideVariablesPanel);
                //             localStorage.setItem('hideVariablesPanel', (localStorage.getItem('hideVariablesPanel') || '').replace((currentLesson?.id || ''), ''));
                //             firestore.collection(`Spaces/${spaceId}/lessons`)
                //                 .doc(currentLesson?.id)
                //                 .update("hideVariablesPanel", !currentLesson?.hideVariablesPanel)
                //                 .then(() => { })
                //                 .catch(console.error);
                //             dispatch(onGetLessonsList(spaceId));
                //         }
                //     }
                // }
            ]} />
            {/* <Tooltip title="Hide">
                <div
                    // variant='text'
                    // color='secondary'
                    // size='small'

                    style={{
                        // height: '80%',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        padding: 0,
                        margin: 0

                        // alignSelf: 'right',
                        // textAlign: 'right',
                        // color: color,
                        // margin: '1px 1px',
                        // justifyContent: 'flex-end'
                    }}
                    // type='button'
                    // disableElevation={true}
                    onClick={() => setShowVariablesPanel(false)}>
                    <KeyboardArrowDownRoundedIcon />
                </div>
            </Tooltip> */}
            {/* <Scrollbar
            className={classes.scrollbarRoot}
            > */}
                <div className={classes.variablesBox}>
                {(variableValues || []).filter(v => v.allowControl).map((variable, index) =>
                    <Box display="flex" flexDirection="column" alignItems='center' justifyContent="center" flexWrap="nowrap"
                        key={index}
                    // style={{ paddingBottom: 10 }}
                    >
                        <Typography variant="subtitle2" style={{ width: "100%", textAlign: "center", textShadow: "0 0 5px rgb(0 0 0)" }}> {variable.name} </Typography>
                        <Spacer y={1}/>
                        <VariablesButtonBar size={"small"} children={variable.values.split(',').map((v: any) => v.trim())} initialValue={variable.value}
                            handleChange={(selectedValue: any) => dispatch(onSetVariable({ name: variable.name, value: selectedValue }))} />
                    </Box>
                )}
                </div>
            {/* </Scrollbar> */}
        </Box>);


    // <MyDraggable
    // initialPos= {{"x": 100, "y": 200}}
    // className='my-draggable'
    // style={{
    //     border: '2px solid #aa5',
    //     padding: '10px'
    // }}
    // />
    //         <DragDropContext onDragEnd={() => { }}>
    //             <Box className={classes.bar}>
    //                 <Box
    //                     // className={classes.root}
    //                     // border={`solid 4px ${grey[300]}`}
    //                     // p={2}
    //                     // mb={3}
    //                     >
    // draggable
    //                 </Box>
    //             </Box>
    //         </DragDropContext>


};

export default VariablesPanel;
