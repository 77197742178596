import React, {
  useEffect,
  createContext,
  useMemo,
} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { firestore } from '@crema/services/auth/firebase/firebase';
// import { SceneLoader } from 'mp/SceneLoader';
import {
  SpaceData,
  ShowcaseTag,
  IQuizDetails
} from '../../../../types/models/home/HomeApp';
// import {planeRendererType} from '../../../../mp/PlaneRenderer';
import Sidebar from './Sidebar';

import ShowcaseOverlay from './ShowcaseOverlay';

import { useDispatch, useSelector } from 'react-redux';

import { AppState } from 'redux/store';

import { Box, Dialog } from '@material-ui/core';
import { onGetPeopleList, showMessage } from 'redux/actions';
import {
  ProjectObj,
  TaskObj,
  LabelObj,
  CollaboratorObj,
} from '../../../../types/models/apps/ProjectBoard';
// import { Keys } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { Keys } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
// import { labelList } from 'shared/constants/AppConst';
import { GET_PEOPLE_LIST_DATA } from 'types/actions/Home.action';
import { useLocation } from 'react-router';
import NotificationService from '@crema/services/NotificationService';
import { baseUrl } from 'Config';
import MpSdk from 'mp/MpSdk';
import { getMatterportTagsFromDB } from '../utils';
import Dictaphone from 'modules/home/components/Dictaphone';

export interface SpaceViewProps {
  space: SpaceData;
  project: ProjectObj;
}

export const ShowcaseContext = createContext({
  // handleTagClick: async (showcaseTagId: string, options?: HandleTagClickOptions) => { },
  // handleAddTag: async (options?: any) => { },
  // handleEditTag: (tagId: string) => { },
  // handleDeleteTag: async (tagId: string) => { },
  // addTagsToEmptyShowcase: async (
  //   tagIds?: string[],
  //   tagObjects?: ShowcaseTag[],
  // ) => { },
  // getMatterportTagsFromDB: async () => { },
  // mpSdk: () => { },
  // handleCancelTagForm: async () => { },
  importMatterportTags: () => { },
  // addingTag: false
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebarToggleRoot: {
      height: '100%',
      width: '6px', backgroundColor: 'white'
    },
    dictaphone: {
      position: 'relative',
      right: '2%',
      top: '2%',
      zIndex: 2
    },
    sidebarToggleInnerRoot: { position: 'absolute', height: '100%' },
    sidebarToggle: {
      width: '20px',
      height: '100px',
      backgroundColor: 'white',
      position: 'relative',
      top: '40%',
      marginRight: '30px',
      right: '30%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '3px 0px 0px 3px',
    },
    arrowForwardIcon: { width: '0.7em', height: '0.7em', marginLeft: '2px' },
    arrowBackIcon: { width: '0.7em', height: '0.7em', marginLeft: '2px' },
    containerSpace: {
      padding: '4px',
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(92vh)',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

  }),

);

const SpaceView: React.FC<SpaceViewProps> = ({ space, project }): any => {
  const classes = useStyles();
  // const classes = useStylings();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { authUser } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  const showDictaphone = useSelector<AppState, boolean>(({ common }) => common.showDictaphone);

  const removeDictaphone = (event: Event) => {
    event.target?.removeEventListener('mouseover', () => { });
    Array.from(document.activeElement?.parentElement?.children || [])?.find(c => c.id == "dictaphoneContainer")?.remove();
    // event.target?.removeChild('dictaphoneContainer'); //FIXME
    // .getElementById('dictaphoneContainer')?.remove()
  }

  function writeToActiveElement(text: string) {
    if (document.activeElement && document.activeElement.constructor.name == "HTMLInputElement") {
      if ((document.activeElement as HTMLInputElement).type == 'text') {
        (document.activeElement as HTMLInputElement).value = (document.activeElement as HTMLInputElement).value.concat(text);
      }
    }
  }

  // }

  const keyCodeHandler = async (event: KeyboardEvent) => {

    // if (event.metaKey && event.altKey && event.keyCode == Keys.V) {
    //   if (document.activeElement && document.activeElement.constructor.name == "HTMLInputElement") {
    //     if ((document.activeElement as HTMLInputElement).type == 'text') {
    //       setDictaphoneOpen(!isDictaphoneOpen);

    //       // setDictaphonePosition('');
    //       // setDictaphoneResult(writeToActiveElement);

    //       console.log(`[st] setDictaphoneResult ${typeof dictaphoneResult}`);
    //       console.log(`[st] setDictaphoneResult ${dictaphoneResult}`)

    //       if (!!document.activeElement) {
    //         document.activeElement.addEventListener('mouseover', removeDictaphone);

    //         const container = document.createElement("div");
    //         container.id = 'dictaphoneContainer';
    //         const node = document.createTextNode("");
    //         container.appendChild(node);
    //         const iconElement = document.createElement("svg");
    //         iconElement.innerHTML = micIcon;
    //         container.appendChild(iconElement);
    //         const dictaphoneContainer = document.createElement("div");
    //         // dictaphoneContainer.innerHTML = renderToString(<Dictaphone />);
    //         container.appendChild(dictaphoneContainer);
    //         document.activeElement.after(container);
    //         //@ts-ignore
    //         // document.activeElement?.insertBefore(document.activeElement, <KeyboardVoiceIcon/>);
    //       }


    //     }

    //   }


    // }
    switch (event.keyCode) {

      case Keys.ESCAPE:
        //this.state.activePopover.hide();
        Simulation.instance.cancelAddObject();
        //TODO also cancel adding tag
        break;

      case Keys.BACKSPACE:

        // dispatch(setOpenTagForm(true))
        // await initMpSdk();
        // mpSdk && mpSdk.Camera.lookAtScreenCoords(-11, 1.4);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyCodeHandler);
    dispatch(onGetPeopleList(space.id, authUser));
    return () => {
      document.removeEventListener('keydown', keyCodeHandler);
      dispatch({ type: GET_PEOPLE_LIST_DATA, payload: [] });
    };
  }, []);

  /*
    Handle Gizmo Transform mode change UI
  */
  // const handleTransformModeChange = async (
  //   event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  // ) => {
  //   setTranformMode(event.target.value as string);
  //   if (Simulation) {
  //     await Simulation.instance.scene.changeGizmoTransformMode(
  //       await getTransformMode(),
  //     );
  //   }
  // };

  //   /*
  //  Places tag in the showcase: During Add Tag, after user picks a position and clicks in the showcase
  //   */
  //   const placeTag = async function () {
  //     let tag = await getTagSidBeingAdded();
  //     if (tag) {
  //       let mps = MpSdk.Instance.mps; //mpSdk || getMpSdkGlobal(space.id);
  //       // console.log(`[st] rep 6}`);
  //       if (await getIsRepositioning()) {
  //         // console.log(`[st] rep 7}`);
  //         let tag = await getTagSidBeingAdded();
  //         let tagSid = tag[0];

  //         // !mpSdk && await initMpSdk();


  //         if (mps) {
  //           console.log(`[st] rep 8 ${tagSid}}`);
  //           let allTags = await mps.Mattertag.getData();
  //           let thisTag = allTags.find((t: any) => t.sid === tagSid);
  //           console.log(`[st] rep 9 }`);
  //           console.log(`[st] rep 9 ${JSON.stringify(thisTag)}`);

  //           mps &&
  //             mps.Mattertag.editPosition(tagSid, {
  //               anchorPosition: thisTag.anchorPosition,
  //               stemVector: thisTag.stemVector,
  //               floorIndex: thisTag.floorIndex,
  //             }).then(async () => {
  //               let allTags = await mps.Mattertag.getData();
  //               let thisTag = allTags.find((t: any) => t.sid === tagSid);

  //               let tagId = getTagIdForTagSid(tagSid);
  //               thisTag && await firestore.doc(`Spaces/${space.id}/tags/${tagId}`)
  //               .set({
  //                 data: JSON.stringify(thisTag),
  //                 lastUpdatedBy: authUser?.uid,
  //                 lastUpdatedOn: new Date()
  //               }, {merge: true})

  //               mps.Mattertag.navigateToTag(
  //                 tagSid,
  //                 mps.Mattertag.Transition.FLY,
  //               );
  //               dispatch(fetchSuccess());
  //               dispatch(showMessage("Tag updated!"));

  //             })
  //         }

  //       } else {
  //         dispatch(setOpenTagForm(true));
  //       }

  //       // await initMpSdk();
  //       mps &&
  //         mps.Mattertag.navigateToTag(tag, mps.Mattertag.Transition.INSTANT);
  //     }
  //     console.log(`[st] setState addingTag`);
  //     // setAddingTag(false);
  //     dispatch({type: SET_IS_ADDING_TAG, payload: false})
  //     // scene?.hideTransformGizmo();
  //     // setTagSidBeingAdded(null);
  //   };


  // const injectHtmlHandler = async (
  //   htmlString: any,
  //   tagSid: string,
  //   width: undefined | number,
  //   height: undefined | number,
  // ) => {
  //   // await initMpSdk();

  //   if (htmlString !== '') {
  //     mpSdk &&
  //       mpSdk.Mattertag.injectHTML(tagSid, htmlString, {
  //         size: {
  //           w: width,
  //           h: height,
  //         },
  //         windowPath: '',
  //       })
  //         .then(function (messenger: any) {
  //           messenger.on('buttonClick', function (tagSid: any) {
  //             dispatch(setTagsViewMode(ViewMode.TAGS));
  //             let selectedShowcaseId = getTagIdForTagSid(tagSid);

  //             selectedShowcaseId && dispatch(setEditShowcaseTagId(selectedShowcaseId))
  //           });
  //         })
  //         .catch((e: any) => {
  //           console.error(e);
  //           console.error('Error in injectHTML');
  //         });
  //   }
  // };

  // function mediaTypeFinder(matterportType: string) {
  //   let type = '';

  //   if (!matterportType) {
  //     type = `mattertag.media.none`;
  //   } else if (matterportType.includes('video')) {
  //     type = `mattertag.media.video`;
  //   } else if (matterportType.includes('photo')) {
  //     type = `mattertag.media.photo`;
  //   } else if (matterportType.includes('file')) {
  //     type = `mattertag.media.file`;
  //   } else if (matterportType.includes('rich')) {
  //     type = `mattertag.media.rich`;
  //   } else {
  //     type = `mattertag.media.none`;
  //   }
  //   // console.log(type,"mediaTypeFinder")
  //   return type;
  // }

  function mediaTypeFinder(matterportType: string) {
    let type = '';

    if (matterportType.includes('none')) {
      type = `mattertag.media.none`;
    } else if (matterportType.includes('video')) {
      type = `mattertag.media.video`;
    } else if (matterportType.includes('photo')) {
      type = `mattertag.media.photo`;
    } else if (matterportType.includes('file')) {
      type = `mattertag.media.file`;
    } else if (matterportType.includes('rich')) {
      type = `mattertag.media.rich`;
    } else {
      type = `mattertag.media.none`;
    }
    return type;
  }

  const sendEmailsToCollaborator = (prevTaskObj: TaskObj | null, taskObj: TaskObj) => {
    // console.log(prevTaskObj,taskObj,"sendEmails");
    if (!prevTaskObj || taskObj.label.length > prevTaskObj.label.length) {
      // added label in task
      let updatedLabel: LabelObj[] = taskObj.label.filter(l => !prevTaskObj?.label.some(sL => sL.name === l.name));
      Promise.all(taskObj.collaborators.map((c: CollaboratorObj) => {
        Promise.all(updatedLabel.map((l: LabelObj) => {
          dispatch(NotificationService.assignLabelInTask(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid || '', c?.id?.toString() || c.email,
            {
              fromFirstName: authUser?.user.firstName || "",
              toEmail: c.email || '',
              taskTitle: taskObj.title,
              taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`,
              labelName: l.name,
            }))
        }));
      }));

    } else if (prevTaskObj && taskObj.label.length < prevTaskObj.label.length) {
      // deleted label from task
      let updatedLabel: LabelObj[] = prevTaskObj.label.filter(l => !taskObj.label.some(sL => sL.name === l.name));
      Promise.all(taskObj.collaborators.map((c: CollaboratorObj) => {
        Promise.all(updatedLabel.map((l: LabelObj) => {
          dispatch(NotificationService.removeLabelFromTask(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid || '', c?.id?.toString() || c.email,
            {
              fromFirstName: authUser?.user.firstName || "",
              toEmail: c.email || '',
              taskTitle: taskObj.title,
              taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`,
              labelName: l.name,
            }))
        }));
      }));

    }

    if (!prevTaskObj || taskObj.collaborators.length > prevTaskObj.collaborators.length) {
      // added collaborators in task
      let updatedCollaborator: CollaboratorObj[] = taskObj.collaborators.filter(l => !prevTaskObj?.collaborators.some(sL => sL.name === l.name));

      Promise.all(updatedCollaborator.map((c: CollaboratorObj) => {
        dispatch(NotificationService.assignTaskToUser(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid || '', c?.id?.toString() || c.email,
          {
            fromFirstName: authUser?.user.firstName || "",
            toEmail: c.email || '',
            taskTitle: taskObj.title,
            taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`
          }))
      }));

    } else if (prevTaskObj && taskObj.collaborators.length < prevTaskObj.collaborators.length) {
      // deleted collaborators from task
      let updatedCollaborator: CollaboratorObj[] = prevTaskObj.collaborators.filter(l => !taskObj.collaborators.some(sL => sL.name === l.name));
      Promise.all(updatedCollaborator.map((c: CollaboratorObj) => {
        dispatch(NotificationService.removeUserFromTask(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid || '', c?.id?.toString() || c.email,
          {
            fromFirstName: authUser?.user.firstName || "",
            toEmail: c.email || '',
            taskTitle: taskObj.title,
            taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`
          }))
      }));

    }

    // Field updated in task
    let updatedField: string[] = [];
    if (taskObj.title !== prevTaskObj?.title) {
      updatedField.push('Title');
    }
    if (taskObj.desc !== prevTaskObj?.desc) {
      updatedField.push('Description');
    }
    if (taskObj.checkedList.length !== (prevTaskObj?.checkedList || []).length) {
      updatedField.push('Checked List');
    }

    if (updatedField.length) {
      // console.log(task.collaborators,"task.collaborators")
      Promise.all(taskObj.collaborators.map((c: CollaboratorObj) => {
        dispatch(NotificationService.fieldUpdatedInTask(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid || '', c?.id?.toString() || '',
          {
            fromFirstName: authUser?.user.firstName || "",
            toEmail: c.email || '',
            taskTitle: taskObj.title,
            taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`,
            updatedField: updatedField.join(', '),
          }));
      }));

    }

  }

  /*
  Save tag to the db, add to showcase and sidebars
   */
  // function getEmbedlyMediaUrl(mediaLinkInput: string) {
  //   const url: any = new URL('https://api.embed.ly/1/extract'),
  //     params: any = {
  //       url: mediaLinkInput,
  //       key: '75db94366375495297fcc5782b2eeb35',
  //     };
  //   Object.keys(params).forEach((key) =>
  //     url.searchParams.append(key, params[key]),
  //   );
  //   return url;
  // }

  // const uploadMediaFiles=async (attachments: AttachmentObj[],)=>{

  //     let tagDocRef = firestore.collection(`Spaces/${space.id}/tags`).doc();

  //     //logic to create urls for attachments
  //     let prefix = `Spaces/${space.did}/Tags`;
  //     var storageRef = storage.ref(prefix);

  //     let attachmentsUrls: any[] = [];

  //     let promises: any = [];
  //     promises = attachments.map((attachment, index) => {
  //       let file: any = attachment.file;

  //       let filename: any = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
  //       filename = `${tagDocRef.id}.${filename}`
  //       let imageRef = storageRef.child(filename);
  //       // let baseModelRef = storage.ref(`3d/${type}/${name}`).child(fileName);

  //       return imageRef
  //         .put(file)
  //         .then(() => {
  //           const imageURL = `https://storage.googleapis.com/virtual-tc.appspot.com/${prefix}/${filename}`; //imageRef.getDownloadURL();
  //           return imageURL;
  //         })
  //         .then(async (imageURL) => {
  //           attachmentsUrls.push(imageURL);
  //           //make model bucket/folder public
  //           try {
  //             let makeFilePublicResult = await Api.post('/admin/model-check', { "modelPath": `${prefix}/${filename}` });

  //             if (makeFilePublicResult.status === 200) {
  //               console.log(`[st] file made public: ${prefix}/${filename}`);
  //               // dispatch(showMessage("All Done!"));
  //               // dispatch({ type: GET_SPACES_LIST, payload: data.data });
  //             } else {
  //               console.error(`[st] error making file public: ${prefix}/${filename} : ${JSON.stringify(makeFilePublicResult)}`)
  //               dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
  //             }
  //           } catch (error) {
  //             console.error(`[st] error making file public: ${prefix}/${filename} : ${error}`)
  //             dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
  //           };

  //           return imageURL;
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     });

  //     return await Promise.all(promises);
  // }
  // /*
  // Save tag to the db, add to showcase and sidebars
  //  */
  const handleAddTagForm = async (
    tagLabelInput: string,
    tagDescInput: string,
    annotationType: string,
    quizDetails: IQuizDetails,
    taskTag: TaskObj,
    tagHtml: string,
    // <<<<<<< HEAD
    mediaLink: string,
    attachmentsUrls: string[],
    showTextToSpeech: boolean,
    labelIds: number[]
  ) => {
    // try {
    //   dispatch(fetchStart());
    //   // await initMpSdk();
    //   let tag = await getTagSidBeingAdded();
    //   let tagSid = tag[0];
    //   // await setMpSdk();

    //   let tagDocRef = firestore.collection(`Spaces/${space.id}/tags`).doc();
    //   console.log(
    //     `[st] adding tag id  ${getFirestoreLink(
    //       `Spaces/${space.id}/tags/${tagDocRef.id}`,
    //     )}`,
    //   );

    //   //delete tag just created
    //   let allTags = await mpSdk.Mattertag.getData();
    //   let tagAdded = allTags.find((t: any) => t.sid === tagSid);

    //   if (!tagAdded) {
    //     console.error(
    //       `couldnt find tagAdded. looking for ${tagSid} found in ${allTags.map(
    //         (x: any) => x.sid + ', ' + x.label,
    //       )}`,
    //     );
    //     throw new Error(`couldnt find tagAdded`);
    //   }

    //   await mpSdk.Mattertag.remove(tagAdded.sid);

    //   //add tag again (to avoid editBillboard)
    //   tagAdded.label = tagLabelInput;
    //   tagAdded.description = tagDescInput;

    //   if (annotationType === ANNOTATION_TYPE.INFO) {
    //     let media = {
    //       type: mediaTypeFinder(mpSdk.Mattertag.MediaType["NONE"]),
    //       src: '',
    //     };
    //     if((attachmentsUrls?.[0]||mediaLink)){
    //       let mediaSrc=attachmentsUrls.length?attachmentsUrls[0]:mediaLink;

    //       const embedlyExtract:any = await EmbedlyService.embedlyExtract(mediaSrc);
    //       const mediaType = (embedlyExtract?.media.type || '').toUpperCase();
    //       media = {
    //         type: mediaTypeFinder(mpSdk.Mattertag.MediaType[mediaType]),
    //         src: mediaSrc,
    //       };
    //     }
    //     tagAdded.media = media;
    //   }
    //   let newTagSid = (await mpSdk.Mattertag.add(tagAdded))[0];

    //   //create showcaseTag to save
    //   tagAdded.sid = newTagSid;
    //   tagAdded.source = 'ST';
    //   let showcaseTag: ShowcaseTag = {
    //     id: tagDocRef.id,
    //     data: JSON.stringify(tagAdded),
    //     createdOn: new Date(),
    //     lastUpdatedOn: new Date(),
    //     createdBy: authUser?.uid,
    //     lastUpdatedBy: authUser?.uid,
    //   };
    //   showcaseTag.annotationType = annotationType || ANNOTATION_TYPE.INFO;
    //   quizDetails && quizDetails.question && (showcaseTag.quizDetails = quizDetails);
    //   tagHtml && (showcaseTag.tagHtml = tagHtml);
    //   // mediaLinkInput && (showcaseTag.mediaLink = mediaLinkInput);
    //   showcaseTag.showTextToSpeech = showTextToSpeech ? true : false;
    //   (attachmentsUrls?.[0]||mediaLink)&&(showcaseTag.mediaUpload= attachmentsUrls.length?"attachment":"link");
    //   labelIds && (showcaseTag.labelIds = labelIds);

    //   if (annotationType === ANNOTATION_TYPE.TASK) {
    //     await Api.post('/api/projects/add/task', {task :{...taskTag, tagId: tagDocRef.id} }).then(async (data) => {
    //       if (data.status === 200) {
    //         showcaseTag.projectId = data.data.id;
    //         let index = data.data.sectionList.findIndex((i:TaskSectionObj)=>(i.id == taskTag.sectionId));
    //         let selectedTask = data.data.sectionList[index].taskList[data.data.sectionList[index].taskList.length-1];

    //         // after save task tag in space then pass into showcase
    //         taskTag && (showcaseTag.taskTag = {...taskTag, id: selectedTask.id});

    //         dispatch({ type: SET_SPACE_DATA, payload: {...space, currentSpaceProject:data.data} });
    //         sendEmailsToCollaborator(null,taskTag);
    //       }
    //     })
    //     if(pathname.includes(HomeRoutes.BOARD))
    //       dispatch(onGetProjectDetail(space.currentSpaceProject.did));
    //   }
    //   await tagDocRef.set({ ...showcaseTag });
    //   // // after save task tag in space then pass into showcase
    //   // taskTag && (showcaseTag.taskTag = {taskTag});

    //   showcaseTag.data = tagAdded;
    //   await initiateTag(showcaseTag, tagAdded.sid);

    //   let tagMap = spaceTagsMap || new Map();
    //   tagMap.set(showcaseTag.id, showcaseTag);
    //   dispatch(setSpaceTagsList(tagMap));

    //   mpSdk.Mattertag.navigateToTag(
    //     tagAdded.sid,
    //     mpSdk.Mattertag.Transition.FLY,
    //   );
    //   dispatch(fetchSuccess());
    //   dispatch(showMessage("Tag created successfully."));
    // } catch (error) {
    //   console.error(error);
    //   dispatch(
    //     fetchError(
    //       'Something went wrong connecting to Matterport. Please try again or refresh the page of the problem persists',
    //     ),
    //   );
    // }
    //   try {
    //     dispatch(fetchStart());
    //     // await initMpSdk();
    //     let tag = await getTagSidBeingAdded();
    //     let tagSid = tag[0];
    //     // await setMpSdk();

    //     let tagDocRef = firestore.collection(`Spaces/${space.id}/tags`).doc();
    //     console.log(
    //       `[st] adding tag id  ${getFirestoreLink(
    //         `Spaces/${space.id}/tags/${tagDocRef.id}`,
    //       )}`,
    //     );

    //     //delete tag just created
    //     let allTags = await MpSdk.Instance.mps.Mattertag.getData();
    //     let tagAdded = allTags.find((t: any) => t.sid === tagSid);

    //     if (!tagAdded) {
    //       console.error(
    //         `couldnt find tagAdded. looking for ${tagSid} found in ${allTags.map(
    //           (x: any) => x.sid + ', ' + x.label,
    //         )}`,
    //       );
    //       throw new Error(`couldnt find tagAdded`);
    //     }

    //     await MpSdk.Instance.mps.Mattertag.remove(tagAdded.sid);

    //     let attachmentsUrls: any[] = await uploadMediaFiles(attachments);

    //     //add tag again (to avoid editBillboard)
    //     tagAdded.label = tagLabelInput;
    //     tagAdded.description = tagDescInput;

    //     // console.log(mediaLinkInput, 'embedlyExplore');
    //     if ((attachmentsUrls[0]||mediaLinkInput) && annotationType === ANNOTATION_TYPE.INFO) {
    //       let mediaSrc=attachmentsUrls.length?attachmentsUrls[0]:mediaLinkInput;

    //       const embedlyExplore = await fetch(getEmbedlyMediaUrl(mediaSrc));
    //       const embedlyExploreJson = await embedlyExplore.json();
    //       const mediaType = (embedlyExploreJson?.media.type || '').toUpperCase();
    //       const media = {
    //         type: mediaTypeFinder(MpSdk.Instance.mps.Mattertag.MediaType[mediaType]),
    //         src: mediaSrc,
    //       };
    //       tagAdded.media = media;
    //     }
    //     let newTagSid = (await MpSdk.Instance.mps.Mattertag.add(tagAdded))[0];

    //     //create showcaseTag to save
    //     tagAdded.sid = newTagSid;
    //     tagAdded.source = 'ST';
    //     let showcaseTag: ShowcaseTag = {
    //       id: tagDocRef.id,
    //       data: JSON.stringify(tagAdded),
    //       createdOn: new Date(),
    //       lastUpdatedOn: new Date(),
    //       createdBy: authUser?.uid,
    //       lastUpdatedBy: authUser?.uid,
    //     };
    //     showcaseTag.annotationType = annotationType || ANNOTATION_TYPE.INFO;
    //     quizDetails && quizDetails.question && (showcaseTag.quizDetails = quizDetails);
    //     tagHtml && (showcaseTag.tagHtml = tagHtml);
    //     mediaLinkInput && (showcaseTag.mediaLink = mediaLinkInput);
    //     showcaseTag.showTextToSpeech = showTextToSpeech ? true : false;

    //     if (annotationType === ANNOTATION_TYPE.TASK) {
    //       await Api.post('/api/projects/add/card', { project: taskTag.project, list: taskTag.list, card: { ...taskTag.selectedCard, tagId: tagDocRef.id } }).then(async (data) => {
    //         if (data.status === 200) showcaseTag.projectId = data.data.id;
    //       })
    //     }
    //     await tagDocRef.set({ ...showcaseTag });
    //     // after save task tag in space then pass into showcase
    //     taskTag && (showcaseTag.taskTag = taskTag.selectedCard);

    //     showcaseTag.data = tagAdded;
    //     await initiateTag(showcaseTag, tagAdded.sid);

    //     console.log(`[st] using spaceTags 2`);
    //     let tagMap = spaceTags || new Map();
    //     tagMap.set(showcaseTag.id, showcaseTag);
    //     dispatch(setSpaceTagsList(tagMap));

    //     MpSdk.Instance.mps.Mattertag.navigateToTag(
    //       tagAdded.sid,
    //       MpSdk.Instance.mps.Mattertag.Transition.FLY,
    //     );
    //     dispatch(fetchSuccess());
    //     dispatch(showMessage("Tag created successfully."));
    //   } catch (error) {
    //     console.error(error);
    //     dispatch(
    //       fetchError(
    //         'Something went wrong connecting to Matterport. Please try again or refresh the page if the problem persists',
    //       ),
    //     );
    //   }
    // =======
    //     media: {type: string, src: string},
    //     showTextToSpeech: boolean,
    //     uploadType:string,
    //   ) => {
    //     try {
    //       dispatch(fetchStart());
    //       // await initMpSdk();
    //       let tag = await getTagSidBeingAdded();
    //       let tagSid = tag[0];
    //       // await setMpSdk();

    //       let tagDocRef = firestore.collection(`Spaces/${space.id}/tags`).doc();
    //       console.log(
    //         `[st] adding tag id  ${getFirestoreLink(
    //           `Spaces/${space.id}/tags/${tagDocRef.id}`,
    //         )}`,
    //       );

    //       //delete tag just created
    //       let allTags = await mpSdk.Mattertag.getData();
    //       let tagAdded = allTags.find((t: any) => t.sid === tagSid);

    //       if (!tagAdded) {
    //         console.error(
    //           `couldnt find tagAdded. looking for ${tagSid} found in ${allTags.map(
    //             (x: any) => x.sid + ', ' + x.label,
    //           )}`,
    //         );
    //         throw new Error(`couldnt find tagAdded`);
    //       }

    //       await mpSdk.Mattertag.remove(tagAdded.sid);

    //       //add tag again (to avoid editBillboard)
    //       tagAdded.label = tagLabelInput;
    //       tagAdded.description = tagDescInput;

    //       if (annotationType === ANNOTATION_TYPE.INFO && media.src) {

    //         tagAdded.media.type = media.type;
    //         tagAdded.media.src = media.src;

    //       }

    //       let newTagSid = (await mpSdk.Mattertag.add(tagAdded))[0];

    //       //create showcaseTag to save
    //       tagAdded.sid = newTagSid;
    //       tagAdded.source = 'ST';
    //       let showcaseTag: ShowcaseTag = {
    //         id: tagDocRef.id,
    //         data: JSON.stringify(tagAdded),
    //         createdOn: new Date(),
    //         lastUpdatedOn: new Date(),
    //         createdBy: authUser?.uid,
    //         lastUpdatedBy: authUser?.uid,
    //       };
    //       showcaseTag.annotationType = annotationType || ANNOTATION_TYPE.INFO;
    //       quizDetails && quizDetails.question && (showcaseTag.quizDetails = quizDetails);
    //       tagHtml && (showcaseTag.tagHtml = tagHtml);
    //       showcaseTag.showTextToSpeech = showTextToSpeech ? true : false;
    //       showcaseTag.uploadType = uploadType;

    //       if (annotationType === ANNOTATION_TYPE.TASK) {
    //         await Api.post('/api/projects/add/card', { project: taskTag.project, list: taskTag.list, card: { ...taskTag.selectedCard, tagId: tagDocRef.id } }).then(async (data) => {
    //           if (data.status === 200) showcaseTag.projectId = data.data.id;
    //         })
    //       }
    //       await tagDocRef.set({ ...showcaseTag });
    //       // after save task tag in space then pass into showcase
    //       taskTag && (showcaseTag.taskTag = taskTag.selectedCard);

    //       showcaseTag.data = tagAdded;
    //       await initiateTag(showcaseTag, tagAdded.sid);

    //       let tagMap = spaceTagsMap || new Map();
    //       tagMap.set(showcaseTag.id, showcaseTag);
    //       dispatch(setSpaceTagsList(tagMap));

    //       mpSdk.Mattertag.navigateToTag(
    //         tagAdded.sid,
    //         mpSdk.Mattertag.Transition.FLY,
    //       );
    //       dispatch(fetchSuccess());
    //       dispatch(showMessage("Tag created successfully."));
    //     } catch (error) {
    //       console.error(error);
    //       dispatch(
    //         fetchError(
    //           'Something went wrong connecting to Matterport. Please try again or refresh the page of the problem persists',
    //         ),
    //       );
    //     }
    // >>>>>>> s14/arp/simplifySidebars
  };

  // /*Edit tag to the db, add to showcase and sidebars*/
  const handleEditTagForm = async (
    tagLabelInput: string,
    tagDescInput: string,
    annotationType: string,
    quizDetails: IQuizDetails | null,
    taskTag: TaskObj,
    tagHtml: any,
    editTagDetails: null | ShowcaseTag,
    // <<<<<<< HEAD
    mediaLink: string,
    attachmentsUrls: string[],
    showTextToSpeech: boolean,
    labelIds: number[]
  ) => {
    // try {
    //   // console.log(tagHtml,editTagDetails,"editTag")
    //   // await initMpSdk();
    //   dispatch(fetchStart());
    //   let docRef = firestore.doc(`Spaces/${space.id}/tags/${editTagDetails?.id}`);
    //   let tag: any = { ...editTagDetails?.data };
    //   console.log(
    //     `[st] adding tag id  ${getFirestoreLink(
    //       `Spaces/${space.id}/tags/${docRef.id}`,
    //     )}`,
    //   );

    //   if (!tag) {
    //     console.error(
    //       `couldnt find tagAdded. looking for ${tag.sid}.`,
    //     );
    //     throw new Error(`couldnt find tagAdded`);
    //   }

    //   await mpSdk.Mattertag.remove(tag.sid);

    //   //add tag again (to avoid editBillboard)
    //   tag.label = tagLabelInput;
    //   tag.description = tagDescInput;

    //   if (annotationType === ANNOTATION_TYPE.INFO) {
    //     let media = {
    //       type: mediaTypeFinder(mpSdk.Mattertag.MediaType["NONE"]),
    //       src: '',
    //     };
    //     if((attachmentsUrls?.[0]||mediaLink)){
    //       let mediaSrc=attachmentsUrls.length?attachmentsUrls[0]:mediaLink;

    //       const embedlyExtract:any = await EmbedlyService.embedlyExtract(mediaSrc);
    //       const mediaType = (embedlyExtract?.media.type || '').toUpperCase();
    //       media = {
    //         type: mediaTypeFinder(mpSdk.Mattertag.MediaType[mediaType]),
    //         src: mediaSrc,
    //       };
    //     }
    //     tag.media = media;
    //   }
    //   let newTagSid = (await mpSdk.Mattertag.add(tag))[0];

    //   //create showcaseTag to save
    //   tag.sid = newTagSid;
    //   tag.source = 'ST';
    //   let showcaseTag: ShowcaseTag = {
    //     id: docRef.id,
    //     data: JSON.stringify(tag),
    //     createdOn: editTagDetails?.createdOn ? new Date(editTagDetails?.createdOn) : new Date(),
    //     lastUpdatedOn: new Date(),
    //     createdBy: editTagDetails?.createdBy ? editTagDetails?.createdBy : authUser?.uid,
    //     lastUpdatedBy: authUser?.uid,
    //   };
    //   showcaseTag.annotationType = annotationType || ANNOTATION_TYPE.INFO;
    //   quizDetails && quizDetails.question && (showcaseTag.quizDetails = quizDetails);
    //   // editTagDetails?.tagHtml && (showcaseTag.tagHtml = editTagDetails?.tagHtml);
    //   tagHtml && (showcaseTag.tagHtml = tagHtml);
    //   // mediaLinkInput && (showcaseTag.mediaLink = mediaLinkInput);
    //   showcaseTag.showTextToSpeech = showTextToSpeech ? true : false;
    //   (attachmentsUrls?.[0]||mediaLink)&&(showcaseTag.mediaUpload= attachmentsUrls.length?"attachment":"link");
    //   labelIds && (showcaseTag.labelIds = labelIds);

    //   if (annotationType === ANNOTATION_TYPE.TASK) {
    //     await Api.put('/api/projects/edit/task', { task:{...taskTag, tagId: docRef.id} }).then(async (data) => {
    //       // if (data.status === 200) showcaseTag.projectId = data.data.id;
    //       dispatch({ type: SET_SPACE_DATA, payload: {...space, currentSpaceProject:data.data} });
    //       editTagDetails?.taskTag && sendEmailsToCollaborator(editTagDetails?.taskTag,taskTag);
    //     })
    //   }
    //   await docRef.update(showcaseTag).catch(console.error);
    //   // after save task tag in space then pass into showcase
    //   taskTag && (showcaseTag.taskTag = taskTag);
    //   // tagObj.data = JSON.parse(tagObj.data);
    //   // setShowcaseTags([...showcaseTags, showcaseTag]);

    //   showcaseTag.data = tag;
    //   await initiateTag(showcaseTag, tag.sid);

    //   let tagMap = spaceTagsMap || new Map();
    //   tagMap.set(showcaseTag.id, showcaseTag);
    //   dispatch(setSpaceTagsList(tagMap));

    //   // set Icon for tag
    //   let iconId: string;
    //   if (annotationType === ANNOTATION_TYPE.QUIZ) {

    //     if (tag.color) {
    //       iconId = `quiz-${LabelColorFromTag(tag.color)}`;
    //     } else {
    //       iconId = 'quizIconId';
    //     }

    //   } else if (annotationType === ANNOTATION_TYPE.TASK) {
    //     let labelName = project.sectionList.find(s=>s.id===showcaseTag.taskTag?.sectionId)?.name || '';
    //     iconId = `task${labelName ? labelName.replaceAll(' ', '') : ''}`;
    //     iconId = iconId;
    //   } else {
    //     if (tag.media && tag.media.src) {
    //       iconId = `media`;
    //     } else {
    //       iconId = `info-${LabelColorFromTag(tag.color)}`;
    //     }

    //   }
    //   await mpSdk.Mattertag.editIcon(tag?.sid, iconId);
    //   await mpSdk.Mattertag.navigateToTag(newTagSid, mpSdk.Mattertag.Transition.FLY);
    //   dispatch(fetchSuccess());
    //   dispatch(showMessage("Tag edited successfully."));
    // } catch (error) {
    //   console.error(error);
    //   dispatch(
    //     fetchError(
    //       'Something went wrong connecting to Matterport. Please try again or refresh the page of the problem persists',
    //     ),
    //   );
    // }

    //// Old //////

    //   try {
    //     // console.log(tagHtml,editTagDetails,"editTag")
    //     // await initMpSdk();
    //     dispatch(fetchStart());
    //     let docRef = firestore.doc(`Spaces/${space.id}/tags/${editTagDetails?.id}`);
    //     let tag: any = { ...editTagDetails?.data };
    //     console.log(
    //       `[st] adding tag id  ${getFirestoreLink(
    //         `Spaces/${space.id}/tags/${docRef.id}`,
    //       )}`,
    //     );

    //     if (!tag) {
    //       console.error(
    //         `couldnt find tagAdded. looking for ${tag.sid}.`,
    //       );
    //       throw new Error(`couldnt find tagAdded`);
    //     }

    //     await MpSdk.Instance.mps.Mattertag.remove(tag.sid);

    //     let attachmentsUrls: any[] = await uploadMediaFiles(attachments);

    //     //add tag again (to avoid editBillboard)
    //     tag.label = tagLabelInput;
    //     tag.description = tagDescInput;

    //     if ((attachmentsUrls[0]||mediaLinkInput) && annotationType === ANNOTATION_TYPE.INFO) {
    //       let mediaSrc=attachmentsUrls.length?attachmentsUrls[0]:mediaLinkInput;

    //       const embedlyExplore = await fetch(getEmbedlyMediaUrl(mediaSrc));
    //       const embedlyExploreJson = await embedlyExplore.json();
    //       const mediaType = (embedlyExploreJson?.media.type || '').toUpperCase();
    //       const media = {
    //         type: mediaTypeFinder(MpSdk.Instance.mps.Mattertag.MediaType[mediaType]),
    //         src: mediaSrc,
    //       };
    //       tag.media = media;
    //     }
    //     let newTagSid = (await MpSdk.Instance.mps.Mattertag.add(tag))[0];

    //     //create showcaseTag to save
    //     tag.sid = newTagSid;
    //     tag.source = 'ST';
    //     let showcaseTag: ShowcaseTag = {
    //       id: docRef.id,
    //       data: JSON.stringify(tag),
    //       createdOn: editTagDetails?.createdOn ? new Date(editTagDetails?.createdOn) : new Date(),
    //       lastUpdatedOn: new Date(),
    //       createdBy: editTagDetails?.createdBy ? editTagDetails?.createdBy : authUser?.uid,
    //       lastUpdatedBy: authUser?.uid,
    //     };
    //     showcaseTag.annotationType = annotationType || ANNOTATION_TYPE.INFO;
    //     quizDetails && quizDetails.question && (showcaseTag.quizDetails = quizDetails);
    //     // editTagDetails?.tagHtml && (showcaseTag.tagHtml = editTagDetails?.tagHtml);
    //     tagHtml && (showcaseTag.tagHtml = tagHtml);
    //     mediaLinkInput && (showcaseTag.mediaLink = mediaLinkInput);
    //     showcaseTag.showTextToSpeech = showTextToSpeech ? true : false;

    //     if (annotationType === ANNOTATION_TYPE.TASK) {
    //       await Api.put('/api/projects/edit/card', { project: taskTag.project, list: taskTag.list, card: { ...taskTag.selectedCard, tagId: docRef.id } }).then(async (data) => {
    //         // if (data.status === 200) showcaseTag.projectId = data.data.id;
    //       })
    //     }
    //     await docRef.update(showcaseTag).catch(console.error);
    //     // after save task tag in space then pass into showcase
    //     taskTag && (showcaseTag.taskTag = taskTag.selectedCard);
    //     // tagObj.data = JSON.parse(tagObj.data);
    //     // setShowcaseTags([...showcaseTags, showcaseTag]);

    //     showcaseTag.data = tag;
    //     await initiateTag(showcaseTag, tag.sid);

    //     console.log(`[st] using spaceTags 3`);
    //     let tagMap = spaceTags || new Map();
    //     tagMap.set(showcaseTag.id, showcaseTag);
    //     dispatch(setSpaceTagsList(tagMap));

    //     // set Icon for tag
    //     let iconId: string;
    //     if (annotationType === ANNOTATION_TYPE.QUIZ) {

    //       if (tag.color) {
    //         iconId = `quiz-${LabelColorFromTag(tag.color)}`;
    //       } else {
    //         iconId = 'quizIconId';
    //       }

    //     } else if (annotationType === ANNOTATION_TYPE.TASK) {
    //       let labelName = showcaseTag.taskTag?.section?.name || '';
    //       iconId = `task${labelName ? labelName.replaceAll(' ', '') : ''}`;
    //       iconId = iconId;
    //     } else {
    //       if (tag.media && tag.media.src) {
    //         iconId = `media`;
    //       } else {
    //         iconId = `info-${LabelColorFromTag(tag.color)}`;
    //       }

    //     }
    //     await MpSdk.Instance.mps.Mattertag.editIcon(tag?.sid, iconId);
    //     await MpSdk.Instance.mps.Mattertag.navigateToTag(newTagSid, MpSdk.Instance.mps.Mattertag.Transition.FLY);
    //     dispatch(fetchSuccess());
    //     dispatch(showMessage("Tag edited successfully."));
    //   } catch (error) {
    //     console.error(error);
    //     dispatch(
    //       fetchError(
    //         'Something went wrong connecting to Matterport. Please try again or refresh the page if the problem persists',
    //       ),
    //     );
    //   }

    // =======
    //     media: {type: string, src: string},
    //     showTextToSpeech: boolean,
    //     uploadType:string,
    //   ) => {
    //     try {
    //       // console.log(tagHtml,editTagDetails,"editTag")
    //       // await initMpSdk();
    //       dispatch(fetchStart());
    //       let docRef = firestore.doc(`Spaces/${space.id}/tags/${editTagDetails?.id}`);
    //       let tag: any = { ...editTagDetails?.data };
    //       console.log(
    //         `[st] adding tag id  ${getFirestoreLink(
    //           `Spaces/${space.id}/tags/${docRef.id}`,
    //         )}`,
    //       );

    //       if (!tag) {
    //         console.error(
    //           `couldnt find tagAdded. looking for ${tag.sid}.`,
    //         );
    //         throw new Error(`couldnt find tagAdded`);
    //       }

    //       await mpSdk.Mattertag.remove(tag.sid);

    //       //add tag again (to avoid editBillboard)
    //       tag.label = tagLabelInput;
    //       tag.description = tagDescInput;

    //       if (annotationType === ANNOTATION_TYPE.INFO) {

    //         if(media.src){
    //           tag.media.type = media.type;
    //           tag.media.src = media.src;
    //         }

    //       }

    //       let newTagSid = (await mpSdk.Mattertag.add(tag))[0];

    //       //create showcaseTag to save
    //       tag.sid = newTagSid;
    //       tag.source = 'ST';
    //       let showcaseTag: ShowcaseTag = {
    //         id: docRef.id,
    //         data: JSON.stringify(tag),
    //         createdOn: editTagDetails?.createdOn ? new Date(editTagDetails?.createdOn) : new Date(),
    //         lastUpdatedOn: new Date(),
    //         createdBy: editTagDetails?.createdBy ? editTagDetails?.createdBy : authUser?.uid,
    //         lastUpdatedBy: authUser?.uid,
    //       };
    //       showcaseTag.annotationType = annotationType || ANNOTATION_TYPE.INFO;
    //       quizDetails && quizDetails.question && (showcaseTag.quizDetails = quizDetails);
    //       // editTagDetails?.tagHtml && (showcaseTag.tagHtml = editTagDetails?.tagHtml);
    //       tagHtml && (showcaseTag.tagHtml = tagHtml);
    //       showcaseTag.showTextToSpeech = showTextToSpeech ? true : false;
    //       showcaseTag.uploadType = uploadType;

    //       if (annotationType === ANNOTATION_TYPE.TASK) {
    //         await Api.put('/api/projects/edit/card', { project: taskTag.project, list: taskTag.list, card: { ...taskTag.selectedCard, tagId: docRef.id } }).then(async (data) => {
    //           // if (data.status === 200) showcaseTag.projectId = data.data.id;
    //         })
    //       }
    //       await docRef.update(showcaseTag).catch(console.error);
    //       // after save task tag in space then pass into showcase
    //       taskTag && (showcaseTag.taskTag = taskTag.selectedCard);
    //       // tagObj.data = JSON.parse(tagObj.data);
    //       // setShowcaseTags([...showcaseTags, showcaseTag]);

    //       showcaseTag.data = tag;
    //       await initiateTag(showcaseTag, tag.sid);

    //       let tagMap = spaceTagsMap || new Map();
    //       tagMap.set(showcaseTag.id, showcaseTag);
    //       dispatch(setSpaceTagsList(tagMap));

    //       // set Icon for tag
    //       let iconId: string;
    //       if (annotationType === ANNOTATION_TYPE.QUIZ) {

    //         if (tag.color) {
    //           iconId = `quiz-${LabelColorFromTag(tag.color)}`;
    //         } else {
    //           iconId = 'quizIconId';
    //         }

    //       } else if (annotationType === ANNOTATION_TYPE.TASK) {
    //         let labelName = showcaseTag.taskTag?.section?.name || '';
    //         iconId = `task${labelName ? labelName.replaceAll(' ', '') : ''}`;
    //         iconId = iconId;
    //       } else {
    //         if (tag.media && tag.media.src) {
    //           iconId = `media`;
    //         } else {
    //           iconId = `info-${LabelColorFromTag(tag.color)}`;
    //         }

    //       }
    //       await mpSdk.Mattertag.editIcon(tag?.sid, iconId);
    //       await mpSdk.Mattertag.navigateToTag(newTagSid, mpSdk.Mattertag.Transition.FLY);
    //       dispatch(fetchSuccess());
    //       dispatch(showMessage("Tag edited successfully."));
    //     } catch (error) {
    //       console.error(error);
    //       dispatch(
    //         fetchError(
    //           'Something went wrong connecting to Matterport. Please try again or refresh the page of the problem persists',
    //         ),
    //       );
    //     }
    // >>>>>>> s14/arp/simplifySidebars
  };

  //   // serialize the contents of the function and call it with a single parameter.
  //   // Then, wrap it with a script tag. '<' is separated from '/' to prevent the browser from
  //   // adding this script tag to the parent page on jsfiddle.
  //   function serializeQuizFunctionCall(
  //     myFunction: any,
  //     mattertagId: string,
  //     quizDetails: IQuizDetails | undefined,
  //   ) {
  //     return (
  //       `<script>
  //         ${myFunction.toString()};
  //         ${myFunction.name}('${mattertagId}',${JSON.stringify(quizDetails)});
  //         <` + `/script>`
  //     );
  //   }

  //   function serializeTaskFunctionCall(
  //     myFunction: any,
  //     taskItem: TaskObj | null | undefined,
  //     tagSid: string,
  //     project: ProjectObj
  //   ) {
  //     return (
  //       `<script>
  // ${myFunction.toString()};
  // ${myFunction.name}(${JSON.stringify(taskItem)},'${tagSid}',${JSON.stringify(project)});
  // <` + `/script>`
  //     );
  //   }

  //   function serializeMediaFunctionCall(
  //     myFunction: any,
  //     mattertagId: string,
  //     mediaLink: string,
  //   ) {
  //     return (
  //       `<script>
  //         ${myFunction.toString()};
  //         ${myFunction.name}('${mattertagId}',${JSON.stringify(mediaLink)});
  //       <` + `/script>`
  //     );
  //   }

  //   // The contents of this function will be executed in a sandboxed mattertag iframe.
  //   // You can only refer to variables defined in the iframe.
  //   function mattertagQuizFunction(
  //     mattertagId: string,
  //     quizDetails: IQuizDetails,
  //   ) {
  //     function getSelectedCheckboxValues(name: string) {
  //       const checkboxes = document.querySelectorAll(
  //         `input[name="${name}"]:checked`,
  //       );
  //       let value: string = '';
  //       checkboxes.forEach((checkbox: any) => {
  //         value = checkbox.value;
  //       });
  //       return value;
  //     }
  //     const btn: any = document.querySelector('#radioInputForm');
  //     btn.addEventListener('change', (event: any) => {
  //       let selectedChoice = getSelectedCheckboxValues('choice');

  //       let correctAns: any = document.getElementById('correct-ans');
  //       let wrongAns: any = document.getElementById('wrong-ans');

  //       if (quizDetails?.answer.includes(selectedChoice)) {
  //         correctAns.style.display = 'block';
  //         wrongAns.style.display = 'none';
  //       } else {
  //         wrongAns.style.display = 'block';
  //         correctAns.style.display = 'none';
  //       }
  //     });
  //   }

  //   function mattertagMediaFunction(mattertagId: string, mediaLink: string) { }

  //   function mattertagTaskFunction(taskItem: TaskObj | null | undefined, tagSid: string, project: ProjectObj) {
  //     // when the button is clicked send a message back to the parent page through the window object.
  //     let editTaskBtn: any = document.getElementById('editTaskBtn');
  //     editTaskBtn.addEventListener('click', function (event: any) {
  //       // console.log(`[st] editTaskBtn button clicked`);
  //       // setOpenEditTagForm(true);
  //     });
  //   }

  //   function makeQuizHtml(
  //     mattertagId: string,
  //     quizDetails: IQuizDetails | undefined,
  //     tagHtml: string,
  //   ) {
  //     return (
  //       quizTagHtml(quizDetails, tagHtml) +
  //       serializeQuizFunctionCall(mattertagQuizFunction, mattertagId, quizDetails)
  //     );
  //   }

  //   function makeMediaHtml(
  //     mattertagId: string,
  //     mediaLink: string,
  //     tagHtml: string,
  //   ) {
  //     return (
  //       mediaTagHtml(mediaLink, tagHtml) +
  //       serializeMediaFunctionCall(mattertagMediaFunction, mattertagId, mediaLink)
  //     );
  //   }

  //   function makeTaskTagHtml(taskItem: TaskObj | null | undefined, tagSid: string, tagHtml: string, project: ProjectObj) {
  //     return (
  //       taskTagHtml(taskItem, tagSid, project) +
  //       serializeTaskFunctionCall(mattertagTaskFunction, taskItem, tagSid, project)
  //     );
  //   }

  // };

  // function makeQuizHtml(
  //   mattertagId: string,
  //   quizDetails: IQuizDetails | undefined,
  //   tagHtml: string,
  // ) {
  //   return (
  //     quizTagHtml(quizDetails, tagHtml) +
  //     serializeQuizFunctionCall(mattertagQuizFunction, mattertagId, quizDetails)
  //   );
  // }

  // function makeMediaHtml(
  //   mattertagId: string,
  //   mediaLink: string,
  //   tagHtml: string,
  // ) {
  //   return (
  //     mediaTagHtml(mediaLink, tagHtml) +
  //     serializeMediaFunctionCall(mattertagMediaFunction, mattertagId, mediaLink)
  //   );
  // // }

  // function makeTaskTagHtml(taskItem: TaskObj | null | undefined, tagSid: string, tagHtml: string) {
  //   return (
  //     taskTagHtml(taskItem, tagSid, tagHtml, authUser) +
  //     serializeTaskFunctionCall(mattertagTaskFunction, taskItem, tagSid)
  //   );
  // }

  // let params: { [key: string]: string } = {
  //   m: space.sid,
  //   play: '1',
  //   applicationKey: MATTERPORT_APP_KEY,
  // };
  // const queryString = Object.keys(params)
  //   .map((key) => key + '=' + params[key])
  //   .join('&');
  // const src =
  //   `/bundle/showcase.html?${queryString}` +
  //   '&title=0&qs=1&hr=0&brand=0&help=0';

  // const exitMovingBox = () => {
  //   setObjectAdding(false);
  //   setObjectSidBeingAdded(null);
  // };

  // const getDispatch = () => {
  //   return dispatch;
  //   // console.log("Dispatch called");
  //   // dispatch(updateStepLogic(logic));
  // }
  // PropertiesPanel.getDispatch = dispatch;

  // const initMpSdk = async (): Promise<any> => {
  //   // mpSdk = mpSdk || getMpSdkGlobal(space.id);;

  //   if (!mpSdk) {
  //     // let mpSdkState = await getMpSdk();
  //     // if (mpSdkState) {
  //     //   return mpSdkState;
  //     // }

  //     console.log(`[st] initializing mpSdk`);

  //     let mpSdkInit = await GetSDK('sdk-iframe');
  //     console.log(`[st] setState mpSdk`);
  //     setMpSdk(mpSdkInit);
  //     // if (/!mpSdk) {
  //     // setMpSdkGlobal(await GetSDK('sdk-iframe'));
  //     setMpSdkGlobal(space.id, mpSdkInit);

  //     await initComponents(mpSdkInit);


  //     mpSdkInit.Camera.zoomTo(0.8)
  //       .then(function (newZoom: any) {
  //         if (!space.imageSrc) {
  //           console.log(`[st] taking snapshot  `);
  //           mpSdkInit.Renderer.takeScreenShot({ width: 1280, height: 512 }, { mattertags: false, sweeps: true })
  //             .then(async (x: any) => {
  //               // console.log(`[st] snapshot taken for ${space.id} ${typeof x} ${x.constructor.name} ${x}`);
  //               // console.log(`[st] ${x} ${JSON.stringify(x)}`);
  //               await firestore.doc(`Spaces/${space.id}`).update({ 'imageSrc': x });

  //             })
  //             .catch(console.error);
  //         }
  //       });

  //     mpSdkInit.Sweep.current.subscribe(function (currentSweep: any) {
  //       setCurrentSweep(currentSweep);
  //       // console.log(`[st] moved to sweep ${currentSweep.sid} ${JSON.stringify(currentSweep) }`);
  //     });
  //     mpSdkInit.Camera.zoom.subscribe(function (zoom: any) {
  //       // the zoom level has changed
  //       // console.log('Current zoom is ', zoom);
  //       setCurrentZoom(zoom);
  //     });

  //     try {
  //       registerAllIcons(mpSdkInit);
  //       if (!space?.homeSweepId) {
  //         mpSdkInit.Sweep.current.subscribe(function (currentSweep: any) {
  //           // Change to the current sweep has occurred.
  //           if (!!currentSweep.sid) {
  //             space.homeSweepId = currentSweep.sid;
  //             firestore.collection(`Spaces`).doc(space.id).set({ homeSweepId: space.homeSweepId }, { merge: true }).then(() => { }).catch(console.error)

  //           }
  //         });
  //       }

  //     } catch (e: any) {
  //       //ignore icon already registered errors
  //     }

  //     // mpSdkInit.Camera.zoom.subscribe(function (zoom: any) {
  //     //   // the zoom level has changed
  //     //   console.log('Current zoom is ', zoom.level);
  //     // });
  //   }

  //   return mpSdk;
  // };

  // useEffect(() => {
  //   spaceViewStart();
  // }, [mpSdk]);

  // const spaceViewStart = async () => {

  //   //Simulation.ForceReset();

  //   if (mpSdk && !loadingStarted) {
  //     console.log(`[st] setState loading`);
  //     console.log(`[st] loading space ${space.id}`);
  //     setLoadingStarted(true);
  //     await getMatterportTagsFromDB();
  //     await Simulation.instance.initialize(space.id, mpSdk, authUser as AuthUser, space);
  //     AddObjectClickSpy.dispatch = dispatch;

  //     dispatch(setSpaceModelsList(Simulation.instance.spaceModels));
  //   }
  // };

  /*
    async( pose) {
    // Changes to the Camera pose have occurred.

  });*/

  // const loadIframely = () => {
  //   let _window: any = window;
  //   _window.iframely && _window.iframely.load();
  // };
  // const getIframelyHtml = () => {
  //   return {
  //     __html: `iframely`,
  //   };
  // };

  // const renderIframelyEmbeded = () => {
  //   return (
  //     <div
  //       style={{ height: '400px', width: '100%' }}
  //       dangerouslySetInnerHTML={getIframelyHtml()}
  //     />
  //   );
  // };

  const importMatterportTags = () => {
    // <<<<<<< HEAD
    //   if (externalTags.length == 0) {
    //     dispatch(showMessage("You don't have any tags to import. But you can create tags with the Add Tag button in the Tags sidebar!"));

    //     return;
    //   }
    //   let batch = firestore.batch();
    //   let tagsFromMpToSave: ShowcaseTag[] = [];

    //   externalTags.forEach((tag: any) => {
    //     if (tag.enabled === true) {
    //       // tag.annotationType = ANNOTATION_TYPE.INFO;
    //       tag.source = 'MP';
    //       // tag.tagHtml = wrapTagHtml(tag.description);
    //       let tagString = JSON.stringify(tag);
    //       const tagsCollectionRef = firestore
    //         .collection('Spaces')
    //         .doc(space.id)
    //         .collection('tags');
    //       let tagDocRef = tagsCollectionRef.doc();
    //       batch.set(tagDocRef, {
    //         data: tagString,
    //         sid: tag.sid,
    //         annotationType: ANNOTATION_TYPE.INFO,
    //         createdOn: new Date(),
    //         lastUpdatedOn: new Date(),
    //         createdBy: authUser?.uid,
    //         lastUpdatedBy: authUser?.uid,
    //         tagsAlreadyImported: true
    //       });
    //       tagsFromMpToSave.push({ id: tagDocRef.id, data: tagString });
    //     }
    //   });
    //   batch.commit().then(() => {
    //     getMatterportTagsFromDB();
    //   });
  };

  //New//
  // const initiateTag = async (showcaseTag: ShowcaseTag, currentSid: string) => {
  //   // console.log(showcaseTag, currentSid, 'initiateTag');
  //   //set global tag sid map
  //   addToCurrentTagsSidMapGlobal(showcaseTag.id, currentSid);

  //   //TODO double check before merging, should we prevent these actions?
  //   // mpSdk.Mattertag.preventAction(currentSid, {
  //   //   // opening: true,
  //   //   // navigating: true
  //   // });

  //   //TODO is this needed? test media tags from matterport
  //   showcaseTag.data?.media?.type &&
  //     (showcaseTag.data.media.type =
  //       'mattertag.media.' + showcaseTag.data.media.type);

  //   let iconId = '';
  //   //set icon
  //   // if (showcaseTag.data.source == 'ST') {
  //   if (!showcaseTag.annotationType) {
  //     showcaseTag.annotationType = 'info';


  //   }
  //   if (!showcaseTag.annotationType || showcaseTag.annotationType == 'info') {

  //     if (showcaseTag.data.media && showcaseTag.data.media.src) {
  //       iconId = `media`;
  //     } else {
  //       iconId = `info-${LabelColorFromTag(showcaseTag.data.color)}`;
  //     }
  //   }

  //   if (showcaseTag.annotationType.startsWith('quiz')) {
  //     if (showcaseTag.data.color) {
  //       iconId = `quiz-${LabelColorFromTag(showcaseTag.data.color)}`;
  //     } else {
  //       iconId = 'quizIconId';
  //     }
  //   }
  //   // alert("2" + iconId);
  //   await mpSdk.Mattertag.editIcon(currentSid, iconId || showcaseTag?.annotationType);
  //   // }

  //   //inject HTML
  //   try {
  //     const tagHtml = showcaseTag.tagHtml || '';
  //     const blocksFromHtml = htmlToDraft(tagHtml);
  //     const { contentBlocks, entityMap } = blocksFromHtml;
  //     const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  //     const editorState = EditorState.createWithContent(contentState);
  //     let pureText = ''
  //     convertToRaw(editorState.getCurrentContent()).blocks.forEach(block => {
  //       pureText = pureText + ' ' + block.text
  //     });

  //     const textToSpeechButton = `<button style="position: absolute; right: 5px; top: 0px;" onclick="(()=>{
  //       var synth = window.speechSynthesis;
  //       var utterThis = new SpeechSynthesisUtterance('${pureText}');
  //       synth.speak(utterThis);
  //       return;
  //       })()"><img src="https://icon-library.com/images/google-voice-icon/google-voice-icon-20.jpg" height='12' width='7' /></button>`;
  //     let tagHtmlSpeech =
  //       tagHtml !== '<p></p>\n' //i.e. if draft is empty
  //         ? `<div style="color:white; font-family:Open Sans;"> ${tagHtml} ${showcaseTag.showTextToSpeech ? textToSpeechButton : ''} </div>`
  //         : '';
  //     if (showcaseTag?.annotationType === ANNOTATION_TYPE.INFO) {
  //       // if (showcaseTag?.mediaLink) {
  //       //   const htmlString = makeMediaHtml(
  //       //     currentSid,
  //       //     showcaseTag?.mediaLink,
  //       //     tagHtml,
  //       //   );
  //       //   await injectHtmlHandler(htmlString, currentSid, 600, 400);
  //       // } else {
  //       await injectHtmlHandler(tagHtmlSpeech, currentSid, undefined, undefined); //, 400, 200);
  //       // }
  //     } else if (showcaseTag?.annotationType === ANNOTATION_TYPE.QUIZ) {
  //       const htmlString = makeQuizHtml(
  //         currentSid,
  //         showcaseTag.quizDetails,
  //         tagHtmlSpeech,
  //       );
  //       await injectHtmlHandler(htmlString, currentSid, 400, 250);
  //     } else if (showcaseTag?.annotationType?.startsWith(ANNOTATION_TYPE.TASK)) {
  //       const htmlString = makeTaskTagHtml(
  //         showcaseTag?.taskTag,
  //         currentSid,
  //         tagHtmlSpeech,
  //         project,
  //       );
  //       await injectHtmlHandler(htmlString, currentSid, 600, 250);
  //     }
  //   } catch (error: any) {
  //     console.error(error);
  //   }
  // };

  //Old//
  // const initiateTag = async (showcaseTag: ShowcaseTag, currentSid: string) => {
  //   // console.log(showcaseTag, currentSid, 'initiateTag');
  //   //set global tag sid map
  //   addToCurrentTagsSidMapGlobal(showcaseTag.id, currentSid);

  //   //TODO double check before merging, should we prevent these actions?
  //   // MpSdk.Instance.mps.Mattertag.preventAction(currentSid, {
  //   //   // opening: true,
  //   //   // navigating: true
  //   // });

  //   //TODO is this needed? test media tags from matterport
  //   showcaseTag.data?.media?.type &&
  //     (showcaseTag.data.media.type =
  //       'mattertag.media.' + showcaseTag.data.media.type);

  //   let iconId = '';
  //   //set icon
  //   // if (showcaseTag.data.source == 'ST') {
  //   if (!showcaseTag.annotationType) {
  //     showcaseTag.annotationType = 'info';


  //   }
  //   if (!showcaseTag.annotationType || showcaseTag.annotationType == 'info') {

  //     if (showcaseTag.data.media && showcaseTag.data.media.src) {
  //       iconId = `media`;
  //     } else {
  //       iconId = `info-${LabelColorFromTag(showcaseTag.data.color)}`;
  //     }
  //   }

  //   if (showcaseTag.annotationType.startsWith('quiz')) {
  //     if (showcaseTag.data.color) {
  //       iconId = `quiz-${LabelColorFromTag(showcaseTag.data.color)}`;
  //     } else {
  //       iconId = 'quizIconId';
  //     }
  //   }
  //   // alert("2" + iconId);
  //   await MpSdk.Instance.mps.Mattertag.editIcon(currentSid, iconId || showcaseTag?.annotationType);
  //   // }

  //   //inject HTML
  //   try {
  //     const tagHtml = showcaseTag.tagHtml || '';
  //     const blocksFromHtml = htmlToDraft(tagHtml);
  //     const { contentBlocks, entityMap } = blocksFromHtml;
  //     const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  //     const editorState = EditorState.createWithContent(contentState);
  //     let pureText = ''
  //     convertToRaw(editorState.getCurrentContent()).blocks.forEach(block => {
  //       pureText = pureText + ' ' + block.text
  //     });

  //     const textToSpeechButton = `<button style="position: absolute; right: 5px; top: 0px;" onclick="(()=>{
  //       var synth = window.speechSynthesis;
  //       var utterThis = new SpeechSynthesisUtterance('${pureText}');
  //       synth.speak(utterThis);
  //       return;
  //       })()"><img src="https://icon-library.com/images/google-voice-icon/google-voice-icon-20.jpg" height='12' width='7' /></button>`;
  //     let tagHtmlSpeech =
  //       tagHtml !== '<p></p>\n' //i.e. if draft is empty
  //         ? `<div style="color:white; font-family:Open Sans;"> ${tagHtml} ${showcaseTag.showTextToSpeech ? textToSpeechButton : ''} </div>`
  //         : '';
  //     if (showcaseTag?.annotationType === ANNOTATION_TYPE.INFO) {
  //       // if (showcaseTag?.mediaLink) {
  //       //   const htmlString = makeMediaHtml(
  //       //     currentSid,
  //       //     showcaseTag?.mediaLink,
  //       //     tagHtml,
  //       //   );
  //       //   await injectHtmlHandler(htmlString, currentSid, 600, 400);
  //       // } else {
  //       await injectHtmlHandler(tagHtmlSpeech, currentSid, undefined, undefined); //, 400, 200);
  //       // }
  //     } else if (showcaseTag?.annotationType === ANNOTATION_TYPE.QUIZ) {
  //       const htmlString = makeQuizHtml(
  //         currentSid,
  //         showcaseTag.quizDetails,
  //         tagHtmlSpeech,
  //       );
  //       await injectHtmlHandler(htmlString, currentSid, 400, 250);
  //     } else if (showcaseTag?.annotationType?.startsWith(ANNOTATION_TYPE.TASK)) {
  //       const htmlString = makeTaskTagHtml(
  //         showcaseTag?.taskTag,
  //         currentSid,
  //         tagHtmlSpeech,
  //       );
  //       await injectHtmlHandler(htmlString, currentSid, 600, 250);
  //     }
  //   } catch (error: any) {
  //     console.error(error);
  //   }
  // };
  // =======
  //     if (externalTags.length == 0) {
  //       dispatch(showMessage("You don't have any tags to import. But you can create tags with the Add Tag button in the Tags sidebar!"));

  //       return;
  //     }
  //     let batch = firestore.batch();
  //     let tagsFromMpToSave: ShowcaseTag[] = [];

  //     externalTags.forEach((tag: any) => {
  //       if (tag.enabled === true) {
  //         // tag.annotationType = ANNOTATION_TYPE.INFO;
  //         tag.source = 'MP';
  //         // tag.tagHtml = wrapTagHtml(tag.description);
  //         let tagString = JSON.stringify(tag);
  //         const tagsCollectionRef = firestore
  //           .collection('Spaces')
  //           .doc(space.id)
  //           .collection('tags');
  //         let tagDocRef = tagsCollectionRef.doc();
  //         batch.set(tagDocRef, {
  //           data: tagString,
  //           sid: tag.sid,
  //           annotationType: ANNOTATION_TYPE.INFO,
  //           createdOn: new Date(),
  //           lastUpdatedOn: new Date(),
  //           createdBy: authUser?.uid,
  //           lastUpdatedBy: authUser?.uid,
  //           tagsAlreadyImported: true,
  //           ...(tag?.media.src && { uploadType: TAG_UPLOAD_TYPE.LINK }),
  //         });
  //         tagsFromMpToSave.push({ id: tagDocRef.id, data: tagString });
  //       }
  //     });
  //     batch.commit().then(() => {
  //       getMatterportTagsFromDB();
  //     });
  //   };

  //   const initiateTag = async (showcaseTag: ShowcaseTag, currentSid: string) => {
  //     // console.log(showcaseTag, currentSid, 'initiateTag');
  //     //set global tag sid map
  //     addToCurrentTagsSidMapGlobal(showcaseTag.id, currentSid);

  //     //TODO double check before merging, should we prevent these actions?
  //     // mpSdk.Mattertag.preventAction(currentSid, {
  //     //   // opening: true,
  //     //   // navigating: true
  //     // });

  //     // //TODO is this needed? test media tags from matterport
  //     // showcaseTag.data?.media?.type &&
  //     //   (showcaseTag.data.media.type = showcaseTag.data.media.type);

  //     let iconId = '';
  //     //set icon
  //     // if (showcaseTag.data.source == 'ST') {
  //     if (!showcaseTag.annotationType) {
  //       showcaseTag.annotationType = 'info';


  //     }
  //     if (!showcaseTag.annotationType || showcaseTag.annotationType == 'info') {

  //       if (showcaseTag.data.media && showcaseTag.data.media.src) {
  //         iconId = `media`;
  //       } else {
  //         iconId = `info-${LabelColorFromTag(showcaseTag.data.color)}`;
  //       }
  //     }

  //     if (showcaseTag.annotationType.startsWith('quiz')) {
  //       if (showcaseTag.data.color) {
  //         iconId = `quiz-${LabelColorFromTag(showcaseTag.data.color)}`;
  //       } else {
  //         iconId = 'quizIconId';
  //       }
  //     }
  //     // alert("2" + iconId);
  //     await mpSdk.Mattertag.editIcon(currentSid, iconId || showcaseTag?.annotationType);
  //     // }

  //     //inject HTML
  //     try {
  //       const tagHtml = showcaseTag.tagHtml || '';
  //       const blocksFromHtml = htmlToDraft(tagHtml);
  //       const { contentBlocks, entityMap } = blocksFromHtml;
  //       const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  //       const editorState = EditorState.createWithContent(contentState);
  //       let pureText = ''
  //       convertToRaw(editorState.getCurrentContent()).blocks.forEach(block => {
  //         pureText = pureText + ' ' + block.text
  //       });

  //       const textToSpeechButton = `<button style="position: absolute; right: 5px; top: 0px;" onclick="(()=>{
  //         var synth = window.speechSynthesis;
  //         var utterThis = new SpeechSynthesisUtterance('${pureText}');
  //         synth.speak(utterThis);
  //         return;
  //         })()"><img src="https://icon-library.com/images/google-voice-icon/google-voice-icon-20.jpg" height='12' width='7' /></button>`;
  //       let tagHtmlSpeech =
  //         tagHtml !== '<p></p>\n' //i.e. if draft is empty
  //           ? `<div style="color:white; font-family:Open Sans;"> ${tagHtml} ${showcaseTag.showTextToSpeech ? textToSpeechButton : ''} </div>`
  //           : '';
  //       if (showcaseTag?.annotationType === ANNOTATION_TYPE.INFO) {
  //         // if (showcaseTag?.mediaLink) {
  //         //   const htmlString = makeMediaHtml(
  //         //     currentSid,
  //         //     showcaseTag?.mediaLink,
  //         //     tagHtml,
  //         //   );
  //         //   await injectHtmlHandler(htmlString, currentSid, 600, 400);
  //         // } else {
  //         await injectHtmlHandler(tagHtmlSpeech, currentSid, undefined, undefined); //, 400, 200);
  //         // }
  //       } else if (showcaseTag?.annotationType === ANNOTATION_TYPE.QUIZ) {
  //         const htmlString = makeQuizHtml(
  //           currentSid,
  //           showcaseTag.quizDetails,
  //           tagHtmlSpeech,
  //         );
  //         await injectHtmlHandler(htmlString, currentSid, 400, 250);
  //       } else if (showcaseTag?.annotationType?.startsWith(ANNOTATION_TYPE.TASK)) {
  //         const htmlString = makeTaskTagHtml(
  //           showcaseTag?.taskTag,
  //           currentSid,
  //           tagHtmlSpeech,
  //         );
  //         await injectHtmlHandler(htmlString, currentSid, 600, 250);
  //       }
  //     } catch (error: any) {
  //       console.error(error);
  //     }
  //   };
  // >>>>>>> s14/arp/simplifySidebars
  // const addTagsToEmptyShowcase = (allTagsForSdk: ShowcaseTag[]) => {
  // /*
  //   tagIds: firebase doc IDs for things like Layers and TagGroups that store tagIds, and need to hide/ show tags
  //   tagObjects: typically to load from the database when the app is first laoded
  // */
  const addTagsToEmptyShowcase = async (
    tagIds?: string[],
    tagObjects?: ShowcaseTag[],
    loadAll = false,
  ) => {
    // // await initMpSdk();

    // if (!MpSdk.Instance.mps) {
    //   return;
    // }

    // let showcaseTagsToAdd;

    // let tagsFromMatterport = await MpSdk.Instance.mps.Mattertag.getData();

    // try {
    //   await MpSdk.Instance.mps.Mattertag.remove(
    //     tagsFromMatterport.map((tag: any) => tag.sid),
    //   );
    // } catch (err: any) {
    //   console.error(err);
    //   dispatch(
    //     fetchError(
    //       'Something went wrong connecting to Matterport. Please refresh the page',
    //     ),
    //   );
    //   return;
    // }

    // if (tagObjects && tagObjects.length > 0) {
    //   // tag objects from DB e.g. during initial load
    //   showcaseTagsToAdd = tagObjects;
    //   // if(space.tagsImported){
    //     // console.log(`[st] setstate setting externaltags`);
    //     //store, in case user wants to import from Matterport
    //     setExternalTags(tagsFromMatterport);
    //   // }

    // } else {
    //   showcaseTagsToAdd = tagIds
    //     ? // && tagIds.length > 0
    //     tagIds.reduce((res: ShowcaseTag[], tagId: string) => {
    //       console.log(`[st] using spaceTags 4`);
    //       let spaceTag = spaceTags.get(tagId);
    //       spaceTag != undefined && res.push(spaceTag);
    //       return res;
    //     }, [])
    //     : getFilteredTags({  }, spaceTags); //FIXME STATUS doesn't work - removed tagsViewMode here
    // }

    // // let xrTags = showcaseTagsToAdd.map(tag => ({label: JSON.parse(tag.data).label, position: JSON.parse(tag.data).anchorPosition}))

    // // console.log(`[st] tags dump ${JSON.stringify(xrTags) }`);


    // let tagDataToAdd = showcaseTagsToAdd.map((tag: any) => tag.data);

    // <<<<<<< HEAD
    // // if(!currentTagGroupId ) {
    // for (let i = 0; i < showcaseTagsToAdd.length; i++) {
    //   try {
    //     tagDataToAdd[i].media = {
    //       type: mediaTypeFinder(tagDataToAdd[i].media.type),
    //       src: tagDataToAdd[i].media.src,
    //     };

    //     let newTagSid = (await mpSdk.Mattertag.add(tagDataToAdd[i]))[0];
    //     let showcaseTag = showcaseTagsToAdd[i];
    //     let currentSid = newTagSid; //newTagSids[i];
    //     await initiateTag(showcaseTag, currentSid);
    //   } catch (error: any) {
    //     console.error(error);
    //   }
    //   // }
    // }
  };

  // /*
  //  Re-initialize all Matterport tags in DB
  //  Remove all from showcase
  //  Add tags and annotations from DB to showcase
  //   */
  // const getMatterportTagsFromDB = async () => {

  // let tagsFromDb: ShowcaseTag[] = [];
  // let mergeTaskTags = [];
  // let badTagIds: string[] = [];

  // let qsTags = await firestore
  //   .collection('Spaces')
  //   .doc(space.id)
  //   .collection('tags')
  //   .get();
  // let tags = qsTags.docs.map((doc: ShowcaseTag) => ({ ...doc.data(), id: doc.id }));

  // let otherTags = tags.filter((doc: ShowcaseTag) => doc.annotationType!=="task");
  // let taskTags = tags.filter((doc: ShowcaseTag) => (doc.annotationType==="task"&&(space.currentSpaceProject&&doc.projectId===space.currentSpaceProject.id)));

  // let qsProjectTasks = await firestore
  //   .collection('Projects')
  //   .doc(project.id)
  //   .collection('Tasks')
  //   .where('type', '==', 'TASK_TAG')
  //   .get();

  //   let projectTasks = await Promise.all(qsProjectTasks.docs.map(async(doc: any) => {
  //     let label = doc.data().labelIds.map((labId: number) => (project.labelList[project.labelList.findIndex((a: any) => (a.id == labId))]));
  //     let collaborators: CollaboratorObj[]=[];

  //     if((doc.data()?.collaboratorIds||[]).length){
  //       let ms = await firestore.collection('Users').where('id', "in",doc.data().collaboratorIds).get();
  //       collaborators= ms.docs.map(u => ({ id: u.id, name:`${u.data().firstName} ${u.data().lastName}`, image: u.data().photoURL,email:u.data().email }));
  //     }

  //     // let collaborators = doc.data().collaboratorIds.map((cId: number) => (project.memberList[project.memberList.findIndex((a: any) => (a.id == cId))]));
  //     return ({ ...doc.data(), id: doc.id, label, collaborators });

  //   }));

  // // merge Space Task data and Project Task data
  // mergeTaskTags = taskTags.map(s => ({ ...s, taskTag: projectTasks.find(p => (p.tagId === s.id)) }));

  // const allTags = [...otherTags, ...mergeTaskTags];

  // allTags.forEach((tag) => {
  //   try {
  //     // attachment-feature
  //     //           let tagHtml= tag?.tagHtml;
  //     //         if (tag.data.description) {
  //     //           tagHtml = `${tagHtml} ${wrapTagHtml(tag.data.description)}`;
  //     //         }
  //     const id = tag.id;
  //     const data = tag.data;
  //     const annotationType = tag?.annotationType;
  //     const quizDetails = tag?.quizDetails;
  //     const mediaLink = tag?.mediaLink;
  //     const taskTag = tag?.taskTag;
  //     const tagHtml = tag?.tagHtml;
  //     const showTextToSpeech = tag?.showTextToSpeech;
  //     const createdOn = tag?.createdOn;
  //     const lastUpdatedOn = tag?.lastUpdatedOn;
  //     const createdBy = tag?.createdBy;
  //     const lastUpdatedBy = tag?.lastUpdatedBy;
  //     const labelIds = tag?.labelIds;

  //     tagsFromDb.push({
  //       id: id,
  //       data: JSON.parse(data),
  //       ...(annotationType && { annotationType: annotationType }),
  //       ...(quizDetails && { quizDetails: quizDetails }),
  //       ...(mediaLink && { mediaLink: mediaLink }),
  //       ...(taskTag && { taskTag: taskTag }),
  //       ...(tagHtml && { tagHtml: tagHtml }),
  //       ...(showTextToSpeech && { showTextToSpeech: showTextToSpeech }),
  //       ...(createdOn && { createdOn: new Date(tag.createdOn.seconds * 1000) }),
  //       ...(lastUpdatedOn && { lastUpdatedOn: new Date(tag.lastUpdatedOn.seconds * 1000) }),
  //       ...(createdBy && { createdBy: createdBy }),
  //       ...(lastUpdatedBy && { lastUpdatedBy: lastUpdatedBy }),
  //       ...(labelIds && { labelIds: labelIds }),
  //     });
  //   } catch (error: any) {
  //     console.error('found a bad tag');
  //     console.error(error);
  //     badTagIds.push(tag.id);
  //   }
  // });
  // =======
  //     // if(!currentTagGroupId ) {
  //     for (let i = 0; i < showcaseTagsToAdd.length; i++) {
  //       try {
  //         let newTagSid = (await mpSdk.Mattertag.add(tagDataToAdd[i]))[0];
  //         let showcaseTag = showcaseTagsToAdd[i];
  //         let currentSid = newTagSid; //newTagSids[i];
  //         await initiateTag(showcaseTag, currentSid);
  //       } catch (error: any) {
  //         console.error(error);
  //       }
  //       // }
  //     }
  //   };

  //   /*
  //    Re-initialize all Matterport tags in DB
  //    Remove all from showcase
  //    Add tags and annotations from DB to showcase
  //     */
  //   const getMatterportTagsFromDB = async () => {

  //     // Get all space projects and find the default project
  //     let spaceProjects = await Api.get(`/api/spaces/${space.did}/spaceProjectList`);
  //     const sProject: ProjectObj = spaceProjects.data.find((f: ProjectObj) => (f.name.includes("Default Project")));

  //     let tagsFromDb: ShowcaseTag[] = [];
  //     let mergeTaskTags = [];
  //     let qsOtherTags = await firestore
  //       .collection('Spaces')
  //       .doc(space.id)
  //       .collection('tags')
  //       .where('annotationType', '!=', 'task')
  //       .get();
  //     let otherTags = qsOtherTags.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
  //     let badTagIds: string[] = [];

  //     let qsTaskTagsQuery = firestore
  //       .collection('Spaces')
  //       .doc(space.id)
  //       .collection('tags')
  //       .where('annotationType', '==', 'task');

  //     let qsTaskTags = await ((sProject && sProject.id) ? qsTaskTagsQuery.where('projectId', '==', sProject.id).get() : qsTaskTagsQuery.get());
  //     let taskTags = qsTaskTags.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));

  //     let qsProjectQuery = firestore
  //       .collection('Projects')
  //       .where('spaceId', '==', space.id)
  //     // .where('id', '==', sProject.id)
  //     // .get();
  //     let qsProject = await ((sProject && sProject.id) ? qsProjectQuery.where('id', '==', sProject.id).get() : qsProjectQuery.get());
  //     let [project] = qsProject.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));

  //     if (qsProject.size !== 0) {

  //       //  let tasks= qsProject.docs.map(doc => {
  //       let qsProjectTasks = await firestore
  //         .collection('Projects')
  //         .doc(qsProject.docs[0].id)
  //         .collection('Tasks')
  //         .where('type', '==', 'TASK_TAG')
  //         .get();
  //       // });
  //       let projectTasks = qsProjectTasks.docs.map((doc: any) => {
  //         let label = doc.data().labelIds.map((labId: number) => (project.labelList[project.labelList.findIndex((a: any) => (a.id == labId))]));
  //         let collaborators = doc.data().collaboratorIds.map((cId: number) => (project.memberList[project.memberList.findIndex((a: any) => (a.id == cId))]));
  //         return ({ ...doc.data(), id: doc.id, label, collaborators });
  //       });

  //       mergeTaskTags = taskTags.map(s => ({ ...s, taskTag: projectTasks.find(p => (p.tagId === s.id)) }));
  //     }

  //     const allTags = [...otherTags, ...mergeTaskTags];
  //     // console.log(project,taskTags,projectTasks,mergeTaskTags,allTags,"qsProjectTasks")

  //     allTags.forEach((tag) => {
  //       try {
  //         // attachment-feature
  //         //           let tagHtml= tag?.tagHtml;
  //         //         if (tag.data.description) {
  //         //           tagHtml = `${tagHtml} ${wrapTagHtml(tag.data.description)}`;
  //         //         }
  //         const id = tag.id;
  //         const data = tag.data;
  //         const annotationType = tag?.annotationType;
  //         const quizDetails = tag?.quizDetails;
  //         // const mediaLink = tag?.mediaLink;
  //         const taskTag = tag?.taskTag;
  //         const tagHtml = tag?.tagHtml;
  //         const showTextToSpeech = tag?.showTextToSpeech;
  //         const createdOn = tag?.createdOn;
  //         const lastUpdatedOn = tag?.lastUpdatedOn;
  //         const createdBy = tag?.createdBy;
  //         const lastUpdatedBy = tag?.lastUpdatedBy;
  //         const projectId = tag?.projectId;
  //         const uploadType = tag?.uploadType;

  //         tagsFromDb.push({
  //           id: id,
  //           data: JSON.parse(data),
  //           ...(annotationType && { annotationType: annotationType }),
  //           ...(quizDetails && { quizDetails: quizDetails }),
  //           // ...(mediaLink && { mediaLink: mediaLink }),
  //           ...(taskTag && { taskTag: taskTag }),
  //           ...(tagHtml && { tagHtml: tagHtml }),
  //           ...(showTextToSpeech && { showTextToSpeech: showTextToSpeech }),
  //           ...(createdOn && { createdOn: new Date(tag.createdOn.seconds * 1000) }),
  //           ...(lastUpdatedOn && { lastUpdatedOn: new Date(tag.lastUpdatedOn.seconds * 1000) }),
  //           ...(createdBy && { createdBy: createdBy }),
  //           ...(lastUpdatedBy && { lastUpdatedBy: lastUpdatedBy }),
  //           ...(projectId && { projectId: projectId }),
  //           ...(uploadType && { uploadType: uploadType }),
  //         });
  //       } catch (error: any) {
  //         console.error('found a bad tag');
  //         console.error(error);
  //         badTagIds.push(tag.id);
  //       }
  //     });
  // >>>>>>> s14/arp/simplifySidebars

  // if (tagsFromDb.find((x) => x.data?.source == 'MP')) {
  //   setTagsAlreadyImported(true);
  // }

  // try {
  //   let tagsFromMatterport = await mpSdk.Mattertag.getData();
  //   // await mpSdk.Mattertag.remove(tagsFromMatterport.map((tag: any) => tag.sid)).catch(console.error);


  //   if (externalTags.length == 0) {
  //     setExternalTags(tagsFromMatterport);
  //   }
  //   tagsFromDb = tagsFromDb.filter((tag) => !badTagIds.includes(tag.id));
  //   let tagMap = new Map(tagsFromDb.map((t) => [t.id, t]));
  //   dispatch(setSpaceTagsList(tagMap)); //controls state accross components

  //   // let tagSidsMap = new Map(tagsFromDb.map(t => [t.id, t.data.sid]));
  //   // setCurrentTagsSidMapGlobal(tagSidsMap); //global reference for current tag sids

  //   // add to showcase
  //   setCurrentTagsSidMapGlobal(new Map());
  //   await addTagsToEmptyShowcase([], tagsFromDb); //tagsFromDb

  //   // })
  // } catch (error: any) {
  //   console.error(error, 'error while getting initial tag data');
  // }

  // /*
  // Mouse movement listener for adding tags
  //  */
  // mpSdk &&
  //   mpSdk.Pointer.intersection.subscribe(
  //     async (intersectionData: {
  //       object: string;
  //       position: any;
  //       normal: { x: any; y: any; z: any };
  //     }) => {
  //       let tag = await getTagSidBeingAdded();
  //       let addingTag = await getAddingTag();
  //       if (tag && addingTag) {
  //         if (
  //           intersectionData.object === 'intersectedobject.model' ||
  //           intersectionData.object === 'intersectedobject.sweep'
  //         ) {
  //           await updateTagPos(
  //             intersectionData.position,
  //             intersectionData.normal,
  //             0.33,
  //             tag,
  //           );
  //         }
  //       }
  //     },
  //   );

  //Old//
  // mpSdk && mpSdk.Mattertag.subscribe(() => console.log(`[st] clicked`))
  //   // Get all space projects and find the default project
  //   let spaceProjects = await Api.get(`/api/spaces/${space.did}/spaceProjectList`);
  //   const sProject: ProjectObj = spaceProjects.data.find((f: ProjectObj) => (f.name.includes("Default Project")));

  //   let tagsFromDb: ShowcaseTag[] = [];
  //   let mergeTaskTags = [];
  //   let qsOtherTags = await firestore
  //     .collection('Spaces')
  //     .doc(space.id)
  //     .collection('tags')
  //     .where('annotationType', '!=', 'task')
  //     .get();
  //   let otherTags = qsOtherTags.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
  //   let badTagIds: string[] = [];

  //   let qsTaskTagsQuery = firestore
  //     .collection('Spaces')
  //     .doc(space.id)
  //     .collection('tags')
  //     .where('annotationType', '==', 'task');

  //   let qsTaskTags = await ((sProject && sProject.id) ? qsTaskTagsQuery.where('projectId', '==', sProject.id).get() : qsTaskTagsQuery.get());
  //   let taskTags = qsTaskTags.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));

  //   let qsProjectQuery = firestore
  //     .collection('Projects')
  //     .where('spaceId', '==', space.id)
  //   // .where('id', '==', sProject.id)
  //   // .get();
  //   let qsProject = await ((sProject && sProject.id) ? qsProjectQuery.where('id', '==', sProject.id).get() : qsProjectQuery.get());
  //   let [project] = qsProject.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));

  //   if (qsProject.size !== 0) {

  //     //  let tasks= qsProject.docs.map(doc => {
  //     let qsProjectTasks = await firestore
  //       .collection('Projects')
  //       .doc(qsProject.docs[0].id)
  //       .collection('Tasks')
  //       .where('type', '==', 'TASK_TAG')
  //       .get();
  //     // });
  //     let projectTasks = qsProjectTasks.docs.map((doc: any) => {
  //       let label = doc.data().labelIds.map((labId: number) => (project.labelList[project.labelList.findIndex((a: any) => (a.id == labId))]));
  //       let collaborators = doc.data().collaboratorIds.map((cId: number) => (project.memberList[project.memberList.findIndex((a: any) => (a.id == cId))]));
  //       return ({ ...doc.data(), id: doc.id, label, collaborators });
  //     });

  //     mergeTaskTags = taskTags.map(s => ({ ...s, taskTag: projectTasks.find(p => (p.tagId === s.id)) }));
  //   }

  //   const allTags = [...otherTags, ...mergeTaskTags];
  //   // console.log(project,taskTags,projectTasks,mergeTaskTags,allTags,"qsProjectTasks")

  //   allTags.forEach((tag) => {
  //     try {
  //       // attachment-feature
  //       //           let tagHtml= tag?.tagHtml;
  //       //         if (tag.data.description) {
  //       //           tagHtml = `${tagHtml} ${wrapTagHtml(tag.data.description)}`;
  //       //         }
  //       const id = tag.id;
  //       const data = tag.data;
  //       const annotationType = tag?.annotationType;
  //       const quizDetails = tag?.quizDetails;
  //       const mediaLink = tag?.mediaLink;
  //       const taskTag = tag?.taskTag;
  //       const tagHtml = tag?.tagHtml;
  //       const showTextToSpeech = tag?.showTextToSpeech;
  //       const createdOn = tag?.createdOn;
  //       const lastUpdatedOn = tag?.lastUpdatedOn;
  //       const createdBy = tag?.createdBy;
  //       const lastUpdatedBy = tag?.lastUpdatedBy;

  //       tagsFromDb.push({
  //         id: id,
  //         data: JSON.parse(data),
  //         ...(annotationType && { annotationType: annotationType }),
  //         ...(quizDetails && { quizDetails: quizDetails }),
  //         ...(mediaLink && { mediaLink: mediaLink }),
  //         ...(taskTag && { taskTag: taskTag }),
  //         ...(tagHtml && { tagHtml: tagHtml }),
  //         ...(showTextToSpeech && { showTextToSpeech: showTextToSpeech }),
  //         ...(createdOn && { createdOn: new Date(tag.createdOn.seconds * 1000) }),
  //         ...(lastUpdatedOn && { lastUpdatedOn: new Date(tag.lastUpdatedOn.seconds * 1000) }),
  //         ...(createdBy && { createdBy: createdBy }),
  //         ...(lastUpdatedBy && { lastUpdatedBy: lastUpdatedBy }),
  //       });
  //     } catch (error: any) {
  //       console.error('found a bad tag');
  //       console.error(error);
  //       badTagIds.push(tag.id);
  //     }
  //   });

  //   // if (tagsFromDb.find((x) => x.data?.source == 'MP')) {
  //   //   setTagsAlreadyImported(true);
  //   // }

  //   try {
  //     let tagsFromMatterport = await mpSdk.Mattertag.getData();
  //     // await mpSdk.Mattertag.remove(tagsFromMatterport.map((tag: any) => tag.sid)).catch(console.error);

  //     if (externalTags.length == 0) {
  //       console.log(`[st] setstate setting externaltags 2`);
  //       setExternalTags(tagsFromMatterport);
  //     }
  //     tagsFromDb = tagsFromDb.filter((tag) => !badTagIds.includes(tag.id));
  //     let tagMap = new Map(tagsFromDb.map((t) => [t.id, t]));
  //     console.log(`[st] setting spaceTags`);
  //     dispatch(setSpaceTagsList(tagMap)); //controls state accross components

  //     // let tagSidsMap = new Map(tagsFromDb.map(t => [t.id, t.data.sid]));
  //     // setCurrentTagsSidMapGlobal(tagSidsMap); //global reference for current tag sids

  //     // add to showcase
  //     setCurrentTagsSidMapGlobal(new Map());
  //     await addTagsToEmptyShowcase([], tagsFromDb); //tagsFromDb

  //     // })
  //   } catch (error: any) {
  //     console.error(error, 'error while getting initial tag data');
  //   }

  //   /*
  //   Mouse movement listener for adding tags
  //    */
  //   mpSdk && addingTag &&
  //     mpSdk.Pointer.intersection.subscribe(
  //       async (intersectionData: {
  //         object: string;
  //         position: any;
  //         normal: { x: any; y: any; z: any };
  //       }) => {
  //         let tag = await getTagSidBeingAdded();
  //         console.log(`[st] setState getAddingTag`);
  //         let addingTag = await getAddingTag();
  //         if (tag && addingTag) {
  //           if (
  //             intersectionData.object === 'intersectedobject.model' ||
  //             intersectionData.object === 'intersectedobject.sweep'
  //           ) {
  //             await updateTagPos(
  //               intersectionData.position,
  //               intersectionData.normal,
  //               0.33,
  //               tag,
  //             );
  //           }
  //         }
  //       },
  //     );
  //   // mpSdk && mpSdk.Mattertag.subscribe(() => console.log(`[st] clicked`))
  // };

  // useEventListener('blur', addTagHandler);

  // const showCustomBillboard = (tagSid: any) => {
  //   // console.log(`[st] clicked ${JSON.stringify(tagSid)}`);
  //   mpSdk.Mattertag.getData().then((tags: any[]) => {
  //     console.log(tags);
  //     console.log(`[st] git tag data ${JSON.stringify(tagSid)}`);
  //     let tag: any = tags.find(tag => tag.sid == tagSid);
  //     console.log(tag);

  //     console.log(`[st] pos ${JSON.stringify(tag)}`);

  //     let finalAnchorPosition = new THREE.Vector3(tag.anchorPosition.x,
  //       tag.anchorPosition.y,
  //       tag.anchorPosition.z);

  //     // Disc anchor looks better but both work (disc is at the center of the  tag disc)
  //     // Feel free to comment out finalAnchorPosition above
  //     let discAnchorPosition = mpSdk.Mattertag.getDiscPosition(tag);
  //     //console.log(tag.stemHeight)
  //     //console.log(tag.stemVector)

  //     //let coordsMp = Simulation.instance.camera.getScreenCoordsOfPoint(finalAnchorPosition);
  //     // Simulation.instance.camera.LookAtXYZ(new THREE.Vector3(
  //     //   tag.anchorPosition.x,
  //     //   tag.anchorPosition.y,
  //     //   tag.anchorPosition.z
  //     // ));

  //     let coords1 = Simulation.instance.camera.getScreenCoordsOfPointWithoutMatterPortSDK(
  //       new THREE.Vector3(discAnchorPosition.x, discAnchorPosition.y, discAnchorPosition.z));
  //     console.log(`[st] coords ${JSON.stringify(coords1)}`);
  //     //console.log(`[st] coordsMP ${JSON.stringify(coordsMp)}`);
  //     setClickedTagCoords(Object.assign({}, coords1));

  //   }).catch(console.error)

  // }

  // useEffect(() => {

  //   //mpSdk && mpSdk.on(mpSdk.Mattertag.Event.CLICK, showCustomBillboard)

  // }, [mpSdk])


  // /*
  // Keeps track of tag co-ordinates during Add Tag
  //  */
  // const updateTagPos = async (
  //   newPos: any,
  //   newNorm: any,
  //   scale: any,
  //   tag: any,
  // ) => {
  //   if (!newPos) return;
  //   if (!scale) scale = 0.33;
  //   if (!newNorm) newNorm = { x: 0, y: 1, z: 0 };

  //   MpSdk.Instance.mps.Mattertag.editPosition(tag[0], {
  //     anchorPosition: newPos,
  //     stemVector: {
  //       x: scale * newNorm.x,
  //       y: scale * newNorm.y,
  //       z: scale * newNorm.z,
  //     },
  //     stemVisible: true,
  //     floorIndex: 0,
  //   })
  //     .then(() => { })
  //     .catch((e: any) => {
  //       console.error(e);
  //       setTagSidBeingAdded(false);
  //     });
  // };


  const addObject = async (objectName: string) => {
    // await initMpSdk();
    Simulation.instance.addObject(objectName);
    dispatch(showMessage("Move cursor into the Space area to add!"));
  };

  const handleDeleteTag = async (tagId: string) => {
    console.log(`[st] using spaceTags`);
    // let visibleSpaceTags: ShowcaseTag[] = Array.from(spaceTags.values());
    // let tag: ShowcaseTag | undefined = visibleSpaceTags.find((el: ShowcaseTag) => tagId === el.id);

    // if (tag) {
    let res = window.confirm(`Are you sure you want to delete this tag?`);
    if (res) {
      const deleteTagRef: any = await firestore
        .doc(`Spaces/${space.id}`)
        .collection(`tags`)
        .doc(tagId);

      // const { id: deleteTagRefId } = deleteTagRef;
      deleteTagRef
        .delete()
        .then(() => {
          getMatterportTagsFromDB();
        })
        .catch(console.error);
    }
    // }
  };

  // const handleCancelTagForm = async () => {
  //   // let tag = tagSidBeingAdded;//FIXME
  //   tagSidBeingAdded && MpSdk.Instance.mps.Mattertag.remove(tagSidBeingAdded);
  //   setTagSidBeingAdded(null);

  //   if(!!editShowcaseTagId) {
  //     MpSdk.Instance.mps.Mattertag.remove(editShowcaseTagId).catch((err: any) => "Couldn't delete tag after cancelling add/ edit");
  //     dispatch(setEditShowcaseTagId(''));
  //   }
  // };

  return (
    <Box className={(console.log(`[st] rendering SpaceView.`), classes.containerSpace)}>
      {/*clickedTagCoords && <Box style={{ backgroundColor: "red", zIndex: 1, position: "absolute", left: clickedTagCoords.x + "px", top: clickedTagCoords.y + "px" }}><Typography >${(console.log(`[st] x is is ${clickedTagCoords.x}`), `x: ${clickedTagCoords.x}, y: ${clickedTagCoords.y}`)}</Typography></Box>*/}

      <ShowcaseContext.Provider
        value={{
          // handleDeleteTag,
          // handleEditTag,
          // handleAddTag,
          // handleTagClick,
          // addTagsToEmptyShowcase,
          // getMatterportTagsFromDB,
          // handleCancelTagForm,
          importMatterportTags,
        }}>

        {showDictaphone && <Dialog open={showDictaphone} onClose={async () => { //TODO: does this cause too many re-renders?
          //TODO
          // addComment();
          // writeToActiveElement(await navigator.clipboard.readText());
          // setShowDictaphone(false);
        }}><Box className='dictaphone'><Dictaphone /></Box></Dialog>}
        {useMemo(() => //DUBIOUS
          <ShowcaseOverlay
            // setTagSidBeingAdded={setTagSidBeingAdded}
            // onAddTag={handleAddTagForm}
            // addObject={addObject}
            // onEditTag={handleEditTagForm}
            // src={src}
            space={space}
            project={project}
            // goToHomeSweep={MpSdk.Instance.mps.goToHomeSweep}
            lockViewDefault={space.settings && space.settings?.lockViewDefault} //if not present, lock view = false
            hideControlsDefault={true}//((space.settings && space.settings?.hideControlsDefault))} //if not present, hide controls = false
            hide3DToolsDefault={space.settings && space.settings?.hide3DToolsDefault} //if not present, hide 3d toolbar = false
          // toggleModelObjectSidebar={() => setIsVisibleModelObjectsSidebar(!isVisibleModelObjectsSidebar)}
          // toggleToursSidebar={() => setIsVisibleToursSidebar(!isVisibleToursSidebar)}
          />, [space.id, MpSdk.Instance.mps])}

        {/* <div style={{

          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'center',
          // position: 'absolute',
          // top: "50%",
          // right: "0%",
          // transform: "translate(-50%, -50%)"
        }}> */}
        {useMemo(() => <Sidebar
        // addObject={addObject}
        />, [space.id])}
        {/* // importMatterportTags={importMatterportTags}
          // tagsAlreadyImported={tagsAlreadyImported}
          // transformMode={transformMode}
          // handleTransformModeChange={handleTransformModeChange}
          // isVisibleModelObjectsSidebar={isVisibleModelObjectsSidebar}
          // isVisibleToursSidebar={isVisibleToursSidebar} */}

        {/* </div> */}
      </ShowcaseContext.Provider>
    </Box>
  );
};
SpaceView.whyDidYouRender = true;
export default SpaceView;