import { SET_SPACES, GET_SPACES } from 'types/actions/Dashboard.action';
import { AppActions } from '../../types';

const initialState: {
    spaces: any[];
    loading: boolean;
} = {
    spaces: [],
    loading: true
};

const Dashboard = (state = initialState, action: AppActions) => {
    switch (action.type) {
        case GET_SPACES:
            return {
                spaces: state.spaces,
            };
        case SET_SPACES:
            return {
                ...state,
                spaces: action.payload,
                loading: false
            };
        default:
            return state;
    }
};
export default Dashboard;
