import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CremaTheme } from 'types/AppContextPropsType';
import "./style.css";
const useStyles = makeStyles((theme: CremaTheme) => ({

}))

export default function IndicatorPulse() {
  // const classes = useStyles();
  return (<div style={{margin: "8px 8px", paddingLeft: "8px"}}>
  <div
    className={"dot-pulse"}
  ></div></div>)
}