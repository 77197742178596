import { Object3D } from "three";
import { GizmoTools } from "../../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import { PlaneRenderer } from "../../components/meshComponents/basic/PlaneRenderer";
import { NestThermostat } from "../../components/meshComponents/NestThermostat";
import { ChangeBooleanPropertyPassThrough, ChangeGenericActionOutcomeTextPassThrough, ChangeNodeColorPassThrough, ChangeTextPassThrough, ChangeTextPanelTextPassThrough, ChangeTextPassWithParamThrough } from "../../Tools/InteropTypes/InteropClasses";
import QueueScheduler from "../../Tools/QueueScheduler";
import Utils from "../../Tools/Utils";
import Simulation from "../core/Simulation";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import NodeStorage from "../storageAndSerialization/NodeStorage";
import { fetchError, showMessage } from "redux/actions";
import PropertiesPanelBehaviorActions, { ActionType, EventActionOutcome, TriggerActionOutcome, VariableValueActionPair, VariableValueTriggerPair } from "./PropertiesPanelBehaviorActions";
import { showRadialMenu } from "redux/actions/Home";
import { store } from "App";
import { ImageRenderer } from "../../components/meshComponents/basic/ImageRenderer";

export enum PropertiesPanelDropDownType {
    ActionTypeDropDown = "Action Type Drop Down",
    SystemVariablesTriggerDropDown = "System Variables Trigger Drop Down",
    SystemVariablesActionDropDown = "System Variables Action Drop Down",
    TriggerActionTypeDropDown = "Trigger Action Type Drop Down",
    InputSource1_DropDown = "Input Source 1 DropDown",
}

export enum UnserializedUserData {
    StartPosition = "StartPosition"
}

export enum UserDataProperties {
    ClickEventActionList = "clickEventActionList",
    type = "type",
    hasColorProperty = "hasColorProperty",
    textProperty = "textProperty",
    customColorProperty = "customColorProperty",
    hasPropertiesPanel = "hasPropertiesPanel",
    distanceForNormalOffset = "distanceForNormalOffset",
    fontSize = "fontSize",
    hasBorderProperty = "hasBorderProperty",
    borderColorProperty = "borderColorProperty",
    nameToShow = "nameToShow",
    newModelURL = "newModelURL",
    id = "id",
    overrideUserData = "overrideUserData",
    catalogDetails = "catalogDetails",
    executeOnceAndRemove = "executeOnceAndRemove",
    userData = "userData", //This is not really a property but created the enum over here anyway
    varTriggers = "varTriggers",
    varActions = "varActions",
    alwaysShow = "alwaysShow",
    TriggerActionList = "triggerActionList",
    localPosition = "localPosition",
    rotationAxis = "rotationAxis",
    rotationRange = "rotationRange",
    borderRadius = "borderRadius",
    borderSize = "borderSize",
    textureSource = "textureSource",

    Logic = "logic",

    //Reserved properties
    inputSource1 = "inputSource1",
    inputSource2 = "inputSource2",
    inputSource3 = "inputSource3",
    inputSource4 = "inputSource4",

    // Logic Trees
    logic = "logic"
}

export enum IoTSpecialProperties {
    diff1 = "diff1",
    diff2 = "diff2",
}

export enum PropertiesPanelMode {
    None = "None",
    CanAdd = "CanAdd",
    Adding = "Adding",
    ConsolidateAdd = "ConsolidateAdd",
    Editing = "Editing",
    Editing_IOT = "Editing_IOT",
    CancelEditing_IOT = "CancelEditing_IOT",
    Consolidate_IOT = "Consolidate_IOT",
}

export class PropertiesPanelUIData {
    currentNodeID: string;
    propertiesPanelMode: PropertiesPanelMode;
    //scale: { x:number, y:number, z: number};
    nodePointer:ISceneNode|null;
    hasRotateToggle:boolean;
    hasToggleComponent:boolean;
    userData: { [key: string]: any };

    constructor() {
        this.currentNodeID = "";
        this.propertiesPanelMode = PropertiesPanelMode.None;
        this.userData = {};
        this.nodePointer = null;
    }

}



export enum NodeDataProperties {
    scale = "scale"
}

export enum UserDataGizmoMinorMods {
    disableScale = "disableScale"
}

export enum UserDataTypes {
    boundedBox = "boundedBox",
    highlightBorder = "highlightBorder",
    arrow = "arrow",
    textPanel = "textPanel",
    initialPlacementStyle = "initialPlacementStyle",
    thermostatNest = "thermostatNest",
    InteriorDesignModel = "InteriorDesignModel",
    leverToggle = "leverToggle",
    webcam = "webcam",
    onOffButton = "onOffButton",
    imageRenderer = "imageRenderer"
}

export enum CompatabilityUserDataTypes {
    boundedBox = "boundexBox"
}

export enum InitialPlacementStyle {
    arrow = "arrow",
    thermostat = "thermostat",
    dial = "dial",
    threeFeetAbove = "threeFeetAbove",
    plane = "plane",
    offsetFromNormal="offsetFromNormal"
}

class PropertiesPanel extends PropertiesPanelBehaviorActions {

    public static getDispatch:any|undefined = undefined;

    public showPropertiesPanel():boolean {
        if(this.node == null) {
            if(Simulation.instance.lastSelectedNode) {
                this.changePropertiesPanelMode(PropertiesPanelMode.CanAdd);
                //this.setShowPropertiesPanel(this.getNodeProperties(this.root.lastSelectedNode));
                this.updatePropertiesPanel();
                return true;
            }
        } else {
            if(Simulation.instance.lastSelectedNode !== this.node) {
                if(this.mode === PropertiesPanelMode.Adding) {
                    console.log("[PropertiesPanel] Adding");
                } else if(this.mode === PropertiesPanelMode.Editing) {
                    console.log("[PropertiesPanel] Editing");
                } else if(this.mode === PropertiesPanelMode.Editing_IOT) {
                    console.log("[PropertiesPanel] Editing IoT");
                }else {
                    this.updatePropertiesPanel();
                    return true;
                }
            } else {
                if(this.mode === PropertiesPanelMode.Adding) {
                } else if(this.mode === PropertiesPanelMode.Editing) {
                    console.log("[PropertiesPanel] Editing");
                }  else if(this.mode === PropertiesPanelMode.Editing_IOT) {
                    console.log("[PropertiesPanel] Editing IoT");
                } else {
                    this.updatePropertiesPanel();
                    return true;
                }
            }
        }

        return false;
    }

    public setUniformScaleOnNode(node:ISceneNode, newValue:number) {
        node.scale.x = newValue;
        node.scale.y = newValue;
        node.scale.z = newValue;

        var x  = new PropertiesPanelUIData();
        x.currentNodeID = node.userData[UserDataProperties.id];
        x.propertiesPanelMode = this.mode;
        x.userData = Utils.SimpleClone(node.userData);
        x.nodePointer = node;
        x.hasRotateToggle = Utils.GetRotateToggleComponent(node) ? true : false;
        x.hasToggleComponent = Utils.GetModelToggleComponent(node) ? true : false;
        this.setPropertiesPanelUIData && this.setPropertiesPanelUIData(x);
    }

    public updatePropertiesPanelUIdata(node:ISceneNode|null = null, refresh:boolean = false):void {
        if(Simulation.instance.InitializationComplete()) {
            if(node == null) {
                this.getModelObjectsSidebar && this.getModelObjectsSidebar()?.then(
                    (value:boolean) => {
                        // console.log(value);
                        store.getState().home.openRadialMenu && PropertiesPanel.getDispatch(showRadialMenu(false));
                        if(value) {
                            //this.showModelObjectsSidebar(true);
                            var x  = new PropertiesPanelUIData();
                            try {
                                this.setPropertiesPanelUIData && this.setPropertiesPanelUIData(x);
                            } catch(e) {
                                console.error(e);
                                console.warn("[st] Someone broke setPropertiesPanelUIData, but we've mitigated the issue, by muting functionality of setPropertiesPanelUIData");
                            }
                        } else {
                        }
                    }
                )

                if(refresh) {
                    setTimeout(() => {
                        var x  = new PropertiesPanelUIData();
                        try{
                            this.setPropertiesPanelUIData && this.setPropertiesPanelUIData(x);
                        } catch(e) {
                            console.error(e)
                        }
                    }, 10);
                }
            } else {
                if(refresh) {
                    setTimeout(() => {
                        var x  = new PropertiesPanelUIData();
                        try{
                            this.setPropertiesPanelUIData && this.setPropertiesPanelUIData(x);
                        } catch(e) {
                            console.error(e)
                        }
                    }, 10);
                }

                setTimeout(() => {
                    var x  = new PropertiesPanelUIData();
                    x.currentNodeID = node.userData[UserDataProperties.id];
                    x.propertiesPanelMode = this.mode;
                    x.userData = Utils.SimpleClone(node.userData);
                    x.nodePointer = node;
                    x.hasRotateToggle = Utils.GetRotateToggleComponent(node) ? true : false;
                    x.hasToggleComponent = Utils.GetModelToggleComponent(node) ? true : false;
                    try{
                        this.setPropertiesPanelUIData && this.setPropertiesPanelUIData(x);
                    } catch(e){console.error(e)}

                }, 50);

                this.showModelObjectsSidebar && this.showModelObjectsSidebar(true);
            }
        }
    }

    private updatePropertiesPanel():void  {
        if(Simulation.instance.lastSelectedNode) {
            this.node = Simulation.instance.lastSelectedNode;
            this.changePropertiesPanelMode(PropertiesPanelMode.CanAdd);
            this.meshes = Utils.FindAllMeshesAndLineSegments(this.node);
            this.updatePropertiesPanelUIdata(this.node, true);
        } else {
            this.changePropertiesPanelMode(PropertiesPanelMode.None);
            this.meshes = null;
            this.updatePropertiesPanelUIdata(null);
        }
    }
/*
    private async setNodeUserDataWithReactMetaAppended(node:ISceneNode):Promise<void> {
        let appendedData = Utils.SimpleClone(node.userData);

        let newMetaReactUserData:{ [key: string]: any } = {};

        newMetaReactUserData[UserDataReactMeta.metaScale] = node.scale;

        Utils.ApplyAllPropertiesFromJSONtoJSON(appendedData, newMetaReactUserData);

        this.setNodeUserData(appendedData);
    }*/

    public checkIfNodeHasProperties(node: ISceneNode):boolean {
        if(node) {
            if(node.userData) {
                if(node.userData["hasPropertiesPanel"]) {
                    return true;
                }
            }
        }

        return false;

    }

    public updateClickEventAction(newNode:ISceneNode) {
        let tempPropertyEvent:EventActionOutcome = {} as EventActionOutcome;
        let tempList = this.node.userData[UserDataProperties.ClickEventActionList];

        if(this.mode === PropertiesPanelMode.Adding) {
            if(this.checkIfNodeHasProperties(newNode)) {

                let count = tempList.length;
                tempPropertyEvent = tempList[count - 1];
                tempPropertyEvent.objectID = newNode.userData["id"];
                tempList[count - 1] = tempPropertyEvent;

                this.node.userData[UserDataProperties.ClickEventActionList] = tempList;

                this.changePropertiesPanelMode(PropertiesPanelMode.ConsolidateAdd);
                setTimeout(() => Simulation.instance.selectNodeWithPropertiesUpdate(this.node), 500);
            }
        } else if(this.mode === PropertiesPanelMode.Editing) {
            if(this.checkIfNodeHasProperties(newNode)) {
                tempPropertyEvent = tempList[this.editIndex];
                tempPropertyEvent.objectID = newNode.userData["id"];
                tempList[this.editIndex] = tempPropertyEvent;

                this.node.userData[UserDataProperties.ClickEventActionList] = tempList;

                this.changePropertiesPanelMode(PropertiesPanelMode.ConsolidateAdd);
                setTimeout(() => Simulation.instance.selectNodeWithPropertiesUpdate(this.node), 100);
            }
        } else if(this.mode === PropertiesPanelMode.Editing_IOT) {

            if(this.checkIfNodeHasProperties(newNode)) {

                if(Utils.GetNestThermostatComponent(newNode)) {
                    if(newNode.userData[UserDataProperties.id] === this.node.userData[UserDataProperties.id]) {
                        showMessage("Can't select the same IoT!");
                        this.changePropertiesPanelMode(PropertiesPanelMode.CancelEditing_IOT);
                    } else {
                        if(this.iotDiffIndex === 1) {
                            this.node.userData[IoTSpecialProperties.diff1] = newNode.userData["id"];
                        } else if(this.iotDiffIndex === 2) {
                            this.node.userData[IoTSpecialProperties.diff2] = newNode.userData["id"];
                        }
                        this.changePropertiesPanelMode(PropertiesPanelMode.Consolidate_IOT);
                    }
                } else {
                    store.dispatch(fetchError("Only an IoT object can be selected for this!"));
                    this.changePropertiesPanelMode(PropertiesPanelMode.CancelEditing_IOT);
                }

                setTimeout(() => Simulation.instance.selectNodeWithPropertiesUpdate(this.node), 100);
            }
        }
    }

    /*
    private checkIfNodeHasValidComponents(node:ISceneNode):boolean {
        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.daeLoader':
                case 'mp.objLoader':
                case 'mp.fbxLoader':
                case 'mp.gltfLoader':
                    return true;
                    //console.log((component as any).pivot.children[0].children[0]);
                    //(((component as any).pivot.children[0].children[0] as Mesh).material as MeshBasicMaterial).visible = false;
            }
        }

        return false;
    }*/

    private removeLastClickEventAction():void {
        let tempArray;
        tempArray = this.node.userData[UserDataProperties.ClickEventActionList];
        if(!tempArray){
            tempArray = []
        }
        tempArray.pop();
        this.node.userData[UserDataProperties.ClickEventActionList] = tempArray;
        this.saveLastNode();
        this.updatePropertiesPanelUIdata(this.node);
    }

    public dropDownChangeEvents<T>(dropDownType:PropertiesPanelDropDownType,  assignment:T, index:number):void {
        let tempVariableValueActionPair:VariableValueActionPair;
        let tempVariableValueTriggerPair:VariableValueTriggerPair;

        switch(dropDownType) {
            case PropertiesPanelDropDownType.ActionTypeDropDown:
                {
                    let tempPropertyEvent: EventActionOutcome;
                    let tempList = this.node.userData[UserDataProperties.ClickEventActionList];
                    tempPropertyEvent = tempList[index];
                    tempPropertyEvent.actionType = assignment as any;
                    tempList[index] = tempPropertyEvent;

                    this.node.userData[UserDataProperties.ClickEventActionList] = tempList;
                }
                break;
            case PropertiesPanelDropDownType.TriggerActionTypeDropDown:
                {
                    let tempTriggerAction: TriggerActionOutcome;
                    let tempList = this.node.userData[UserDataProperties.TriggerActionList];
                    tempTriggerAction = tempList[index];
                    tempTriggerAction.actionType = assignment as any;
                    tempList[index] = tempTriggerAction;

                    this.node.userData[UserDataProperties.TriggerActionList] = tempList;
                }
                break;
            case PropertiesPanelDropDownType.SystemVariablesTriggerDropDown:
                {
                    let tempList = this.node.userData[UserDataProperties.varTriggers];
                    tempVariableValueTriggerPair = tempList[index];
                    let assignmentWithType = (assignment as unknown as VariableValueTriggerPair);

                    if (assignmentWithType.name.length > 0) {
                        tempVariableValueTriggerPair.name = assignmentWithType.name;
                    }

                    if (assignmentWithType.value.length > 0) {
                        tempVariableValueTriggerPair.value = assignmentWithType.value;
                    }

                    if (assignmentWithType.logic.length > 0) {
                        tempVariableValueTriggerPair.logic = assignmentWithType.logic;
                    }

                    tempList[index] = tempVariableValueTriggerPair;

                    this.node.userData[UserDataProperties.varTriggers] = tempList;
                }
                break;

            case PropertiesPanelDropDownType.SystemVariablesActionDropDown:
                {
                    let tempList = this.node.userData[UserDataProperties.varActions];
                    tempVariableValueActionPair = tempList[index];
                    let assignmentWithType2 = (assignment as unknown as VariableValueActionPair);

                    if (assignmentWithType2.name.length > 0) {
                        tempVariableValueActionPair.name = assignmentWithType2.name;
                    }

                    if (assignmentWithType2.value.length > 0) {
                        tempVariableValueActionPair.value = assignmentWithType2.value;
                    }

                    tempList[index] = tempVariableValueActionPair;

                    this.node.userData[UserDataProperties.varActions] = tempList;
                }
                break;
            case PropertiesPanelDropDownType.InputSource1_DropDown:
                this.node.userData[UserDataProperties.inputSource1] = assignment as any;
                break;
        }

        this.saveLastNode();
        this.updatePropertiesPanelUIdata(this.node);
    }

    public changeVarTriggers(newVariableName:string, index:number):void {

        this.saveLastNode();
        this.updatePropertiesPanelUIdata(this.node);
    }

    public deleteItem(dropDownType:PropertiesPanelDropDownType, index:number):void {

        let listType = "";

        switch(dropDownType) {
            case PropertiesPanelDropDownType.ActionTypeDropDown:
                listType = UserDataProperties.ClickEventActionList;
                break;
            case PropertiesPanelDropDownType.TriggerActionTypeDropDown:
                listType = UserDataProperties.TriggerActionList;
                break;
            case PropertiesPanelDropDownType.SystemVariablesTriggerDropDown:
                listType = UserDataProperties.varTriggers;
                break;
            case PropertiesPanelDropDownType.SystemVariablesActionDropDown:
                listType = UserDataProperties.varActions;
                break;
        }

        let tempList = this.node.userData[listType];
        if (index > -1) {
            tempList.splice(index, 1);
        }

        this.node.userData[listType] = tempList;
        this.saveLastNode();

        if(tempList.length < 1) {
            this.changePropertiesPanelMode(PropertiesPanelMode.CanAdd);
        }
        this.updatePropertiesPanelUIdata(this.node);
    }

    public addTriggerActionOutcome():void {
        let tempArray = [];

        if(!(UserDataProperties.TriggerActionList in this.node.userData)) {
            this.node.userData[UserDataProperties.TriggerActionList] = [];
        }

        tempArray = this.node.userData[UserDataProperties.TriggerActionList];

        let emptyTriggerAction:TriggerActionOutcome =
        {
            actionType: ActionType.Highlight,
            parameter: ""
        }

        tempArray.push(emptyTriggerAction);

        this.node.userData[UserDataProperties.TriggerActionList] = tempArray;
        this.saveLastNode();
        this.updatePropertiesPanelUIdata(this.node);
    }

    private addEmptyClickEventAction():void {
        let tempArray = [];
        tempArray = this.node.userData[UserDataProperties.ClickEventActionList];

        let emptyAction:EventActionOutcome =
        {
            actionType: ActionType.Hide,
            parameter: "",
            objectID:"",
        }

        tempArray.push(emptyAction);

        this.node.userData[UserDataProperties.ClickEventActionList] = tempArray;
        this.saveLastNode();
        this.updatePropertiesPanelUIdata(this.node);
    }

    public editNodeClickActionEvent(index:number):void {
        this.changePropertiesPanelMode(PropertiesPanelMode.Editing);
        this.editIndex = index;
        this.updatePropertiesPanelUIdata(this.node);
    }

    public editNodeIOTDiffSource(index:number):void {
        this.changePropertiesPanelMode(PropertiesPanelMode.Editing_IOT);
        this.iotDiffIndex = index;
        this.updatePropertiesPanelUIdata(this.node);
        GizmoTools?.instance?.hideTools();
    }

    public deleteNodeIOTDiffSource(index:number):void {
        this.iotDiffIndex = index;

        if(this.iotDiffIndex === 1) {
            this.node.userData[IoTSpecialProperties.diff1] = ""
        } else if(this.iotDiffIndex === 2) {
            this.node.userData[IoTSpecialProperties.diff2] = ""
        }
        this.iotDiffIndex = -1;
        this.updatePropertiesPanelUIdata(this.node);
        this.saveLastNode();
    }

    public updateVarList(x:VariableValueTriggerPair[], type:UserDataProperties) {
        this.node.userData[type] = Utils.SimpleClone(x);
        this.saveLastNode(true, this.node);
        this.updatePropertiesPanelUIdata(this.node);
    }


    // public async updateLogic(logic: Logic) {

        // SpaceView.getDispatch && SpaceView.getDispatch(updateStepLogic(logic));
        // alert(PropertiesPanel.getDispatch==undefined);
        // PropertiesPanel.getDispatch && PropertiesPanel.getDispatch(updateStepLogic(logic));


        //PropertiesPanel.getDispatch && PropertiesPanel.getDispatch(updateStepLogic(logic));
        // NodeStorage.storeLogicForStep(logic);

        // spaceViewDispatchHook && spaceViewDispatchHook(updateStepLogic(logic));
        // if(this.node){
        //     this.node.userData[UserDataProperties.logic] = Utils.SimpleClone(logic);
        //     this.saveLastNode(true, this.node);
        //     this.updatePropertiesPanelUIdata(this.node);
        // }

    // }

    public changeNameOfNode(param:ChangeTextPassThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {
            tempNode.userData[UserDataProperties.nameToShow] = param.newText;
            //this.saveLastNode(true, tempNode);
            if(param.updateUI) {
                this.updatePropertiesPanelUIdata(tempNode);
            }
        } else {
            return false;
        }

        return true;
    }

    public changeParameterOfEventAction(param:ChangeGenericActionOutcomeTextPassThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {

            let tempPropertyEvent: EventActionOutcome;
            let tempList = tempNode.userData[UserDataProperties.ClickEventActionList];
            tempPropertyEvent = tempList[param.index] as EventActionOutcome;
            tempPropertyEvent.parameter = param.newValue;
            tempList[param.index] = tempPropertyEvent;

            tempNode.userData[UserDataProperties.ClickEventActionList] = tempList;

            if(param.updateUI) {
                this.updatePropertiesPanelUIdata(tempNode);
            }
        } else {
            return false;
        }

        return true;
    }

    public changeParameterOfTriggerAction(param:ChangeGenericActionOutcomeTextPassThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {

            let tempPropertyEvent: TriggerActionOutcome;
            let tempList = tempNode.userData[UserDataProperties.TriggerActionList];
            tempPropertyEvent = tempList[param.index] as TriggerActionOutcome;
            tempPropertyEvent.parameter = param.newValue;
            tempList[param.index] = tempPropertyEvent;

            tempNode.userData[UserDataProperties.TriggerActionList] = tempList;

            if(param.updateUI) {
                this.updatePropertiesPanelUIdata(tempNode);
            }
        } else {
            return false;
        }

        return true;
    }

    //public changeBooleanPropertyOfNode(param:)

    public changeModelURLOfNode(param:ChangeTextPassThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {
            tempNode.userData[UserDataProperties.newModelURL] = param.newText;
            //this.saveLastNode(true, tempNode);
            if(param.updateUI) {
                this.updatePropertiesPanelUIdata(tempNode);
            }
        } else {
            return false;
        }

        return true;
    }

    public changeImageTextureSource(param:ChangeTextPassThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {
            tempNode.userData[UserDataProperties.textureSource] = param.newText;
            ((tempNode.components[0] as any).instance as ImageRenderer).inputs.textureSource = tempNode.userData[UserDataProperties.textureSource];
            //this.saveLastNode(true, tempNode);
            if(param.updateUI) {
                this.updatePropertiesPanelUIdata(tempNode);
            }
        } else {
            return false;
        }

        return true;
    }

    public changeBorderRadius(param:ChangeTextPassThrough):boolean {
        // let tempNode = this.node;
        // if(param.passNode != null)
        // {
        //     tempNode = param.passNode;
        // }

        // if(tempNode) {
        //     let finalBorderRadius = Number.parseInt(param.newText);

        //     if(Number.isNaN(finalBorderRadius)) {
        //         finalBorderRadius = 0;
        //     } else {
        //         finalBorderRadius = Math.min(finalBorderRadius, 550);
        //     }

        //     tempNode.userData[UserDataProperties.borderRadius] = finalBorderRadius;
        //     ((tempNode.components[0] as any).instance as ImageRenderer).inputs.borderRadius = finalBorderRadius;
        //     //this.saveLastNode(true, tempNode);
        //     if(param.updateUI) {
        //         this.updatePropertiesPanelUIdata(tempNode);
        //     }
        // } else {
        //     return false;
        // }

        return true;
    }

    public changeBorderSize(param:ChangeTextPassThrough):boolean {
        // let tempNode = this.node;
        // if(param.passNode != null)
        // {
        //     tempNode = param.passNode;
        // }

        // if(tempNode) {
        //     let finalBorderSize = Number.parseInt(param.newText);

        //     if(Number.isNaN(finalBorderSize)) {
        //         finalBorderSize = 0;
        //     }

        //     tempNode.userData[UserDataProperties.borderSize] = finalBorderSize;
        //     ((tempNode.components[0] as any).instance as ImageRenderer).inputs.borderSize = finalBorderSize;
        //     //this.saveLastNode(true, tempNode);
        //     if(param.updateUI) {
        //         this.updatePropertiesPanelUIdata(tempNode);
        //     }
        // } else {
        //     return false;
        // }

        return true;
    }

    public changeRotationAxisOfNodeWithParam(param:ChangeTextPassWithParamThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {

            let vectorComponent:number|string = Number.parseFloat(param.newText);

            if(Number.isNaN(vectorComponent)) {
                return false;
            } else {

                if(vectorComponent == 0) {
                    if(param.newText.length > 1) {
                        vectorComponent = param.newText as string;
                    }
                }

                if(UserDataProperties.rotationAxis in tempNode.userData) {

                } else {
                    tempNode.userData[UserDataProperties.rotationAxis] = { "x": 0, "y": 1, "z": 0 };
                }

                if(param.param === 'x') {
                    tempNode.userData[UserDataProperties.rotationAxis].x = vectorComponent;
                    (tempNode.components[1] as any).instance.inputs.rotationAxis.x = Number.parseFloat(tempNode.userData[UserDataProperties.rotationAxis].x);
                    //((tempNode.components[1] as any).instance as RotateToggle).prepareClips();
                } else if(param.param === 'y') {
                    tempNode.userData[UserDataProperties.rotationAxis].y = vectorComponent;
                    (tempNode.components[1] as any).instance.inputs.rotationAxis.y = Number.parseFloat(tempNode.userData[UserDataProperties.rotationAxis].y);
                    //((tempNode.components[1] as any).instance as RotateToggle).prepareClips();
                } else if(param.param === 'z') {
                    tempNode.userData[UserDataProperties.rotationAxis].z = vectorComponent;
                    (tempNode.components[1] as any).instance.inputs.rotationAxis.z = Number.parseFloat(tempNode.userData[UserDataProperties.rotationAxis].z);
                    //((tempNode.components[1] as any).instance as RotateToggle).prepareClips();
                }
            }

            //this.saveLastNode(true, tempNode);
            if(param.updateUI) {
                this.updatePropertiesPanelUIdata(tempNode);
            }
        } else {
            return false;
        }

        return true;
    }

    public changeRotationRangesOfNodeWithParam(param:ChangeTextPassThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {

            tempNode.userData[UserDataProperties.rotationRange] = param.newText;
            (tempNode.components[1] as any).instance.inputs.rotationRange = param.newText.split(",");

            //this.saveLastNode(true, tempNode);
            if(param.updateUI) {
                this.updatePropertiesPanelUIdata(tempNode);
            }
        } else {
            return false;
        }

        return true;
    }

    public changeLocalPositionOfNodeWithParam(param:ChangeTextPassWithParamThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {

            let vectorComponent:number|string = Number.parseFloat(param.newText);

            if(Number.isNaN(vectorComponent)) {
                return false;
            } else {

                if(vectorComponent == 0) {
                    if(param.newText.length > 1) {
                        vectorComponent = param.newText as string;
                    }
                }

                if(UserDataProperties.localPosition in tempNode.userData) {

                } else {
                    tempNode.userData[UserDataProperties.localPosition] = { "x": 0, "y": 0, "z": 0 };
                }

                if(param.param === 'x') {
                    tempNode.userData[UserDataProperties.localPosition].x = vectorComponent;
                    (tempNode.components[0] as any).instance.inputs.localPosition.x = Number.parseFloat(tempNode.userData[UserDataProperties.localPosition].x);
                } else if(param.param === 'y') {
                    tempNode.userData[UserDataProperties.localPosition].y = vectorComponent;
                    (tempNode.components[0] as any).instance.inputs.localPosition.y = Number.parseFloat(tempNode.userData[UserDataProperties.localPosition].y);
                } else if(param.param === 'z') {
                    tempNode.userData[UserDataProperties.localPosition].z = vectorComponent;
                    (tempNode.components[0] as any).instance.inputs.localPosition.z = Number.parseFloat(tempNode.userData[UserDataProperties.localPosition].z);
                }
            }

            //this.saveLastNode(true, tempNode);
            if(param.updateUI) {
                this.updatePropertiesPanelUIdata(tempNode);
            }
        } else {
            return false;
        }

        return true;
    }

    public changeTextOfNode(param:ChangeTextPanelTextPassThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }
        if(tempNode) {
            if(UserDataProperties.type in tempNode.userData) {
                switch(tempNode.userData[UserDataProperties.type]) {
                    case UserDataTypes.textPanel:
                        let textComponents = Utils.GetAllCanvasTextComponents(tempNode);

                        if(textComponents) {
                            if(param.newText != null) {
                                textComponents[0].inputs.text = param.newText;
                                tempNode.userData[UserDataProperties.textProperty] = param.newText;
                            }

                            if(param.fontSize != null) {
                                textComponents[0].inputs.fontSize = param.fontSize;
                                tempNode.userData[UserDataProperties.fontSize] = param.fontSize;
                            } else {
                                //do default value if key doesn't exist (upgrading property)
                                if(!(UserDataProperties.fontSize in tempNode.userData)) {
                                    console.warn("fontSize property doesn't exist, probably a legacy node: upgrading node")
                                    textComponents[0].inputs.fontSize = 30;
                                    tempNode.userData[UserDataProperties.fontSize] = 30;
                                    //this.saveLastNode(true, tempNode);
                                }
                            }

                            if(param.updateUI) {
                                this.updatePropertiesPanelUIdata(tempNode);
                            }
                        }
                        return true;
                        break;
                    default:
                        return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }

        return true;
    }

    public setBooleanPropertyOfNode(param:ChangeBooleanPropertyPassThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {
            if(param.propertyName === UserDataProperties.hasBorderProperty) {
                if(tempNode.userData[UserDataProperties.type] === UserDataTypes.boundedBox ||
                   tempNode.userData[UserDataProperties.type] === CompatabilityUserDataTypes.boundedBox) {
                    tempNode.userData[UserDataProperties.type] = UserDataTypes.boundedBox;
                    let boundedBoxComponent = Utils.GetHighlightBoxComponent(tempNode);
                    //@ts-ignore
                    boundedBoxComponent.inputs.hasBorder = param.value;
                    tempNode.userData[UserDataProperties.hasBorderProperty] = param.value;
                } else {
                    return false;
                }
            } else {
                tempNode.userData[param.propertyName] = param.value;

                if(param.propertyName === UserDataProperties.alwaysShow) {
                    if(tempNode.userData[UserDataProperties.alwaysShow]) {
                        Utils.EnableCollidersOnNode(tempNode);
                        Utils.SetVisibility(true, tempNode);
                    }
                }
            }
            /*
            if(UserDataProperties.type in tempNode.userData) {
                switch(tempNode.userData[UserDataProperties.type]) {
                    case UserDataTypes.boundexBox:
                        let boundedBoxComponent = Utils.GetHighlightBoxComponent(tempNode);

                        //@ts-ignore
                        boundedBoxComponent.inputs.hasBorder = param.value;

                        tempNode.userData[UserDataProperties.hasBorderProperty] = param.value;
                        break;
                }
            } else {
                return false;
            }*/
        } else {
            return false;
        }

        if(param.updateUI) {
            //TODO: I actually donno what to do here
        }

        return true;
    }

    public changeColorOfNode(param:ChangeNodeColorPassThrough):boolean {
        let tempNode = this.node;
        if(param.passNode != null)
        {
            tempNode = param.passNode;
        }

        if(tempNode) {
            if(UserDataProperties.type in tempNode.userData) {
                switch(tempNode.userData[UserDataProperties.type]) {
                    case UserDataTypes.boundedBox:
                    case CompatabilityUserDataTypes.boundedBox:
                        let boundedBoxComponent = Utils.GetHighlightBoxComponent(tempNode);

                        if(param.borderColor) {
                            //@ts-ignore
                            boundedBoxComponent.inputs.lineColor = param.colorHex;
                            //@ts-ignore
                            boundedBoxComponent.inputs.lineOpacity = param.alpha;

                            tempNode.userData[UserDataProperties.borderColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);
                        } else {
                            //@ts-ignore
                            boundedBoxComponent.inputs.color = param.colorHex;
                            //@ts-ignore
                            boundedBoxComponent.inputs.opacity = param.alpha;

                            tempNode.userData[UserDataProperties.customColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);
                        }

                        tempNode.userData[UserDataProperties.type] = UserDataTypes.boundedBox;
                        break;
                    case UserDataTypes.highlightBorder:
                        let planeRendererComponent = Utils.GetPlaneRendererComponent(tempNode);

                        //@ts-ignore
                        planeRendererComponent.inputs.color = param.colorHex;
                        //@ts-ignore
                        planeRendererComponent.inputs.opacity = param.alpha;

                        tempNode.userData[UserDataProperties.customColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);

                        break;
                    case UserDataTypes.arrow:
                        var meshes = Utils.FindAllMeshesAndLineSegments(tempNode);

                        if(!meshes) {
                            return false;
                        } else {
                            Utils.SetMeshesColor(meshes, param.colorHex);
                        }
                        tempNode.userData[UserDataProperties.customColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);
                        break;
                    case UserDataTypes.textPanel:

                        if(param.borderColor) {
                            var textPanelPlane = (((tempNode as any).components[1] as any).instance as PlaneRenderer);

                            textPanelPlane.inputs.color = parseInt(param.colorHex.substring(1, param.colorHex.length), 16);
                            textPanelPlane.inputs.opacity = param.alpha;

                            tempNode.userData[UserDataProperties.borderColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);
                        } else {
                            var textPanelPlane = (((tempNode as any).components[0] as any).instance as PlaneRenderer);

                            textPanelPlane.inputs.color = parseInt(param.colorHex.substring(1, param.colorHex.length), 16);
                            textPanelPlane.inputs.opacity = param.alpha;

                            if(param.alpha < 0.95) {
                                textPanelPlane.inputs.transparent = true;
                            } else {
                                textPanelPlane.inputs.transparent = false;
                            }

                            tempNode.userData[UserDataProperties.customColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);
                        }
                        break;
                    case UserDataTypes.thermostatNest:
                        var nestThermostat = (((tempNode as any).components[3] as any).instance as NestThermostat);
                        param.alpha = 1.0;

                        if(param.borderColor) {
                            nestThermostat.inputs.strokeColor = param.colorHex;
                            tempNode.userData[UserDataProperties.borderColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);
                        } else {
                            nestThermostat.inputs.color = param.colorHex;
                            tempNode.userData[UserDataProperties.customColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);
                        }

                        break;
                    case UserDataTypes.imageRenderer:
                        var tempImageRenderer = (((tempNode as any).components[0] as any).instance as ImageRenderer);


                        if(param.borderColor) {
                            // tempImageRenderer.inputs.borderColor = parseInt(param.colorHex.substring(1, param.colorHex.length), 16);
                            // tempImageRenderer.inputs.borderOpacity = param.alpha;

                            // tempNode.userData[UserDataProperties.borderColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);
                        } else {
                            tempImageRenderer.inputs.color = parseInt(param.colorHex.substring(1, param.colorHex.length), 16);
                            tempImageRenderer.inputs.opacity = param.alpha;

                            tempNode.userData[UserDataProperties.customColorProperty] = Utils.GetHexWithAlpha(param.colorHex, param.alpha);
                        }

                        break;
                    default:
                        return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }

        if(param.updateUI) {
            this.updatePropertiesPanelUIdata(tempNode);
        }
        return true;
    }

    public changePropertiesPanelMode(mode:PropertiesPanelMode/*, callback:(() => void)|null = null*/) {
        if(this.mode === PropertiesPanelMode.None) {
            if(mode === PropertiesPanelMode.CanAdd) {
                this.mode = mode;
            }
        } else if(this.mode === PropertiesPanelMode.CanAdd) {
            if(mode === PropertiesPanelMode.Adding) {
                this.mode = mode;
                this.addEmptyClickEventAction();
                GizmoTools?.instance?.hideTools();
            } else if(mode === PropertiesPanelMode.Editing) {
                this.mode = mode;
                GizmoTools?.instance?.hideTools();
            } else if(mode === PropertiesPanelMode.Editing_IOT) {
                this.mode = mode;
                GizmoTools?.instance?.hideTools();
            } else if(mode === PropertiesPanelMode.None) {
                this.mode = mode;
            } else if(mode === PropertiesPanelMode.CanAdd) {
                this.mode = mode;
            }
        } else if(this.mode === PropertiesPanelMode.Adding) {
            //Adding process was cancelled midway
            if(mode === PropertiesPanelMode.CanAdd) {
                this.mode = mode;
                this.removeLastClickEventAction();
                GizmoTools?.instance?.setPosition(Simulation.instance.gizmoUIData, null, null, null);
            } else if(mode === PropertiesPanelMode.ConsolidateAdd) {
                this.mode = PropertiesPanelMode.CanAdd;
                this.saveLastNode();
            }
        } else if(this.mode === PropertiesPanelMode.Editing) {
            //Adding process was cancelled midway
            if(mode === PropertiesPanelMode.CanAdd) {
                this.mode = mode;
                //this.removeLastClickEventAction();
                GizmoTools?.instance?.setPosition(Simulation.instance.gizmoUIData, null, null, null);
                this.editIndex = -1;
                this.updatePropertiesPanelUIdata(this.node);
            } else if(mode === PropertiesPanelMode.ConsolidateAdd) {
                this.mode = PropertiesPanelMode.CanAdd;
                this.saveLastNode();
            }
        } else if(this.mode === PropertiesPanelMode.Editing_IOT) {
            //Adding process was cancelled midway
            if(mode === PropertiesPanelMode.Consolidate_IOT) {
                this.mode = PropertiesPanelMode.CanAdd;
                this.saveLastNode();
            } else if(mode === PropertiesPanelMode.CancelEditing_IOT) {
                this.mode = PropertiesPanelMode.CanAdd;
                //this.iotDiffIndex = 1;
                //GizmoTools?.instance?.hideTools();
            }
        }

    }

    public hidePropertiesPanel():void {
        //this.setShowPropertiesPanel(false);
        //PropertiesPanelUI.instance.hide();
        this.changePropertiesPanelMode(PropertiesPanelMode.None);
        this.updatePropertiesPanelUIdata(null, true);
    }

    public getPropertiesPanelMode():PropertiesPanelMode {
        return this.mode;
    }

    public saveLastNode(delay:boolean = false, forceSpecificNode:ISceneNode|null = null):void {
        let localTargetNodeToSave = this.node;
        if(forceSpecificNode) {
            localTargetNodeToSave = forceSpecificNode;
        }
        if(delay) {
            if(this.nodeSaveQueueScheduler) {
                this.nodeSaveQueueScheduler.addQueueElement(localTargetNodeToSave, true);
            } else {
                console.error("Critical: Delayed Save Scheduler seems to be broken")
            }
        } else {
            if(localTargetNodeToSave) {
                NodeStorage.storeNode(localTargetNodeToSave);
            }
        }
    }
    private node:ISceneNode;
    private mode:PropertiesPanelMode;
    private editIndex:number;
    private iotDiffIndex:number;

    public setPropertiesPanelUIData:React.Dispatch<React.SetStateAction<PropertiesPanelUIData>>;
    public showModelObjectsSidebar:React.Dispatch<React.SetStateAction<boolean>>;
    public getModelObjectsSidebar:() => Promise<boolean>;

    //public setPropertiesPanelUIData:React.Dispatch<React.SetStateAction<PropertiesPanelUIData>>;

    private meshes:Object3D[]|null;
    private nodeSaveQueueScheduler:QueueScheduler<ISceneNode>;
    public colorSaveQueueScheduler:QueueScheduler<ChangeNodeColorPassThrough>;

    constructor() {
        super();
        this.mode = PropertiesPanelMode.None;
        this.editIndex = -1;
        this.iotDiffIndex = -1;
        this.nodeSaveQueueScheduler = new QueueScheduler<ISceneNode>(NodeStorage.storeNode, 1500);
        this.colorSaveQueueScheduler = new QueueScheduler<ChangeNodeColorPassThrough>(this.changeColorOfNode.bind(this), 10);
        //this.iotInputSourceSaveQueueScheduler = new QueueScheduler<string>(this.changeColorOfNode.bind(this), 10);
    }
}

export default PropertiesPanel;
