import TextFormatRoundedIcon from '@mui/icons-material/TextFormatRounded';

import VideoCameraFrontRoundedIcon from '@mui/icons-material/VideoCameraFrontRounded';
import { CustomLeverIcon, CustomPowerOffIcon, CustomPowerOnIcon, CustomGaugeIcon, BoxHighlightIcon, CustomArrowIcon, BorderHighlightIcon, CustomSwitchIcon, CustomDialIcon } from '@crema/core/Icons/CustomIcons';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import WaterfallChartRoundedIcon from '@mui/icons-material/WaterfallChartRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import store from 'redux/store';


export const modelMap = new Map();

export const OffButtonModel = {
        "version": "1.0",
        "icon": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Off Black/icon_off_Black.jpg",
        "iconMui": CustomPowerOffIcon,
        "payload": {
            "objects": [{
                "name": "Power Off",
                "position": { "x": 0, "y": 0, "z": 0 },
                "rotation": { "x": 0, "y": 0, "z": 0 },
                "scale": { "x": 1, "y": 1, "z": 1 },
                "userData": {
                    "hasPropertiesPanel": true,
                    "alwaysShow": false,
                    "initialPlacementStyle": "thermostat",
                },
                "components": [
                    {
                        "type": "mp.gltfLoader",
                        "inputs": {
                            "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Off Black/Off_Black.gltf",
                            "localScale": { "x": 1, "y": 1, "z": 1 },
                            "localRotation": { "x": -90, "y": 0, "z": 180 },
                            "localPosition": { "x": 0, "y": 0, "z": 0 }
                        }
                    },
                ],

            }]
        },

}

export const OnButtonModel = {
    "version": "1.0",
    "icon": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/On Black/icon_on_Black.jpg",
    iconMui: CustomPowerOnIcon,
    "payload": {
        "objects": [{
            "name": "Power On",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "hasPropertiesPanel": true,
                "alwaysShow": false,
                "initialPlacementStyle": "thermostat",
            },
            "components": [
                {
                    "type": "mp.gltfLoader",
                    "inputs": {
                        "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/On Black/On_Black.gltf",
                        "localScale": { "x": 1, "y": 1, "z": 1 },
                        "localRotation": { "x": -90, "y": 0, "z": 180 },
                        "localPosition": { "x": 0, "y": 0, "z": 0 }
                    }
                },
            ],

        }]
    }
}

export const OnOffButtonModel = {
    "version": "1.0",
    "icon": "https://image.flaticon.com/icons/png/512/2885/2885976.png",
    "iconMui": CustomSwitchIcon,
    "payload": {
        "objects": [{
            "name": "On Off Button",
            "position": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": {
                "x": 1,
                "y": 1,
                "z": 1
            },
            "userData": {
                "type": "onOffButton",
                "hasPropertiesPanel": true,
                "initialPlacementStyle": "offsetFromNormal",
                "distanceForNormalOffset": 0.5,
                "alwaysShow": false,
                "buttons": { "scalePatch": "Patch Size" },
                "disableScale": false,
                "overrideUserData" : {
                    "disableScale": false
                }
            },
            "components": [
                {
                    "type": "mp.onOffButton",
                    "inputs": {
                        "rotationAxis": {
                            "x": 0,
                            "y": 0,
                            "z": 1
                        }
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": true,
                        "INTERACTION.DRAG": true,
                        "INTERACTION.DRAG_END": true,
                        "INTERACTION.DRAG_BEGIN": true
                    }
                }
            ]

        }]
    }
}

export const ArrowModel = {
    "version": "1.0",
    "icon": "https://img.flaticon.com/icons/png/512/52/52692.png?size=1200x630f&pad=10,10,10,10&ext=png&bg=FFFFFFFF",
    iconMui: CustomArrowIcon,
    "payload": {
        "objects": [{
            "name": "Arrow",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 0.25, "y": 0.5, "z": 0.25 },
            "userData": {
                "type": "arrow",
                "hasPropertiesPanel": true,
                "hasColorProperty": true,
                "initialPlacementStyle": "arrow",
                "alwaysShow": false,
            },
            "components": [
                {
                    "type": "mp.gltfLoader",
                    "inputs": {
                        "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Arrow%20Large/Arrow_New.gltf",
                        "localScale": { "x": 2.5, "y": 2.5, "z": 3.75 },
                        "localRotation": { "x": 0, "y": 0, "z": 0 },
                        "localPosition": { "x": 0, "y": 0, "z": 0 }
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": true,
                        "INTERACTION.DRAG": true,
                        "INTERACTION.DRAG_END": true,
                        "INTERACTION.DRAG_BEGIN": true
                    }
                },
                {
                    "type": "mp.objLoader",
                    "inputs": {
                    "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj",
                    "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
                    "localRotation": { "x": 0, "y": 0, "z": 0 },
                    "localPosition": { "x": 0, "y": 0, "z": 0 }
                    },
                    "events": {
                    "INTERACTION.CLICK": false,
                    "INTERACTION.HOVER": false,
                    "INTERACTION.DRAG": false
                    }
                },
                {
                    "type": "mp.loadingIndicator",
                    "inputs": {
                    "size": { "x": 1, "y": 1, "z": 1 }
                    }
                }
            ],
            "bindings": [
              [2, "logo", 1, "objectRoot"]
            ]
        }]
    }
}

export const BoxHighlightModel = {
    "version": "1.0",
    "icon": "https://static.thenounproject.com/png/438176-200.png",
    iconMui: BoxHighlightIcon,
    "payload": {
        "objects": [{
            "name": "Box Highlight",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "type": "boundexBox",
                "hasPropertiesPanel": true,
                "hasColorProperty": true,
                "hasBorderProperty": true,
                "initialPlacementStyle": "offsetFromNormal",
                "distanceForNormalOffset": 0.55,
                "alwaysShow": false,
            },
            "components": [
                {
                    "type": "mp.highlightBox",
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": false,
                        "INTERACTION.DRAG": false
                    }
                }
            ]
        }]
    }
}

export const BorderHighlightModel = {
    "version": "1.0",
    "icon": "https://w7.pngwing.com/pngs/929/75/png-transparent-squared-green-frame-square-text-area-frame-pattern-simple-square-frame-border-frame-blue-thumbnail.png",
    iconMui: BorderHighlightIcon,
    "payload": {
        "objects": [{
            "name": "Border Highlight",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "type": "highlightBorder",
                "hasPropertiesPanel": true,
                "hasColorProperty": true,
                "initialPlacementStyle": "plane",
                "alwaysShow": false,
            },
            "components": [
                {
                    "id": 0,
                    "type": "mp.planeRenderer",
                    "inputs": {
                        "transparent": true,
                        "opacity": 0.5
                    }
                },
                {
                    "id": 1,
                    "type": "mp.canvasRenderer",
                    "inputs": {
                        "textureRes": {
                            "w": 1024,
                            "h": 1024
                        }
                    }
                },
                {
                    "id": 2,
                    "type": "mp.canvasBorder",
                    "inputs": {
                        "position": {
                            "x": 0,
                            "y": 0
                        },
                        "size": {
                            "h": 1024,
                            "w": 1024
                        },
                        "lineWidth": 100,
                        "radius": 50,
                        "color": "white"
                    }
                }
            ], "bindings": [
                [1, "painter", 2, "painter"],
                [0, "texture", 1, "texture"]


            ],
            "events": [
                [1, "repaint", 2, "repaint"]


            ]
        }]
    }
}

export const IoTModel = {
    "version": "1.0",
    "icon": "https://www.pinclipart.com/picdir/middle/85-854633_iot-icon-clipart.png",
    iconMui: CustomGaugeIcon,
    "payload": {
        "objects": [{
            "name": "IoT",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "type": "thermostatNest",
                "hasPropertiesPanel": true,
                "initialPlacementStyle": "thermostat",
                "hasColorProperty": true,
                "hasBorderProperty": true,
                "inputSource1": "None",
                "alwaysShow": false,
                "diff1": "",
                "diff2": "",
            },
            "components": [
                {
                    "type": "mp.daeLoader",
                    "inputs": {
                        "url": "https://storage.googleapis.com/st-models/3d/nest.dae",
                        "localScale": { "x": 0.04, "y": 0.04, "z": 0.04 },
                        "localRotation": { "x": -90, "y": 0, "z": 180 },
                        "localPosition": { "x": 0, "y": 0, "z": 0 }
                    }
                },
                {
                    "type": "mp.planeRenderer",
                    "inputs": {
                        "localPosition": { "x": 0, "y": 0.15, "z": 0.75 },
                        "localRotation": { "x": -90, "y": 0, "z": 180 }
                    }
                },
                {
                    "type": "mp.canvasRenderer",
                    "inputs": {
                        "textureRes": {
                            "w": 1024,
                            "h": 1024
                        }
                    }
                },
                {
                    "type": "mp.nestThermostat"
                },
                {
                    "type": "mp.objLoader",
                    "inputs": {
                    "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj",
                    "localScale": { "x": 0.005, "y": 0.005, "z": 0.005 },
                    "localRotation": { "x": 0, "y": 0, "z": 0 },
                    "localPosition": { "x": 0, "y": 0, "z": 0 }
                    },
                    "events": {
                    "INTERACTION.CLICK": false,
                    "INTERACTION.HOVER": false,
                    "INTERACTION.DRAG": false
                    }
                },
                {
                    "type": "mp.loadingIndicator",
                    "inputs": {
                    "size": { "x": 1, "y": 1, "z": 1 }
                    }
                }
            ],
            "bindings": [
                [3, "loadingState", 0, "loadingState"],
                [1, "texture", 2, "texture"],
                [2, "painter", 3, "painter"],
                [3, "texture", 2, "texture"],
                [1, "visible", 3, "visible"],
                [5, "logo", 4, "objectRoot"]
            ],
            "events": [
                [3, "hover", 0, "INTERACTION.HOVER"],
                [3, "hover", 1, "INTERACTION.HOVER"],
                [2, "repaint", 3, "repaint"]
            ]
        }]
    }
}

export const TextBoxModel = {
    "version": "1.0",
    "icon": "https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png",
    iconMui: TextFormatRoundedIcon,
    "payload": {
        "objects": [{
            "name": "Text Box",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 0.45, "z": 1 },
            "userData": {
                "type": "textPanel",
                "hasPropertiesPanel": true,
                "textProperty": "",
                "hasColorProperty": true,
                "hasBorderProperty": true,
                "initialPlacementStyle": "plane",
                "fontSize": 50,
                "alwaysShow": false,
                "width": 1,
                "height": 1
            },
            "components": [
                {
                    "id": 0,
                    "type": "mp.planeRenderer",
                    "inputs": {
                        "localScale": { "x":1, "y": 1, "z": 1 },
                        "transparent": false,
                        "opacity": 1
                    }
                },
                {
                    "id": 1,
                    "type": "mp.planeRenderer",
                    "inputs": {
                        "localScale": { "x":1, "y": 1, "z": 1 },
                        "transparent": true,
                        "opacity": 1
                    }
                },
                {
                    "id": 2,
                    "type": "mp.canvasRenderer",
                    "inputs": {
                        "textureRes": {
                            "w": 1024,
                            "h": 1024
                        }
                    }
                },
                {
                    "id": 3,
                    "type": "mp.canvasBorder",
                    "inputs": {
                        "position": {
                            "x": 0,
                            "y": 0
                        },
                        "size": {
                            "w": 1024,
                            "h": 1024,
                        },
                        "radius": 5,
                        "lineWidth": 20
                    }
                },
                {
                    "id": 4,
                    "type": "mp.planeRenderer",
                    "inputs": {
                        "invertScale": false,
                        "transparent": true,
                        "opacity": 1,
                        "polygonOffset": true,
                        "polygonOffsetFactor": -100,
                        "color": "white"
                    }
                },
                {
                    "id": 5,
                    "type": "mp.canvasRenderer",
                    "inputs": {
                        "textureRes": {
                            "w": 512,
                            "h": 512
                        }
                    }
                },
                {
                    "id": 6,
                    "type": "mp.canvasText",
                    "inputs": {
                        "size": {
                            "w": 1024,
                            "h": 1024
                        },
                        "text": "Sample text",
                        "fontStyle": "normal bold",
                        "fontSize": 80,
                        "fontName": "sans-serif",
                        "position": { "x": 30, "y": 0, "z": 0 },
                        "textWidth": 840
                    }
                },
                {
                    "id": 7,
                    "type": "mp.toggleState",
                    "inputs": {
                        "initialState": false
                    }
                }
            ], "bindings": [
                [1, "texture", 2, "texture"],
                [2, "painter", 3, "painter"],
                [5, "painter", 6, "painter"],
                [4, "texture", 5, "texture"],
                [4, "visible", 7, "negated"]

            ],
            "events": [
                [7, "toggle", 1, "INTERACTION.CLICK"],
                [5, "repaint", 6, "textUpdate"]
            ]
        }]
    }
}

export const WebcamModel = {
    "version": "1.0",
    "icon": "https://png.pngtree.com/png-vector/20191011/ourmid/pngtree-webcam-icon-png-image_1817559.jpg",
    "iconMui": VideoCameraFrontRoundedIcon,
    "payload": {
        "objects": [{
            "name": "Webcam",
            "position": { "x": -4.65, "y": 1.5, "z": -0.1 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1.2, "y": 1.2, "z": 1.2 },
            "userData": {
                "type": "webcam",
                "hasPropertiesPanel": true,
                "hasColorProperty": false,
                "initialPlacementStyle": "plane",
                "alwaysShow": false,
            },
            "components": [

                {
                    "id": 0,
                    "type": "mp.planeRenderer",
                    "inputs": {
                        "transparent": false,
                        "borderRadius": 50
                    }
                },
                {
                    "id": 1,
                    "type": "mp.videoRenderer"
                },
                {
                    "id": 2,
                    "type": "mp.videoStreamCapture",
                    "inputs": {
                        "deviceId": 0
                    }
                },
                {
                    "id": 3,
                    "type": "mp.toggleState",
                    "inputs": {
                        "initialState": false
                    }
                },

                {
                    "id": 4,
                    "type": "mp.planeRenderer",
                    "inputs": {
                        "transparent": true,
                        "opacity": 0.8,
                        "borderRadius": 50
                    }
                },
                {
                    "id": 5,
                    "type": "mp.canvasRenderer",
                    "inputs": {
                        "textureRes": {
                            "w": 1024,
                            "h": 1024
                        }
                    }
                },
                {
                    "id": 6,
                    "type": "mp.canvasBorder",
                    "inputs": {
                        "position": {
                            "x": 0,
                            "y": 0
                        },
                        "size": {
                            "h": 1024,
                            "w": 1024
                        },
                        "radius": 50
                    }
                },

                {
                    "id": 7,
                    "type": "mp.planeRenderer",
                    "inputs": {
                        "aspect": 1.77777777778,
                        "transparent": true,
                        "opacity": 0.8,
                        "polygonOffset": true,
                        "polygonOffsetFactor": -2
                    }
                },
                {
                    "id": 8,
                    "type": "mp.canvasRenderer",
                    "inputs": {
                        "textureRes": {
                            "w": 256,
                            "h": 256
                        }
                    }
                },
                {
                    "id": 9,
                    "type": "mp.canvasText",
                    "inputs": {
                        "size": {
                            "h": 256,
                            "w": 256
                        },
                        "text": "Click to enable webcam.",
                        "fontStyle": "normal bold",
                        "fontSize": 30,
                        "fontName": "sans-serif",
                        "position": { "x": 70, "y": 70, "z": 0 },
                        "textWidth": 200,
                        "fontColor": "#000000",
                    }
                }
            ],
            "bindings": [
                [1, "src", 2, "stream"],
                [0, "texture", 1, "texture"],
                [2, "enabled", 3, "state"],
                [0, "aspect", 2, "aspect"],
                [5, "painter", 6, "painter"],
                [4, "texture", 5, "texture"],
                [4, "aspect", 2, "aspect"],
                [8, "painter", 9, "painter"],
                [7, "texture", 8, "texture"],
                [7, "visible", 3, "negated"],
                [7, "aspect", 2, "aspect"]
            ],
            "events": [
                [3, "toggle", 0, "INTERACTION.CLICK"]
            ]
        }]
    }
}


export const LeverToggleModel = {
    "version": "1.0",
    "icon": "https://static.thenounproject.com/png/1037776-200.png",
    "iconMui": CustomLeverIcon,
    "payload": {
        "objects": [{
            "name": "Lever Toggle",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 3, "y": 3, "z": 3 },
            "userData": {
                "type": "leverToggle",
                "nameToShow": "Lever",
                "hasPropertiesPanel": true,
                "initialPlacementStyle": "default",
                "distanceForNormalOffset": 0.01,
                "disableScale": true,
                "alwaysShow": false,
                "overrideUserData" : {
                    "disableScale": true
                },
                "localPosition": { "x": 0, "y": 0, "z": 0 },
                "rotationAxis": { "x": 0, "y": 1, "z":0 },
                "rotationRange:": "0,45"
            },
            "components": [
                {
                    "type": "mp.daeLoader",
                    "inputs": {
                        "url": "/assets/3d/Lever/Lever.dae",
                        "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
                        "localRotation": { "x": 0, "y": 0, "z":0 },
                        "localPosition": { "x": 0, "y": 0, "z": 0 },
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": true,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                },
                {
                    "type": "mp.rotateToggle",
                    "inputs" : {
                        "rotationAxis": {
                            "x": 0,
                            "y": 1,
                            "z": 0
                        },
                        "rotationRange": [0, -90],
                        "transitionTime" : 0.5
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": true,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                },
                {
                    "type": "mp.objLoader",
                    "inputs": {
                    "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj",
                    "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
                    "localRotation": { "x": 0, "y": 0, "z": 0 },
                    "localPosition": { "x": 0, "y": 0, "z": 0 }
                    },
                    "events": {
                        "INTERACTION.CLICK": false,
                        "INTERACTION.HOVER": false,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }

                },
                {
                    "type": "mp.loadingIndicator",
                    "inputs": {
                    "size": { "x": 1, "y": 1, "z": 1 }
                    }
                }
            ],
            "bindings": [
              [3, "logo", 2, "objectRoot"],
              [1, "targetMesh", 0, "objectRoot"]
            ]
        }]
    }
}

export const ToggleMultiModelModel = {
    "version": "1.0",
    "icon": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/On Black/icon_on_Black.jpg",
    iconMui: CustomPowerOnIcon,
    "payload": {
        "objects": [{
            "name": "Toggle Multi Model",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "hasPropertiesPanel": true,
                "alwaysShow": false,
                "initialPlacementStyle": "thermostat",
                "nameToShow": "On Off Button"
            },
            "components": [
                {
                    "type": "mp.gltfLoader",
                    "inputs": {
                        "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/On Black/On_Black.gltf",
                        "localScale": { "x": 1, "y": 1, "z": 1 },
                        "localRotation": { "x": -90, "y": 0, "z": 180 },
                        "localPosition": { "x": 0, "y": 0, "z": 0 }
                    },
                    "events": {
                        "INTERACTION.CLICK": false,
                        "INTERACTION.HOVER": false,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                },
                {
                    "type": "mp.gltfLoader",
                    "inputs": {
                        "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Off Black/Off_Black.gltf",
                        "localScale": { "x": 1, "y": 1, "z": 1 },
                        "localRotation": { "x": -90, "y": 0, "z": 180 },
                        "localPosition": { "x": 0, "y": 0, "z": 0 }
                    },
                    "events": {
                        "INTERACTION.CLICK": false,
                        "INTERACTION.HOVER": false,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                },
                {
                    "type": "mp.gltfLoader",
                    "inputs": {
                        "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Off Black/Off_Black.gltf",
                        "localScale": { "x": 1.1, "y": 1.1, "z": 1.1 },
                        "localRotation": { "x": -90, "y": 0, "z": 180 },
                        "localPosition": { "x": 0, "y": 0, "z": 0 }
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": true,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                },
                {
                    "type": "mp.ToggleComponent",
                    "inputs" :  {
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": true,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                }
            ],

        }]
    }
}

export const ThreeDMenuModel = {
    "version": "1.0",
    "icon": "https://static.thenounproject.com/png/1037776-200.png",
    iconMui: MenuRoundedIcon,
    "payload": {
        "objects": [{
            "name": "Menu",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "hasPropertiesPanel": true,
                "alwaysShow": false,
                "initialPlacementStyle": "plane",
                "type": "textPanel",
            },
            "components": [
                {
                    "id": 0,
                    "type": "st.plateRenderer",
                    "inputs": {
                        "aspect": 1.77777777778,
                        "transparent": true,
                        "opacity": 0.8,
                        "polygonOffset": true,
                        "polygonOffsetFactor": 0,
                        "color": 0x5f69f6,
                        "borderRadius": 100,
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": false,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                },
                {
                    "id": 1,
                    "type": "st.plateRenderer",
                    "inputs": {
                        "aspect": 1.77777777778,
                        "transparent": true,
                        "opacity": 1,
                        "polygonOffset": true,
                        "polygonOffsetFactor": -2,
                        "borderRadius": 100,
                        "invertScale": true,
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": false,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                },
                {
                    "id": 2,
                    "type": "mp.canvasRenderer",
                    "inputs": {
                        "textureRes": {
                            "w": 1024,
                            "h": 1024
                        }
                    }
                },
                {
                    "id": 3,
                    "type": "mp.canvasBorder",
                    "inputs": {
                        "position": {
                            "x": 0,
                            "y": 0
                        },
                        "size": {
                            "w": 1024,
                            "h": 1024,
                        },
                        "radius": 100,
                        "lineWidth": 20,
                        "color": "white"
                    }
                },
                {
                    "id": 4,
                    "type": "st.buttonTextRenderer",
                    "inputs": {
                        "transparent": true,
                        "opacity": 1,
                        "polygonOffset": true,
                        "polygonOffsetFactor": -4,
                        "borderRadius": 50,
                        "color": "#f5efef",
                        "hoverColor": "#ff0d60",
                        "clickColor": "#3b74ff",
                        "borderShadowColor": "#4bea27",
                        "borderStyle": "#000000",
                        "borderLineWidth": 10,
                        "bgColor": "rgba(111,200,239,0.93)",
                        "bgHoverColor": "rgba(140,0,255,0.93)",
                        "bgClickColor": "rgba(46,109,134,0.93)",
                        "fontSize": 50,
                        "textLabel": "Rattus Rattus",
                        "size": { "w": 0.7, "h": 0.15 },
                        "localPosition": { "x": 0.15, "y": 0.75, "z": 0.01 },
                    }
                },
                {
                    "id": 5,
                    "type": "st.buttonTextRenderer",
                    "inputs": {
                        "transparent": true,
                        "opacity": 1,
                        "polygonOffset": true,
                        "polygonOffsetFactor": -4,
                        "borderRadius": 50,
                        "color": "#f5efef",
                        "hoverColor": "#ff0d60",
                        "clickColor": "#3b74ff",
                        "borderShadowColor": "#4bea27",
                        "borderStyle": "#000000",
                        "borderLineWidth": 10,
                        "bgColor": "rgba(111,200,239,0.93)",
                        "bgHoverColor": "rgba(140,0,255,0.93)",
                        "bgClickColor": "rgba(46,109,134,0.93)",
                        "fontSize": 50,
                        "textLabel": "Pochahontos",
                        "size": { "w": 0.7, "h": 0.15 },
                        "localPosition": { "x": 0.15, "y": 0.55, "z": 0.01 },
                    }
                },
                {
                    "id": 6,
                    "type": "st.buttonTextRenderer",
                    "inputs": {
                        "transparent": true,
                        "opacity": 1,
                        "polygonOffset": true,
                        "polygonOffsetFactor": -4,
                        "borderRadius": 50,
                        "color": "#f5efef",
                        "hoverColor": "#ff0d60",
                        "clickColor": "#3b74ff",
                        "borderShadowColor": "#4bea27",
                        "borderStyle": "#000000",
                        "borderLineWidth": 10,
                        "bgColor": "rgba(111,200,239,0.93)",
                        "bgHoverColor": "rgba(140,0,255,0.93)",
                        "bgClickColor": "rgba(46,109,134,0.93)",
                        "fontSize": 50,
                        "textLabel": "Comanche",
                        "size": { "w": 0.7, "h": 0.15 },
                        "localPosition": { "x": 0.15, "y": 0.35, "z": 0.01 },
                    }
                },
                {
                    "id": 7,
                    "type": "st.buttonTextRenderer",
                    "inputs": {
                        "transparent": true,
                        "opacity": 0.9,
                        "polygonOffset": true,
                        "polygonOffsetFactor": -4,
                        "borderRadius": 50,
                        "color": "#f5efef",
                        "hoverColor": "#ff0d60",
                        "clickColor": "#3b74ff",
                        "borderShadowColor": "#4bea27",
                        "borderStyle": "#000000",
                        "borderLineWidth": 10,
                        "bgColor": "rgba(13,255,0,0.6)",
                        "bgHoverColor": "rgba(140,0,255,0.93)",
                        "bgClickColor": "rgba(46,109,134,0.93)",
                        "fontSize": 50,
                        "textLabel": "Kaesar",
                        "size": { "w": 0.7, "h": 0.15 },
                        "localPosition": { "x": 0.15, "y": 0.15, "z": 0.01 },
                    }
                }
            ],
            "bindings": [
                [1, "texture", 2, "texture"],
                [2, "painter", 3, "painter"],
            ],

        }]
    }
}

export const ImageRendererModel = {
    "version": "1.0",
    "icon": "https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png",
    iconMui: ImageRoundedIcon,
    "payload": {
        "objects": [{
            "name": "Image",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "type": "imageRenderer",
                "hasPropertiesPanel": true,
                "hasColorProperty": true,
                "hasBorderProperty": false,
                "initialPlacementStyle": "plane",
                "alwaysShow": false,
                "width": 1,
                "height": 1,
                "borderRadius": 0,
                "borderSize": 0,
                "textureSource": "/assets/images/texture.jpg"
            },
            "components": [
                {
                    "id": 0,
                    "type": "st.imageRenderer",
                    "inputs": {
                        "localScale": { "x":1, "y": 1, "z": 1 },
                        "opacity": 1,
                        "borderRadius": 100,
                        "borderSize": 10,
                        "textureSource": "/assets/images/texture.jpg"
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                    }
                }
            ]
        }]
    }
}

export const FireParticleSystem = {
    "version": "1.0",
    "icon": "https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png",
    iconMui: LocalFireDepartmentRoundedIcon,
    "payload": {
        "objects": [{
            "name": "Fire",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "hasPropertiesPanel": true,
                "alwaysShow": false,
                "initialPlacementStyle": "plane",
            },
            "components": [
                {
                    "id": 0,
                    "type": "st.fireParticleSystem",
                    "inputs": {
                        "particleCount": 1000,
                        "velocityMin": {x: 0, y: 0, z: 0},
                        "velocityMax": {x: 0, y: 0, z: 0},
                        "gravity": {x: 0, y: 0, z: 0},
                        "chaosMax": {x: 10, y: 10, z: 10},
                        "chaos": false,
                        "particleSystemLife": 1.25,
                        "boxVisible": true
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": false,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                }
            ]
        }]
    }
}

export const WaterParticleSystem = {
    "version": "1.0",
    "icon": "https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png",
    iconMui: WaterfallChartRoundedIcon,
    "payload": {
        "objects": [{
            "name": "Water Spray",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "hasPropertiesPanel": true,
                "alwaysShow": false,
                "initialPlacementStyle": "plane",
            },
            "components": [
                {
                    "id": 0,
                    "type": "st.waterParticleSystem",
                    "inputs": {
                        "particleCount": 1000,
                        "velocityMin": {x: 0, y: 0, z: 0},
                        "velocityMax": {x: 0, y: 0, z: 0},
                        "gravity": {x: 0, y: 0, z: 0},
                        "chaosMax": {x: 10, y: 10, z: 10},
                        "chaos": false,
                        "particleSystemLife": 1.25,
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": false,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                }
            ]
        }]
    }
}

export const DialModel = {
    "version": "1.0",
    "access": "ADMIN",
    "icon": "https://static.thenounproject.com/png/1037776-200.png",
    "iconMui": CustomDialIcon,
    "payload": {
        "objects": [{
            "name": "Dial",
            "position": { "x": 0, "y": 0, "z": 0 },
            "rotation": { "x": 0, "y": 0, "z": 0 },
            "scale": { "x": 1, "y": 1, "z": 1 },
            "userData": {
                "type": "leverToggle",
                "nameToShow": "Dial",
                "hasPropertiesPanel": true,
                "initialPlacementStyle": "dial",
                "distanceForNormalOffset": 0.01,
                "disableScale": true,
                "alwaysShow": false,
                "overrideUserData" : {
                    "disableScale": true
                },
                "localPosition": {
                    "z": 0,
                    "y": 0.21,
                    "x": 0.1
                },
                "rotationAxis": {
                    "x": 0,
                    "y": 0,
                    "z": 1
                },
                "rotationRange:": "0,45",
                "quaternionStart": {"x":0,"y":0,"z":0,"w":1},
            },
            "components": [
                {
                    "type": "mp.gltfLoader",
                    "inputs": {
                        "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Yellow%20Control/YellowControl.gltf",
                        "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
                        "localRotation": { "x": 0, "y": 0, "z":0 },
                        "localPosition": { "x": 0, "y": 0, "z": 0 },
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": true,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                },
                {
                    "type": "mp.rotateToggle",
                    "inputs" : {
                        "rotationAxis": {
                            "x": 0,
                            "y": 1,
                            "z": 0
                        },
                        "rotationRange": [0, -90],
                        "transitionTime" : 0.5
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": true,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }
                },
                {
                    "type": "mp.objLoader",
                    "inputs": {
                    "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj",
                    "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
                    "localRotation": { "x": 0, "y": 0, "z": 0 },
                    "localPosition": { "x": 0, "y": 0, "z": 0 }
                    },
                    "events": {
                        "INTERACTION.CLICK": false,
                        "INTERACTION.HOVER": false,
                        "INTERACTION.DRAG": false,
                        "INTERACTION.DRAG_END": false,
                        "INTERACTION.DRAG_BEGIN": false
                    }

                },
                {
                    "type": "mp.loadingIndicator",
                    "inputs": {
                    "size": { "x": 1, "y": 1, "z": 1 }
                    }
                }
            ],
            "bindings": [
              [3, "logo", 2, "objectRoot"],
              [1, "targetMesh", 0, "objectRoot"]
            ]
        }]
    }
}

modelMap.set(TextBoxModel.payload.objects[0].name, Object.freeze(TextBoxModel));
modelMap.set(WebcamModel.payload.objects[0].name, Object.freeze(WebcamModel));
modelMap.set(BorderHighlightModel.payload.objects[0].name, Object.freeze(BorderHighlightModel));
modelMap.set(ArrowModel.payload.objects[0].name, Object.freeze(ArrowModel));
modelMap.set(BoxHighlightModel.payload.objects[0].name, Object.freeze(BoxHighlightModel));
modelMap.set(LeverToggleModel.payload.objects[0].name, Object.freeze(LeverToggleModel));
modelMap.set(IoTModel.payload.objects[0].name, Object.freeze(IoTModel));
// modelMap.set(OffButtonModel.payload.objects[0].name, Object.freeze(OffButtonModel));
// modelMap.set(OnButtonModel.payload.objects[0].name, Object.freeze(OnButtonModel));
modelMap.set(OnOffButtonModel.payload.objects[0].name, Object.freeze(OnOffButtonModel));
modelMap.set(ToggleMultiModelModel.payload.objects[0].name, Object.freeze(ToggleMultiModelModel));
// modelMap.set(ThreeDMenuModel.payload.objects[0].name, Object.freeze(ThreeDMenuModel));
modelMap.set(ImageRendererModel.payload.objects[0].name, Object.freeze(ImageRendererModel));
modelMap.set(FireParticleSystem.payload.objects[0].name, Object.freeze(FireParticleSystem));
modelMap.set(WaterParticleSystem.payload.objects[0].name, Object.freeze(WaterParticleSystem));
modelMap.set(DialModel.payload.objects[0].name, Object.freeze(DialModel));

Object.freeze(modelMap);
export default modelMap;

