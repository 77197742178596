import * as THREE from 'three';
import TextureManager, {TextureWithLoadState} from '../TextureManager';
import Simulation from '../core/Simulation';
import {EulerIntegrator} from '../../components/systemComponents/EulerIntegrator';
import ListNode from '../ds/ListNode';
import {FireParticleSystemComponent} from '../../components/ParticleSystem/FireParticleSystemComponent';
import Particle from './Particle';

import DoublyLinkedList from '../ds/DoublyLinkedList';
import TestElement from './DummyElement';
import {Float64BufferAttribute} from 'three/src/core/BufferAttribute';
import GradientInterpolator from './Interpolators/GradientInterpolator';
import {AddEquation, OneMinusSrcAlphaFactor, SrcAlphaFactor} from 'three/src/constants';
import ShaderStringCache from './Shaders/ShaderStringCache';
import VectorGradientInterpolator from './Interpolators/VectorGradientInterpolator';
import RegularEmitterDescriptor from './EmitterDescriptor/RegularEmitterDescriptor';
import { WaterParticleSystemComponent } from '../../components/ParticleSystem/WaterParticleSystemComponent';

export enum EmitterType {
    GlowyAdditive = "GlowyAdditive",
    PlainJane = "PlainJane"
}

export interface EmitterParameters {
    particleTexture: string,
    vertexShader: string,
    fragmentShader: string,
    emitterType:EmitterType,
    alphaTest?:number,
    particleCount?: number,
    emissionRate?:number,
    damping?:number,
    angularDamping?:number,
    spawnInterval?:number,
    life?:number,
    randomLife?:number,
    timeIntervalScale?:number,
    emitterDescriptor:RegularEmitterDescriptor,
    brownianMotion?:boolean,
    brownianVector?:THREE.Vector3
}

export default class Emitter extends THREE.Object3D {
    protected geometry: THREE.BufferGeometry | null;
    protected sprite: THREE.Texture;
    protected textureLoaderState: TextureWithLoadState | undefined;

    public alphaGradientInterpolator: GradientInterpolator|null;
    public sizeGradientInterpolator: GradientInterpolator|null;
    public colorGradientInterpolator: VectorGradientInterpolator|null;

    protected universalForces:THREE.Vector3[];
    protected attractors:{ position: THREE.Vector3, mass:number, radius:number}[];

    protected particles: ListNode<Particle>[]|null;
    protected activeParticlesList: DoublyLinkedList<Particle>|null;
    protected particlePool: DoublyLinkedList<Particle>|null;
    protected _internalSpawnInterval: number;
    protected initialized: boolean;

    protected material:THREE.ShaderMaterial|null;
    protected particleGeometry:THREE.Points|null;
    
    protected materialVisible:boolean = true;

    public constructor(protected particleSystem: FireParticleSystemComponent | WaterParticleSystemComponent, protected emitterParameters:EmitterParameters) {
        super();

        this.initialized = false;
        this.geometry = new THREE.BufferGeometry();

        //const sprite = new THREE.TextureLoader().load('/assets/images/disc.png');

        this.universalForces = [];
        this.attractors = [];

        this.particles = [];
        this.emitterParameters.spawnInterval = this.emitterParameters.spawnInterval ? this.emitterParameters.spawnInterval : 0.1;
        this.emitterParameters.damping = this.emitterParameters.damping ? this.emitterParameters.damping : 0.995;
        this.emitterParameters.angularDamping = this.emitterParameters.angularDamping ? this.emitterParameters.angularDamping : 0.995;
        this.emitterParameters.timeIntervalScale = this.emitterParameters.timeIntervalScale ? this.emitterParameters.timeIntervalScale : 1;
        this.emitterParameters.emissionRate = this.emitterParameters.emissionRate ? this.emitterParameters.emissionRate : 5;
        this.emitterParameters.life = this.emitterParameters.life ? this.emitterParameters.life! : 2.0;
        this.emitterParameters.randomLife = this.emitterParameters.randomLife ? this.emitterParameters.randomLife! : 0.0;
        this.emitterParameters.brownianMotion = this.emitterParameters.brownianMotion ? this.emitterParameters.brownianMotion! : false;
        this.emitterParameters.brownianVector = this.emitterParameters.brownianVector ? this.emitterParameters.brownianVector! : new THREE.Vector3(0, 0, 0);
        this._internalSpawnInterval = 0;

        // let tempList = new DoublyLinkedList<TestElement>();
        //
        // let node1 = new TestElement(tempList, 'rajesh').nodeRef as ListNode<TestElement>;
        // let node2 = new TestElement(tempList, 'peter').nodeRef as ListNode<TestElement>;
        // //let node = tempList.push(new DummyElement("douglas"));
        // let node3 = new TestElement(tempList, 'dmonte').nodeRef as ListNode<TestElement>;
        //
        // console.log(tempList.length);
        // console.log(tempList.removeNode(node1)?.name);
        // console.log(tempList.length);

        this.textureLoaderState = TextureManager.instance.LoadTexture(emitterParameters.particleTexture, this.onTextureLoaded.bind(this), undefined);
        console.log('===done====');
    }

    public addUniversalForce(value:THREE.Vector3):void {
        this.particleSystem.root!.updateMatrixWorld(true);
        let matrixWorld = this.particleSystem.root!.matrixWorld.clone();
        //matrixWorld = matrixWorld.extractRotation(matrixWorld);
        matrixWorld.setPosition(0, 0, 0);
        matrixWorld.invert();
        //matrixWorld.setsc(0, 0, 0);
        value.applyMatrix4(matrixWorld);
        //  value = this.particleSystem.root!.worldToLocal(value)
        this.universalForces.push(value);
    }

    public addAttractor(value: {position:THREE.Vector3, mass:number, radius:number}):void {
        this.particleSystem.root!.updateMatrixWorld(true);
        // value.position = this.particleSystem.root!.worldToLocal(value.position);
        this.attractors.push(value);
    }

    public simulate(dt: number) {
        if (!this.initialized) {
            return;
        }
    
        if (!this.materialVisible) {
            return;
        }

        this._internalSpawnInterval += dt;
        if (this._internalSpawnInterval >= this.emitterParameters.spawnInterval!) {
            this._internalSpawnInterval = 0;

            this.emitParticles();
        }
        // let t0 = performance.now();
        const positions = this.geometry!.attributes.position as THREE.BufferAttribute;
        const colors = this.geometry!.attributes.color as THREE.BufferAttribute;
        const angles = this.geometry!.attributes.angle as THREE.BufferAttribute;
        const sizes = this.geometry!.attributes.size as THREE.BufferAttribute;
        let index = 0;
        // for(const particle of this.particles) {
        //     particle.applyDamping(this.damping);
        //     particle.simulate(dt);
        //     positions.setXYZ(index++,particle.position.x, particle.position.y, particle.position.z);
        // }
        const scaled_dt = dt * this.emitterParameters.timeIntervalScale!;
        let node = this.activeParticlesList!.first;
        if (node) {

            while (node) {
                const particle = node.data;
                node = node.next;

                particle.applyDamping(this.emitterParameters.damping!);
                particle.applyAngularDamping(this.emitterParameters.angularDamping!);

                for (const force of this.universalForces) {
                    particle.applyForce(force);
                }

                for (const attractor of this.attractors) {
                    // console.log(particle.position);
                    let toVector = attractor.position.clone().sub(particle.position);//.normalize().multiplyScalar(attractor.mass);
                    let vecLength = toVector.length();

                    if (vecLength < attractor.radius) {
                        let inverseLength = 1.0 / vecLength;
                        let force = toVector.multiplyScalar(inverseLength * attractor.mass);
                        particle.applyForce(force);
                    }

                }

                if (this.emitterParameters.brownianMotion) {
                    let newChaoticVector = this.emitterParameters.brownianVector!.clone();
                    newChaoticVector.x = Math.random() * newChaoticVector.x - newChaoticVector.x * 0.5;
                    newChaoticVector.y = Math.random() * newChaoticVector.y - newChaoticVector.y * 0.5;
                    newChaoticVector.z = Math.random() * newChaoticVector.z - newChaoticVector.z * 0.5;
                    particle.applyForce(newChaoticVector);
                }

                particle.simulate(scaled_dt);
                positions.setXYZ(index, particle.position.x, particle.position.y, particle.position.z);
                let alpha = this.alphaGradientInterpolator!.getInterpolation(particle.life);
                let color = this.colorGradientInterpolator!.getInterpolation(particle.life);
                let size = this.sizeGradientInterpolator!.getInterpolation(particle.life);
                particle.color.setRGB(color.x, color.y, color.z);
                colors.setXYZW(index,particle.color.r, particle.color.g, particle.color.b, alpha);
                angles.setX(index, particle.angle);
                sizes.setX(index, size);
                index++;
                if (particle.dead) {
                    this.particlePool!.push(this.activeParticlesList!.removeNode(particle.nodeRef as ListNode<Particle>)!);
                }

                if (index > this.emitterParameters.particleCount!) {
                    console.error('Emitter render overload: ' + index);
                    break;
                }
            }
        }

        if (index > 0) {
            positions.needsUpdate = true;
            colors.needsUpdate = true;
            sizes.needsUpdate = true;
            angles.needsUpdate = true;
        }

        // let t1 = performance.now();
        // let t2 = t1 - t0;
        // console.log(t2);
    }

    protected emitParticles(): void {
        if (!this.materialVisible) {
            return;
        }
        
        for (let i = 0; i < this.emitterParameters.emissionRate!; i++) {
            let tempParticle = this.particlePool!.shift();

            if (tempParticle) {
                this.emitterParameters.emitterDescriptor.DescribeParticle(tempParticle, new THREE.Vector3(0, 0, 0), this.emitterParameters.life! + this.emitterParameters.randomLife! * Math.random());
                //THREE.MathUtils.
                this.activeParticlesList!.push(tempParticle);
            }
        }
        
        //console.log('emit, pool: ' + this.particlePool.length);
        //console.log('emit, active: ' + this.activeParticlesList.length);
    }

    protected onTextureLoaded(texture: THREE.Texture | undefined): void {
        this.sprite = texture!;

        const positionArray: number[] = [];
        const colorsArray: number[] = [];
        const sizesArray: number[] = [];
        const anglesArray: number[] = [];
        this.activeParticlesList = new DoublyLinkedList<Particle>();
        this.particlePool = new DoublyLinkedList<Particle>();

        for (let i = 0; i < this.emitterParameters.particleCount!; i++) {
            let tempParticle = new Particle(this.particlePool);
            this.particles!.push(tempParticle.nodeRef as ListNode<Particle>);
            positionArray.push(tempParticle.position.x, tempParticle.position.y, tempParticle.position.z);
            colorsArray.push(1, 1, 1, 1);
            sizesArray.push(0.1);
            anglesArray.push(0)
        }

        this.geometry!.setAttribute('position', new THREE.Float32BufferAttribute(positionArray, 3).setUsage(THREE.DynamicDrawUsage));
        this.geometry!.setAttribute('color', new THREE.Float32BufferAttribute(colorsArray, 4).setUsage(THREE.DynamicDrawUsage));
        this.geometry!.setAttribute('size', new THREE.Float32BufferAttribute(sizesArray, 1).setUsage(THREE.DynamicDrawUsage));
        this.geometry!.setAttribute('angle', new THREE.Float32BufferAttribute(anglesArray, 1).setUsage(THREE.DynamicDrawUsage));


        // let material = new THREE.PointsMaterial({
        //     size: 0.1,
        //     sizeAttenuation: true,
        //     map: this.sprite,
        //     alphaTest: 0.5,
        //     premultipliedAlpha: true,
        //     //depthWrite: false,
        //     //depthTest: true,
        //     transparent: true,
        //     vertexColors: true,
        //     blendEquation: THREE.AddEquation,
        //
        //     blendSrc: THREE.SrcAlphaFactor,
        //     blendDst: THREE.OneMinusSrcAlphaFactor,
        //     blendSrcAlpha: THREE.OneFactor,
        //     blendDstAlpha: THREE.OneMinusSrcAlphaFactor,
        //     blending: THREE.AdditiveBlending
        // });

        const uniforms = {
            diffuseTexture: {
                value: this.sprite
            },
            pointMultiplier: {
                value: window.innerHeight / (2.0 * Math.tan(0.5 * 60.0 * Math.PI / 180.0))
            }
        };

        let parameters:THREE.ShaderMaterialParameters =
            {
                uniforms: uniforms,
                vertexShader: this.emitterParameters.vertexShader,
                fragmentShader: this.emitterParameters.fragmentShader,
                alphaTest: this.emitterParameters.alphaTest ? this.emitterParameters.alphaTest : 0
            };

        if(this.emitterParameters.emitterType === EmitterType.GlowyAdditive) {
            parameters = {
                ...parameters,
                blending: THREE.AdditiveBlending,
                depthTest: true,
                depthWrite: false,
                transparent: true
            }
        }

        if(this.alphaGradientInterpolator == null) {
            this.alphaGradientInterpolator = new GradientInterpolator([
                {gradient: 0, interpolationMapping: 0},
                {gradient: 0.1, interpolationMapping: 1},
                {gradient: 1.8, interpolationMapping: 1},
                {gradient: 2, interpolationMapping: 1}]);
        }

        if(this.sizeGradientInterpolator == null) {
            this.sizeGradientInterpolator = new GradientInterpolator([
                {gradient: 0, interpolationMapping: 0},
                {gradient: 1.0, interpolationMapping: 0.1},
                {gradient: 1.8, interpolationMapping: 0.1},
                {gradient: 2, interpolationMapping: 0}]);
        }

        if(this.colorGradientInterpolator == null) {
            this.colorGradientInterpolator = new VectorGradientInterpolator([
                {gradient: 0, interpolationMapping: new THREE.Vector3(1, 0, 0)},
                {gradient: 0.5, interpolationMapping: new THREE.Vector3(0, 1, 0)},
                {gradient: 1.5, interpolationMapping: new THREE.Vector3(0, 0, 1)},
                {gradient: 2, interpolationMapping: new THREE.Vector3(1, 1, 1)}]);
        }

        this.material = new THREE.ShaderMaterial(parameters);
        this.particleGeometry = new THREE.Points(this.geometry!, this.material);
        this.particleGeometry!.frustumCulled = false;
        this.add(this.particleGeometry!);
        this.particleSystem.root!.add(this);
        this.initialized = true;
    }
    
    public setVisible(x:boolean) {
        if (this.material) {
            this.materialVisible = x;
            this.material.visible = x;
        }
    }
    
    dispose() {
        this.activeParticlesList!.dispose();
        this.particlePool!.dispose();
        this.activeParticlesList = null;
        this.particlePool = null;
        this.particles!.length = 0;
        this.particles = null;
        this.material!.dispose();
        this.geometry!.dispose();
        this.material = null;
        this.geometry = null;
        this.particleGeometry = null;
    }
}
