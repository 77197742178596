import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Typography } from '@material-ui/core';
import { OverflowMenu } from 'modules/home/components/OverflowMenu';
import TextFormatRoundedIcon from '@mui/icons-material/TextFormatRounded';
import ThreeDRotationRoundedIcon from '@mui/icons-material/ThreeDRotationRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import HighlightAltRoundedIcon from '@mui/icons-material/HighlightAltRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
// import { CustomRotateIcon, ThreeDIcon } from './Icons/CustomIcons'
import { ReactComponent as ThreeDIcon2Svg } from 'assets/images/3dIcons/ThreeDIcon2.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    tile: {
        '& .MuiGridListTile-tile': {
            cursor: 'pointer',

            '&:hover': {
                backgroundColor: fade(theme.palette.primary.main, 0.1),
                transform: 'translateY(-4px)',
                '& .fav-btn': {
                    opacity: 1,
                    visibility: 'visible',
                },
            },
        }
    },
    itemRoot: {
        // minWidth: '100px',
        // maxWidth: '100px'

    },
    overflowMenu: {
        maxHeight: '10px',
        maxWidth: '10px',
        position: 'absolute',
        right: '22px',
        top: '2px',
        zIndex: 3,
        // display: '',
        '& .MuiIconButton-root': {
            padding: '2 2'
        },
        '&:hover': {
            // display: 'block',
            // backgroundColor: 'red'
        },
    },
    gridList: {
        // width: 1000,
        // height: 1000,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    deleteBadge: {
        '& .MuiBadge-colorPrimary': {
            backgroundColor: 'transparent'

        }
    }
}));

export default function TitlebarGridList({ tileData, hasOverflowMenu }: any) {
    const classes = useStyles();

    //   console.log(`[st] ${JSON.stringify(tileData)}`);


    return (
        <div className={classes.root}>
            <GridList
                cellHeight={"auto"}
                spacing={3} cols={3} className={classes.gridList}>
                {/* <GridListTile key="Subheader"  style={{ height: 'auto' }}>
          <ListSubheader component="div"></ListSubheader>
        </GridListTile> */}
                {tileData.map((tile: any, index: number) => (

                    <GridListTile key={tile.id} className={classes.tile}>
                        {hasOverflowMenu && <span className={classes.overflowMenu}>
                            <OverflowMenu menuItems={[{ title: "Delete", onClick: () => { tile.onDelete() }, isDisabled: tile.isDisabled }]} />
                        </span>}
                        <div onClick={tile.onClick} style={{
                            height: '70px',minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '12px'
                        }}>
                            {/* <img style={{ height: "50px" }} src={tile.img || "https://onlinepngtools.com/images/examples-onlinepngtools/400-by-400.png"}
                         alt={""} /> */}
                            {/* <ArrowDownwardRoundedIcon /> */}

                            {tile.iconMui ? <tile.iconMui /> : (tile.img ? getImageIcon(tile.img, tile.title) :
                            // <ThreeDIcon color="black"/>
                            <ThreeDIcon2Svg style={{fill: "black", height: 23}}/>
                            )}
                            <div style={{ fontSize: '12px', textAlign: 'center', marginTop: '4px' }}>{tile.title}</div>
                        </div>
                    </GridListTile>

                ))}
            </GridList>
        </div>
    );
}

function getImageIcon(imgSrc: string, altText: string) {
   return <img style={{ height: "50px" }} src={imgSrc || "https://onlinepngtools.com/images/examples-onlinepngtools/400-by-400.png"}
        alt={altText} />

}