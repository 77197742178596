import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IntlMessages from '../../../../@crema/utility/IntlMessages';
import { ProjectObj } from '../../../../types/models/apps/ProjectBoard';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles, MenuItem, Select } from '@material-ui/core';
import { SpaceData } from 'types/models/home/HomeApp';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { CremaTheme } from 'types/AppContextPropsType';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const useStyles = makeStyles((theme: CremaTheme) => ({
  selectBox: {
    marginLeft: 8,
    cursor: 'pointer',
    fontSize: 14,
    [theme.breakpoints.up('xl')]: {
      marginLeft: 24,
    },
    '& .MuiSelect-select': {
      paddingLeft: 10,
    },
  },
  selectOption: {
    cursor: 'pointer',
    padding: 8,
    fontSize: 14,
  },
}));
interface AddNewProjectProps {
  isAddProjectOpen: boolean;
  onCloseAddProjectModal: () => void;
  onAddProject: (projectName: string,spaceId:string) => void;
  selectedProject: ProjectObj;
  // selectedSpace: SpaceData;
  classes: any;
}

const AddNewProject: React.FC<AddNewProjectProps> = ({
  isAddProjectOpen,
  onCloseAddProjectModal,
  onAddProject,
  selectedProject,
  // selectedSpace,
  classes,
}) => {
  const [projectName, setProjectName] = useState(() =>
    selectedProject ? selectedProject.name : '',
  );

  const [spaceId, setSpaceId] = useState<string>('');
  // (() =>
  //   selectedSpace ? selectedSpace.name : '',
  // );
  const spacesList = useSelector<AppState, SpaceData[]>(({ home }) => home.spacesList);

  const onClickAddButton = () => {
    if (projectName !== '') {
      onAddProject(projectName,spaceId);
      setProjectName('');
      setSpaceId('');
      onCloseAddProjectModal();
    }
  };

  return (
    <Dialog
      open={isAddProjectOpen}
      onClose={() => onCloseAddProjectModal()}
      aria-labelledby='simple-modal-title'
      TransitionComponent={Transition}
      aria-describedby='simple-modal-description'
      className={classes.dialogBox}>
      <Card className={classes.addProjectCard}>
        <TextField
          fullWidth
          margin='normal'
          label={<IntlMessages id='projectboard.projectTitle' />}
          value={projectName}
          onChange={event => setProjectName(event.target.value)}
        />
        <Select
          // defaultValue={defaultValue}
          // value={selectionType}
          onChange={(event: any) => setSpaceId(event.target.value)}
          // disableUnderline={true}
          className={clsx(classes.selectBox, 'select-box')}
          >
          {spacesList.map((space: SpaceData) => (
            <MenuItem
              key={space?.id || ''}
              value={space?.id || ''}
              className={classes.selectOption}
              >
              {space?.name || ''}
            </MenuItem>
          ))}
        </Select>
        <Button className={classes.addButton} onClick={onClickAddButton}>
          <IntlMessages id='common.add' />
        </Button>
      </Card>
    </Dialog>
  );
};

export default AddNewProject;
