import React from 'react';
import Card from '@material-ui/core/Card';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {Fonts} from '../../../../shared/constants/AppEnums';
import {ProjectObj} from '../../../../types/models/apps/ProjectBoard';

interface ProjectItemProps {
  project: ProjectObj;
  onEditButtonClick: (project: ProjectObj) => void;
  onViewProjectDetail: (project: ProjectObj) => void;
  classes: any;
}

const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  onEditButtonClick,
  onViewProjectDetail,
  classes,
}) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={project.id}>
      <Card
        className={classes.projectItem}
        key={project.id}
        onClick={() => onViewProjectDetail(project)}>
        <ListAltIcon className={classes.listAltIcon} />
        <EditIcon
          className={classes.listAltIcon}
          onClick={() => onEditButtonClick(project)}
        />
        <Box mb={4} fontWeight={Fonts.MEDIUM} fontSize={14}>
          {project.name}
        </Box>
        <Box component='span' onClick={event => event.stopPropagation()} />
      </Card>
    </Grid>
  );
};

export default ProjectItem;
