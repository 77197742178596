import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme, createStyles, fade } from '@material-ui/core/styles';
import {
  ILesson,
  ISceneData,
} from 'types/models/home/HomeApp';
import {
  Box,
  Button,
  List,
  ListItem,
  Tooltip,
  TextField,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  IconButton,
  Fab
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { canEdit } from '@crema/utility/Utils';
import IntlMessages from '@crema/utility/IntlMessages';
import { Fonts } from 'shared/constants/AppEnums';
import { grey, red } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { onGetLessonsList } from 'redux/actions/Lesson';
import { makeid } from 'shared/helpers/helper';
import StepsBar from './StepsBar';
import { SET_PRESENTATION_MODE } from 'types/actions/Home.action';
import { ShowcaseContext } from '../..';
import Layout from '../layout';
import { defaultLayerNameSingular } from 'shared/constants/AppConst';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import Spacer from '@crema/core/Spacer';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import { showMessage } from 'redux/actions';
import { goToLesson } from 'redux/actions/Lesson';

//tags list overflow bug
// tag menu should be hidden pres mode
// popover hide in pres mode
// 3d prop panel not visible anymore

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
      color: 'black',
      lineHeight: '48px',
    },
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    createTagsLink: {
      paddingLeft: theme.spacing(4),
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonGroup: {
      alignContent: 'center',
      justifyContent: 'center',
      width: '80%',
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    avatar: {
      cursor: 'pointer',
      backgroundColor: grey[100],
      border: `1px dashed ${grey[400]}`,
    },
    pointer: {
      cursor: 'pointer',
    },
    addIconRoot: {
      color: theme.palette.primary.light,
      fontWeight: Fonts.LIGHT,

    },
    btnRoot: {
      backgroundColor: red[500],
      color: theme.palette.primary.contrastText,
      paddingLeft: 24,
      paddingRight: 24,
      marginTop: 12,
      '&:hover, &:focus': {
        backgroundColor: red[800],
        color: theme.palette.primary.contrastText,
      },
    },
    editIcon: {
      marginRight: 5,
      cursor: 'pointer',
      // height: '20px'
    },
    deleteIcon: {
      marginRight: 5,
      // color: 'red',
      cursor: 'pointer',
      // height: '20px'
    },
    lessonBox: {
      // minWidth: '320px',
      // maxWidth: '320px',
      height: '100%',
      // boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.12)',
      // transition: 'all 0.5s ease',
      // paddingTop: '0px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
        maxWidth: '100%',
        width: '100%',
      },
    },
    lessonContainer: {
      // backgroundColor: "lightgrey",
      borderRadius: "15px",
      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
      margin: '5px 5px 5px 5px',
      backgroundColor: "aliceblue",
      padding: '5px 2px'
      //alignItems: 'flex-start'
    },
    lessonMetadata: {
      textAlign: 'right',
      alignSelf: 'flex-end'
    }
  }),
);

interface Props {
  setMinimized: (isMinimized: boolean) => void;
  isMinimized: boolean;
}

export default function LessonSidebar({ setMinimized, isMinimized }: Props) {
  const { currentSpace, currentLesson, lessonsList, primaryUseCase, primaryUseCaseOther } = useSelector<
    AppState,
    AppState['home']
  >(({ home }) => home);
  const presentationMode = useSelector<AppState, boolean>(({ home }) => home.presentationMode);
  const { authUser } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  const classes = useStyles();
  const [isAddLayer, setAddLayer] = useState(false);
  const [showSteps, setShowSteps] = useState(false);
  const [editLayerId, setEditLayerId] = useState<null | string>(null);
  const [listName, setListName] = useState('');
  const showcaseContext = useContext(ShowcaseContext);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    //   (async () => {
    //     let les = await firestore
    //       .collection(`Spaces/${currentSpace?.id}/lessons`)
    //       .get();
    //     setLessonsList(les.docs.map((l) => l.data()));
    //   })();
    //   return () => {
    //     dispatch({type: SET_LESSON_DATA, payload: null});
    //   };
    // }, []);
    currentSpace &&
      !currentLesson &&
      dispatch(onGetLessonsList(currentSpace.id));
    // if(!!match?.params.mid){
    // }
  }, [history]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (editLayerId) handleEditLesson(editLayerId);
        else onClickAddButton();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  const onClickAddButton = async () => {
    if (listName !== '' && currentSpace) {
      const newLayerRef = await firestore
        .collection(`Spaces/${currentSpace?.id}/lessons`)
        .doc();

      newLayerRef.set({
        id: newLayerRef.id,
        did: makeid(8),
        name: listName,
        createdOn: new Date(),
        createdBy: authUser?.uid,
      });
      dispatch(onGetLessonsList(currentSpace?.id));
      setAddLayer(false);
      setListName('');
    }
  };

  const handleDeleteLesson = async (lesson: ISceneData) => {
    if (!currentSpace) return;
    let res = window.confirm(`Are you sure you want to delete this lesson: ${lesson?.name}?`);
    if (res) {

      const newLayerRef: any = await firestore.doc(
        `Spaces/${currentSpace.id}/lessons/${lesson.id}`,
      );

      newLayerRef
        .delete()
        .then(() => {
          dispatch(onGetLessonsList(currentSpace?.id));
        })
        .catch(console.error);
    }
  };

  const handleEditLesson = async (lessonId: string | null) => {
    if (!currentSpace) return;
    const newLayerRef = await firestore.doc(
      `Spaces/${currentSpace.id}/lessons/${lessonId}`,
    );

    newLayerRef
      .update({ name: listName })
      .then(() => {
        dispatch(onGetLessonsList(currentSpace?.id));
        setAddLayer(false);
        setEditLayerId(null);
        setListName('');
      })
      .catch(console.error);
  };
  // console.log(editLayerId, 'editLayerId');

  // if(presentationMode) {
  //   return <></>
  // } else
  if (currentLesson) {
    return <StepsBar setMinimized={setMinimized} isMinimized={isMinimized} />;
  } else {
    return (
      <>
        <Layout>
          {isMinimized ? <ItemHover> <Button onClick={() => setMinimized(false)}><KeyboardArrowLeftRoundedIcon /></Button></ItemHover> : null}
          <div className={'sidebar ' + classes.lessonBox}>
            <Typography
              variant='h6'
              align='center'
              className={classes.heading}
              gutterBottom>
              {primaryUseCase?.layerTermPlural || 'Experiences'}
            </Typography>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}>
              {currentSpace && canEdit(currentSpace.spaceMembership.role) && (
                <Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    style={{ marginBottom: 15 }}>
                    {/* <Button
                      variant='contained'
                      color='secondary'
                      size='small'
                      style={{ height: '90%' }}
                      type='button'
                      onClick={() => {
                        setAddLayer(true);
                        setEditLayerId(null);
                        setListName('');
                      }}>
                      Create New
                    </Button> */}

                    <Tooltip title={`Create new ${currentSpace.primaryUseCase?.layerTermSingular}`} >
                      <Fab size="small" color="secondary" onClick={() => {
                        setAddLayer(true);
                        setEditLayerId(null);
                        setListName('');
                      }}>
                        <AddIcon />

                      </Fab>
                    </Tooltip>
                    <Box display='flex'>
                      {isAddLayer ? (
                        <Box px={3} textAlign='right'>
                          <CloseIcon
                            className={classes.pointer}
                            onClick={async () => {
                              setAddLayer(false);
                            }}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  {isAddLayer ? (
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'>
                      <TextField
                        fullWidth
                        style={{ width: "100%", fontSize: "13px" }}
                        margin='normal'
                        label={`${primaryUseCase?.layerTermSingular || defaultLayerNameSingular} Name`}
                        value={listName}
                        onChange={(event) => setListName(event.target.value)}
                      />
                      <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        type='submit'
                        style={{
                          marginTop: 10,
                          marginBottom: 20,
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          if (editLayerId) handleEditLesson(editLayerId);
                          else onClickAddButton();
                        }}>
                        <IntlMessages
                          id={editLayerId ? 'common.edit' : 'common.add'}
                        />
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              )}
            </Box>

            <List style={{ padding: 3 }}>
              {/* <ListSubheader component="div" id="nested-list-subheader">
              On Track
            </ListSubheader> */}

              {lessonsList
                .filter((lesson) => lesson.name)
                .map((lesson: ILesson, index: number) => {
                  return (<ListItem key={lesson.id + index}>

                    <Card
                      // raised={true}
                      style={{ minWidth: "100%", borderRadius: '16px' }}
                    >
                      <CardContent>
                        <CardActionArea onClick={() =>
                        history.push({pathname: `/home/space/${currentSpace?.did}/project/${currentSpace?.currentSpaceProject.did}/layers/${lesson.did}`, search: '?mode=studio'})}
                          // goToLesson(currentSpace?.did || '', currentSpace?.currentSpaceProject.did || '', lesson.did, false, (path: string) => history.push(path))}
                          >

                          <Typography gutterBottom variant='h6' className={'h7Left'}>
                            {lesson.name}
                          </Typography>
                          {/* <Typography variant='body2' color='text.secondary'>
                                stepsInfo
                              </Typography> */}
                          <Typography variant='caption' style={{ overflow: 'wrap', color: fade('rgb(0,0,0)', 0.7) }}
                          // color='secondary'
                          >
                            Created on {((lesson as any).createdOn || (lesson as any).createdAt)?.toDate()?.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}
                            {/* {//FIXME}} */}
                          </Typography>
                        </CardActionArea>
                      </CardContent>

                      <CardActions>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}
                        >
                          {/* <Fab size="small"> */}
                          <Tooltip title="Start Presentation">
                            <IconButton size="small" color="secondary" onClick={() => {
                              history.push({pathname: `/home/space/${currentSpace?.did}/project/${currentSpace?.currentSpaceProject.did}/layers/${lesson.did}`, search:"?mode=present"});
                              // dispatch({ type: SET_PRESENTATION_MODE, payload: true });
                            }}>
                              <PlayArrowRoundedIcon fontSize='large'  />
                            </IconButton>
                          </Tooltip>
                          {/* </Fab> */}
                          {/* <Button color="secondary" variant="outlined" size="small"
                            style={{
                              height: "90%",
                              marginLeft: '16px'
                            }}
                            onClick={() => {
                              history.push(`/home/space/${currentSpace?.did}/project/${currentSpace?.currentSpaceProject.did}/layers/${lesson.did}`);
                              dispatch({ type: SET_PRESENTATION_MODE, payload: true })
                            }}
                          >Present

                          </Button> */}

                          {canEdit() && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}
                          >
                            <Tooltip title="Rename Lesson">
                              <IconButton
                                size='medium'
                                // className={classes.editIcon}
                                onClick={() => {
                                  setAddLayer(true);
                                  setEditLayerId(lesson.id);
                                  setListName(lesson.name);
                                }}>
                                <EditIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Lesson">
                              <IconButton size='medium'
                                // className={classes.deleteIcon}
                                onClick={() => {
                                  handleDeleteLesson(lesson);
                                }}>
                                <DeleteIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Share Lesson">
                              <IconButton size='medium'
                                // className={classes.deleteIcon}
                                onClick={() => {
                                  navigator.clipboard.writeText("Check out this experience I created on SpatialThink: " + window.location.pathname);
                                  dispatch(showMessage("Lesson share link copied to your clipboard. You can paste it anywhere you like! Remember to share the space with the recipient first!"))
                                }}>
                                <ShareRoundedIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>

                            <Spacer x={4}/>
                          </div>}
                        </div>
                      </CardActions>
                    </Card>

                  </ListItem>

                  );
                })}
            </List>
          </div>
          {/* {lessonsList
              .filter((lesson) => lesson.name)
              .map((lesson: ILesson, index: number) => {
                return (
                  <ItemHover key={index}>
                    <Box className={classes.lessonContainer}>
                      <ListItem key={lesson.id + index}>

                        <Link
                          type='underlineAlways'
                          style={{ cursor: 'pointer' }}
                          // underline='always'
                          // style={{ width: "80%" }}
                          onClick={() => {
                            // dispatch({ type: SET_LESSON_DATA, payload: lesson });
                            // setCurrentLesson(lesson)
                            history.push(`/home/space/${currentSpace?.did}/layers/${lesson.did}`);
                          }}>
                          {lesson.name}
                        </Link>
                        {/* {currentSpace && canEdit(currentSpace.spaceMembership.role) && ( */}
          {/* <ListItemSecondaryAction style={{ right: 0 }} key={`list-item-for-lesson-${lesson.id + index}`}>
                        <Tooltip title='Edit Name' key={`tooltip-for-lesson-${lesson.id + index}`}> */}

          {/* <EditIcon
                              key={`tooltip-editicon-for-lesson-${lesson.id + index}`}
                              className={classes.editIcon}
                              onClick={() => {
                                setAddLayer(true);
                                setEditLayerId(lesson.id);
                                setListName(lesson.name);
                              }}
                            />
                        </Tooltip>
                        <Tooltip title='Delete' key={`delete-icon-tooltip-for-lesson-${lesson.id + index}`}>
                            <DeleteIcon
                              key={`tooltip-editicon-for-lesson-${lesson.id + index}`}
                              className={classes.deleteIcon}
                              onClick={() => {
                                handleDeleteLesson(lesson);
                              }}
                            />
                        </Tooltip>
                        </ListItemSecondaryAction> */}
          {/* <LittleButton title={`Edit`} icons={[

                        ]} onClickFunc={() => {
                          history.push(`/home/space/${currentSpace?.did}/layers/${lesson.did}`);
                        }} /> */}
          {/* )} */}
          {/* </ListItem>
                      <Box className={classes.lessonMetadata} key={`meta-for-lesson-${lesson.id + index}`}></Box>
                    </Box>
                  </ItemHover>
                );
              })}
          </List>
        </div> */}
        </Layout>
      </>
    );
  }
}

{/* <Link
                          type='underlineAlways'
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            history.push(`/home/space/${currentSpace?.did}/project/${currentSpace?.currentSpaceProject.did}/layers/${lesson.did}`);
                          }}>
                          {lesson.name}
                        </Link>
                        <ListItemSecondaryAction style={{ right: 0 }}>
                          <Button
                            variant='text'
                            style={{ height: '90%', marginBottom: '10px' }}
                            type='button'
                            onClick={() => dispatch({ type: SET_PRESENTATION_MODE, payload: true })}>
                            <Tooltip title="Start Presentation"><PlayCircleRoundedIcon color="success" /></Tooltip>
                          </Button>

                          <Tooltip title='Edit'>
                            <EditIcon
                              className={classes.editIcon}
                              onClick={() => {
                                setAddLayer(true);
                                setEditLayerId(lesson.id);
                                setListName(lesson.name);
                              }}
                            /></Tooltip>
                          <Tooltip title='Delete'>
                            <DeleteIcon
                              className={classes.deleteIcon}
                              onClick={() => {
                                handleDeleteLesson(lesson);
                              }}
                            /></Tooltip>
                        </ListItemSecondaryAction> */}
