import React from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
  tagLabelInput: string;
  tagDescInput: string;
  saveBtnClick: boolean;
  setTagLabelInput(value: string): void;
  setTagDescInput(value: string): void;
}

export default function InfoTagForm({
  tagLabelInput,
  tagDescInput,
  setTagLabelInput,
  setTagDescInput,
  saveBtnClick,
}: Props) {
  return (
    <>
      <TextField
        autoFocus
        margin='dense'
        id='tagLabel'
        label='Title'
        fullWidth
        value={tagLabelInput}
        onChange={(e) => setTagLabelInput(e.target.value)}
        error={tagLabelInput === ''&&saveBtnClick}
        helperText={
          tagLabelInput === ''&&saveBtnClick &&
          'Required field.'
        }
      />
      <TextField
        margin='dense'
        id='tagDesc'
        label='Description'
        type='text'
        fullWidth
        multiline
        value={tagDescInput}
        onChange={(e) => setTagDescInput(e.target.value)}
      />
    </>
  );
}
