import { SceneComponent, ComponentInteractionType } from "../../SubSystems/sceneManagement/SceneComponent";
import * as THREE from "three"
import Utils from "../../Tools/Utils";
import { UserDataProperties } from "../../SubSystems/ui-interop/PropertiesPanel";
import { SET_VARIABLE_VALUE } from "types/actions/Home.action";
import Simulation from "../../SubSystems/core/Simulation";
import { store } from "App";

interface Inputs {
    rotationAxis: {x: number, y: number, z: number};
    rotationRange: any;
    //toggle: boolean;
    state: number;
    transitionTime: number;
    targetMesh: THREE.Object3D | null;
}

export class RotateToggle extends SceneComponent {

    inputs: Inputs = {
        rotationAxis: {x: 0, y: 0, z: 1},
        rotationRange: [0, 90],
        //toggle: false,
        state: 0,
        transitionTime: 0.3,
        targetMesh: null,
    };

    //private object3D:THREE.Object3D;

    private goalRotation:THREE.Quaternion;
    private currentAnimationTime:number;

    private initialized:boolean = false;

    private localEphemeralIndex:number = -1;

    onInit() {
        this.outputs.collider = (this.context.root as any).obj3D;

        //this.object3DMixer = new THREE.AnimationMixer(this.object3D);
        //let rotationAxisFinal = new THREE.Vector3(this.inputs.rotationAxis.x, this.inputs.rotationAxis.y, this.inputs.rotationAxis.z);
        //this.inputs.targetMesh!.setRotationFromQuaternion(new THREE.Quaternion().setFromAxisAngle(rotationAxisFinal, this.inputs.rotationRange[this.inputs.state] * THREE.MathUtils.DEG2RAD));
        //this.prepareClips();

        this.currentAnimationTime = 0;
    }

    prepareClips() {
        let rotationAxisFinal = new THREE.Vector3(this.inputs.rotationAxis.x, this.inputs.rotationAxis.y, this.inputs.rotationAxis.z);

        this.goalRotation = new THREE.Quaternion().setFromAxisAngle(rotationAxisFinal, this.inputs.rotationRange[this.inputs.state] * THREE.MathUtils.DEG2RAD);
    }

    onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
        if (interactionType === ComponentInteractionType.CLICK) {
          this.notify(ComponentInteractionType.CLICK, {
            type: interactionType,
            node: this.context,
            component: this,
          });

          this.inputs.state++;

          if(this.inputs.state >= this.inputs.rotationRange.length) {
            this.inputs.state = 0;
          }
          //console.log(this.context.root.userData[UserDataProperties.inputSource1]);

        if(this.context.root.userData && UserDataProperties.inputSource1 in this.context.root.userData) {
            if(this.context.root.userData[UserDataProperties.inputSource1]) {
                let varSearch = Simulation.instance.Variables?.find(v => v.name === this.context.root.userData[UserDataProperties.inputSource1]);

                if(varSearch) {
                    let allowedValuesArray = (varSearch.values as string).split(",").map(x => x.trim());

                    let newValue = '';
                    newValue = allowedValuesArray[Math.min(this.inputs.state, allowedValuesArray.length - 1)];

                    store.dispatch({ type: SET_VARIABLE_VALUE, payload: { name: this.context.root.userData[UserDataProperties.inputSource1], value: newValue } });
                }
            }
            }
        }

        if (interactionType === ComponentInteractionType.HOVER) {
            this.notify(ComponentInteractionType.HOVER, {
                hover: interactionType,
                node: this.context,
                component: this,
            });

            if (this.localEphemeralIndex === -1) {
                if (this.inputs.targetMesh) {
                    let meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(this.inputs.targetMesh!);
                    this.localEphemeralIndex = Simulation.instance.outlineComponentColor2.addEphemeralMeshes(meshes!, this.localEphemeralIndex);
                }
            } else {
                Simulation.instance.outlineComponentColor2.removeEphemeralMeshes(this.localEphemeralIndex);
                this.localEphemeralIndex = -1;
            }
        }
    }

    onInputsUpdated(oldInputs: Inputs) {

        if (oldInputs.state !== this.inputs.state) {
            this.prepareClips();
            this.currentAnimationTime = 0;
        }


    }

    onTick(delta:number) {
        var deltaInSeconds = delta * 0.001;

        if (this.inputs.targetMesh) {

            if (!this.initialized) {
                this.initialized = true;
                this.prepareClips();
                this.inputs.targetMesh.rotation.setFromQuaternion(this.goalRotation);
            } else {
                if (this.currentAnimationTime < this.inputs.transitionTime) {
                    let currentRotation = new THREE.Quaternion().setFromRotationMatrix(this.inputs.targetMesh.matrix);
                    currentRotation = currentRotation.slerp(this.goalRotation, this.currentAnimationTime / this.inputs.transitionTime);
                    this.inputs.targetMesh.rotation.setFromQuaternion(currentRotation);
                    this.currentAnimationTime += deltaInSeconds;
                } else {
                    this.inputs.targetMesh.rotation.setFromQuaternion(this.goalRotation);
                }
            }
        }
    }
}

export const rotateToggleType = 'mp.rotateToggle';
export const makeRotateToggle = function() {
    return new RotateToggle();
};
