import {WindowingSystem} from './WindowingSystem';

export interface GenericEventCallback<T> {
    (e:T):void;
}

export class InputSystem {
    protected mouseDownCallbacks:GenericEventCallback<MouseEvent>[];
    protected mouseUpCallbacks:GenericEventCallback<MouseEvent>[];
    protected mouseMoveCallbacks:GenericEventCallback<MouseEvent>[];
    
    readonly keyStates:{ [key: string]: boolean } = {};
    
    private _mouseDown:boolean;
    
    constructor(protected windowingSystem:WindowingSystem) {
        this.windowingSystem.getShowcaseDocument().addEventListener('mousedown', this.onMouseDown.bind(this));
        this.windowingSystem.getShowcaseDocument().addEventListener('mouseup', this.onMouseUp.bind(this));
        this.windowingSystem.getShowcaseDocument().addEventListener('mousemove', this.onMouseMove.bind(this));
    
        this.windowingSystem.getShowcaseDocument().addEventListener('keydown', this.onKeyDown.bind(this));
        this.windowingSystem.getShowcaseDocument().addEventListener('keyup', this.onKeyUp.bind(this));
        
        this.mouseDownCallbacks = [];
        this.mouseUpCallbacks = [];
        this.mouseMoveCallbacks = [];
        
        this._mouseDown = false;
    }
    
    protected onKeyDown(e:KeyboardEvent) {
        this.keyStates[ e.code ] = true;
    }
    
    protected onKeyUp(e:KeyboardEvent) {
        this.keyStates[ e.code ] = false;
    }
    
    public registerMouseUpCallback(callback:GenericEventCallback<MouseEvent>):number {
        return this.mouseUpCallbacks.push(callback) - 1;
    }
    
    public registerMouseDownCallback(callback:GenericEventCallback<MouseEvent>):number {
        return this.mouseDownCallbacks.push(callback) - 1;
    }
    
    public registerMouseMoveCallback(callback:GenericEventCallback<MouseEvent>):number {
        return this.mouseMoveCallbacks.push(callback) - 1;
    }
    
    protected onMouseMove(e:MouseEvent):void {
        for (const singleCallback of this.mouseMoveCallbacks) {
            singleCallback(e);
        }
    }
    
    protected onMouseDown(e:MouseEvent):void {
        for (const singleCallback of this.mouseDownCallbacks) {
            singleCallback(e);
        }
    
        this._mouseDown = true;
    }
    
    protected onMouseUp(e:MouseEvent):void {
        for (const singleCallback of this.mouseUpCallbacks) {
            singleCallback(e);
        }
    
        this._mouseDown = false;
    }
    
    get MouseDown(): boolean {
        return this._mouseDown;
    }
}
