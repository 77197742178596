import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { useDispatch, useSelector, } from 'react-redux';
import { ViewMode, } from '.';
import LogicTrees from '../../Interactions/LogicTrees';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { setWorkflowsSidebarOpen, setTagsViewMode, setTagsViewModeSecondary } from 'redux/actions/Home';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { ILesson, SpaceData, TagGroup } from 'types/models/home/HomeApp';
import { AppState } from 'redux/store';
import Layout from './layout';
import IndicatorPulse from '@crema/core/IndicatorPulse';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontWeight: 500,
            color: 'black',
            lineHeight: '48px',
        },


        sidebarContainer: {
            minWidth: '35%',
            backgroundColor: 'white',
            // paddingTop: '10px',
            // maxWidth: '320px',
            // height: '100%',
            overflow: 'auto',
            boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.12)',
            transition: 'all 0.5s ease',
            // paddingTop: '0px',
            [theme.breakpoints.down('xs')]: {
                minWidth: '100%',
                maxWidth: '100%',
                width: '100%',
            },
        },
        backButton: {
            // height: '90%',
            // padding: '30px 10px',
            margin: '10px 10px',
            boxShadow: 'none',
            '& .MuiSvgIcon-root': {
                //   height: '0.75em',
            },
            // margin: 10,
            textAlign: 'center', cursor: 'pointer'
        },
    })
);

interface Props {
    // currentSpace: SpaceData;;
    // currentLesson: ILesson;
    tagGroup: TagGroup;
}

export default function WorkflowsSidebar({ tagGroup, }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [hasPendingChanges, setHasPendingChanges] = useState<boolean>(false);
    // const {
    //     // currentSpace,
    //     tagsViewMode, isWorkflowsSidebarOpen
    //     // tagsViewModeSecondary, currentLesson, currentTagGroupId
    //       } = useSelector<AppState, AppState['home']>(({ home }) => home);

    // const [tagGroupData, setTagGroupData] = useState<TagGroup>(null);

    // const initCurrentTagGroupData = async() => {
    //     if(currentSpace?.id) {
    //         if(currentLesson?.id) {
    //             if(currentTagGroupId) {
    //                 let data = tagGroupsList.find(tg => tg.id == currentTagGroupId)
    //                 //setTagGroupData(await (await firestore.doc(`Spaces/${currentSpace?.id}/lessons/${currentLesson}/tagGroups/${currentTagGroupId}`).get()).data);
    //                 // let docRef = await firestore.doc(`Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups/${currentTagGroupId}`);
    //                 // let doc = await docRef.get();
    //                 // let data = await doc.data();
    //                 if(data) {
    //                     setTagGroupData(data);
    //                 }
    //             }
    //         }
    //     }
    // }

    // useEffect(() => {
    //     initCurrentTagGroupData();
    // });

    // isWorkflowsSidebarOpen ?
    //  [ViewMode.TABLE, ViewMode.LAYERS_SIM].map(v => v.valueOf()).includes(tagsViewMode)  ||

    // const hasChanges = React.useRef<any>(null);
    console.log(`[st] rendering WorkflowsSidebar`);
    return (

        <Layout width='500px'><div className={'sidebar ' + classes.sidebarContainer}>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >
                <Button
                    variant='text'
                    className={classes.backButton}
                    color='primary'
                    size='small'
                    type='button'
                    onClick={() => {

                        // if (tagsViewMode == ViewMode.LAYERS_SIM) {
                        // dispatch(setTagsViewMode(ViewMode.LAYERS));
                        // dispatch(setTagsViewModeSecondary(''));
                        // }

                        if(hasPendingChanges){
                            let res = window.confirm("You have unsaved changes. Are you sure to leave this page?.....");
                            if(res){
                                dispatch(setWorkflowsSidebarOpen(false));
                            }
                        } else {

                        // if (hasChanges && hasChanges?.current && hasChanges.current()) {

                            dispatch(setWorkflowsSidebarOpen(false));
                        }
                        // }

                        // dispatch({ type: SET_LESSON_DATA, payload: null });
                        // dispatch(setCurrentTagGroupId(''));
                        // dispatch(setTagsViewModeSecondary(''));

                        // dispatch(onStopLesson());
                        // dispatch(setTagsViewMode(ViewMode.LAYERS));
                        // handleShowAllSpaceTags();
                        // history.push(`/home/space/${currentSpace?.did}/layers`);
                    }}>
                    {/* <Tooltip title="Back"> <ArrowBackRoundedIcon /> </Tooltip> */}
                    <Tooltip title='Back'>
                        <ArrowBackRoundedIcon />
                    </Tooltip>
                </Button></div>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                    // variant='contained'
                    variant='h6'
                    component='span'
                // align='center'
                // color='secondary'

                // className={classes.heading}
                // gutterBottom

                >
                    Interactions
                </Typography>
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 2px'
                }}>
                    <Typography
                        // variant='contained'
                        variant='body2'
                        component='span'
                    // align='center'
                    // color='secondary'

                    // className={classes.heading}
                    // gutterBottom

                    >
                        {tagGroup.name}
                    </Typography>
                    <Typography
                        // variant='contained'
                        variant='caption'
                        component='span'
                        style={{ width: '70%' }}
                    // align='center'
                    // color='secondary'

                    // className={classes.heading}
                    // gutterBottom

                    >
                        {tagGroup.bannerText}
                    </Typography>
                </div>

                {/* <Typography
                variant='h6'
                align='center'
                className={classes.heading}
                gutterBottom>
                Broad: {tagsViewMode}  {primaryUseCase?.layerTermPlural || 'Experiences'}
            </Typography> */}
                {/* <Link
            type='underlineAlways'
            //  underline='always'
            className={classes.backButton}
            // style={{ margin: 10, textAlign: 'center', cursor: 'pointer' }}
            onClick={() => {}}>
            BACK
          </Link> */}

                <LogicTrees
                    tagGroup={tagGroup}
                    openLogicTreesDrawer={true}
                    onCloseDrawer={() => { }}
                    setHasPendingChanges={setHasPendingChanges}
                    // hasChanges={hasChanges}
                />
            </div>
        </div>
        </Layout>)

}