import * as THREE from 'three';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderingSystem} from '../Systems/RenderingSystem';
import {ISceneNode, SceneComponent} from '../../mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import {SceneNode} from './SceneNode';
import {MimickPointer} from './MimickPointer';
import {GLTFComponent} from '../Components/GLTFComponent';
import {UserDataProperties, UserDataTypes} from '../../mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';

interface SceneConfigureCallback {
    (renderer: THREE.WebGLRenderer, three: typeof THREE, effectComposer: EffectComposer): void;
}

export interface ComponentInitFunction {
    (): SceneComponent;
}

export class MimickScene {
    
    readonly componentInitTypeNameMap: {[key: string]: ComponentInitFunction};
    
    constructor(protected renderingSystem: RenderingSystem) {
        this.componentInitTypeNameMap = {};
    }
    
    public register(componentStringType: string, componentInitFunction: ComponentInitFunction) {
        this.componentInitTypeNameMap[componentStringType] = componentInitFunction;
    }
    
    public configure(callback: SceneConfigureCallback) {
        callback(this.renderingSystem.renderer, THREE, new EffectComposer(this.renderingSystem.renderer));
    }
    
    public deserialize(sceneJSON: string): SceneNode[] {
        let nodesCollection = JSON.parse(sceneJSON);
        
        let sceneNodes: SceneNode[] = [];
        for (const sceneNode of nodesCollection.payload.objects as any[]) {
            let sceneNodeInstantiated: SceneNode = Object.assign(new SceneNode(this), sceneNode);
            let validSceneNode = false;
            if (sceneNodeInstantiated.name === 'gltf') {
                let component = new GLTFComponent(this.renderingSystem, sceneNode.components[0].inputs.url);
                component.root.position.set(sceneNode.position.x, sceneNode.position.y, sceneNode.position.z);
                component.root.rotation.set(sceneNode.rotation.x * THREE.MathUtils.DEG2RAD, sceneNode.rotation.y * THREE.MathUtils.DEG2RAD, sceneNode.rotation.z * THREE.MathUtils.DEG2RAD);
                sceneNodeInstantiated.addCustomComponent(component);
                validSceneNode = true;
            }
            //
            // let components = sceneNode.components;
            //
            // for (const component of components as any[]) {
            //     sceneNodeInstantiated.addComponent(component.type);
            // }
            //
            
            if (validSceneNode) {
                sceneNodes.push(sceneNodeInstantiated)
            }
        }
        
        return sceneNodes;
    }
    
    public createNode(): SceneNode {
        return new SceneNode(this);
    }
}
