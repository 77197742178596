import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import PropertiesPanel, { PropertiesPanelDropDownType, UserDataProperties } from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel";

export enum VariableType {
    booleanVariableType = "Boolean",
    csvVariableType = "List",
    differenceVariableType = "List Difference",
    stringVariableType = "Any"
}

export const getLabelForVariableType = (varType: VariableType) => {
    switch (varType) {
        case VariableType.booleanVariableType:
            return "True/ False";
        case VariableType.csvVariableType:
            return "Values to show on Digital Gauge";
        case VariableType.stringVariableType:
            return "Any";
    }
}

export enum VariableOptions {
    allowControl = "allowControl",
    type = "type"
}

export class VariableTypeAllowedValues {
    public static getAllowedTypes(type: VariableType): string[] {
        switch (type) {
            case VariableType.booleanVariableType:
                return ["True", "False"]
                break;
            case VariableType.csvVariableType:
                return [];
                break;
            case VariableType.stringVariableType:
                return [];
                break;
        }

        return [];
    }

    public static getSystemVariablesOfType(variableType: VariableType, variableValues: any[], prepopulateWithNone: boolean = true, allowControlOnly: boolean = true): string[] {
        let inputSource = [];

        if (prepopulateWithNone) {
            inputSource.push("None");
        }
        variableValues = variableValues || [];
        for (const variable of variableValues) {
            if (allowControlOnly) {
                if (VariableOptions.allowControl in variable) {
                    if (variable[VariableOptions.allowControl]) {
                        if (VariableOptions.type in variable) {
                            if (variable[VariableOptions.type] === variableType) {
                                inputSource.push(variable.name);
                            }
                        }
                    }
                }

            } else {
                if (VariableOptions.type in variable) {
                    if (variable[VariableOptions.type] === variableType) {
                        inputSource.push(variable.name);
                    }
                }
            }
        }

        return inputSource;
    }

    public static updateInputSourceForNestNode(nodeUserData: any, variableValues: any[]): string[] {
        let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.csvVariableType, variableValues, true, false)
            .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.differenceVariableType, variableValues, false, false));

        if (nodeUserData[UserDataProperties.inputSource1] === "None") {
        } else {
            if (allowedSystemVariablesAsInputSourceForNest.indexOf(nodeUserData[UserDataProperties.inputSource1]) === -1) {
                nodeUserData[UserDataProperties.inputSource1] = "None";
                Simulation.instance.propertiesPanel.dropDownChangeEvents<string>(PropertiesPanelDropDownType.InputSource1_DropDown, "None", 0);
            }
        }

        return allowedSystemVariablesAsInputSourceForNest;
    }

    public static getAllIOTVariables(variableValues: any[]): string[] {
        return VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.csvVariableType, variableValues, false, false)
            .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.differenceVariableType, variableValues, false, false));
    }

    public static updateInputSourceForGenericToggle(nodeUserData: any, variableValues: any[]): string[] {
        let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.booleanVariableType, variableValues, true, false)
            .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.csvVariableType, variableValues, false, false));
        if (nodeUserData[UserDataProperties.inputSource1] === "None") {
        } else {
            if (allowedSystemVariablesAsInputSourceForNest.indexOf(nodeUserData[UserDataProperties.inputSource1]) === -1) {
                nodeUserData[UserDataProperties.inputSource1] = "None";
                Simulation.instance.propertiesPanel.dropDownChangeEvents<string>(PropertiesPanelDropDownType.InputSource1_DropDown, "None", 0);
            }
        }

        return allowedSystemVariablesAsInputSourceForNest;
    }
}