import { ComponentOutput, SceneComponent } from '../../SubSystems/sceneManagement/SceneComponent';
import { Size, SizeScale } from '../meshComponents/basic/PlaneRenderer';
import { IPainter2d } from './CanvasRenderer';

type Inputs = {
  position: { x: number; y: number; },
  size: Size;
  radius: number;
  text: string;
  fontStyle: string;
  fontSize: number;
  fontName: string;
  textWidth: number;
  fontColor: string;
  sizeScale: SizeScale;
};

type Outputs = {
  painter: IPainter2d | null;
} & ComponentOutput;

type Events = {
  textUpdate: boolean;
};

export class  CanvasText extends SceneComponent implements IPainter2d {
  inputs: Inputs = {
    position: { x: 0, y: 0 },
    size: { h: 512, w: 512 },
    radius: 1,
    text: 'Placeholder',
    fontStyle: "normal bold",
    fontSize: 80,
    fontName: "sans-serif",
    textWidth: 100,
    fontColor: "#ffffff",
    sizeScale: {x: 1, y: 1}
  }

  outputs = {
    painter: null,
  } as Outputs;

  events = {
    textUpdate: true,
  } as Events;

  // private imgElement:HTMLImageElement;
  // private anyElement:any;

  onInit() {
    this.outputs.painter = this;
    /*
    <div style="display:none;">
    <img id="source"
         src="rhino.jpg"
         width="300" height="227">
  </div>
    document.children.
*/
    // let element = document.createElement("div");
    // document.body.appendChild(element);
    //
    // this.imgElement = document.createElement("img");
    // this.imgElement.setAttribute("src", "/assets/images/texture.jpg")
    // this.imgElement.setAttribute("id", "testID");
    //
    //
    // this.imgElement.onload = this.onLoadEvent.bind(this);
    //
    // element.appendChild(this.imgElement);
    //
    // this.anyElement = document.getElementById("testID");
    // console.log(this.imgElement);
    // console.log(this.anyElement);
  }
  onLoadEvent(ev:Event) {
    console.log(ev)
    this.notify('textUpdate');
    }

  /*
  onTick(delta:number) {
  }
*/
  onInputsUpdated(oldInputs: Inputs) {
    //this.notify('update');

    if (oldInputs.text !== this.inputs.text) {
      //console.log("paint.ready")
      this.notify('textUpdate');
    }

    if (oldInputs.fontSize !== this.inputs.fontSize) {
      //console.log("paint.ready")
      this.notify('textUpdate');
    }

    if (oldInputs.sizeScale !== this.inputs.sizeScale) {
        //console.log("paint.ready")
        this.notify('textUpdate');
    }

    if (oldInputs.fontColor !== this.inputs.fontColor) {
        //console.log("paint.ready")
        this.notify('textUpdate');
      }
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    context2d.strokeStyle = this.inputs.fontColor;
    context2d.fillStyle = this.inputs.fontColor;
    context2d.font = this.inputs.fontStyle + " " + this.inputs.fontSize + "px " + this.inputs.fontName;
    context2d.textAlign = 'left';
    context2d.textBaseline = 'top';
    context2d.clearRect(0, 0, size.w * this.inputs.sizeScale.x, size.h * this.inputs.sizeScale.y);
    
    
    

    // if(this.anyElement) {
    //     context2d.fillStyle = "#ff0000";
    //     context2d.fillRect(32, 32, 128, 128);
    //     context2d.drawImage(this.anyElement, 10, 10);
    // }
    wrapText(context2d, this.inputs.text, size.w * 0.5 * this.inputs.sizeScale.x, size.h * 0.5 * this.inputs.sizeScale.y, size.w * this.inputs.sizeScale.x, this.inputs.fontSize * 0.9);
  }
}

function wrapText(context: CanvasRenderingContext2D, text: string, x: number, y: number,
  maxWidth: number, lineHeight: number) {
  var startY:number = y;
  var startX:number = x;

  text = text.replaceAll('\r', '');

  var words = text.split(' ');
  var line = '';
  var lines:string[] = [];
  var linesToRender:string[] = [];

  //while
  //let glyphWidth = context.measureText("X");

  context.textAlign = 'center';
  context.textBaseline = 'middle';
  var wordCount = words.length;
  for(var n = 0; n < wordCount; n++) {
    var testLine = line + words[n]+ ((n < (wordCount - 1)) ? ' ' : '');
    var metrics = context.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      lines.push( line );
      line = words[n] + ' ';
    }
    else {
      line = testLine;
    }
  }

  lines.push( line );
  for(var n = 0; n < lines.length; n++) {
    var currentLine = lines[n];
    let lineBreaksLines = currentLine.split('\n');
    for(var i = 0; i < lineBreaksLines.length; i++) {
      var newLine = lineBreaksLines[i];
      var brokenLines = breakBigLines(context, newLine, maxWidth)

      for(var j = 0; j < brokenLines.length; j++) {
        linesToRender.push(brokenLines[j]);
      }

    }
  }
  var totalLines = linesToRender.length;
  var halfTextHeight = lineHeight * totalLines * 0.5;
  for(var n = 0; n < totalLines; n++) {
    context.fillText(linesToRender[n], x, y + n * lineHeight - halfTextHeight + lineHeight * 0.5 );
  }
}

function breakBigLines(context: CanvasRenderingContext2D, text: string,  maxWidth: number):string[] {
    let line = ''
    let lines:string[] = []
    let lastIndex = 0;
    for(var n = 0; n < text.length; n++) {
      var glyph = text[n];
      line += glyph;
      var metrics = context.measureText(line);
      var testWidth = metrics.width;

      if(testWidth < maxWidth) {

      } else {
        lines.push(line);
        line = '';
        lastIndex = n + 1;
      }
    }

    lines.push(text.substr(lastIndex, text.length));

    return lines;
}



export const canvasTextType = 'mp.canvasText';
export function makeCanvasText() {
  return new CanvasText();
}
