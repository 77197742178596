import { AppActions } from '../../types';
import { Dispatch } from 'redux';
import { fetchError, fetchStart, fetchSuccess, showMessage } from './Common';
import {
  ADD_SPACES_HIDE_MODAL,
  GET_SPACES_LIST,
  GET_PEOPLE_LIST_DATA,
} from '../../types/actions/Home.action';
import { appIntl } from '@crema/utility/Utils';
import Api from "@crema/services/ApiConfig";
import { onGetSpacesList } from './Home';
import {
  InviteStatus,
  PeopleObj,
  SpaceData,
} from 'types/models/home/HomeApp';
import { AuthUser } from 'types/models/AuthUser';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { QuerySnapshot } from '@firebase/firestore-types';
import { SpaceInvite } from 'types/models/dataAccess/Space';
import { getMetadata } from 'types/models/Model';
import { getInQueryResultWithChunks } from 'modules/home/SpaceDetail/utils';

export const onCreateSpace = (space: any): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions | any>, getState: any) => {
    const { projectList } = getState().projects;
    dispatch(fetchStart());

    console.log(`[st] creating space ${space} ${JSON.stringify(space)}`);

    Api.post('/api/spaces/', space)
      .then(data => {

        console.log(`[st] response ${Object.entries(data.data)}`);

        if (data.status === 200) {
          dispatch(fetchSuccess());
          !space.isSample && dispatch(showMessage(messages['message.spaceCreated'] as string));
          !space.isSample && dispatch({ type: ADD_SPACES_HIDE_MODAL, payload: false });
          // dispatch(fetchStart());
          Api.get('/api/spaces/list')
            .then(data => {
              if (data.status === 200) {
                // dispatch(fetchSuccess());
                { console.log(`[st] current space ${JSON.stringify(data.data, (key, val) => key == "imageSrc" ? "" : val)}`); }
                dispatch({ type: GET_SPACES_LIST, payload: data.data });
              } else {
                // dispatch(
                // fetchError(messages['message.somethingWentWrong'] as string),
                // );
              }
            })
            .catch(error => {
              // dispatch(fetchError(error.message));
            });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }

      })
      .catch(error => {
        dispatch(fetchError(error.message));

      }).catch(console.error);

  };
};

export const resolveSpaceInvites = (userEmail: string, userId: string): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    console.log(`[st] getting space invites for user}`);

    firestore
      .collection('SpaceInvites')
      .where('userEmail', '==', userEmail)
      .get()
      .then((querySnapshot: QuerySnapshot) => {
        let invites: any[] = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        invites.forEach((invite) => {
          let doc = firestore.collection(`SpaceMemberships`).doc();

          doc.set({
            userId: userId,
            spaceId: invite.spaceId,
            role: invite.role,
            ...getMetadata(userId, doc.id)
          }).catch((err) => console.error(err))
        })


      }).catch((error) => {
        console.error(error);
        dispatch(fetchError('Error adding invited space'));
      });

  };
};

export const onDeleteSpace = (space: SpaceData): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    console.log(`[st] deleting space ${space} ${JSON.stringify(space)}`);

    Api.delete(`/api/spaces/`, { data: { id: space.id } })
      .then(data => {

        console.log(`[st] response ${data.data ? Object.entries(data.data) : ""}`);

        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(showMessage(messages['message.spaceDeleted'] as string));

        } else if (data.status === 404) {
          dispatch(showMessage("Space not found, or already deleted"));
        } else if (data.status === 401) {
          dispatch(showMessage("You don't have enough permissions to delete this space."));

        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }

        // dispatch(fetchStart());
        Api.get('/api/spaces/list')
          .then(data => {
            if (data.status === 200) {
              // dispatch(fetchSuccess());
              dispatch({ type: GET_SPACES_LIST, payload: data.data });
            }
          })
          .catch(error => {
            // dispatch(fetchError(error.message));
          });

      })
      .catch(error => {
        dispatch(fetchError(error.message));

      }).catch(console.error);

  };
};

export const onGetPeopleList = (
  spaceId: string | undefined,
  authUser: AuthUser | null,
): any => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    try {
      let res: PeopleObj[] = [];
      let qs = await firestore
        .collection('SpaceMemberships')
        .where('spaceId', '==', spaceId)
        .get();

      let memberships = qs.docs.map((doc) => doc.data());

      let qsInvites = await firestore
        .collection('SpaceInvites')
        .where('spaceId', '==', spaceId)
        .get();

      let invites: SpaceInvite[] = qsInvites.docs.map((doc: any) => doc.data());

      getInQueryResultWithChunks('Users', 'id', memberships.map(membership => membership.userId))
        // firestore
        //   .collection('Users')
        //   .where('id', 'in', memberships.map(membership => membership.userId))
        //   .get()
        // .then((qs: QuerySnapshot) => {
        .then((data: any[]) => {
          let users = data.reduce((obj: any, doc) => (obj[doc.id] = doc, obj), {});

          dispatch(fetchSuccess());

          //add memberships first
          res.push(...(memberships.map(mem => ({ userId: mem.userId, role: mem.role?.toUpperCase(), email: users[mem.userId].email, name: users[mem.userId].firstName + ' ' + users[mem.userId].lastName }))));

          //then add invites
          res.push(...invites.map((invite) => ({ userId: '', email: invite.userEmail, role: invite.role, name: invite.userEmail, inviteStatus: InviteStatus.INVITED })));

          dispatch({ type: GET_PEOPLE_LIST_DATA, payload: res });
        })
    } catch (error: any) {
      console.error("error getting people: " + error)
    }
  };
};

export const onDeleteSpacePeople = (spaceId: string, projectId: string, people: PeopleObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/spaces/delete/people', { spaceId, projectId, people })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PEOPLE_LIST_DATA, payload: data.data });
          dispatch(showMessage(messages['message.peopleDeleted'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};
