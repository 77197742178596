import {
  auth,
  facebookAuthProvider,
  firestore,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
  userRepo,
} from '../../@crema/services/auth/firebase/firebase';
import {
  AWAITING_VERIFICATION,
  FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import { onCreateSpace, resolveSpaceInvites } from '../../redux/actions';
import { AuthType } from '../../shared/constants/AppEnums';
import { AppActions } from '../../types';
import { Dispatch } from 'redux';
import { AuthUser } from '../../types/models/AuthUser';
import { fetchError, fetchStart, fetchSuccess, showMessage } from './Common';
import { UserRepo, UserModel } from 'types/models/dataAccess/User';
import { authRole } from 'shared/constants/AppConst';
import NotificationService from '@crema/services/NotificationService';

// export const VERIFY_EMAIL_LINK = `${baseUrl}/verifyemail`;

export const onSignUpFirebaseUser = ({

  email,
  password,
  firstName,
  lastName,
  organization
}: {

  email: string;
  password: string;
  firstName: string;
  lastName: string;
  organization: string;
}): any => {
  return (dispatch: Dispatch<AppActions | any>) => {
    dispatch(fetchStart());
    try {
      auth.createUserWithEmailAndPassword(email, password)
        .then((data: any) => {
          console.log(`[st] creating User with email ${data.email} `);
          /*{ model received from firebase auth
  "user": {
    "uid": "CFJ1ZNFwOee0DDUIJrsuA5snSK23",
    "displayName": null,
    "photoURL": null,
    "email": "amar.a.ntine.a@gmail.com",
    "emailVerified": false,
    "phoneNumber": null,
    "isAnonymous": false,
    "tenantId": null,
    "providerData": [{
      "uid": "amar.a.ntine.a@gmail.com",
      "displayName": null,
      "photoURL": null,
      "email": "amar.a.ntine.a@gmail.com",
      "phoneNumber": null,
      "providerId": "password"
    }],
    "apiKey": "AIzaSyCQD7hpQtnLSF8svUJUG0Yn0do8fKIWjTI",
    "appName": "[DEFAULT]",
    "authDomain": "virtual-tc.firebaseapp.com",
    "stsTokenManager": {
      "apiKey": "AIzaSyCQD7hpQtnLSF8svUJUG0Yn0do8fKIWjTI",
      "refreshToken": "AGEhc0DtTIHpnH729yDHKxptbw9URV0CY3fjODMr-Ma6km8wKOu7M02WtmDsD3_YctEl7lPFXgE-B90wZ0p0stFoRTv4HfRBayPHaCHWcZMe2A6fPGFyjIna1UknVQ1r4ThTNGq2uYvxt1VoGlnlazdIR9A-1CD0VajHKEGdTiEgoRC6EEuhkmihF3inCKwcsczVVP5jQB6o-eZ2eMyUZ79ORAQsoZpBbg",
      "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjMwMjUxYWIxYTJmYzFkMzllNDMwMWNhYjc1OTZkNDQ5ZDgwNDI1ZjYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdmlydHVhbC10YyIsImF1ZCI6InZpcnR1YWwtdGMiLCJhdXRoX3RpbWUiOjE2MjI4MjY2MjAsInVzZXJfaWQiOiJDRkoxWk5Gd09lZTBERFVJSnJzdUE1c25TSzIzIiwic3ViIjoiQ0ZKMVpORndPZWUwRERVSUpyc3VBNXNuU0syMyIsImlhdCI6MTYyMjgyNjYyMCwiZXhwIjoxNjIyODMwMjIwLCJlbWFpbCI6ImFtYXIuYS5udGluZS5hQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJhbWFyLmEubnRpbmUuYUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.LFz5yWu_nQpxEC-fZanTUoRQOpOUxdLjqpZRNcw_r_OkpwEHLTIkVd4qxCW-3geGirnmaa3uBEtSD1u_ClgHEkQYfvFAlAdR13tSphiT-puSpFTtMtE9a1TT73gsKK83Yzr7w5RbUhLsEC9rrtcbdqk4M-JTrfiT3gY-4HZRBVXvBfzgtPan6OTdy9xSzQNqufgrZsWQar7LrDjFivmY9jIVExA728dn54tI-Kx_VD9vgauRFyhkijHetzolmLQ-GTEDkVY4HYkX1da1TpA85JG5eMHk3MRbw9WmqWCQtTDsV7yLmhdaGvXuFiLqX-4S0Wu1uNl1MuEBUtKuf_bpKg",
      "expirationTime": 1622830220000
    },
    "redirectEventId": null,
    "lastLoginAt": "1622826619973",
    "createdAt": "1622826619973",
    "multiFactor": {
      "enrolledFactors": []
    }
  },
  "credential": null,
  "additionalUserInfo": {
    "providerId": "password",
    "isNewUser": true
  },
  "operationType": "signIn"
} */
          let user: UserModel = {
            id: data.user.uid,
            email: data.user.email,
            firstName: firstName,
            lastName: lastName,
            organization: organization,
            authType: AuthType.FIREBASE,
            createdOn: new Date(),
            createdBy: data.user.uid,
            lastUpdatedOn: new Date(),
            lastUpdatedBy: data.user.uid
          }

          console.log(`[st] creating User ${user}`);
          new UserRepo().create(user).then(async createdUser => {
            console.log(`[st] createdUser ${JSON.stringify(createdUser)}`);
            dispatch(fetchSuccess());
            // dispatch({ type: UPDATE_AUTH_USER, payload: getUserObject(data.user, createdUser) });
            dispatch({ type: AWAITING_VERIFICATION, payload: true });

            console.log(`[st] sendEmailVerification creatingUser `);
            data.user.sendEmailVerification()
              .then(() => dispatch(showMessage("Verification email sent to your email")))
              .catch((e: any) => dispatch(fetchError(e.message)));

            dispatch(
              onCreateSpace({
                sid: "j4RZx7ZGM6T",
                name: "Sample House",
                isSample: "true"
                // uid: userObj.user.uid,
              }),
            );

            dispatch(resolveSpaceInvites(user.email, user.id));

          });
          let link = `home/spaces`;

        setTimeout(function() { //Start the timer
            dispatch(NotificationService.welcomeUser(link, user.id,  //After 30 minutes, send an email
              {
                toFirstName: user.firstName,
                toEmail: user.email,
              }));
        }.bind(this), 30 * 60 * 1000);

        })
        .catch(error => {
          // console.log(`[st] dispatching error1`);
          console.error(error);
          dispatch(fetchError(error.message));
        });
    } catch (error: any) {
      console.error(error);
      dispatch(fetchError(error.message));
    }
  };
};

export const onForgetPasswordFirebaseUser = (email: string): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      auth
        .sendPasswordResetEmail(email)
        .then((data) => {
          dispatch(fetchSuccess());
          dispatch({ type: FORGET_PASSWORD_EMAIL, payload: email });
        })
        .catch((error) => {
          dispatch(
            fetchError(getFirebaseAuthErrorForUser(error)));
        });
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};

export const onGetFirebaseSignInUser = (): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      // auth
      //   .onAuthStateChanged()
      //   .then((authUser: any) => {
      //     dispatch(fetchSuccess());
      //     dispatch({
      //       type: UPDATE_AUTH_USER,
      //       payload: getUserObject(authUser),
      //     });
      //   })
      //   .catch((error: any) => {
      //     dispatch(fetchError(error.message));
      //   });
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};

export const getUserObject = (authUser: any, userModel: UserModel): AuthUser => {

  // userRepo.get(authUser.uid).then(user => {
  return {
    authType: AuthType.FIREBASE,
    uid: authUser.uid,
    displayName: authUser.displayName,
    email: authUser.email,
    role: authRole.user,
    photoURL: authUser.photoURL,
    token: authUser.refreshToken,
    user: userModel,
    emailVerified: authUser.emailVerified,
  };
  // }).catch(error => {
  //   console.error(error);
  //   throw new Error("Something went wrong getting user data. Please refresh the page");
  // });
  // return <AuthUser>{};
};

export const setUserOnSignIn = (authUser: firebase.default.User): any => {

  return async (dispatch: Dispatch<AppActions>) => {

    if (authUser) {

      if (authUser?.emailVerified) {
        dispatch(fetchStart());
        let user = await userRepo.get(authUser.uid); // await makes it NOT go to /signin screen
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_AUTH_USER,
          payload: getUserObject(authUser, user),
        });
      } else {
        console.log(`[st] sendEmailVerification seuseronsignin `);
        auth.currentUser?.sendEmailVerification()
          .then(() => console.log(`[st] Verification email sent to ${auth.currentUser?.email}`))
          //dispatch(showMessage("Verification email sent!")))
          .catch((e: any) => {
            console.error(e);
            // dispatch(fetchError("Hmm.. that didn't quite work. Please try again"))
          })
        dispatch({
          type: AWAITING_VERIFICATION,
          payload: true,
        });
      }

    }
    // dispatch(fetchStart());
    // authUser && userRepo.get(authUser.uid).then(user => {

    //   dispatch(fetchSuccess());
    //   if (authUser?.emailVerified) {
    //     dispatch({
    //       type: UPDATE_AUTH_USER,
    //       payload: getUserObject(authUser, user),
    //     });
    //   } else {
    //     auth.currentUser && auth.currentUser?.sendEmailVerification()
    //     .then(() => console.log(`[st] Verification email sent to ${auth.currentUser?.email}`))
    //     //dispatch(showMessage("Verification email sent!")))
    //       .catch((e: any) => {
    //         console.error(e);
    //         // dispatch(fetchError("Hmm.. that didn't quite work. Please try again"))
    //       })
    //     dispatch({
    //       type: AWAITING_VERIFICATION,
    //       payload: true,
    //     });
    //   }

    // }).catch(error => { dispatch(fetchError(error)) })
  }
}

export const onSignInFirebaseUser = (email: string, password: string): any => {

  return (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(fetchStart());
      console.log(`[st] onSignInFirebaseUser start `);

      // auth.fetchSignInMethodsForEmail(email).then((x) => console.log(`[st] ,ethods ${x}`));

      auth
        .signInWithEmailAndPassword(email, password)
        .then(data => {

          //TODO do we need to dispatch setUserOnSignIn?
          data.user && dispatch(setUserOnSignIn(data.user));
          dispatch(fetchSuccess());

        }).catch(error => {
          dispatch(fetchError(getFirebaseAuthErrorForUser(error)));

        })
    } catch (error: any) {
      console.log(`[st] onSignInFirebaseUser err1  `);
      // console.error("caught in firebase"+ error);
      // dispatch(fetchError(error.message));
    }
  };
};

export const onSignOutFirebaseUser = (): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      auth
        .signOut()
        .then((data) => {
          dispatch(fetchSuccess());
          dispatch({ type: UPDATE_AUTH_USER, payload: null });
        })
        .catch((error) => {
          dispatch(fetchError(error.message));
        });
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};

export const signInUserWithGoogle = (): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      auth
        .signInWithPopup(googleAuthProvider)
        .then(data => {
          //TODO do we need to dispatch setUserOnSignIn?
          data.user && setUserOnSignIn(data.user);
          dispatch(fetchSuccess());
        })
        .catch((error) => {
          dispatch(fetchError(error.message));
        });
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};
export const signInUserWithGithub = () : any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      auth
        .signInWithPopup(githubAuthProvider)
        .then(data => {
          //TODO do we need to dispatch setUserOnSignIn?
          data.user && setUserOnSignIn(data.user);
          dispatch(fetchSuccess());
        })
        .catch((error) => {
          dispatch(fetchError(error.message));
        });
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};

export const signInUserWithFacebook = (): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      auth
        .signInWithPopup(facebookAuthProvider)
        .then(data => {

          //TODO do we need to dispatch setUserOnSignIn?
          data.user && setUserOnSignIn(data.user);
          dispatch(fetchSuccess());
        })
        .catch((error) => {
          dispatch(fetchError(error.message));
        });
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};

export const signInUserWithTwitter = () => {
  return (dispatch: Dispatch<AppActions>) => {
    // dispatch(fetchStart());
    try {
      auth
        .signInWithPopup(twitterAuthProvider)
        .then(data => {
          //TODO do we need to dispatch setUserOnSignIn?
          data.user && setUserOnSignIn(data.user);
          dispatch(fetchSuccess());
        })
        .catch((error) => {
          dispatch(fetchError(error.message));
        });
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};

function getFirebaseAuthErrorForUser(error: any): any {
  console.error(JSON.stringify(error));
  switch (error.code) {
    case "auth/user-not-found":
      return "User not found. Please try signing up!";
    case "auth/wrong-password":

      return "Invalid email or password. Please try again, or try resetting your password";

    default:
      return error.message;
    // return "Hmm... Something went wrong. Please try again"
  }
}

export const onUpdateFirebaseUser = ({
  firstName,
  lastName,
  organization,
  password,
}: {
  firstName: string;
  lastName: string;
  organization: string;
  password: string;
}) => {
  return async (dispatch: Dispatch<AppActions | any>) => {
    dispatch(fetchStart());
    try {
      // console.log({firstName, lastName, organization, password});
      const authUser = auth.currentUser;
      if (authUser) {
        await authUser.updateProfile({
          displayName: firstName + ' ' + lastName,
        });
        // console.log(user,"onUpdateFirebaseUser");
        dispatch(fetchSuccess());
        await firestore.collection('Users').doc(authUser.uid).update({ firstName, lastName, organization })
        let user = await userRepo.get(authUser.uid); // await makes it NOT go to /signin screen
        dispatch({
          type: UPDATE_AUTH_USER,
          payload: getUserObject(authUser, user),
        });
        // await user.updatePassword(password);
      } else {
        throw new Error('no user found');
      }
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};
