import React from 'react';
import { render } from 'react-dom';
import { history } from 'redux/store';
import {authRole, initialUrl} from '../../shared/constants/AppConst';

function RerouteToHome () {

  // render(
    return (history.push(initialUrl), React.lazy(() => import('./Dashboard')))
  // )

}

export const homeConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/home/spaces',
        component: React.lazy(() => import('./Dashboard')),
      }
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/home/space/:did/project/:pDid?/:menu?/:mid?',
        component: React.lazy(() => import('./SpaceDetail')),
      },
    ],
  },
  // {
  //   auth: authRole.user,
  //   routes: [
  //     {
  //       path: '*',
  //       component: <RerouteToHome/>,
  //     },
  //   ],
  // },
];

