import React from 'react';
import Box from '@material-ui/core/Box';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import IntlMessages from '../../../../../../../../@crema/utility/IntlMessages';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { Fonts } from '../../../../../../../../shared/constants/AppEnums';

const useStyles = makeStyles((theme) => ({
    // iconRoot: {
    //     marginRight: 8,
    //     color: grey[600],
    //     cursor: 'pointer',
    // },
    pointer: {
        cursor: 'pointer',
    },
    iconButton: {
        // '&$width': '0.5em',
        sizeSmall: {
            width: '0.5em',
            height: '0.5em',
        },
        cursor: 'pointer',
        // width: 50,
        // height: "100%",
        backgroundColor: 'transparent',
        paddingTop: '4px',
        // paddingBottom: '1px',
        color: theme.palette.secondary.main,
        '&:hover': {
            background: 'transparent',
        },
    },
}));

interface AttachementsProps {
    onClickDeleteIcon: (event: React.MouseEvent<HTMLElement>) => void;
    onAddAttachments: (files: any[]) => void;
}

const Attachments: React.FC<AttachementsProps> = ({
    onClickDeleteIcon,
    onAddAttachments,
}) => {
    const { getRootProps, getInputProps } = useDropzone({
        multiple:false,
        accept: 'application/pdf,image/jpeg,image/png,image/gif,video/mp4',
        onDrop: acceptedFiles => {
            const files = acceptedFiles.map(file => {
                return {
                    id: Math.floor(Math.random() * 10000),
                    file,
                    preview: URL.createObjectURL(file),
                };
            });
            onAddAttachments(files);
        },
    });

    const classes = useStyles();

    return (
        <Box px={3} display='flex' alignItems='center'>
            <Box {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <AttachFileIcon className={classes.iconButton} />
            </Box>
            {/* <Box onClick={onClickDeleteIcon}>
                <DeleteIcon className={classes.iconRoot} />
            </Box> */}
        </Box>
    );
};

export default Attachments;
