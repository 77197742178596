import React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Fonts } from 'shared/constants/AppEnums';
import clsx from 'clsx';
import { PeopleObj, SpaceRole } from 'types/models/home/HomeApp';
import { Tooltip, Typography } from '@material-ui/core';
import { RolePill } from './RolePill';
import { OverflowMenu } from 'modules/home/components/OverflowMenu';

const useStyles = makeStyles((theme) => ({
  contentArea: {
    fontSize: 14,
    width: "100%"
  },
  avatarSize: {
    width: 48,
    height: 48,
  },

  listItemRoot: {
    backgroundColor: 'white',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    padding: '8px 20px',
    marginBottom: '7px',
    '&:hover': {
      border: "1px solid grey",
    },
    // '&:not(:last-child)': {
    //   borderBottom: '1px solid #ECEDF1',
    // },
    width: "100%",
    // margin: '5px'
  },

}));

interface IProps {
  // listStyle: string;
  key: string;
  item: PeopleObj;
  setDeleteDialogOpen: (isDeleteDialogOpen: boolean) => void;
  setSelectedUser: (selectedUser: PeopleObj) => void;
  currentPeopleSpaceRole: PeopleObj | undefined;
}
const PeopleItem = ({ item, setDeleteDialogOpen, setSelectedUser, currentPeopleSpaceRole }: IProps) => {
  const classes = useStyles();

  return (
    // <Tooltip arrow  title={item.email} >
    <Box className={clsx(classes.listItemRoot, 'item-hover')}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          mr={4}
          clone>
          <Avatar className={classes.avatarSize} src={''} />
        </Box>
        <Box className={classes.contentArea}>
          <Box mb={0.5} fontWeight={Fonts.MEDIUM}>
            {item.name?.trim() || item.email}
          </Box>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >
            <RolePill role={item.role} inviteStatus={item.inviteStatus} />
            {item.inviteStatus && <div style={{
                marginLeft: '20px',
                padding: '3px 10px',
                backgroundColor: 'rgb(255, 255, 0, 0.2)',
                borderRadius: 15,
                display: 'inline-block',
                whiteSpace: 'nowrap'
              }}>
                <Typography style={{ color: 'rgb(255, 0, 0, 0.9)', fontSize: '12px' }}>{"Invited"}</Typography>
              </div>}
          </div>
        </Box>
      </div>
      {currentPeopleSpaceRole?.role == SpaceRole.OWNER && <span style={{ marginLeft: 'auto', justifySelf: 'flex-end' }}>
        <OverflowMenu menuItems={[
          { title: "Delete", onClick: () => { setDeleteDialogOpen(true); setSelectedUser(item); } },
        ]} />
      </span>}
    </Box>
    // </Tooltip>
  );
};

export default PeopleItem;

PeopleItem.propTypes = {
  item: PropTypes.object.isRequired,
};
