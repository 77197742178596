import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Switch,
  Grid,
  IconButton,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { canEdit } from '@crema/utility/Utils';
import { Fonts } from 'shared/constants/AppEnums';
import Layout from '../layout';
import ModelUploader from './ModelUploader';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import { onGetCatalogItems } from 'redux/actions/Home';
import { makeModelsAccessible } from 'modules/home/SpaceDetail/utils';
import DeleteIcon from '@material-ui/icons/Delete';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { fetchError, showMessage } from 'redux/actions';
import TitlebarGridList from '@crema/core/TitlebarGridList';
import InsertedObjects from '../ThreeDSidebar/InsertedObjects';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { SpaceRole } from 'types/models/home/HomeApp';
import { AuthUser } from 'types/models/AuthUser';
import { Disable } from 'react-disable';


const useStyles = makeStyles((theme) =>
  createStyles({
    gridRoot: {
      paddingLeft: '10px',
      paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '16px'
    },
    fieldLabel: {
      // paddingRight: '16px',
      fontSize: '13px',
      color: 'black',
      width: '100%',
      marginBottom: '8px',
      marginTop: '10px'
    },
    textField: {
      // width: '100%',
      fontWeight: Fonts.LIGHT,
      marginBottom: 20,
      marginTop: 0,
      // marginLeft: 16,
      // marginLeft: 5,
      // marginRight: 5
      '& .MuiOutlinedInput-input': {
        padding: "10px 14px"
      },
      '& .MuiInputBase-root': {
        fontSize: '14px'

      }
    },
    margin: {
      margin: theme.spacing(1),
    },
    heading: {
      fontWeight: 500,
      color: 'black',
      lineHeight: '48px',
    },
    deleteBadge: {
      '& .MuiBadge-colorPrimary': {
        backgroundColor: 'transparent'

      }
    }

  }),
);

interface Props {
  // myUploadsOnly?: boolean;
}

export default function CatalogSidebar({ }: Props) {
  const spaceMembershipRole = useSelector<
    AppState,
    SpaceRole | undefined
  >(({ home }) => home.currentSpace?.spaceMembership?.role);

  const catalogItems = useSelector<
    AppState,
    any[]
  >(({ home }) => home.catalogItems);

  const authUser = useSelector<
    AppState,
    AuthUser | null
  >(({ auth }) => auth.authUser);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [myUploadsOnly, setMyUploadsOnly] = useState(false);
  const [adminView, setAdminView] = useState(false);
  // const [catalogItems, setCatalogItems] = useState<any[]>([]);
  let catalogItemsForGrid = [];
  //TODO catalaogItems don't refresh on add or delete - it may slow to load?
  for (let i = 0; i < catalogItems.length; i = i + 3) {
    catalogItemsForGrid.push(catalogItems.slice(i, i + 3));
  }

  useEffect(() => {
    dispatch(onGetCatalogItems());
  }, []);

  function isCatalogItemVisible(catItem: any) {

    if (adminView) {
      return true;
    }
    // if (!myUploadsOnly) {
    //   return !catItem.isPrivate;
    // } else {
    //   // catItem.isPrivate &&
    //   return catItem.createdBy == authUser?.uid;
    // }

    if (myUploadsOnly) {
      return catItem.createdBy == authUser?.uid;
    }

    return !catItem.isPrivate || catItem.createdBy == authUser?.uid;
    // catItem.isPrivate &&
    // return catItem.isPublic;


    // return (!catItem.isPrivate ||
    //   (catItem.isPrivate && catItem.createdBy == authUser?.uid) ||
    //   (authUser && authUser.user.permissions && authUser.user.permissions.includes("MODEL_ADMIN")))
  }

  function addObject(catItem: any) {
    Simulation.instance.addObject(catItem);
  }


  return (console.log(`[st] rendering Catalog sidebar`),
    <Disable disabled={!canEdit()}>
      <Layout>

        {isUploadOpen && <ModelUploader isUploadOpen={isUploadOpen} setClose={() => {
          setUploadOpen(false);
        }} />}

        {/* <Typography
        variant='h6'
        align='center'
        className={classes.heading}
        gutterBottom> */}
        {/* <div className={'h7'}>
        3D Catalog
      </div> */}
        {/* </Typography> */}

        <FormControlLabel
          style={{ textAlign: "center", marginBottom: '20px', display: 'block' }}
          control={
            <Switch
              checked={myUploadsOnly}
              onChange={(e: any) => setMyUploadsOnly(!myUploadsOnly)}
              name="checkedB"
              color="primary"
            />
          }
          label="My Uploads"
        />

        {/* <Switch

      label={'My Uploads'}
      variant='outlined' color='secondary' size='small'
        onDelete={() => {
          setMyUploadsOnly(!myUploadsOnly)
        }}
      /> */}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {spaceMembershipRole && canEdit(spaceMembershipRole) && (
            <Button
              variant='contained'
              color='secondary'
              size='small'
              style={{ height: '90%', marginBottom: '20px' }}
              type='button'
              onClick={() => setUploadOpen(true)}>
              Upload
            </Button>
          )}

          {authUser && authUser.user.permissions && authUser.user.permissions.includes("MODEL_ADMIN") && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                variant='outlined'
                color='secondary'
                size='small'
                style={{ height: '90%', margin: '10px' }}
                type='button'
                onClick={() => makeModelsAccessible()}>
                Fix Models (admin)
              </Button>
              <Box>
                <FormControlLabel
                  style={{ textAlign: "center", marginBottom: '20px', display: 'block' }}
                  control={
                    <Switch
                      checked={adminView}
                      onChange={(e: any) => setAdminView(!adminView)}
                      name="adminView"
                      color="primary"
                    />
                  }
                  label="Admin View"
                />
              </Box>
            </div>
          )}
        </Box>

        {/* {console.log(`[st] catalogItems ${catalogItems[0] && JSON.stringify(catalogItems[0])} }`)} */}
        {catalogItems.length > 0 && <TitlebarGridList tileData={
          catalogItems.filter(catItem => isCatalogItemVisible(catItem)).map((catItem: any) => ({
            id: catItem.id,
            img: catItem.thumbnailUrl,
            title: catItem.name,
            onClick: () => addObject(JSON.stringify(catItem)),
            onDelete: () => deleteCatalogItem(catItem),
            isDisabled: (
              // alert(!(authUser && authUser.user.permissions && authUser.user.permissions.includes("MODEL_ADMIN")) && !(catItem.createdBy === authUser?.uid) + " - " + catItem.name),
              !(authUser && authUser.user.permissions && authUser.user.permissions.includes("MODEL_ADMIN")) && !(catItem.createdBy === authUser?.uid))
          }))
        }
          hasOverflowMenu={true}
        />}

        {/*
      <Grid container direction="column" spacing={8} className={classes.gridRoot}>
        {catalogItemsForGrid.map((item: any, index: number) => (
          <Box display="flex" flex-direction="row" >
            {getGridItem(item[0])}

            {item[1] && getGridItem(item[1])}

            {item[2] && getGridItem(item[2])}

          </Box>
        ))}
      </Grid> */}
      </Layout>
    </Disable>
  );


  // function getGridItem(item: any) {
  //   return <Grid item xs={12} md={12} style={{ height: '1000', width: '1000' }}>
  //     <Badge className={classes.deleteBadge} badgeContent={
  //       <IconButton onClick={() => {
  //         firestore.collection(`catalog`).doc(item.id).delete().then(() =>
  //           dispatch(showMessage("Deleted")))
  //           .catch((error) => {
  //             console.error(error);
  //             dispatch(fetchError("Hmm.. that may not have worked. Refresh the page and try again?"))
  //           })
  //       }}>
  //         <DeleteIcon />
  //       </IconButton>} color='primary'>

  //       <IconButton aria-label='delete' className={classes.margin} onClick={() => addObject(JSON.stringify(item))}>
  //         <Box display='flex' flexDirection="column" alignItems="center">
  //           <Avatar variant="square"
  //             style={{ height: 40, width: 40 }}
  //             src={item.icon || item.thumbnailUrl || "https://secure.img1-fg.wfcdn.com/im/84765442/resize-h800-w800%5Ecompr-r85/1502/150206760/Vanity+Table+Set+With+Adjustable+Brightness+Mirror+And+Cushioned+Stool%2C+Dressing+Table+Vanity+Makeup+Table+With+Free+Make-Up+Organizer.jpg"}
  //           />
  //           <Typography variant="body2">{item.name}</Typography>
  //         </Box>
  //       </IconButton>
  //     </Badge>
  //   </Grid>
  // }

  function deleteCatalogItem(catItem: any) {
    console.log(`[st] Deleting ${catItem.id} - ${catItem.name}`);
    let res = window.confirm(`Are you sure you want to delete ${catItem?.name}?`);

    if (res) {

      firestore.collection(`catalog`).doc(catItem.id).delete().then(() => {
        dispatch(showMessage("Deleted"));
        dispatch(onGetCatalogItems());
      }).catch((error: any) => {
        console.error(error);
        dispatch(fetchError("Hmm.. that may not have worked. Refresh the page and try again?"))
      })
    }
  }
}
