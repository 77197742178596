import React, { useContext, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@material-ui/core';
// import SidebarTabPanel from './TabPanel';
import { ShowcaseContext } from '../../';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import Button from '@material-ui/core/Button';
import { canEdit } from '@crema/utility/Utils';
import { ViewMode } from '..';
import { ANNOTATION_TYPE, ShowcaseTag, SIDEBAR_INDEX, SpaceRole } from 'types/models/home/HomeApp';
import Layout from '../layout';
import { showMessage } from 'redux/actions';
import ThreeDAnnotations from '../ThreeDSidebar/ThreeDAnnotations';
import clsx from 'clsx';
import { setCurrentSpaceTagsList, setTagsViewMode } from 'redux/actions/Home';
import { SET_IS_ADDING_TAG } from 'types/actions/Home.action';
import ProjectBoard from 'modules/apps/ProjectBoard';
import { addTagsToEmptyShowcase } from 'modules/home/SpaceDetail/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
      color: 'black',
      lineHeight: '48px',
      textAlign: 'center',
      marginTop: '10px'
    },
    tagsOr3dTab: {
      margin: '10px 5px',
      padding: '5px 2px',
      width: '50%',
      textAlign: 'center'

    },
    tagsOr3dTabActive: {
      color: 'black',
      borderBottom: '3px solid #315F72',
      borderRadius: '2px',

    },
    tagsOr3dTabInActive: {
      // color: 'black',

      cursor: 'pointer',
      '&:hover': {
        borderBottom: '3px solid #315F7250',
        borderRadius: '2px'
      }
    }
  }),
);
interface Props {
  // importMatterportTags(): void;
  // tagsAlreadyImported: boolean;
  // addObject(objectName: string): void;
}

export default function TaskSidebar({
  // importMatterportTags,
  // tagsAlreadyImported,
  // addObject,
  // onTagsTabClick,
  // on3DTabClick
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const showcaseContext = useContext(ShowcaseContext);

  // const spaceRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home?.currentSpace?.spaceMembership?.role);
  // const tagsViewMode = useSelector<AppState, string>(({ home }) => home.tagsViewMode);
  // const tagsImported = useSelector<AppState, boolean>(({ home }) => home?.currentSpace?.tagsImported || true);
  const spaceTags = useSelector<AppState, Map<string, ShowcaseTag>>(({ home }) => home.spaceTags);
  const currentSidebarIndex = useSelector<AppState, SIDEBAR_INDEX>(({ home }) => home.currentSidebarIndex);

  // let visibleSpaceTags: ShowcaseTag[] = [];
  // let sidebarTitle = '';

  // // visibleSpaceTags = getFilteredTags({tagsViewMode: 'projectStatus'}, spaceTags);
  // if (tagsViewMode == ViewMode.STATUS) {
  //   visibleSpaceTags = Array.from(spaceTags.values()).filter(
  //     (t) => t.annotationType && t.annotationType.startsWith(ANNOTATION_TYPE.TASK),
  //   );
  //   sidebarTitle = 'Project Status';
  // } else {
  //   visibleSpaceTags = Array.from(spaceTags.values());
  //   sidebarTitle = 'Tags';
  // }

  useEffect(() => {
    let visibleSpaceTags: ShowcaseTag[] = [];
    if (currentSidebarIndex == SIDEBAR_INDEX.TASKS) {
      visibleSpaceTags = Array.from(spaceTags.values()).filter(
        (t) => t.annotationType && t.annotationType.startsWith(ANNOTATION_TYPE.TASK),
      );
    } else {
      // visibleSpaceTags = getTagsForTab(value);
    }
    addTagsToEmptyShowcase(Array.from(visibleSpaceTags).map(tag => tag.id));
    console.log(`[st] setstate setting setCurrentSpaceTagsList 1`);
    dispatch(setCurrentSpaceTagsList(visibleSpaceTags))
  }, [spaceTags, currentSidebarIndex])


  return (console.log(`[st] rendering TaskSidebar`),
    <div style={{ height: "100%", padding: '16px',
    // width: '100%'
    minWidth: '550px',
      maxWidth: '600px',
     }}>
      <ProjectBoard />

      {/* // <div style={{ display: 'flex', flexDirection: 'row',
      // // justifyContent: 'space-evenly',
      // alignItems: 'center' }} >
      // {(tagsViewMode != ViewMode.STATUS)&&<>
      //   <div onClick={() => { tagsViewMode !== ViewMode.TAGS && dispatch(setTagsViewMode(ViewMode.TAGS)) }} className={clsx(classes.tagsOr3dTab, tagsViewMode == ViewMode.TAGS ? classes.tagsOr3dTabActive : classes.tagsOr3dTabInActive)}> */}
      {/* <Typography
        // variant='contained'
        variant='h6'
        // component='span'
      // align='center'
      // color='secondary'

      className={classes.heading}
      // gutterBottom

      >
        Tags
      </Typography> */}
      {/*<div style={{
        display: 'flex', flexDirection: 'row',
        // justifyContent: 'space-evenly',
        alignItems: 'center'
      }} >
        <div onClick={
          () => { tagsViewMode !== ViewMode.TAGS && dispatch(setTagsViewMode(ViewMode.TAGS)) }}
          className={clsx(classes.tagsOr3dTab, tagsViewMode == ViewMode.TAGS ? classes.tagsOr3dTabActive : classes.tagsOr3dTabInActive)}
          >
          <Typography
            // variant='contained'
            variant='h6'
            component='span'
          // align='center'
          // color='secondary'

          // className={classes.heading}
          // gutterBottom

          >
            TAGS
          </Typography>


        </div>
         <Divider orientation='vertical' />
        { <div onClick={() => {
          tagsViewMode !== ViewMode.MODELS && dispatch(setTagsViewMode(ViewMode.MODELS))
        }}

          className={clsx(classes.tagsOr3dTab,
            tagsViewMode == ViewMode.MODELS ? classes.tagsOr3dTabActive : classes.tagsOr3dTabInActive)}>
          <Typography
            // variant='contained'
            variant='h5'
          // align='center'
          // color={'secondary'}

          // className={classes.heading}
          // gutterBottom

          >
            3D
          </Typography>
        </div>
        </>}
        {(tagsViewMode == ViewMode.STATUS) &&
         <div style={{width:'100%'}} onClick={() => { tagsViewMode !== ViewMode.STATUS && dispatch(setTagsViewMode(ViewMode.STATUS))}}
           className={clsx(classes.tagsOr3dTab, tagsViewMode == ViewMode.STATUS ? classes.tagsOr3dTabActive : classes.tagsOr3dTabInActive)}
         >
         <Typography
           // variant='contained'
           variant='h5'
         // align='center'
         // color={'secondary'}

         // className={classes.heading}
         // gutterBottom

         >
           PROJECT STATUS
         </Typography>
       </div>}
      </div>
          </div>*/}

      {/* <Divider /> */}
      {/* <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 10,
        }}> */}
      {/* {currentSpace && canEdit(currentSpace.spaceMembership.role) && (
          <Fab
            size='small'
            color='secondary'
            aria-label='add'
            onClick={() => showcaseContext.handleAddTag()}>
            <AddIcon />
          </Fab>*/}

      {/* {spaceRole && canEdit(spaceRole) &&
          <Button
            variant='contained'
            color='secondary'
            size='small'
            style={{ height: '90%', marginTop: '10px' }}
            type='button'
            onClick={() => {
              dispatch(showMessage("Move cursor into the Space area to add a tag!"))
              // showcaseContext.handleAddTag();
              dispatch({ type: SET_IS_ADDING_TAG, payload: true })
            }
            }>
            Add tag
          </Button>} */}


      {/* {canEdit(currentSpace.spaceMembership.role) && (
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              // style={{width: "80%"}}
              // type='button'
              onClick={() => handleAddTag()}>
              Delete all tags
            </Button>
          )} */}
      {/* </Box> */}

      {/* <Box> */}
      {/* <ProjectBoard /> */}
      {/* </Box> */}
      {/* <Box marginTop="15px" marginBottom="15px">
        <AvatarGrid isSizeSmall={true} dataList={["Info", "Image", "Video", "Audio", "Files"].map(x =>
          { return {imageName: x, imageSrc: `/assets/images/sidebarIcons/${x}.png`} }
        )
        } />
      </Box> */}

      {/* <Divider orientation='horizontal' variant='middle' /> */}

      {/* {
        !tagsImported &&
        spaceRole && canEdit(spaceRole) &&
        // tagsViewMode == ViewMode.TAGS && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}><Link
          type='underlineAlways'
          //  underline='always'
          style={{ margin: 10, textAlign: 'center', cursor: 'pointer' }}
          onClick={() => showcaseContext.importMatterportTags()}>
          Import Matterport Tags
        </Link></div>
        // )
      } */}
      {/* {
        // (tagsViewMode == ViewMode.TAGS || tagsViewMode == ViewMode.LAYERS_TAGS || tagsViewMode == ViewMode.STATUS) &&
        <SidebarTabPanel
        // importMatterportTags={showcaseContext.importMatterportTags}
        // tagsAlreadyImported={tagsAlreadyImported}
        />} */}
      {
        // (tagsViewMode == ViewMode.MODELS || tagsViewMode == ViewMode.LAYERS_TAGS) &&
        // <ObjectLibrary
        //   addObject={addObject}
        // />
      }
    </div>
  );
}
