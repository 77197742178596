import * as THREE from 'three';
import {CameraSystem} from './CameraSystem';
import {RenderingSystem} from './RenderingSystem';

export class RaycastSystem {
    protected pointer: THREE.Vector2;
    protected raycaster: THREE.Raycaster;
    
    protected oldScales: Map<THREE.Object3D, THREE.Vector3>;
    
    constructor(protected renderSystem: RenderingSystem) {
        this.pointer = new THREE.Vector2();
        this.raycaster = new THREE.Raycaster();
        
        this.renderSystem.cameraSystem.windowingSystem.inputSystem.registerMouseMoveCallback(this.onPointerMove.bind(this));
        
        this.renderSystem.cameraSystem.windowingSystem.registerFrameUpdateCallback(this.onEnterFrame.bind(this));
        
        this.oldScales = new Map<THREE.Object3D, THREE.Vector3>();
    }
    
    onEnterFrame() {
        this.raycaster.setFromCamera(this.pointer, this.renderSystem.cameraSystem.camera);
        
        // this.cameraSystem.windowingSystem
        
        let objects = this.renderSystem.base.children;
        
        for (const object of objects) {
            let mapEntry = this.oldScales.get(object);
            if (!mapEntry) {
                mapEntry = object.scale.clone();
                this.oldScales.set(object, mapEntry);
                
            }
            
            
            if (this.renderSystem.cameraSystem.windowingSystem.inputSystem.MouseDown) {
                const intersection = this.raycaster.intersectObject(object, true);
                if (intersection.length > 0) {
                    object.scale.set(mapEntry.x + 0.2, mapEntry.y + 0.2, mapEntry.z + 0.2);
                }
            } else {
                object.scale.set(mapEntry.x, mapEntry.y, mapEntry.z);
            }
        }
        
        
    }
    
    protected onPointerMove(e: MouseEvent) {
        this.pointer.x = (e.clientX / this.renderSystem.cameraSystem.windowingSystem.width) * 2 - 1;
        this.pointer.y = -(e.clientY / this.renderSystem.cameraSystem.windowingSystem.height) * 2 + 1;
    }
}
