import {
    Texture,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Euler,
    MathUtils,
    Wrapping,
    ClampToEdgeWrapping,
    RepeatWrapping,
    Material,
    ShapeGeometry,
} from 'three';
import {SceneComponent, ComponentInteractionType} from '../../../SubSystems/sceneManagement/SceneComponent';
import Utils from '../../../Tools/Utils';
import * as THREE from 'three';
import {EulerIntegrator} from '../../systemComponents/EulerIntegrator';
import {CanvasRenderer} from '../../CanvasComponents/CanvasRenderer';
import CardinalAxesAndPlanes from '../../../Tools/CardinalAxesAndPlanes';
import QueueScheduler from '../../../Tools/QueueScheduler';
import {CanvasText} from '../../CanvasComponents/CanvasText';
import {CanvasBorder} from '../../CanvasComponents/CanvasBorder';

export type Size = {w: number; h: number;};
export type SizeScale = {x: number; y: number;};

type Inputs = {
    texture: Texture | null;
    aspect: number;
    transparent: boolean;
    visible: boolean;
    opacity: number;
    color: number;
    borderRadius: number;
    polygonOffset: boolean;
    polygonOffsetFactor: number;
    polygonOffsetUnits: number;
    invertScale: boolean;
    //planeSize: { w:number, y:number };
    localScale: {x: number; y: number; z: number;};
    localPosition: {x: number; y: number; z: number;};
    localRotation: {x: number; y: number; z: number;};
}

export class PlaneRenderer extends SceneComponent implements IPlaneRenderer {
    public canvasText: CanvasText | null = null;
    public canvasRenderer: CanvasRenderer | null = null;
    inputs: Inputs = {
        texture: null,
        aspect: 1,
        transparent: true,
        visible: true,
        opacity: 1,
        color: 0xffffff,
        polygonOffset: false,
        borderRadius: 10,
        polygonOffsetFactor: 0,
        polygonOffsetUnits: 0,
        invertScale: false,
        localScale: {x: 1, y: 1, z: 1},
        localPosition: {x: 0, y: 0, z: 0},
        localRotation: {x: 0, y: 0, z: 0},
    };
    events = {
        [ComponentInteractionType.CLICK]: true,
    };
    private mesh: Mesh | null = null;
    private pivotNode: Object3D;
    private oldRootScale: THREE.Vector2;
    private isTextBox: boolean = false;
    private textCanvasRenderer: CanvasRenderer | null = null;
    private textCanvasText: CanvasText | null = null;
    private textCanvasBorder: CanvasBorder | null = null;
    private rebuildMeshQueue: QueueScheduler<any>;
    
    buildMesh(any: any | null = null): boolean {
        const THREE = this.context.three;
        
        if (this.mesh) {
            this.pivotNode.remove(this.mesh);
            this.mesh.geometry.dispose();
            (this.mesh.material as Material).dispose();
            this.mesh = null;
        }
        
        if (this.inputs.invertScale) {
            this.mesh = new THREE.Mesh(
                //new THREE.PlaneBufferGeometry(this.oldRootScale.x, this.oldRootScale.y),
                new THREE.ShapeGeometry(Utils.RoundedRectShape(1, 1, this.inputs.borderRadius)).translate(-0.5, -0.5, 0),//.scale(1, this.oldRootScale.y, 1),
                new THREE.MeshBasicMaterial({
                    transparent: this.inputs.transparent,
                    map: this.inputs.texture,
                    opacity: this.inputs.opacity,
                    color: this.inputs.color,
                    polygonOffset: this.inputs.polygonOffset,
                    polygonOffsetFactor: this.inputs.polygonOffsetFactor,
                    polygonOffsetUnits: this.inputs.polygonOffsetUnits,
                    side: THREE.DoubleSide,
                }));
            //this.mesh!.scale.set(1.0/this.oldRootScale.x, 1.0/this.oldRootScale.y, 1);
            
            var geometry = (this.mesh!.geometry as ShapeGeometry);
            geometry.computeBoundingBox();
            var max = geometry.boundingBox!.max,
                min = geometry.boundingBox!.min;
            
            var inverseOldRootScale = CardinalAxesAndPlanes.instance.unitVector2.clone().divide(this.oldRootScale);
            var offset = inverseOldRootScale.clone().multiplyScalar(0.5);//new THREE.Vector2(0 - min.x, 0 - min.y);
            var range = new THREE.Vector2(max.x - min.x, max.y - min.y);
            var faces = geometry.faces;
            
            range.multiply(inverseOldRootScale);
            
            geometry.faceVertexUvs[0] = [];
            
            var rangeInverse = CardinalAxesAndPlanes.instance.unitVector2.clone().divide(range);
            
            for (var i = 0; i < faces.length; i++) {
                
                var v1 = geometry.vertices[faces[i].a],
                    v2 = geometry.vertices[faces[i].b],
                    v3 = geometry.vertices[faces[i].c];
                
                geometry.faceVertexUvs[0].push([
                    new THREE.Vector2((v1.x + offset.x) * rangeInverse.x, (v1.y + offset.y) * rangeInverse.y),
                    new THREE.Vector2((v2.x + offset.x) * rangeInverse.x, (v2.y + offset.y) * rangeInverse.y),
                    new THREE.Vector2((v3.x + offset.x) * rangeInverse.x, (v3.y + offset.y) * rangeInverse.y),
                ]);
            }
            this.mesh!.scale.set(1.0, 1.0, 1);
        } else {
            if (this.isTextBox && this.textCanvasRenderer && this.textCanvasText) {
                this.textCanvasRenderer.inputs.sizeScale = {x: this.oldRootScale.x, y: this.oldRootScale.y};
                this.textCanvasText.inputs.sizeScale = {x: this.oldRootScale.x, y: this.oldRootScale.y};
            }
            
            this.mesh = new THREE.Mesh(
                //new THREE.PlaneBufferGeometry(2, 1),
                new THREE.ShapeGeometry(Utils.RoundedRectShape(this.oldRootScale.x, this.oldRootScale.y, this.inputs.borderRadius)).translate(-0.5 * this.oldRootScale.x, -0.5 * this.oldRootScale.y, 0),//.scale(1, this.oldRootScale.y, 1),
                //new THREE.ShapeGeometry(Utils.RoundedRectShape(this.context.root.scale.x, this.context.root.scale.y, this.inputs.borderRadius)).translate(-this.context.root.scale.x*0.5, -this.context.root.scale.y*0.5, 0),//.scale(1, this.oldRootScale.y, 1),
                new THREE.MeshBasicMaterial({
                    transparent: this.inputs.transparent,
                    map: this.inputs.texture,
                    opacity: this.inputs.opacity,
                    color: this.inputs.color,
                    polygonOffset: this.inputs.polygonOffset,
                    polygonOffsetFactor: this.inputs.polygonOffsetFactor,
                    polygonOffsetUnits: this.inputs.polygonOffsetUnits,
                    side: THREE.DoubleSide,
                }));
            
            var geometry = (this.mesh!.geometry as ShapeGeometry);
            geometry.computeBoundingBox();
            var max = geometry.boundingBox!.max,
                min = geometry.boundingBox!.min;
            
            var inverseOldRootScale = new THREE.Vector2(this.oldRootScale.x, this.oldRootScale.y);//  CardinalAxesAndPlanes.instance.unitVector2.clone().divide(this.oldRootScale);
            var offset = inverseOldRootScale.clone().multiplyScalar(0.5);//new THREE.Vector2(0 - min.x, 0 - min.y);
            var range = new THREE.Vector2(max.x - min.x, max.y - min.y);
            var faces = geometry.faces;
            
            geometry.faceVertexUvs[0] = [];
            
            var rangeInverse = CardinalAxesAndPlanes.instance.unitVector2.clone().divide(range);
            
            for (var i = 0; i < faces.length; i++) {
                
                var v1 = geometry.vertices[faces[i].a],
                    v2 = geometry.vertices[faces[i].b],
                    v3 = geometry.vertices[faces[i].c];
                
                geometry.faceVertexUvs[0].push([
                    new THREE.Vector2((v1.x + offset.x) * rangeInverse.x, (v1.y + offset.y) * rangeInverse.y),
                    new THREE.Vector2((v2.x + offset.x) * rangeInverse.x, (v2.y + offset.y) * rangeInverse.y),
                    new THREE.Vector2((v3.x + offset.x) * rangeInverse.x, (v3.y + offset.y) * rangeInverse.y),
                ]);
            }
            
            this.mesh!.scale.set(this.inputs.localScale.x / this.oldRootScale.x, this.inputs.localScale.y / this.oldRootScale.y, this.inputs.localScale.z);
        }
        
        /*
        this.context.root.userData["width"] = this.context.root.scale.x;
        this.context.root.userData["height"] = this.context.root.scale.y;
        this.context.root.scale.x = 1;
        this.context.root.scale.y = 1;
        this.context.root.scale.z = 1;*/
        this.mesh!.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);
        this.mesh!.setRotationFromEuler(new Euler(this.inputs.localRotation.x * MathUtils.DEG2RAD, this.inputs.localRotation.y * MathUtils.DEG2RAD, this.inputs.localRotation.z * MathUtils.DEG2RAD));
        this.mesh!.updateMatrixWorld();
        this.pivotNode.add(this.mesh!);
        this.mesh!.visible = this.inputs.visible;
        //this.mesh!.scale.set(1.0/this.oldRootScale.x, 1.0/this.oldRootScale.y, 1);
        
        /*
        const material = this.mesh!.material as MeshBasicMaterial;
        material.map = this.inputs.texture;
        material.needsUpdate = true;*/
        
        return true;
    }
    
    onInit() {
        const THREE = this.context.three;
        
        if (this.context.root.name === 'Text Box') {
            if ((this.context.root.components[4] as any).instance == this) {
                console.log('found');
                this.isTextBox = true;
                this.textCanvasRenderer = (this.context.root.components[5] as any).instance;
                this.textCanvasText = (this.context.root.components[6] as any).instance;
            }
        }
        
        this.pivotNode = new THREE.Group();
        //
        
        this.outputs.objectRoot = this.pivotNode;
        this.outputs.collider = this.pivotNode;
        
        this.rebuildMeshQueue = new QueueScheduler<any>(this.buildMesh.bind(this), 50);
        
        if (this.inputs.invertScale) {
            this.oldRootScale = new THREE.Vector2(0, 0);
            //this.oldRootScale.set(1, 1);
            //this.rebuildMeshQueue.addQueueElement({}, true);
            //this.rebuildMeshQueue.addQueueElement(new THREE.Vector2(this.oldRootScale.x, this.oldRootScale.y), true);
        } else {
            if (this.isTextBox) {
                this.oldRootScale = new THREE.Vector2(0, 0);
                //this.oldRootScale.set(1, 1);
            } else {
                this.oldRootScale = CardinalAxesAndPlanes.instance.unitVector2.clone();
                this.rebuildMeshQueue.addQueueElement({}, true);
            }
        }
    }
    
    
    onTick(delta: number) {
        if (this.inputs.invertScale) {
            
            var rootScale = new THREE.Vector2(this.context.root.scale.x, this.context.root.scale.y);
            if (Math.abs(rootScale.x - this.oldRootScale.x) > 0.1 ||
                Math.abs(rootScale.y - this.oldRootScale.y) > 0.1) {
                this.oldRootScale.set(rootScale.x, rootScale.y);
                this.rebuildMeshQueue.addQueueElement({}, true);
                
            }
        } else {
            if (this.isTextBox) {
                var rootScale = new THREE.Vector2(this.context.root.scale.x, this.context.root.scale.y);
                if (Math.abs(rootScale.x - this.oldRootScale.x) > 0.1 ||
                    Math.abs(rootScale.y - this.oldRootScale.y) > 0.1) {
                    this.oldRootScale.set(rootScale.x, rootScale.y);
                    this.rebuildMeshQueue.addQueueElement({}, true);
                    
                }
            }
        }
    }
    
    onEvent(eventType: string, eventData: unknown) {
        this.notify(eventType, eventData);
    }
    
    onInputsUpdated(oldInputs: Inputs) {
        if (!this.mesh) {
            return;
        }
        
        if (oldInputs.transparent !== this.inputs.transparent) {
            (this.mesh!.material as MeshBasicMaterial).transparent = this.inputs.transparent;
        }
        
        if (oldInputs.texture !== this.inputs.texture) {
            const material = this.mesh!.material as MeshBasicMaterial;
            material.map = this.inputs.texture;
            const THREE = this.context.three;
            //let scale = this.pivotNode.parent!.scale.y;
            //material.map!.offset = new THREE.Vector2(0, 0.25);
            //material.map!.repeat.set(1, 2);
            //material.map!.wrapT = ClampToEdgeWrapping;
            material.needsUpdate = true;
            
        }
        
        if (oldInputs.visible !== this.inputs.visible) {
            this.mesh!.visible = this.inputs.visible;
        }
        
        if (oldInputs.color !== this.inputs.color) {
            // @ts-ignore
            (this.mesh.material as MeshBasicMaterial).color.set(this.inputs.color);
        }
        
        if (oldInputs.opacity !== this.inputs.opacity) {
            // @ts-ignore
            (this.mesh.material as MeshBasicMaterial).opacity = this.inputs.opacity;
        }
        
        if (oldInputs.polygonOffset !== this.inputs.polygonOffset) {
            const material = this.mesh!.material as MeshBasicMaterial;
            material.polygonOffset = this.inputs.polygonOffset;
            material.polygonOffsetFactor = this.inputs.polygonOffsetFactor;
            material.polygonOffsetUnits = this.inputs.polygonOffsetUnits;
        }
        
        this.mesh!.scale.set(this.inputs.localScale.x, this.inputs.localScale.y / this.inputs.aspect, this.inputs.localScale.z);
        this.mesh!.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);
        this.mesh!.setRotationFromEuler(new Euler(this.inputs.localRotation.x * MathUtils.DEG2RAD, this.inputs.localRotation.y * MathUtils.DEG2RAD, this.inputs.localRotation.z * MathUtils.DEG2RAD));
    }
    
    onDestroy() {
        this.outputs.collider = null;
        this.outputs.objectRoot = null;
        
        (this.mesh!.material as MeshBasicMaterial).dispose();
        this.mesh!.geometry.dispose();
    }
    
}

export interface IPlaneRenderer extends SceneComponent {
    inputs: Inputs;
}

export const planeRendererType = 'mp.planeRenderer';

export function makePlaneRenderer() {
    return new PlaneRenderer();
}
