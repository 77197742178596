import { Dispatch } from 'redux';
import { AppActions } from 'types';
import {

  CommonActionTypes,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_DICTAPHONE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,

} from '../../types/actions/Common.action';

export const fetchStart = (): CommonActionTypes => ({ type: FETCH_START });

export const fetchSuccess = (): CommonActionTypes => ({ type: FETCH_SUCCESS });

// export const historyPush = (route: string): any => {
//   return (dispatch: Dispatch<AppActions>) => {

//     history.push()
//   }

// };

// export const showMessage = (message: string): CommonActionTypes => {
//   setTimeout(() => {
//     return (dispatch: Dispatch<AppActions>) => {
//       dispatch(_showMessage("")); //clear message after a second, so the next message can be displayed
//     }
//   }, 6000);
//   return _showMessage(message);
// }

export const showMessage = (message: string): any => {
  return (dispatch: Dispatch<AppActions>) => {
    setTimeout(() => {

      dispatch(_showMessage("")); //clear message after a second, so the next message can be displayed

    }, 6000);
    return dispatch(_showMessage(message));
  }
}

export const fetchError = (error: string): any => {
  return (dispatch: Dispatch<AppActions>) => {

    setTimeout(() => {
      dispatch(_fetchError("")); //clear error message after a second, so next error can be displayed
    }
      , 6000);

    return dispatch(_fetchError(error));

  };
}

const _fetchError = (error: string): CommonActionTypes => {
  return ({ type: FETCH_ERROR, error });

};

const _showMessage = (message: string): CommonActionTypes => ({
  type: SHOW_MESSAGE,
  message,
});

export const onToggleAppDrawer = (): CommonActionTypes => ({
  type: TOGGLE_APP_DRAWER,
});

export const hideMessage = (): CommonActionTypes => ({ type: HIDE_MESSAGE });

export const setShowDictaphone = (value: boolean): CommonActionTypes => ({ type: SHOW_DICTAPHONE, payload: value });