import {GizmoTools} from "../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import { Behaviors, IInteractionDragBeginEvent, IInteractionDragEndEvent, IInteractionDraggingEvent } from "../SubSystems/core/Behaviors";
import Simulation from "../SubSystems/core/Simulation";
import { IComponentEventSpy, ComponentInteractionType, SceneComponent, ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import { PropertiesPanelMode } from "../SubSystems/ui-interop/PropertiesPanel";

export class DragBeginObjectSpy implements IComponentEventSpy<IInteractionDragBeginEvent> {
    public eventType = ComponentInteractionType.DRAG_BEGIN;
    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
    }
    onEvent(payload: IInteractionDragBeginEvent) {
        Behaviors.instance.processAllNodeDragBeginEvents(this.node, payload);
    }
}

export class DragEndObjectSpy implements IComponentEventSpy<IInteractionDragEndEvent> {
    public eventType = ComponentInteractionType.DRAG_END;
    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
    }
    onEvent(payload: IInteractionDragEndEvent) {
        Behaviors.instance.processAllNodeDragEndEvents(this.node, payload);
    }
}

export class DragObjectSpy implements IComponentEventSpy<IInteractionDraggingEvent> {
    public eventType = ComponentInteractionType.DRAG;
    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
    }
    onEvent(payload: IInteractionDraggingEvent) {
        Behaviors.instance.processAllNodeDragEvents(this.node, payload);
    }
}

