import React, { useEffect, useState } from 'react';
import { Box, Button, Tooltip } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'redux/store';
import { Typography } from '@material-ui/core';
import VolumeOff from '@material-ui/icons/VolumeOff';
import VolumeUp from '@material-ui/icons/VolumeUp';
import { TagGroup } from 'types/models/home/HomeApp';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Spacer from '@crema/core/Spacer';
import { useHistory } from 'react-router-dom';

import { onExitPresentationMode } from 'redux/actions/Lesson';

const useStyles = makeStyles((theme) => ({
  bar: {
    position: 'absolute',
    top: '0%',
    textAlign: 'center',
    borderRadius: '15px',
    // margin: '5px 5px',
    // top: "2px",
    width: '100%',
    // height: '60px',
    // maxHeight: '60px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '14px 14px',
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
    alignItems: 'center',
  },
  lessonExitButtonSoloContainer: {
    // margin: '5px 5px',
    position: 'absolute',
    top: '0%',
    // textAlign: 'center',
    // borderRadius: '15px',
    // margin: '5px',
    // top: "2px",
    width: '100%',
    // height: '60px',
    // maxHeight: '60px',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '14px 14px',
    display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
    alignItems: 'center',
  },
  bannerText: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    // fontWeight: 'bold',

    // margin: '0px 150px',
    // maxHeight: '60px',
    // overflow: 'hidden'
  },
  ttsButton: {
    backgroundColor: 'grey',

    // position: 'absolute',
    // right: ' 7px',
    // top: '15px',
    // height: '40px',
    // width: '28px',
    color: 'white',
    // borderRadius: '5px',
    // zIndex: 3

  },
  muteSwitch: {
    backgroundColor: 'grey',
    // position: 'absolute',
    color: 'white',
    // right: '1%',
    // zIndex: 10,
    // marginTop: '71px',
  },
}));
const Banner: React.FC<{}> = () => {
  const classes = useStyles();
  const tagGroupsList = useSelector<AppState, TagGroup[]>(({ home }) => home.tagGroupsList);
  const currentTagGroupId = useSelector<AppState, string>(({ home }) => home.currentTagGroupId);
  const presentationMode = useSelector<AppState, boolean>(({ home }) => home.presentationMode);

  const dispatch = useDispatch();

  let currentTagGroup =
    currentTagGroupId ?
      tagGroupsList.find((tg: any) => tg.id === currentTagGroupId) : null;
  const synth = window.speechSynthesis;
  let ttlMute = localStorage.getItem('ttlMute');
  const [bannerTTL, setMuteBannerTTL] = useState(false);
  const [isPlaying, setIsSoundPlaying] = useState(false);

  // currentTagGroupId && tagGroupsList.find(tg => tg.id === currentTagGroupId)?.bannerText ?
  //     return (<Box className={classes.bar}>
  //   <Typography variant="h6"> {bannerText} </Typography>
  // </Box>)
  //     :
  const playTextToSpeech = (text: string) => {
    var utterThis = new SpeechSynthesisUtterance(text);
    setIsSoundPlaying(true);
    synth.speak(utterThis);
    return;
  };

  const cancelTTL = () => {
    setIsSoundPlaying(false);
    synth.cancel();
  };
  const muteTTLBanners = () => {
    setMuteBannerTTL(!bannerTTL);
    let ttlMute = localStorage.getItem('ttlMute');
    if (ttlMute === 'on') {
      localStorage.setItem('ttlMute', 'off');
    } else {
      localStorage.setItem('ttlMute', 'on');
    }
  };
  useEffect(() => {
    ttlMute === 'off' && cancelTTL();
  }, [ttlMute]);

  useEffect(() => {
    ttlMute === 'on' &&
      !!currentTagGroup?.bannerText &&
      playTextToSpeech(currentTagGroup.bannerText);
  }, [currentTagGroup?.bannerText]);

  useEffect(() => {
    let ttlMute = localStorage.getItem('ttlMute');

    if (ttlMute === 'on') {
      setMuteBannerTTL(false);
    } else {
      setMuteBannerTTL(true);
    }
  }, []);

  const history = useHistory();
  return (console.log(`[st] rendering Banner`),
    currentTagGroup && currentTagGroup.bannerText ? (
      <Box className={classes.bar}>

        <Typography variant='h6' className={classes.bannerText}>{currentTagGroup.bannerText}</Typography>

        <Button variant="contained"
        // className={classes.ttsButton}
        >
          {bannerTTL ? <VolumeOff onClick={() => { muteTTLBanners() }} /> :
            <VolumeUp onClick={() => { muteTTLBanners() }} />}
        </Button>
        <Spacer x={2} />
        {presentationMode && <Button variant="contained" style={{ color: 'white', backgroundColor: 'grey' }}
          // className={classes.ttsButton}
          onClick={onExitPresentationMode}>
              <Tooltip title='Exit Presentation'><LogoutRoundedIcon
                color='warning'
              /></Tooltip>
      </Button>}

    </Box >
  ) : (
  <Box className={classes.lessonExitButtonSoloContainer}>
    <Button variant="contained"
    // className={classes.ttsButton}
    >
      {bannerTTL ? <VolumeOff onClick={() => { muteTTLBanners() }} /> :
        <VolumeUp onClick={() => { muteTTLBanners() }} />}
    </Button>
    <Spacer x={2} />
    {presentationMode && <Button variant="contained" style={{ color: 'white', backgroundColor: 'grey' }}
      // className={classes.ttsButton}
      onClick={onExitPresentationMode}>
      <Tooltip title='Exit Presentation'><LogoutRoundedIcon
        color='warning'
      /></Tooltip>
    </Button>}
  </Box>
));
};

export default Banner;
