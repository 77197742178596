import {
    ComponentInteractionType,
    IComponentEventSpy, ISceneNode,
    SceneComponent,
} from '../SubSystems/sceneManagement/SceneComponent';
import {IInteractionHoverEvent} from '../SubSystems/core/Behaviors';
import Utils from '../Tools/Utils';
import Simulation from '../SubSystems/core/Simulation';

export class GlowHoverSpy implements IComponentEventSpy<IInteractionHoverEvent> {
    public eventType = ComponentInteractionType.HOVER;
    private localEphemeralIndex: number = -1;
    private hoverMode: boolean;

    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
        this.hoverMode = false;
    }

    startGlow(): void {
        if (this.localEphemeralIndex === -1) {
            let mesh = Utils.Find3DRootOfNode(this.node);
            if (mesh) {
                //console.log(mesh);
                let meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(mesh);
                this.localEphemeralIndex = Simulation.instance.outlineComponentColor2.addEphemeralMeshes(meshes!, this.localEphemeralIndex);
            }
        }
    }

    stopGlow(): void {
        if (this.localEphemeralIndex !== -1) {
            Simulation.instance.outlineComponentColor2.removeEphemeralMeshes(this.localEphemeralIndex);
            this.localEphemeralIndex = -1;
        }
    }

    onEvent(payload: IInteractionHoverEvent) {
        // console.log(payload);
        // console.log(this.mainComponent.inputs);
        //Utils.SetMeshesVisibility()
        if (payload.hover) {
            this.hoverMode = true;
        } else {
            this.hoverMode = false;
        }

        if (this.hoverMode) {
            this.startGlow();
        } else {
            this.stopGlow();
        }
        // console.log(`[st] GLOW hovering ${JSON.stringify( payload.point)} ${JSON.stringify( payload.normal )}`);
        //Behaviors.instance.processAllNodeDragBeginEvents(this.node, payload);
        // console.log("hover");
        //let meshes = Utils.FindAllMeshesAndLineSegments(this.node);
        //Simulation.instance.highlightModel(this.node.userData[UserDataProperties.id], meshes);
        //console.log("highlighting")
        //let meshes = Utils.FindAllMeshesAndLineSegments(targetNode);
    }
}
