import React from 'react';
import Card from '@material-ui/core/Card';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import moment from 'moment';
import {Box} from '@material-ui/core';
import Collaborators from './Collaborators';
import Labels from './Labels';
import {makeStyles} from '@material-ui/core/styles';
import {Fonts} from '../../../../../../shared/constants/AppEnums';
import {
  TaskSectionObj,
  TaskObj,
} from '../../../../../../types/models/apps/ProjectBoard';

interface CardDetailProps {
  task: TaskObj;
  onEditTaskDetail: (task: TaskObj, list: TaskSectionObj) => void;
  list: TaskSectionObj;
}

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 8,
    cursor: 'pointer',
  },
  pointer: {
    cursor: 'pointer',
  },
  attachFileIconRoot: {
    marginLeft: 8,
  },
}));

const CardDetail: React.FC<CardDetailProps> = ({
  task,
  onEditTaskDetail,
  list,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} onClick={() => onEditTaskDetail(task, list)}>
      <Box mb={3} display='flex' alignItems='center'>
        <Box mr={2} fontWeight={Fonts.MEDIUM}>
          {task.title}
        </Box>
        {task.attachments && task.attachments.length > 0 ? (
          <Box ml='auto' display='flex' alignItems='center'>
            <Box component='span' fontWeight={Fonts.LIGHT}>
              {task.attachments.length}
            </Box>
            <AttachFileIcon className={classes.attachFileIconRoot} />
          </Box>
        ) : null}
      </Box>
      {task.label.length > 0 ? <Labels labels={task.label} /> : null}

      <Box display='flex' alignItems='center'>
        {task.collaborators.length > 0 ? <Collaborators collaborators={task.collaborators} /> : null}

        <Box ml={3} mr='auto' color='text.secondary'>
          {task.date
            ? moment(task.date)
                .format('ll')
                .split(',')[0]
            : null}
        </Box>
        {task.comments.length > 0 ? (
          <Box ml={2} display='flex' alignItems='center' color='text.secondary'>
            <Box component='span' mr={2} fontWeight={Fonts.LIGHT}>
              {task.comments.length}
            </Box>
            <ChatBubbleIcon />
          </Box>
        ) : null}
      </Box>
    </Card>
  );
};

export default CardDetail;
