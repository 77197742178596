import * as THREE from 'three';
import {SpatialThinkSDK} from '../SpatialThinkSDK';
import {RenderingSystem} from '../Systems/RenderingSystem';

export class BaseComponent {
    public root: THREE.Object3D;
    public type:string;
    constructor(protected renderingSystem:RenderingSystem) {
        this.root = new THREE.Object3D();
        this.renderingSystem.base.add(this.root);
        this.type = "BaseComponent";
    }
}
