import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TagsList from './Tags/TagsList';
import { ShowcaseContext } from '../..';
import { SpaceData, ShowcaseTag, ANNOTATION_TYPE } from '../../../../../../types/models/home/HomeApp';
import SwipeableViews from 'react-swipeable-views';
import { TagType, ViewMode } from '..';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSpaceTagsList, setEditShowcaseTagId, setOpenTagForm } from 'redux/actions/Home';
import { addTagsToEmptyShowcase, handleDeleteTag } from 'modules/home/SpaceDetail/utils';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  // dir: PropTypes.any.isRequired,
};
function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
    // height: '600px',
    height: '50%',
  },
  appBarRoot: {
    // elevation4: {
    boxShadow: 'unset',
    backgroundColor: 'transparent'
    // }
  },
  tabButton: {
    minWidth: '30px',
    textTransform: 'none'
  }

}));

interface IProps {
  // importMatterportTags(): void;
  // tagsAlreadyImported: boolean;
}
export default function SidebarTabPanel({
  // importMatterportTags,
  // tagsAlreadyImported,
}: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const spaceTags = useSelector<AppState, Map<string, ShowcaseTag>>(({ home }) => home.spaceTags);
  const tagsViewMode = useSelector<AppState, string>(({ home }) => home.tagsViewMode);

  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    let visibleSpaceTags: ShowcaseTag[] = [];
    if (tagsViewMode == ViewMode.STATUS) {
      visibleSpaceTags = Array.from(spaceTags.values()).filter(
        (t) => t.annotationType && t.annotationType.startsWith(ANNOTATION_TYPE.TASK),
      );
    } else {
      visibleSpaceTags = getTagsForTab(value);
    }
    addTagsToEmptyShowcase(Array.from(visibleSpaceTags).map(tag => tag.id));
    console.log(`[st] setstate setting setCurrentSpaceTagsList 1`);
    dispatch(setCurrentSpaceTagsList(visibleSpaceTags))
  }, [spaceTags, tagsViewMode])

  function getTagsForTab(tabValue: number) {
    let tagType = tagTypeToName(tabValue);
    let visibleSpaceTags: ShowcaseTag[] = [];
    if (tagType === TagType.ALL) {
      visibleSpaceTags = Array.from(spaceTags.values())
    }
    else if (tagType === TagType.INFO) {
      visibleSpaceTags = Array.from(spaceTags.values()).filter(
        (t: ShowcaseTag) => t.annotationType && t.annotationType === ANNOTATION_TYPE.INFO && !t.data.media?.src
      );
    }
    else if (tagType === TagType.QUIZ) {
      visibleSpaceTags = Array.from(spaceTags.values()).filter(
        (t) => t.annotationType && (t.annotationType === ANNOTATION_TYPE.QUIZ)
      );
    }
    else if (tagType === TagType.TASK) {
      visibleSpaceTags = Array.from(spaceTags.values()).filter(
        (t) => t.annotationType && (t.annotationType === ANNOTATION_TYPE.TASK)
      );
    }
    else if (tagType === TagType.MEDIA) {
      visibleSpaceTags = Array.from(spaceTags.values()).filter(
        // (t) => ((t.data?.media?.type || '').includes('file') || (t.data?.media?.type || '').includes('video') || (t.data?.media?.type || '').includes('photo'))
        (t) => (t.data?.media?.src && t.data?.media?.src.trim())
      );
    }
    return visibleSpaceTags;
  }

  function handleChange(event: any, newValue: any) {
    // annotationType
    let visibleSpaceTags: ShowcaseTag[] = []
    setValue(newValue);
    if (tagsViewMode == ViewMode.STATUS) {
      visibleSpaceTags = Array.from(spaceTags.values()).filter(
        (t) => t.annotationType && t.annotationType.startsWith(ANNOTATION_TYPE.TASK),
      );
    } else {
      visibleSpaceTags = getTagsForTab(newValue);
    }
    // console.log(visibleSpaceTags, tagType, "visibleSpaceTags");
    console.log(`[st] setstate setting setCurrentSpaceTagsList 2`);
    dispatch(setCurrentSpaceTagsList(visibleSpaceTags));
    addTagsToEmptyShowcase([], visibleSpaceTags);
  }

  function handleChangeIndex(index: any) {
    setValue(index);
  }

  const handleEditTag = (tagId: string) => {
    dispatch(setOpenTagForm(true));
    dispatch(setEditShowcaseTagId(tagId));
  };

  return (console.log(`[st] rendering SidebarTabPanel`),
    <div className={classes.root}>
      <AppBar position='static' color='default' classes={{ root: classes.appBarRoot }}>
        <Tabs
          value={value}
          onChange={handleChange}
          style={tagsViewMode !== ViewMode.STATUS ? { minHeight: '48px' } : { minHeight: '0px' }}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          aria-label='full width tabs'>
          {tagsViewMode !== ViewMode.STATUS && <Tab classes={{ root: classes.tabButton }} label={'All'} {...a11yProps(0)} />}
          {tagsViewMode !== ViewMode.STATUS && <Tab classes={{ root: classes.tabButton }} label={'Info'} {...a11yProps(1)} />}
          {tagsViewMode !== ViewMode.STATUS && <Tab classes={{ root: classes.tabButton }} label={'Quiz'} {...a11yProps(2)} />}
          {tagsViewMode !== ViewMode.STATUS && <Tab classes={{ root: classes.tabButton }} label={'Task'} {...a11yProps(3)} />}
          {tagsViewMode !== ViewMode.STATUS && <Tab classes={{ root: classes.tabButton }} label={'Media'} {...a11yProps(4)} />}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0}>
          <TagsList
            onClickTagsPopup={(option: string, tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TagsList
            onClickTagsPopup={(option: string, tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TagsList
            onClickTagsPopup={(option: string,tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TagsList
            onClickTagsPopup={(option: string, tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TagsList
            onClickTagsPopup={(option: string, tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
          />
        </TabPanel>
      </SwipeableViews>

    </div>
  );
}

function tagTypeToName(index: number) {
  let name = '';
  if (index === 0) {
    name = TagType.ALL
  } else if (index === 1) {
    name = TagType.INFO
  }
  else if (index === 2) {
    name = TagType.QUIZ
  }
  else if (index === 3) {
    name = TagType.TASK
  }
  else if (index === 4) {
    name = TagType.MEDIA
  }
  return name
}