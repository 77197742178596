export const SET_SPACES = 'SET_SPACES';
export const GET_SPACES = 'GET_SPACES';

export interface SetSpacesAction {
    type: typeof SET_SPACES;
    payload: any[];
}

export interface GetSpacesAction {
    type: typeof GET_SPACES;
}

export type DashboardActionTypes =
    | SetSpacesAction
    | GetSpacesAction;
