import { IInteractionDragBeginEvent } from "../SubSystems/core/Behaviors";
import Simulation from "../SubSystems/core/Simulation";
import { IComponentEventSpy, ComponentInteractionType, SceneComponent, ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import { Logic, LogicEngine } from "../../../../types/models/dataAccess/Logic";
import { store } from "App";
import { ViewMode, ViewModeSecondary } from "modules/home/SpaceDetail/SpaceView/Sidebar";
import { firestore } from "@crema/services/auth/firebase/firebase";
import { setTagsViewModeSecondary, setTagsViewMode } from "redux/actions/Home";
import { canEdit } from "@crema/utility/Utils";
import { SET_CURRENT_SIDEBAR_INDEX } from "types/actions/Home.action";
import { SIDEBAR_INDEX } from "types/models/home/HomeApp";
import { getCurrentTagGroup } from "modules/home/SpaceDetail/utils";

export class AddObjectClickSpy implements IComponentEventSpy<IInteractionDragBeginEvent> {
    public eventType = ComponentInteractionType.CLICK;
    public static ptrToSetActionToNode: ((node: ISceneNode, i: number) => void) | undefined = undefined;
    public static ptrToSetConditionToNode: ((node: ISceneNode, i: number) => void) | undefined = undefined;
    // public static dispatch: any | undefined = undefined;
    public static objectSelectionState: number = -1;
    public static actionOrConditionStatePointer: React.Dispatch<React.SetStateAction<number>> | undefined = undefined;

    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
    }
    async onEvent(payload: IInteractionDragBeginEvent) {
        // console.log(`[st] clicked ${JSON.stringify( payload.point)} ${JSON.stringify( payload.normal )} ${JSON.stringify( payload.input )} ${JSON.stringify( payload.normal)}`);

        /*
        if (this.node.name == "/assets/scenes/highlightBorder.json") {
            let componentIterator: IterableIterator<SceneComponent> = this.node.componentIterator();
            for (const component of componentIterator) {
                if (component.componentType == "mp.canvasBorder") {
                    component!.inputs!.color = "yellow";
                }
            }
        }*/

        let currentSpace = store.getState().home.currentSpace;
        Simulation.instance.resetSimulationMode();

        // if (true)
        // !(tagsViewMode === ViewMode.LAYERS_SIM ||
        // !store.getState().home.isWorkflowsSidebarOpen) //FIXME TODO: why was this needed?
        // )
        // { //don't show prop panel if in workflows mode

        if (!store.getState().home.presentationMode) {
            await Simulation.instance.selectNodeWithPropertiesUpdate(this.node);

            // console.log(`[st] ${ JSON.stringify({p : this.node.position, r: this.node.quaternion, u: this.node.unserializedUserData, uu: this.node.userData}) }`);

            if (store.getState().home.currentSidebarIndex == SIDEBAR_INDEX.LAYERS && store.getState().home.currentLesson) {

                store.dispatch(setTagsViewModeSecondary(ViewModeSecondary.LAYERS_PROP))
            } else {
                store.dispatch({ type: SET_CURRENT_SIDEBAR_INDEX, payload: SIDEBAR_INDEX.THREED })
            }
        }

        if (AddObjectClickSpy.ptrToSetActionToNode) {
            AddObjectClickSpy.ptrToSetActionToNode(this.node, AddObjectClickSpy.objectSelectionState);
        }

        if (AddObjectClickSpy.ptrToSetConditionToNode) {
            AddObjectClickSpy.ptrToSetConditionToNode(this.node, AddObjectClickSpy.objectSelectionState);
        }

        //Behaviors.instance.processAllNodeClickEvents(this.node);

        let currentLessonId = store.getState().home.currentLesson?.id;
        let currentSpaceId = currentSpace?.id;
        let tg = getCurrentTagGroup();
        if (currentSpaceId && currentLessonId && tg) {

            if (tg.logic) {
                LogicEngine.processStepLogicConditions(tg.id, tg.logic as Logic, this.node, true, false);
            }
        }


        // console.profileEnd('processAllNodeClickEvents')

        //let nodePosition = this.node.position.clone();
        //nodePosition.y -= 0.6096; //remove 2 feet to the node position
        //GizmoTools?.instance?.setPosition(Simulation.instance.camera.getScreenCoordsOfPoint(nodePosition));


        //let screenPos = Simulation.instance.camera.getScreenCoordsOfPoint(this.node.position);
    }


}
