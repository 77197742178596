import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import IntlMessages from '@crema/utility/IntlMessages';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { Fonts } from 'shared/constants/AppEnums';
import { CremaTheme } from 'types/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => ({
  tableRowRoot: {
    borderBottom: '0 none',
    color: grey[500],
  },
  tableCellRoot: {
    borderBottom: '0 none',
    minWidth: 85,
    fontSize: 13,
    padding: 8,
    fontWeight: Fonts.BOLD,
    textTransform: 'capitalize'
  },
}));

interface TableHeadingProps { headers: string[] }

const TableHeading: React.FC<TableHeadingProps> = ({ headers }: TableHeadingProps) => {
  const classes = useStyles();
  return (console.log("rendering TableHeading"),
    <TableRow className={classes.tableRowRoot}>
      <TableCell align='center' className={classes.tableCellRoot}>
        Link Tag
      </TableCell>
      <TableCell align='center' className={classes.tableCellRoot}>
        <IntlMessages id='common.tag.goToTag' />
      </TableCell>
      {/* <TableCell align='center' className={classes.tableCellRoot}>
        <IntlMessages id='common.tag.label' />
      </TableCell> */}
      {headers.length > 0 && headers.map((header) => (
        <TableCell align='center' className={classes.tableCellRoot}>
          {header}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableHeading;