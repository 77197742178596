import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import {
  externalTags,
  getTagIdForTagSid,
  isPlacingTag,
  isRepositioning,
  setCurrentTagsSidMapGlobal,
  setCurrentZoom,
  setExternalTags,
  setIsPlacingTag,
  setIsRepositioning,
  setTagSidBeingAdded,
  tagSidBeingAdded,
} from 'modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils';
import { setEditShowcaseTagId, setOpenTagForm, setSelectedTag, setSpaceModelsList, setSpaceTagsList } from 'redux/actions/Home';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { GetSDK, initComponents } from 'mp';
import MpSdk from './MpSdk';
import { IQuizDetails, ShowcaseTag, SIDEBAR_INDEX, SpaceData } from 'types/models/home/HomeApp';
import { fetchSuccess, showMessage } from 'redux/actions';
import { addTagsToEmptyShowcase, handleTagClick, registerAllIcons } from 'modules/home/SpaceDetail/utils';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { CollaboratorObj, ProjectObj, TaskObj } from 'types/models/apps/ProjectBoard';
import Simulation from './core/craEngine/SubSystems/core/Simulation';
import { AuthUser } from 'types/models/AuthUser';
import { MATTERPORT_APP_KEY } from './Sdk';
import { mediaTagHtml, quizTagHtml } from 'modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/tagHt';
import { taskTagHtml } from 'modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/taskTagHtml';
import { ShowcaseContext } from 'modules/home/SpaceDetail/SpaceView';
import { SET_IS_ADDING_TAG } from 'types/actions/Home.action';
import { SpatialThinkSDK, SpatialThinkSDKModes } from 'CustomSdk/SpatialThinkSDK';
import InputSubSystem from './core/craEngine/SubSystems/input/InputSubSystem';
import _ from 'lodash';
import { store } from 'App';
interface Props {
  // src: string;
  space: SpaceData;
  project: ProjectObj;
  // space: SpaceData;
}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  //   paddingBottom: theme.spacing(5),
  //   borderBottom: '1px solid #E5E4EA',
  //   marginBottom: 20,
  //   alignItems: 'center',
  //   [theme.breakpoints.down('xs')]: {
  //     flexDirection: 'column',
  //   },
  // },
  // socialButtons: {
  //   display: 'flex',
  //   [theme.breakpoints.down('xs')]: {
  //     marginTop: theme.spacing(4),
  //   },
  // },
  frame: {
    height: '100%',
    width: '100%',
  },
}));

// export class Frame extends Component<Props, {}> {
// render() {
// const classes = useStyles();

export default function Frame(props: Props): any {

  // const [mpSdk, setMpSdk] = useState<any>(null);
  const spaceTags =
    useSelector<
      AppState,
      Map<string, ShowcaseTag>
    >(({ home }) => home.spaceTags);

  const space = useSelector<AppState, any>(({ home }) => ({
    id: home.currentSpace?.id, homeSweepId: home.currentSpace?.homeSweepId,
    imageSrc: home.currentSpace?.imageSrc, did: home.currentSpace?.did, sid: home.currentSpace?.sid
  }));

  const isAddingTag =
    useSelector<
      AppState,
      boolean
    >(({ home }) => home.isAddingTag);

  const currentSidebarIndex =
    useSelector<
      AppState,
      SIDEBAR_INDEX
    >(({ home }) => home.currentSidebarIndex);

  // const tagSidBeingAdded =
  // useSelector<
  //   AppState,
  //   any
  // >(({ home }) => home.tagSidBeingAdded);

  const dispatch = useDispatch();

  // const [addingTag, setAddingTag, getAddingTag] = useSetState<boolean>(false);
  // const [clickedTagCoords, setClickedTagCoords] = useState<any>(undefined);
  const [loadingStarted, setLoadingStarted] = React.useState(false);
  const [tagSidInFocus, setTagSidInFocus] = React.useState('');
  // const showcaseContext = useContext(ShowcaseContext);
  // const [tagSidBeingAdded, setTagSidBeingAdded, getTagSidBeingAdded] =
  //   useSetState<any>(null);
  // const [tagSidBeingAdded, setTagSidBeingAdded] =
  //   useState<any>(null);

  // const [isRepositioning, setRepositioning, getIsRepositioning] = useSetState<boolean>(false);
  // function useSetState<State = any>(initialState: State | (() => State)) {
  //   const [state, setState] = useState<State>(initialState);
  //   const getState = async (): Promise<State> => {
  //     let state: unknown;

  //     // console.log(`[st] useSetState get ${JSON.stringify(initialState)}`);

  //     await setState((currentState: State) => {
  //       // console.log(`[st] useSetState get ${JSON.stringify(currentState)}`);
  //       state = currentState;

  //       return currentState;
  //     });
  //     return state as State;
  //   };

  //   return [state, setState, getState] as [
  //     State,
  //     typeof setState,
  //     typeof getState,
  //   ];
  // }

  const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser);

  // const currentSpaceId = useSelector<AppState, string>(
  //   ({ home }) => home.currentSpace?.id || ''
  // );

  // const { projectDetail:project }: { projectDetail: ProjectObj } = useSelector<AppState, AppState['projects']>(
  //   ({ projects }) => projects
  // );

  let params: { [key: string]: string } = {
    m: space.sid,
    play: '1',
    applicationKey: MATTERPORT_APP_KEY,
  };
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  const src =
    `/bundle/showcase.html?${queryString}` +
    '&title=0&qs=1&hr=0&brand=0&help=0';

  /*
Keeps track of tag co-ordinates during Add Tag
 */
  const updateTagPos = async (
    newPos: any,
    newNorm: any,
    scale: any,
    tag: any,
  ) => {
    if (!newPos) return;
    if (!scale) scale = 0.33;
    if (!newNorm) newNorm = { x: 0, y: 1, z: 0 };

    MpSdk.Instance.mps.Mattertag.editPosition(tag[0], {
      anchorPosition: newPos,
      stemVector: {
        x: scale * newNorm.x,
        y: scale * newNorm.y,
        z: scale * newNorm.z,
      },
      stemVisible: true,
      floorIndex: 0,
    })
      .then(() => {
        // console.log(`[st] edited position in updateTagPos ${JSON.stringify( newPos)}`)
      })
      .catch((e: any) => {
        console.error("error in updatetagpos " + e);
        setTagSidBeingAdded(false);
        setIsPlacingTag(false);

      });
  };

  const trackTag = () => {
    MpSdk.Instance.mps.Pointer.intersection.subscribe(
      async (intersectionData: {
        object: string;
        position: any;
        normal: { x: any; y: any; z: any };
      }) => {

        if ((tagSidBeingAdded) && isAddingTag && !isPlacingTag) {
          console.log(`[st] tracktag ${isAddingTag} - ${tagSidBeingAdded}`);
          if (
            intersectionData.object === 'intersectedobject.model' ||
            intersectionData.object === 'intersectedobject.sweep' ||
            intersectionData.object === 'intersectedobject.unknown'
          ) {
            await updateTagPos(
              intersectionData.position,
              intersectionData.normal,
              0.33,
              tagSidBeingAdded ,
            );

          }
        }
      });
  }

  const handleAddTag = async (): Promise<void> => {

    // add new tag
    let floor = await MpSdk.Instance.mps.Floor.getData();

    if (isRepositioning) {
      console.log(`[st] about to start tracking tag for respositioning `);

      await trackTag();

    } else {


      MpSdk.Instance.mps.Mattertag.add([
        {
          label: 'New Tag',
          description: '',
          anchorPosition: { x: 0, y: 0, z: 0 },
          stemVector: { x: 0, y: 0, z: 0 },
          stemVisible: true,
          color: { r: 1, g: 0, b: 0 },
          floorIndex: floor.currentFloor,
          stemHeight: 0.000001,
        },
      ])
        .then(async (sid: string) => {
          setTagSidBeingAdded(sid);
          console.log(`[st] about to start tracking tag ${sid}`);

          await trackTag();
        })
        .catch((e: any) => {
          console.error(e);
          dispatch({ type: SET_IS_ADDING_TAG, payload: false });
          setIsRepositioning(false);
        });
    }
  };

  useEffect(() => {
    isAddingTag && handleAddTag()
  }, [isAddingTag]);

  // serialize the contents of the function and call it with a single parameter.
  // Then, wrap it with a script tag. '<' is separated from '/' to prevent the browser from
  // adding this script tag to the parent page on jsfiddle.
  function serializeQuizFunctionCall(
    myFunction: any,
    mattertagId: string,
    quizDetails: IQuizDetails | undefined,
  ) {
    return (
      `<script>
        ${myFunction.toString()};
        ${myFunction.name}('${mattertagId}',${JSON.stringify(quizDetails)});
        <` + `/script>`
    );
  }

  function serializeTaskFunctionCall(
    myFunction: any,
    taskItem: any,
    tagSid: any,
  ) {
    return (
      `<script>
${myFunction.toString()};
${myFunction.name}(${JSON.stringify(taskItem)},'${tagSid}');
<` + `/script>`
    );
  }

  function serializeMediaFunctionCall(
    myFunction: any,
    mattertagId: string,
    mediaLink: string,
  ) {
    return (
      `<script>
        ${myFunction.toString()};
        ${myFunction.name}('${mattertagId}',${JSON.stringify(mediaLink)});
      <` + `/script>`
    );
  }

  // The contents of this function will be executed in a sandboxed mattertag iframe.
  // You can only refer to variables defined in the iframe.
  function mattertagQuizFunction(
    mattertagId: string,
    quizDetails: IQuizDetails,
  ) {
    function getSelectedCheckboxValues(name: string) {
      const checkboxes = document.querySelectorAll(
        `input[name="${name}"]:checked`,
      );
      let value: string = '';
      checkboxes.forEach((checkbox: any) => {
        value = checkbox.value;
      });
      return value;
    }
    const btn: any = document.querySelector('#radioInputForm');
    btn.addEventListener('change', (event: any) => {
      let selectedChoice = getSelectedCheckboxValues('choice');

      let correctAns: any = document.getElementById('correct-ans');
      let wrongAns: any = document.getElementById('wrong-ans');

      if (quizDetails?.answer.includes(selectedChoice)) {
        correctAns.style.display = 'block';
        wrongAns.style.display = 'none';
      } else {
        wrongAns.style.display = 'block';
        correctAns.style.display = 'none';
      }
    });
  }

  function mattertagMediaFunction(mattertagId: string, mediaLink: string) { }

  function mattertagTaskFunction(taskItem: any, tagSid: any) {
    // when the button is clicked send a message back to the parent page through the window object.
    let editTaskBtn: any = document.getElementById('editTaskBtn');
    editTaskBtn.addEventListener('click', function (event: any) {
      // console.log(`[st] editTaskBtn button clicked`);
      // setOpenEditTagForm(true);
    });
  }


  function makeTaskTagHtml(taskItem: TaskObj, tagSid: string, tagHtml: string) {
    return (
      taskTagHtml(taskItem, tagSid,
        // props.project
      ) +
      serializeTaskFunctionCall(mattertagTaskFunction, taskItem, tagSid)
    );
  }

  function makeQuizHtml(
    mattertagId: string,
    quizDetails: IQuizDetails | undefined,
    tagHtml: string,
  ) {
    return (
      quizTagHtml(quizDetails, tagHtml) +
      serializeQuizFunctionCall(mattertagQuizFunction, mattertagId, quizDetails)
    );
  }

  function makeMediaHtml(
    mattertagId: string,
    mediaLink: string,
    tagHtml: string,
  ) {
    return (
      mediaTagHtml(mediaLink, tagHtml) +
      serializeMediaFunctionCall(mattertagMediaFunction, mattertagId, mediaLink)
    );
  }

  // const injectHtmlHandler = async (
  //   htmlString: any,
  //   tagSid: string,
  //   width: undefined | number,
  //   height: undefined | number,
  // ) => {
  //   // await initMpSdk();

  //   if (htmlString !== '') {
  //     MpSdk.Instance.mps &&
  //       MpSdk.Instance.mps.Mattertag.injectHTML(tagSid, htmlString, {
  //         size: {
  //           w: width,
  //           h: height,
  //         },
  //         windowPath: '',
  //       })
  //         .then(function (messenger: any) {
  //           messenger.on('buttonClick', function (tagSid: any) {
  //             dispatch(setTagsViewMode(ViewMode.TAGS));
  //             let selectedShowcaseId = getTagIdForTagSid(tagSid);

  //             selectedShowcaseId && dispatch(setEditShowcaseTagId(selectedShowcaseId));
  //           });
  //         })
  //         .catch((e: any) => {
  //           console.error(e);
  //           console.error('Error in injectHTML');
  //         });
  //   }
  // };
  /*
   Re-initialize all Matterport tags in DB
   Remove all from showcase
   Add tags and annotations from DB to showcase
    */
  const getMatterportTagsFromDB = async () => {

    let tagsFromDb: ShowcaseTag[] = [];
    let mergeTaskTags = [];
    let badTagIds: string[] = [];

    let qsTags = await firestore
      .collection('Spaces')
      .doc(props.space.id)
      .collection('tags')
      .get();
    let tags = qsTags.docs.map((doc: ShowcaseTag) => ({ ...doc.data(), id: doc.id }));

    let otherTags = tags.filter((doc: ShowcaseTag) => doc.annotationType !== "task");
    let taskTags = tags.filter((doc: ShowcaseTag) => (doc.annotationType === "task" && (props.project && doc.projectId === props.project.id)));

    let qsProjectTasks = await firestore
      .collection('Projects')
      .doc(props.project.id)
      .collection('Tasks')
      .where('type', '==', 'TASK_TAG')
      .get();

    let projectTasks = await Promise.all(qsProjectTasks.docs.map(async (doc: any) => {
      let label = doc.data().labelIds.map((labId: number) => (props.project.labelList[props.project.labelList.findIndex((a: any) => (a.id == labId))]));
      let collaborators: CollaboratorObj[] = [];

      if ((doc.data()?.collaboratorIds || []).length) {
        let ms = await firestore.collection('Users').where('id', "in", doc.data().collaboratorIds).get();
        collaborators = ms.docs.map(u => ({ id: u.id, name: `${u.data().firstName} ${u.data().lastName}`, image: u.data().photoURL, email: u.data().email }));
      }

      // let collaborators = doc.data().collaboratorIds.map((cId: number) => (project.memberList[project.memberList.findIndex((a: any) => (a.id == cId))]));
      return ({ ...doc.data(), id: doc.id, label, collaborators });

    }));

    // merge Space Task data and Project Task data
    mergeTaskTags = taskTags.map(s => ({ ...s, taskTag: projectTasks.find(p => (p.tagId === s.id)) }));

    const allTags = [...otherTags, ...mergeTaskTags];

    allTags.forEach((tag) => {
      try {
        // attachment-feature
        //           let tagHtml= tag?.tagHtml;
        //         if (tag.data.description) {
        //           tagHtml = `${tagHtml} ${wrapTagHtml(tag.data.description)}`;
        //         }
        const id = tag.id;
        const data = tag.data;
        const annotationType = tag?.annotationType;
        const quizDetails = tag?.quizDetails;
        // const mediaLink = tag?.mediaLink;
        const taskTag = tag?.taskTag;
        const tagHtml = tag?.tagHtml;
        const showTextToSpeech = tag?.showTextToSpeech;
        const createdOn = tag?.createdOn;
        const lastUpdatedOn = tag?.lastUpdatedOn;
        const createdBy = tag?.createdBy;
        const lastUpdatedBy = tag?.lastUpdatedBy;
        const labelIds = tag?.labelIds;
        const projectId = tag?.projectId;
        const uploadType = tag?.uploadType;

        tagsFromDb.push({
          id: id,
          data: JSON.parse(data),
          ...(annotationType && { annotationType: annotationType }),
          ...(quizDetails && { quizDetails: quizDetails }),
          // ...(mediaLink && { mediaLink: mediaLink }),
          ...(taskTag && { taskTag: taskTag }),
          ...(tagHtml && { tagHtml: tagHtml }),
          ...(showTextToSpeech && { showTextToSpeech: showTextToSpeech }),
          ...(createdOn && { createdOn: new Date(tag.createdOn.seconds * 1000) }),
          ...(lastUpdatedOn && { lastUpdatedOn: new Date(tag.lastUpdatedOn.seconds * 1000) }),
          ...(createdBy && { createdBy: createdBy }),
          ...(lastUpdatedBy && { lastUpdatedBy: lastUpdatedBy }),
          ...(labelIds && { labelIds: labelIds }),
          ...(projectId && { projectId: projectId }),
          ...(uploadType && { uploadType: uploadType }),
        });
      } catch (error: any) {
        console.error('found a bad tag');
        console.error(error);
        badTagIds.push(tag.id);
      }
    });

    // if (tagsFromDb.find((x) => x.data?.source == 'MP')) {
    //   setTagsAlreadyImported(true);
    // }

    try {
      let tagsFromMatterport = await MpSdk.Instance.mps.Mattertag.getData();
      // await MpSdk.Instance.mps.Mattertag.remove(tagsFromMatterport.map((tag: any) => tag.sid)).catch(console.error);

      if (externalTags.length == 0) {
        console.log(`[st] setstate setting externaltags 2`);
        setExternalTags(tagsFromMatterport);
      }
      tagsFromDb = tagsFromDb.filter((tag) => !badTagIds.includes(tag.id));
      let tagMap = new Map(tagsFromDb.map((t) => [t.id, t]));
      console.log(`[st] setting spaceTags`);
      dispatch(setSpaceTagsList(tagMap)); //controls state accross components

      // let tagSidsMap = new Map(tagsFromDb.map(t => [t.id, t.data.sid]));
      // setCurrentTagsSidMapGlobal(tagSidsMap); //global reference for current tag sids

      // add to showcase
      setCurrentTagsSidMapGlobal(new Map());
      await addTagsToEmptyShowcase([], tagsFromDb); //tagsFromDb

      // })
    } catch (error: any) {
      console.error(error, 'error while getting initial tag data');
    }
  }


  /*
    Register showcase window listener
     */
  function useEventListener(
    eventName: string,
    handler: ({ clientX, clientY }: any) => void,
    element = window,
  ) {
    const savedHandler = useRef(handler);
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      const eventListener = (event: any) => savedHandler.current(event);
      element.addEventListener(eventName, eventListener);
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    }, [eventName, element]);
  }

  /*
  Click handler for Add Tag
   */
  const addTagHandler = useCallback(async (event: any) => {

    // console.log(`[st] handler ${Object.keys(event)}}`);

    // if (isAddingTag){
    //   InputSubSystem.input && InputSubSystem.input.setMatterPortCameraMovement(false); //DUBIOUS
    // }
    // else {
      //  console.log(`[st] handler ${JSON.stringify( InputSubSystem?.input?.getMatterPortCameraMovement())} -- ${InputSubSystem?.input?.inputs?.userNavigationEnabled}}`);
    //   if(InputSubSystem?.input?.inputs && !InputSubSystem?.input?.inputs['userNavigationEnabled']){
    //     // view is locked. do nothing.

    //   } else {

    //     InputSubSystem.input && InputSubSystem.input.setMatterPortCameraMovement(true); //DUBIOUS
    //   }

    // }
    // InputSubSystem.input && InputSubSystem.input.setMatterPortCameraMovement(!isAddingTag); //DUBIOUS

    // console.log(`[st] addTagHandler ${isAddingTag} - ${tagSidBeingAdded}`);
    if (isAddingTag && tagSidBeingAdded &&
      document.activeElement && document.activeElement.id === 'sdk-iframe') {
      // event.preventDefault();
      console.log(`[st] about to placetag`);
      // dispatch({ type: SET_IS_ADDING_TAG, payload: false });


      setIsPlacingTag(true);

      // let tag = tagSidBeingAdded;
      // console.log(`[st] tagSidBeingAdded ${tag}`);
      // let hand = await getObjectSidBeingAdded();
      // var isAddingObject = await getObjectAdding();
      // if (tag) {
      await placeTag();
      // }
    }
    // scene?.hideTransformGizmo();
  }, [tagSidBeingAdded, isAddingTag]);

  // useEffect(() => {

  // }, [tagSidBeingAdded, isAddingTag])


  useEventListener('blur', addTagHandler);

  // useEffect(() => {  document.addEventListener('blur', blurHandler)}, [])


  // const showCustomBillboard = (tagSid: any) => {
  //   // console.log(`[st] clicked ${JSON.stringify(tagSid)}`);
  //   mpSdk.Mattertag.getData().then((tags: any[]) => {
  //     console.log(tags);
  //     console.log(`[st] git tag data ${JSON.stringify(tagSid)}`);
  //     let tag: any = tags.find(tag => tag.sid == tagSid);
  //     console.log(tag);

  //     console.log(`[st] pos ${JSON.stringify(tag)}`);

  //     let finalAnchorPosition = new THREE.Vector3(tag.anchorPosition.x,
  //       tag.anchorPosition.y,
  //       tag.anchorPosition.z);

  //     // Disc anchor looks better but both work (disc is at the center of the  tag disc)
  //     // Feel free to comment out finalAnchorPosition above
  //     let discAnchorPosition = mpSdk.Mattertag.getDiscPosition(tag);
  //     //console.log(tag.stemHeight)
  //     //console.log(tag.stemVector)

  //     //let coordsMp = Simulation.instance.camera.getScreenCoordsOfPoint(finalAnchorPosition);
  //     // Simulation.instance.camera.LookAtXYZ(new THREE.Vector3(
  //     //   tag.anchorPosition.x,
  //     //   tag.anchorPosition.y,
  //     //   tag.anchorPosition.z
  //     // ));

  //     let coords1 = Simulation.instance.camera.getScreenCoordsOfPointWithoutMatterPortSDK(
  //       new THREE.Vector3(discAnchorPosition.x, discAnchorPosition.y, discAnchorPosition.z));
  //     console.log(`[st] coords ${JSON.stringify(coords1)}`);
  //     //console.log(`[st] coordsMP ${JSON.stringify(coordsMp)}`);
  //     setClickedTagCoords(Object.assign({}, coords1));

  //   }).catch(console.error)

  // }

  // useEffect(() => {

  //   //mpSdk && mpSdk.on(mpSdk.Mattertag.Event.CLICK, showCustomBillboard)

  // }, [mpSdk])


  /*
 Places tag in the showcase: During Add Tag, after user picks a position and clicks in the showcase
  */
  const placeTag = async function () {
    let tag = tagSidBeingAdded; // await getTagSidBeingAdded();
    if (tag) {
      let mps = MpSdk.Instance.mps; //mpSdk || getMpSdkGlobal(space.id);
      // console.log(`[st] rep 6}`);
      // if (await getIsRepositioning()) {
      if (isRepositioning) {
        console.log(`[st] is repositioning`);
        let tag = tagSidBeingAdded; //await getTagSidBeingAdded();
        let tagSid = tag[0];

        // !mpSdk && await initMpSdk();


        if (mps) {
          console.log(`[st] rep 8 ${tagSid}}`);
          let allTags = await mps.Mattertag.getData();
          let thisTag = allTags.find((t: any) => t.sid === tagSid);
          console.log(`[st] rep 9 }`);
          console.log(`[st] rep 9 ${JSON.stringify(thisTag)}`);

          mps &&
            mps.Mattertag.editPosition(tagSid, {
              anchorPosition: thisTag.anchorPosition,
              stemVector: thisTag.stemVector,
              floorIndex: thisTag.floorIndex,
            }).then(async () => {
              setIsRepositioning(false);
              dispatch({ type: SET_IS_ADDING_TAG, payload: false });
              setTagSidBeingAdded('');
              setIsPlacingTag(false);

              let allTags = await mps.Mattertag.getData();
              let thisTag = allTags.find((t: any) => t.sid === tagSid);

              let tagId = getTagIdForTagSid(tagSid);
              thisTag && await firestore.doc(`Spaces/${space.id}/tags/${tagId}`)
                .set({
                  data: JSON.stringify(thisTag),
                  lastUpdatedBy: authUser?.uid,
                  lastUpdatedOn: new Date()
                }, { merge: true });

              if (tagId) {
                let spaceTagsCopy = _.cloneDeep(spaceTags);
                let spaceTag = spaceTagsCopy.get(tagId);
                if (spaceTag) {
                  spaceTag.data = thisTag;
                  spaceTag.lastUpdatedBy = authUser?.uid;
                  spaceTag.lastUpdatedOn = new Date();
                  dispatch(setSpaceTagsList(spaceTagsCopy));
                }
              }

              mps.Mattertag.navigateToTag(
                tagSid,
                mps.Mattertag.Transition.FLY,
              ).catch(console.error);

              dispatch(fetchSuccess());
              dispatch(showMessage("Tag updated!"));


            }).catch(console.error)
        }

      } else {
        // let tagId = firestore.collection(`Spaces/${space.id}/tags`).doc().id; // generate new id
        // let showcaseTag = {id: tagId, data: {sid: tagSidBeingAdded}}
        // console.log(`[st] opening tag form . pos is`);
        // dispatch(setEditShowcaseTagId(tagSidBeingAdded));

        dispatch(setOpenTagForm(true));
      }

      // await initMpSdk();
      // mps &&
      //   mps.Mattertag.navigateToTag(tag, mps.Mattertag.Transition.INSTANT); //FIXME is this needed?
    }
    // console.log(`[st] setState addingTag`);
    // setAddingTag(false);
    // setTagSidBeingAdded(null);
    console.log(`[st] dispatching isAddingTag ${isAddingTag} - ${tagSidBeingAdded}`);
    dispatch({ type: SET_IS_ADDING_TAG, payload: false })
    console.log(`[st] dispatched isAddingTag ${isAddingTag} - ${tagSidBeingAdded}`);
    // scene?.hideTransformGizmo();

  };

  const spaceViewStart = async () => {

    //Simulation.ForceReset();

    if (MpSdk.Instance.mps && !loadingStarted) {

      console.log(`[st] loading space ${space.id}`);
      setLoadingStarted(true);
      await getMatterportTagsFromDB();
      await Simulation.instance.initialize(space.id, MpSdk.Instance.mps, authUser as AuthUser,
        // props.space
      );
      // AddObjectClickSpy.dispatch = dispatch;

      dispatch(setSpaceModelsList(Simulation.instance.spaceModels));
    }
  };

  const initMpSdk = async (): Promise<any> => {
    // mpSdk = mpSdk || getMpSdkGlobal(space.id);;

    // if (!MpSdk.Instance.mps) {
    // let mpSdkState = await getMpSdk();
    // if (mpSdkState) {
    //   return mpSdkState;
    // }

    console.log(`[st] initializing mpSdk for space ${space.id}`);

    let mpSdkInit = await GetSDK('sdk-iframe');
    MpSdk.Instance.init(mpSdkInit);
    // console.log(`[st] setState mpSdk`);
    // setMpSdk(mpSdkInit);
    // if (/!mpSdk) {
    // setMpSdkGlobal(await GetSDK('sdk-iframe'));
    // setMpSdkGlobal(space.id, mpSdkInit);


    await initComponents(MpSdk.Instance.mps);

    MpSdk.Instance.mps.Camera.zoomTo(0.8)
      .then(function (newZoom: any) {
        if (!space.imageSrc) {
          console.log(`[st] taking snapshot  `);
          mpSdkInit.Renderer.takeScreenShot({ width: 1280, height: 512 }, { mattertags: false, sweeps: true })
            .then(async (x: any) => {
              // console.log(`[st] snapshot taken for ${space.id} ${typeof x} ${x.constructor.name} ${x}`);
              // console.log(`[st] ${x} ${JSON.stringify(x)}`);
              await firestore.doc(`Spaces/${space.id}`).update({ 'imageSrc': x });

            })
            .catch(console.error);
        }
      });

    // mpSdkInit.Sweep.current.subscribe(function (currentSweep: any) {
    //   // setCurrentSweep(currentSweep);
    //   MpSdk.Instance.mps.Camera.getPose().catch(console.error).then((p: any) => setCurrentSweep(p))

    //   // console.log(`[st] moved to sweep ${currentSweep.sid} ${JSON.stringify(currentSweep) }`);
    // });

    mpSdkInit.Camera.zoom.subscribe(function (zoom: any) {
      // console.log('Current zoom is ', zoom);
      setCurrentZoom(zoom);
    });

    try {
      registerAllIcons(mpSdkInit);
      if (!space?.homeSweepId) {

        mpSdkInit.Sweep.current.subscribe(function (currentSweep: any) {
          // Change to the current sweep has occurred.
          if (!!currentSweep.sid) {
            space.homeSweepId = currentSweep.sid;
            firestore.collection(`Spaces`).doc(space.id).set({ homeSweepId: space.homeSweepId },
              { merge: true }).then(() => { }).catch(console.error)

          }
        });
      }

    } catch (e: any) {
      //ignore icon already registered errors
    }

    // mpSdkInit.Camera.zoom.subscribe(function (zoom: any) {
    //   // the zoom level has changed
    //   console.log('Current zoom is ', zoom.level);
    // });
    // }

    spaceViewStart();
    return MpSdk.Instance.mps;
  };

  useEffect(() => {

    initMpSdk().then(() => {

      MpSdk.Instance.mps.on(MpSdk.Instance.mps.Mattertag.Event.CLICK, async function (tagSid: string) {

        let clickedTagId = getTagIdForTagSid(tagSid);
        clickedTagId && (clickedTagId !== store.getState().home.editShowcaseTagId) && dispatch(setEditShowcaseTagId(clickedTagId));

      });

      // MpSdk.Instance.mps.on(MpSdk.Instance.mps.Mattertag.Event.HOVER, async function (tagSid: string) {

      //   let selectedTagId = getTagIdForTagSid(tagSid);
      //   selectedTagId && dispatch(setSelectedTag(selectedTagId));

      // });
    })

  }, [src]);

  // useEffect(() => {
  //   MpSdk.Instance.mps && MpSdk.Instance.mps.on(MpSdk.Instance.mps.Mattertag.Event.HOVER, (tagSid: string) => {
  //     MpSdk.Instance.hoverHandler(tagSid);
  //     // setTagSidInFocus(tagSid);
  //   });
  //   MpSdk.Instance.mps && MpSdk.Instance.mps.on(MpSdk.Instance.mps.Mattertag.Event.CLICK, (tagSid: string) => {

  //     MpSdk.Instance.clickHandler(tagSid);
  //     setTagSidInFocus(tagSid);
  //   });
  //   MpSdk.Instance.mps && MpSdk.Instance.mps.on(MpSdk.Instance.mps.Camera.Event.MOVE, () => {

  //     if (tagSidInFocus) {

  //     }

  // })
  // }, [MpSdk.Instance.mps]);

  // useEffect(() => {
  //   MpSdk.Instance.mps && MpSdk.Instance.mps.Mattertag.navigateToTag(
  //     tagSidInFocus,
  //     MpSdk.Instance.mps.Mattertag.Transition.FLY,
  //   ).then(() => {
  //     // setTimeout(() => spaceTag != undefined && playTextToSpeech(spaceTag.data.label), 1000); //TODO commenting this for now
  //   }).catch(console.error);
  // }, [tagSidInFocus, MpSdk.Instance.mps]);

  const goToHomeSweep = async () => {
    MpSdk.Instance.mps && space.homeSweepId && MpSdk.Instance.mps.Sweep.moveTo(space.homeSweepId)
      .then(async () => {
        MpSdk.Instance.mps.Camera.zoomTo(0.7).then(() => { }).catch(console.error);

      }).catch(console.error);
  }

  if (currentSidebarIndex == SIDEBAR_INDEX.AR) {
    SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.AugmentedReality);
    // <Button onClick={() => { SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.AugmentedReality) }}> AR </Button>
    // <Button onClick={() => {
    //   SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.Regular)
    //   Simulation.instance.initialize(space.id, SpatialThinkSDK.instance.getSDK(), authUser as AuthUser,
    //     // props.space
    //   );
    //   initComponents(SpatialThinkSDK.instance.getSDK()).then(() => {

    //   });
    // }}> THREE JS </Button>

    //addThreeSdkToDomInIframe();
    // SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.Regular)
  }

  if (currentSidebarIndex == SIDEBAR_INDEX.BLANK_SCENE) {
    // SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.AugmentedReality);
    // <Button onClick={() => { SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.AugmentedReality) }}> AR </Button>
    // <Button onClick={() => {
    SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.Regular)
    Simulation.instance.initialize(space.id, SpatialThinkSDK.instance.getSDK(), authUser as AuthUser,
      // props.space
    );
    initComponents(SpatialThinkSDK.instance.getSDK()).then(() => {

    });
    // }}> THREE JS </Button>

    //addThreeSdkToDomInIframe();
    // SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.Regular)
  }

  return (useMemo(() => (console.log(`[st] rendering Frame`), <IFrame src={src} />), [src, currentSidebarIndex]));
  // currentSidebarIndex == SIDEBAR_INDEX.AR ? <ARContainer/> : <IFrame src={src} />), [src, currentSidebarIndex]));

}

Frame.whyDidYouRender = true;

{/* <Button onClick={() => addARSdkToDom()}> AR </Button>
<Button onClick={() => { SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.Regular)}}> THREE JS </Button> */}

const IFrame = ({ src }: { src: string }) => {


  return (console.log(`[st] rendering IFrame`), <div id="iframe-container" style={{ height: '100%' }}
  >


    {/* <Button onClick={() => addARSdkToDom()}> AR </Button>
    <Button onClick={() => { SpatialThinkSDK.instance.SetMode(SpatialThinkSDKModes.Regular)}}> THREE JS </Button> */}

    {/* <Button onClick={() => addARSdkToDom()}> AR </Button>
    <Button onClick={() => {
      let sdk = addThreeSdkToDom();
      document.body.appendChild(sdk!.renderer!.domElement)
      }}> THREE JS </Button> */}

    <iframe
      sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
      allow='xr-spatial-tracking'
      id='sdk-iframe'
      style={{ width: '100%', height: '100%' }}
      src={src}

    ></iframe>
  </div>)

}
IFrame.whyDidYouRender = true;
