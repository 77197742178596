import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { OverflowMenu } from '../../../../../components/OverflowMenu';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Box,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { ShowcaseTag, TagGroup, ANNOTATION_TYPE } from '../../../../../../../types/models/home/HomeApp';
import { canEdit } from '@crema/utility/Utils';
import { ShowcaseContext } from '../../..';
import { handleTagClick } from '../../../../utils';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { setTagGroupsList } from 'redux/actions/Step';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import { ViewMode } from '../..';
import { LabelObj, ProjectObj } from 'types/models/apps/ProjectBoard';
import { showMessage } from 'redux/actions';
import { Scrollbar } from '@crema';
import SearchBar from '@crema/core/SearchBar';
import { setIsRepositioning, getTagSidForTagId, setTagSidBeingAdded } from './ShowcaseUtils';
import { SET_IS_ADDING_TAG } from 'types/actions/Home.action';
interface RefObject<T> {
  // immutable
  readonly current: T | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagsListRoot: {
      // overflow: 'scroll',
      // height: '800px',
      // paddingLeft: '10px',
      // paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    searchBar: {
      color: 'blue'
    },
    heading: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      lineHeight: '48px',
    },
    root: {
      width: '100%',
      // maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    listItem: {
      backgroundColor: "white",
      paddingLeft: '12px',
      paddingRight: '2px',
      fontSize: '12px',
      paddingTop: '2px',
      paddingBottom: '2px',
      // background:"#f9f8f8",
      borderRadius: "16px",
      marginBottom: "7px",
      '&:hover': {
        border: "1px solid grey",
      },
    },
    listItemTitle: {
      paddingLeft: '2px',
      paddingRight: '2px',
      fontSize: '13px',
      paddingTop: '2px',
      paddingBottom: '2px',
      width: '100%',
      wordWrap: 'break-word',
    },
    scrollLabels: {
      display: 'flex',
      width: '200px',
      height: '22px',
    },
  }),
);

interface TagslistProps {
  onClickTagsPopup: (option: string, tagId: string) => void;
}


const TagsList: React.FC<TagslistProps> = ({
  onClickTagsPopup,
}) => {

  const classes = useStyles();
  const [hideTags, setHideTags] = useState<boolean>(false);
  const currentSpaceId = useSelector<AppState, string>(({ home }) => home.currentSpace?.id || '');
  const currentLessonId = useSelector<AppState, string>(({ home }) => home?.currentLesson?.id || '');
  const currentTagGroupId = useSelector<AppState, string>(({ home }) => home.currentTagGroupId);
  const tagGroupsList  = useSelector<AppState, TagGroup[]>(({ home }) => home.tagGroupsList);

  const currentShowcaseTags = useSelector<AppState, ShowcaseTag[]>(({ home }) => home?.currentShowcaseTags || []);
  const editShowcaseTagId  = useSelector<AppState, string>(({ home }) => home.editShowcaseTagId);

  const projectDetail = useSelector<AppState, ProjectObj | null>(
    ({ projects }) => projects.projectDetail
  );
  const [filterText, setFilterText] = useState('');

  // const [visibleSpaceTags, setVisibleSpaceTags] = React.useState<ShowcaseTag[]>([]);
  const dispatch = useDispatch();
  let visibleSpaceTags: ShowcaseTag[] = [];
  const tagOverflowMenuItems = (showcaseTag: ShowcaseTag) => [
    { title: 'Edit', onClick: () => onClickTagsPopup('Edit', showcaseTag.id) },
    { title: 'Delete', onClick: () => onClickTagsPopup('Delete', showcaseTag.id) },
    {
      title: 'Reposition', onClick: () => {
        if (showcaseTag.id) {


          dispatch(showMessage("Move cursor in the Space area to reposition!"));
          // showcaseContext.handleAddTag({isRepositioning: true, tagId: showcaseTag.id})}},

          setIsRepositioning(true);
          setTagSidBeingAdded([getTagSidForTagId(showcaseTag?.id)]);

          dispatch({ type: SET_IS_ADDING_TAG, payload: true });
          // setTagSidBeingRepositioned(getTagSidForTagId(showcaseTag?.id || '') || ''); //FIXME what if it's undefined
        }
      }
    }
  ];

  //visibleSpaceTags = getFilteredTags({tagsViewMode: 'projectStatus'}, currentShowcaseTags);
  visibleSpaceTags = Array.from(currentShowcaseTags).filter(showcaseTag => {
    if (!filterText) return true;
    let searchableText = showcaseTag?.annotationType === 'quiz'
      ? showcaseTag?.quizDetails?.question
      : showcaseTag?.annotationType === ANNOTATION_TYPE.TASK
        ? showcaseTag?.taskTag?.title
        : showcaseTag.data?.label + showcaseTag.data?.description;
    return searchableText.toUpperCase().includes(filterText.toUpperCase());
  });

  // useEffect(() => {
  //   // alert("edited")
  // 	visibleSpaceTags.find((el)=> {
  // 		if(editShowcaseTagId === el.id){
  // 			onClickTagsPopup('Edit', el.id)
  // 			return true
  // 		}
  // 	})
  // }, [editShowcaseTagId]);

  // useEffect(() => {
  // let refs = tagRefs;
  const tagRefs: Map<string, React.RefObject<HTMLInputElement>> = new Map();

  const Field = ({ children, id }: { children: any, id: string }) => {
    const fieldRef = React.useRef<HTMLInputElement>(null);
    tagRefs.set(id, fieldRef);
    return (
      <div
        className="field"
        ref={fieldRef}>
        {children}
      </div>
    );
  };

  const handleClick = (tagId: string) => { //TODO: commenting out because scrolling into view doesn't work reliably e.g. after you go to a different tab

    // let c = tagRefs.get(tagId)?.current;

    // if (tagId && !!c) {

    //   c.scrollIntoView({ behavior: "smooth" });

    //   // this is needed if we will need to highlight selected tag
    //   c.style.border = '1px solid green';
    //   // c.style.background = '#000';
    //     // for (var prop in tagRefs) {
    //     //   if (prop !== tagId) {
    //     //     tagRefs[prop].current!.style.background = '#fff';
    //     //     console.log('obj.' + prop + ' = ' + tagRefs[prop]);
    //     //   }
    //     // }
    // }
  };

  React.useEffect(() => {
    if (editShowcaseTagId) {
      handleClick(editShowcaseTagId);
    }
  }, [editShowcaseTagId]);

  return (
    <div className={classes.tagsListRoot}>
      {/*
        <SearchBar
            // overlap={false}
            value={filterText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFilterText(event.target.value)
            }
            className={classes.searchBar}
            style={{backgroundColor: "red", width: "100%", height: "40px"}}
            placeholder={"Search"}
          /> */}


      {(<>
        <SearchBar
          overlap={false}
          value={filterText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFilterText(event.target.value)
          }
          style={{ backgroundColor: "red", width: "100%", height: "40px" }}
          placeholder={"Search"}
        />

        <List
          // component='nav'
          aria-labelledby='nested-list-subheader'
          className={classes.root}>
          {visibleSpaceTags?.map((showcaseTag: ShowcaseTag) => {
            let tagLabelIds = showcaseTag?.annotationType === ANNOTATION_TYPE.TASK ? showcaseTag.taskTag?.label.map(l => (l.id)) : showcaseTag.labelIds;
            let tagLabelList: (LabelObj | undefined)[] | null = tagLabelIds?.map(tagId => (projectDetail?.labelList.find(tag => (tag.id === tagId)))) || null;
            return (<div key={`main-${showcaseTag.id}`}>
              <ItemHover key={`item-hover-${showcaseTag.id}`} >
                <Field id={showcaseTag.id} key={showcaseTag.id} >
                  <ListItem key={showcaseTag.id} className={classes.listItem}
                    onClick={() => {
                      handleTagClick(showcaseTag.id);
                    }}

                  >
                    {/* {Object.keys(currentScene || {}).length !== 0 && ( */}
                    {currentTagGroupId && (
                      <Checkbox
                        edge='start'
                        checked={
                          currentTagGroupId &&
                            tagGroupsList &&
                            tagGroupsList.length > 0 &&
                            (tagGroupsList
                              .filter(
                                (tagGroup: TagGroup) =>
                                  tagGroup.id === currentTagGroupId,
                              )[0]
                              ?.tagIds || []).includes(showcaseTag.id)
                            ? true
                            : false
                          // currentScene.tags
                          //   ?.map((o: any) => o?.data?.label)
                          //   .flat()
                          //   .indexOf(showcaseTag.data?.label) !== -1
                        }
                        tabIndex={-1}
                        onChange={async () => {
                          let currentTagGroup = tagGroupsList.filter(
                            (tagGroup: TagGroup) =>
                              tagGroup.id === currentTagGroupId,
                          )[0];
                          let tgs: TagGroup[] = [];
                          if (currentTagGroup.tagIds.includes(showcaseTag.id)) {
                            //uncheck the tag i.e. remove from tag group
                            // tagGroupsList =
                            // // currentTagGroup.tagIds = currentTagGroup.tagIds.filter((tagId: string) => tagId !== showcaseTag.id);
                            // tagGroupsList.map(((tagGroup: TagGroup) => tagGroup.id == currentTagGroup.id && tagGroup.tagIds.splice(
                            //   tagGroup.tagIds.indexOf(showcaseTag.id)
                            // )))

                            tagGroupsList.forEach((tagGroup) => {
                              if (tagGroup.id == currentTagGroupId) {
                                tagGroup.tagIds = (tagGroup.tagIds || []).filter(
                                  (tid) => tid != showcaseTag.id,
                                );
                                currentTagGroup = tagGroup;
                              }
                              tgs.push(tagGroup);
                            });
                          } else {
                            //check the tag i.e. remove from tag group
                            tagGroupsList.forEach((tagGroup) => {
                              if (tagGroup.id == currentTagGroupId) {
                                tagGroup.tagIds.push(showcaseTag.id);
                                currentTagGroup = tagGroup;
                              }
                              tgs.push(tagGroup);
                            });
                          }
                          dispatch(setTagGroupsList(tgs));

                          await firestore
                            .doc(
                              `Spaces/${currentSpaceId}/lessons/${currentLessonId}/tagGroups/${currentTagGroup.id}`,
                            )
                            .set(currentTagGroup);

                          // tagGroupDocs.docs.map(tagGroupDoc => tgs.push({ id: tagGroupDoc.id, name: tagGroupDoc.data().name,
                          //   tagIds: tagGroupDoc.data().tagIds }));

                          // if (
                          //   currentScene.tags
                          //     ?.map((o: any) => o?.data?.label)
                          //     .flat()
                          //     .indexOf(showcaseTag.data?.label) !== -1
                          // ) {
                          //   // let [filterMatterTag] = sceneCollapsableOpen.tags.filter((item: any) => (item.label === tagTableData.data?.label));
                          //   submitSelectTagsForScene(showcaseTag, false);
                          // } else {
                          //   submitSelectTagsForScene(showcaseTag, true);
                          // }
                        }}
                        disableRipple
                        inputProps={{ 'aria-labelledby': showcaseTag.data?.label }}
                      />
                    )}
                    {/* <ListItemIcon style={{ minWidth: '40px' }}>
                      <VisibilityIcon />
                    </ListItemIcon> */}

                    {/* <ListItemIcon style={{ minWidth: '40px' }}>
                  {showcaseTag?.annotationType === 'quiz' ? (
                    <HelpOutlinedIcon style={{ color: '#F57E7E' }} />
                  ) : ((showcaseTag?.annotationType === ANNOTATION_TYPE.TASK ? (
                    <AssignmentTurnedInRoundedIcon style={{ color: '#F57E7E' }} />) : (
                    <InfoOutlinedIcon style={{ color: '#F57E7E' }} />
                  )))}
                </ListItemIcon> */}

                    <Box flex={1} width={'90%'}>
                      <ListItemText
                        id={showcaseTag.id}
                        disableTypography={true}
                        primary={
                          showcaseTag?.annotationType === 'quiz'
                            ? showcaseTag?.quizDetails?.question
                            : showcaseTag?.annotationType === ANNOTATION_TYPE.TASK
                              ? showcaseTag?.taskTag?.title
                              : showcaseTag.data?.label
                        }
                        className={classes.listItemTitle}
                        style={{
                          cursor: 'pointer',
                          // fontSize: '12px',
                        }}

                      />
                      {/* {showcaseTag?.annotationType === 'info' && ( */}
                      <Box>
                        <Scrollbar className={classes.scrollLabels}>
                          {tagLabelList && tagLabelList.map(label => (
                            <Tooltip arrow title={label?.name || ''} placement='top' key={label?.id}>
                              <Box
                                height={{ xs: 6, sm: 8 }}
                                width={20}
                                mx={1}
                                minWidth={20}
                                borderRadius={20}
                                key={label?.id}
                                bgcolor={label?.color}
                              />
                            </Tooltip>
                          ))}
                        </Scrollbar>
                      </Box>
                      {/* )} */}
                    </Box>

                    {canEdit() && (
                      <span style={{ marginLeft: 'auto' }}>
                        <OverflowMenu menuItems={tagOverflowMenuItems(showcaseTag)} />
                      </span>
                    )}
                  </ListItem>
                </Field>
              </ItemHover>
            </div>);
          })}
        </List></>
      )}
    </div>
  );
};

export default TagsList;
