import Api from '@crema/services/ApiConfig';
import { store } from 'App';
import htmlToDraft from 'html-to-draftjs';
import MpSdk from 'mp/MpSdk';
// <<<<<<< s17/ahsan/mergeRerenderFix&ProjectBoard
// import { setEditShowcaseTagId, setOpenTagForm, setTagsViewMode } from 'redux/actions/Home';
// =======
import { setEditShowcaseTagId, setOpenTagForm, setSpaceTagsList, setTagsViewMode } from 'redux/actions/Home';
// >>>>>>> s17/ahsan/newDecMerge&ProjectRerenderFix
import { LabelColorFromTag } from 'shared/constants/AppConst';
import { CollaboratorObj, ProjectObj, TaskObj } from 'types/models/apps/ProjectBoard';
import { ANNOTATION_TYPE, HandleTagClickOptions, IQuizDetails, ShowcaseTag, TagGroup } from 'types/models/home/HomeApp';
import { ViewMode } from './SpaceView/Sidebar';
import { addToCurrentTagsSidMapGlobal, externalTags, getTagIdForTagSid, getTagSidForTagId, setCurrentTagsSidMapGlobal, setExternalTags } from './SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils';
import { mediaTagHtml, quizTagHtml } from './SpaceView/Sidebar/TagSidebar/Tags/tagHt';
import { taskTagHtml } from './SpaceView/Sidebar/TagSidebar/Tags/taskTagHtml';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import _ from 'lodash';
import { fetchError, fetchStart, fetchSuccess, setShowDictaphone, showMessage } from 'redux/actions';
import { firestore, storage } from '@crema/services/auth/firebase/firebase';
import { svgToPng } from '@crema/core/SvgToPng';
import { ScheduledSvg } from 'assets/images/home/icons/ScheduledSvg';
import { InProgressSvg } from 'assets/images/home/icons/InProgressSvg';
import { CompleteSvg } from 'assets/images/home/icons/CompleteSvg';
import { NeedsAttentionSvg } from 'assets/images/home/icons/NeedsAttentionSvg';
import { ReactComponent as InProgressTest } from 'assets/images/home/icons/InProgress.svg';
import { SET_SPACE_DATA } from 'types/actions/Home.action';

export const getTagIcon = (annotationType: any): any => {
  return tagIconMap1.get(annotationType);
};
// const tagIconMap = {
//     'annoIconId': 'https://i.imgur.com/c4Cwbjd.png',
//     'quizIconId': 'https://i.imgur.com/gJlKMOv.png',
//     'taskScheduled':
//         '/assets/images/home/clock.png',
//     'taskComplete':
//         '/assets/images/home/checked.png',
//     'taskPaused':
//         '/assets/images/home/pause.png',
//     'taskInProgress':
//         '/assets/images/home/play-button.png'
// }

// const tagIconIdMap = (annoType) => {
//     switch (annoType) {
//         case 'quiz': return 'quizIconId'
//         case 'taskComplete': return 'quizIconId'
//     }
//     if (annoType === 'quiz')

// }

export const taskColors: Record<string, string> = {
  Complete: '#008037',
  'In Progress': '#D6AE0D',
  Scheduled: '#02377D',
  'Needs Attention': '#BE1931',
};
export const taskCompleteColor = '008037';
export const taskInProgressColor = 'D6AE0D';
export const taskScheduledColor = '02377D';
export const taskNeedsAttentionColor = 'BE1931';

const tagIconMap1: Map<string, string> = new Map<string, string>();
tagIconMap1.set('undefined', 'https://i.imgur.com/c4Cwbjd.png');
tagIconMap1.set('anno', 'https://i.imgur.com/c4Cwbjd.png');
tagIconMap1.set('quiz', 'https://i.imgur.com/gJlKMOv.png');
// tagIconMap1.set('taskScheduled', '/assets/images/home/clock.png');
// tagIconMap1.set('taskComplete', '/assets/images/home/orangeIcon.png');
tagIconMap1.set('task', '/assets/images/home/orangeIcon.png');
// 'https://i.imgur.com/dSEEzXP.png')
// '/assets/images/home/checked.png')
// tagIconMap1.set('taskPaused', '/assets/images/home/orangeIcon.png');
// '/assets/images/home/pause.png')
// tagIconMap1.set('taskInProgress', '/assets/images/home/play-button.png');

export const registerAllIcons = (mpSdk: any) => {
  // Array.from(tagIconMap1).map(([key,value]) => mpSdk.Mattertag.registerIcon(key, value).catch(console.error));
  // tagIconMap1.keys().next(key => mpSdk.Mattertag.registerIcon(key, tagIconMap1[key]));
  // Object.entries(tagIconMap).forEach((k, v) => mpSdk.Mattertag.registerIcon(k, v));

  mpSdk.Mattertag.registerIcon('info-Red', '/assets/images/home/InfoIconRed.png');
  mpSdk.Mattertag.registerIcon('info-Orange', '/assets/images/home/InfoIconOrange.png');
  mpSdk.Mattertag.registerIcon('info-Pink', '/assets/images/home/InfoIconPink.png');
  mpSdk.Mattertag.registerIcon('info-PinkWarm', '/assets/images/home/InfoIconPinkWarm.png');
  mpSdk.Mattertag.registerIcon('info-Lavender', '/assets/images/home/InfoIconLavender.png');
  mpSdk.Mattertag.registerIcon('info-Purple', '/assets/images/home/InfoIconPurple.png');

  mpSdk.Mattertag.registerIcon('info-Teal', '/assets/images/home/InfoIconTeal.png');
  mpSdk.Mattertag.registerIcon('info-Blue', '/assets/images/home/InfoIconBlue.png');
  mpSdk.Mattertag.registerIcon('info-SkyBlue', '/assets/images/home/InfoIconSkyBlue.png');
  mpSdk.Mattertag.registerIcon('info-Olive', '/assets/images/home/InfoIconOlive.png');
  mpSdk.Mattertag.registerIcon('info-Green', '/assets/images/home/InfoIconGreen.png');
  mpSdk.Mattertag.registerIcon('info-Mint', '/assets/images/home/InfoIconMint.png');

  mpSdk.Mattertag.registerIcon('info-Lime', '/assets/images/home/InfoIconLime.png');
  mpSdk.Mattertag.registerIcon('info-Yellow', '/assets/images/home/InfoIconYellow.png');
  mpSdk.Mattertag.registerIcon('info-Mustard', '/assets/images/home/InfoIconMustard.png');
  mpSdk.Mattertag.registerIcon('info-Brown', '/assets/images/home/InfoIconBrown.png');
  mpSdk.Mattertag.registerIcon('info-Grey', '/assets/images/home/InfoIconGrey.png');
  mpSdk.Mattertag.registerIcon('info-Black', '/assets/images/home/InfoIconBlack.png');


  // mpSdk.Mattertag.registerIcon('annoIconId', '/assets/images/home/InfoBlue3DRedWhite.png');
  // mpSdk.Mattertag.registerIcon('anno', '/assets/images/home/InfoIcon3DBlueWhite.png');

  mpSdk.Mattertag.registerIcon('quizIconId', '/assets/images/home/QuizIconGreen.png');
  mpSdk.Mattertag.registerIcon('quiz', '/assets/images/home/QuizIconGreen.png');
  mpSdk.Mattertag.registerIcon('quiz-Green', '/assets/images/home/QuizIconGreen.png');
  mpSdk.Mattertag.registerIcon('quiz-Red', '/assets/images/home/QuizIconRed.png');
  mpSdk.Mattertag.registerIcon('quiz-PinkWarm', '/assets/images/home/QuizIconPinkWarm.png');
  mpSdk.Mattertag.registerIcon('quiz-Mustard', '/assets/images/home/QuizIconMustard.png');
  mpSdk.Mattertag.registerIcon('quiz-Blue', '/assets/images/home/QuizIconBlue.png');

  mpSdk.Mattertag.registerIcon('media', '/assets/images/home/MediaIconBlue.png');

  svgToPng(ScheduledSvg()).then((url) => {
    mpSdk.Mattertag.registerIcon(
      'taskScheduled',
      url,
    );
  });

  svgToPng(InProgressSvg()).then((url) => {
    console.log(`[st] InProgressSvg ${url}`);

    mpSdk.Mattertag.registerIcon(
      'taskInProgress',
      url,
    );
  });

  svgToPng(CompleteSvg()).then((url) => {
    mpSdk.Mattertag.registerIcon(
      'taskComplete',
      url,
    );
  });

  svgToPng(NeedsAttentionSvg()).then((url) => {
    mpSdk.Mattertag.registerIcon(
      'taskNeedsAttention',
      url,
    );
  });

  // mpSdk.Mattertag.registerIcon(
  //   'taskInProgress',
  //   '/assets/images/home/InProgressIcon2.png',
  // );
  // mpSdk.Mattertag.registerIcon(
  //   'taskNeedsAttention',
  //   '/assets/images/home/NeedsAttentionIcon2.png',
  //   // '/assets/images/home/pause.png'
  // );
  // mpSdk.Mattertag.registerIcon(
  //   'taskComplete',
  //   '/assets/images/home/CompleteIcon2.png',
  // );

  // let a = svgToPng(NeedsAttentionSvg);

  mpSdk.Mattertag.registerIcon('task', '/assets/images/home/checked.png');
};

export const getFilteredTags = (
  filters: any,
  tags: Map<string, ShowcaseTag>,
): ShowcaseTag[] => {
  if (filters?.tagsViewMode == ViewMode.STATUS) {
    return Array.from(tags.values()).filter(
      (t) => t.annotationType && t.annotationType.startsWith('task'),
    );
  } else {
    return Array.from(tags.values());
  }
};

export const wrapTagHtml = (content: string) => {
  return `<div style="color:white; font-family:Poppins">` +
    content +
    `</div>`;
}

export const getFirestoreLink = (path: string) => {

  path.replaceAll("/", "~2F");
  return `https://console.firebase.google.com/u/0/project/virtual-tc/firestore/data/~2F${path}`;

}

export const makeModelsAccessible = () => {

  Api.post('/admin/model-check', { "modelPath": `3d/` })
    .then(data => {
      if (data.status === 200) {
        alert(`[st] model now accessible `);
        console.log(`[st] model now accessible`);
        // dispatch(fetchSuccess());
        // dispatch({ type: GET_SPACES_LIST, payload: data.data });
      } else {
        console.error(`[st] error ${JSON.stringify(data)} `);
        // dispatch(
        //   fetchError(messages['message.somethingWentWrong'] as string),
        // );
      }
    })
    .catch(error => {
      console.error(`[st] error ${JSON.stringify(error)} `);
    });
}

export function makeQuizHtml(
  mattertagId: string,
  quizDetails: IQuizDetails | undefined,
  tagHtml: string,
) {
  return (
    quizTagHtml(quizDetails, tagHtml) +
    serializeQuizFunctionCall(mattertagQuizFunction, mattertagId, quizDetails)
  );
}

export function makeMediaHtml(
  mattertagId: string,
  mediaLink: string,
  tagHtml: string,
) {
  return (
    mediaTagHtml(mediaLink, tagHtml) +
    serializeMediaFunctionCall(mattertagMediaFunction, mattertagId, mediaLink)
  );
}

export function makeTaskTagHtml(taskItem: TaskObj, tagSid: string, tagHtml: string,
  // project: ProjectObj
) {

  let taskTagHtmlText: any = taskTagHtml(taskItem, tagSid,
    // project
  );

  return (
    taskTagHtmlText
     +
    serializeTaskFunctionCall(mattertagTaskFunction, taskItem, tagSid)
  );
}



// serialize the contents of the function and call it with a single parameter.
// Then, wrap it with a script tag. '<' is separated from '/' to prevent the browser from
// adding this script tag to the parent page on jsfiddle.
function serializeQuizFunctionCall(
  myFunction: any,
  mattertagId: string,
  quizDetails: IQuizDetails | undefined,
) {
  return (
    `<script>
        ${myFunction.toString()};
        ${myFunction.name}('${mattertagId}',${JSON.stringify(quizDetails)});
        <` + `/script>`
  );
}

function serializeTaskFunctionCall(
  myFunction: any,
  taskItem: any,
  tagSid: any,
) {
  return (
    `<script>
${myFunction.toString()};
${myFunction.name}(${JSON.stringify(taskItem)},'${tagSid}');
<` + `/script>`
  );
}

function serializeMediaFunctionCall(
  myFunction: any,
  mattertagId: string,
  mediaLink: string,
) {
  return (
    `<script>
        ${myFunction.toString()};
        ${myFunction.name}('${mattertagId}',${JSON.stringify(mediaLink)});
      <` + `/script>`
  );
}


// The contents of this function will be executed in a sandboxed mattertag iframe.
// You can only refer to variables defined in the iframe.
function mattertagQuizFunction(
  mattertagId: string,
  quizDetails: IQuizDetails,
) {
  function getSelectedCheckboxValues(name: string) {
    const checkboxes = document.querySelectorAll(
      `input[name="${name}"]:checked`,
    );
    let value: string = '';
    checkboxes.forEach((checkbox: any) => {
      value = checkbox.value;
    });
    return value;
  }
  const btn: any = document.querySelector('#radioInputForm');
  btn.addEventListener('change', (event: any) => {
    let selectedChoice = getSelectedCheckboxValues('choice');

    let correctAns: any = document.getElementById('correct-ans');
    let wrongAns: any = document.getElementById('wrong-ans');

    if (quizDetails?.answer.includes(selectedChoice)) {
      correctAns.style.display = 'block';
      wrongAns.style.display = 'none';
    } else {
      wrongAns.style.display = 'block';
      correctAns.style.display = 'none';
    }
  });
}

function mattertagMediaFunction(mattertagId: string, mediaLink: string) { }

function mattertagTaskFunction(taskItem: any, tagSid: any) {
  // when the button is clicked send a message back to the parent page through the window object.
  let editTaskBtn: any = document.getElementById('editTaskBtn');
  editTaskBtn.addEventListener('click', function (event: any) {
    // console.log(`[st] editTaskBtn button clicked`);
    // setOpenEditTagForm(true);
  });
}

export function mediaTypeFinder(matterportType: string) {
  let type = '';

  if (!matterportType) {
    type = `mattertag.media.none`;
  } else if (matterportType.includes('video')) {
    type = `mattertag.media.video`;
  } else if (matterportType.includes('photo')) {
    type = `mattertag.media.photo`;
  } else if (matterportType.includes('file')) {
    type = `mattertag.media.file`;
  } else if (matterportType.includes('rich')) {
    type = `mattertag.media.rich`;
  } else {
    type = `mattertag.media.none`;
  }
  // console.log(type,"mediaTypeFinder")
  return type;
}

/*
  tagIds: firebase doc IDs for things like Layers and TagGroups that store tagIds, and need to hide/ show tags
  tagObjects: typically to load from the database when the app is first laoded
*/
export const addTagsToEmptyShowcase = async (
  tagIds?: string[],
  tagObjects?: ShowcaseTag[],
  loadAll = false,
) => {
  // await initMpSdk();

  if (!MpSdk.Instance.mps) {
    return;
  }

  let showcaseTagsToAdd;

  let tagsFromMatterport = await MpSdk.Instance.mps.Mattertag.getData();

  try {
    await MpSdk.Instance.mps.Mattertag.remove(
      tagsFromMatterport.map((tag: any) => tag.sid),
    );
  } catch (err: any) {
    console.error(err);
    store.dispatch( //FIXME with return (dispatch: Dispatch<AppActions>) => {
      fetchError(
        'Something went wrong connecting to Matterport. Please refresh the page',
      ),
    );
    return;
  }

  if (tagObjects && tagObjects.length > 0) {
    // tag objects from DB e.g. during initial load
    showcaseTagsToAdd = tagObjects;
    // if(space.tagsImported){
    // console.log(`[st] setstate setting externaltags`);
    //store, in case user wants to import from Matterport
    setExternalTags(tagsFromMatterport);
    // }

  } else {
    let spaceTags = store.getState().home.spaceTags;
    showcaseTagsToAdd = tagIds
      ? // && tagIds.length > 0
      tagIds.reduce((res: ShowcaseTag[], tagId: string) => {
        // console.log(`[st] using spaceTags 4`);
        let spaceTag = spaceTags.get(tagId);
        spaceTag != undefined && res.push(spaceTag);
        return res;
      }, [])
      : getFilteredTags({}, spaceTags); //FIXME STATUS doesn't work - removed tagsViewMode here
  }

  // let xrTags = showcaseTagsToAdd.map(tag => ({label: JSON.parse(tag.data).label, position: JSON.parse(tag.data).anchorPosition}))

  // console.log(`[st] tags dump ${JSON.stringify(xrTags) }`);


  let tagDataToAdd = showcaseTagsToAdd.map((tag: any) => tag.data);

  // if(!currentTagGroupId ) {
  for (let i = 0; i < showcaseTagsToAdd.length; i++) {
    try {
      if (tagDataToAdd[i].media){
        tagDataToAdd[i].media = {
          type: mediaTypeFinder(tagDataToAdd[i].media?.type),
          src: tagDataToAdd[i].media?.src,
        };

      }

      let newTagSid = (await MpSdk.Instance.mps.Mattertag.add(tagDataToAdd[i]))[0];
      let showcaseTag = showcaseTagsToAdd[i];
      let currentSid = newTagSid; //newTagSids[i];
      await initiateTag(showcaseTag, currentSid);
    } catch (error: any) {
      console.error(error);
    }
    // }
  }
};

export const initiateTag = async (showcaseTag: ShowcaseTag, currentSid: string,
  // project:ProjectObj
) => {

  // console.log(showcaseTag, currentSid, 'initiateTag');
  //set global tag sid map
  addToCurrentTagsSidMapGlobal(showcaseTag.id, currentSid);

  //TODO double check before merging, should we prevent these actions?
  // mpSdk.Mattertag.preventAction(currentSid, {
  //   // opening: true,
  //   // navigating: true
  // });

  //TODO is this needed? test media tags from matterport
  showcaseTag.data?.media?.type &&
    (showcaseTag.data.media.type =
      'mattertag.media.' + showcaseTag.data.media.type);

  let iconId = '';
  //set icon
  // if (showcaseTag.data.source == 'ST') {
  if (!showcaseTag.annotationType) {
    showcaseTag.annotationType = 'info';


  }
  if (!showcaseTag.annotationType || showcaseTag.annotationType == 'info') {

    if (showcaseTag.data.media && showcaseTag.data.media.src) {
      iconId = `media`;
    } else {
      iconId = `info-${LabelColorFromTag(showcaseTag.data.color)}`;
    }
  }

  if (showcaseTag.annotationType.startsWith('quiz')) {
    if (showcaseTag.data.color) {
      iconId = `quiz-${LabelColorFromTag(showcaseTag.data.color)}`;
    } else {
      iconId = 'quizIconId';
    }
  }
  // alert("2" + iconId);
  if (showcaseTag.annotationType.startsWith('task')) {
     let sectionName = store?.getState().home.currentSpace?.currentSpaceProject?.sectionList?.find(s => s.id == showcaseTag.taskTag?.sectionId)?.name;
    //  alert(sectionName);
    iconId = 'task' + sectionName?.replaceAll(' ', '');
  }

  await MpSdk.Instance.mps.Mattertag.editIcon(currentSid, iconId);

  //inject HTML
  try {
    const tagHtml = showcaseTag.tagHtml || '';
    const blocksFromHtml = htmlToDraft(tagHtml);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    let pureText = ''
    convertToRaw(editorState.getCurrentContent()).blocks.forEach(block => {
      pureText = pureText + ' ' + block.text
    });

    const textToSpeechButton = `<button style="position: absolute; right: 5px; top: 0px;" onclick="(()=>{
      var synth = window.speechSynthesis;
      var utterThis = new SpeechSynthesisUtterance('${pureText}');
      synth.speak(utterThis);
      return;
      })()"><img src="https://icon-library.com/images/google-voice-icon/google-voice-icon-20.jpg" height='12' width='7' /></button>`;
    let tagHtmlSpeech =
      tagHtml !== '<p></p>\n' //i.e. if draft is empty
        ? `<div style="color:white; font-family:Open Sans;"> ${tagHtml} ${showcaseTag.showTextToSpeech ? textToSpeechButton : ''} </div>`
        : '';
    if (showcaseTag?.annotationType === ANNOTATION_TYPE.INFO) {
      // if (showcaseTag?.mediaLink) {
      //   const htmlString = makeMediaHtml(
      //     currentSid,
      //     showcaseTag?.mediaLink,
      //     tagHtml,
      //   );
      //   await injectHtmlHandler(htmlString, currentSid, 600, 400);
      // } else {
      await injectHtmlHandler(tagHtmlSpeech, currentSid, undefined, undefined); //, 400, 200);
      // }
    } else if (showcaseTag?.annotationType === ANNOTATION_TYPE.QUIZ) {
      const htmlString = makeQuizHtml(
        currentSid,
        showcaseTag.quizDetails,
        tagHtmlSpeech,
      );
      await injectHtmlHandler(htmlString, currentSid, 400, 250);
    } else if (showcaseTag?.annotationType?.startsWith(ANNOTATION_TYPE.TASK)) {
      const htmlString = !!showcaseTag.taskTag ? makeTaskTagHtml(
        showcaseTag.taskTag,
        currentSid,
        tagHtmlSpeech,
        // project
      ): '';
      await injectHtmlHandler(htmlString, currentSid, 600, 250);
    }
  } catch (error: any) {
    console.error(error);
  }
};


const injectHtmlHandler = async (
  htmlString: any,
  tagSid: string,
  width: undefined | number,
  height: undefined | number,
) => {
  // await initMpSdk();


  if (htmlString !== '') {
    MpSdk.Instance.mps &&
      MpSdk.Instance.mps.Mattertag.injectHTML(tagSid, htmlString, {
        size: {
          w: width,
          h: height,
        },
        windowPath: '',
      })
        .then(function (messenger: any) {
          messenger.on('editTask', function (tagSid: any) {
            store.dispatch(setTagsViewMode(ViewMode.TAGS));
            let selectedShowcaseId = getTagIdForTagSid(tagSid);

            selectedShowcaseId && store.dispatch(setEditShowcaseTagId(selectedShowcaseId));
            store.dispatch(setOpenTagForm(true));
          });

          messenger.on('addVoiceComment', function (tagSid: any) {
            let selectedShowcaseId = getTagIdForTagSid(tagSid);

            selectedShowcaseId && store.dispatch(setEditShowcaseTagId(selectedShowcaseId));
            store.dispatch(setShowDictaphone(true));
          });
        })
        .catch((e: any) => {
          console.error(e);
          console.error('Error in injectHTML');
        });
  }
};

export const importMatterportTags = () => {

      if (externalTags.length == 0) {
        store.dispatch(showMessage("You don't have any tags to import from Matterport! But you can create new tags from the Quick Add toolbar!"));
        return;
      }
      let batch = firestore.batch();
      let tagsFromMpToSave: ShowcaseTag[] = [];

      externalTags.forEach((tag: any) => {
        if (tag.enabled === true) {
          // tag.annotationType = ANNOTATION_TYPE.INFO;
          tag.source = 'MP';
          // tag.tagHtml = wrapTagHtml(tag.description);
          let tagString = JSON.stringify(tag);
          const tagsCollectionRef = firestore
            .collection('Spaces')
            .doc(store.getState().home.currentSpace?.id)
            .collection('tags');
          let tagDocRef = tagsCollectionRef.doc();
          batch.set(tagDocRef, {
            data: tagString,
            sid: tag.sid,
            annotationType: ANNOTATION_TYPE.INFO,
            createdOn: new Date(),
            lastUpdatedOn: new Date(),
            createdBy: store.getState().auth.authUser?.uid,
            lastUpdatedBy: store.getState().auth.authUser?.uid,
            tagsAlreadyImported: true
          });
          tagsFromMpToSave.push({ id: tagDocRef.id, data: tagString });
        }
      });
      batch.commit().then(() => {
        let space = _.cloneDeep(store.getState().home.currentSpace);
        if (space){
          space.tagsImported = true;
          firestore.doc(`Spaces/${space?.id}`).update({ tagsImported: true }).then(() => {
            store.dispatch({ type: SET_SPACE_DATA, payload: space });
            getMatterportTagsFromDB();
          }).catch(console.error);
        }

      });
    };



export const handleTagClick = async (showcaseTagId: string, options?: HandleTagClickOptions) => {
  // await initMpSdk();
  let currentTagSid = getTagSidForTagId(showcaseTagId);
  // let spaceTags = store.getState().home.spaceTags;
  // let spaceTag = spaceTags.get(showcaseTagId);

  if (currentTagSid && MpSdk.Instance.mps) {
    //  let cts = await MpSdk.Instance.mps.Mattertag.getData();
    // console.log(
    //   `[st] tag clicked ${showcaseTagId}`
    // );
    if (options && options.preventNavigation) {
      MpSdk.Instance.mps.Mattertag.preventAction(currentTagSid, {
        // opening: true,
        navigating: true
      }).then(() => {
        console.log(`[st] nav to tag 1`);

        MpSdk.Instance.mps.Mattertag.navigateToTag(
          currentTagSid,
          MpSdk.Instance.mps.Mattertag.Transition.FLY,
        ).then(() => {
          // setTimeout(() => spaceTag != undefined && playTextToSpeech(spaceTag.data.label), 1000); //TODO commenting this for now
        }).catch(console.error);
      })
    } else {
      console.log(`[st] nav to tag 2`);
      MpSdk.Instance.mps.Mattertag.navigateToTag(
        currentTagSid,
        MpSdk.Instance.mps.Mattertag.Transition.FLY,
      ).then(() => {
        // spaceTag != undefined && playTextToSpeech(spaceTag.data.label);
      }).catch(console.error);
    }

  }
};

const playTextToSpeech = (text: string) => {

  const synth = window.speechSynthesis;
  const utterThis = new SpeechSynthesisUtterance(text);
  synth.speak(utterThis);

};

export const handleDeleteTag = async (tagId: string) => {
  console.log(`[st] using spaceTags`);
  // let visibleSpaceTags: ShowcaseTag[] = Array.from(spaceTags.values());
  // let tag: ShowcaseTag | undefined = visibleSpaceTags.find((el: ShowcaseTag) => tagId === el.id);

  // if (tag) {
  let res = window.confirm(`Are you sure you want to delete this tag?`);
  if (res) {
    const deleteTagRef: any = await firestore
      .doc(`Spaces/${store.getState().home.currentSpace?.id}`)
      .collection(`tags`)
      .doc(tagId);

    // const { id: deleteTagRefId } = deleteTagRef;
    deleteTagRef
      .delete()
      .then(() => {
        getMatterportTagsFromDB();
      })
      .catch(console.error);
  }
  // }
};

/*
   Re-initialize all Matterport tags in DB
   Remove all from showcase
   Add tags and annotations from DB to showcase
    */
export const getMatterportTagsFromDB = async () => {

  let space = store.getState().home.currentSpace;
  // Get all space projects and find the default project
  let spaceProjects = await Api.get(`/api/spaces/${space?.did}/spaceProjectList`);
  const sProject: ProjectObj = spaceProjects.data.find((f: ProjectObj) => (f.name.includes("Default Project")));

  let tagsFromDb: ShowcaseTag[] = [];
  let mergeTaskTags = [];
  let badTagIds: string[] = [];

  let qsTags = await firestore
    .collection('Spaces')
    .doc(space?.id)
    .collection('tags')
    .get();
  let tags = qsTags.docs.map((doc: ShowcaseTag) => ({ ...doc.data(), id: doc.id }));

  let otherTags = tags.filter((doc: ShowcaseTag) => doc.annotationType !== "task");
  let taskTags = tags.filter((doc: ShowcaseTag) => (doc.annotationType === "task" && (sProject && doc.projectId === sProject.id)));

  let qsProjectTasks = await firestore
    .collection('Projects')
    .doc(sProject.id)
    .collection('Tasks')
    .where('type', '==', 'TASK_TAG')
    .get();

  let projectTasks = await Promise.all(qsProjectTasks.docs.map(async (doc: any) => {
    let label = doc.data().labelIds.map((labId: number) => (sProject.labelList[sProject.labelList.findIndex((a: any) => (a.id == labId))]));
    let collaborators: CollaboratorObj[] = [];

    if ((doc.data()?.collaboratorIds || []).length) {
      let ms = await firestore.collection('Users').where('id', "in", doc.data().collaboratorIds).get();
      collaborators = ms.docs.map(u => ({ id: u.id, name: `${u.data().firstName} ${u.data().lastName}`, image: u.data().photoURL, email: u.data().email }));
    }

    // let collaborators = doc.data().collaboratorIds.map((cId: number) => (project.memberList[project.memberList.findIndex((a: any) => (a.id == cId))]));
    return ({ ...doc.data(), id: doc.id, label, collaborators });

  }));

  // merge Space Task data and Project Task data
  mergeTaskTags = taskTags.map(s => ({ ...s, taskTag: projectTasks.find(p => (p.tagId === s.id)) }));

  const allTags = [...otherTags, ...mergeTaskTags];

  allTags.forEach((tag) => {
    try {
      // attachment-feature
      //           let tagHtml= tag?.tagHtml;
      //         if (tag.data.description) {
      //           tagHtml = `${tagHtml} ${wrapTagHtml(tag.data.description)}`;
      //         }
      const id = tag.id;
      const data = tag.data;
      const annotationType = tag?.annotationType;
      const quizDetails = tag?.quizDetails;
      const mediaLink = tag?.mediaLink;
      const taskTag = tag?.taskTag;
      const tagHtml = tag?.tagHtml;
      const showTextToSpeech = tag?.showTextToSpeech;
      const createdOn = tag?.createdOn;
      const lastUpdatedOn = tag?.lastUpdatedOn;
      const createdBy = tag?.createdBy;
      const lastUpdatedBy = tag?.lastUpdatedBy;
      // const labelIds = tag?.labelIds;

      tagsFromDb.push({
        id: id,
        data: JSON.parse(data),
        ...(annotationType && { annotationType: annotationType }),
        ...(quizDetails && { quizDetails: quizDetails }),
        ...(mediaLink && { mediaLink: mediaLink }),
        ...(taskTag && { taskTag: taskTag }),
        ...(tagHtml && { tagHtml: tagHtml }),
        ...(showTextToSpeech && { showTextToSpeech: showTextToSpeech }),
        ...(createdOn && { createdOn: new Date(tag.createdOn.seconds * 1000) }),
        ...(lastUpdatedOn && { lastUpdatedOn: new Date(tag.lastUpdatedOn.seconds * 1000) }),
        ...(createdBy && { createdBy: createdBy }),
        ...(lastUpdatedBy && { lastUpdatedBy: lastUpdatedBy }),
        // ...(labelIds && { labelIds: labelIds }),
      });
    } catch (error: any) {
      console.error('found a bad tag');
      console.error(error);
      badTagIds.push(tag.id);
    }
  });

  // if (tagsFromDb.find((x) => x.data?.source == 'MP')) {
  //   setTagsAlreadyImported(true);
  // }

  try {
    let tagsFromMatterport = await MpSdk.Instance.mps.Mattertag.getData();
    // await MpSdk.Instance.mps.Mattertag.remove(tagsFromMatterport.map((tag: any) => tag.sid)).catch(console.error);

    if (externalTags.length == 0) {
      console.log(`[st] setstate setting externaltags 2`);
      setExternalTags(tagsFromMatterport);
    }
    tagsFromDb = tagsFromDb.filter((tag) => !badTagIds.includes(tag.id));
    let tagMap = new Map(tagsFromDb.map((t) => [t.id, t]));
    console.log(`[st] setting spaceTags`);
    store.dispatch(setSpaceTagsList(tagMap)); //controls state accross components

    // let tagSidsMap = new Map(tagsFromDb.map(t => [t.id, t.data.sid]));
    // setCurrentTagsSidMapGlobal(tagSidsMap); //global reference for current tag sids

    // add to showcase
    setCurrentTagsSidMapGlobal(new Map());
    await addTagsToEmptyShowcase([], tagsFromDb); //tagsFromDb

    // })
  } catch (error: any) {
    console.error(error, 'error while getting initial tag data');
  }
}

export const getCurrentTagGroup = () => {
  return store.getState().home.tagGroupsList.find(
    (tagGroup: TagGroup) => tagGroup.id === store.getState().home.currentTagGroupId,
  );
}

export const addModelToCurrentTagGroup = (modelId: string) => {
  let tagGroup = getCurrentTagGroup();
  (tagGroup?.tagIds || []).push(modelId);
  // store.getState().home.tagGroupsList.find(tg => tg.id == tagGroup?.id) //FIXME what if tg is undefined - test it

  //   tgs.push(tagGroup);
  //   dispatch(setTagGroupsList(tgs));
}

export const getTagGroupsLength = () => {
  let tgList = store.getState().home?.tagGroupsList;
  return (tgList && tgList.length) ? tgList.length : 0;
}

export const isJumpToTagIdValid = (tagGroup: TagGroup): boolean => {


  return (
    !!tagGroup.jumpToTagId
    && tagGroup.tagIds?.includes(tagGroup.jumpToTagId)
    && (!!getTagSidForTagId(tagGroup.jumpToTagId)
      || store.getState().home.spaceModels.has(tagGroup.jumpToTagId))
  )

}

export const getInQueryResultWithChunks = async (collectionName: string, fieldName: string, inList: string[]) => {
  let inSet = Array.from(new Set(inList));
  let result: any[] = [];
  await Promise.all(
    _.chunk(inSet, 10).map(inListChunk => {

      return firestore.collection(collectionName).where(fieldName, 'in', inListChunk).get()
        .then((qs) => {
          let resultChunk: any[] = qs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          result.push(...resultChunk);
        })
      // .catch(console.error);
    }));
  return result;
}

export const uploadMediaFiles = async (attachments: File[]) => {
  store.dispatch(fetchStart());
  // let tagDocRef = firestore.collection(`Spaces/${store.getState().home.currentSpace?.id}/nodes/${store.getState().auth.authUser?.uid}`);

  //logic to create urls for attachments
  let prefix = `Spaces/${store.getState().home.currentSpace?.did}/nodes`;
  var storageRef = storage.ref(prefix);

  let attachmentsUrls: string[] = [];

  let promises: Promise<string>[] = [];
  promises = attachments.map((f, index) => {
    let file: any = f;

    let filename: any = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
    filename = `${store.getState().auth.authUser?.uid}.${filename}`
    let imageRef = storageRef.child(filename);
    // let baseModelRef = storage.ref(`3d/${type}/${name}`).child(fileName);

    return imageRef
      .put(file)
      .then(() => {
        const imageURL = `https://storage.googleapis.com/virtual-tc.appspot.com/${prefix}/${filename}`; //imageRef.getDownloadURL();
        return imageURL;
      })
      .then(async (imageURL: any) => {
        attachmentsUrls.push(imageURL);
        //make model bucket/folder public
        try {
          let makeFilePublicResult = await Api.post('/admin/model-check', { "modelPath": `${prefix}/${filename}` });

          if (makeFilePublicResult.status === 200) {
            console.debug(`[st] file made public: ${prefix}/${filename}`);
            // dispatch(showMessage("All Done!"));
            // dispatch({ type: GET_SPACES_LIST, payload: data.data });
          } else {
            console.error(`[st] error making file public: ${prefix}/${filename} : ${JSON.stringify(makeFilePublicResult)}`)
            store.dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
          }
        } catch (error) {
          console.error(`[st] error making file public: ${prefix}/${filename} : ${error}`)
          store.dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
        };

        return imageURL;
      })
      .catch((error: any) => {
        console.log(error);
      });
  });

  return await Promise.all(promises).then((response) => { store.dispatch(fetchSuccess()); return response; })
}
