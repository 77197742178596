import { store } from "App";
import _ from "lodash";
import { setSpaceModelsList } from "redux/actions/Home";
import { Logic } from "types/models/dataAccess/Logic";
import { firestore } from "../../../../../@crema/services/auth/firebase/firebase";
import { GizmoTools } from "../../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import Utils from "../../Tools/Utils";
import { SimulationMode } from "../core/RenderingAndPlaceObjectStateSystem";
import Simulation from "../core/Simulation";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import { UserDataProperties } from "../ui-interop/PropertiesPanel";
import Serialization from "./Serialization";

export default class NodeStorage {

    // public static async storeLogicForStep(logic:Logic):Promise<boolean> {
    //     let currentLessonId = store.getState().home.currentLesson?.id;
    //     let currentTagGroupId = store.getState().home.currentTagGroupId;
    //     //console.log(currentLessonId);
    //     let docRef = await firestore.doc(`Spaces/${Simulation.instance.spaceID}/lessons/${currentLessonId}/tagGroups/${currentTagGroupId}`);

    //     if(!docRef) {
    //         return false;
    //     }
    //     //console.log(await (await docRef.get()).data())
    //     //console.log(docRef)
    //     await docRef.update( { "logic" : Utils.SimpleClone(logic)});
    //     return true;
    // }

    public static async storeNode(node: ISceneNode): Promise<boolean> {
        try {
            if (node == null) {
                return false;
            }

            let docRef: any;
            let model;

            //TODO if this slows things down, we can only get floor at create time, not update
            let floorData = await Simulation.instance.renderingSubSystem.sdk().Floor.getData().catch(console.error);
            let currentFloor = floorData ? floorData.currentFloor : '';
            if (!node.userData["id"]) {

                docRef = firestore.collection(`Spaces/${Simulation.instance.spaceID}/nodes`).doc();
                let id = docRef.id;
                node.userData["id"] = id;
                node.userData.floorIndex = currentFloor || node.userData.floorIndex;

                model = Serialization.SerializeNode(node, docRef.id);
                model.createdOn = new Date();
                model.createdBy = Simulation.instance.currentUser.uid;
                model.lastUpdatedOn = model.createdOn;
                model.lastUpadatedBy = model.createdBy;

            } else {
                docRef = firestore.doc(`Spaces/${Simulation.instance.spaceID}/nodes/${node.userData["id"]}`);
                node.userData.floorIndex = currentFloor || node.userData.floorIndex;

                model = Serialization.SerializeNode(node, docRef.id);
                model.lastUpdatedOn = new Date();
                model.lastUpadatedBy = Simulation.instance.currentUser.uid;
            }

            await docRef.set(model, {merge: true});

            //model = this.deserializeNode(docRef);
            //nameToShow: Utils.ExtractModelDisplayName(doc.data().name, doc.id)
            (model as any)[UserDataProperties.nameToShow] = node.userData[UserDataProperties.nameToShow];

            console.log(`[st] spaceModelsMap model ${JSON.stringify(model)}`);
            (model as any).nodeRef = node;
            Simulation.instance.spaceModels.set(model.id, model); //will be used to update redux store

            // GizmoTools?.instance?.props.updateNodes();
            store.dispatch(setSpaceModelsList(Simulation.instance.spaceModels));
            // console.log("Node saved");
            return true;
        } catch (e:any) {
            console.error(`[st] storeNode failed with: ${e}`)
        }

        return false;
    }

    public static async deleteNode() {
        try {
            let nodeToDelete = Simulation.instance.lastSelectedNode;
            let nodeId = nodeToDelete?.userData["id"];
            let dr = firestore.doc(`Spaces/${Simulation.instance.spaceID}/nodes/${nodeId}`);
            let o = await dr.get();

            await o.ref.delete();

            if (Simulation.instance.getSimulationMode() == SimulationMode.ADD_OBJECT) {
                setTimeout(Simulation.instance.resumeMatterPortCameraMovement.bind(this), 1000);
            }

            if (!Simulation.instance.spaceModels || Simulation.instance.spaceModels.size == 0) {
                return;

            }
            if (Simulation.instance.spaceModels.get(nodeId)) {
                if (Simulation.instance.lastSelectedNode === Simulation.instance.spaceModels.get(nodeId).nodeRef) {
                    Simulation.instance.scene.hideTransformGizmo();
                    GizmoTools?.instance?.hideTools();
                    Simulation.instance.propertiesPanel.hidePropertiesPanel();
                    Simulation.instance.lastSelectedNode = null;
                }
            }


            if (Simulation.instance.spaceModels.get(nodeId).nodeRef) {
                Simulation.instance.spaceModels.get(nodeId).nodeRef.stop();
                Simulation.instance.spaceModels.get(nodeId).nodeRef = null;
            }

            Simulation.instance.spaceModels.delete(nodeId);

            store.dispatch(setSpaceModelsList(Simulation.instance.spaceModels));
        } catch (e:any) {
            console.error(`[st] deleteNode failed with: ${e}`)
        }
    }

    public static async loadNodesFromDB(callback: (node: ISceneNode, dbJSON: any) => void):Promise<Map<string, any>> {
        try {
            let nodesFromDB = await firestore.collection(`Spaces/${Simulation.instance.spaceID}/nodes`).get();
            // this.d.log("LOADING nodes... " + `Spaces/${this.spaceID}/nodes`)

            let dbNodesArray = nodesFromDB.docs.map((doc: any) => Serialization.DeserializeNode(doc)).sort((a, b) => a.id - b.id);
            let spaceModelsMap = new Map<string, any>();
            for (let i = 0; i < dbNodesArray.length; i++) {

                let model = dbNodesArray[i];
                spaceModelsMap.set(dbNodesArray[i].id, model);
                // DebugLogger.BigLine();
                // if (!excludeIds.includes(dbNodesArray[i].userData.id)) {
                let nodeStarted = await Simulation.instance.scene.loadClassical(model.name, callback, model);

                model.nodeRef = Simulation.instance.scene.getLastNodeAdded();
                // }
            }


            return spaceModelsMap;
        } catch (e:any) {
            console.error(`[st] failed to load nodes in Simulation : ${e}`)
        }
        //TODO: Show an error message
        return new Map<string, any>();
    }

    public static async duplicateNode() {
        try {
            let node = Simulation.instance.lastSelectedNode;
            if (node == null) {
                return;
            }


            let docRef = firestore.collection(`Spaces/${Simulation.instance.spaceID}/nodes`).doc();
            //node.userData["id"] = docRef.id;
            let model = Serialization.SerializeNode(node, docRef.id);
            model.userData['id'] = docRef.id;
            model.userData[UserDataProperties.nameToShow] = Simulation.instance.scene.generateNameFromCount(node);//model.userData[UserDataProperties.nameToShow] + " copy";
            await docRef.set(model);


            //let newNode = Object.assign({}, node);
            //newNode.userData["id"] = docRef.id;

            let nodeStarted = await NodeStorage.loadNode(model.name, Simulation.instance.scenePreProcess.bind(Simulation.instance), model);
            node = Simulation.instance.scene.getLastNodeAdded();
            let tempNodePosition = node.position;
            tempNodePosition.x += 0.01;
            tempNodePosition.y += 0.05;
            tempNodePosition.z += 0.01;
            node.position.x = tempNodePosition.x;
            node.position.y = tempNodePosition.y;
            node.position.z = tempNodePosition.z;
            (model as any)[UserDataProperties.nameToShow] = node.userData[UserDataProperties.nameToShow];
            (model as any).nodeRef = node;
            Simulation.instance.spaceModels.set(model.id, model);
            store.dispatch(setSpaceModelsList(Simulation.instance.spaceModels));

            //Simulation.instance.selectNode(node);
            await Simulation.instance.selectNodeWithPropertiesUpdate(node);

            console.log("Node duplicated!");
        } catch (e:any) {
            console.error(`[st] duplicateNode failed with: ${e}`)
        }
    }

    public static async loadNode(objectName:string,
         callback: (node: ISceneNode, dbJSON: any) => void = Simulation.instance.scenePreProcess.bind(Simulation.instance),
          dbJSON?: any):Promise<boolean> {
        try {
            return await Simulation.instance.scene.loadClassical(objectName, callback, dbJSON);
        } catch (e:any) {
            console.log(`[st] scene wasn't yet loaded: ${e}`)
        }

        return  false;
    }
}