import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns1 = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params: any) =>
      `${params.getValue(params.id, 'firstName') || ''} ${params.getValue(params.id, 'lastName') || ''
      }`,
  },
];

const rows1 = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function AppDataGrid(
  { rows, columns }: { rows?: any[], columns?: GridColDef[] }
) {

  // rows.every(row => Object.entries(row).every(kv => row[kv[0]] = isObject(kv[1]) ? JSON.stringify(kv[1]) : kv[1]));
  return (
    <div style={{ height: 400,
    // width: '100%'
     }}>


      <DataGrid
        rows={rows || rows1}
        columns={columns || columns1}
        pageSize={20}
        rowsPerPageOptions={[20]}
      // checkboxSelection
      // disableSelectionOnClick
      />

    </div>
  );
}
