import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { onAddNewComment, setShowDictaphone } from 'redux/actions';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import StopIcon from '@mui/icons-material/Stop';
import { Box, Card, IconButton, CardActions, CardContent, Tooltip, Typography, CardHeader, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import IndicatorPulse from '@crema/core/IndicatorPulse';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import EditableTextField from 'modules/home/SpaceDetail/SpaceView/Sidebar/LessonSidebar/EditableTextField';
import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { AppState } from 'redux/store';
import { AuthUser } from 'types/models/AuthUser';
import { CommentObj } from 'types/models/apps/ProjectBoard';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        postButton: {

            textAlign: "end",
            marginLeft: 'auto',

            '&:hover': {
                color: 'green',

            }

        }
    }));

const Dictaphone = (
    // { callbackFn }: { callbackFn: (text: string) => void }
) => {

    const dispatch = useDispatch();
    const commands: any = [
        {
            command: 'reset',
            callback: () => resetTranscript()
        },
        {
            command: 'shut up',
            callback: () => setMessage('I wasn\'t talking.')
        },
        {
            command: 'Hello',
            callback: () => setMessage('Hi there!')
        },
    ]
    const [message, setMessage] = useState('');

    const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser);
    const classes = useStyles();

    const {
        transcript,
        interimTranscript,
        finalTranscript,
        resetTranscript,
        listening,
    } = useSpeechRecognition({ commands });

    useEffect(() => {
        if (finalTranscript !== '') {
            console.log('Got final result:', finalTranscript);
        }
    }, [interimTranscript, finalTranscript]);

    useEffect(() => {
        listenContinuously()
    }, []);
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        return null;
    }

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        console.log('Your browser does not support speech recognition software! Try Chrome desktop, maybe?');
    }
    const listenContinuously = () => {
        SpeechRecognition.startListening({
            continuous: true,
            language: 'en-GB',
        });
    };

    const stopDictaphone = () => {
        SpeechRecognition.stopListening();
        // callbackFn(finalTranscript);
        navigator.clipboard.writeText(finalTranscript);
        dispatch(setShowDictaphone(false));
    }

    const resetDictaphone = () => {
        // SpeechRecognition.
        resetTranscript();
        listenContinuously();
        // SpeechRecognition.stopListening();
        // callbackFn(finalTranscript);
        // navigator.clipboard.writeText(finalTranscript);
        // dispatch(setShowDictaphone(false));
    }

    return (

        <Card style={{ minWidth: '500px', maxWidth: '500px', margin: '16px' }}>
            <CardHeader title='Add Task Comment' action={
                <Tooltip title="Cancel">
                    <IconButton aria-label="Cancel" onClick={stopDictaphone}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            }>

                {/* <Typography style={{ color: 'blue',  }} variant="h6">Add Task Comment</Typography> */}

            </CardHeader>

            <CardContent>
                {/* <Typography variant="h6">Add Task Comment</Typography> */}

                {/* <EditableTextField
                    save={(text) => { (name !== tg.name) && handleEditTagGroup({ ...tg, name: name }) }}

                    multiline={false}
                    initValue={tg.name || ''}
                    placeholder={'Step name or number'}

                /> */}
                <Typography>
                    {transcript}

                    {listening && <IndicatorPulse />}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                {/* <Box style={{ textAlign: "start"
                    // display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'
                     }}> */}
                <IconButton onClick={resetDictaphone}><Tooltip title="Reset"><ReplayRoundedIcon /></Tooltip></IconButton>
                {/* <Button type="Button" onClick={listenContinuously}>Listen</Button> */}
                <IconButton onClick={SpeechRecognition.stopListening}><Tooltip title="Done"><CheckCircleOutlineRoundedIcon /></Tooltip></IconButton>
                {/* </Box>
                <Box style={{
                    // display: "block",
                textAlign: "end"
                    // display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'
                    }}> */}
                <Button variant="outlined" className={classes.postButton}

                    onClick={() => {
                        SpeechRecognition.stopListening();
                        let c = {
                            comment: finalTranscript,
                            name: ((authUser!.user.firstName && authUser!.user.lastName) ? (authUser!.user.firstName + " " + authUser!.user.lastName) : authUser!.email),
                            image: authUser!.photoURL || '',
                            date: new Date(),
                        } as CommentObj;
                        dispatch(onAddNewComment('', '', c));
                        dispatch(setShowDictaphone(false));

                    }
                    }

                >Post Comment</Button>
                {/* </Box> */}
            </CardActions>


        </Card>
    );
};

export default Dictaphone;