import { Loader } from "@crema";
import ConfirmationDialog from "@crema/core/ConfirmationDialog";
import { FileUploader } from "@crema/core/FileUploader";
import ToggleButtons from "@crema/core/ToggleButtons";
import { firestore, storage } from "@crema/services/auth/firebase/firebase";
import { Box, InputLabel, TextField, Button, Divider } from "@material-ui/core";
import React, { Props, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchError, fetchSuccess, showMessage } from "redux/actions";
import { Fonts } from "shared/constants/AppEnums";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useAuthUser } from "@crema/utility/AppHooks";
import { AppState } from "redux/store";
import Api from '@crema/services/ApiConfig';
import { appIntl } from "@crema/utility/Utils";
import { onGetCatalogItems } from "redux/actions/Home";

// const { messages } = appIntl();
const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        fieldLabel: {
            // paddingRight: '16px',
            fontSize: '13px',
            color: 'black',
            width: '100%',
            marginBottom: '8px',
            marginTop: '10px'
        },
        textField: {
            // width: '100%',
            fontWeight: Fonts.LIGHT,
            marginBottom: 20,
            marginTop: 0,
            // marginLeft: 16,
            // marginLeft: 5,
            // marginRight: 5
            '& .MuiOutlinedInput-input': {
                padding: "10px 14px"
            },
            '& .MuiInputBase-root': {
                fontSize: '14px'

            }
        },
    })

);

interface ModelUploaderProps {
    isUploadOpen: boolean
    setClose: () => void
}

interface ModelUploaderState {
    name: string,
    type: string,
    subfolderName: string,
    mainFolderFiles: any[],
    subFolderFiles: any[],
    loading: boolean
}

export default function ModelUploader({ isUploadOpen, setClose }: ModelUploaderProps) {

    const [name, setName] = useState('');
    const [subfolderName, setSubfolderName] = useState('');
    const [mainFolderFiles, setMainFolderFiles] = useState<any[]>([]);
    const [subFolderFiles, setSubFolderFiles] = useState<any[]>([]);
    const [thumbnailFile, setThumbnailFile] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');
    const { currentSpace } = useSelector<
        AppState,
        AppState['home']
    >(({ home }) => home);

    // const [{ name, type, subfolderName, mainFolderFiles, subFolderFiles, loading }, setState] = useState(initialState);
    const dispatch = useDispatch();

    const classes = useStyles();
    let authUser = useAuthUser();

    const uploadMainFolderFiles = async () => {

        if (!type) {
            dispatch(fetchError("Please select a file type!"));
            return;
        }
        if (!name) {
            dispatch(fetchError("Please add a name!"));
            return;
        }
        if (!thumbnailFile) {
            dispatch(fetchError("Please upload a thumbnail image!"));
            return;
        }
        if (mainFolderFiles.length == 0) {
            dispatch(fetchError("You need to upload some files for the main folder!"));
            return;
        }
        if ((subFolderFiles.length > 0 && !subfolderName) || (subFolderFiles.length == 0 && !!subfolderName)) {
            dispatch(fetchError("Sub folder files and name should either both be empty, or both have data"));
            return;
        }


        //main folder files

        let fileUrls: string[] = [];
        await Promise.all(
            mainFolderFiles.map(async (fileObj: any) => {
                let file = fileObj.file;
                let fileName: any = file?.name;

                let baseModelRef = storage.ref(`3d/${type}/${name}`).child(fileName);

                let uploadedFile = await baseModelRef.put(file);
                console.log(`[st] uploaded ${uploadedFile.ref.fullPath} ${uploadedFile.ref.getDownloadURL()}`);

                fileUrls.push(`https://storage.googleapis.com/virtual-tc.appspot.com/3d/${type}/${name}/${fileName}`);
                // .then(() => {
                //   const imageURL = baseModelRef.getDownloadURL();
                //   return imageURL;
                // }).catch(console.error);
            }));

        //sub folder files
        await uploadSubfolderFiles();


        //thumbnail
        let prefix = `3d/${type}/${name}`;
        let file = thumbnailFile[0].file;

        let fileName: any = file?.name;

        let baseModelRef = storage.ref(prefix).child(fileName);

        let uploadedFile = await baseModelRef.put(file);
        console.log(`[st] uploaded ${uploadedFile.ref.fullPath} ${uploadedFile.ref.getDownloadURL()}`);

        let thumbnailUrl = `https://storage.googleapis.com/virtual-tc.appspot.com/${prefix}/${fileName}`;


        let docRef = firestore.collection(`catalog`).doc();
        await docRef.set({
            id: docRef.id,
            type: type, name: name,
            space: currentSpace?.id,
            createdBy: authUser?.uid,
            createdOn: new Date(),
            isPrivate: true //!(authUser && authUser.user.permissions && authUser.user.permissions.includes("MODEL_ADMIN"))
         });
        await docRef.update({
            fileUrls: fileUrls,
            thumbnailUrl: thumbnailUrl
        });
        dispatch(showMessage("Successfully uploaded! Verifying download..."));

        //make model bucket/folder public
        try {
            let modelCheckRes = await Api.post('/admin/model-check', { "modelPath": `3d/${type}/${name}/` });

            if (modelCheckRes.status === 200) {
                console.log(`[st] model now accessible ${name}`);
                dispatch(fetchSuccess());

                dispatch(onGetCatalogItems());
                dispatch(showMessage("Successfully uploaded!"));
                // dispatch({ type: GET_SPACES_LIST, payload: data.data });
                setClose();
            } else {
                console.log(`[st] error: ${JSON.stringify(modelCheckRes)}`)
                dispatch(fetchError("The model is uploaded, but we couldn't verify the download. Please check the uploaded model from the catalog, or try again"));
            }
        } catch (error) {
            console.log(error)
            dispatch(fetchError("The model is uploaded, but we couldn't verify the download. Please check the uploaded model from the catalog, or try again"));
        };
    }


    const uploadSubfolderFiles = async (): Promise<any[]> => {

        return await Promise.all(
            subFolderFiles.map((fileObj: any) => {
                let file = fileObj.file;
                let filename: any = file?.name;
                let baseModelRef = storage.ref(`3d/${type}/${name}/${subfolderName}`).child(filename);

                return baseModelRef
                    .put(file)
                // .then(() => {
                //   const imageURL = baseModelRef.getDownloadURL();
                //   return imageURL;
                // }).catch(console.error);

            }))
    }

    return (<ConfirmationDialog
        open={isUploadOpen}
        onDeny={setClose}
        onConfirm={setClose}
        title={""}
        dialogTitle={"Upload 3d model files"}
        yesLabel={"Done"}
        noLabel={"Cancel"}
        isYesButtonCTA={false}
        children={

            [loading && <Loader />,
            < Box component='div'
                display='flex'
                // flexWrap='wrap'
                justifyContent="flex-start"
                flexDirection='column'
                alignContent='flex-start'
                mb={5}
            >

                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                    <InputLabel className={classes.fieldLabel}>Name</InputLabel>
                    <TextField
                        style={{
                            color: 'black'
                            // width: "30%",
                            // paddingRight: "1%"
                        }}
                        className={classes.textField}
                        name='name'
                        placeholder="Name"
                        value={name}
                        onChange={event => {
                            setName(event.target.value);
                        }}
                    />
{/* <<<<<<< HEAD
										{showNameError && <span className={classes.errorMessage}>*The file name already exists. Please re-enter</span>}
=======
>>>>>>> parent of e0d0d17c (fixing duplicating issue on add model) */}
                </Box>

                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                    <InputLabel className={classes.fieldLabel}>File Type</InputLabel>
                    <ToggleButtons size={"small"} children={["obj", "dae", "gltf"]} initialValue={type}
                        handleChange={setType} />
                </Box>

                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                    <h5 className={classes.fieldLabel}>Main folder files</h5>
                    <FileUploader
                        // acceptedFileTypes='.obj, .mtl'
                        handleFiles={setMainFolderFiles} title={`Selected type: .${type} will be the main file`} />
                </Box>
                <Divider />

                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                    <h5 className={classes.fieldLabel} >Additional files - from a sub-folder</h5>
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                        <InputLabel className={classes.fieldLabel}>Sub-folder name (ensure correct spelling and case)</InputLabel>
                        <TextField
                            style={{
                                color: 'black', fontSize: '12px'

                            }}
                            className={classes.textField}
                            name='subfolder-name'
                            value={subfolderName}
                            placeholder={"Sub-folder Name"}
                            onChange={event => {
                                setSubfolderName(event.target.value);
                            }}
                        />
                    </Box>

                    <FileUploader
                        // acceptedFileTypes='.obj, .mtl'
                        handleFiles={setSubFolderFiles} />
                </Box>


                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                    <h5 className={classes.fieldLabel} >Thumbnail image</h5>

                    <FileUploader
                        acceptedFileTypes='image/*'
                        handleFiles={setThumbnailFile}
                        allowOnlyOne={true} />
                </Box>
                {/* <FileUploader handleFiles={(files2: any[]) => setState(prevState => ({ ...prevState, "subFolderFiles": files2 }))} /> */}

                <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    style={{ height: '90%', alignSelf: "center", marginTop: '10px' }}
                    type='button'
                    onClick={async () => {
// <<<<<<< HEAD
// 											for (let i = 0; i < catalogItems.length; i ++) {
// 												if(catalogItems[i].name?.toLowerCase() === name?.toLowerCase()){
// 													dispatch(fetchError("*The file name already exists. Please re-enter"));
// 													setShowNameError(true)
// 													return;
// 												}
// 											}
// =======
// >>>>>>> parent of e0d0d17c (fixing duplicating issue on add model)
                        if (mainFolderFiles.length == 0) {
                            dispatch(fetchError("Please add files for the main folder!"));
                            return;
                        }
                        if (!subfolderName && subFolderFiles.length > 0) {
                            dispatch(fetchError("Please specify the sub-folder name!"));
                            return;
                        }
                        setLoading(true);
                        try {
                            await uploadMainFolderFiles();
                            // await uploadSubfolderFiles();
                            // await uploadThumbnailFile();
                            setLoading(false);

                        } catch (error) {
                            dispatch(fetchError("Something went wrong uploading your files. Please try again!"));
                            setLoading(false);
                        }

                    }}>
                    Upload
                </Button>



            </Box>
            ]
        }

    />)
}