import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TagSidebar from './TagSidebar';
import CatalogSidebar from './CatalogSidebar';
import PeopleSidebar from './PeopleSidebar';
import ThreeDSidebar from './ThreeDSidebar';
import LessonSidebar from './LessonSidebar';
import DataSidebar from './DataSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'redux/store';
import { fade, useMediaQuery, useTheme, Badge } from '@material-ui/core';
import TurnedInRoundedIcon from '@material-ui/icons/TurnedInRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import LayersRoundedIcon from '@material-ui/icons/LayersRounded';
import WeekendRoundedIcon from '@material-ui/icons/WeekendRounded';
import FunctionsIcon from '@material-ui/icons/Functions';
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import DownloadingIcon from '@mui/icons-material/Downloading';
// import { ThreeDIcon } from '@crema/core/Icons/CustomIcons';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { CremaTheme } from 'types/AppContextPropsType';
import { SIDEBAR_INDEX, SpaceRole } from 'types/models/home/HomeApp';
import Variables from './Variables/Variables';
import { canEdit } from '@crema/utility/Utils';
import { SET_CURRENT_SIDEBAR_INDEX } from 'types/actions/Home.action';
import clsx from 'clsx';
import { UseCase } from 'shared/constants/AppConst';
import { useAuthUser, useUserAdmin, useUserInExperiment } from '@crema/utility/AppHooks';
import { AuthUser } from 'types/models/AuthUser';
import TaskSidebar from './TaskSidebar';
import { useHistory } from 'react-router-dom';
import { ProjectObj } from 'types/models/apps/ProjectBoard';
import { onStopLesson } from 'redux/actions/Lesson';
import { store } from 'App';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { ReactComponent as ThreeDIcon2Svg } from 'assets/images/3dIcons/ThreeDIcon2.svg';
import { Disable } from 'react-disable';
import Disabled from '@crema/core/Disabled';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  matches: boolean;
}


const useStyles = makeStyles((theme: CremaTheme) =>
  createStyles({
    tabRoot: {
      '& .MuiTab-root': {
        // backgroundColor: '#1890ff',
        '& .MuiButtonBase-root': {
        },
        width: '0.7em',
        fontSize: '0.85em',
        textTransform: 'none',
        color: 'black',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400
      },

      '& .MuiTab-labelIcon': {
        color: 'black'
      },

      '& .MuiTab-textColorInherit': {
        textColor: 'black'
      }
      ,

      '& .MuiTab-textColorPrimary': {
        textColor: 'black'
      }
      ,

      '& .MuiTab-textColorSecondary': {
        textColor: 'black'
      },
      '& svg': {
        color: theme.palette.secondary.main
      },
      '& .MuiTabs-indicator': {
        display: 'none'
      }
    },
    minimizedContainer: {
      // display: 'flex',
      // flexDirection: 'row',
      minWidth: '0px !important',
      maxWidth: '0px !important',
    },
    // regularContainer: {
    //   display: 'flex', flexDirection: 'row',
    // }
    tabMenuItem: {
      height: 70,
      borderRadius: '8px !important',
      '&.Mui-selected': {
        color: 'black !important',
        backgroundColor: fade(theme.palette.primary.main, 0.2),
      },
      // '&.Mui-selected svg': {
      //   color: theme.palette.secondary.main
      // },
    }
  }));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, matches, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={matches ? value == 2 ? { maxHeight: "500px" } : { maxHeight: "250px" } : {}}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ height: "100%" }}>
          {/* // <Box sx={{ p: 3 }}> */}
          {/* <Typography> */}
          {children}
          {/* </Typography> */}
          {/* // </Box> */}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface Props {
  // tagsAlreadyImported: boolean;
  // importMatterportTags(): void;
  // addObject(objectName: string): void;
  setMinimized: (isMinimized: boolean) => void;
  isMinimized: boolean;
}

export default function SidebarTabs({
  // tagsAlreadyImported,
  // importMatterportTags,
  // addObject,
  setMinimized,
  isMinimized,
}: Props) {
  const history = useHistory();
  // const [selectedTabIndex, setSelectedTabIndex] = React.useState<any>(false);
  const [tagsSubTab, setTagsSubTab] = React.useState('Tags');
  // const { currentSpace, primaryUseCase, currentSidebarIndex } = useSelector<AppState, AppState['home']>(
  //   ({ home }) => home,
  // );
  // const [variables, showVariables] = React.useState<boolean>(false);
  const spaceRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home?.currentSpace?.spaceMembership?.role);
  const primaryUseCase = useSelector<AppState, UseCase | null>(({ home }) => home.primaryUseCase);
  const currentSidebarIndex = useSelector<AppState, SIDEBAR_INDEX>(({ home }) => home.currentSidebarIndex);
  const currentSpaceDid = useSelector<AppState, string>(
    ({ home }) => home.currentSpace?.did || ''
  );
  const projectDetail = useSelector<AppState, ProjectObj | null>(
    ({ projects }) => projects.projectDetail
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const isUserAdmin = useUserAdmin();
  const isUserInExperiment = useUserInExperiment;

  const handleSidebarTabChange = (event: React.SyntheticEvent, newValue: number) => {
    // setSelectedTabIndex(newValue);
    dispatch({ type: 'SET_CURRENT_SIDEBAR_INDEX', payload: newValue });
    if (newValue == SIDEBAR_INDEX.TASKS) {
      history.push(`/home/space/${currentSpaceDid}/project/${projectDetail?.did}/tasks`);
    } else {
      history.push(`/home/space/${currentSpaceDid}/project/${projectDetail?.did}`);
    }
  };

  const presentationMode = useSelector<AppState, boolean>(({ home }) => home.presentationMode);
  if (currentSidebarIndex !== SIDEBAR_INDEX.LAYERS) {
    !!store.getState().home.currentLesson && dispatch(onStopLesson());
  }

  const classes = useStyles();

  return (console.log(`[st] rendering SidebarTabs can edit value for ${currentSpaceDid} is ${canEdit()}`),
    <Box
      id="app-sidebar"
      flexDirection={{ xs: 'column', sm: 'row' }}
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        //   height: 224
      }}
    >
      {spaceRole && canEdit(spaceRole) && currentSidebarIndex.valueOf() == SIDEBAR_INDEX.VARIABLES
        && <Variables
          isVariablesOpen={currentSidebarIndex == SIDEBAR_INDEX.VARIABLES}
          onCloseVariables={() => dispatch({ type: SET_CURRENT_SIDEBAR_INDEX, payload: SIDEBAR_INDEX.NONE })}
        />}

      {/* <div style={{backgroundColor: 'blue', minWidth: '250px', maxWidth: '250px', display: 'block'}}>iframe</div> */}

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.TAGS} matches={matches}>
        {/* <div className={'sidebar-container'}> */}
        <div style={{ backgroundColor: "#f4f7fe", height: "100%" }}>
          <TagSidebar />
        </div>

      </TabPanel>

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.THREED} matches={matches}>
        <div className={'sidebar-container'}
        // style={{ backgroundColor: "#f4f7fe", height: "100%" }}
        >
          {React.useMemo(() =>
            <ThreeDSidebar />
            , [])}
        </div>
      </TabPanel>

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.LAYERS} matches={matches}>
        {/* <div className={'sidebar-container'}> */}
        <div style={{ backgroundColor: "#f4f7fe", height: "100%" }}>
          {<LessonSidebar setMinimized={setMinimized} isMinimized={isMinimized} />}
        </div>
      </TabPanel>
      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.PEOPLE} matches={matches}>
        {/* <div className={'sidebar-container'}> */}
        <div style={{ backgroundColor: "#f4f7fe", height: "100%" }}>
          <PeopleSidebar />
        </div>

      </TabPanel>
      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.CATALOG} matches={matches}>
        {/* <div className={'sidebar-container'}> */}
        <div style={{ backgroundColor: "#f4f7fe", height: "100%" }}>
          {React.useMemo(() =>
            // React.lazy
            (<CatalogSidebar />),
            [])}
        </div>
      </TabPanel>

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.TASKS} matches={matches}>
        {/* <div className={'sidebar-container-broad'}> */}
        <div style={{ backgroundColor: "#f4f7fe", height: "100%" }}>
          <TaskSidebar />
        </div>

      </TabPanel>

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.DATA} matches={matches}>
        {/* <div className={'sidebar-container'}> */}
        <div style={{ backgroundColor: "#f4f7fe", height: "100%" }}>
          <DataSidebar />
        </div>

      </TabPanel>

      <Tabs
        orientation={matches ? "horizontal" : "vertical"}
        variant={matches ? "scrollable" : "standard"}
        value={(currentSidebarIndex == SIDEBAR_INDEX.NONE) ? false : currentSidebarIndex.valueOf()}
        centered={true}
        onChange={handleSidebarTabChange}
        aria-label="Sidebar tabs"
        className={clsx(classes.tabRoot, presentationMode ? classes.minimizedContainer : '')}
        style={{ position: 'relative', right: '0px' }}

      // sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab className={clsx('item-hover', classes.tabMenuItem)} icon={<TurnedInRoundedIcon />} label="Tags" {...a11yProps(SIDEBAR_INDEX.TAGS)} />
        <Tab className={clsx('item-hover', classes.tabMenuItem)} icon={<ThreeDIcon2Svg style={{ fill: theme.palette.secondary.main, height: 23 }} />} label="3D" {...a11yProps(SIDEBAR_INDEX.THREED)} />
        <Tab className={clsx('item-hover', classes.tabMenuItem)} icon={<LayersRoundedIcon />} label={primaryUseCase?.layerTermPlural || 'Experiences'} {...a11yProps(SIDEBAR_INDEX.LAYERS)} />
        <Tab className={clsx('item-hover', classes.tabMenuItem)} icon={<PlaylistAddCheckRoundedIcon />} label="Tasks" {...a11yProps(SIDEBAR_INDEX.TASKS)} />
        <Tab className={clsx('item-hover', classes.tabMenuItem)} icon={<WeekendRoundedIcon />} label="Catalog" {...a11yProps(SIDEBAR_INDEX.CATALOG)} />

        <Tab disabled={!canEdit()} className={clsx('item-hover', classes.tabMenuItem)} icon={<PeopleAltRoundedIcon />} label="People" {...a11yProps(SIDEBAR_INDEX.PEOPLE)} />

        <Tab disabled={!canEdit()} className={clsx('item-hover', classes.tabMenuItem)} icon={<FunctionsIcon />} label="Variables" {...a11yProps(SIDEBAR_INDEX.VARIABLES)} />

        {isUserAdmin &&
          <Tab className={clsx('item-hover', classes.tabMenuItem)} icon={<DownloadingIcon />} label="Data" {...a11yProps(SIDEBAR_INDEX.DATA)} />}
        {(isUserAdmin || isUserInExperiment('EXPERIMENT-AR')) &&
          <Tab className={clsx('item-hover', classes.tabMenuItem)} icon={<ViewInArIcon />} label="View in AR" {...a11yProps(SIDEBAR_INDEX.AR)} />}
        {isUserAdmin &&
          <Tab className={clsx('item-hover', classes.tabMenuItem)} icon={<ViewInArIcon />} label="Blank Scene" {...a11yProps(SIDEBAR_INDEX.BLANK_SCENE)} />}
      </Tabs>
    </Box>
  );
}
