import {
  SpaceData,
  FilterDataSpaces,
  ILesson,
  ISceneData,
  ShowcaseTag,
  TagGroup,
  PeopleObj,
  SIDEBAR_INDEX,
} from '../../types/models/home/HomeApp';
import {
  ADD_SPACES_HIDE_MODAL,
  GET_SPACES_LIST,
  SET_SPACE_LIST,
  SET_FILTER_DATA_SPACES,
  SET_SPACE_DATA,
  GET_SPACE_PROJECTS,
  GET_LESSONS_LIST,
  GET_SCENES_LIST,
  SET_LESSON_DATA,
  SET_SCENE_DATA,
  GET_SHARED_SPACES_LIST,
  SET_SPACE_TAGS_LIST,
  SET_SPACE_MODELS_LIST,
  SET_MP_SDK,
  SET_TAGS_VIEW_MODE,
  SET_TAG_GROUP_ID,
  SET_TAG_GROUP_LIST,
  SET_SPACE_CONTROLS,
  SET_BANNER_TEXT,
  SET_VARIABLE_VALUE,
  GET_PEOPLE_LIST_DATA,
  SET_CURRENT_SHOWCASE_TAGS,
  SET_SELECTED_TAG,
  SET_CATALOG_ITEMS,
	// SET_CLOSE_ALL_POPUPS,
  SET_EDIT_SHOWCASE_TAG_ID,
  SET_OPEN_TAG_FORM,
	// SET_TASK_FOR_EDIT,
  SET_TAGS_VIEW_MODE_SECONDARY,
  SET_BROAD_SIDEBAR_OPEN,
  OPEN_RADIAL_MENU,
  SET_CURRENT_SIDEBAR_INDEX,
  SET_IS_ADDING_TAG,
  SET_TAG_SID_BEING_ADDED,
  SET_CURRENT_CUSTOM_DATA,
  SET_PRESENTATION_MODE
} from '../../types/actions/Home.action';
import {AppActions} from '../../types';
import {NotificationModel} from 'types/models/NotificationModel';
import {
  GET_NOTIFICATIONS,
  ADD_NOTIFICATION,
} from 'types/actions/Notification.actions';

import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { UseCase } from 'shared/constants/AppConst';
import { ProjectObj } from 'types/models/apps/ProjectBoard';
import _ from 'lodash';

export enum VIEW_TYPE {
  GRID = 2,
}

const initialState: {
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////   Don't put here if a field is not specific to a space /////////////////////////////////////
  // mpSdk: any;
  spacesList: SpaceData[];
  sharedSpacesList: SpaceData[];
  currentSpace: SpaceData | undefined;
  filterDataSpaces: FilterDataSpaces;
  lessonsList: ILesson[];
  scenesList: ISceneData[];
  currentLesson: ILesson | null;
  currentScene: ISceneData;
  currentCustomData: any,
  spaceTags: Map<string, ShowcaseTag>;
  spaceModels: Map<string, any>;
  currentShowcaseTags: ShowcaseTag[];
  tagsViewMode: string;
  tagsViewModeSecondary: string;
  isWorkflowsSidebarOpen: boolean;
  tagGroupsList: TagGroup[];
  currentTagGroupId: string | undefined;
  controlVarsMap: any;
  notifications: NotificationModel[];
  notifCount: number;
  bannerText: string;
  peopleList: PeopleObj[];
  selectedTagId: string;
  catalogItems: any[];
  variableValues: any[];
  primaryUseCase:UseCase|null;
  secondaryUseCase:UseCase[];
  primaryUseCaseOther:string;
  secondaryUseCaseOther:string;
	// closeAllPopUps: boolean | string;
	editShowcaseTagId: string; //Set when you click a tag: For editing, adding voice comment, scrolling into view
  tagSidBeingAdded: any;
	openTagForm: boolean;
  openRadialMenu: boolean;
  currentSidebarIndex: SIDEBAR_INDEX;
  isAddingTag: boolean;
  presentationMode: boolean;
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
} = {
  // mpSdk: null,
  spacesList: [],
  sharedSpacesList: [],
  currentSpace: undefined,
  filterDataSpaces: {
    title: '',
    rating: [],
  },
  lessonsList: [],
  scenesList: [],
  currentLesson: null,
  currentScene: {},
  currentCustomData: {},
  spaceTags: new Map(),
  spaceModels: new Map(),
  currentShowcaseTags: [],
  tagsViewMode: '',
  tagsViewModeSecondary: '',
  isWorkflowsSidebarOpen: false,
  tagGroupsList: [],
  currentTagGroupId: undefined,
  controlVarsMap: {},
  notifications: [],
  notifCount: 0,
  bannerText: '',
  peopleList: [],
  selectedTagId: '', //Set when you hover over a tag
  catalogItems: [],
  variableValues: [],
  primaryUseCase:null,
  secondaryUseCase:[],
  primaryUseCaseOther:'',
  secondaryUseCaseOther:'',
	// closeAllPopUps: '',
	editShowcaseTagId: '',
  tagSidBeingAdded: null,
	openTagForm: false,
  openRadialMenu: false,
  currentSidebarIndex: SIDEBAR_INDEX.NONE,
  isAddingTag: false,
  presentationMode: false
};

const Home = (state = initialState, action: AppActions) => {
  switch (action.type) {
    // case SET_MP_SDK:
    //   return {
    //     ...state,
    //     mpSdk: action.payload,
    //   };
    case GET_SPACES_LIST:

      return {
        ...state,
        //TODO put these in a separate User/ Auth store,
        notifications: state.notifications,
        notifCount: state.notifCount,

        //TODO put these in a separate global application store,
        catalogItems: state.catalogItems,

        spacesList: action.payload,
      };
    case SET_FILTER_DATA_SPACES:
      return {
        ...state,
        filterData: action.payload,
      };

    case SET_SPACE_DATA:

      let spaceProperties = action.payload ? {...state} : {...initialState}

      return {
        ...spaceProperties,
        //TODO put these in a separate User/ Auth store,
        notifications: state.notifications,
        spacesList: state.spacesList,
        notifCount: state.notifCount,


        //TODO put these in a separate global application store,
        catalogItems: state.catalogItems,
        currentSpace: action.payload,

        //dependant on space
        variableValues: action.payload?.variables ? action.payload.variables : [],

        primaryUseCase: action.payload?.primaryUseCase ? action.payload.primaryUseCase : null,
        secondaryUseCase: action.payload?.secondaryUseCase ? action.payload.secondaryUseCase : [],
        primaryUseCaseOther: action.payload?.primaryUseCaseOther ? action.payload.primaryUseCaseOther : '',
        secondaryUseCaseOther: action.payload?.secondaryUseCaseOther ? action.payload.secondaryUseCaseOther : '',
      };

    case SET_SPACE_LIST:
      return {
        ...state,
        spacesList: state.spacesList,
      };

    case ADD_SPACES_HIDE_MODAL:
      return {
        ...state,
        hide_model: action.payload,
      };

    case GET_SHARED_SPACES_LIST:
      return {
        ...state,
        sharedSpacesList: action.payload,
      };

    case SET_SPACE_TAGS_LIST:
      return {
        ...state,
        spaceTags: action.payload,
      };

    case SET_SPACE_MODELS_LIST:
      return {
        ...state,
        spaceModels: action.payload,
      };

    case GET_LESSONS_LIST:

      if (state.currentLesson){
        let l = action.payload.find(lesson => lesson.id == state.currentLesson?.id) || null;
        return {
          ...state,
          lessonsList: action.payload,
          currentLesson: l
        };
      }
      return {
        ...state,
        lessonsList: action.payload,

      };
    // case GET_SCENES_LIST:
    //   return {
    //     ...state,
    //     scenesList: action.payload,
    //   };
    case SET_LESSON_DATA:
      // if (!action.payload){
      //   return {
      //     ...state,
      //     variableValues: initialState.variableValues,
      //     currentTagGroupId: initialState.currentTagGroupId,
      //     currentLesson: initialState.currentLesson,
      //   };
      // } else {

        let varValues = state.variableValues.map(v => (v.value = '', v));
        return {
          ...state,
          currentLesson: action.payload,
          variableValues: varValues,
        };
      // }

    // case SET_SCENE_DATA:
    //   return {
    //     ...state,
    //     currentScene: action.payload,
    //   };
    case SET_TAGS_VIEW_MODE:
      return {
        ...state,
        tagsViewMode: action.payload,
      };
    case SET_TAGS_VIEW_MODE_SECONDARY:
      return {
        ...state,
        tagsViewModeSecondary: action.payload,
      };
    case SET_BROAD_SIDEBAR_OPEN:
      return {
        ...state,
        isWorkflowsSidebarOpen: action.payload,
      };
    case SET_TAG_GROUP_LIST:
      return {
        ...state,
        tagGroupsList: action.payload,
      };

    case SET_TAG_GROUP_ID:
      return {
        ...state,
        currentTagGroupId: action.payload,
      };

    case SET_SPACE_CONTROLS:
      return {
        ...state,
        controlVarsMap: action.payload,
      };

    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        notifCount: action.payload.notifCount,
      };

    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications,
          // action.payload
        ],
      };
    case SET_BANNER_TEXT:
      return {
        ...state,
        bannerText: action.payload,
      };

    case GET_PEOPLE_LIST_DATA:
      return {
        ...state,
        peopleList: action.payload,
      };

    case SET_CURRENT_SHOWCASE_TAGS:
      return {
        ...state,
        currentShowcaseTags: action.payload,
      };

    case SET_CATALOG_ITEMS:
      return {
        ...state,
        catalogItems: action.payload,
      };

    case SET_VARIABLE_VALUE: {
      let varValues = _.cloneDeep(state.variableValues);
      let v = varValues.find(v => v.name.toLowerCase() === action.payload.name.toLowerCase());
      if (v){
        v.value = action.payload.value;
      } else {
        varValues.push(action.payload);
      }

      Simulation.instance.updateVariablesState(varValues, state.currentLesson?.id, state.currentTagGroupId, state.currentSpace?.id);
      return {
        ...state,
        variableValues: varValues,
      };}

			// case SET_CLOSE_ALL_POPUPS:
			// 	return {
			// 		...state,
			// 		closeAllPopUps: !state.closeAllPopUps,
			// 	};

			case SET_EDIT_SHOWCASE_TAG_ID:
				return {
					...state,
					editShowcaseTagId: action.payload,
        };

			case SET_OPEN_TAG_FORM:
				return {
					...state,
					openTagForm: action.payload,
      };

    case GET_SPACE_PROJECTS:
      return {
        ...state,
        spaceProjectList: action.payload,
      };
    // case GET_RECENT_ORDER:
    //   return {
    //     ...state,
    //     recentOrders: action.payload.orders,
    //     orderCount: action.payload.orderCount,
    //   };

    // // case SET_CART_ITEMS:
    // //   return {
    // //     ...state,
    // //     cartItems: action.payload,
    // //   };

    // case UPDATE_CART_ITEM:
    //   return {
    //     ...state,
    //     cartItems: state.cartItems.map(item =>
    //       item.id === action.payload.id ? action.payload : item,
    //     ),
    //   };

    // case ADD_CART_ITEM:
    //   let cartItems: CartItems[] = [];
    //   if (state.cartItems.some(item => +item.id === +action.payload.id)) {
    //     cartItems = state.cartItems.map(item => {
    //       if (+item.id === +action.payload.id) {
    //         item.count = +item.count + 1;
    //       }
    //       return item;
    //     });
    //     return {
    //       ...state,
    //       cartItems: cartItems,
    //     };
    //   } else {
    //     const cartData: CartItems = {
    //       id: action.payload.id,
    //       title: action.payload.title,
    //       mrp: action.payload.mrp,
    //       discount: action.payload.discount,
    //       brand: action.payload.brand,
    //       image: action.payload.image[0].src,
    //       count: 1,
    //     };
    //     cartItems = state.cartItems.concat(cartData);
    //     return {
    //       ...state,
    //       cartItems: cartItems,
    //     };
    //   }

    // case REMOVE_CART_ITEM:
    //   return {
    //     ...state,
    //     cartItems: state.cartItems.filter(
    //       item => item.id !== action.payload.id,
    //     ),
    //   };

    // case GET_CUSTOMERS:
    //   return {
    //     ...state,
    //     customers: action.payload.customers,
    //     customerCount: action.payload.customerCount,
    //   };
    case SET_SELECTED_TAG:
      return {
        ...state,
        selectedTagId: action.payload,
      };
    case OPEN_RADIAL_MENU:
      return {
        ...state,
        // openRadialMenu: action.payload,
      };
    case SET_CURRENT_SIDEBAR_INDEX:
    return {
      ...state,
      currentSidebarIndex: action.payload,
    };

    case SET_IS_ADDING_TAG:
    return {
      ...state,
      isAddingTag: action.payload,
    };
    case SET_TAG_SID_BEING_ADDED:
    return {
      ...state,
      tagSidBeingAdded: action.payload,
    };
    case SET_CURRENT_CUSTOM_DATA:
      return {
        ...state,
        currentCustomData: action.payload
      }
    case SET_PRESENTATION_MODE:
      Simulation.instance.updateFireWaterObjects(!action.payload);
      return {
        ...state,
        presentationMode: action.payload
      }
    default:
      return state;
  }
};
export default Home;
