import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import { firestore } from '@crema/services/auth/firebase/firebase';
import Api from '@crema/services/ApiConfig';
import { SpaceData, SpaceRole, ACTIONS, OBJECT_TYPES, IActivityLog } from 'types/models/home/HomeApp';
import { SET_SPACES } from 'types/actions/Dashboard.action';
import { DTagGroup, DLesson, DPerson } from 'types/models/Dashboard';

const getLessonsBySpaceID = async (spaceId: string) => {
    let lessonsDocs = await firestore.doc(`Spaces/${spaceId}`).collection('lessons').get();
    return await lessonsDocs.docs.map(async (doc) => {
        let tagGroups: DTagGroup[] = await getTagGroupsByLessonID(spaceId, doc.id);
        return ({ name: doc.data().name, id: doc.id, tagGroups: tagGroups, userProgress: [] });
    });
};

const getTagGroupsByLessonID = async (spaceId: string, lessonId: string) => {
    let tagGroupsDocs = await firestore.collection(`Spaces/${spaceId}/lessons/${lessonId}/tagGroups`).get();
    let tagGroups: DTagGroup[] = tagGroupsDocs.docs.map((doc) => ({ sortIndex: doc.data().sortIndex, id: doc.id }));
    tagGroups.sort((a, b) => a.sortIndex - b.sortIndex);
    return tagGroups;
};

const getPeopleList = async (spaceId: string) => {
    let qs = await firestore
        .collection('SpaceMemberships')
        .where('spaceId', '==', spaceId)
        .get();
    let memberships = qs.docs.map((doc) => doc.data());
    let users: any = null;
    let members = memberships.map(membership => membership.userId);
    while (members.length) {
        const onlyTenMembersID = members.splice(0, 10);
        let usersDocs = await firestore
            .collection('Users')
            .where('id', 'in', [...onlyTenMembersID])
            .get();
        let onlyTenMembers = usersDocs.docs.reduce((obj: any, doc) => (obj[doc.id] = doc.data(), obj), {});
        users = {...users, ...onlyTenMembers};
    }
    return await memberships.map(async mem => {
        return ({
            id: mem.userId,
            role: mem.role,
            email: users[mem.userId].email,
            name: users[mem.userId].firstName + ' ' + users[mem.userId].lastName,
            activities: await getUserActivity(mem.userId)
        });
    });
};

const getUserActivity = async (userId: string) => {
    let userActivitiesDoc = await firestore.doc(`Users/${userId}`).collection(`ActivityLog`).get();
    let userActivities: IActivityLog[] = userActivitiesDoc.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as IActivityLog[];
    return userActivities;
}

export const getSpaceList = () => {
    return async (dispatch: Dispatch<AppActions>) => {
        let spaceData = await Api.get('/api/spaces/list');
        let spaceList = [];
        if (spaceData.status == 200)
            spaceList = spaceData.data;

        Promise.all(spaceList.filter((s: SpaceData) => s.spaceMembership.role !== SpaceRole.VIEWER).map(async (space: SpaceData) => {
            let lessons: DLesson[] = await Promise.all(await getLessonsBySpaceID(space?.id));
            let people: DPerson[] = await Promise.all(await getPeopleList(space?.id));
            lessons = lessons.map((lesson: DLesson) => {
                let userProgress = people.map((person: DPerson) => {
                    return lesson.tagGroups.filter((tagGroup: DTagGroup) => {
                        if (person.activities.some(act => act.action == ACTIONS.CLICK && act.object == OBJECT_TYPES.TAGGROUP && act.objectId == tagGroup.id))
                            return true;
                        return false;
                    }).length;
                });

                return ({ ...lesson, userProgress });
            });
            return ({ id: space.id, name: space.name, lessons: lessons, people: people });
        }))
            .then((data: any[]) => {
                dispatch({ type: SET_SPACES, payload: data });
            })
            .catch(error => {
                dispatch({ type: SET_SPACES, payload: [] });
                throw error;
            });
    };
}