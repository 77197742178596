import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import { CremaTheme } from 'types/AppContextPropsType';
import { grey } from '@material-ui/core/colors';

const options = ['Edit', 'Delete', 'Create Task'];

const ITEM_HEIGHT = 48;
interface OverflowMenuProps {
  // onClickTagsPopup: (option: string, mattertag: ShowcaseTag) => void;
  // mattertag: ShowcaseTag;
  menuItems: any[];
  buttonStyle?: any;
}

const useStyles = makeStyles((theme: CremaTheme) => ({

  iconButton: {
    padding: '1px',
    // color: theme.palette.secondary.main,
    color: grey[500],
    // '&:hover, &:focus': {
    //   color: theme.palette.secondary,
    // },
    '& .MuiIconButton-root': {
      // padding: '1px',
      // '&:hover, &:focus': {
      //   color: theme.palette.primary
      // }
    },
  },

  menuItem: {
    '& .MuiMenuItem-root': {
      fontSize: '13px'
    }
  }
}))

export const OverflowMenu: React.FC<OverflowMenuProps> = ({
  // onClickTagsPopup,
  // mattertag,
  menuItems,
  buttonStyle
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {/* <ItemHover> */}
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          disableFocusRipple={true}
          className={classes.iconButton}
          style={buttonStyle}
          onClick={handleClick}>
          <MoreVertIcon/>
        </IconButton>
      {/* </ItemHover> */}
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        className={classes.menuItem}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}>
        {/* {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === 'Pyxis'}
            onClick={() => {
              handleClose();
              onClickTagsPopup(option, mattertag);
            }}>
            {option}
          </MenuItem>
        ))} */}
        {menuItems.map((item, index) =>
          <MenuItem
            // style={{fontSize: '13px'}}
            disabled={item.isDisabled}
            key={index}
            onClick={() => {
              item.onClick && item.onClick();
              setTimeout(() => handleClose(), 1000);


            }}>
            {item.component || item.title}
          </MenuItem>)}
        {/* <MenuItem
          key={'Delete'}
          onClick={() => {
            handleClose();
            item.onClick();
          }}>
          {'Delete'}
        </MenuItem> */}
        {/* <MenuItem
          key={'Create Task'}
          onClick={() => {
            handleClose();
          }}>
          <CreateTaskModal tagSid={mattertag.id} />
        </MenuItem> */}

        {/* <MenuItem
          key={'Create Task'}
          onClick={() => {
            handleClose();
          }}>
          <Link
            target='_blank'
            style={{textDecoration: 'none', color: '#495057'}}
            to={`/apps/projects/1622995601143/true/${mattertag.id}`}>
            Create task
          </Link>
        </MenuItem> */}
      </Menu>
    </div>
  );
};
