import * as React from 'react';
import { ListSubheader, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  selectButton: {
    '& .MuiButton-endIcon': {
      margin: 0
    },
    textTransform: 'none'
  },
  selectMenuItem: {
    display: 'flex',
    fontSize: '13px',
    color: 'black',
    fontWeight: 500,
  },
}));

interface Props {
  id?: string,
  defaultOption?: string,
  size: any,
  style?: any,
  buttonClickAction?: any,
  options: Array<any>
}

export default function DropDownButton({ id, defaultOption, size, options, buttonClickAction, style }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [btnText, setButtonText] = React.useState<null | string>('');
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  React.useEffect(() => {
    if (defaultOption) {
      if (!options.length) {
        setButtonText(defaultOption);
        return;
      }
      const defOption = options.find((option) => option.id == defaultOption);
      if (defOption)
        setButtonText(defOption.name);

    } else {
      if (options.length)
        setButtonText(options[0].name);
    }
  }, [defaultOption]);

  function getOptions(options: Array<any>) {

    let groupArray = _.groupBy(options, o => o.category);

    if (Object.keys(groupArray).length > 0) {
      return Object.keys(groupArray).map(key => {

        let el: any[] = [];
        // el.push(<optgroup label={key}></optgroup>);
        key && (key !== "undefined") && el.push(<ListSubheader style={{ fontSize: '13px', fontStyle:'italic' }}>{key}---</ListSubheader>);
        let optionsArray: any[] = groupArray[key];
        optionsArray.length && optionsArray.map((option: any) => {
          el.push(
            <MenuItem
              key={option?.id}
              className={classes.selectMenuItem}
              onClick={() => {
                setButtonText(option?.name);
                handleClose();
                buttonClickAction(option?.id);
              }}
            >
              {option?.name}
            </MenuItem>)
        });
        return el;
      });
    } else {
      return options.length && options.map((option) =>
        <MenuItem
          key={option?.id}
          className={classes.selectMenuItem}
          onClick={() => {
            setButtonText(option?.name);
            handleClose();
            buttonClickAction(option?.id);
          }}
        >
          {option?.name}
        </MenuItem>
      )
    }


  }

  return (
    <>
      <Button
        id={id}
        className={classes.selectButton}
        size={size}
        style={{ ...style, padding: '0 5px' }}
        variant="outlined"
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {btnText}
      </Button>
      {options.length != 0 && (
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {/* <ListSubheader>Category 1</ListSubheader>
                    <optgroup label="Category 2"></optgroup> */}

          {getOptions(options)}
          {/* {options.length && options.map((option) =>
            <MenuItem
              key={option?.id}
              className={classes.selectMenuItem}
              onClick={() => {
                setButtonText(option?.name);
                handleClose();
                buttonClickAction(option?.id);
              }}
            >
              {option?.name}
            </MenuItem>
          )} */}
        </Menu>
      )}
    </>
  );
}