import {customObjType, makeCustomObjLoader} from './core/craEngine/components/CustomModelLoaders/CustomObjLoader';
import {customDAEType, makeCustomDAELoader} from './core/craEngine/components/CustomModelLoaders/CustomDAELoader';
import {lightType, makeLight} from './Light';
import {videoRendererType, makeVideoRenderer} from './core/craEngine/components/video/VideoRenderer';
import { makeToggleState, toggleStateType } from './core/craEngine/components/tiny/ToggleState';
import { makeOnOffButton, onOffButtonType } from './core/craEngine/components/meshComponents/OnOffButton';
import { makePaintSpray, paintSprayType } from './core/craEngine/components/meshComponents/PaintSpray';
import { eulerIntegratorType, makeEulerIntegrator } from './core/craEngine/components/systemComponents/EulerIntegrator';
import { canvasBorderType, makeCanvasBorder } from './core/craEngine/components/CanvasComponents/CanvasBorder';
import { canvasRendererType, makeCanvasRenderer } from './core/craEngine/components/CanvasComponents/CanvasRenderer';
import { canvasTextType, makeCanvasText } from './core/craEngine/components/CanvasComponents/CanvasText';
import { nestThermostatType, makeNestThermostat } from './core/craEngine/components/meshComponents/NestThermostat';
import { planeRendererType, makePlaneRenderer } from './core/craEngine/components/meshComponents/basic/PlaneRenderer';
import { genericInputType, makeGenericInput } from './core/craEngine/components/systemComponents/GenericInput';
import { videoStreamCaptureType, makeVideoStreamCapture } from './core/craEngine/components/video/VideoStreamCapture';
import { orientedBoxType as highlightBoxType, makeOrientedBox as makeHighlightBox } from './core/craEngine/components/meshComponents/HighlightBox';
import { makeOutlinePostProcess, outlinePostProcessType } from './core/craEngine/components/PostProcess/OutlineComponent';
import { makeRotateToggle, rotateToggleType } from './core/craEngine/components/tiny/RotateToggle';
import { loadingIndicatorType, makeLoadingIndicator } from './core/craEngine/components/tiny/LoadingIndicator';
import { makeToggleComponent, toggleComponentType } from './core/craEngine/components/tiny/ToggleComponent';
import {makePlateRenderer, plateRendererType} from './core/craEngine/components/meshComponents/basic/PlateRenderer';
import {
  buttonTextRendererType,
  makeButtonTextRenderer,
} from './core/craEngine/components/meshComponents/basic/ButtonTextRenderer';
import { imageRendererType, makeImageRenderer } from './core/craEngine/components/meshComponents/basic/ImageRenderer';
import {
  makeFireParticleSystem,
  fireParticleSystemType,
} from './core/craEngine/components/ParticleSystem/FireParticleSystemComponent';
import { makeWaterParticleSystem, waterParticleSystemType } from './core/craEngine/components/ParticleSystem/WaterParticleSystemComponent';

export const test: string = 'test';

export {GetSDK} from './Sdk';
// export {Frame} from './Frame';
// export * from './sdk-components/NestThermostat';
// export * from './sdk-components/RoomShadow';
// export * from './sdk-components/ClockPainter';
// export * from './sdk-components/SphereSource';

// import { securityCameraType, makeSecurityCamera } from './sdk-components/SecurityCamera';
// import { loadingIndicatorType, makeLoadingIndicator } from './sdk-components/LoadingIndicator';

// import { sceneRendererType, makeSceneRenderer }  from './sdk-components/SceneRenderer';
// import { videoRendererType, makeVideoRenderer } from './sdk-components/VideoRenderer';
// import { videoStreamCaptureType, makeVideoStreamCapture } from './sdk-components/VideoStreamCapture';
// import { nestThermostatType, makeNestThermostat } from './sdk-components/NestThermostat';
// import { roomShadowType, makeRoomShadow }  from './sdk-components/RoomShadow';
// import { clockPainterType, makeClockPainter } from './sdk-components/ClockPainter';
// import { scenePainterType, makeScenePainter } from './sdk-components/ScenePainter';
// import { tunerType, makeTuner } from './sdk-components/Tuner';
// import { hlsLoaderType, makeHlsLoader } from './sdk-components/HlsLoader';
// import { mjpegPlayerType, makeMjpegPlayer } from './sdk-components/MjpegPlayer';
// import { toggleStateType, makeToggleState } from './sdk-components/ToggleState';
// import { canvasBorderType, makeCanvasBorder } from './sdk-components/CanvasBorder';
// import { canvasTextType, makeCanvasText } from './sdk-components/CanvasText';
// import { canvasImageType, makeCanvasImage } from './sdk-components/CanvasImage';
// import { cameraInputType, makeCameraInput } from './sdk-components/Camera';
// import { makeSphereSource, sphereSourceType } from './sdk-components/SphereSource';
// import { boxSourceType, makeBoxSource } from './sdk-components/BoxSource';
// import { cylinderSourceType, makeCylinderSource } from './sdk-components/CylinderSource';

export const  initComponents = async (sdk: any) => {
  await Promise.all([
    sdk.Scene.register(rotateToggleType, makeRotateToggle),
    sdk.Scene.register(fireParticleSystemType, makeFireParticleSystem),
    sdk.Scene.register(waterParticleSystemType, makeWaterParticleSystem),
    sdk.Scene.register(buttonTextRendererType, makeButtonTextRenderer),
    sdk.Scene.register(plateRendererType, makePlateRenderer),
    sdk.Scene.register(toggleComponentType, makeToggleComponent),
    sdk.Scene.register(outlinePostProcessType, makeOutlinePostProcess),
    sdk.Scene.register(highlightBoxType, makeHighlightBox),
    sdk.Scene.register(imageRendererType, makeImageRenderer),
    // sdk.Scene.register(securityCameraType, makeSecurityCamera),
    // sdk.Scene.register(loadingIndicatorType, makeLoadingIndicator),
    sdk.Scene.register(planeRendererType, makePlaneRenderer),
    sdk.Scene.register(canvasRendererType, makeCanvasRenderer),
    sdk.Scene.register(customObjType, makeCustomObjLoader),
    sdk.Scene.register(customDAEType, makeCustomDAELoader),
    sdk.Scene.register(genericInputType, makeGenericInput),
    sdk.Scene.register(lightType, makeLight),
    // sdk.Scene.register(sceneRendererType, makeSceneRenderer),
    sdk.Scene.register(videoRendererType, makeVideoRenderer),
    sdk.Scene.register(videoStreamCaptureType, makeVideoStreamCapture),
     sdk.Scene.register(nestThermostatType, makeNestThermostat),
    // sdk.Scene.register(roomShadowType, makeRoomShadow),
    // sdk.Scene.register(clockPainterType, makeClockPainter),
    // sdk.Scene.register(scenePainterType, makeScenePainter),
    // sdk.Scene.register(tunerType, makeTuner),
    // sdk.Scene.register(hlsLoaderType, makeHlsLoader),
    // sdk.Scene.register(mjpegPlayerType, makeMjpegPlayer),
    sdk.Scene.register(toggleStateType, makeToggleState),
    sdk.Scene.register(onOffButtonType, makeOnOffButton),
    sdk.Scene.register(eulerIntegratorType, makeEulerIntegrator),
    sdk.Scene.register(canvasBorderType, makeCanvasBorder),
    sdk.Scene.register(canvasTextType, makeCanvasText),
    sdk.Scene.register(paintSprayType, makePaintSpray),
    sdk.Scene.register(loadingIndicatorType, makeLoadingIndicator),
    // sdk.Scene.register(canvasImageType, makeCanvasImage),
    // sdk.Scene.register(cameraInputType, makeCameraInput),
    // sdk.Scene.register(sphereSourceType, makeSphereSource(sdk)),
    // sdk.Scene.register(boxSourceType, makeBoxSource(sdk)),
    // sdk.Scene.register(cylinderSourceType, makeCylinderSource(sdk)),
  ]);
};

export const assetVersion = '1.0-2-g6b74572';
export const cdnUrl = `https://static.matterport.com/showcase-sdk/examples/assets-${assetVersion}/assets`;
export const sdkKey = 'd9aa6b4671a74fc69305497e14cfda8a';
export const interfaceVersion = '3.10';
