import { mapToObject } from "@crema/utility/Utils";
import { store } from "App";
import PropertiesPanel from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel";
import Utils from "mp/core/craEngine/Tools/Utils";
import MpSdk from "mp/MpSdk";
import { SweepData } from "types/models/home/HomeApp";

let _currentTagsIdToSidMap: Map<string, string>;
let _currentTagSidToIdMap: Map<string, string>;

export const addToCurrentTagsSidMapGlobal = (showcaseTagId: string, tagSid: string) => {
    (_currentTagsIdToSidMap || new Map()).set(showcaseTagId, tagSid);
    (_currentTagSidToIdMap || new Map()).set(tagSid, showcaseTagId);
}
export const setCurrentTagsSidMapGlobal = (tags: Map<string, string>) => {
    _currentTagsIdToSidMap = tags;
    setTagSidToIdMap();
}

export const getTagSidForTagId = (tagId: string) => {
    return _currentTagsIdToSidMap && _currentTagsIdToSidMap.get(tagId)
}

// export const tagsAlreadyImported = () => {
//     if (Array.from(store.getState().home.spaceTags.values()).find((x) => x.data?.source == 'MP')) {
//         return true;
//     }
//     return false;
// }


export let isRepositioning: boolean;
export const setIsRepositioning = (value: boolean) => {
    isRepositioning = value;
}


export let isPlacingTag: boolean;
export const setIsPlacingTag = (value: boolean) => {
    isPlacingTag = value;
}

// export let currentSweep: any;
// export let currentRotation: any;
// export const setCurrentSweep = (pose: any) => {
//     currentSweep = pose.sweep;
//     currentRotation = pose.rotation;
// }


export const getCurrentSweep = async (): Promise<SweepData> => {

    try {
        let pose = await MpSdk.Instance.mps.Camera.getPose();
        let s: SweepData = { sid: pose.sweep, rotation: pose.rotation, pose: pose };
        currentZoom && (s.zoom = currentZoom);
        return Utils.SimpleClone(s);
        // .catch(console.error);
    } catch (e) {
        console.error(e);
        return { sid: '' };
    }
}

export let currentZoom: any;
export const setCurrentZoom = (zoom: any) => {
    currentZoom = zoom;
}

const setTagSidToIdMap = () => {

    _currentTagSidToIdMap = Array.from(_currentTagsIdToSidMap.entries()).reduce(function (map, obj) {
        map.set(obj[1], obj[0]);
        return map;
    }, new Map<string, string>());

}

export const getTagIdForTagSid = (tagSid: string) => {
    let tagId = _currentTagSidToIdMap.get(tagSid)
    return tagId;
}

export const mpSdkMap = new Map();
export const setMpSdkGlobal = (spaceId: string, mpSdk: any) => {

    mpSdkMap.clear();

    mpSdkMap.set(spaceId, mpSdk);

}
export const getMpSdkGlobal = (spaceId: string) => {
    return mpSdkMap.get(spaceId);
}

export let tagSidBeingAdded: any = '';
export const setTagSidBeingAdded = (value: any) => {
    tagSidBeingAdded = value
};

export let externalTags: any[] = [];
export const setExternalTags = (tags: any[]) => externalTags = tags;

export let historyHook: any = null;
export const setHistoryHook = (history: any) => historyHook = history;

export let spaceViewDispatchHook: any = null;
export const setSpaceViewDispatchHook = (dispatch: any) => {
    spaceViewDispatchHook = dispatch;
    PropertiesPanel.getDispatch = dispatch;
}

// export let rawRichText: string = '';
// export const setRawRichText = (text: string) => rawRichText = text;




// export let tagRefs: Map<string, React.RefObject<HTMLInputElement>> = new Map();
// export const setTagRefs = (refs: Map<string, React.RefObject<HTMLInputElement>>) => tagRefs = refs;
