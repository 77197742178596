import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {
  onAddNewTask,
  onDeleteSelectedTask,
  onEditTaskDetails,
} from '../../../../../../redux/actions';
import ConfirmationDialog from '../../../../../../@crema/core/ConfirmationDialog';
import IntlMessages from '../../../../../../@crema/utility/IntlMessages';
import CardHeader from './CardHeader';
import AddCardForm from './AddCardForm';
import {makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {
  AttachmentObj,
  ProjectObj,
  TaskSectionObj,
  TaskObj,
  CheckedListObj,
  LabelObj,
  CollaboratorObj,
  CommentObj,
} from '../../../../../../types/models/apps/ProjectBoard';
import { useLocation } from 'react-router';
import { AppState } from 'redux/store';
import NotificationService from "../../../../../../@crema/services/NotificationService";
import { ANNOTATION_TYPE, HomeRoutes } from 'types/models/home/HomeApp';
import { baseUrl } from 'Config';
import { getTaskSection } from 'modules/apps/ProjectBoard/ProjectUtils';

const useStyles = makeStyles(() => ({
  dialogBox: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 900,
      width: '100%',
    },
  },
  scrollRoot: {
    height: 595,
  },
}));

interface AddCardProps {
  onCloseAddTask: () => void;
  project: ProjectObj;
  list: TaskSectionObj;
  selectedTask: TaskObj | null;
  submitTask: (task: TaskObj) => any;
  annotationType:string;
}

const AddTaskCard: React.FC<AddCardProps> = ({
  onCloseAddTask,
  project,
  list,
  selectedTask,
  submitTask,
  annotationType
}) => {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const currentSpaceDid = useSelector<AppState, string>(
    ({ home }) => home.currentSpace?.did || ''
  );
  const {authUser} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

  const currentSpaceId = useSelector<AppState, string>(
    ({ home }) => home.currentSpace?.id || ''
  );

  const [checkedList, setCheckedList] = useState<CheckedListObj[]>(() =>
    selectedTask ? selectedTask.checkedList : [],
  );

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const [selectedCollaborators, setCollaboratorsList] = useState<CollaboratorObj[]>(() =>
    selectedTask ? selectedTask.collaborators : [],
  );

  const [selectedLabels, setSelectedLabels] = useState<LabelObj[]>(() =>
    selectedTask ? selectedTask.label : [],
  );

  const [comments, setComments] = useState<CommentObj[]>(() =>
    selectedTask ? selectedTask.comments : [],
  );

  const [attachments, setAttachments] = useState<AttachmentObj[]>(() =>
    selectedTask ? selectedTask.attachments : [],
  );

  const [section, setSection] = useState<TaskSectionObj | null>(() =>
    selectedTask ? getTaskSection(selectedTask, project)||null : null,
  );

  const [selectedProject, setSelectedProject] = useState<ProjectObj | null>(null);

  useEffect(()=>{
    setSelectedProject(project || null);
  },[project]);

  const onAddAttachments = (files: any) => {
    setAttachments([...attachments, ...files]);
  };

  const onDeleteTask = () => {
    const projectId = project.id;
    const sectionId = list.id;
    const taskId = selectedTask!.id || '';
    dispatch(onDeleteSelectedTask(projectId, sectionId, taskId));
    setDeleteDialogOpen(false);
    onCloseAddTask();
  };

  const onClickDeleteIcon = () => {
    if (selectedTask) {
      setDeleteDialogOpen(true);
    } else {
      onCloseAddTask();
    }
  };

  const sendEmailsToCollaborator = (prevTaskObj:TaskObj,taskObj:TaskObj) =>{
    // console.log(prevTaskObj,taskObj,"sendEmailsToCollaborator");
    if(taskObj.label.length > prevTaskObj.label.length){
      // added label in task
      let updatedLabel:LabelObj[] = taskObj.label.filter(l => !prevTaskObj.label.some(sL => sL.name === l.name));
      Promise.all(taskObj.collaborators.map((c:CollaboratorObj)=>{
        Promise.all(updatedLabel.map((l:LabelObj)=>{
          dispatch(NotificationService.assignLabelInTask(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid||'', c?.id?.toString()||c.email,
          {
              fromFirstName: authUser?.user.firstName || "" ,
              toEmail: c.email||'',
              taskTitle: taskObj.title,
              taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`,
              labelName: l.name,
          }))
        }));
      }));

    } else if(taskObj.label.length < prevTaskObj.label.length){
       // deleted label from task
      let updatedLabel:LabelObj[] = prevTaskObj.label.filter(l => !taskObj.label.some(sL => sL.name === l.name));
      Promise.all(taskObj.collaborators.map((c:CollaboratorObj)=>{
        Promise.all(updatedLabel.map((l:LabelObj)=>{
          dispatch(NotificationService.removeLabelFromTask(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid||'', c?.id?.toString()||c.email,
          {
              fromFirstName: authUser?.user.firstName || "" ,
              toEmail: c.email||'',
              taskTitle: taskObj.title,
              taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`,
              labelName: l.name,
          }))
        }));
      }));

    }

    if(taskObj.collaborators.length > prevTaskObj.collaborators.length){
      // added collaborators in task
      let updatedCollaborator:CollaboratorObj[] = taskObj.collaborators.filter(l => !prevTaskObj.collaborators.some(sL => sL.name === l.name));

      Promise.all(updatedCollaborator.map((c:CollaboratorObj)=>{
        dispatch(NotificationService.assignTaskToUser(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid||'', c?.id?.toString()||c.email,
          {
              fromFirstName: authUser?.user.firstName || "" ,
              toEmail: c.email||'',
              taskTitle: taskObj.title,
              taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`
          }))
      }));

    } else if(taskObj.collaborators.length < prevTaskObj.collaborators.length){
       // deleted collaborators from task
      let updatedCollaborator:CollaboratorObj[] = prevTaskObj.collaborators.filter(l => !taskObj.collaborators.some(sL => sL.name === l.name));
      Promise.all(updatedCollaborator.map((c:CollaboratorObj)=>{
        dispatch(NotificationService.removeUserFromTask(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid||'', c?.id?.toString()||c.email,
          {
              fromFirstName: authUser?.user.firstName || "" ,
              toEmail: c.email||'',
              taskTitle: taskObj.title,
              taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`
          }))
      }));

    }

    // Field updated in task
    let updatedField:string[] = [];
    if(taskObj.title !== prevTaskObj.title){
      updatedField.push('Title');
    }
    if(taskObj.desc !== prevTaskObj.desc){
      updatedField.push('Description');
    }
    if(taskObj.checkedList.length !== prevTaskObj.checkedList.length){
      updatedField.push('Checked List');
    }

    if(updatedField.length){
      // console.log(task.collaborators,"task.collaborators")
      Promise.all(taskObj.collaborators.map((c:CollaboratorObj)=>{
        dispatch(NotificationService.fieldUpdatedInTask(`apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`, authUser?.uid||'', c?.id?.toString()||'',
          {
              fromFirstName: authUser?.user.firstName || "" ,
              toEmail: c.email || '',
              taskTitle: taskObj.title,
              taskLink: `${baseUrl}/apps/projects/${project.did}/section/${taskObj.sectionId}/task/${taskObj.id}`,
              updatedField: updatedField.join(', '),
          }));
      }));

    }

  }
  const classes = useStyles();

  const {messages} = useIntl();

  const validationSchema = yup.object({
    title: yup
      .string()
      .required(messages['validation.titleRequired'] as string),
  });

  return (console.log(`[st] rendering AddCard`),
      <>
        <Formik
          validateOnChange={true}
          initialValues={{
            title: selectedTask ? selectedTask.title : '',
            desc: selectedTask && selectedTask.desc ? selectedTask.desc : '',
            label: selectedTask && selectedTask.label ? selectedTask.label : [],
            collaborators: selectedTask && selectedTask.collaborators ? selectedTask.collaborators : [],
            date: selectedTask && selectedTask.date ? selectedTask.date : null,
            // section: section,
            // selectedProject: selectedProject,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, {setSubmitting, resetForm}) => {
            if (selectedTask) {
              const editedTask = {
                // ...selectedTask,
                type: selectedTask.type,
                id: selectedTask.id,
                projectId: project.id,
                spaceId: selectedTask.spaceId,
                comments: comments,
                ...data,
                attachments: attachments,
                collaborators: selectedCollaborators,
                label: selectedLabels,
                checkedList: checkedList.filter(
                  (item: CheckedListObj) => item.title !== '',
                ),
                sectionId: (pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK)?section?.id:list.id,
                // ...((pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK) && { section: section }),
              };
              if ((pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK)) {
                if (section&&selectedProject) {
                  setSubmitting(true);
                  submitTask(editedTask);
                  resetForm();
                  setSubmitting(false);
                } else {
                  setSubmitting(false);
                }
              } else if(selectedProject){
                setSubmitting(true);
                dispatch(onEditTaskDetails(editedTask,(task:TaskObj) => {sendEmailsToCollaborator(selectedTask,editedTask)}));
                onCloseAddTask();
                resetForm();
                setSubmitting(false);
              }
            } else {
              const newTask = {
                // id: Math.floor(Math.random() * 1000),
                // id: '',
                attachments: attachments,
                checkedList: checkedList.filter(
                  (item: CheckedListObj) => item.title !== '',
                ),
                comments: comments,
                ...data,
                label: selectedLabels,
                collaborators: selectedCollaborators,
                sectionId: (pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK) ? section?.id :list.id,
                type: (pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK) ? "TASK_TAG" : "TASK",
                spaceId: currentSpaceId || '',
                projectId: project.id,
                // ...((pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK)&&{section:section}),
              };
              if ((pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK)) {
                // Created task from space
                if (section&&selectedProject) {
                  setSubmitting(true);
                  submitTask(newTask);
                  resetForm();
                  setSubmitting(false);
                } else {
                  setSubmitting(false);
                }
              } else {
                // Created task from project
                setSubmitting(true);
                dispatch(onEditTaskDetails(newTask,(task:TaskObj) => { }));
                onCloseAddTask();
                resetForm();
                setSubmitting(false);
              }
            }
          }}>
          {({values, isSubmitting, setFieldValue,touched,errors,submitForm}) => (
            <>
              <CardHeader
                onAddAttachments={onAddAttachments}
                onClickDeleteIcon={onClickDeleteIcon}
              />

              {selectedProject && <AddCardForm
                values={values}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                project={selectedProject}
                list={list}
                checkedList={checkedList}
                onCloseAddTask={()=> {
                  if(pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK){
                    // if task form closed from space
                    onCloseAddTask();
                  } else{
                     // if task form closed from project board
                    if(!selectedTask?.title){
                      onDeleteTask();
                    } else{
                      onCloseAddTask();
                    }
                  }
                }}
                setCheckedList={setCheckedList}
                comments={comments}
                setComments={setComments}
                attachments={attachments}
                setAttachments={setAttachments}
                selectedLabels={selectedLabels}
                setSelectedLabels={setSelectedLabels}
                selectedCollaborators={selectedCollaborators}
                setCollaboratorsList={setCollaboratorsList}
                selectedTask={selectedTask}
                section={section}
                setSection={setSection}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                touched={touched}
                errors={errors}
                annotationType={annotationType}
              />}
            </>
          )}
        </Formik>

      {isDeleteDialogOpen ? (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          onDeny={setDeleteDialogOpen}
          onConfirm={onDeleteTask}
          title={<IntlMessages id='projectboard.deleteCard' />}
          dialogTitle={<IntlMessages id='common.deleteItem' />}
        />
      ) : null}
    </>
  );
};

export default AddTaskCard;
