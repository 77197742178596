import {GenericEventCallback, InputSystem} from './InputSystem';

export interface UpdateCallback {
    (deltaTime:number): void;
}

export interface FrameUpdateCallback {
    (): void;
}

export class WindowingSystem {
    protected showcase: HTMLIFrameElement| null = null;
    protected iFrameDocument: Document | null = null;
    
    protected _inputSystem:InputSystem;
    
    protected _updateCallbacks: UpdateCallback[];
    protected _frameUpdateCallbacks: FrameUpdateCallback[];
    
    constructor() {
        if (this.showcase == null) {
            this.showcase = document.getElementById('sdk-iframe') as HTMLIFrameElement;
            this.iFrameDocument = this.showcase!.contentDocument || this.showcase!.contentWindow!.document;
        }
    
        this._inputSystem = new InputSystem(this);
        this._updateCallbacks = [];
        this._frameUpdateCallbacks = [];
    }
    
    public initContainer(container:HTMLElement) {
        this.iFrameDocument!.body.innerHTML = '';
        this.iFrameDocument!.body.appendChild(container);//this.renderer.domElement;
    }
    
    public getShowcaseDocument():Document {
        return this.iFrameDocument!;
    }
    
    public get aspectRatio():number {
        return this.showcase!.clientWidth / this.showcase!.clientHeight;
    }
    
    public get width():number {
        return this.showcase!.clientWidth;
    }
    
    public get height():number {
        return this.showcase!.clientHeight;
    }
    
    get inputSystem(): InputSystem {
        return this._inputSystem;
    }
    
    public registerUpdateCallback(callback:UpdateCallback):number {
        return this._updateCallbacks.push(callback) - 1;
    }
    
    public processAllUpdateCallbacks(deltaTime:number) {
        for (const updateCallback of this._updateCallbacks) {
            updateCallback(deltaTime);
        }
    }
    
    public registerFrameUpdateCallback(callback:FrameUpdateCallback):number {
        return this._frameUpdateCallbacks.push(callback) - 1;
    }
    
    public processAllFrameUpdateCallbacks() {
        for (const callback of this._frameUpdateCallbacks) {
            callback();
        }
    }
}
