import React, { useContext, Dispatch, SetStateAction } from 'react';
import { Box, FormControlLabel, Switch, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HomeIcon from '@material-ui/icons/Home';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import { OverflowMenu } from '../../../components/OverflowMenu';
import { ShowcaseContext } from '..';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { AppState } from 'redux/store';
import InputSubSystem from 'mp/core/craEngine/SubSystems/input/InputSubSystem';
import { canEdit } from '@crema/utility/Utils';
import { addTagsToEmptyShowcase } from '../../utils';
import { ShowcaseTag, SpaceRole } from 'types/models/home/HomeApp';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { useUserAdmin } from '@crema/utility/AppHooks';

const useStyles = makeStyles((theme) => ({
  bar: {
    left: '0px',
    bottom: '70px',
    display: 'flex',
    position: 'absolute',

    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    zIndex: 5,
    pointerEvents: 'none',

    // left: '30px',
    // left: 'calc(1vw + 28px)',
    // width: '5%',
    // bottom: '60px',

    // // marginLeft: "40px"
    // // display: 'flex',
    // // flexDirection: 'row',
    // // alignItems: 'center'

  },
  root: {
    // width: '600px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },

  },
  iconRowOld: {
    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
    pointerEvents: 'auto',
  },
  iconRow: {
  },
  shorcutIcon: {
    margin: '4px',
    fontSize: 30,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    height: "30px",
    width: "30px",
    '&:hover, &:focus': {
      color: '#FF3158',
    },
  },
  threeDotsIcon: {
    margin: '4px',
    paddingTop: '2px',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    height: "30px",
    width: "30px",
    '&:hover, &:focus': {
      color: '#FF3158',
    },
  },
}));

interface Props {
  goToHomeSweep: () => void;
  lockViewDefault: boolean;
  hide3DToolsDefault: boolean;
  showVariablesPanel: boolean;
  setShowVariablesPanel: Dispatch<SetStateAction<boolean>>;
}


const ShortCutPanel: React.FC<Props> = ({ goToHomeSweep, lockViewDefault, hide3DToolsDefault, setShowVariablesPanel, showVariablesPanel }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isDictaphoneOpen, setDictaphoneOpen] = React.useState(false);
  const spaceTags = useSelector<AppState, Map<string, ShowcaseTag>>(({ home }) => home.spaceTags);
  const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
  const spaceMembershipRole = useSelector<AppState, SpaceRole | undefined >(({ home }) => home.currentSpace?.spaceMembership?.role);
  const [lockView, setLockView] = React.useState(lockViewDefault);
  const [propertyPanelShowOverride, setPropertyPanelShowOverride] = React.useState<boolean>(true);

  const isAddingTag =
    useSelector<
      AppState,
      boolean
    >(({ home }) => home.isAddingTag);


  const theme = useTheme();
  Simulation.instance.lockViewState = [lockView, setLockView];
  Simulation.instance.propertyPanelShowOverride = [propertyPanelShowOverride, setPropertyPanelShowOverride];

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow placement='top' classes={classes} {...props} />;
  }
  // React.useEffect(() => {
  //   let setTo = !hide3DToolsDefault ? propertyPanelShowOverride : !propertyPanelShowOverride
  //   setPropertyPanelShowOverride(setTo)
  // }, [Simulation.instance])

  React.useEffect(() => {

    if (!(spaceMembershipRole && canEdit(spaceMembershipRole))) {
      setPropertyPanelShowOverride(false);
      console.log(`[st] 1 setPropertyPanelShowOverride `);

    } else {
      let setTo = !hide3DToolsDefault ? propertyPanelShowOverride : !propertyPanelShowOverride;
      console.log(`[st] 2 setPropertyPanelShowOverride ${setTo}`);
      setPropertyPanelShowOverride(setTo);
    }

  }, [Simulation.instance, spaceMembershipRole]);

  //TODO does this break things?
  // React.useEffect(() => {
  //   console.log(`[st] locking view ${isAddingTag}`);

  //   setLockView(isAddingTag);
  // }, [isAddingTag]);

  const isUserAdmin = useUserAdmin();

  React.useEffect(() => {
    InputSubSystem.input && InputSubSystem.input.setMatterPortCameraMovement(!lockView);
  }, [InputSubSystem.input]);

  return (console.log(`[st] rendering ShortcutPanel`),
    <Box className={classes.bar} id='shortcut-panel'>
      <div className={classes.root} style={{pointerEvents: 'none'}}>
        <Collapse in={open}>
          <Alert
            severity='info'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }>
            Keyboard shortcuts for 3D objects
            <br /><br />
            {/* <p>▪️ Click on a 3D object <br />▪️ Use G for Green/ B for Blue/O for Orange keys to move in the corresponding X/Y/Z axes as below:</p>
            <br /> */}
            <p><b>▪️ To Move: </b> Press Keys
              <span style={{ color: 'green' }}> G / </span>
              <span style={{ color: 'blue' }}>B / </span>
              <span style={{ color: 'orange' }}>O  </span>
              or
              <b> Shift</b> +   <span style={{ color: 'green' }}> G / </span>
              <span style={{ color: 'blue' }}>B / </span>
              <span style={{ color: 'orange' }}>O  </span> for the opposite direction
              <br /> -- (<span style={{ color: 'green' }}>G for Green</span>/ <span style={{ color: 'blue' }}>B for Blue </span>/ <span style={{ color: 'orange' }}>O for Orange </span> for corresponding X/Y/Z axes )

            </p>
            <br /><p><b>▪️ To Rotate: </b> Option +
              <span style={{ color: 'green' }}> G / </span>
              <span style={{ color: 'blue' }}>B / </span>
              <span style={{ color: 'orange' }}>O  </span>
              and
              <b> Shift + Option</b> +  <span style={{ color: 'green' }}> G / </span>
              <span style={{ color: 'blue' }}>B / </span>
              <span style={{ color: 'orange' }}>O  </span> for the opposite direction
            </p>
          </Alert>
        </Collapse>
      </div>
      <div className={classes.iconRowOld} >
      {/* <BootstrapTooltip title='Add with Voice' style={{visibility: 'hidden'}}>
          <KeyboardVoiceIcon
            onClick={() => {
              if (document.activeElement && document.activeElement.constructor.name == "HTMLInputElement"){
                if((document.activeElement as HTMLInputElement).type == 'text'){
                  (document.activeElement as HTMLInputElement).value = 'this is a test'
                }

              }
              setDictaphoneOpen(!isDictaphoneOpen);

            }}
            className={classes.shorcutIcon}
            fontSize='small'
          />
        </BootstrapTooltip> */}
        <BootstrapTooltip title='Keyboard Shortcuts'>
          <HelpOutlineIcon
            onClick={() => {
              setOpen(!open);
            }}
            className={classes.shorcutIcon}
            fontSize='small'
          />
        </BootstrapTooltip>
        <BootstrapTooltip title='Go to Home position'>
          <HomeIcon
            onClick={() => goToHomeSweep ? goToHomeSweep() : {}}
            className={classes.shorcutIcon}
            fontSize='small'
          />
        </BootstrapTooltip>
        <BootstrapTooltip title='More Options'>
          <div className={classes.threeDotsIcon}>
            <OverflowMenu buttonStyle={{ color: theme.palette.secondary.main }} menuItems={[
              {
                title: "Hide all tags", onClick: () => { addTagsToEmptyShowcase([]) }
              },
              { title: "Hide all 3D", onClick: () => { spaceModels.size > 0 && Simulation.instance.filterVisibleNodes([]) } },
              { title: "Show all tags", onClick: () => { addTagsToEmptyShowcase(Array.from(spaceTags.keys())) } },
              { title: "Show all 3D", onClick: () => { spaceModels.size > 0 && Simulation.instance.filterVisibleNodes(Array.from(spaceModels.keys())) } },
              {
                title: `Lock View ${lockView ? '(On)' : '(Off)'}`,
                // onClick: () => {
                //   spaceModels.size > 0 &&
                //     InputSubSystem.input.setMatterPortCameraMovement(lockView);
                //   setLockView(!lockView);

                // },
                component: <FormControlLabel
                  style={{
                    // textAlign: "center",
                    // marginBottom: '10px',
                    // display: 'block'
                  }}
                  control={
                    <Switch
                      checked={lockView}
                      size="small"
                      onChange={(e: any) => {
                        // spaceModels.size > 0 &&
                        InputSubSystem.input.setMatterPortCameraMovement(lockView);
                        setLockView(!lockView);

                      }}
                      name="lockViewSwitch"
                      color="primary"
                    />
                  }
                  label="Lock View"
                />
              },
              {
                // title: `Show 3D toolbar${propertyPanelShowOverride ? '(On)' : '(Off)'}`,
                // onClick: () => {
                //   setPropertyPanelShowOverride && setPropertyPanelShowOverride(!propertyPanelShowOverride)
                // },
                component: <FormControlLabel
                  style={{
                    // textAlign: "center",
                    // marginBottom: '10px',
                    // display: 'block'
                  }}
                  control={
                    <Switch
                      size="small"
                      checked={propertyPanelShowOverride}
                      onChange={(e: any) => {
                        setPropertyPanelShowOverride && setPropertyPanelShowOverride(!propertyPanelShowOverride);
                      }}
                      name="propPanelShowSwitch"
                      color="primary"
                    />
                  }
                  label="Show 3D tools"
                />
              },
              {
                // title: `Show Controls${showVariablesPanel ? '(On)' : '(Off)'}`, onClick: () => {
                //   // currentSpace && currentSpace.variables && currentSpace.variables?.length > 0 &&
                //   setShowVariablesPanel && setShowVariablesPanel(!showVariablesPanel);
                // },
                component: <FormControlLabel
                  style={{
                    // textAlign: "center",
                    // marginBottom: '10px',
                    // display: 'block'
                  }}
                  control={
                    <Switch
                      size="small"
                      checked={showVariablesPanel}
                      onChange={(e: any) => {
                        setShowVariablesPanel && setShowVariablesPanel(!showVariablesPanel);
                      }}
                      name="showVariablesSwitch"
                      color="primary"
                    />
                  }
                  label="Show Variables"
                />
              }
            ]} />

          </div>
        </BootstrapTooltip>
      </div>
    </Box>
  );
};

export default React.memo(ShortCutPanel);