import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Form, Formik, useField } from 'formik';
import Modal from '@material-ui/core/Modal';
import * as yup from 'yup';
// import {onSaveSpace} from '../../../../../../redux/actions';
import Box from '@material-ui/core/Box';
import { Fonts } from 'shared/constants/AppEnums';
import {
  List
} from '@material-ui/core';
import {
  logUserActivity,
  onSetVariable,

} from 'redux/actions/Home';
import {
  onGetTagGroupsList,
  onUpdateTagGroup,
  setCurrentTagGroupId,



  handleGoToTagGroupByIndex,
  setTagGroupsList,
} from 'redux/actions/Step';
import { ANNOTATION_TYPE, SweepTransition, } from 'types/models/home/HomeApp';
import {
  // currentSweep,
   currentZoom, getMpSdkGlobal, mpSdkMap, getCurrentSweep } from '../TagSidebar/Tags/ShowcaseUtils';
// import { SweepTransition, } from 'types/models/home/HomeApp';
// import { currentSweep, currentZoom, getMpSdkGlobal } from '../TagSidebar/Tags/ShowcaseUtils';
import { ShowcaseTag, TagGroup, ACTIONS, OBJECT_TYPES } from 'types/models/home/HomeApp';
import { ShowcaseContext } from '../..';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { SET_TAG_GROUP_LIST } from 'types/actions/Home.action';
import { CremaTheme } from 'types/AppContextPropsType';
import { InfoView } from '@crema';
import { firestore } from '@crema/services/auth/firebase/firebase';
import Utils from 'mp/core/craEngine/Tools/Utils';
import TagGroupCard from './TagGroup';
import log from 'loglevel';
import { showMessage } from 'redux/actions';
import { addTagsToEmptyShowcase, handleTagClick, isJumpToTagIdValid } from 'modules/home/SpaceDetail/utils';
import MpSdk from 'mp/MpSdk';

// import LogoutIcon from '@material-ui/icons/Logout';
// import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

const useStyles = makeStyles((theme: Theme) =>
  // root: {
  //   height: 216,
  //   flexGrow: 1,
  //   maxWidth: 400,
  // },
  createStyles({
    tagGroupsListing: {},
    tagGroupsListingRoot: {
      width: '100%',
      paddingLeft: '15px',
      paddingRight: '15px',
      // backgroundColor: theme.palette.background.paper,
    },
    tagGroupsListSubHeader: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    tagGroupsListItem: {
      cursor: 'pointer',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '2px',
      paddingBottom: '2px',
      fontSize: '12px',
    },
    tagGroupsListItemActive: {
      backgroundColor: 'rgba(244, 247, 254, 0.8)',
      paddingTop: '2px',
      paddingBottom: '2px',
      fontSize: '12px',
    },
    tagsNested: {
      // paddingLeft: theme.spacing(6),
      fontSize: '12px',
      cursor: 'pointer',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    tagGroupName: {
      '& .MuiTypography-body1': {
        fontSize: '12px',
      },
      paddingTop: '0px',
      paddingBottom: '0px',
      maxWidth: '150px',
    },
    tagsNestedText: {
      '& .MuiTypography-body1': {
        fontSize: '12px',
      },
      // paddingLeft: theme.spacing(6),
      cursor: 'pointer',
      paddingRight: '2px',
      fontSize: '12px',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    deleteTagGroupIcon: {
      marginRight: 4,
      color: theme.palette.text.primary,
    },
    icon: {
      marginRight: 4,
      cursor: 'pointer',
      height: '18px',
      width: '18px',
      '& .MuiSvgIcon-root': {
        // width: '0.5em'
      }
    },
    iconActive: {
      // display: 'none',
      // '&:hover': {
      display: 'block',
      marginRight: 4,
      cursor: 'pointer',
      // height: '12px',
      // width: '12px',
      color: "blue",
      zIndex: 20,
      fontSize: '10px'

      // }

    },
    iconInactive: {
      // display: 'none',
      // '&:hover': {
      display: 'block',
      marginRight: 4,
      cursor: 'pointer',
      // height: '12px',
      // width: '12px',
      color: "grey",
      zIndex: 20,
      fontSize: '10px'
      // }
    },
    outlineBox: {
      // border: '1px solid #0000001c',
      padding: "15px",
      // paddingTop: "25px",
      // width: '280px',
      borderRadius: '10px',
      marginBottom: '12px'
    },
    outlineBoxTitle: {
      // marginBottom: "-5px",
      backgroundColor: "white",
      color: "black",
      marginTop: "-45px", //removes notched outline
      padding: "10px",
      width: "fit-content",
      // textTransform: "uppercase",
      fontSize: "13px",
    },
    altBgEven: {
      // backgroundColor: '##f4f7fecc'
      backgroundColor: '#3c48991c'
    },
    altBgOdd: {
      backgroundColor: '#f4f7fecc'
    },
    bgActive: {
      // backgroundColor: '#0e6ebf4a'// '#f0f8ff',
      backgroundColor: '#c8d9e9',
      border: '1px solid #0388e5bd'
    },
    bgInactive: {
      backgroundColor: '#3c48991c'
    },
    textField: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      fontSize: "13px",
      width: '250px',
      '& .MuiInputBase-input': {
        fontSize: "13px",
      },
      '& .MuiFormLabel-root': {
        fontSize: "13px",
      },
      '& .MuiInput-root': {
        // borderTopWidth: "0.5px",
        borderLeftWidth: "0.5px",
        borderRightWidth: "0.5px",
      },
      '& Mui-focused': {
        borderTopWidth: "0px",
      }
    },
    dense: {
      marginTop: theme.spacing(3),
    },
  }),
);

interface TagGroupsProps {
  onDuplicateTagGroups: (tg: TagGroup) => void;
}

const playTextToSpeech = (text: string) => {

  var synth = window.speechSynthesis;
  var utterThis = new SpeechSynthesisUtterance(text);
  synth.speak(utterThis);
  return;

}

const TagGroups: React.FC<TagGroupsProps> = ({ onDuplicateTagGroups }) => {

  const {
    currentSpace,
    currentLesson,
    currentTagGroupId,
    spaceModels,
    spaceTags,
    tagGroupsList,
  } = useSelector<AppState, AppState['home']>(({ home }) => home);
  const { authUser } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const showcaseContext = useContext(ShowcaseContext);
  const [editTagGroup, setEditTagGroup] = useState<TagGroup | null>(null);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [refs, setRefs] = useState<any>([]);
  // const [tagGroupsListState, setTagGroupsListState] = useState<TagGroup[]>([]);
  // const [bannerTextBeingEdited, setBannerTextBeingEdited] = useState<string>('');
  // const [openLogicTreesDrawer, setOpenLogicTreesDrawer] = useState<boolean>(false);
  // let mpSdkLocal = currentSpace ? getMpSdkGlobal(currentSpace?.id) : undefined;

  const tgRefs = useRef([]);

  useEffect(() => {

    showTagsAnd3DForStep(currentTagGroupId);
    handleScrollIntoView(currentTagGroupId);
    if (currentTagGroupId == 'MFER0P8GxgG9C5sUsNpK') {
      try {
        dispatch(onSetVariable({ name: 'Priming Valve', value: 'close' }))
        dispatch(onSetVariable({ name: 'Air Compressor', value: 'Off' }))
        dispatch(onSetVariable({ name: 'Main Drain', value: 'open' }))
        dispatch(onSetVariable({ name: 'Control Valve', value: 'Close' }))
      } catch (e) { }
    }

  }, [currentTagGroupId,
    tagGroupsList,
    // mpSdkLocal,
    spaceTags, spaceModels
  ]);

  // useEffect(() => {
  //   runStepEnterWorkflow();

  // <<<<<<< HEAD
  //   // }, [currentTagGroupId])
  // =======
  //   let tagGroup = tagGroupsList.find((tagGroup: TagGroup) => tagGroup.id === currentTagGroupId);
  //   tagGroup && tagGroup.logic && LogicEngine.processStepLogicConditions(tagGroup.logic, null, false, false, true, spaceModels, variableValues);

  // }, [currentTagGroupId,
  //   tagGroupsList, variableValues, currentSpace
  //  ])
  // >>>>>>> e8f188fa (hide save pose, jump to from viewers)

  const showTagsAnd3DForStep = async (tagGroupId: any) => {

    if (tagGroupId) {
      let tagGroup = tagGroupsList.find((tagGroup: TagGroup) => tagGroup.id === tagGroupId);

      if (!tagGroup) {
        return;
      }
      let tagIdsForTagGroup = tagGroup.tagIds || [];

      // if(tagIdsForTagGroup && tagIdsForTagGroup.length > 0){

      let showTagIds = tagIdsForTagGroup.filter(spaceTag => spaceTags.has(spaceTag));
      await addTagsToEmptyShowcase(showTagIds);
      // let modelsList = tagIdsForTagGroup.map((tagId: any) => tagId.split('-')[1]);

      let showModelIds: string[] = spaceModels && spaceModels.size > 0 ?
        Array.from(spaceModels.values()).filter(s => s.userData.alwaysShow).map(s => s.id) : [];

      showModelIds.push(...tagIdsForTagGroup.filter(spaceModel => spaceModels.has(spaceModel)));
      await spaceModels.size > 0 && Simulation.instance.filterVisibleNodes(showModelIds);


      // if(modelsList.length == tagIdsForTagGroup.length) { //i.e. this group only has models, no tags
      //TODO: @raj highlight modelList[0]
      // } else {


      // let mpSDK = await (showcaseContext.initMpSdk() as any);
      // if (tagGroup.sweepAndPose) {
      //   goToPose(tagGroup);
      // }

      if (tagGroup.jumpToTagId && isJumpToTagIdValid(tagGroup)){
        try {
          handleTagClick(tagGroup.jumpToTagId);
          Simulation.instance.handleModelClick(tagGroup.jumpToTagId);
        } catch (e) { }

      } else if (tagGroup.sweepAndPose) {
        goToPose(tagGroup);

      } else if (showTagIds.length > 0) {
        //   handleTagClick(showTagIds[0], {
        //   // preventNavigation: true
        // });
      }
    }

    // mpSDK.Mattertag.getData().then((tags: any[]) => {

    //   let tag: any = tags.find(tag => tag.sid == _currentTagsSidMapGlobal.get( showTagIds[0]));

    //   if(tag) {
    //     let discAnchorPosition = mpSDK.Mattertag.getDiscPosition(tag);
    //     let anchoreDiscPositionVec3 = new THREE.Vector3(discAnchorPosition.x, discAnchorPosition.y, discAnchorPosition.z);

    //     // if(Simulation.instance.camera.isPointInFOV(anchoreDiscPositionVec3)){ //TODO RAJ if showTagIds[0] is in FOV
    //       //look at that tag
    //       alert("looking at " + JSON.stringify(tag) )
    //       Simulation.instance.camera.LookAtXYZ(anchoreDiscPositionVec3);
    //     // } else {
    //       // handleTagClick(showTagIds[0]);
    //     // }
    //   } else {
    //     console.log("[st] Error: First tag in this step not found in tag list")
    //   }
    // });
    //console.log(showTagIds[0]);

    // showcaseContext.initMpSdk().then(mpSdk => mpSdk && mpSdk.Camera.zoomTo(0.7).then(() => {

    // }).catch(console.error))

    // for(let i = 1; i < showTagIds.length; i++) {
    //   setTimeout(() => {
    //     handleTagClick(showTagIds[i]);
    //   }, 1000)
    // }
  }

  // }

  const focusOnTagGroup = (tg: any) => {
    // setBannerTextBeingEdited(tg.bannerText)
    currentTagGroupId !== tg.id && dispatch(setCurrentTagGroupId(tg.id));
    // dispatch(logUserActivity('CLICK', 'TagGroup', tg.id));
    dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tg.id));

  };

  const handleTagInTagGroupClick = async (tg: any, tagId: string) => {
    dispatch(setCurrentTagGroupId(tg.id));
    await showTagsAnd3DForStep(tg.id);
    handleTagClick(tagId);
    Simulation.instance.handleModelClick(tagId);
  }

  function handleUpTagGroups(tg: any) {
    if (tg.sortIndex == 0) return;
    let index = tagGroupsList.findIndex(
      (tag) => tag.sortIndex == tg.sortIndex - 1,
    );
    let index1 = tagGroupsList.findIndex((tag) => tag == tg);
    tagGroupsList[index].sortIndex++;
    tagGroupsList[index1].sortIndex--;
    let batch = firestore.batch();
    let docRef = firestore
      .collection(
        `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups`,
      )
      .doc(tagGroupsList[index].id);
    batch.update(docRef, { sortIndex: tagGroupsList[index].sortIndex });
    docRef = firestore
      .collection(
        `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups`,
      )
      .doc(tagGroupsList[index1].id);
    batch.update(docRef, { sortIndex: tagGroupsList[index1].sortIndex });
    batch.commit();
    let tgs = [...tagGroupsList].sort(
      (a: TagGroup, b: TagGroup) => a.sortIndex - b.sortIndex,
    );
    dispatch({ type: SET_TAG_GROUP_LIST, payload: tgs });
    dispatch(setTagGroupsList(tgs));
  }


  function handleDownTagGroups(tg: any) {
    if (tg.sortIndex == tagGroupsList.length - 1) return;

    let index = tagGroupsList.findIndex(
      (tag) => tag.sortIndex == tg.sortIndex + 1,
    );
    let index1 = tagGroupsList.findIndex((tag) => tag == tg);
    tagGroupsList[index].sortIndex--;
    tagGroupsList[index1].sortIndex++;
    let batch = firestore.batch();
    let docRef = firestore
      .collection(
        `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups`,
      )
      .doc(tagGroupsList[index].id);
    batch.update(docRef, { sortIndex: tagGroupsList[index].sortIndex });
    docRef = firestore
      .collection(
        `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups`,
      )
      .doc(tagGroupsList[index1].id);
    batch.update(docRef, { sortIndex: tagGroupsList[index1].sortIndex });
    batch.commit();
    let tgs = [...tagGroupsList].sort(
      (a: TagGroup, b: TagGroup) => a.sortIndex - b.sortIndex,
    );
    dispatch({ type: SET_TAG_GROUP_LIST, payload: tgs });

    dispatch(setTagGroupsList(tgs));
  }

  function toggleJumpToTagId(tagId: string, activeTg: TagGroup) {
    let tg = tagGroupsList.find(tagGroup => tagGroup.id == activeTg.id);
    if (tg) {
      tg.jumpToTagId = (tg.jumpToTagId == tagId) ? '' : tagId;
      currentSpace && currentLesson && dispatch(onUpdateTagGroup(tg
        // currentSpace?.id, currentLesson?.id, tg, tagGroupsList
      ));
    }
  }

  async function savePose(activeTg: TagGroup) {
    let tg = tagGroupsList.find(tagGroup => tagGroup.id == activeTg.id);
    if (tg) {
      tg.sweepAndPose = await getCurrentSweep();
      // let pose = await MpSdk.Instance.mps.Camera.getPose().catch(console.error);
      // dispatch(showMessage("Pose saved!"));
      // console.log(`[st] pose saved - ${JSON.stringify( pose)}`);

      // tg.sweepAndPose && (tg.sweepAndPose.pose = pose);
      // tg.sweepAndPose && currentZoom && (tg.sweepAndPose.zoom = currentZoom);
      console.log(`[st] ${JSON.stringify(tg.sweepAndPose)}`);

      // tg.sweepAndPose = Utils.SimpleClone(tg.sweepAndPose)
      currentSpace && currentLesson && dispatch(onUpdateTagGroup(tg
        // currentSpace?.id, currentLesson?.id, tg, tagGroupsList
      ));
    }
  }

  function getTagLabel(tagId: string, spaceTags: any): string {
    let tagLabel: string;
    if (spaceModels.size > 0 && spaceModels.has(tagId)) {
      tagLabel = spaceModels.get(tagId).nameToShow;
    } else {
      let showcaseTag = spaceTags.get(tagId);

      switch (showcaseTag?.annotationType) {
        case ANNOTATION_TYPE.QUIZ:
          tagLabel =
            showcaseTag.quizDetails?.question.slice(0, 30) + '...' || 'no label';
          break;

        case ANNOTATION_TYPE.TASK:
          tagLabel = showcaseTag.taskTag?.title;
          break;

        default:
          tagLabel = showcaseTag?.data.label;
          break;
      }
    }
    // }
    return tagLabel;
  }
  const handleDeleteTagGroups = async (tg: TagGroup) => {

    if (!currentSpace || !currentLesson) return;
    let res = window.confirm(`Are you sure you want to delete this step: ${tg?.name}?`);

    if (res) {

      const newTagGroupsRef: any = await firestore.doc(
        `Spaces/${currentSpace.id}/lessons/${currentLesson.id}/tagGroups/${tg.id}`,
      );
      newTagGroupsRef
        .delete()
        .then(async () => {
          //fix order

          let batch = firestore.batch();

          for (let si = tg.sortIndex + 1; si < tagGroupsList.length; si++) {
            let docRef = firestore.collection(
              `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups`,
            ).doc(tagGroupsList[si].id);
            batch.update(docRef, { sortIndex: tagGroupsList[si].sortIndex - 1 });
          }

          await batch.commit();

          dispatch(onGetTagGroupsList(authUser, currentSpace, currentLesson));
          tagGroupsList.length > 0 && dispatch(handleGoToTagGroupByIndex(tg.sortIndex - 1));
        })
        .catch(console.error);
    }
  };

  const handleEditTagGroups = (tg: TagGroup) => {
    setEditTagGroup(tg);
    setOpenEditForm(true);
  };

  const handleEditTagGroupsInLesson = async (id: string, name: string, bannerText: string, variableNameForInput: string) => {
    const newTagGroupsRef: any =
      currentSpace &&
      (await firestore
        .collection(`Spaces/${currentSpace.id}/lessons/${currentLesson?.id}/tagGroups/`)
        // .doc(editTagGroup?.id));
        .doc(id));

    // alert(`${name} + ${bannerText} + ${variableNameForInput}`)
    newTagGroupsRef
      .set({ name: name, bannerText: bannerText, variableNameForInput: variableNameForInput }, { merge: true })
      .then(() => {
        dispatch(onGetTagGroupsList(authUser, currentSpace, currentLesson));
        setEditTagGroup(null);
        setOpenEditForm(false);
      })
      .catch(console.error);
  };

  // const refs = useRef();
  // const refs: any = {}
  // tagGroupsList.reduce((acc: any, value: any) => {
  //   acc[value.id] = React.createRef();
  //   return acc;
  // }, {});

  const handleScrollIntoView = (id: any) => {

    // console.log(`[st] scrolling ${JSON.stringify(refs)} `);//${id} ${refs[id] && refs[id].current}`);

    refs[id]
      && refs[id].current
      && refs[id]
        .current
        .scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
  }

  // function getTagGroupCard(tg: TagGroup, index: number) {
  // tgRefs.current.push(element)
  const tgcs = tagGroupsList.map((tg: TagGroup, index: number) => {

    // refs[tg.id] = refs[tg.id] || React.createRef<any>();
    // refs[tg.id] = useRef();
    // console.log(`[st] setting ${JSON.stringify(refs)} `)

    return <TagGroupCard
      key={index}
      ref={(el: any) => {
        // console.log(`[st] setting div to ref ${JSON.stringify(el)}`);

        refs[tg.id] = el;
        setRefs(refs)
      }}
      // ref={el => (this.refs[m.id] = el)}
      // key={`tag-group-${tg.id}-${index}`}
      isActive={currentTagGroupId == tg.id}
      // mpSdk={mpSdkLocal}
      tg={tg}
      onDuplicateTagGroups={onDuplicateTagGroups}
      onClick={() => focusOnTagGroup(tg)}
      showTagsAnd3DForStep={(tgId) => showTagsAnd3DForStep(tg.id)}
      handleEditTagGroup={(utg: TagGroup) => handleEditTagGroupsInLesson(utg.id, utg.name, utg.bannerText || '', utg.variableNameForInput || '')}
      toggleJumpToTagId={toggleJumpToTagId}
      getTagLabel={getTagLabel}
      handleTagInTagGroupClick={handleTagInTagGroupClick} savePose={savePose} handleUpTagGroups={handleUpTagGroups} handleDownTagGroups={handleDownTagGroups}
      handleDeleteTagGroups={handleDeleteTagGroups}
    // scrollIntoView={() => ref.current.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    // })}
    />
  })

  // tgcs.forEach(tgc => {

  //   refs[tg.id] = refs[tg.id] || React.createRef<any>();
  //   setRefs(refs);
  //   // refs[tg.id] = useRef();
  //   console.log(`[st] setting ${JSON.stringify(refs) } `)
  // })
  // }

  return (
    <>
      <EditTagForm
        open={openEditForm}
        onClosed={() => {
          setOpenEditForm(false);
        }}
        onSubmit={(name: string, bannerText: string, variableNameForInput: string) => {
          handleEditTagGroupsInLesson(editTagGroup?.id || '', name, bannerText, variableNameForInput)
        }}
        tg={editTagGroup}
      />
      <div className={classes.tagGroupsListing}>
        <List
          component='nav'
          aria-labelledby='nested-list-subheader'
          disablePadding
          // subheader={
          // <ListSubheader
          //   component='div'
          //   id='nested-list-subheader'
          //   className={classes.tagGroupsListSubHeader}>
          //   tagGroupss/ Tag groups
          // </ListSubheader>
          // }
          className={classes.tagGroupsListingRoot}>
          {tgcs}
          {/*{tagGroupsList
            // .sort(    (a: TagGroup, b: TagGroup) => a.sortIndex - b.sortIndex)
            .map((tg: TagGroup, index: number) => (
              // getTagGroupCard(tg, index)

              //   <TagGroupCard
              //   key={index}
              //   ref={refs[tg.id]}
              //   // ref={el => (this.refs[m.id] = el)}
              //   // key={`tag-group-${tg.id}-${index}`}
              //   isActive={currentTagGroupId == tg.id}
              //   mpSdk={mpSdkLocal}
              //   tg={tg}
              //   onDuplicateTagGroups={onDuplicateTagGroups}
              //   onClick={() => focusOnTagGroup(tg)}
              //   showTagsAnd3DForStep={(tgId) => showTagsAnd3DForStep(tg.id)}
              //   handleEditTagGroup={(utg: TagGroup) => handleEditTagGroupsInLesson(utg.id, utg.name, utg.bannerText || '', utg.variableNameForInput || '')}
              //   toggleJumpToTagId={toggleJumpToTagId}
              //   getTagLabel={getTagLabel}
              //   handleTagInTagGroupClick={handleTagInTagGroupClick} savePose={savePose} handleUpTagGroups={handleUpTagGroups} handleDownTagGroups={handleDownTagGroups}
              //   handleDeleteTagGroups={handleDeleteTagGroups}
              // // scrollIntoView={() => ref.current.scrollIntoView({
              // //   behavior: 'smooth',
              // //   block: 'start',
              // // })}
              // />
            ))*/}

          {/* } */}
        </List>
      </div>
    </>
  );
};

export default TagGroups;

const useStylesTags = makeStyles((theme: CremaTheme) => ({
  formRoot: {
    textAlign: 'left',
    [theme.breakpoints.up('xl')]: {
      marginBottom: 24,
    },
  },
  myTextFieldRoot: {
    width: '100%',
  },
  checkboxRoot: {
    marginLeft: -12,
  },
  pointer: {
    cursor: 'pointer',
  },
  btnRoot: {
    borderRadius: theme.overrides.MuiCard.root.borderRadius,
    width: '10rem',
    fontWeight: Fonts.REGULAR,
    fontSize: 16,
  },
  dividerRoot: {
    marginBottom: 16,
    marginLeft: -48,
    marginRight: -48,
    [theme.breakpoints.up('xl')]: {
      marginBottom: 32,
    },
  },
  iconButtonRoot: {
    marginLeft: 4,
    marginRight: 4,
    color: theme.palette.grey[500],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 8,
      marginRight: 8,
    },
  },
  textLg: {
    fontSize: 18,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  colorTextPrimary: {
    color: theme.palette.primary.main,
  },
  underlineNone: {
    textDecoration: 'none',
  },
  textGrey: {
    color: theme.palette.grey[500],
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const MyTextField = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
    />
  );
};

const validationSchema = yup.object({
  name: yup.string().required('Name required'),
});

interface EditTagFormProps {
  open: boolean;
  onClosed: () => void;
  tg: TagGroup | null;
  onSubmit: (name: string, bannerText: string, variableNameForInput: string) => void;
}

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const EditTagForm: React.FC<EditTagFormProps> = (props) => {
  let userObj: any = {};
  userObj = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  const dispatch = useDispatch();
  const classes = useStylesTags(props);
  const [modalStyle] = React.useState(getModalStyle);
  const [name, setName] = useState<string>('');
  const [bannerTextEditForm, setBannerTextEditForm] = useState<string>('');
  const [variableNameForInput, setVariableNameForInput] = useState<string>('');

  useEffect(() => {
    setName(props.tg?.name || '');
    setBannerTextEditForm(props.tg?.bannerText || '');
    setVariableNameForInput(props.tg?.variableNameForInput || '');
  }, [props.tg]);

  return (
    <>
      {props.open && (
        <Modal
          open={props.open}
          onClose={props.onClosed}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'>
          <div style={modalStyle} className={classes.paper}>
            <Box flex={1} display='flex' flexDirection='column'>
              <Box
                px={{ xs: 6, sm: 10, xl: 15 }}
                pt={8}
                flex={1}
                display='flex'
                flexDirection='column'>
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    name: name,
                    bannerText: bannerTextEditForm,
                    variableNameForInput: variableNameForInput,
                    jumpToTagId: props.tg?.jumpToTagId
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting }) => {
                    setSubmitting(true);
                    props.onSubmit(data.name, data.bannerText, data.variableNameForInput);
                    setSubmitting(false);
                  }}>
                  {({ isSubmitting }) => (
                    <Form
                      className={classes.formRoot}
                      noValidate
                      autoComplete='off'>
                      <Box mb={{ xs: 3, lg: 4 }}>
                        <MyTextField
                          value={name}
                          label='Step name'
                          name='name'
                          variant='outlined'
                          className={classes.myTextFieldRoot}
                        />
                      </Box>

                      <Box mb={{ xs: 3, lg: 4 }}>
                        <MyTextField
                          value={bannerTextEditForm}
                          label='Banner text'
                          name='bannerText'
                          variant='outlined'
                          multiline
                          className={classes.myTextFieldRoot}
                        />
                      </Box>

                      <Box mb={{ xs: 3, lg: 4 }}>
                        <MyTextField
                          value={variableNameForInput}
                          label='Ask for variable input'
                          name='variableNameForInput'
                          variant='outlined'
                          className={classes.myTextFieldRoot}
                        />
                      </Box>

                      {/* <Box mb={{ xs: 3, lg: 4 }}>
                        <MyTextField
                          value={props.tg?.variableNameForInput}
                          label='Jump to Tag by default'
                          name='jumpToTagId'
                          variant='outlined'
                          className={classes.myTextFieldRoot}
                        />
                      </Box> */}

                      <Box
                        mb={6}
                        display='flex'
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        alignItems={{ sm: 'center' }}
                        justifyContent={{ sm: 'space-between' }}>
                        <Button
                          variant='contained'
                          color='secondary'
                          type='submit'
                          disabled={isSubmitting}
                          className={classes.btnRoot}>
                          Save
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>

              <InfoView />
            </Box>
          </div>
        </Modal>
      )}
    </>
  );
};


const LittleButton = (props: { title: any; onClickFunc: any; }) => {
  let { title, onClickFunc } = props
  return (<Button
    variant='outlined'
    color='secondary'
    size='small'
    style={{ height: '30px' }}
    type='button'
    disableElevation={true}
    onClick={onClickFunc}
  // {() => {
  //   // showcaseContext.addTagsToEmptyShowcase(Array.from(spaceTags.keys()));
  //   // spaceModels.size > 0 && Simulation.instance.filterVisibleNodes(Array.from(spaceModels.keys()));
  //   handleShowAllSpaceTags();
  // }}
  >
    {title}
  </Button>)
}

export function goToZoom(tagGroup: TagGroup) {
  let mpSdk = MpSdk.Instance.mps;
  mpSdk && tagGroup && tagGroup.sweepAndPose && tagGroup.sweepAndPose.zoom &&
    (tagGroup.sweepAndPose.zoom?.level !== currentZoom?.level) &&
    mpSdk.Camera.zoomTo(tagGroup.sweepAndPose.zoom.level)
      .catch((e: any) => console.error(`Error step/cant-move-to-zoom: ${e}`))
}

export async function goToPose(tagGroup: TagGroup) {
  let mpSdk = MpSdk.Instance.mps;
  // console.log(`[st] moving to ${JSON.stringify( sweep1)} ${pose1.rotation}`)
  // let mpSdk = await showcaseContext.initMpSdk();
  // if (currentSweep.sid !== )
  if (mpSdk && tagGroup && tagGroup?.sweepAndPose) {
    // console.log(`[st] currentSweep:  ${JSON.stringify(currentSweep.sid)} --- ${JSON.stringify(currentSweep.rotation)} ----- `);

    // console.log(`[st] tg:  ${JSON.stringify(tagGroup.sweepAndPose.sid)} --- ${JSON.stringify(tagGroup?.sweepAndPose.pose.rotation)} ----- `);
    let currentSweep = await getCurrentSweep();
    if (currentSweep?.sid !== tagGroup.sweepAndPose.sid) { // different sweeps, move, rotate and zoom
      mpSdk.Sweep.moveTo(tagGroup?.sweepAndPose.sid, { rotation: tagGroup?.sweepAndPose?.pose?.rotation, transition: SweepTransition.FLY }).then(() => {
        goToZoom(tagGroup);
      })
        .catch((e: any) => console.error(`Error step/cant-move-to-pose: ${e}`));

    } else if (currentSweep?.rotation !== tagGroup.sweepAndPose.rotation) { //same sweep, check rotation
      mpSdk.Camera.setRotation(tagGroup.sweepAndPose.rotation).then(goToZoom(tagGroup))
        .catch((e: any) => console.error(`Error step/cant-move-to-rotation: ${e}`));

    } else { //same sweep, same rotation, check zoom

      goToZoom(tagGroup);
    }
  }

}


// export const focusOnTagGroup = (tg: any, authUser: any, dispatch: any, isForced = false, currentTagGroupId: string) => {
//   // setBannerTextBeingEdited(tg.bannerText)
//   if (isForced) {
//     dispatch(setCurrentTagGroupId(tg.id));
//     // showTagsAnd3DForStep(tg.id)
//   } else {
//     currentTagGroupId !== tg.id && dispatch(setCurrentTagGroupId(tg.id));
//   }
//   // dispatch(logUserActivity('CLICK', 'TagGroup', tg.id));
//   dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tg.id));

// };

