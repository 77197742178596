import { AppActions } from '../../types';
import {
  FORGET_PASSWORD_EMAIL,
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  AWAITING_VERIFICATION
} from '../../types/actions/Auth.actions';
import { AuthUser } from '../../types/models/AuthUser';

const INIT_STATE: {
  authUser: AuthUser | null;
  token: string | null;
  message_data: string | null;
  awaiting_verfication: boolean;
}
  = {
  authUser: null,
  token: null,
  message_data: null,
  awaiting_verfication: false
};

const Auth = (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return action.payload == null ? //on signout - clear states
      INIT_STATE
        :
        {
          ...state,
          authUser: action.payload
        }
    }
    case AWAITING_VERIFICATION: {
      return {
        ...state,
        awaiting_verfication: action.payload,
      };
    }
    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        authUser: null,
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        message_data: action.payload,
      };
    }
    default:
      return state;
  }
};
export default Auth;
