import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from '../../../../../../../../@crema/core/GridContainer';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TwitterPicker } from 'react-color';
import Radio from '@material-ui/core/Radio';
import { fetchError, onAddProjectLabel, onEditProjectLabel, onGetProjectDetail } from 'redux/actions';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
// import { firestore, storage } from '@crema/services/auth/firebase/firebase';
// import { firestore, storage } from '@crema/services/auth/firebase/firebase';
import IntlMessages from '@crema/utility/IntlMessages';
import {
  HomeRoutes,
  IQuizDetails,
  ShowcaseTag,
  SpaceData,
  TAG_UPLOAD_TYPE,
} from '../../../../../../../../types/models/home/HomeApp';
import {
  ProjectObj,
  TaskSectionObj,
  AttachmentObj,
  TaskObj,
  LabelObj
} from '../../../../../../../../types/models/apps/ProjectBoard';
import { Chip } from '@material-ui/core';
import QuizTagForm from './QuizTagForm';
import InfoTagForm from './InfoTagForm';
import { ReactComponent as MediaIcon } from '../../../../../../assets/images/video-file.svg';
// import { validateEmail, validateUrl } from '@crema/utility/Utils';
// import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { mediaFileType, validateEmail, validateUrl } from '@crema/utility/Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOpenTagForm,
  setEditShowcaseTagId,
  addToSpaceTags,
  // setTagSidBeingAdded,
} from 'redux/actions/Home';
import { AppState } from 'redux/store';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { RichEditorExample } from './RichTextEditor';
import Draft from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ReactDOMServer from 'react-dom/server';
import { getFirestoreLink, initiateTag, makeQuizHtml, makeTaskTagHtml, mediaTypeFinder, wrapTagHtml } from 'modules/home/SpaceDetail/utils';
import CardAttachments from '../../../../../../../apps/ProjectBoard/ProjectDetail/List/AddCard/CardAttachments';
import Attachments from './Attachments';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import { Box, Grid, IconButton } from '@material-ui/core';
import { ANNOTATION_TYPE } from "types/models/home/HomeApp";
import clsx from 'clsx';
import { ShowcaseContext } from 'modules/home/SpaceDetail/SpaceView';
import { Scrollbar } from '@crema';
import { useLocation } from 'react-router';
import AddTaskCard from 'modules/apps/ProjectBoard/ProjectDetail/List/AddCard';
import { FileUploader } from '@crema/core/FileUploader';
import EmbedlyService from '@crema/services/EmbedlyService';
// import Api from '@crema/services/ApiConfig';
// import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { useOnClickOutside } from '@crema/utility/AppHooks';
import { defaultTaskLabelColor } from 'shared/constants/AppConst';
import Api from '@crema/services/ApiConfig';
import { firestore, storage } from '@crema/services/auth/firebase/firebase';
import MpSdk from 'mp/MpSdk';
import { fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import { addToCurrentTagsSidMapGlobal, setIsPlacingTag, setTagSidBeingAdded, tagSidBeingAdded } from '../ShowcaseUtils';
// import { LabelColorFromTag } from 'shared/constants/AppConst';
import htmlToDraft from 'html-to-draftjs';
import { SET_IS_ADDING_TAG, SET_SPACE_DATA } from 'types/actions/Home.action';
import Spacer from '@crema/core/Spacer';
// import Api from '@crema/services/ApiConfig';
// import { fetchError, fetchError, fetchStart, fetchSuccess } from 'redux/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    annotationTypeHeading: {
      fontSize: '14px',
    },
    annotationTypeBody: {
      display: 'flex',
      flexDirection: 'row',
    },
    dialogBox: {
      position: 'relative',
      // width: 700,
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 700,
        width: '100%',
      },
      '& .MuiInputLabel-root':{
        fontSize: '14px'

      }
    },
    scrollRoot: {
      maxHeight: 595,
    },
    dialogActionRoot: {
      // display: 'flex',
      width: '100%',
      padding: '8px 20px',
    },
    mediaIcon: {
      cursor: 'pointer',
    },
    highlightMediaIcon: {
      cursor: 'pointer',
      fill: '#F04F47',
    },
    mediaFormRoot: {
      // position: 'absolute',
      // bottom: '0px',
      // left: '0px',
      // zIndex: 1000000111,
      width: '100%',
      background: '#fff',
      padding: '10px 0px',
      textAlign: 'right',
    },
    cpPopover: {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 11,
    },
    mediaForm: {
      border: '1px solid rgb(204, 204, 204)',
      borderRadius: '2px',
      padding: '8px',
    },
    embedlyOEmbededRoot: {
      height: '400px',
      width: '100%',
    },
    iconButton: {
      // '&$width': '0.5em',
      sizeSmall: {
        width: '0.5em',
        height: '0.5em',
      },
      // width: 50,
      // height: "100%",
      backgroundColor: 'transparent',
      paddingTop: '4px',
      // paddingBottom: '1px',
      color: theme.palette.secondary.main,
      '&:hover': {
        background: 'transparent',
      },
    },
    labelDropdown: {
      marginTop: '10px',

          '& .MuiOutlinedInput-notchedOutline': {
            borderTopWidth: "0px",
            borderLeftWidth: "0px",
            borderRightWidth: "0px",
            // borderRadius: "3px",
            '& :focus':{
              borderRadius: "1px",
            }
          },
          '.MuiInputBase-input:focus': {
            borderRadius: "1px",
          }
          // borderTopWidth: "0px"
        }

  }),
);

interface Props {
  // onAddTag(
  //   tagLabelInput: string,
  //   tagDescInput: string,
  //   annotationType: string,
  //   quizDetails: IQuizDetails,
  //   taskTag: ITaskTagForm | null,
  //   tagHtml: string,
  //   mediaLinkInput: string,
  //   attachments: AttachmentObj[],
  //   showTextToSpeech: boolean,
  // ): void;
  // tagSid: any;
  // onEditTag(
  //   tagLabelInput: string,
  //   tagDescInput: string,
  //   annotationType: string,
  //   quizDetails: IQuizDetails,
  //   taskTag: ITaskTagForm | null,
  //   tagHtml: any,
  //   editTagDetails: null | ShowcaseTag,
  //   mediaLinkInput: string,
  //   attachments: AttachmentObj[],
  //   showTextToSpeech: boolean,
  // ): void;
  // setTagSidBeingAdded(val: any): void;
}

export interface ITaskTagForm {
  project: ProjectObj;
  list: TaskSectionObj;
  selectedTask: TaskObj;
}

interface newLabelObj{
  name: string;
  id: number;
  color: string;
  inputValue?: string;
}

const filterHigh = createFilterOptions<newLabelObj>();
export default function AddTagForm({
  // onAddTag,
  // tagSid,
  // onEditTag,
  // setTagSidBeingAdded,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const showcaseContext = useContext(ShowcaseContext);
  const labelPopupRef = useRef<HTMLHeadingElement>(null)

  // NewWork //
//   const { openTagForm, editShowcaseTagId, currentShowcaseTags, currentSpace } = useSelector<AppState, AppState['home']>(
//     ({ home }) => home
//   );
// const projectDetail = useSelector<AppState, ProjectObj | null>(
//   ({projects}) => projects.projectDetail
// );
  const [visible, setVisibility] = useState(false);
  const [showMediaLink, setShowMediaLink] = useState(false);
// NewWork End //

  // const userId = useSelector<AppState, AppState['auth']]>(({ auth }) => auth);

  // const isAddingTag = useSelector<AppState, boolean>(({ home }) => home.isAddingTag);
  const userId = useSelector<AppState, string>(({ auth }) => auth.authUser?.uid || '');

  const projectDetail = useSelector<AppState, ProjectObj | null>(
    ({projects}) => projects.projectDetail
  );

  // const { openTagForm, editShowcaseTagId, currentShowcaseTags, currentSpace, spaceProjectList } = useSelector<AppState, AppState['home']>(
  //   ({ home }) => home,
  //   shallowEqual
  // );

  const openTagForm = useSelector<AppState, boolean>(
    ({ home }) => (home.openTagForm)
  );

  // const tagSidBeingAdded = useSelector<AppState, string>(
  //   ({ home }) => (home.tagSidBeingAdded)
  // );

  // const openTagForm = useSelector<AppState, boolean>(
  //   ({ home }) => (console.log(`[st] checking openTagForm`), home.openTagForm)
  // );

  // const spaceProjectList = useSelector<AppState, ProjectObj[]>(
  //   ({ home }) => (console.log(`[st] checking spaceProjectList`), home.spaceProjectList)
  // );

  // const editShowcaseTagId = useSelector<AppState, string>(
  //   ({ home }) => home.editShowcaseTagId
  // );

  const currentSpaceDid = useSelector<AppState, string>(
    ({ home }) => home.currentSpace?.did || ''
  );

  const currentSpaceId = useSelector<AppState, string>(
    ({ home }) => home.currentSpace?.id || ''
  );

  const space = useSelector<AppState, SpaceData | undefined>(
    ({ home }) => home.currentSpace,
  );

  const tagBeingEdited = useSelector<AppState, ShowcaseTag | undefined>(
    ({ home }) => home.editShowcaseTagId ? home.spaceTags.get(home.editShowcaseTagId) : undefined
  );

  // console.log(`[st] ${tagBeingEdited}`)
  // const openTagForm = useSelector<AppState, boolean>(
  //   ({ home }) => home.openTagForm
  // );

  const [showMediaLinkInput, setShowMediaLinkInput] = useState(false);
  const [showTextToSpeech, setShowTextToSpeech] = useState(false);
  const [saveBtnClick, setSaveBtnClick] = useState(false);
  const [annotationType, setAnnotationType] = React.useState<string>(ANNOTATION_TYPE.INFO);
  const [quizDetails, setQuizDetails] = React.useState<IQuizDetails>({
    question: '',
    options: [],
    answer: [],
    optionsCount: 0
  });
  const [attachments, setAttachments] = useState<AttachmentObj[]>(() => []);
  const [attachmentsUrls, setAttachmentsUrls] = useState<string[]>(() => []);
  const [tagLabelInput, setTagLabelInput] = React.useState('');
  const [tagDescInput, setTagDescInput] = React.useState('');
  const [mediaLinkInput, setMediaLinkInput] = React.useState('');
  // const [mediaLink, setMediaLink] = React.useState('');
  // const [oEmbedlyHtml, setOEmbedlyHtml] = React.useState<string>('');
  // const [editTagDetails, setEditTagDetails] = React.useState<any>(null);
  // const [editTagDetails, setEditTagDetails] = React.useState<any>(null);
  // const [mediaLinkInput, setMediaLinkInput] = React.useState('');
  const [uploadType, setUploadType] = React.useState<string>('');
  // const [oEmbedlyHtml, setOEmbedlyHtml] = React.useState<string>('');
  // const [editTagDetails, setEditTagDetails] = React.useState<any>(null);
  const [selectedTaskTag, setSelectedTaskTag] = React.useState<TaskObj | null>(null);
  const [editorState, setEditorState] = useState<Draft.EditorState>(
    EditorState.createEmpty(),
  );
  const [selectedLabels, setSelectedLabels] = useState<LabelObj[]>([]);
  // Task States
  const [list, setList] = useState<TaskSectionObj | null>(null);
  const [selectedLabelId, setSelectedLabelId] = useState<number|undefined>(undefined);


  // useEffect(() => {
  //   !showMediaLink&&mediaLinkInput&& loadEmbedlyOEmbeded(mediaLinkInput);
  // },[mediaLinkInput,showMediaLink]);
  //   dispatch(onGetPeopleList(currentSpace?.id, authUser));
  // }, [dispatch]);

  // useEffect(() => {
  //   (async () => {
  //     // const path = pathname.split('/');
  //     // const did = path[path.length - 1];
  //     currentSpaceDid && await dispatch(onGetSpaceProjectList(currentSpaceDid));
  //     // dispatch(onGetProjectLabelList(did));
  //     // dispatch(onGetMemberList(did));
  //   })();
  // }, [
  //   // dispatch,
  //   pathname]);

  // useEffect(() => {
  //   const project: ProjectObj | null = spaceProjectList.find((f) => (f.name.includes("Default Project"))) || null;
  //   project?.did && dispatch(onGetProjectDetail(project.did));
  // }, [
  //   // dispatch,
  //   spaceProjectList]);

  useEffect(() => {
    // let visibleSpaceTags: ShowcaseTag[] = Array.from(currentShowcaseTags);
    // let tag: ShowcaseTag | undefined = visibleSpaceTags.find((el: ShowcaseTag) => editShowcaseTagId === el.id);
    // console.log("handleEditTag",editShowcaseTagId,tag);

    // console.log("tagBeingEdited ",tagBeingEdited);
    if (tagBeingEdited) {
      const {
        annotationType = '',
        data: { description, label, sid, media },
        id,
        quizDetails,
        taskTag,
        showTextToSpeech,
        tagHtml,
        uploadType,
        labelIds,
      }: any = tagBeingEdited;
      setAnnotationType(annotationType);
      setShowTextToSpeech(showTextToSpeech ? true : false);
      quizDetails && setQuizDetails(quizDetails);
      setTagDescInput(description);
      setTagLabelInput(label);
      // setEditTagDetails(tag);
      // setTagSidBeingAdded(sid);
      setMediaLinkInput(media.src);
      // setEditTagDetails(tagBeingEdited);

      // setTagSidBeingAdded(sid);
      // dispatch(setTagSidBeingAdded(null));
      setTagSidBeingAdded(null);
      setIsPlacingTag(false);

       setSelectedTaskTag(taskTag);
       setSelectedLabels((labelIds||[]).map((labelId:number)=>(projectDetail?.labelList.find(label=>(label.id===labelId)))));
      if(uploadType===TAG_UPLOAD_TYPE.ATTACHMENT) {
        setAttachments([{
        id:'',
        preview:media.src,
        file: {
          path: media.src,
          name: (media?.src||'').substring(media?.src.lastIndexOf('/')+1),
          lastModified: 0,
          lastModifiedDate: '',
          // type:(media?.src||'').split('.').pop(),
          type: mediaFileType((media?.src||'').split('.').pop()),
        },
      }]);
      setUploadType(TAG_UPLOAD_TYPE.ATTACHMENT);
    } else if (uploadType===TAG_UPLOAD_TYPE.LINK){
      setMediaLinkInput(media.src);
      // loadEmbedlyOEmbeded(media.src);
      setUploadType(TAG_UPLOAD_TYPE.LINK);
      setAttachments([]);
    }
      const { contentBlocks } = convertFromHTML(tagHtml || '');
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocks)
      ));

    }
// <<<<<<< HEAD
  }, [tagBeingEdited?.id]);

  // const loadIframely = () => {
  //   let _window: any = window;
  //   _window.iframely && _window.iframely.load();
  // };

  useOnClickOutside(labelPopupRef, () => setVisibility(false));

  const colorLabel = ()=>(
    visible ? (
       <Box className={classes.cpPopover}>
         <TwitterPicker
           // color={primary}
           onChange={color => {
             setVisibility(!visible);
             let index=selectedLabels.findIndex((lab)=>(lab.id===selectedLabelId));
             let label={...selectedLabels[index],color:color.hex};
             selectedLabels[index].color=color.hex;
             projectDetail?.id && dispatch(onEditProjectLabel(projectDetail?.id,label))
           }}
         />
       </Box>
     ) : null
 );
// =======
//   }, [editShowcaseTagId]);

//   const loadEmbedlyOEmbeded = async(mediaSrc:string) => {
//     const embedlyOembed:any = await EmbedlyService.embedlyOembed(mediaSrc);
//     embedlyOembed.html&&setOEmbedlyHtml(embedlyOembed.html);
//   };
// >>>>>>> s14/arp/simplifySidebars

  const handleAnnotationType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnnotationType(event.target?.value);
  };

  const renderSelectCategoryForm = () => {
    return (
      <FormControl component='fieldset'>
        <Spacer y={2}/>
        <FormLabel component='legend' className={classes.annotationTypeHeading}>
          Tag Type
        </FormLabel>
        <RadioGroup
          aria-label='annotationType'
          name='info'
          className={classes.annotationTypeBody}
          value={annotationType}
          onChange={(e) => {
            handleAnnotationType(e);
            setSaveBtnClick(false);
          }}>
          <FormControlLabel
            value={ANNOTATION_TYPE.INFO}
            disabled={tagBeingEdited?.annotationType ? true : false}
            control={<Radio />}
            label='Info'
          />
          <FormControlLabel
            value={ANNOTATION_TYPE.QUIZ}
            disabled={tagBeingEdited?.annotationType ? true : false}
            control={<Radio />}
            label='Quiz'
          />
          <FormControlLabel
            value={ANNOTATION_TYPE.TASK}
            disabled={tagBeingEdited?.annotationType ? true : false}
            control={<Radio />}
            label='Task'
          />
        </RadioGroup>
      </FormControl>
    );
  };

  // const getOEmbededHtml = () => {
  //   return {
  //     __html: oEmbedlyHtml?oEmbedlyHtml:'',
  //   };
  // };

  // const renderEmbedlyOEmbeded = () => {
  //   return (
  //     <div
  //       // className={classes.embedlyOEmbededRoot}
  //       dangerouslySetInnerHTML={getOEmbededHtml()}
  //     />
  //   );
  // };
  const renderMediaForm = () => {
    return (
      <div className={classes.mediaFormRoot}>
        <div className={classes.mediaForm}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
// <<<<<<< HEAD
              if (mediaLinkInput && validateUrl(mediaLinkInput)) {

                // setShowMediaLink(false);
                // setAttachments([]);

                // loadEmbedlyOEmbeded(mediaLinkInput)
                // setMediaLinkInput('');
              }
// =======
//               // if (mediaLinkInput && validateUrl(mediaLinkInput)) {
//               //   // setShowMediaLinkInput(false);
//               //   setAttachments([]);
//               //   loadEmbedlyOEmbeded(mediaLinkInput)
//               //   // setMediaLinkInput('');
//               // }
// >>>>>>> s14/arp/simplifySidebars
            }}>
            <TextField
              margin='dense'
              required
              id='mediaLinkInput'
              label='Media Link'
              placeholder={'https://'}
              fullWidth
              // value={mediaLinkInput.split(/[\\\/]/).pop()}
              value={mediaLinkInput}
              onChange={(e) => {
                if(attachments.length){
                   dispatch(fetchError("You can either add media as a link or upload a file. Please remove the uploaded file to add a media link"));
                } else{
                  setMediaLinkInput(e.target.value);
                  setUploadType(TAG_UPLOAD_TYPE.LINK);
                  setAttachments([]);
                }
              }}
              error={mediaLinkInput !== '' && !validateUrl(mediaLinkInput)}
              helperText={
                mediaLinkInput !== '' &&
                !validateUrl(mediaLinkInput) &&
                'Incorrect media link.'
              }
            />
            <Button
            onClick={() => { setMediaLinkInput('');setUploadType('');}}
            variant='contained'
            color='secondary'>
              Clear
            </Button>
            <Button
              variant='contained'
              color='secondary'
              style={{marginLeft: '8px', marginRight: '8px'}}
              type='submit'>
              Apply
            </Button>
          </form>
        </div>
      </div>
    );
  };

  const renderLabelDropdown = () => (
     <Autocomplete
       multiple
       className={classes.labelDropdown}
       id='tags-outlined'
       options={projectDetail?.labelList||[]}
       selectOnFocus
       clearOnBlur
       handleHomeEndKeys
       value={selectedLabels}
       openOnFocus
       getOptionSelected={(option, value) => option.name === value.name}
       getOptionLabel={(option:newLabelObj) => {
         // e.g value selected with enter, right from the input
         if (typeof option === 'string') {
           return option;
         }
         if (option.inputValue) {
           return  option.inputValue.trim();
         }
         return option.name
       }}
       onChange={(event, value:newLabelObj[]) => {
         if (typeof value === 'string') {
           // timeout to avoid instant validation of the dialog's form.
           setTimeout(() => {
             setSelectedLabels(value);
           });
         } else if (value && value[value.length?value.length-1:0]?.inputValue) {
           setSelectedLabels(value);
           let label=value[value.length?value.length-1:0];
           let labelObj={
             id: label.id,
             name: label.name,
             color: label.color
           }
           projectDetail?.id && dispatch(onAddProjectLabel(projectDetail?.id, labelObj));
         } else {
           setSelectedLabels(value);
         }
       }}
       renderOption={(option) => (!option.inputValue ? <Chip label={option.name} style={{background: option.color}} /> : <Box>{option.inputValue}</Box>)}
       filterSelectedOptions
       renderInput={params => (
         <TextField
           {...params}
           variant='outlined'
           label={<IntlMessages id='common.label' />}
           fullWidth
         />
       )}
       renderTags={(value, getTagProps) =>
         <div style={{position:'relative',zIndex:10}} ref={labelPopupRef}>
           {colorLabel()}
           {value.map((option, index) => (
             <Chip
             label={option.name}
             style={{background:option.color}}
             {...getTagProps({ index })}
             onClick={() => {
               setVisibility(!visible);
               setSelectedLabelId(option.id);
             }}
             onDelete={()=>{
               setSelectedLabels(selectedLabels.filter((lab)=>lab.id!=option.id));
               // selectedTask&&dispatch(onDeleteProjectLabel(project.id,selectedTask.id,option.id));
             }}
             />
           ))}
         </div>
       }

       filterOptions={(options, params) => {
         const toFilter = filterHigh(options, params);
         if (params.inputValue !== '') {
           let newLabel:newLabelObj={
             inputValue:`Create new label "${params.inputValue}"`,
             name:`${params.inputValue}`,
             id: (projectDetail?.labelList||[]).length,
             color: defaultTaskLabelColor,
           }
           toFilter.push(newLabel);
         }
         return toFilter;
       }}
     />
  )

  const renderFormBody = () => {
    switch (annotationType) {
      case ANNOTATION_TYPE.QUIZ:
        return (
          <>
          <QuizTagForm
            quizDetails={quizDetails}
            setQuizDetails={setQuizDetails}
            saveBtnClick={saveBtnClick}
          />
          {renderLabelDropdown()}
          {/* {<p style={{ margin: '10px 2px' }}>Additional text </p>}
          {<RichEditorExample initialValue={editorState} saveDraftHtml={setEditorState} />} */}
          </>
        );
      case ANNOTATION_TYPE.INFO:
        return (
          <>
            <InfoTagForm
              saveBtnClick={saveBtnClick}
              tagLabelInput={tagLabelInput}
              tagDescInput={tagDescInput}
              setTagLabelInput={setTagLabelInput}
              setTagDescInput={setTagDescInput}
            />
            {renderLabelDropdown()}
            {/* {<p style={{ margin: '10px 2px' }}>Additional text </p>}
            {<RichEditorExample initialValue={editorState} saveDraftHtml={setEditorState} />} */}
            {/* {oEmbedlyHtml && renderEmbedlyOEmbeded()} */}
            {showMediaLink && renderMediaForm()}
          </>
        );
      case ANNOTATION_TYPE.TASK:
        return !projectDetail ? null :
          <AddTaskCard
            list={list!}
            project={projectDetail}
            selectedTask={selectedTaskTag}
            onCloseAddTask={() => {
              handleCancelTagForm();
              handleClosedForm();
            }}
            submitTask={(taskTag) => {
              handleSubmitForm(taskTag);
            }}
            annotationType={annotationType}
          // saveBtnClick={saveBtnClick}
          />
      default:
        return null;
    }
  };

  // function taskTagReactHtml() {
  //   return !(currentSpace && currentSpace.currentSpaceProject) ? null :
  //     ReactDOMServer.renderToString(
  //       <AddTaskCard
  //         list={list!}
  //         project={currentSpace.currentSpaceProject}
  //         selectedTask={selectedTaskTag}
  //         onCloseAddTask={() => {
  //           showcaseContext.handleCancelTagForm();
  //           handleClosedForm();
  //         }}
  //         submitTask={(taskTag) => {
  //           handleSubmitForm(taskTag);
  //         }}
  //         annotationType={annotationType}
  //       // saveBtnClick={saveBtnClick}
  //       />,
  //     );
  // }

  const handleClosedForm = () => {

    setTagSidBeingAdded(null);
    setIsPlacingTag(false);
    dispatch({ type: SET_IS_ADDING_TAG, payload: false });
    dispatch(setOpenTagForm(false));
    dispatch(setEditShowcaseTagId(''));
    // setShowMediaLinkInput(false);
    setMediaLinkInput('');
    setUploadType('');
    // setOEmbedlyHtml('');
    setSaveBtnClick(false);
    setShowTextToSpeech(false);
    setAnnotationType(ANNOTATION_TYPE.INFO);
    setTagLabelInput('');
    setTagDescInput('');
    setQuizDetails({ question: '', options: [], answer: [], optionsCount: 0 });
    setAttachments([]);
    // setEditTagDetails(null);
    setSelectedTaskTag(null);
    setEditorState(EditorState.createEmpty());
    setSelectedLabels([]);
    setSelectedLabelId(undefined);

  };

   const handleCancelTagForm = async () => {
    // let tag = tagSidBeingAdded;//FIXME
    tagSidBeingAdded && MpSdk.Instance.mps.Mattertag.remove(tagSidBeingAdded);
    setTagSidBeingAdded(null);
    setIsPlacingTag(false);
    if(!!tagBeingEdited?.id) {
      MpSdk.Instance.mps.Mattertag.remove(tagBeingEdited?.id).catch((err: any) => "Couldn't delete tag after cancelling add/ edit");
      dispatch(setEditShowcaseTagId(''));
    }
  };
  const handleSubmitForm = (taskTag: TaskObj|null = null) => {
    if (!tagBeingEdited) handleAddTagForm(taskTag);
    else handleEditTagForm(taskTag);
  };

  const handleAddTagForm = async (taskTag: TaskObj | null = null) => {
    // console.log(
    //   `[st] submitting task form ${JSON.stringify(
    //     taskTag,
    //   )} tagLabelInput, tagDescInput, annotationType, quizDetails, taskTag : ${tagLabelInput}, ${tagDescInput}, ${annotationType}, ${quizDetails}, ${taskTag}`,
    // );

    let tagHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
// <<<<<<< HEAD
//     const labelIds = selectedLabels.map((label)=> label.id);

//     if (annotationType === ANNOTATION_TYPE.TASK) {
//       await onAddTag2(
//         taskTag?.title || '',
//         taskTag?.desc || '',
//         annotationType,
//         quizDetails,
//         taskTag,
//         tagHtml,
//         mediaLinkInput,
//         attachmentsUrls,
//         showTextToSpeech,
//         labelIds
//       );
//     } else if (annotationType === ANNOTATION_TYPE.QUIZ) {
//       await onAddTag2('Quiz', '', annotationType, quizDetails, taskTag, tagHtml, mediaLinkInput, attachmentsUrls, showTextToSpeech,labelIds);
//     } else {
//       await onAddTag2(
//         tagLabelInput,
//         tagDescInput,
//         annotationType,
//         quizDetails,
//         taskTag,
//         tagHtml,
//         mediaLinkInput,
//         attachmentsUrls,
//         showTextToSpeech,
//         labelIds
//       );
//     }
//     handleClosedForm();
// =======
    let _mediaLink = (attachments.length ? attachments[0]?.preview : mediaLinkInput);
    let media = { type:'', src:'' };
    const labelIds = selectedLabels.map((label)=> label.id);

      try {

        if(_mediaLink){
          const embedlyExtract:any = await EmbedlyService.embedlyExtract(_mediaLink);
          if(!embedlyExtract.media?.type){
            throw new Error(`Can't find any media to show. Please check the URL.`);
          }

          const mediaType = (embedlyExtract.media?.type || '');
          media.type = `mattertag.media.${mediaType}`;
          media.src = _mediaLink;
        }

        if (annotationType === ANNOTATION_TYPE.TASK) {
          await onAddTag2(
            taskTag?.title || '',
            taskTag?.desc || '',
            annotationType,
            quizDetails,
            taskTag,
            tagHtml,
            media,
            attachmentsUrls,
            showTextToSpeech,
            uploadType,
            labelIds
          );
        } else if (annotationType === ANNOTATION_TYPE.QUIZ) {
          await onAddTag2('Quiz', '', annotationType, quizDetails, taskTag, tagHtml, media,attachmentsUrls, showTextToSpeech,uploadType, labelIds);
        } else {
          await onAddTag2(
            tagLabelInput,
            tagDescInput,
            annotationType,
            quizDetails,
            taskTag,
            tagHtml,
            media,
            attachmentsUrls,
            showTextToSpeech,
            uploadType,
            labelIds
          );
        }
        handleClosedForm()

      } catch (error:any) {
        console.error(error);
        dispatch(fetchError(error.message));
      }
// >>>>>>> s14/arp/simplifySidebars
  };

  const handleEditTagForm = async (taskTag: TaskObj | null = null) => {
    // console.log(taskTag,editTagDetails, 'handleEditTagForm');
    let tagHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
// <<<<<<< HEAD
//     const labelIds = selectedLabels.map((label)=> label.id);

//     if (annotationType === ANNOTATION_TYPE.TASK) {
//       await onAddTag2(
//         taskTag?.title || '',
//         taskTag?.desc || '',
//         annotationType,
//         quizDetails,
//         taskTag,
//         tagHtml,
// mediaLinkInput,
// attachmentsUrls,
//         showTextToSpeech,
//         labelIds
//       );
//     } else if (annotationType === ANNOTATION_TYPE.QUIZ) {
//       await onAddTag2('Quiz', '', annotationType, quizDetails, taskTag, tagHtml, mediaLinkInput, attachmentsUrls, showTextToSpeech,labelIds);
//     } else {
//       await onAddTag2(
//         tagLabelInput,
//         tagDescInput,
//         annotationType,
//         quizDetails,
//         taskTag,
//         tagHtml,
// mediaLinkInput,
// attachmentsUrls,
//         showTextToSpeech,
//         labelIds
//       );
// =======
    let _mediaLink = (attachments.length ? attachments[0]?.preview : mediaLinkInput);
    let media = { type:'', src:'' };
    const labelIds = selectedLabels.map((label)=> label.id);

    try {

      if(_mediaLink){
        const embedlyExtract:any = await EmbedlyService.embedlyExtract(_mediaLink);
        if(!embedlyExtract.media?.type){
          throw new Error(`Can't find any media to show. Please check the URL.`);
        }

        const mediaType = (embedlyExtract.media?.type || '');
        media.type = `mattertag.media.${mediaType}`;
        media.src = _mediaLink;
      } else{
        media.type = `mattertag.media.none`;
        media.src = '';
      }

      if (annotationType === ANNOTATION_TYPE.TASK) {
        await onAddTag2(
          taskTag?.title || '',
        taskTag?.desc || '',
          annotationType,
          quizDetails,
          taskTag,
          tagHtml,
          media,
          attachmentsUrls,
          showTextToSpeech,
          uploadType,
          labelIds
        );
        dispatch(setEditShowcaseTagId(''))
      } else if (annotationType === ANNOTATION_TYPE.QUIZ) {
        await onAddTag2('Quiz', '', annotationType, quizDetails, taskTag, tagHtml, media,  attachmentsUrls, showTextToSpeech,uploadType,  labelIds);
      } else {
        await onAddTag2(
          tagLabelInput,
          tagDescInput,
          annotationType,
          quizDetails,
          taskTag,
          tagHtml,
          media,
          attachmentsUrls,
          showTextToSpeech,
          uploadType,
          labelIds
        );
      }
      handleClosedForm();

    } catch (error:any) {
      console.error(error);
      dispatch(fetchError(error.message));
// >>>>>>> s14/arp/simplifySidebars
    }
  };

// <<<<<<< HEAD
// // <<<<<<< HEAD
//   const onAddAttachments = async (files: any) => {
//     // if(attachments.length){
//     //   await deleteMediaFiles(attachments);
//     //   setAttachmentsUrls([]);
//     // }

//     let _attachmentsUrls: string[] = await uploadMediaFiles([...files]);
//     setAttachmentsUrls(_attachmentsUrls);

//     setAttachments([
//       // ...attachments,
//       ...files
//     ]);
//     setMediaLinkInput('');
//     setMediaLinkInput('');
//     setShowMediaLink(false);
//     // setOEmbedlyHtml('');
// // =======
// //   const onAddAttachments = (files: any) => {
// //     setAttachments([...attachments, ...files]);
// //     setMediaLinkInput('');
// //     setShowMediaLinkInput(false);
// // >>>>>>> s17/ahsan/rerenderTagFixes
// =======
  const onAddAttachments = async (files: any) => {
    if(mediaLinkInput!==''){
      dispatch(fetchError("You can either add media as a link or upload a file. Please remove the media link to upload"));
    } else{
      if(attachments.length){
        await deleteMediaFiles(attachments);
      }

      let _attachmentsUrls: string[] = await uploadMediaFiles([...files]);
      let file = files[0]?.file;

      var blob = file.slice(0, file.size, file.type);
      let newFile = new File([blob], (_attachmentsUrls?.[0]||'').substring(_attachmentsUrls?.[0].lastIndexOf('/')+1), {type: file.type});

      setAttachments([
        // ...attachments,
        {...files[0],preview:_attachmentsUrls?.[0],file:newFile}
      ]);
      setMediaLinkInput('');
      // setOEmbedlyHtml('');
      setUploadType(TAG_UPLOAD_TYPE.ATTACHMENT);
    }
// >>>>>>> s14/arp/simplifySidebars
  };

  const onClickDeleteIcon = () => { };

  const onDeleteAttachment = async (id: string) => {
    const updatedAttachments = attachments.filter(
      (attachment: AttachmentObj) => attachment.id !== id,
    );
    await deleteMediaFiles(attachments);
    setAttachments(updatedAttachments);
    setAttachmentsUrls([]);
  };

  // const saveDraftHtml = (editorState: Draft.EditorState) => setTagHtml(editorState);
  const uploadMediaFiles=async (attachments: AttachmentObj[])=>{
    dispatch(fetchStart());
    let tagDocRef = firestore.collection(`Spaces/${currentSpaceId}/tags`).doc();

    //logic to create urls for attachments
    let prefix = `Spaces/${currentSpaceDid}/Tags`;
    var storageRef = storage.ref(prefix);

    let attachmentsUrls: string[] = [];

    let promises: Promise<string>[] = [];
    promises = attachments.map((attachment, index) => {
      let file: any = attachment.file;

      let filename: any = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
      filename = `${tagDocRef.id}.${filename}`
      let imageRef = storageRef.child(filename);
      // let baseModelRef = storage.ref(`3d/${type}/${name}`).child(fileName);

      return imageRef
        .put(file)
        .then(() => {
          const imageURL = `https://storage.googleapis.com/virtual-tc.appspot.com/${prefix}/${filename}`; //imageRef.getDownloadURL();
          return imageURL;
        })
        .then(async (imageURL) => {
          attachmentsUrls.push(imageURL);
          //make model bucket/folder public
          try {
            let makeFilePublicResult = await Api.post('/admin/model-check', { "modelPath": `${prefix}/${filename}` });

            if (makeFilePublicResult.status === 200) {
              console.log(`[st] file made public: ${prefix}/${filename}`);
              // dispatch(showMessage("All Done!"));
              // dispatch({ type: GET_SPACES_LIST, payload: data.data });
            } else {
              console.error(`[st] error making file public: ${prefix}/${filename} : ${JSON.stringify(makeFilePublicResult)}`)
              dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
            }
          } catch (error) {
            console.error(`[st] error making file public: ${prefix}/${filename} : ${error}`)
            dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
          };

          return imageURL;
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return await Promise.all(promises).then((response)=>{  dispatch(fetchSuccess()); return response;})
}

const deleteMediaFiles=async (attachments: AttachmentObj[])=>{
    dispatch(fetchStart());
    //logic to create urls for attachments
    let prefix = `Spaces/${currentSpaceDid}/Tags`;
    var storageRef = storage.ref(prefix);

    let promises: Promise<string>[] = [];
    promises = attachments.map((attachment, index) => {

      let imageRef = storageRef.child(attachment.file.name);
      // let baseModelRef = storage.ref(`3d/${type}/${name}`).child(fileName);

      return imageRef
        .delete()
        .then(response => {
          dispatch(fetchSuccess());
          return response;
        })
        .catch((error) => {
// <<<<<<< HEAD
          console.error(`[st] error making file public: ${prefix}/${attachment.file.name} : ${error}`)
// =======
//           console.error(`[st] error: ${prefix}/${attachment.file.name} : ${error}`)
// >>>>>>> s14/arp/simplifySidebars
          dispatch(fetchError("Could not delete the file. Please refresh and try again"));
        });
    });

    return await Promise.all(promises);
}


    /*
  Save tag to the db, add to showcase and sidebars
   */
  const onAddTag2 = async (
    tagLabelInput: string,
    tagDescInput: string,
    annotationType: string,
    quizDetails: IQuizDetails,
    taskTag: TaskObj | null,
    tagHtml: string,
    media: {type: string, src: string},
    attachmentsUrls: string[],
    showTextToSpeech: boolean,
    uploadType:string,
    labelIds: number[]
  ) => {
    try {
      dispatch(fetchStart());
      // await initMpSdk();

// <<<<<<< HEAD
      // let tag = tagSidBeingAdded;
      // let tag = editShowcaseTagId;

      // let tagSid = tag[0];
      // await setMpSdk();
// =======
      let tagDocRef;
      let allTags;
      let tagAdded;
      let tagSid: string;

      if(tagBeingEdited){
// >>>>>>> origin/s17/ahsan/rerenderTagFixes

        tagDocRef = firestore.doc(`Spaces/${currentSpaceId}/tags/${tagBeingEdited.id}`);
        tagAdded = tagBeingEdited.data;
        tagSid = tagBeingEdited.data.sid;

      } else{

        let tag = tagSidBeingAdded;
        tagSid = tag[0];
        console.log(`[st] onAddTag2 tag is ${tagSid} `);

        tagDocRef = firestore.collection(`Spaces/${currentSpaceId}/tags`).doc();
        //delete tag just created
        allTags = await MpSdk.Instance.mps.Mattertag.getData();
        tagAdded = allTags.find((t: any) => t.sid === tagSid);

      }

      console.log(
        `[st] adding tag id  ${getFirestoreLink(
          `Spaces/${currentSpaceId}/tags/${tagDocRef.id}`,
        )}`,
      );

      if (!tagAdded) {
        console.error(
          `couldnt find ${!tagBeingEdited?.id ? "tagAdded" : "tagEdited"}. looking for ${tagSid} found in ${allTags.map(
            (x: any) => x.sid + ', ' + x.label,
          )}`,
        );
        throw new Error(`couldnt find ${!tagBeingEdited?.id ? "tagAdded" : "tagEdited"}`);
      }

      await MpSdk.Instance.mps.Mattertag.remove(tagAdded.sid);

      // let attachmentsUrls: any[] = await uploadMediaFiles(attachments);

      //add tag again (to avoid editBillboard)
      tagAdded.label = tagLabelInput;
      tagAdded.description = tagDescInput;

      if (annotationType === ANNOTATION_TYPE.INFO && media.src) {
        // let media = {
        //   type: mediaTypeFinder(MpSdk.Instance.mps.Mattertag.MediaType["NONE"]),
        //   src: '',
        // };
        // if((attachmentsUrls?.[0]||mediaLinkInput)){
        //   let mediaSrc=attachmentsUrls.length?attachmentsUrls[0]:mediaLinkInput;

        //   const embedlyExtract:any = await EmbedlyService.embedlyExtract(mediaSrc);
        //   const mediaType = (embedlyExtract?.media.type || '').toUpperCase();
        //   media = {
        //     type: mediaTypeFinder(MpSdk.Instance.mps.Mattertag.MediaType[mediaType]),
        //     src: mediaSrc,
        //   };
        // }
        // tagAdded.media = media;
        tagAdded.media.type = media.type;
                tagAdded.media.src = media.src;
      }
      let newTagSid = (await MpSdk.Instance.mps.Mattertag.add(tagAdded))[0];

      //create showcaseTag to save
      tagAdded.sid = newTagSid;
      tagAdded.source = 'ST';
      let showcaseTag: ShowcaseTag = {
        id: tagDocRef.id,
        data: JSON.stringify(tagAdded),
        createdOn: new Date(),
        lastUpdatedOn: new Date(),
        createdBy: userId,
        lastUpdatedBy: userId,
      };
      showcaseTag.annotationType = annotationType || ANNOTATION_TYPE.INFO;
      quizDetails && quizDetails.question && (showcaseTag.quizDetails = quizDetails);
      tagHtml && (showcaseTag.tagHtml = tagHtml);
      // mediaLinkInput && (showcaseTag.mediaLink = mediaLinkInput);
      showcaseTag.showTextToSpeech = showTextToSpeech ? true : false;
      // (attachmentsUrls?.[0]||mediaLinkInput)&&(showcaseTag.mediaUpload= attachmentsUrls.length?"attachment":"link");
      uploadType && (showcaseTag.uploadType = uploadType);
      labelIds && (showcaseTag.labelIds = labelIds);

      if (annotationType === ANNOTATION_TYPE.TASK) {
        if(tagBeingEdited){
          // await Api.put('/api/projects/edit/card', { project: taskTag?.project, list: taskTag?.list, card: { ...taskTag?.selectedCard, tagId: tagDocRef.id } }).then(async (data) => {
          //   // if (data.status === 200) showcaseTag.projectId = data.data.id;
          // });
          await Api.put('/api/projects/edit/task', { task:{...taskTag, tagId: tagDocRef.id} }).then(async (data) => {
            // if (data.status === 200) showcaseTag.projectId = data.data.id;
            dispatch({ type: SET_SPACE_DATA, payload: {...space, currentSpaceProject:data.data} });
            // tagBeingEdited?.taskTag && sendEmailsToCollaborator(tagBeingEdited?.taskTag,taskTag);
          })
        } else{
// <<<<<<< HEAD
          // await Api.post('/api/projects/add/card', { project: taskTag?.project, list: taskTag?.list, card: { ...taskTag?.selectedCard, tagId: tagDocRef.id } }).then(async (data) => {
          //   if (data.status === 200) showcaseTag.projectId = data.data.id;
          // });
          await Api.post('/api/projects/add/task', {task :{...taskTag, tagId: tagDocRef.id} }).then(async data => {
            if (data.status === 200) {
              showcaseTag.projectId = data.data.project?.id;
              // let index = data.data.project.sectionList.findIndex((i:TaskSectionObj)=>(i.id == taskTag?.sectionId));
              // let selectedTask = data.data.project.sectionList[index].taskList[data.data.project.sectionList[index].taskList.length-1];

              taskTag && (taskTag.id = data.data.task?.id);

              dispatch({ type: SET_SPACE_DATA, payload: {...space, currentSpaceProject: data.data.project} });
              // sendEmailsToCollaborator(null,taskTag);
            }
          })
// =======
//          await Api.post('/api/projects/add/card', { project: taskTag?.project, list: taskTag?.list, card: { ...taskTag?.selectedCard, tagId: tagDocRef.id } }).then(async (data) => {
//             if (data.status === 200) {
//               showcaseTag.projectId = data.data.id;
//               let index = data.data.sectionList.findIndex((i:TaskSectionObj)=>(i.id == taskTag?.selectedCard?.sectionId));
//               let selectedTask: TaskObj = data.data.sectionList[index].taskList[data.data.sectionList[index].taskList.length-1];

//               taskTag && taskTag?.selectedCard && (taskTag.selectedCard.id = selectedTask.id);

//               // dispatch({ type: SET_SPACE_DATA, payload: {...space, currentSpaceProject:data.data} });
//               // sendEmailsToCollaborator(null,taskTag);
//             }
//           })
//           // if(pathname.includes(HomeRoutes.BOARD))
//           //   dispatch(onGetProjectDetail(projectDetail?.did));
// >>>>>>> s17/arp/decMerge
        }
        if(pathname.includes(HomeRoutes.TASKS)){
          projectDetail && dispatch(onGetProjectDetail(projectDetail?.did));
        }
      }

      if(tagBeingEdited){
        await tagDocRef.update(showcaseTag).catch(console.error);
      } else{
        await tagDocRef.set({ ...showcaseTag });
      }

      // after save task tag in space then pass into showcase
      taskTag && (showcaseTag.taskTag = taskTag);

      showcaseTag.data = tagAdded;
      await initiateTag(showcaseTag, tagAdded.sid);

      dispatch(addToSpaceTags(showcaseTag));
      console.log(`[st] nav to tag 3`);
//       // set Icon for tag
//       let iconId: string;
//       if (annotationType === ANNOTATION_TYPE.QUIZ) {

//         if (tag.color) {
//           iconId = `quiz-${LabelColorFromTag(tag.color)}`;
//         } else {
//           iconId = 'quizIconId';
//         }

//       } else if (annotationType === ANNOTATION_TYPE.TASK) {
//         let labelName = showcaseTag.taskTag?.section?.name || '';
//         iconId = `task${labelName ? labelName.replaceAll(' ', '') : ''}`;
//         iconId = iconId;
//       } else {
//         if (tag.media && tag.media.src) {
//           iconId = `media`;
//         } else {
//           iconId = `info-${LabelColorFromTag(tag.color)}`;
//         }

//       }
//       await mpSdk.Mattertag.editIcon(tag?.sid, iconId);
      MpSdk.Instance.mps.Mattertag.navigateToTag(
        tagAdded.sid,
        MpSdk.Instance.mps.Mattertag.Transition.FLY,
      );
      dispatch(fetchSuccess());
      dispatch(showMessage(!tagBeingEdited?.id ? "Tag created!" : "Tag edited!"));
    } catch (error) {
      console.error(error);
      dispatch(
        fetchError(
          'Something went wrong connecting to Matterport. Please try again or refresh the page if the problem persists',
        ),
      );
    }
  };


  // const initiateTag = async (showcaseTag: ShowcaseTag, currentSid: string) => {
  //   // console.log(showcaseTag, currentSid, 'initiateTag');
  //   //set global tag sid map
  //   addToCurrentTagsSidMapGlobal(showcaseTag.id, currentSid);

  //   //TODO double check before merging, should we prevent these actions?
  //   // MpSdk.Instance.mps.Mattertag.preventAction(currentSid, {
  //   //   // opening: true,
  //   //   // navigating: true
  //   // });

  //   //TODO is this needed? test media tags from matterport
  //   showcaseTag.data?.media?.type &&
  //     (showcaseTag.data.media.type =
  //       'mattertag.media.' + showcaseTag.data.media.type);

  //   let iconId = '';
  //   //set icon
  //   // if (showcaseTag.data.source == 'ST') {
  //   if (!showcaseTag.annotationType) {
  //     showcaseTag.annotationType = 'info';


  //   }
  //   if (!showcaseTag.annotationType || showcaseTag.annotationType == 'info') {

  //     if (showcaseTag.data.media && showcaseTag.data.media.src) {
  //       iconId = `media`;
  //     } else {
  //       iconId = `info-${LabelColorFromTag(showcaseTag.data.color)}`;
  //     }
  //   }

  //   if (showcaseTag.annotationType.startsWith('quiz')) {
  //     if (showcaseTag.data.color) {
  //       iconId = `quiz-${LabelColorFromTag(showcaseTag.data.color)}`;
  //     } else {
  //       iconId = 'quizIconId';
  //     }
  //   }
  //   // alert("2" + iconId);
  //   await MpSdk.Instance.mps.Mattertag.editIcon(currentSid, iconId || showcaseTag?.annotationType);
  //   // }

  //   //inject HTML
  //   try {
  //     const tagHtml = showcaseTag.tagHtml || '';
  //     const blocksFromHtml = htmlToDraft(tagHtml);
  //     const { contentBlocks, entityMap } = blocksFromHtml;
  //     const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  //     const editorState = EditorState.createWithContent(contentState);
  //     let pureText = ''
  //     convertToRaw(editorState.getCurrentContent()).blocks.forEach(block => {
  //       pureText = pureText + ' ' + block.text
  //     });

  //     const textToSpeechButton = `<button style="position: absolute; right: 5px; top: 0px;" onclick="(()=>{
  //       var synth = window.speechSynthesis;
  //       var utterThis = new SpeechSynthesisUtterance('${pureText}');
  //       synth.speak(utterThis);
  //       return;
  //       })()"><img src="https://icon-library.com/images/google-voice-icon/google-voice-icon-20.jpg" height='12' width='7' /></button>`;
  //     let tagHtmlSpeech =
  //       tagHtml !== '<p></p>\n' //i.e. if draft is empty
  //         ? `<div style="color:white; font-family:Open Sans;"> ${tagHtml} ${showcaseTag.showTextToSpeech ? textToSpeechButton : ''} </div>`
  //         : '';
  //     if (showcaseTag?.annotationType === ANNOTATION_TYPE.INFO) {
  //       // if (showcaseTag?.mediaLink) {
  //       //   const htmlString = makeMediaHtml(
  //       //     currentSid,
  //       //     showcaseTag?.mediaLink,
  //       //     tagHtml,
  //       //   );
  //       //   await injectHtmlHandler(htmlString, currentSid, 600, 400);
  //       // } else {
  //       await injectHtmlHandler(tagHtmlSpeech, currentSid, undefined, undefined); //, 400, 200);
  //       // }
  //     } else if (showcaseTag?.annotationType === ANNOTATION_TYPE.QUIZ) {
  //       const htmlString = makeQuizHtml(
  //         currentSid,
  //         showcaseTag.quizDetails,
  //         tagHtmlSpeech,
  //       );
  //       await injectHtmlHandler(htmlString, currentSid, 400, 250);
  //     } else if (showcaseTag?.annotationType?.startsWith(ANNOTATION_TYPE.TASK)) {
  //       const htmlString = makeTaskTagHtml(
  //         showcaseTag?.taskTag,
  //         currentSid,
  //         tagHtmlSpeech,
  //       );
  //       await injectHtmlHandler(htmlString, currentSid, 600, 250);
  //     }
  //   } catch (error: any) {
  //     console.error(error);
  //   }
  // };

  // const injectHtmlHandler = async (
  //   htmlString: any,
  //   tagSid: string,
  //   width: undefined | number,
  //   height: undefined | number,
  // ) => {
  //   // await initMpSdk();

  //   if (htmlString !== '') {
  //     mpSdk &&
  //       mpSdk.Mattertag.injectHTML(tagSid, htmlString, {
  //         size: {
  //           w: width,
  //           h: height,
  //         },
  //         windowPath: '',
  //       })
  //         .then(function (messenger: any) {
  //           messenger.on('buttonClick', function (tagSid: any) {
  //             dispatch(setTagsViewMode(ViewMode.TAGS));
  //             let selectedShowcaseId = getTagIdForTagSid(tagSid);

  //             selectedShowcaseId && dispatch(setEditShowcaseTagId(selectedShowcaseId))
  //           });
  //         })
  //         .catch((e: any) => {
  //           console.error(e);
  //           console.error('Error in injectHTML');
  //         });
  //   }
  // };


  function getEmbedlyMediaUrl(mediaLinkInput: string) {
    const url: any = new URL('https://api.embed.ly/1/extract'),
      params: any = {
        url: mediaLinkInput,
        key: '75db94366375495297fcc5782b2eeb35',
      };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key]),
    );
    return url;
  }

  // const uploadMediaFiles=async (attachments: AttachmentObj[])=>{

  //     let tagDocRef = firestore.collection(`Spaces/${currentSpaceId}/tags`).doc();

  //     //logic to create urls for attachments
  //     let prefix = `Spaces/${currentSpaceDid}/Tags`;
  //     var storageRef = storage.ref(prefix);

  //     let attachmentsUrls: any[] = [];

  //     let promises: any = [];
  //     promises = attachments.map((attachment, index) => {
  //       let file: any = attachment.file;

  //       let filename: any = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
  //       filename = `${tagDocRef.id}.${filename}`
  //       let imageRef = storageRef.child(filename);
  //       // let baseModelRef = storage.ref(`3d/${type}/${name}`).child(fileName);

  //       return imageRef
  //         .put(file)
  //         .then(() => {
  //           const imageURL = `https://storage.googleapis.com/virtual-tc.appspot.com/${prefix}/${filename}`; //imageRef.getDownloadURL();
  //           return imageURL;
  //         })
  //         .then(async (imageURL: any) => {
  //           attachmentsUrls.push(imageURL);
  //           //make model bucket/folder public
  //           try {
  //             let makeFilePublicResult = await Api.post('/admin/model-check', { "modelPath": `${prefix}/${filename}` });

  //             if (makeFilePublicResult.status === 200) {
  //               console.log(`[st] file made public: ${prefix}/${filename}`);
  //               // dispatch(showMessage("All Done!"));
  //               // dispatch({ type: GET_SPACES_LIST, payload: data.data });
  //             } else {
  //               console.error(`[st] error making file public: ${prefix}/${filename} : ${JSON.stringify(makeFilePublicResult)}`)
  //               dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
  //             }
  //           } catch (error) {
  //             console.error(`[st] error making file public: ${prefix}/${filename} : ${error}`)
  //             dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
  //           };

  //           return imageURL;
  //         })
  //         .catch((error: any) => {
  //           console.log(error);
  //         });
  //     });

  //     return await Promise.all(promises);
  // }

  return (
    (console.log(`[st] rendering AddTagForm ${openTagForm}`), <Dialog
      open={openTagForm}
      onClose={() => {}
        // dispatch(setOpenTagForm(false)) Do nothing. Enforce clicking cancel to exit
        }
      aria-labelledby='form-dialog-title'
      style={{ color: 'black' }}
      className={classes.dialogBox}
      color='black'>
      <Scrollbar className={classes.scrollRoot}>
        {/* <DialogTitle id='form-dialog-title'>Tag Details</DialogTitle> */}
        <DialogContent style={{ position: 'relative' }}>
          {renderSelectCategoryForm()}

          {renderFormBody()}
          {annotationType !== ANNOTATION_TYPE.TASK && <p style={{ margin: '10px 2px' }}>Additional text</p>}
          {RichTextEditor(editorState, setEditorState, annotationType)}
        </DialogContent>
        {annotationType !== ANNOTATION_TYPE.TASK && (
          <DialogActions>
            <div className={classes.dialogActionRoot}>
              <div
                style={
                  annotationType === ANNOTATION_TYPE.INFO
                    ? { justifyContent: 'space-between', display: "flex", }
                    : { justifyContent: 'flex-end', display: "flex", }
                }>

                {annotationType === ANNOTATION_TYPE.INFO && (
                  <div style={{ display: 'flex' }}>
                    {/* //TODO this needs to fixed, then uncommented */}
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={showTextToSpeech}
                          onChange={() => setShowTextToSpeech(!showTextToSpeech)}
                        />
                      }
                      label='Text to Speech'
                    /> */}

                    <IconButton
                      aria-label={"Photo/Video link"}
                      size='small'
                      className={clsx(
                        classes.iconButton,
                        // item.isActive ? classes.isActive: ""
                      )}
                      onClick={() => {
                        setShowMediaLink(!showMediaLink);
                      }}
                    // disableRipple={true}
                    >
                      <PermMediaIcon />
                    </IconButton>
                    {/* <MediaIcon
                  height={20}
                  width={20}
                  className={
                    !showMediaLink
                      ? classes.mediaIcon
                      : classes.highlightMediaIcon
                  }
                  onClick={() => {
                    setShowMediaLink(!showMediaLink);
                  }}
                /> */}
                    <Attachments
                      onAddAttachments={onAddAttachments}
                      onClickDeleteIcon={onClickDeleteIcon}
                    />
                  </div>
                )}

                <div>
                  <Button onClick={() => {
                    handleCancelTagForm();
                    handleClosedForm();
                  }} color='primary'>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setSaveBtnClick(true);
                      if (annotationType === ANNOTATION_TYPE.INFO) {
                        if (tagLabelInput !== '') {
                          handleSubmitForm(null);
                          setSaveBtnClick(false);
                        }
                      } else if (annotationType === ANNOTATION_TYPE.QUIZ) {
                        if (quizDetails.question !== '' ){
                          handleSubmitForm(null);
                          setSaveBtnClick(false);
                        }
                      }
                    }}
                    variant='contained'
                    color='secondary'>
                    Save
                  </Button>
                </div>

              </div>

              {annotationType === ANNOTATION_TYPE.INFO && (
                <div>
                  <CardAttachments
                    attachments={attachments}
                    onDeleteAttachment={onDeleteAttachment}
                  />
                  {/* <FileUploader
                    // acceptedFileTypes='image/*'
                    handleFiles={setAttachments}
                  // allowOnlyOne={true}
                  /> */}
                </div>
              )}

            </div>
          </DialogActions>
        )}
      </Scrollbar>
    </Dialog>)
  );
}
AddTagForm.whyDidYouRender = true;

const RichTextEditor = (editorState: Draft.EditorState, setEditorState: any, annotationType: string) => {
  return (
    useMemo(() => annotationType !== ANNOTATION_TYPE.TASK ? <RichEditorExample initialValue={editorState} saveDraftHtml={setEditorState} />: null, [editorState,annotationType !== ANNOTATION_TYPE.TASK])
)}

function sendEmailsToCollaborator(arg0: null, taskTag: TaskObj | null) {
  throw new Error('Function not implemented.');
}

