import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const reportsConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/reports',
        component: React.lazy(() => import('./Reports')),
      }
    ],
    }
];
