import * as React from 'react';
// import { styled } from '@mui/material/styles';
// import MuiAccordion from '@mui/material/Accordion';
import { Accordion, AccordionDetails, AccordionSummary, AccordionSummaryProps, Typography } from '@material-ui/core';
// import Typography from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import { CremaTheme } from 'types/AppContextPropsType';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InsertedObjects from './InsertedObjects';
import ThreeDAnnotations from './ThreeDAnnotations';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import CatalogSidebar from '../CatalogSidebar';
import Scrollbar from '@crema/core/Scrollbar';
import { useMemo } from 'react';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { PropertiesPanelUIData, PropertiesPanelMode } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
import PropertiesPanel from '../../ShowcaseOverlay/3DTools/PropertiesPanel';

// const Accordion = (props: any) => (
//     <MuiAccordion children={''}
//         // disableGutters
//         // elevation={1}
//         // square
//         {...props} />
// );

// // (({ theme: CremaTheme }) => ({
// //     // border: `1px solid ${theme.palette.divider}`,
// //     '&:not(:last-child)': {
// //         borderBottom: 0,
// //     },
// //     '&:before': {
// //         display: 'none',
// //     },
// // }));

// (({ theme }) => ({
//     color: theme.palette.text.primary,
//     // backgroundColor:

//     //   theme.palette.mode === 'dark'
//     //     ? 'rgba(255, 255, 255, .05)'
//     //     : 'rgba(0, 0, 0, .03)',
//     flexDirection: 'row-reverse',
//     '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//         transform: 'rotate(90deg)',
//     },
//     '& .MuiAccordionSummary-content': {
//         marginLeft: theme.spacing(1),
//     },
//     '& MuiAccordionDetails-root': {
//         paddingLeft: '0px',
//         paddingRight: '0px',
//     },

// }));

const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        accordion: {
            border: `1px solid ${theme.palette.divider}`,
            // borderRadius: '16px',
            // '&:not(:last-child)': {
            //     borderBottom: 0,
            // },
            '&:before': {
                display: 'none',
            },
            '& .Mui-expanded': {
                margin: '2px 0px',
                // maxHeight: '300px',
                // color: "red",
                // backgroundColor: "green"
            },
            '& .MuiCollapse-entered': {
                // maxHeight: 'calc(100vh - 510px)',
                // minHeight: 'calc(100vh - 310px)',
            },
        },
        accordionSummary: {
            color: theme.palette.text.primary,
            // backgroundColor:

            //   theme.palette.mode === 'dark'
            //     ? 'rgba(255, 255, 255, .05)'
            //     : 'rgba(0, 0, 0, .03)',
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                // transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
                transform: 'rotate(90deg)',
            },
            '& .MuiCollapse-entered': {
                // maxHeight: 'calc(100vh - 510px)',
                // minHeight: 'calc(100vh - 310px)',
            },
            '& .MuiAccordionSummary-content': {
                marginLeft: theme.spacing(1),
            },
            // '& MuiAccordionDetails-root': {
            //     paddingLeft: '0px',
            //     paddingRight: '0px',
            // },

            '& .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: '30px',
                maxHeight: '35px'
            }
        },
        sectionTitle: {
            fontWeight: 500,
            fontFamily: 'inherit',
            fontSize: '14px'
        },
        accordionContent: {
            // maxHeight: 'calc(100vh - 310px)',
            // minHeight: 'calc(100vh - 310px)',
            overflow: 'scroll',
            '& .MuiAccordion-root.Mui-expanded': {
                margin: '2px 0px'
            },
            '& .MuiCollapse-entered': {
                // maxHeight: '300px'
                // 'calc(100vh - 510px)',
                // minHeight: 'calc(100vh - 310px)',
            },
            // '& .MuiAccordionDetails-root': {
            //     paddingLeft: '0px',
            //     paddingRight: '0px',
            // },
            // '& .MuiAccordionDetails': {
            //     root: {
            //         paddingLeft: '0px',
            //         paddingRight: '0px'
            //     }
            // },
        },

    }));

export default function ThreeDAccordions() {
    const [expanded, setExpanded] = React.useState<string>('insertedObjects');
    // const [addObjectExpanded, setAddObjectExpanded] = React.useState<boolean>(true);
    // const [insertedObjectsExpanded, setInsertedObjectsExpanded] = React.useState<boolean>(true);
    // const [libraryExpanded, setLibraryExpanded] = React.useState<boolean>(false);
    const classes = useStyles();

    const [propertiesPanelUIData, setPropertiesPanelUIData] = React.useState<PropertiesPanelUIData>(new PropertiesPanelUIData());
    Simulation.instance.propertiesPanel.setPropertiesPanelUIData = setPropertiesPanelUIData;

    const AppAccordionSummary = ((props: AccordionSummaryProps) => (
        <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ArrowRightRoundedIcon
            // sx={{ fontSize: '0.9rem' }}
            />
            }
            {...props}
        />
    ));
    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : '');
    };

    return (console.log(`[st] rendering ThreeDAccordions`),
        <div style={{
            maxHeight: '100%', height: "100%",
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
            // alignItems: 'center',
            width: "100%"
        }}>
            {propertiesPanelUIData.propertiesPanelMode !== PropertiesPanelMode.None &&
                // <div className={classes.outlineBox}>
                // <p className={classes.outlineBoxTitle}>Properties</p>
                <div style={{ borderRadius: '16px', backgroundColor: "white", marginBottom: '16px' }}>
                    <PropertiesPanel propertiesPanelUIData={propertiesPanelUIData} />
                </div>
            }
            <Accordion
                className={classes.accordion}
                // disableGutters
                // defaultExpanded
                // expanded={addObjectExpanded}
                expanded={expanded == 'addObject'}
                onChange={handleChange('addObject')}
            // onChange={() => setAddObjectExpanded(!addObjectExpanded)}
            >
                <AppAccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls='addObjectbh-content'
                    id='addObjectbh-header'
                >
                    <Typography
                        variant="body1"
                        className={classes.sectionTitle}
                    // sx={{ width: '33%', flexShrink: 0 }}
                    >3D Objects</Typography>

                </AppAccordionSummary>
                <AccordionDetails className={classes.accordionContent}>
                    <div
                    // style={{ maxHeight: 'calc(100vh - 150px)' }}
                    >
                        {/* ThreeDAnnotations */}
                        {useMemo(() => <ThreeDAnnotations />, [])}

                    </div>

                </AccordionDetails>
            </Accordion>
            <Accordion
                className={classes.accordion}
                // defaultExpanded
                expanded={expanded == 'insertedObjects'}
                onChange={handleChange('insertedObjects')}
            // expanded={insertedObjectsExpanded}
            // onChange={() => setInsertedObjectsExpanded(!insertedObjectsExpanded)}
            >
                <AppAccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls='insertedbh-content'
                    id='insertedbh-header'
                >
                    <Typography
                        variant="body1"
                        className={classes.sectionTitle}
                    >
                        Inserted Objects
                    </Typography>
                    {/* <Typography sx={{color: 'text.secondary'}}>
            I am an accordion
          </Typography> */}
                </AppAccordionSummary>
                <AccordionDetails className={classes.accordionContent}>
                    <div
                    // style={{ maxHeight: 'calc(100vh - 310px)', minHeight: 'calc(100vh - 310px)' }}
                    >
                        {/* <Scrollbar> */}
                        <div style={{
                            maxHeight: 'calc(100vh - 400px)',
                            // height: "100%",
                            // overflow: "scroll"
                        }}>

                            {/* {[...Array(100).keys()].map(i => <div>{i}</div>)} */}
                            {useMemo(() => <InsertedObjects />, [])}
                        </div>
                        {/* </Scrollbar> */}
                    </div>
                </AccordionDetails>
            </Accordion>


            {/* <Accordion
                expanded={expanded == 'panel3'}
                onChange={handleChange('panel3')}
            >
                <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel3bh-content'
                    id='panel3bh-header'
                >
                    <Typography
                        // sx={{ width: '33%', flexShrink: 0 }}
                        variant="body1"
                        className={classes.sectionTitle}>

                        My Uploads
                    </Typography>

                </AccordionSummary>
                <AccordionDetails className={classes.accordionContent}>
                    <Typography>
                        Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
                        sit amet egestas eros, vitae egestas augue. Duis vel est augue.
                    </Typography>
                </AccordionDetails>
            </Accordion> */}
            <Accordion
                expanded={expanded == 'library'}
                onChange={handleChange('library')}
            // expanded={libraryExpanded}
            // onChange={() => setLibraryExpanded(!libraryExpanded)}
            >
                <AppAccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls='librarybh-content'
                    id='librarybh-header'
                >
                    <Typography
                        // sx={{ width: '33%', flexShrink: 0 }}
                        variant="body1"
                        className={classes.sectionTitle}
                    >
                        Library
                    </Typography>
                </AppAccordionSummary>
                <AccordionDetails className={classes.accordionContent}>
                    <div style={{
                        maxHeight: 'calc(100vh - 400px)',
                        // height: "100%",
                        // overflow: "scroll"
                    }}>

                        {React.useMemo(() => <CatalogSidebar />, [])}
                        </div>
                    {/* <Typography>

                    </Typography> */}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}