import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import Frame from 'mp/Frame';
import CloseIcon from '@mui/icons-material/Close';
import {
  SpaceData,
  SpaceMembership,
} from 'types/models/home/HomeApp';
import GizmoTools from './3DTools/GizmoTools';
import VariablesPanel from './Variables/VariablesPanel';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';

import LessonsPlayTools from './LessonsPlayTools';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';

import ShortCutPanel from './ShortCutPanel';
import InputSubSystem from 'mp/core/craEngine/SubSystems/input/InputSubSystem';
import BannerDisplay from './BannerDisplay';
import VariablesInputDialog from './Variables/VariablesInputDialog';
import { ProjectObj } from 'types/models/apps/ProjectBoard';
import { ILesson } from 'types/models/home/HomeApp';

import TurnedInRoundedIcon from '@material-ui/icons/TurnedInRounded';
import LayersRoundedIcon from '@material-ui/icons/LayersRounded';
import * as THREE from "three";
import { showMessage } from 'redux/actions';
import modelMap from 'modules/home/models';
import { defaultLayerNamePlural } from 'shared/constants/AppConst';
// import { ThreeDIcon } from '@crema/core/Icons/CustomIcons';
import { canEdit } from '@crema/utility/Utils';
import AddTag from '../Sidebar/TagSidebar/Tags/AddTag';
import MpSdk from 'mp/MpSdk';
import { SET_IS_ADDING_TAG } from 'types/actions/Home.action';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useUserAdmin } from '@crema/utility/AppHooks';
import { ReactComponent as ThreeDIcon2Svg } from 'assets/images/3dIcons/ThreeDIcon2.svg';
import TagSidebar from '../Sidebar/TagSidebar';
import { ViewModeSecondary } from '../Sidebar';
import InsertedObjects from '../Sidebar/ThreeDSidebar/InsertedObjects';
import { setTagsViewModeSecondary } from 'redux/actions/Home';
import SidebarTabPanel from '../Sidebar/TagSidebar/TabPanel';
import { getTagSidForTagId } from '../Sidebar/TagSidebar/Tags/ShowcaseUtils';
import Dictaphone from 'modules/home/components/Dictaphone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quizInputContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    playLessonControlFooter: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      bottom: '20px',
      width: '100%',
      margin: '0px auto',
      maxWidth: '100%',
      color: '#fff',
    },
    playLessonCtrlSubTitle: {
      padding: '0px 20px',
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#fff',
    },
    playLessonCtrlTitle: {
      padding: '0px 20px',
      fontSize: '15px',
      fontWeight: 'bold',
      color: '#fff',
    },
    lockViewSwitch: {
      position: 'absolute',
      color: 'white',
      left: '1%',
      zIndex: 10,
      marginTop: '11px',
    },
    propertyPanelSwitch: {
      position: 'absolute',
      color: 'white',
      left: '1%',
      zIndex: 10,
      marginTop: '41px',
    },

    variableDisplaylSwitch: {
      position: 'absolute',
      color: 'white',
      left: '1%',
      zIndex: 10,
      marginTop: '101px',
    },
    circularMenu: {
      position: 'absolute',
      bottom: 120,
      left: '15%',
      transform: 'translate(-50%, -50%)',
      color: theme.palette.secondary.main,
    },
    horizontalMenu: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#222222e9',
      zIndex: 10,
      '& .MuiButton-root': {
        margin: '5px 10px',
        border: 'none !important',
        borderRadius: 0,
        padding: 6,
        backgroundColor: 'transparent',
        width: 30,
        height: 30,
        transition: '.3s',
        '& svg': {
          transition: '.3s'
        },
        '&.active, &:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'inset 2px 4px 3px 0px rgba(0, 0, 0, 0.57), inset 1px 1px 3px 0px rgba(0, 0, 0, 0.78) !important',
          '& svg': {
            color: theme.palette.secondary.main,
          }
        }
      },
    },
    horizontalSubMenu: {
      position: 'absolute',
      bottom: 45,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#222222e9',
      zIndex: 10,
      '& .MuiButton-root': {
        margin: '5px 10px',
        border: 'none !important',
        borderRadius: 0,
        padding: 6,
        backgroundColor: 'transparent',
        width: 30,
        height: 30,
        transition: '.3s',
        '& svg': {
          transition: '.3s'
        },
        '&.active, &:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'inset 2px 4px 3px 0px rgba(0, 0, 0, 0.57), inset 1px 1px 3px 0px rgba(0, 0, 0, 0.78) !important',
          '& svg': {
            color: theme.palette.secondary.main,
          }
        }
      },
    },
  }),
);
interface Props {
  lockViewDefault: boolean;
  hideControlsDefault: boolean;
  hide3DToolsDefault: boolean;
  space: SpaceData,
  project: ProjectObj,

}

function ShowcaseOverlay({

  lockViewDefault,
  hideControlsDefault,
  hide3DToolsDefault,
  space,
  project,
}: Props) {
  const classes = useStyles();
  const [lockView, setLockView] = useState(lockViewDefault);
  // const [hideControls, setHideControls] = useState(hideControlsDefault);
  const [isShowCircularMenu, setIsShowCircularMenu] = useState<boolean>(true);
  const [isShowObjectCircularMenu, setIsShowObjectCircularMenu] =
    useState<boolean>(false);
  // const [propertyPanelShowOverride, setPropertyPanelShowOverride] =
  //   useState(true);
  const [showVariablesPanel, setShowVariablesPanel] =
    useState(false);
  const [clickedTagCoords, setClickedTagCoords] = useState<any>(undefined);
  const spaceMembership = useSelector<AppState, SpaceMembership | undefined>(({ home }) => home.currentSpace?.spaceMembership);
  const currentLesson = useSelector<AppState, ILesson | null>(({ home }) => home.currentLesson);
  const tagsViewModeSecondary = useSelector<AppState, string>(({ home }) => home.tagsViewModeSecondary);

  // const selectedTagId = useSelector<AppState, string>(({ home }) => home.selectedTagId);

  const presentationMode = useSelector<AppState, boolean>(({ home }) => home.presentationMode);

  const dispatch = useDispatch();
  const isUserAdmin = useUserAdmin();
  // Simulation.instance.dispatch = dispatch;

  const [isvarInputDialogOpen, setVarInputDialog] = useState(true);
  useEffect(() => InputSubSystem.input && InputSubSystem.input.setMatterPortCameraMovement(!lockView), [InputSubSystem.input]);

  // useEffect(() => {
  //   document.addEventListener('blur', () => console.log(`[st] blurred`));
  //   return () => {
  //     document.removeEventListener('blur', blurHandler);
  //   };
  // }, [MpSdk.Instance.mps]);


  // useEffect(() => {
  //   selectedTagId && getTagCoords(selectedTagId);
  // }, [selectedTagId]);


  // useEffect(() => setShowVariablesPanel(!hideControlsDefault ? !propertyPanelShowOverride : propertyPanelShowOverride), [hideControlsDefault])
  // useEffect(() => {


  //   if (!(currentSpace && canEdit(currentSpace.spaceMembership.role))){
  //     setPropertyPanelShowOverride(false);
  //     console.log(`[st] 1 setPropertyPanelShowOverride `);

  //   } else {
  //     let setTo = !hide3DToolsDefault ? propertyPanelShowOverride : !propertyPanelShowOverride;
  //     console.log(`[st] 2 setPropertyPanelShowOverride ${setTo}`);
  //     setPropertyPanelShowOverride(setTo);
  //   }

  // }, [Simulation.instance, currentSpace])

  const getTagCoords = async (tagId: string) => {

    let tagSid = getTagSidForTagId(tagId);
    let tags = await MpSdk.Instance.mps.Mattertag.getData();

    //FIXME !!!! Tags id-sid data is out of sync !!!!
    let discAnchorPosition = await MpSdk.Instance.mps.Mattertag.getDiscPosition(tags[2]);
    console.log(`[st] coords ${JSON.stringify(discAnchorPosition)}`);


    let coords = Simulation.instance.camera.getScreenCoordsOfPointWithoutMatterPortSDK(
      new THREE.Vector3(discAnchorPosition.x, discAnchorPosition.y, discAnchorPosition.z));
    setClickedTagCoords(Object.assign({}, coords));

  }


  const showCustomBillboard = (tagSid: any) => {
    console.log(`[st] hovering ${JSON.stringify(tagSid)}`);
    // MpSdk.Instance.mps.Mattertag.getData().then((tags: any[]) => {
    //   console.log(tags);
    //   console.log(`[st] git tag data ${JSON.stringify(tagSid)}`);
    //   let tag: any = tags.find(tag => tag.sid == tagSid);
    //   console.log(tag);

    //   console.log(`[st] pos ${JSON.stringify(tag)}`);

    //   let finalAnchorPosition = new THREE.Vector3(tag.anchorPosition.x,
    //     tag.anchorPosition.y,
    //     tag.anchorPosition.z);

    // Disc anchor looks better but both work (disc is at the center of the  tag disc)
    // Feel free to comment out finalAnchorPosition above
    let discAnchorPosition = MpSdk.Instance.mps.Mattertag.getDiscPosition(tagSid);
    console.log(`[st] hovering 2 ${JSON.stringify(discAnchorPosition)}`);
    //console.log(tag.stemHeight)
    //console.log(tag.stemVector)

    //let coordsMp = Simulation.instance.camera.getScreenCoordsOfPoint(finalAnchorPosition);
    // Simulation.instance.camera.LookAtXYZ(new THREE.Vector3(
    //   tag.anchorPosition.x,
    //   tag.anchorPosition.y,
    //   tag.anchorPosition.z
    // ));

    let coords1 = Simulation.instance.camera.getScreenCoordsOfPointWithoutMatterPortSDK(
      new THREE.Vector3(discAnchorPosition.x, discAnchorPosition.y, discAnchorPosition.z));
    console.log(`[st] coords ${JSON.stringify(coords1)}`);
    //console.log(`[st] coordsMP ${JSON.stringify(coordsMp)}`);
    setClickedTagCoords(Object.assign({}, coords1));

    // }).catch(console.error)

  }

  const onTagClick = (tagSid: any) => {
    console.log(`[st] clicked ${JSON.stringify(tagSid)}`);
    MpSdk.Instance.mps.Mattertag.getDiscPosition(tagSid).then((discAnchorPosition: any) => {
      console.log(`[st] hovering 2 ${JSON.stringify(discAnchorPosition)}`);
    }).catch(console.error);

  }

  // useEffect(() => {
  // MpSdk.Instance.mps && MpSdk.Instance.mps.on(MpSdk.Instance.mps.Mattertag.Event.HOVER, showCustomBillboard);
  // MpSdk.Instance.mps && MpSdk.Instance.mps.on(MpSdk.Instance.mps.Mattertag.Event.CLICK, onTagClick );
  // }, [MpSdk.Instance.mps]);

  useEffect(() => {
    let setTo = !hideControlsDefault
      ? showVariablesPanel
      : !showVariablesPanel;
    setShowVariablesPanel(setTo);
  }, [Simulation.instance]);
  // let currentTagGroup = currentTagGroupId && (setVarInputDialog(true), tagGroupsList.find(tg => tg.id === currentTagGroupId));

  const theme = useTheme();
  const submenuForObjectModel = useMemo(() => {
    return (
      // <<<<<<< HEAD:src/modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/index.tsx
      //       spaceMembership && canEdit(spaceMembership.role) &&
      //       <CircleMenu
      //         className={classes.circularMenu}
      //         startAngle={-360}
      //         rotationAngle={360}
      //         itemSize={7}
      //         radius={12}
      //         onMenuToggle={(toggleState: boolean) => {
      //           if (toggleState == false) {
      //             setTimeout(() => {
      //               setIsShowCircularMenu(true);
      //               setIsShowObjectCircularMenu(false);
      //             }, 500);
      //           }
      //         }}
      //         isOpenMenu={true}
      //       >
      //         {modelMap.size > 0 && Array.from(modelMap.values()).map(model => (
      //           <CircleMenuItem
      //             onClick={() => {
      //               addObject(model.payload.objects[0].name)
      //             }}
      //             tooltip={model.payload.objects[0].name}
      //           >
      //             {<Tooltip open={isShowObjectCircularMenu} title={model.payload.objects[0].name}>
      //             {model.iconMui ? <model.iconMui /> : <ThreeDIcon />}</Tooltip>}
      //             {/* <img style={{ height: "50px" }} src={model.icon || "https://onlinepngtools.com/images/examples-onlinepngtools/400-by-400.png"}
      //               alt={""} /> */}
      //             {/* <div style={{ fontSize: '12px', textAlign: 'center', marginTop: '4px' }}>{model.payload.objects[0].name}</div> */}
      //           </CircleMenuItem>
      // =======
      // currentSpace && canEdit(currentSpace.spaceMembership.role) && <CircleMenu
      //   className={classes.circularMenu}
      //   startAngle={-360}
      //   rotationAngle={360}
      //   itemSize={7}
      //   radius={12}
      //   onMenuToggle={(toggleState: boolean) => {
      //     if (toggleState == false) {
      //       setTimeout(() => {
      //         setIsShowCircularMenu(true);
      //         setIsShowObjectCircularMenu(false);
      //       }, 500);
      //     }
      //   }}
      //   isOpenMenu={true}
      // >
      //   {modelMap.size > 0 && Array.from(modelMap.values()).map((model, index) => (
      //     <CircleMenuItem
      //       key={`CircleMenuItem-in-MatterPortScreen-${index}`}
      //       onClick={() => {
      //         addObject(model.payload.objects[0].name)
      //       }}
      //       tooltip={model.payload.objects[0].name}
      //     >
      //       {<Tooltip open={isShowObjectCircularMenu} title={model.payload.objects[0].name}>
      //       {model.iconMui ? <model.iconMui /> : <ThreeDIcon />}</Tooltip>}
      //       {/* <img style={{ height: "50px" }} src={model.icon || "https://onlinepngtools.com/images/examples-onlinepngtools/400-by-400.png"}
      //         alt={""} /> */}
      //       {/* <div style={{ fontSize: '12px', textAlign: 'center', marginTop: '4px' }}>{model.payload.objects[0].name}</div> */}
      //     </CircleMenuItem>
      //   ))}
      // </CircleMenu>
      canEdit(spaceMembership?.role || '') && (
        <ButtonGroup variant="contained" aria-label="outlined primary button group" className={classes.horizontalSubMenu}>
          {modelMap.size > 0 && Array.from(modelMap.values())
            // .filter(m => !m['access'] || (isUserAdmin && m['access']=="ADMIN"))
            .map((model, index) => (
              <Tooltip key={`Tooltip-HorizontalSubMenuItem-in-ShowcaseOverlay-${index}`} title={model.payload.objects[0].name} placement="top">
                <Button
                  key={`HorizontalSubMenuItem-in-ShowcaseOverlay-${index}`}
                  onClick={() => {
                    setIsShowObjectCircularMenu(false);
                    Simulation.instance.addObject(model.payload.objects[0].name);
                  }}>
                  {model.iconMui ? <model.iconMui style={{ fill: "white" }} /> :
                    // <ThreeDIcon />
                    <ThreeDIcon2Svg style={{ fill: "white", height: 23 }} />
                  }
                </Button>
              </Tooltip>
              // >>>>>>> origin/s13/arp/v1.2changes:src/modules/home/SpaceDetail/SpaceView/MatterPortScreen/index.tsx
            ))}
        </ButtonGroup>
      )
    );
  }, [modelMap]);

  console.log(`[st] rendering ShowcaseOverlay`);
  return (
    <>
      <div id='showcase'
        style={{ width: '100%', position: 'relative', height: '100%' }}
        className='top frame'>

        {/* {clickedTagCoords &&
          <Box
            style={{ backgroundColor: "red", zIndex: 1, position: "absolute", left: clickedTagCoords.x + "px",
            top: clickedTagCoords.y + "px" }}>
              <Typography >
                  ${(console.log(`[st] x is is ${clickedTagCoords.x}`), `x: ${clickedTagCoords.x}, y: ${clickedTagCoords.y}`)}
              </Typography>
          </Box>} */}

        {/* {selectedTagId && getTagCoords(selectedTagId)} */}

        {/* {<Dictaphone />} */}

        {/* {clickedTagCoords && <Box
          style={{
            backgroundColor: "red", zIndex: 1, position: "absolute", left: clickedTagCoords?.x + "px",
            top: clickedTagCoords?.y + "px"
          }}>
          <Typography >
            ${(console.log(`[st] x is is ${clickedTagCoords?.x}`), `x: ${clickedTagCoords?.x}, y: ${clickedTagCoords?.y}`)}
          </Typography>
        </Box>} */}

        {currentLesson && <BannerDisplay />}

        {currentLesson && <VariablesInputDialog
          open={true}
          onClose={() => setVarInputDialog(false)}
        />}
        {/* )} */}
        {/* <VariablesDisplay showVariablesPanel={showVariablesPanel} /> */}
        {!presentationMode && <GizmoTools />}
        {/* <PropertiesPanel /> */}
        <VariablesPanel
          showVariablesPanel={showVariablesPanel}
          setShowVariablesPanel={setShowVariablesPanel}
        />

        {useMemo(() => <ShortCutPanel
          setShowVariablesPanel={setShowVariablesPanel}
          showVariablesPanel={showVariablesPanel}
          goToHomeSweep={MpSdk.Instance?.goToHomeSweep}
          lockViewDefault={lockViewDefault}
          hide3DToolsDefault={hide3DToolsDefault}
        />, [MpSdk.Instance?.mps, lockViewDefault, hide3DToolsDefault])}

        <div style={
          { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }
        }>
          <div style={{
            position: "absolute", bottom: "0%",
            // right: "200px",
            // display: "block",
            // alignSelf: "center",
            // textAlign: "center",
            //textAlign: "center",
            // backgroundColor: "#00000050",
            color: "white",
            textShadow: '0 0 5px rgb(0,0,0)'
          }}>Space: {space.name}</div>
        </div>
        {/* <ShortCutPanel goToHomeSweep={goToHomeSweep} /> */}
        {useMemo(() => <AddTag
        // onAddTag={onAddTag}
        // onEditTag={onEditTag}
        // setTagSidBeingAdded={setTagSidBeingAdded}
        />, [])}

        {(canEdit(spaceMembership?.role || '')) && !presentationMode && (
          <ButtonGroup id='quick-access-toolbar' variant="contained" aria-label="outlined primary button group" className={classes.horizontalMenu}>
            <Tooltip title="Tag" placement="top">
              <Button
                onClick={() => {
                  setIsShowObjectCircularMenu(false);
                  dispatch(showMessage("Move cursor into the Space area to add a tag!"));
                  // showcaseContext.handleAddTag();
                  dispatch({ type: SET_IS_ADDING_TAG, payload: true })
                }}>
                <TurnedInRoundedIcon style={{ fontSize: 25 }} />
              </Button>
            </Tooltip>
            {/* <Tooltip title="Task" placement="top">
            <Button
              onClick={() => {
                setIsShowObjectCircularMenu(false);
                dispatch(showMessage("Move cursor into the Space area to add a tag!"));
                // showcaseContext.handleAddTag();
                dispatch({ type: SET_IS_ADDING_TAG, payload: true })
              }}>
              <PlaylistAddCheckRoundedIcon style={{ fontSize: 25 }} />
            </Button>
          </Tooltip>
          <Tooltip title="Question" placement="top">
            <Button
              onClick={() => {
                setIsShowObjectCircularMenu(false);
                dispatch(showMessage("Move cursor into the Space area to add a tag!"));
                // showcaseContext.handleAddTag();
                dispatch({ type: SET_IS_ADDING_TAG, payload: true })
              }}>
              <HelpOutlineIcon style={{ fontSize: 25 }} />
            </Button>
          </Tooltip> */}
            <Tooltip title="3D Object" placement="top">
              <Button
                onClick={() => {
                  // toggleModelObjectSidebar();
                  if (isShowObjectCircularMenu === false) {
                    setIsShowObjectCircularMenu(true);
                  }
                  else {
                    setIsShowObjectCircularMenu(false);
                  }
                }}
                className={isShowObjectCircularMenu ? 'active' : ''}>
                {/* <ThreeDIcon /> */}
                <ThreeDIcon2Svg style={{ fill: isShowObjectCircularMenu ? theme.palette.secondary.main : "white", height: 23 }} />
              </Button>
            </Tooltip>
            {/* <Tooltip title="Upload" placement="top">
            <Button
              onClick={() => {
                setIsShowObjectCircularMenu(false);
                dispatch(showMessage("Move cursor into the Space area to add a tag!"));
                // showcaseContext.handleAddTag();
                dispatch({ type: SET_IS_ADDING_TAG, payload: true })
              }}>
              <WeekendRoundedIcon style={{ fontSize: 25 }} />
            </Button>
          </Tooltip> */}
            <Tooltip title={defaultLayerNamePlural} placement="top">
              <Button
                onClick={() => {
                  setIsShowObjectCircularMenu(false);
                  // toggleToursSidebar();
                }}>
                <LayersRoundedIcon style={{ fontSize: 25 }} />
              </Button>
            </Tooltip>
          </ButtonGroup>
        )}
        {/**  Sub CircleMenu for 3D Object */}
        {isShowObjectCircularMenu && submenuForObjectModel}

        {useMemo(() => <LessonsPlayTools />, [])}

        {useMemo(() => <div

        >{!!(tagsViewModeSecondary == ViewModeSecondary.LAYERS_TAGS || tagsViewModeSecondary == ViewModeSecondary.LAYERS_3D) && !presentationMode ?

          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
            position: 'absolute',
            height: "100%",
            right: '1%',
            // maxHeight: 'calc(100vh - 400px)',
            // minHeight: 'calc(100vh - 400px)',

            // maxHeight: '700px',
            // minHeight: '700px',

            // overflow: 'scroll',
            pointerEvents: 'none'
          }}>

            <div
              style={{
                minWidth: '300px',
                maxWidth: '300px',
                // maxHeight: 'calc(100vh - 400px)',
                // minHeight: 'calc(100vh - 400px)',

                maxHeight: '700px',
                // minHeight: '700px',
                overflow: 'scroll',
                backgroundColor: '#f4f7fe',

                borderRadius: '16px',
                pointerEvents: 'auto',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'
              }}>
              <IconButton onClick={() => dispatch(setTagsViewModeSecondary(''))} style={{ pointerEvents: 'auto' }}>
                <CloseIcon />
              </IconButton>
              {tagsViewModeSecondary == ViewModeSecondary.LAYERS_TAGS ?
                <div
                  style={{
                    minWidth: '300px',
                    maxWidth: '300px',
                    maxHeight: 'calc(100vh - 400px)',
                    minHeight: 'calc(100vh - 400px)',

                    // maxHeight: '400px',
                    // minHeight: '400px',
                    overflow: 'scroll',
                    backgroundColor: '#f4f7fe',

                    borderRadius: '16px',
                    pointerEvents: 'auto',
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'
                  }}>

                  <SidebarTabPanel />
                </div> :

                (tagsViewModeSecondary == ViewModeSecondary.LAYERS_3D ?
                  <div
                    style={{
                      minWidth: '300px',
                      maxWidth: '300px',
                      maxHeight: 'calc(100vh - 400px)',
                      minHeight: 'calc(100vh - 400px)',


                      // maxHeight: '700px',
                      // minHeight: '700px',
                      overflow: 'scroll',
                      backgroundColor: '#f4f7fe',

                      borderRadius: '16px',
                      pointerEvents: 'auto',
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'
                    }}> <InsertedObjects /></div>
                  :
                  <></>

                )
              }
            </div>

          </div>

          : <></>}
        </div>, [tagsViewModeSecondary, presentationMode])}

        {useMemo(() => <Frame
          // src={src}
          space={space}
          project={project}
        // space={{space: {id}}}
        ></Frame>, [space?.id, project?.id])}
      </div>
    </>
  );
}
export default ShowcaseOverlay;
ShowcaseOverlay.whyDidYouRender = true;