import * as THREE from 'three';
import {WindowingSystem} from './WindowingSystem';
import {CameraSystem} from './CameraSystem';

export interface RenderCallback {
    (timestamp: any, frame: any): void;
}

export class RenderingSystem {
    protected container: HTMLElement;
    protected _renderer: THREE.WebGLRenderer;
    protected _scene: THREE.Scene;
    protected clock:THREE.Clock;
    protected _stepsPerFrame:number;
    protected _stepsPerFrameInverse:number;
    
    protected renderCallbacks: RenderCallback[];
    private _baseObject3D:THREE.Object3D;
    
    
    constructor(protected _cameraSystem: CameraSystem) {
        this.container = document.createElement('div');
        this.clock = new THREE.Clock();
        
        this._renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
        this._renderer.setPixelRatio(window.devicePixelRatio);
        this._renderer.setSize(this._cameraSystem.windowingSystem.width, this._cameraSystem.windowingSystem.height);
        this.container.appendChild(this._renderer.domElement);
        this._cameraSystem.windowingSystem.initContainer(this.container);
        
        this.renderCallbacks = [];
        
        this.stepsPerFrame = 1;
        
        this._scene = new THREE.Scene();
        this._baseObject3D = new THREE.Object3D();
        this._scene.add(this._baseObject3D);
    }
    
    public set stepsPerFrame(x) {
        this._stepsPerFrame = x;
        this._stepsPerFrameInverse = 1.0 / this._stepsPerFrame;
    }
    
    public get stepsPerFrame():number {
        return this._stepsPerFrame;
    }
    
    public registerRenderCallback(renderCallback: RenderCallback): number {
        return this.renderCallbacks.push(renderCallback) - 1;
    }
    
    public unregisterRenderCallback(index: number): void {
        this.renderCallbacks.splice(index, 1);
    }
    
    public startRenderer() {
        window.addEventListener('resize', this.onWindowResize.bind(this));
        
        this._renderer.setAnimationLoop(this.render.bind(this));
    }
    
    protected render(timestamp: any, frame: any) {
        this._cameraSystem.windowingSystem.processAllFrameUpdateCallbacks();
        
        const deltaTime = Math.min( 0.05, this.clock.getDelta() ) * this._stepsPerFrameInverse;
        
        for (let i = 0; i < this._stepsPerFrame; i++) {
            this._cameraSystem.windowingSystem.processAllUpdateCallbacks(deltaTime);
        }
        
        for (const renderCallback of this.renderCallbacks) {
            renderCallback(timestamp, frame);
        }
        this.renderer.render(this._scene, this._cameraSystem.camera);
    }
    
    protected onWindowResize() {
        this._cameraSystem.camera.aspect = this._cameraSystem.windowingSystem.aspectRatio;
        this._cameraSystem.camera.updateProjectionMatrix();
        
        this._renderer.setSize(this._cameraSystem.windowingSystem.width, this._cameraSystem.windowingSystem.height);
    }
    
    public disableXR() {
        this.renderer.xr.enabled = false;
    }
    
    get renderer(): THREE.WebGLRenderer {
        return this._renderer;
    }
    
    get scene(): THREE.Scene {
        return this._scene;
    }
    
    get base(): THREE.Object3D {
        return this._baseObject3D;
    }
    
    get cameraSystem(): CameraSystem {
        return this._cameraSystem;
    }
    
}
