import React, {  } from 'react';
import {createStyles, WithStyles, withStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import OpenWithRoundedIcon from '@material-ui/icons/OpenWithRounded';
import RotateRightRoundedIcon from '@material-ui/icons/RotateRightRounded';
import AspectRatioRoundedIcon from '@material-ui/icons/AspectRatioRounded';
//import { Simulation } from 'mp/core/craEngine/Simulation';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
//import { Vector3 } from 'three';
import * as THREE from "three"
import Utils from 'mp/core/craEngine/Tools/Utils';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GizmoInteroparabilitySubSystem, { GizmoModes } from 'mp/core/craEngine/SubSystems/ancillary/GizmoInteroparabilitySubSystem';
import NodeStorage from 'mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage';

export class PropertiesPanelGizmoToolbarUIData {
  disableScale: boolean;
  nameToShow:string;

  constructor() {
      this.disableScale = false;
      this.nameToShow = "";
  }
}


const styles = (theme:any) =>createStyles({
  fab: {
    margin: theme.spacing(2),
  },
  gizmoButton: {

  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    top: 200,
    left: 200
  },
  gizmoIcon:{
height: '10px'
  }
});

interface Props extends WithStyles<typeof styles>{
// spaceModels: Map<string, any>;
// updateNodes(): void;
}

interface State {
  uiData:PropertiesPanelGizmoToolbarUIData;
}


class GizmoTools extends React.Component<Props, State> {

  inputEl: any;
  gizmoToolUI: any;

  public gizmoMoveElement:HTMLElement;

  state =
  {
    uiData: new PropertiesPanelGizmoToolbarUIData()
  }


  gizmoElementStyle = {
      position: 'absolute',
      left: "10px",
      top: "50px",
      visibility: "hidden",
  } as React.CSSProperties;

  componentWillUnmount() {
      //instance = null;
  }

  componentDidMount() {
  }

  componentDidUpdate() {
    if(!this.gizmoMoveElement) {
      if(this.gizmoToolUI.current) {
        this.gizmoMoveElement = document.getElementById((this.gizmoToolUI.current as any).id)!;
        this.hideTools();
      }
    }
  }

  /*
  public showTools(_uiData:PropertiesPanelGizmoToolbarUIData):void {

    if(!Simulation.instance.propertyPanelShowOverride[0]) {
      return;
    }

    this.setState({
      uiData: _uiData
    })
    this.gizmoMoveElement.style.visibility = 'visible';
  }*/

  public setPosition(_uiData:PropertiesPanelGizmoToolbarUIData, pos : THREE.Vector3|null, _2Dmin:THREE.Vector3|null, _2Dmax: THREE.Vector3|null):void {
    //this.gizmoMoveElement.style.left = Math.round(pos.x - this.gizmoMoveElement.offsetWidth * 0.5) + "px";
    //this.gizmoMoveElement.style.top = Math.round(pos.y - this.gizmoMoveElement.offsetHeight * 0.5) + "px";

    if(!(pos == null || _2Dmin == null || _2Dmax == null) && !!this.gizmoMoveElement) {
      let halfGizmoToolsIconsWidth = this.gizmoMoveElement.offsetWidth * 0.5;
      let halfGizmoToolsIconsHeight = this.gizmoMoveElement.offsetHeight * 0.5;

      let finalX = Math.round(pos.x - halfGizmoToolsIconsWidth - 10);
      let finalY = Math.round(_2Dmin.y + halfGizmoToolsIconsHeight * 2 + 10);

      let resolution = Simulation.instance.renderingSubSystem.getScreenResolution();

      let rightEdge = resolution.x - this.gizmoMoveElement.offsetWidth;
      let bottomEdge = resolution.y - this.gizmoMoveElement.offsetHeight;


      if(finalY >= bottomEdge) {
        finalY = Math.round(_2Dmax.y - halfGizmoToolsIconsHeight * 2 - 10);
      }

      if(finalX < 0) {
        finalX = Math.round(_2Dmin.x + halfGizmoToolsIconsWidth * 2 + 10);
      } else if(finalX >= rightEdge) {
        finalX = Math.round(_2Dmin.x - halfGizmoToolsIconsWidth * 2 - 10);
      }

      // this.gizmoMoveElement.style.left = finalX + "px";
      // this.gizmoMoveElement.style.top = finalY + "px";

      // this.gizmoMoveElement.style.left = "40%";
      // this.gizmoMoveElement.style.top = "85%"
      this.gizmoMoveElement.style.left = finalX + "px";
      this.gizmoMoveElement.style.top = finalY + "px";
    }

    console.log(`[st] 3 setPropertyPanelShowOverride ${Simulation.instance.propertyPanelShowOverride[0]}`);
    if(!Simulation.instance.propertyPanelShowOverride[0]) {
      return;
    }

    this.setState({
      uiData: _uiData
    })
    this.gizmoMoveElement.style.visibility = 'visible';
  }

  public hideTools():void {
    this.gizmoMoveElement && (this.gizmoMoveElement.style.visibility = 'hidden');
  }

  private static _instance:GizmoTools;

  public static get instance():GizmoTools {
    return GizmoTools._instance;
  }

  constructor(props:Props) {
    super(props);

    this.inputEl = React.createRef();
    this.gizmoToolUI = React.createRef();
    this.onButtonClick = this.onButtonClick.bind(this);

    this.onTranslateClick = this.onTranslateClick.bind(this);
    //Simulation.instance.gizmoToolsScreen = this;
    GizmoTools._instance = this;
  }

  public async onTranslateClick() {
    await GizmoInteroparabilitySubSystem.instance.ChangeGizmoMode(GizmoModes.TRANSLATE_GIZMO_MODE);
    var showcase = document.getElementById('sdk-iframe');
    showcase?.focus();
  }

  public async onRotateClick() {
    await GizmoInteroparabilitySubSystem.instance.ChangeGizmoMode(GizmoModes.ROTATE_GIZMO_MODE);
    var showcase = document.getElementById('sdk-iframe');
    showcase?.focus();
  }

  public async onScaleClick() {
    await GizmoInteroparabilitySubSystem.instance.ChangeGizmoMode(GizmoModes.SCALE_GIZMO_MODE);
    var showcase = document.getElementById('sdk-iframe');
    showcase?.focus();
  }

  public async onDuplicateClick() {
    await NodeStorage.duplicateNode();
    var showcase = document.getElementById('sdk-iframe');
    showcase?.focus();
  }

  public async onDeleteClick() {
    // showDeleteConfirmation(true);

    let res = window.confirm(`Are you sure you want to delete ${this.state.uiData.nameToShow}?`);
    if (res){
      await NodeStorage.deleteNode();
    }

  }
  public onButtonClick() {

    var div = document.getElementById((this.gizmoToolUI.current as any).id);
    //(this.inputEl!.current as any).disabled = !(this.inputEl!.current as any).disabled;
  }

  render() {
    const { classes } = this.props;
    let hideScaleGizmo:boolean = this.state.uiData.disableScale;
    /*
    if(this.props.simulationInterop?.currentNodeID) {
      let currentNode = this.props.spaceModels.get(this.props.simulationInterop?.currentNodeID);

      if(currentNode) {
        let nodeUserData = currentNode[UserDataProperties.userData];
        if(UserDataGizmoMinorMods.disableScale in nodeUserData) {
          hideScaleGizmo = nodeUserData[UserDataGizmoMinorMods.disableScale];
        }
      }
    }*/

    return(console.log("rendering GizmoTools"),
      <div>

      <div id="gizmoTool" ref={this.gizmoToolUI} style={Utils.SimpleClone(this.gizmoElementStyle)}>
      {/* <ShowcaseButton items={[
        {title: "Move", onClick: () => this.onTranslateClick(), icon: MoveIcon},
        {title: "Rotate", onClick: () => this.onRotateClick()},
        this.state.uiData.disableScale && {title: "Scale", onClick: () => this.onScaleClick()},
        {title: "Duplicate", onClick: () => this.onDuplicateClick()},
        {title: "Delete", onClick: () => this.onDeleteClick.bind(this)},
        ]}/> */}
      <Grid container justify='center' alignItems='center'>

        <Tooltip arrow  title="Move" aria-label='move'>
          <Fab color='secondary' size="medium"  className={classes.gizmoButton} onClick={this.onTranslateClick} >
            <OpenWithRoundedIcon />
          </Fab>
        </Tooltip>
        <Tooltip arrow  title="Rotate" aria-label='rotate'>
          <Fab color='secondary' size="medium"  className={classes.gizmoButton} onClick={this.onRotateClick}>
            <RotateRightRoundedIcon />
          </Fab>
        </Tooltip>

        {
          this.state.uiData.disableScale ?
          null :
          <Tooltip arrow  title="Scale" aria-label='scale'>
            <Fab color='secondary' size="medium"  className={classes.gizmoButton} onClick={this.onScaleClick}>
              <AspectRatioRoundedIcon />
            </Fab>
          </Tooltip>
        }
        <Tooltip arrow  title="Duplicate" aria-label='scale'>
          <Fab color='secondary' size="medium"  className={classes.gizmoButton} onClick={this.onDuplicateClick} >
            <FileCopyIcon />
          </Fab>
        </Tooltip>
        <Tooltip arrow  title="Delete" aria-label='scale'>
          <Fab color='secondary' size="medium"  className={classes.gizmoButton} onClick={this.onDeleteClick.bind(this)} >
            <DeleteIcon />
          </Fab>
        </Tooltip>
        </Grid>
      </div>
    </div>
    )
  }
}

//export default GizmoTools;
export default withStyles(styles, { withTheme: true })(GizmoTools);
export {GizmoTools};
/*
const GizmoTools: React.FC<Props> = ({ }) => {
  const classes = useStyles();
  const [debugValue, setDebugValue, getDebugValue] = useSetState<string>("aaa");

  const updatePosition = () => {

  }

  useEffect(() => {

    setDebugValue(JSON.stringify(Simulation.debugValue));

    window.addEventListener('mousemove', () => {});

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('mousemove', () => {})
    }
  }, [])

  const moveToolUI = useRef(null);

  const inputEl = useRef(null);
  const onButtonClick = () => {

    setDebugValue(JSON.stringify(Simulation.debugValue));
    // `current` points to the mounted text input element


    (inputEl!.current as any).disabled = !(inputEl!.current as any).disabled;
  };

  return (
    <div>
      <input ref={inputEl} type="text" />
      <button onClick={onButtonClick}>Focus the input</button>
      <Tooltip arrow  ref={moveToolUI} title={debugValue} aria-label='move' className={classes.absolute}>
        <Fab color='secondary' size="medium"  className={classes.gizmoButton}>
          <OpenWithRoundedIcon />
        </Fab>
      </Tooltip>
    </div>
  );

  function useSetState<State = any>(initialState: State | (() => State)) {
    const [state, setState] = useState<State>(initialState);
    const getState = async (): Promise<State> => {
      let state: unknown;

      await setState((currentState: State) => {
        state = currentState;
        return currentState;
      });
      return state as State;
    };

    return [state, setState, getState] as [
      State,
      typeof setState,
      typeof getState,
    ];
  }
}*/
