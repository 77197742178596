import { Dispatch } from 'redux';
import { AppActions } from '../../types';

import { fetchError, fetchStart, fetchSuccess, showMessage } from './Common';
import {
  GET_SPACES_LIST,
  SET_SPACE_DATA,
  SET_FILTER_DATA_SPACES,
  GET_LESSONS_LIST,
  SET_LESSON_DATA,
  SET_SPACE_TAGS_LIST,
  SET_TAGS_VIEW_MODE,
  SET_TAG_GROUP_LIST,
  SET_TAG_GROUP_ID,
  SET_VARIABLE_VALUE,
  SET_CURRENT_SHOWCASE_TAGS,
  SET_SPACE_MODELS_LIST,
  SET_SELECTED_TAG,
  SET_CATALOG_ITEMS,
  // SET_CLOSE_ALL_POPUPS,
  SET_EDIT_SHOWCASE_TAG_ID,
  SET_OPEN_TAG_FORM,
  // SET_CLOSE_ALL_POPUPS,
  // SET_TASK_FOR_EDIT,
  SET_TAGS_VIEW_MODE_SECONDARY,
  SET_BROAD_SIDEBAR_OPEN,
  OPEN_RADIAL_MENU,
  SET_CURRENT_CUSTOM_DATA,
  SET_PRESENTATION_MODE
} from 'types/actions/Home.action';
import {
  ILesson,
  SpaceData,
  ShowcaseTag,
  TagGroup,
  IActivityLog,
  ACTIONS,
  OBJECT_TYPES,
  IShareSpace,
  CustomData,
  CustomDataType,
  CustomDataField,
  FieldType
} from 'types/models/home/HomeApp';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { AuthUser } from 'types/models/AuthUser';
import { appIntl } from '@crema/utility/Utils';
import Api from '@crema/services/ApiConfig';
import { SpaceErrors } from 'types/models/dataAccess/Space';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { defaultLayerNameSingular } from 'shared/constants/AppConst';
import { store } from 'App';
import { historyHook } from 'modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils';
import { Logic, LogicEngine } from 'types/models/dataAccess/Logic';
// import { useHistory } from 'react-router-dom';
import { getMetadata } from 'types/models/Model';
import NotificationService from '@crema/services/NotificationService';
import { baseUrl } from 'Config';
import { CollaboratorObj, ProjectObj } from 'types/models/apps/ProjectBoard';
import { userRoles } from '@crema';
import { getCurrentTagGroup } from 'modules/home/SpaceDetail/utils';
import { onStopLesson } from './Lesson';
import _ from 'lodash';

export const onGetSpacesList = (): any => {
  const { messages } = appIntl();

  return (dispatch: Dispatch<AppActions>) => {
    // dispatch(fetchStart());
    return Api.get('/api/spaces/list')
      .then(data => {
        if (data.status === 200) {
          dispatch({ type: GET_SPACES_LIST, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
          throw messages['message.somethingWentWrong'];
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};


export const onGetCatalogItems = (
  // filterData: FilterDataSpaces,
  // user: AuthUser | null,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    // if (user != null) {
    dispatch(fetchStart());
    // let res: SpaceData[] = [];
    firestore.collection(`catalog`).get().then(qs => {

      let items = qs.docs.map(doc => doc.data()).filter(x => x.name).sort((x, y) => x.createdOn - y.createdOn);
      dispatch(fetchSuccess());
      dispatch({ type: SET_CATALOG_ITEMS, payload: items });

    }
    ).catch((error) => {
      console.log('Error getting documents: ', error);
    });
    // }
  }
}

export const onGetSpace = (params: { did: string, id?: string, pDid?: string }, onFailure: (err: any) => void): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    Api.get(`/api/spaces/${params.did}`, { params })
      .then(data => {
        if (data.status === 200) {
          let payload = data.data;
          if (!!payload.code) {
            dispatch(fetchError(SpaceErrors.get(payload.code)));
            setTimeout(() => {
              dispatch(fetchError("")); //clear error message after a few seconds, so next error can be displayed
            }
              , 3000);
          } else {
            dispatch(fetchSuccess());
            dispatch({ type: SET_SPACE_DATA, payload: data.data });
          }

        } else {
          console.log(JSON.stringify(data))
          dispatch(fetchError('Space not found'));
        }
      })
      .catch(error => {

        console.log(JSON.stringify(error));
        onFailure(error);

        // history && history.goBack();

        // dispatch(fetchError(error.response.statusText));
      });
  };
}

export const setCurrentSpace = (space: SpaceData): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_SPACE_DATA, payload: space });
  };
};

export const onSetVariable = (varNameValue: any): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_VARIABLE_VALUE, payload: varNameValue });
  };
};

export const setFilters = (filters: any): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_FILTER_DATA_SPACES, payload: filters });
  };
};

export const deleteTag = (
  id: string,
  tagCollectionPath: string,
  tagCollectionName: string,
  sceneTagCollectionPath: string,
  sceneTagCollectionName: string,
): any => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      const deleteTagRef: any = await firestore
        .doc(tagCollectionPath)
        .collection(tagCollectionName)
        .doc(id);

      // const { id: deleteTagRefId } = deleteTagRef;
      return deleteTagRef.delete();
    } catch (error: any) {
      console.log('Error delete tag: ', error?.message);
      return error;
    }
  };
};

export const addToSpaceTags = (showcaseTag: ShowcaseTag): any => {

  return (dispatch: Dispatch<AppActions>) => {
    let tagMap = store.getState().home.spaceTags || new Map();
    tagMap.set(showcaseTag.id, showcaseTag);
    dispatch(setSpaceTagsList(tagMap));

  }
  // spaceTags = new Map(
  //   Array.from(spaceTags).sort(
  //     (a: [string, ShowcaseTag], b: [string, ShowcaseTag]) =>
  //       (new Date(b[1].createdOn || '').getTime() || -Infinity) -
  //       (new Date(a[1].createdOn || '').getTime() || -Infinity),
  //   ),
  // );
  // return (dispatch: Dispatch<AppActions>) => {
  //   dispatch({ type: SET_SPACE_TAGS_LIST, payload: spaceTags });
  // };
};

export const updateTag = (tag: ShowcaseTag): any => {

  return (dispatch: Dispatch<AppActions>) => {
    let st = _.cloneDeep(store.getState().home?.spaceTags);
    st.set(tag.id, tag);

    dispatch(setSpaceTagsList(st));
  };
}

export const setSpaceTagsList = (spaceTags: Map<string, ShowcaseTag>): any => {
  spaceTags = new Map(
    Array.from(spaceTags).sort(
      (a: [string, ShowcaseTag], b: [string, ShowcaseTag]) =>
        (new Date(b[1].createdOn || '').getTime() || -Infinity) -
        (new Date(a[1].createdOn || '').getTime() || -Infinity),
    ),
  );
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_SPACE_TAGS_LIST, payload: spaceTags });
  };
};

export const setCurrentSpaceTagsList = (spaceTags: ShowcaseTag[]): any => {
  spaceTags.sort(
    (a: ShowcaseTag, b: ShowcaseTag) =>
      (new Date(b.createdOn || '').getTime() || -Infinity) -
      (new Date(a.createdOn || '').getTime() || -Infinity),
  );
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CURRENT_SHOWCASE_TAGS, payload: spaceTags });
  };
};

export const setSpaceModelsList = (spaceModels: Map<string, any>): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_SPACE_MODELS_LIST, payload: spaceModels });
  };
};


// export const updateSpaceTagsList = (updatedSpaceTags: Map<string, ShowcaseTag>, spaceTags: Map<string, ShowcaseTag>) => {

//   let tagMap = new Map();
//   spaceTags.forEach(t => tagMap.set(t.id, t));
//   updatedSpaceTags.forEach(t => )

//   return (dispatch: Dispatch<AppActions>) => {
//     dispatch({type: SET_SPACE_TAGS_LIST, payload: spaceTags});
//   };
// }

// export const onSaveVariablesList = (currentSpace: SpaceData, authUser: AuthUser | null, toSaveVariableList: any[]) => {

//   return (dispatch: Dispatch<AppActions>) => {
//     if (authUser != null) {
//       dispatch(fetchStart());

//       firestore.doc(`Spaces/${currentSpace.id}`).update({ variables: toSaveVariableList })
//         .then((querySnapshot) => {
//           dispatch({ type: SET_SPACE_DATA, payload: currentSpace })
//           dispatch(fetchSuccess());

//         })
//         .catch((error) => {
//           console.log('Error saving variables: ', error);
//         });
//     } else {
//       dispatch(fetchError('User is not logged in! Please try again'));
//     }
//   };
// };

export const logUserActivity = (action: ACTIONS, object: OBJECT_TYPES, objectId: string = ''): any => {
  return async (dispatch: Dispatch<AppActions>) => {
    let userId = store.getState().auth.authUser?.uid;
    const newActivityLogRef =
      await firestore
        .doc(`Users/${userId}`)
        .collection('ActivityLog')
        .doc();

    const { id: activityDocId } = newActivityLogRef;
    let newActivity: IActivityLog = {
      id: activityDocId,
      uid: userId,
      action: action,
      object: object,
      objectId: objectId,
      timestamp: new Date(),
    };
    newActivityLogRef
      .set(newActivity)
      .then(() => {
        return newActivity;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchError(error.message));
      });
  };
};

export const setTagsViewModeFromSidebar = (mode: string, history?: any): any => {
  return (dispatch: Dispatch<AppActions>) => {

    dispatch(onStopLesson());
    historyHook && historyHook.push(`/home/space/${store.getState().home.currentSpace?.did}/project/${store.getState().home.currentSpace?.currentSpaceProject.did}`);

    // const history = useHistory();
    // history.push(`/home/space/${currentSpace?.did}/`);
    // useHistoryPush(`/home/space/${store.getState().home.currentSpace?.did}/`);

    dispatch({ type: SET_TAGS_VIEW_MODE, payload: mode });
  };
};

export const setTagsViewMode = (mode: string, history?: any): any => {
  return (dispatch: Dispatch<AppActions>) => {
    // if(store.getState().home.tagsViewMode !== ViewMode.LAYERS && store.getState().home.tagsViewModeSecondary !== ViewMode.LAYERS){
    //   dispatch(onStopLesson());
    //   historyHack && historyHack.push(`/home/space/${store.getState().home.currentSpace?.did}/`);
    // }

    // const history = useHistory();
    // history.push(`/home/space/${currentSpace?.did}/`);
    // useHistoryPush(`/home/space/${store.getState().home.currentSpace?.did}/`);

    dispatch({ type: SET_TAGS_VIEW_MODE, payload: mode });
  };
};

export const setWorkflowsSidebarOpen = (isOpen: boolean): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_BROAD_SIDEBAR_OPEN, payload: isOpen });
  };
};

export const setTagsViewModeSecondary = (mode: string): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_TAGS_VIEW_MODE_SECONDARY, payload: mode });
  };
};

export const setSelectedTag = (tag: string): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_SELECTED_TAG, payload: tag });
  };
};

// export const setCloseAllPopUp = (mode: boolean) => {
//   return (dispatch: Dispatch<AppActions>) => {
//     dispatch({ type: SET_CLOSE_ALL_POPUPS, payload: mode });
//   };
// };

export const setEditShowcaseTagId = (id: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_EDIT_SHOWCASE_TAG_ID, payload: id });
  };
};

export const setOpenTagForm = (value: boolean) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_OPEN_TAG_FORM, payload: value });
  };
};

// export const setTagSidBeingAdded = (value: any) => {

//   // return (dispatch: Dispatch<AppActions>) => {
//   //   dispatch({ type: SET_TAG_SID_BEING_ADDED, payload: value });
//   // };
// };


export const onShareSpace = (form: IShareSpace, space: SpaceData, projectObj: ProjectObj, authUser: AuthUser | null) => {
  const { messages } = appIntl();

  return (dispatch: Dispatch<AppActions | any>) => {
    firestore
      .collection('Users')
      .where('email', '==', form.email)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.size !== 0) {
          let recipient = querySnapshot.docs[0].data();
          // let userId = recipient.id;
          let membershipsRef = firestore.collection(`SpaceMemberships`);

          // Check Space Memberships if exist then update role otherwise show user already exist with same role
          firestore
            .collection('SpaceMemberships')
            .where('userId', '==', recipient.id)
            .where('spaceId', '==', space?.id)
            .get()
            .then(async (querySnapshot) => {
              if (querySnapshot.size !== 0) {
                let membership = querySnapshot.docs[0].data();
                let project = await firestore.collection(`Projects`).doc(projectObj.id).get();
                project.exists && !((project.data()?.memberIdList || []).includes(recipient.id)) && await firestore.collection(`Projects`).doc(project.id).update({ memberIdList: [...project.data()?.memberIdList, recipient.id] });

                if (membership.role.toUpperCase() !== userRoles.OWNER) {
                  if (form.role.toUpperCase() !== membership.role.toUpperCase()) {
                    await membershipsRef.doc(membership.id).delete();

                    let doc = membershipsRef.doc();
                    await doc.set({
                      userId: recipient.id,
                      spaceId: space?.id,
                      role: form.role.toUpperCase(),
                      ...getMetadata(authUser?.uid || '', doc.id)
                    });

                    dispatch(NotificationService.shareSpaceWithUser(`home/space/${space?.did}/project/${projectObj.did}`, authUser?.uid || '', recipient.id,
                      {
                        fromFirstName: authUser ? authUser.user.firstName : '',
                        fromLastName: authUser ? authUser.user.lastName : '',
                        toEmail: form.email,
                        spaceLink: `${baseUrl}/home/space/${space?.did}/project/${projectObj.did}`,
                        spaceName: space?.name || ''
                      }));

                    dispatch(showMessage(`Shared space with ${form.email}`));
                    console.log(`[st] shared space mem id: ${doc.id}`);
                  } else {
                    dispatch(fetchError(`Already shared space with ${form.email} with role ${form.role}`));
                  }
                } else {
                  dispatch(fetchError(`You don't have access to change ${userRoles.OWNER} role!`));
                }
              } else {
                let doc = membershipsRef.doc();
                await doc.set({
                  userId: recipient.id,
                  spaceId: space?.id,
                  role: form.role.toUpperCase(),
                  ...getMetadata(authUser?.uid || '', doc.id)
                });

                let project = await firestore.collection(`Projects`).doc(projectObj.id).get();
                project.exists && !((project.data()?.memberIdList || []).includes(recipient.id)) && await firestore.collection(`Projects`).doc(project.id).update({ memberIdList: [...project.data()?.memberIdList, recipient.id] });

                dispatch(NotificationService.shareSpaceWithUser(`home/space/${space?.did}/project/${projectObj.did}`, authUser?.uid || '', recipient.id,
                  {
                    fromFirstName: authUser ? authUser.user.firstName : '',
                    fromLastName: authUser ? authUser.user.lastName : '',
                    toEmail: form.email,
                    spaceLink: `${baseUrl}/home/space/${space?.did}/project/${projectObj.did}`,
                    spaceName: space?.name || ''
                  }));

                dispatch(showMessage(`Shared space with ${form.email}`));
                console.log(`[st] shared space mem id: ${doc.id}`);
              }
            })
            .catch((error) => {
              console.log(error);
              fetchError(messages['message.somethingWentWrong'] as string);
            });
        } else {
          // if user not exist in User collection
          let invitesRef = firestore.collection(`SpaceInvites`);

          // Check Space Invites if exist then update role otherwise show user already exist with same role
          firestore
            .collection('SpaceInvites')
            .where('userEmail', '==', form.email)
            .where('spaceId', '==', space?.id)
            .get()
            .then(async (querySnapshot) => {
              if (querySnapshot.size !== 0) {
                let invites = querySnapshot.docs[0].data();
                if (form.role.toUpperCase() !== invites.role.toUpperCase()) {
                  let doc = invitesRef.doc(invites.id);
                  await doc.delete();

                  Api.post(`/api/spaces/${space?.did}/invite`, { "email": form.email, "role": form.role })
                    .then(async data => {
                      if (data.status === 200) {
                        dispatch(fetchSuccess());
                        dispatch(showMessage(`Invite sent to ${form.email}`));

                        let project = await firestore.collection(`Projects`).doc(projectObj.id).get();
                        project.exists && !((project.data()?.memberListInvites || []).some((el: CollaboratorObj) => el.email == form.email)) && await firestore.collection(`Projects`).doc(project.id).update({ memberListInvites: [...project.data()?.memberListInvites, form] });
                        dispatch(NotificationService.inviteNewUserToSpace(`home/space/${space?.did}/project/${projectObj.did}`, authUser?.uid || '',
                          {
                            fromFirstName: authUser ? authUser.user.firstName : '',
                            fromLastName: authUser ? authUser.user.lastName : '',
                            toEmail: form.email,
                            spaceLink: `${baseUrl}/home/space/${space?.did}/project/${projectObj.did}`,
                            spaceName: space?.name || ''
                          }));
                        // dispatch({ type: GET_SPACES_LIST, payload: data.data });
                      } else {
                        dispatch(
                          fetchError(messages['message.somethingWentWrong'] as string),
                        );
                      }
                    })
                    .catch(error => {
                      dispatch(fetchError(error.message));
                    });
                } else {
                  dispatch(fetchError(`Already invite sent to ${form.email}`))
                }
              } else {
                Api.post(`/api/spaces/${space?.did}/invite`, { "email": form.email, "role": form.role })
                  .then(async data => {
                    if (data.status === 200) {
                      dispatch(fetchSuccess());
                      dispatch(showMessage(`Invite sent to ${form.email}`));

                      let project = await firestore.collection(`Projects`).doc(projectObj.id).get();
                      project.exists && !((project.data()?.memberListInvites || []).some((el: CollaboratorObj) => el.email == form.email)) && await firestore.collection(`Projects`).doc(project.id).update({ memberListInvites: [...project.data()?.memberListInvites, form] });
                      dispatch(
                        NotificationService.inviteNewUserToSpace(
                          `home/space/${space?.did}/project/${projectObj.did}`,
                          authUser?.uid || '',
                          {
                            fromFirstName: authUser ? authUser.user.firstName : '',
                            fromLastName: authUser ? authUser.user.lastName : '',
                            toEmail: form.email,
                            spaceLink: `${baseUrl}/home/space/${space?.did}/project/${projectObj.did}`,
                            spaceName: space?.name || '',
                          },
                        ),
                      );
                      // dispatch({ type: GET_SPACES_LIST, payload: data.data });
                    } else {
                      dispatch(
                        fetchError(messages['message.somethingWentWrong'] as string),
                      );
                    }
                  })
                  .catch((error) => {
                    dispatch(fetchError(error.message));
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              fetchError(messages['message.somethingWentWrong'] as string);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        fetchError(messages['message.somethingWentWrong'] as string);
      });
  };
};
export const showRadialMenu = (open: boolean) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: OPEN_RADIAL_MENU, payload: open });
  }
}

export const saveSpaceCustomData = (spaceId: string, name: string, userId: string | undefined, customDataType: CustomDataType) => {
  return async (dispatch: Dispatch<AppActions>) => {
    const customDataRef =
      await firestore
        .doc(`Spaces/${spaceId}`)
        .collection('CustomData')
        .doc();

    const { id: customDataId } = customDataRef;
    let customData: CustomData = {
      id: customDataId,
      name: name,
      customDataType: customDataType,
      createdBy: userId ? userId : '',
      createdOn: new Date()
    };

    customDataRef
      .set(customData)
      .then(() => {
        dispatch({ type: SET_CURRENT_CUSTOM_DATA, payload: customData });
        dispatch(fetchSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchError(error.message));
      });

    return customData;
  };
};

export const saveSpaceCustomDataField = (spaceId: string, customDataId: string, customDataFieldType: FieldType, data: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    const customDataFieldRef =
      await firestore
        .doc(`Spaces/${spaceId}/CustomData/${customDataId}`)
        .collection('Fields')
        .doc();

    const { id: customDataFieldId } = customDataFieldRef;
    let customDataField: CustomDataField = {
      id: customDataFieldId,
      fieldType: customDataFieldType,
      data: data
    };

    customDataFieldRef
      .set(customDataField)
      .then(() => {
        dispatch(fetchSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchError(error.message));
      });

    return customDataField;
  };
};

export const linkSpaceCustomDataFieldToTag = (spaceId: string, customDataId: string, fieldWithTag: CustomDataField) => {
  return async (dispatch: Dispatch<AppActions>) => {
    await firestore
      .doc(`Spaces/${spaceId}/CustomData/${customDataId}/Fields/${fieldWithTag.id}`)
      .set(fieldWithTag)
      .then(() => {
        dispatch(fetchSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchError(error.message));
      });
  };
};
