export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'spaces',
    title: 'Spaces',
    messageId: 'sidebar.spaces',
    type: 'item',
    icon: 'dashboard',
    url: '/home/spaces',
  },
  // {
  //   id: 'sharedSpaces',
  //   title: 'Shared Spaces',
  //   messageId: 'sidebar.sharedSpaces',
  //   type: 'item',
  //   icon: 'share',
  //   url: '/home/shared-spaces',
  // },
  {
    id: 'projects',
    title: 'Project Status',
    messageId: 'sidebar.apps.projectboard',
    type: 'item',
    icon: 'taskAlt',
    url: '/apps/projects',
  },
  {
    id: 'reports',
    title: 'Reports',
    messageId: 'sidebar.reports',
    type: 'item',
    icon: 'widgets',
    url: '/reports',
  },
];
export default routesConfig;
