import * as THREE from 'three';
import {WindowingSystem} from './WindowingSystem';

export class CameraSystem {
    protected _camera:THREE.PerspectiveCamera;
    protected mouseDown:boolean;
    
    public aimSensitivity:number;
    public cameraSpeed:number;
    
    protected direction:THREE.Vector3;
    
    constructor(protected _windowingSystem:WindowingSystem) {
        this._camera = new THREE.PerspectiveCamera(75, this._windowingSystem.aspectRatio, 0.1, 1000);
        this.camera.rotation.order = 'YXZ';
        this.mouseDown = false;
        this._windowingSystem.inputSystem.registerMouseDownCallback(this.onMouseDown.bind(this));
        this._windowingSystem.inputSystem.registerMouseUpCallback(this.onMouseUp.bind(this));
        this._windowingSystem.inputSystem.registerMouseMoveCallback(this.onMouseMove.bind(this));
        
        this._windowingSystem.registerUpdateCallback(this.onUpdate.bind(this))
        
        this.aimSensitivity = 2.5;
        this.cameraSpeed = 1;
        this.direction = new THREE.Vector3()
    }
    
    protected onUpdate(deltaTime:number):void {
        //console.log(deltaTime);
        if (this._windowingSystem.inputSystem.keyStates['KeyW']) {
            this.camera.getWorldDirection(this.direction);
            this.camera.position.add(this.direction.clone().multiplyScalar(this.cameraSpeed * deltaTime));
        } else if (this._windowingSystem.inputSystem.keyStates['KeyS']) {
            this.camera.getWorldDirection(this.direction);
            this.camera.position.sub(this.direction.clone().multiplyScalar(this.cameraSpeed * deltaTime));
        }
    
        if (this._windowingSystem.inputSystem.keyStates['KeyA']) {
            this.camera.getWorldDirection(this.direction);
            let strafeVector = this.camera.up.clone().cross(this.direction);
            this.camera.position.add(strafeVector.clone().multiplyScalar(this.cameraSpeed * deltaTime));
        } else if (this._windowingSystem.inputSystem.keyStates['KeyD']) {
            this.camera.getWorldDirection(this.direction);
            let strafeVector = this.camera.up.clone().cross(this.direction);
            this.camera.position.sub(strafeVector.clone().multiplyScalar(this.cameraSpeed * deltaTime));
        }
    }
    
    protected onMouseDown(e:MouseEvent):void {
        // console.log("mouse down " + e);
        // console.log(e);
        this.mouseDown = true;
    }
    
    protected onMouseUp(e:MouseEvent):void {
        // console.log("mouse up " + e);
        // console.log(e);
        this.mouseDown = false;
    }
    
    protected onMouseMove(e:MouseEvent):void {
        if (this.mouseDown) {
            // console.log("dragging " + e.movementX);
            // console.log(e);
            this.camera.rotation.y -= e.movementX * 0.001 * this.aimSensitivity;
            this.camera.rotation.x -= e.movementY * 0.001 * this.aimSensitivity;
        }
    }
    
    get camera(): THREE.PerspectiveCamera {
        return this._camera;
    }
    
    get windowingSystem(): WindowingSystem {
        return this._windowingSystem;
    }
}
