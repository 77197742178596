import * as THREE from 'three';
import { firestore } from '../../../../../@crema/services/auth/firebase/firebase';
import { GizmoTools } from '../../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools';
import { EulerIntegrator, eulerIntegratorType } from '../../components/systemComponents/EulerIntegrator';
import { ISceneNodeExtensions } from '../../extensions/ISceneNodeExtensions';
import { QuaternionExtensions } from '../../extensions/QuaternionExtensions';
import { AddObjectClickSpy } from '../../spies/AddObjectClickSpy';
import {
    ChangeBooleanPropertyPassThrough,
    ChangeNodeColorPassThrough,
    ChangeTextPanelTextPassThrough,
    ChangeTextPassThrough,
} from '../../Tools/InteropTypes/InteropClasses';
import Utils from '../../Tools/Utils';
import { ISceneNode, SceneComponent } from '../sceneManagement/SceneComponent';
import { SceneLoader } from '../sceneManagement/SceneLoader';
import {
    UserDataTypes,
    UserDataProperties,
    NodeDataProperties,
    CompatabilityUserDataTypes, UnserializedUserData,
} from '../ui-interop/PropertiesPanel';
import { DragBeginObjectSpy, DragEndObjectSpy, DragObjectSpy } from '../../spies/DragSpies';
import { AuthUser } from '../../../../../types/models/AuthUser';
import {
    OutlinePostProcess,
    outlinePostProcessType,
    TemporalOutlineElement,
} from '../../components/PostProcess/OutlineComponent';
import { PlaneRenderer } from '../../components/meshComponents/basic/PlaneRenderer';
import { SimulationMode } from './RenderingAndPlaceObjectStateSystem';
import InputSubSystem from '../input/InputSubSystem';
import NodeStorage from '../storageAndSerialization/NodeStorage';
import PropertiesPanelWithSimulationFunctionality from '../ancillary/PropertiesPanelWithSimulationFunctionality';
import { SpaceData } from 'types/models/home/HomeApp';
import { Behaviors, ITransform } from './Behaviors';
import {
    TriggerActionOutcome,
    VariableLogicType,
    VariableValueTriggerPair,
} from '../ui-interop/PropertiesPanelBehaviorActions';
import { LogicEngine } from 'types/models/dataAccess/Logic';
import { store } from 'App';
import { RotateToggle } from '../../components/tiny/RotateToggle';
import { ToggleComponent, toggleComponentType } from '../../components/tiny/ToggleComponent';
import { HoverSpy } from '../../spies/HoverSpy';
import { ImageRenderer } from '../../components/meshComponents/basic/ImageRenderer';
import { GlowHoverSpy } from '../../spies/GlowHoverSpy';

export default class Simulation extends PropertiesPanelWithSimulationFunctionality {
    private static _instance: Simulation | null = null;

    currentUser: AuthUser;
    currentSpace: SpaceData;
    //public members

    integrator: EulerIntegrator;
    spaceID: string;
    //private members
    //private mpSDK:any;

    private initializationComplete: boolean;
    private variables: any[] | undefined;

    public customSDK: any;

    public get Variables(): any[] | undefined {
        return this.variables;
    }

    //public moveToolUI_domElement:DOMElement<;

    private constructor() {
        super();
        this.variables = [];
        this.initializationComplete = false;
        console.log('[Simulation] started');

        //this.customSDK = customSdk;
        // this.customSDK = {
        //     Scene: new THREE.Scene(),
        //     renderer: new THREE.WebGLRenderer(),
        //     effectComposer: null,
        //     camera: new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 )
        // }
    }

    public static ForceReset(): void {
        Simulation._instance = null;
        Simulation._instance = new Simulation();
    }

    public static get instance(): Simulation {
        if (!Simulation._instance) {
            Simulation._instance = new Simulation();
        }

        return Simulation._instance;
    }

    async initialize(spaceID: string, sdk: any, authUser: AuthUser,
        // currentSpace: SpaceData
    ) {

        let currentSpace = store.getState().home.currentSpace;
        if (!currentSpace) {
            return;
        }
        this.spaceID = spaceID;
        this.currentUser = authUser;
        this.currentSpace = currentSpace;
        this.variables = currentSpace?.variables;
        console.log('[Simulation] Base setup...');


        this.renderingSubSystem.initialize(sdk);

        // this.sdk = sdk;
        //customSDK.Scene = new THREE.Scene();
        this.customSDK = sdk;
        this.scene = new SceneLoader(sdk, this);
        // this.scene = new SceneLoader(this.customSDK, this);
        this.scene.load('light', () => {
        });

        console.log('[Simulation] Base setup complete!');

        this.simulationMode = SimulationMode.NONE;

        if (this.customSDK.hasOwnProperty('customSDK')) {

        } else {



            const eulerIntegratorNode = await sdk.Scene.createNode();
            this.integrator = eulerIntegratorNode.addComponent(eulerIntegratorType);
            eulerIntegratorNode.start();
            this.setupPointerIntersectionSubscription();

            InputSubSystem.instance.Initialize(sdk);

            this.InitializeCamera();
            console.log('===========================================================');


            const postProcessNode = await sdk.Scene.createNode();
            this.outlineComponent = await postProcessNode.addComponent(outlinePostProcessType);
            await postProcessNode.start();

            const postProcessNode2 = await sdk.Scene.createNode();
            this.outlineComponentColor2 = await postProcessNode2.addComponent(outlinePostProcessType);
            await postProcessNode2.start();

            this.outlineComponentColor2.outlinePass?.visibleEdgeColor.set(0xffff00);
            this.outlineComponentColor2.outlinePass?.hiddenEdgeColor.set(0xffff00);

            //let gp = new GlitchPass();

            //this.renderingSubSystem.effectComposer.addPass( gp );
        }

        // initialize fixed nodes from DB
        console.log('[Simulation] Loading Scene...');
        this.spaceModels = await NodeStorage.loadNodesFromDB(this.scenePreProcess.bind(this));
        console.log('[Simulation] Loading Scene complete!');

        this.initializationComplete = true;

        if (this.customSDK.hasOwnProperty('customSDK')) {

        } else {
            EulerIntegrator.instance?.integrators.push(this.tickTok.bind(this));
        }

        // const particleSystemNode = await sdk.Scene.createNode();
        // await particleSystemNode.addComponent(fireParticleSystemType);
        // await particleSystemNode.start();

        //const emitter:Emitter = new Emitter();
        //this.variables = initialState.currentSpace?.variables;
    }

    public getVariableDefaultValues(name: string): string[] {
        if (this.variables) {
            var variablesFindResult = this.variables.find(v => v.name === name);

            if (variablesFindResult) {
                return variablesFindResult.values?.split(',').map((v: any) => v.trim());
            } else {
                return [];
            }
        }
        return [];
    }

    public updateVariablesState(newState: any[], currentLessonId: string | undefined, currentTagGroupId: string | undefined, currentSpaceId: string | undefined) {
        //var oldState = Utils.SimpleClone(this.variables);

        //for(let i )
        //this.evaluateVariableTriggerConditions(newState);
        LogicEngine.onVariablesStateChange(currentLessonId, currentTagGroupId, currentSpaceId/*, this.spaceModels*/, newState);

        if (this.spaceModels) {
            this.spaceModels.forEach(model => {
                let node = model.nodeRef as ISceneNode;

                if (node) {
                    if (node.userData && UserDataProperties.inputSource1 in node.userData) {
                        if ((node.userData[UserDataProperties.inputSource1] as string) && (node.userData[UserDataProperties.inputSource1] as string).length > 0) {
                            let localSystemVariable = newState!.find(element => (element.name as string).toLowerCase() === (node.userData[UserDataProperties.inputSource1] as string).toLowerCase());
                            if (localSystemVariable) {
                                let allowedValuesArray = (localSystemVariable.values as string).split(',').map(x => x.trim());
                                if (localSystemVariable.value) {
                                    let stateIndex = allowedValuesArray.indexOf(localSystemVariable.value);

                                    if (node.name === 'On Off Button' || node.name === "Toggle Multi Model") {
                                        let toggleMultiModelComponent: ToggleComponent = (node.components[3] as any).instance as ToggleComponent;
                                        if (toggleMultiModelComponent) {
                                            if (stateIndex == 0) {
                                                toggleMultiModelComponent.inputs.toggle = true;
                                            } else {
                                                toggleMultiModelComponent.inputs.toggle = false;
                                            }
                                        }
                                    } else if (node.name.includes('Lever')) {
                                        let rotateToggle: RotateToggle = (node.components[1] as any).instance as RotateToggle;
                                        if (rotateToggle) {
                                            rotateToggle.inputs.state = stateIndex;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }


        this.variables = Utils.SimpleClone(newState);
    }

    tickTok(dt: number) {
        if (!this.propertyPanelShowOverride[0]) {
            if (GizmoTools?.instance) {
                GizmoTools?.instance?.hideTools();
                Simulation.instance.propertiesPanel.hidePropertiesPanel();
            }

            this.scene.hideTransformGizmo();
        }
        //console.log(this.variables![0])
        //this.variables?.push({"name" : "aaa", "values" : "a, b"})
    }

    evaluateVariableTriggerConditions(variableValues: any[]): void {
        if (this.spaceModels) {
            this.spaceModels.forEach(model => {
                let targetNode: ISceneNode = model.nodeRef;//
                if (targetNode) {
                    if (UserDataProperties.TriggerActionList in targetNode.userData) {
                        //let varTriggerList: VariableValueTriggerPair[] = model.nodeRef.userData[UserDataProperties.varTriggers] || [];
                        let varTriggerList: VariableValueTriggerPair[] = targetNode.userData[UserDataProperties.varTriggers] || [];

                        let allVariableTriggerConditionsMet: boolean = false;

                        for (const varTrigger of varTriggerList) {
                            let relevantSystemVariable = variableValues.find(vv => vv.name === varTrigger.name);

                            if (relevantSystemVariable) {
                                if (relevantSystemVariable.hasOwnProperty('value')) {
                                    if ((relevantSystemVariable.value as string != null) && (relevantSystemVariable.value as string).length > 0) {
                                        if (varTrigger.hasOwnProperty('logic')) {
                                            if (varTrigger.logic as VariableLogicType === VariableLogicType.and) {
                                                if (relevantSystemVariable.value === varTrigger.value) {
                                                    allVariableTriggerConditionsMet = true;
                                                } else {
                                                    allVariableTriggerConditionsMet = false;
                                                    break;
                                                }
                                            } else if (varTrigger.logic as VariableLogicType === VariableLogicType.or) {
                                                if (relevantSystemVariable.value === varTrigger.value) {
                                                    allVariableTriggerConditionsMet = true;
                                                    break;
                                                }
                                            } else if (varTrigger.logic as VariableLogicType == VariableLogicType.blank) {
                                                if (relevantSystemVariable.value != varTrigger.value) {
                                                    allVariableTriggerConditionsMet = true;
                                                    break;
                                                } else {
                                                    allVariableTriggerConditionsMet = false;
                                                    break;
                                                }
                                            }
                                        } else {
                                            if (relevantSystemVariable.value === varTrigger.value) {
                                                allVariableTriggerConditionsMet = true;
                                            } else {
                                                allVariableTriggerConditionsMet = false;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        let triggerActionListArray = targetNode.userData[UserDataProperties.TriggerActionList] as TriggerActionOutcome[];
                        if (allVariableTriggerConditionsMet) {

                            for (const triggerAction of triggerActionListArray) {
                                Behaviors.runActionsOnNode(targetNode, triggerAction);
                            }
                        } else {
                            for (const triggerAction of triggerActionListArray) {
                                Behaviors.runUnActionsOnNode(targetNode, triggerAction);
                            }
                        }
                    }
                }
            });

            //Knock yerself out with performance benchmarks
            // if(this.spaceModels.size > 0) {
            //     //let spaceModelsArray = Array.from(this.spaceModels.values());
            //     var testStore = null;
            //     performance.mark("T0");
            //     for (const iterator of this.spaceModels) {
            //         //console.log(iterator[1]);
            //         testStore = iterator[1];
            //         console.log(testStore)
            //     }

            //     performance.mark("T1");
            //     this.spaceModels.forEach(model => {
            //         testStore = model;
            //         console.log(testStore)
            //     });

            //     performance.mark("T2");
            //     let spaceModelsArray = Array.from(this.spaceModels.values());

            //     _.forEach(spaceModelsArray, function(element) {
            //         testStore = element;
            //         console.log(testStore)
            //     } )

            //     performance.mark("T3");
            //     for(let key of this.spaceModels.keys()) {
            //         testStore = this.spaceModels.get(key);
            //         console.log(testStore)
            //     }
            //     performance.mark("T4");

            //     performance.measure("For of perf", "T0", "T1");
            //     performance.measure("Foreach perf ts native", "T1", "T2");
            //     performance.measure("Foreach  lodash/array perf", "T2", "T3");
            //     performance.measure("Foreach  lodash/map perf", "T3", "T4");
            //     //performance.measure("Iteration foreach perf native", "T2", "T1");

            //     var measures = performance.getEntriesByType("measure");
            //     measures.forEach(element => {
            //         console.log(element.name + ": " + element.duration);
            //     });
            //     performance.clearMarks();
            //     performance.clearMeasures();
            // }
        }

        this.customSDK?.renderer?.xr && (this.customSDK!.renderer!.xr.enabled = true);
    }

    highlightModel(objectID: string, meshes: THREE.Object3D[] | null) {
        this.outlineComponent.temporalOutlines.set(objectID, new TemporalOutlineElement(3000, meshes!));
    }

    public resetAllNodesPositions(): void {
        if (this.spaceModels) {
            this.spaceModels.forEach(model => {
                let node = model.nodeRef as ISceneNode;

                let originalMeshes = Utils.FindAllMeshesAndLineSegments(node);

                if (node?.unserializedUserData && UnserializedUserData.StartPosition in node.unserializedUserData) {
                    for (const meshTransformCollection of node.unserializedUserData[UnserializedUserData.StartPosition] as
                        ITransform[]) {

                        let m = originalMeshes?.find(m => m.uuid == meshTransformCollection.mesh.uuid);

                        if (m) {
                            m.position.set(meshTransformCollection.position.x, meshTransformCollection.position.y, meshTransformCollection.position.z);
                            m.rotation.set(meshTransformCollection.rotation.x, meshTransformCollection.rotation.y, meshTransformCollection.rotation.z, "XYZ");
                            m.scale.set(meshTransformCollection.scale.x, meshTransformCollection.scale.y, meshTransformCollection.scale.z);
                        }
                    }
                }
            });
        }
    }

    scenePreProcess(node: ISceneNode, dbJSON: any = null): void {
        //node.start();

        if (!node.userData) {
            node.userData = {};
        }

        if (!node.unserializedUserData) {
            node.unserializedUserData = {};
        }
        if (dbJSON == null) {
            let quaternionStart = new THREE.Quaternion();
            QuaternionExtensions.assign(quaternionStart, node.quaternion);
            node.userData['quaternionStart'] = JSON.stringify(quaternionStart);
            node.userData[UserDataProperties.ClickEventActionList] = [];
            //node.userData["show"] = [];
            //node.userData["hide"] = [];

            if (node.userData[UserDataProperties.hasColorProperty]) {
                node.userData[UserDataProperties.customColorProperty] = '#44ffffaa';
            }

            if (node.userData[UserDataProperties.hasBorderProperty]) {
                node.userData[UserDataProperties.borderColorProperty] = '#000000ff';
            }

            if (UserDataProperties.executeOnceAndRemove in node.userData) {
                let scaleOnce = node.userData[UserDataProperties.executeOnceAndRemove][NodeDataProperties.scale];
                node.userData[UserDataProperties.executeOnceAndRemove] = {};

                ISceneNodeExtensions.setScaleFromAny(node, scaleOnce);
            }

            if (node.userData[UserDataProperties.type] === UserDataTypes.textPanel) {
                node.userData[UserDataProperties.textProperty] = 'Add a helpful cue!';
                node.userData[UserDataProperties.customColorProperty] = '#00000080';
                node.userData[UserDataProperties.borderColorProperty] = '#F8DB1CFF';
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.boundedBox ||
                node.userData[UserDataProperties.type] === CompatabilityUserDataTypes.boundedBox) {
                node.userData[UserDataProperties.customColorProperty] = '#65E35014';
                node.userData[UserDataProperties.borderColorProperty] = '#00000000';
                node.userData[UserDataProperties.type] = UserDataTypes.boundedBox;
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.highlightBorder) {
                //node.userData[UserDataProperties.customColorProperty] = "#65E35014"
                //node.userData[UserDataProperties.borderColorProperty] = "#00000000"
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.thermostatNest) {
                node.userData[UserDataProperties.customColorProperty] = '#CF5300FF';
                node.userData[UserDataProperties.borderColorProperty] = '#ffa500FF';
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.imageRenderer) {
                node.userData[UserDataProperties.customColorProperty] = '#ffffffff';
                node.userData[UserDataProperties.borderColorProperty] = '#ff000000';
            }
        } else {

            let position = JSON.parse(dbJSON.position);
            let rotation = JSON.parse(dbJSON.quaternion);
            let scale = JSON.parse(dbJSON.scale);

            let customSceneNode: boolean | undefined = (node as any).customSceneNode;

            // if (!customSceneNode) {
            //     // ISceneNodeExtensions.setPositionFromAny(node, position);
            // } else {
            //     console.log("hi");
            // }

            ISceneNodeExtensions.setPositionFromAny(node, position);

            let newRotation = new THREE.Quaternion(rotation._x, rotation._y, rotation._z, rotation._w);//.setFromEuler(new Euler(45, 0, 0, "XYZ"));
            //let newRotation = new Quaternion().setFromEuler(new Euler(45, 0, 0, "XYZ"));
            ISceneNodeExtensions.setRotation(node, newRotation);
            ISceneNodeExtensions.setScaleFromAny(node, scale);

            Utils.ApplyAllPropertiesFromJSONtoJSON(node.userData, dbJSON.userData);

            if (UserDataProperties.overrideUserData in node.userData) {
                Utils.ApplyAllPropertiesFromJSONtoJSON(node.userData, node.userData[UserDataProperties.overrideUserData]);
            }
        }

        if (UserDataProperties.localPosition in node.userData) {
            let tempMeshInstance = (node.components[0] as any).instance;
            tempMeshInstance.inputs.localPosition.x = Number.parseFloat(node.userData[UserDataProperties.localPosition].x);
            tempMeshInstance.inputs.localPosition.y = Number.parseFloat(node.userData[UserDataProperties.localPosition].y);
            tempMeshInstance.inputs.localPosition.z = Number.parseFloat(node.userData[UserDataProperties.localPosition].z);
        }

        if (UserDataProperties.rotationAxis in node.userData) {
            let tempMeshInstance = (node.components[1] as any).instance;
            tempMeshInstance.inputs.rotationAxis.x = Number.parseFloat(node.userData[UserDataProperties.rotationAxis].x);
            tempMeshInstance.inputs.rotationAxis.y = Number.parseFloat(node.userData[UserDataProperties.rotationAxis].y);
            tempMeshInstance.inputs.rotationAxis.z = Number.parseFloat(node.userData[UserDataProperties.rotationAxis].z);
            (tempMeshInstance as RotateToggle).prepareClips();
        }

        if (UserDataProperties.rotationRange in node.userData) {
            let tempMeshInstance = (node.components[1] as any).instance;
            tempMeshInstance.inputs.rotationRange = node.userData[UserDataProperties.rotationRange].split(',');
        }

        if (UserDataProperties.type in node.userData) {
            if (node.userData[UserDataProperties.type] === UserDataTypes.textPanel) {
                ((node as any).components[4] as PlaneRenderer).canvasText = (node as any).components[6];
                ((node as any).components[4] as PlaneRenderer).canvasRenderer = (node as any).components[5];
            } else if (node.userData[UserDataProperties.type] === UserDataTypes.thermostatNest) {
                //this.propertiesPanel.verifyInputSourceForIOT(node);
                //VariableTypeAllowedValues.updateInputSourceForNestNode(node, this.variables!);
            }
        }

        const componentIterator: IterableIterator<SceneComponent> = node.componentIterator();
        for (const component of componentIterator) {
            switch (component.componentType) {
                case 'mp.objLoader':
                case 'mp.daeLoader':
                case 'mp.fbxLoader':
                case 'mp.gltfLoader':
                case 'mp.highlightBox':
                case 'mp.planeRenderer':
                case 'st.plateRenderer':
                case 'mp.onOffButton':
                case 'st.imageRenderer':
                case 'st.fireParticleSystem':
                case 'st.waterParticleSystem':

                    const tempAddObjectClickSpy = new AddObjectClickSpy(component, node);
                    component.spyOnEvent(tempAddObjectClickSpy);

                    const tempDragBeginObjectClickSpy = new DragBeginObjectSpy(component, node);
                    component.spyOnEvent(tempDragBeginObjectClickSpy);

                    const tempDragEndObjectClickSpy = new DragEndObjectSpy(component, node);
                    component.spyOnEvent(tempDragEndObjectClickSpy);

                    const tempDragObjectClickSpy = new DragObjectSpy(component, node);
                    component.spyOnEvent(tempDragObjectClickSpy);

                    if (component.componentType === 'mp.objLoader') {
                        let objs = Utils.FindAllMeshesAndLineSegments(node);
                        if (objs) {
                            Utils.PatchMeshesMaterialUVs(objs);
                        }
                    }

                    if (node.userData[UserDataProperties.type] === UserDataTypes.InteriorDesignModel) {
                        if (component.componentType === 'mp.objLoader' ||
                            component.componentType === 'mp.gltfLoader' ||
                            component.componentType === 'mp.daeLoader') {
                            const tempGlowHoverSpy = new GlowHoverSpy(component, node);
                            component.spyOnEvent(tempGlowHoverSpy);
                        }
                    }
                    break;
            }
        }

        if (node.userData[UserDataProperties.hasColorProperty]) {
            let colorHexAndAlpha = Utils.SeparateHexFromAlpha(node.userData[UserDataProperties.customColorProperty]);
            Simulation.instance.propertiesPanel.colorSaveQueueScheduler.addQueueElement(new ChangeNodeColorPassThrough(colorHexAndAlpha[0], colorHexAndAlpha[1], false, node, false), true);
        }

        if (UserDataProperties.hasBorderProperty in node.userData) {
            Simulation.instance.propertiesPanel.setBooleanPropertyOfNode(
                new ChangeBooleanPropertyPassThrough(
                    node.userData[UserDataProperties.hasBorderProperty], UserDataProperties.hasBorderProperty, node, false,
                ));

            if (UserDataProperties.borderColorProperty in node.userData) {
                let colorHexAndAlpha = Utils.SeparateHexFromAlpha(node.userData[UserDataProperties.borderColorProperty]);
                Simulation.instance.propertiesPanel.colorSaveQueueScheduler.addQueueElement(new ChangeNodeColorPassThrough(colorHexAndAlpha[0], colorHexAndAlpha[1], true, node, false), true);
            }
        }

        if (node.userData[UserDataProperties.type] === UserDataTypes.textPanel) {
            Simulation.instance.propertiesPanel.changeTextOfNode(
                new ChangeTextPanelTextPassThrough(
                    node.userData[UserDataProperties.textProperty], Utils.GetUD_NumberValue(node.userData, UserDataProperties.fontSize), node, false,
                ));
        }

        if (node.userData[UserDataProperties.type] === UserDataTypes.imageRenderer) {
            // Simulation.instance.propertiesPanel.changeBorderRadius(
            //     new ChangeTextPassThrough(
            //         node.userData[UserDataProperties.borderRadius], node, false,
            //     ));

            ((node.components[0] as any).instance as ImageRenderer).inputs.textureSource = node.userData[UserDataProperties.textureSource];
        }
    }

    public InitializationComplete(): boolean {
        return this.initializationComplete;
    }

    public updateFireWaterObjects(boxVisible: boolean) {

        if (Simulation.instance.scene) {

            let fireWaterModels = Simulation.instance.scene.findNodesByName(["Fire", "Water Spray"]);

            fireWaterModels.forEach(m => {
                (m?.components && m?.components[0] as any).instance.inputs.boxVisible = boxVisible;
            });
        }

    }
}
