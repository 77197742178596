import React from 'react';
import Draft from 'draft-js';
import 'draft-js/dist/Draft.css';
import './RichEditor.css';
import { Popper, Box, MenuItem, Select, NativeSelect, Dialog, Typography } from "@material-ui/core";
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';

const { Editor, EditorState, RichUtils, getDefaultKeyBinding, convertToRaw, ContentState, convertFromHTML } = Draft;
// export function draftToHtml2 (editorState) {
//     convertToRaw(editorState.getCurrentContent());
// }

export class RichEditorExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.props.initialValue
    };

    this.focus = () => this.refs.editor.focus();
    this.onChange = (editorState) => {
      this.setState({ editorState });
      this.props.saveDraftHtml(editorState);
    };

    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
  }

  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        this.state.editorState,
        4 /* maxDepth */,
      );
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
    );
  }

  render() {
    const { editorState } = this.state;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <div className='RichEditor-root' >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',  }}>
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
            type={'headings'}
          />
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />
        </div>
        <div className={className} onClick={this.focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this.onChange}
            placeholder=''
            ref='editor'
            spellCheck={true}
          />
        </div>
      </div>
    );
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.comp ||  this.props.label}
      </span>
    );
  }
}

const HEADING_TYPES = [
  {label: 'Normal', style: 'unstyled', comp: (text) => <p>{text}</p>},
  { label: 'Heading 1', style: 'header-one', comp: (text) => <h2>{text}</h2> }, //bc default h1 is too big for draft-js styles
  { label: 'Heading 2', style: 'header-two', comp: (text) => <h3>{text}</h3> },
  { label: 'Heading 3', style: 'header-three', comp: (text) => <h4>{text}</h4> },
  // { label: 'Heading 4', style: 'header-four', comp: (text) => <h4>{text}</h4> },
  // { label: 'Heading 5', style: 'header-five', comp: (text) => <h5>{text}</h5> },
  // { label: 'Heading 6', style: 'header-six', comp: (text) => <h6>{text}</h6> },
];

const BLOCK_TYPES = [

  //  {label: 'Blockquote', style: 'blockquote'},
  { label: 'UL', style: 'unordered-list-item', comp: <FormatListBulletedIcon/>}, //TODO use icons
  { label: 'OL', style: 'ordered-list-item', comp: <FormatListNumberedRtlIcon /> },
  // {label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className='RichEditor-controls' style={props.type == "headings" ? {minWidth: "150px"}: null}>
      {props.type == "headings" ?

        <Select
          // value={HEADING_TYPES.find(b => b.style === blockType)?.label}
          // defaultValue={HEADING_TYPES.find(b => b.style === blockType)?.label}
          value={HEADING_TYPES.find(b => b.style === blockType)?.style || ''}
          onChange={(e) => {
            console.log(`[st] blockType is  ${blockType}. Selection is: ${HEADING_TYPES.find(b => b.style === blockType)} -- (${JSON.stringify(Object.entries(e.target))})`);
            props.onToggle(e.target.value);

          }
          }
        > {
            HEADING_TYPES.map((type) => (

              <MenuItem value={type.style} key={type.style}
              // onClick={() => props.onToggle(type.style)}
              > {type.comp(type.label)}
                {/* <StyleButton
                  key={type.label}
                  active={type.style === blockType}
                  label={type.label}
                  onToggle={props.onToggle}
                  style={type.style}
                /> */}
              </MenuItem>


            ))}</Select>
        : BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            comp={type.comp}
          />
        ))


      }
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'B', style: 'BOLD', comp: <FormatBoldIcon/>},
  { label: 'I', style: 'ITALIC' ,comp: <FormatItalicIcon/> },
  { label: 'U', style: 'UNDERLINE', comp: <FormatUnderlinedIcon/> },
  // { label: 'M', style: 'CODE' },
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className='RichEditor-controls'>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          comp={type.comp}
        />
      ))}
    </div>
  );
};
