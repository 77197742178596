import * as THREE from 'three';
import {XRReferenceSpace, XRSession} from 'three';
import {RenderingSystem} from './Systems/RenderingSystem';

export class TagsARInteraction {
    //XR members
    protected reticle: THREE.Mesh | null = null;
    protected controller: THREE.Group | null = null;

    protected hitTestSource: THREE.XRHitTestSource | null = null;
    protected hitTestSourceRequested = false;

    protected anchor1Mode = true;

    protected anchor1:THREE.Mesh;
    protected anchor2:THREE.Mesh;
    protected cylinderGeometry:THREE.Geometry;

    constructor(protected xrReferenceSpaceObj3D:THREE.Object3D, protected renderingSystem:RenderingSystem) {
        this.renderingSystem.renderer.xr.enabled = true;

        alert("To begin, look down & point the circle at your space's origin point");

        this.controller = this.renderingSystem.renderer.xr.getController(0);
        this.controller.addEventListener('select', this.xrOnSelect.bind(this));
        this.renderingSystem.scene.add(this.controller);

        this.reticle = new THREE.Mesh(
            new THREE.RingGeometry(0.15, 0.2, 32).rotateX(-Math.PI / 2),
            new THREE.MeshBasicMaterial({ color: "0xff0000"}),
        );
        this.reticle.matrixAutoUpdate = false;
        this.reticle.visible = false;
        this.renderingSystem.scene.add(this.reticle);

        this.cylinderGeometry = new THREE.CylinderGeometry(0.01, 0.01, 0.5, 32).translate(0, 0.1, 0);
    }

    protected xrOnSelect() {
        if (this.reticle!.visible) {

            if (this.anchor1Mode) {
                if (!this.anchor1) {
                    const material = new THREE.MeshPhongMaterial({color: 0xffffff});
                    const mesh = new THREE.Mesh(this.cylinderGeometry, material);
                    mesh.position.setFromMatrixPosition(this.reticle!.matrix);
                    this.anchor1 = mesh;
                    this.renderingSystem.scene.add(this.anchor1);
                    alert("Great! Now point the circle at Origin2");
                } else {
                    this.anchor1.position.setFromMatrixPosition(this.reticle!.matrix);
                }
                this.anchor1Mode = !this.anchor1Mode;
            } else {
                if (!this.anchor2) {
                    const material = new THREE.MeshPhongMaterial({color: 0xea3939});
                    const mesh = new THREE.Mesh(this.cylinderGeometry, material);
                    mesh.position.setFromMatrixPosition(this.reticle!.matrix);
                    this.anchor2 = mesh;
                    this.renderingSystem.scene.add(this.anchor2);
                } else {
                    this.anchor2.position.setFromMatrixPosition(this.reticle!.matrix);
                }
                this.anchor1Mode = !this.anchor1Mode;
            }

            if (this.anchor1 && this.anchor2) {
                let forwardAxis = this.anchor2.position.clone().sub(this.anchor1.position.clone());
                forwardAxis = forwardAxis.normalize();
                let upAxis = new THREE.Vector3(0, 1, 0);
                let rightAxis = upAxis.clone().cross(forwardAxis);
                rightAxis = rightAxis.normalize();

                //xrReferenceMatrix.setPosition(anchor1.position.x, anchor1.position.y, anchor1.position.z);
                let xrReferenceMatrix = new THREE.Matrix4();
                xrReferenceMatrix.makeBasis(rightAxis, upAxis, forwardAxis);
                this.xrReferenceSpaceObj3D.position.set(this.anchor1.position.x, this.anchor1.position.y, this.anchor1.position.z);
                this.xrReferenceSpaceObj3D.setRotationFromMatrix(xrReferenceMatrix);
            }
        }
    }

    public dispose() {
        if (this.reticle) {
            this.renderingSystem.scene.remove(this.reticle);
            this.reticle.geometry.dispose();
            (this.reticle.material as THREE.MeshBasicMaterial).dispose();
            this.reticle = null;
        }
        this.hitTestSourceRequested = false;
        this.clearAllHitTestSources();
    }

    protected clearAllHitTestSources() {
        this.hitTestSourceRequested = false;
        this.hitTestSource = null;
    }

    protected onSessionRequestReferenceSpace(session: XRSession, referenceSpace: XRReferenceSpace) {
        session.requestHitTestSource({space: referenceSpace}).then((source) => {
            this.onRequestHitSource(source);
        });

    }

    protected onRequestHitSource(source: THREE.XRHitTestSource) {
        this.hitTestSource = source;
    }

    public xrRender(frame: any) {
        if (frame) {
            const referenceSpace = this.renderingSystem.renderer.xr.getReferenceSpace();
            const session = this.renderingSystem.renderer.xr.getSession();

            if (session) {

                if (!this.hitTestSourceRequested) {

                    session.requestReferenceSpace('viewer').then((referenceSpace) => {
                        this.onSessionRequestReferenceSpace(session, referenceSpace);
                    });

                    session.addEventListener('end', this.clearAllHitTestSources.bind(this));
                    this.hitTestSourceRequested = true;

                }

                if (this.hitTestSource) {

                    const hitTestResults = frame.getHitTestResults(this.hitTestSource);

                    if (hitTestResults.length) {
                        const hit = hitTestResults[0];

                        this.reticle!.visible = true;
                        this.reticle!.matrix.fromArray(hit.getPose(referenceSpace).transform.matrix);

                    } else {
                        this.reticle!.visible = false;
                    }

                }
            }

        }
    }
}
