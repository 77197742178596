export const getServerApiUrl = () => {

    // if (location.hostname === 'localhost') {
    //     return 'http://localhost:8080';
    // } else {
        return 'https://spatialthink.uc.r.appspot.com'; // use default prod server by default. it checks req header to select dev/prod DB
    // }
}

export const baseUrl = 'https://app.spatialthink.com'; // 'http://localhost:3000'; for testing
    // (window.location.hostname === 'localhost')


