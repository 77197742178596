import {AuthUser} from '../models/AuthUser';

export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const FORGET_PASSWORD_EMAIL = 'FORGET_PASSWORD_EMAIL';
export const SIGNOUT_AUTH_SUCCESS = 'SIGNOUT_AUTH_SUCCESS';
export const AWAITING_VERIFICATION = 'AWAITING_VERIFICATION';
export interface SetAuthTokenActions {
  type: typeof SET_AUTH_TOKEN;
  payload: string | null;
}

export interface SetForgetPasswordActions {
  type: typeof FORGET_PASSWORD_EMAIL;
  payload: string | null;
}

export interface UpdateAuthUserActions {
  type: typeof UPDATE_AUTH_USER;
  payload: AuthUser | null;
}
export interface AwaitingVerificationActions {
  type: typeof AWAITING_VERIFICATION;
  payload: boolean;
}
export interface SignoutAuthUserActions {
  type: typeof SIGNOUT_AUTH_SUCCESS;
}

export type AuthActions =
  | UpdateAuthUserActions
  | SetForgetPasswordActions
  | SetAuthTokenActions
  | SignoutAuthUserActions
  | AwaitingVerificationActions;
