import React from 'react'
import { Icon } from "@material-ui/core";
import RotateSvg from 'assets/images/3dIcons/rotate.svg';
import LeverSvg from 'assets/images/3dIcons/lever.svg';
// import GaugeSvg from 'assets/images/3dIcons/gauge.svg';
import {ReactComponent as GaugeDigitalSvg} from 'assets/images/3dIcons/GaugeDigitalSvg.svg';
import DialSvg from 'assets/images/3dIcons/DialSvg.svg';
import BoxHighlightSvg from 'assets/images/3dIcons/boxHighlight.svg';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import HighlightAltRoundedIcon from '@mui/icons-material/HighlightAltRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import ThreeDRotationRoundedIcon from '@mui/icons-material/ThreeDRotationRounded';
import ThreeDIcon2Svg from 'assets/images/3dIcons/ThreeDIcon2.svg';
// import { ReactComponent as ThreeDIcon2Svg } from 'assets/images/3dIcons/ThreeDIcon2.svg';

import SwitchSvg from 'assets/images/3dIcons/switch.svg';
import { InProgressSvg } from 'assets/images/home/icons/InProgressSvg';
// import CompleteSvg from "assets/images/home/icons/CompleteSvg.svg";
interface Props {
    svgSrc: any, height: number, width: number
}

export const CustomIcon: React.FC<Props> = ({ svgSrc, height, width }) => {
    return <Icon>
        <img src={svgSrc}
        // height={height} width={width}
        />
    </Icon>
}

export const CustomIconWhite: React.FC<Props> = ({ svgSrc, height, width }) => {
    return <Icon>
        <img src={svgSrc}
            style={{
                // color: "white",
                backgroundColor: "white"
            }}
        // height={height} width={width}
        />
    </Icon>
}

export const CustomRotateIcon: React.FC = () => <CustomIconWhite svgSrc={RotateSvg} height={25} width={25} />

export const CustomSwitchIcon: React.FC = () => <CustomIcon svgSrc={SwitchSvg} height={25} width={25} />

export const CustomLeverIcon: React.FC = () => <CustomIconWhite svgSrc={LeverSvg} height={25} width={25} />

export const CustomPowerOffIcon: React.FC = () => <PowerSettingsNewRoundedIcon style={{ color: 'white' }} />

export const BorderHighlightIcon: React.FC = () => <HighlightAltRoundedIcon style={{ color: 'blue' }} />

export const CustomPowerOnIcon: React.FC = () => <PowerSettingsNewRoundedIcon style={{ color: 'white' }} />

export const CustomArrowIcon: React.FC = () => <ArrowDownwardRoundedIcon style={{
    color: "rgb(0,0,0,0.5)"
    // 'blue'
}} />

export const CustomGaugeIcon: React.FC = () => <GaugeDigitalSvg color="white" height={25}/> // <CustomIcon svgSrc={GaugeDigitalSvg} height={25} width={25} />
export const CustomDialIcon: React.FC = () => <CustomIcon svgSrc={DialSvg} height={25} width={25} />

export const BoxHighlightIcon: React.FC = () => <CustomIcon svgSrc={BoxHighlightSvg} height={25} width={25} />

// export const ThreeDIcon: React.FC<any> = (color?: string) => <ThreeDRotationRoundedIcon />
{/* <any> = ({ color } : { color?: string}) => {
    let svgContent = ThreeDIcon2Svg as string;
    if(color){
        svgContent = svgContent.replace('<svg', `<svg style="fill: ${color};"`);
    }
    return <CustomIcon svgSrc={svgContent} height={25} width={25} />
} */}
// {/*

export const ThreeDIcon2: React.FC  = () =>  <ThreeDRotationRoundedIcon height={20} width={20} />

///tasks
// export const ScheduledTaskIcon: React.FC  = () => <CustomIcon svgSrc={ScheduledSvg} height={25} width={25} />
export const InProgressIcon: React.FC = () => <CustomIcon svgSrc={InProgressSvg()} height={25} width={25} /> //TODO why doesnt this icon work
// export const NeedsAttentionIcon: React.FC  = () => <CustomIcon svgSrc={NeedsAttentionSvg} height={25} width={25} />
// export const CompleteIcon: React.FC  = () => <CustomIcon svgSrc={CompleteSvg} height={25} width={25} />



// SVGs
export const micIcon = `<svg fill="red" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="18" height="18" viewBox="0 0 974.699 974.699" style="enable-background:new 0 0 974.699 974.699;" xml:space="preserve">
<g>
  <path d="M731.1,107.1C731.1,47.9,683.1,0,623.999,0H350.8c-59.2,0-107.101,48-107.101,107.1v37h487.4V107.1L731.1,107.1z" />
  <path d="M243.699,514.4c0,59.199,48,107.1,107.101,107.1h273.199c59.2,0,107.101-48,107.101-107.1V224.2h-487.4V514.4z" />
  <path d="M307.499,810.1h119.9v104.6c0,33.102,26.9,60,60,60s60-26.898,60-60V810.1h119.9c68.5,0,132.8-27.299,181.1-76.9
C896.3,684,922.6,618.801,922.6,549.5c0-33.1-26.9-60-60-60c-33.101,0-60,26.9-60,60c0,77.5-60.7,140.6-135.4,140.6h-179.8h-179.9
c-74.6,0-135.399-63.1-135.399-140.6c0-33.1-26.9-60-60-60c-33.1,0-60,26.9-60,60c0,69.301,26.4,134.5,74.2,183.699
C174.699,782.801,239,810.1,307.499,810.1z" />
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`