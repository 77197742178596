import React, { useState, useEffect } from 'react';
import {
	Box,
	IconButton,
	Paper,
	Grid,
	Divider,
	Drawer,
	Button,
	Typography,
	Tooltip,
	MenuItem,
	Select,
	TextField,
	Fab,
	Link,
} from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
// import DropDownButton from './VariablesPanel/DropDownButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import PanelInputField, { PanelInputLabel } from '../SpaceView/ShowcaseOverlay/3DTools/PanelInputField';
import ShortUuid from 'short-uuid';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { UserDataProperties } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
// import {useDispatch} from 'react-redux';

import {
	Logic,
	ConditionTree,
	NestedCondition,
	Condition,
	OBJECT_ACTIONS,
	CONDITION_JOINER,
	ACTION_TYPE,
	CONDITION_TYPE,
	OBJECT_EVENT_TYPES,
} from 'types/models/dataAccess/Logic';
import { fetchError } from 'redux/actions';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import DropDownButton from './DropDownButton';
import { CremaTheme } from 'types/AppContextPropsType';
import { ISceneNode, Keys } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import { AddObjectClickSpy } from 'mp/core/craEngine/spies/AddObjectClickSpy';
import { firestore } from '@crema/services/auth/firebase/firebase';
import _ from 'lodash';
import { Prompt } from 'react-router';
import { VariableTypeAllowedValues } from '../SpaceView/Sidebar/Variables/VariableTypes';
import { ActionType } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanelBehaviorActions';
import { onUpdateTagGroup } from 'redux/actions/Step';
import { TagGroup } from 'types/models/home/HomeApp';
import IndicatorPulse from '@crema/core/IndicatorPulse';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme: CremaTheme) => ({
	drawer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		transition: 'height 1s ease-out'
	},
	treeBox: {
		position: 'relative'
	},
	removeTreeBtn: {
		position: 'absolute',
		right: 0,
		top: 0
	},
	addLogicTreeButton: {
		height: 40,
		width: 150
	},
	paper: {
		minWidth: 400,
		backgroundColor: '#eee !important',
		margin: 15,
		position: 'relative',
		// width: "100%"
	},
	treeItem: {
		justifyContent: 'space-between',
		minWidth: 350,
		paddingTop: "8px",
		marginBottom: "8px",
		borderRadius: '16px',
	},
	triggersActionsContainer: {
		position: 'relative'
	},
	treeNode: {
		position: 'relative',
		marginBottom: 10,
		justifyContent: 'flex-start',
		borderRadius: '16px'
		// backgroundColor: '#fff'
	},
	decision: {
		marginBottom: 150,
	},
	addTriggerButton: {
		transition: 'all .2s',
		padding: 0,
		height: 36,
		'& .MuiSvgIcon-root': {
			fontSize: '2.5rem'
		},
		color: theme.palette.primary.main
	},
	addNestedTriggerButton: {
		color: fade(theme.palette.secondary.main, 0.5),
		transition: 'all .2s',
		padding: 0,
		height: 36,
		'& .MuiSvgIcon-root': {
			fontSize: '2.5rem'
		},
		'&:hover': {
			color: theme.palette.secondary.main
		}
	},
	addButton: {
		position: 'absolute',
		padding: 0,
		'& .MuiSvgIcon-root': {
			fontSize: '2.5rem'
		},
	},
	removeButton: {
		float: 'right'
	},
	actionTitle: {
		marginTop: '-22px',
		fontSize: '1em',
		fontFamily: 'fantasy',
		fontStyle: 'italic',
		color: 'purple',
	},

	selectBox: {
		// width: 'fit-content',
		// minWidth: '20%',
		width: '100px',

		display: 'flex',
		margin: theme.spacing(1),
		// fontWeight: Fonts.LIGHT,
		marginBottom: 20,
		marginTop: 16,
		// marginLeft: 5,
		// marginRight: 5
		'& .MuiOutlinedInput-input': {
			padding: '5px 10px',
		}
	},

	selectMenuItem: {
		display: 'flex',
		color: "black",
		// fontWeight: Fonts.LIGHT,
	},
}));

interface ILine {
	start: string;
	end: string;
	labels?: any;
	startAnchor?: any;
	endAnchor?: any;
}

interface ICondition {
	id: string;
	type: CONDITION_TYPE;
	objectEventType?: OBJECT_EVENT_TYPES;
	objectId?: string;
	varName?: string;
	varValue?: string;
	valueOptions: Array<any>;
	nestedCondition?: NestedCondition;
}

export interface IAction {
	id: string;
	type: ACTION_TYPE;
	varName?: string;
	varValue?: string;
	actionType?: OBJECT_ACTIONS;
	objectId?: string;
	currentTagGroupId?: string;
	parameters?: string;
	valueOptions: Array<any>;
	iotVariableName?: string;
	waitInSeconds?: number;
}

function LogicTreeNestedCondition(
	{ tIndex, conditionsData, updateConditions, depth }:
		{ tIndex: string, conditionsData: NestedCondition, updateConditions(data: NestedCondition): void, depth: number }) {

	const variableValues = useSelector<AppState, any[]>(({ home }) => home.variableValues);
	const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
	const variableOptions = variableValues.length > 0 ? variableValues.filter((e: any) => e.allowControl == true).map((e: any) => ({ id: e.name, name: e.name })) : [];
	const [lastIndex, setLastIndex] = useState<number>(0);

	let selectableConditions: any[] = [];
	let v: any[] = [];
	variableOptions.forEach((vo: any) => v.push({ ...vo, category: 'Variables' }));

	let o: any[] = (Object.values(OBJECT_EVENT_TYPES).map(action => ({ id: action, name: action, category: 'Objects' })));
	selectableConditions.push(...v);
	selectableConditions.push(...o);
	let objectOptions: any[] = [];
	if (spaceModels.size > 0) {
		spaceModels.forEach((model, key) => {
			objectOptions.push({
				id: key,
				name: model.nameToShow
			});
			objectOptions.sort((a, b)=> a.name - b.name);
		});
	}
	else {
		objectOptions.push({
			id: '',
			name: ''
		});
	}

	const classes = useStyles();
	const dispatch = useDispatch();
	const [lines, setLines] = useState<ILine[]>([]);
	const [tConditions, setTConditions] = useState<ICondition[]>([]);
	const [tRootLogic, setRootLogic] = React.useState<CONDITION_JOINER>(CONDITION_JOINER.ALL);
	const emptyNestedCondition: NestedCondition = {
		conditionJoiner: CONDITION_JOINER.ALL,
		conditions: []
	};

	const [currentSpecificConditionState, setSpecificCondition] = useState<number>(-1);

	useEffect(() => {
		let conditionList: ICondition[] = [];
		if (conditionsData) {
			const conditionJoiner = conditionsData.conditionJoiner;
			setRootLogic(conditionJoiner ? conditionJoiner : CONDITION_JOINER.ALL);
			const conditions: Condition[] = conditionsData.conditions;
			conditions.forEach((condition: any, index) => {
				if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {
					conditionList.push({
						id: 't' + tIndex + condition.varName + condition.varValue + index,
						varName: condition.varName,
						varValue: condition.varValue,
						type: CONDITION_TYPE.VARIABLE_CONDITION,
						valueOptions: getValueOptions(condition.varName),
					});
				} else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {
					conditionList.push({
						id: 't' + tIndex + condition.objectEventType + index,
						objectEventType: condition.objectEventType,
						type: CONDITION_TYPE.OBJECT_CONDITION,
						objectId: condition.objectId,
						valueOptions: [],
					});
				} else if (condition.type == CONDITION_TYPE.NESTED_TREE) {
					conditionList.push({
						id: 't' + tIndex + '-nested-tree' + index,
						nestedCondition: condition.nestedCondition,
						type: CONDITION_TYPE.NESTED_TREE,
						valueOptions: [],
					});
				}
			});
		}
		setTConditions(conditionList);
		return () => {
			setLines([]);
			setTConditions([]);
			selectableConditions = [];
			objectOptions = [];
		}
	}, []);

	useEffect(() => {

		updateConditions({ conditionJoiner: tRootLogic, conditions: tConditions });
		let linesTemp: ILine[] = [];
		linesTemp.push({
			start: `t${tIndex}-decision`,
			end: `t${tIndex}-add-button`,
			startAnchor: 'right',
			endAnchor: 'left',
			// labels: {
			// 	start: (
			// 		<div
			// 			style={{
			// 				fontSize: '.8em',
			// 				fontFamily: 'fantasy',
			// 				color: 'blue',
			// 				marginBottom: 5,
			// 			}}>
			// 			Conditions
			// 		</div>
			// 	)
			// }
		});
		tConditions.forEach((condition) => linesTemp.push({ start: `t${tIndex}-decision`, end: condition.id, startAnchor: 'right', endAnchor: 'left' }));
		setLines(linesTemp);
	}, [tConditions, tRootLogic]);

	function getValueOptions(variable: string) {
		let varValuesFind = variableValues.length > 0 ? variableValues.find(v => v.name === variable) : undefined;

		if (varValuesFind) {
			return varValuesFind.values?.split(",").map((v: any) => ({ id: v.trim(), name: v.trim() }));
		} else {
			return [{ id: 0, name: 'No value' }];
		}
	}

	function addNestedTree() {
		if (depth === 5) {
			dispatch(fetchError("Cannot add a deeper tree"));
			return;
		}
		let last = lastIndex;

		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);
		while (triggerArrayTemp.some(e => e.id == `t${tIndex}-trigger${last}`))
			last++;

		triggerArrayTemp.push({
			id: `t${tIndex}-trigger${last}`,
			type: CONDITION_TYPE.NESTED_TREE,
			nestedCondition: emptyNestedCondition,
			valueOptions: [],
		});
		setLastIndex(last);
		setTConditions(triggerArrayTemp);
	}

	function addCondtion() {
		let last = lastIndex;

		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);
		while (triggerArrayTemp.some(e => e.id == `t${tIndex}-trigger${last}`))
			last++;

		if (variableValues.length > 0) {
			triggerArrayTemp.push({
				id: `t${tIndex}-trigger${last}`,
				varName: variableValues[0].name,
				varValue: getValueOptions(variableValues[0].name)[0].id,
				type: CONDITION_TYPE.VARIABLE_CONDITION,
				valueOptions: getValueOptions(variableValues[0].name),
			});
		}
		else {
		//TODO: Artur: I had commented out the if part to make CLICK as the default condition. Is it causing this bug: https://app.asana.com/0/1201627700010013/1201683644226126
		triggerArrayTemp.push({
			id: `t${tIndex}-trigger${last}`,
			objectEventType: OBJECT_EVENT_TYPES.CLICK,
			objectId: '',
			type: CONDITION_TYPE.OBJECT_CONDITION,
			valueOptions: objectOptions,
		});
		}
		setLastIndex(last);
		setTConditions(triggerArrayTemp);
	}

	function removeCondition(targetTrigger: ICondition) {
		let triggerArrayTemp: ICondition[] = tConditions.filter((e) => e.id != targetTrigger.id);
		setTConditions(triggerArrayTemp);
	}

	function changeConditionVariable(index: number, selected: string) {
		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);

		if (Object.values(OBJECT_EVENT_TYPES).includes(selected as OBJECT_EVENT_TYPES)) {
			let selectedObjectEventType = selected as OBJECT_EVENT_TYPES;
			triggerArrayTemp[index].type = CONDITION_TYPE.OBJECT_CONDITION;
			triggerArrayTemp[index].objectEventType = selectedObjectEventType;
			triggerArrayTemp[index].objectId = objectOptions[0].id;

			if (selectedObjectEventType == OBJECT_EVENT_TYPES.CLICK) {
				console.log("click selected");
			}
		}
		else {
			triggerArrayTemp[index].type = CONDITION_TYPE.VARIABLE_CONDITION;
			triggerArrayTemp[index].varName = selected;
			triggerArrayTemp[index].valueOptions = getValueOptions(selected);
			triggerArrayTemp[index].varValue = getValueOptions(selected)[0].id;
		}

		setTConditions(triggerArrayTemp);
	}

	function changeConditionValue(index: number, selected: string) {
		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);
		triggerArrayTemp[index].varValue = selected;
		setTConditions(triggerArrayTemp);
	}

	function changeNestedCondition(index: number, data: NestedCondition) {
		let triggerArrayTemp: ICondition[] = tConditions.map(e => e);
		triggerArrayTemp[index].nestedCondition = data;
		setTConditions(triggerArrayTemp);
	}

	function changeOConditionObjectId(i: number, selected: string) {

		let conditionsArrayTemp: ICondition[] = tConditions.map(e => e);
		conditionsArrayTemp[i].objectId = selected;
		setTConditions(conditionsArrayTemp);
	}

	function setConditionToNode(node: ISceneNode, i: number): void {
		AddObjectClickSpy.ptrToSetConditionToNode = undefined;
		AddObjectClickSpy.actionOrConditionStatePointer = undefined;

		if (tConditions[i]) {
			if (node) {
				if (node.userData) {
					let conditionsArrayTemp: ICondition[] = tConditions.map(e => e);
					conditionsArrayTemp[i].objectId = node.userData[UserDataProperties.id];
					setTConditions(conditionsArrayTemp);
				}
			}
		}

		setSpecificCondition(-1);
	}

	//AddObjectClickSpy.ptrToSetConditionToNode = ptrToSetConditionToNode;

	return (
		<Xwrapper>
			{lines.map((line, i) => (
				<Xarrow {...line}
					color="black" path="grid" showHead={false} strokeWidth={1} key={`t${tIndex}-line${i}`} />
			))}
			<Grid container alignItems="center" className={classes.treeItem}>
				<Grid item xs={3}>

					<DropDownButton
						id={`t${tIndex}-decision`}
						options={Object.values(CONDITION_JOINER).map(join => ({ id: join, name: join }))}
						defaultOption={tRootLogic}
						buttonClickAction={(logic: CONDITION_JOINER) => setRootLogic(logic)} size="small" />


					{/* <Select
						id={`t${tIndex}-decision`}
						variant='filled'
						label="Joiner"
						className={classes.selectBox}
						name='joiner'
						value={tRootLogic}
						onChange={(event: React.ChangeEvent<any>) => setRootLogic(event.target.value)}>
						{Object.values(CONDITION_JOINER).map((val: CONDITION_JOINER, index: number) => <MenuItem key={val} className={classes.selectMenuItem} value={val}>{val}</MenuItem>)}
					</Select> */}

				</Grid>
				<Grid item xs={9}>
					<Box>
						{tConditions.map((condition, i) => (
							<Grid container className={classes.treeNode} style={{ backgroundColor: `#000000${depth * 10}` }} id={condition.id} key={`t${tIndex}-c${i}`}>
								{condition.type == CONDITION_TYPE.NESTED_TREE && (
									<>
										<Tooltip title="Delete Nested Trigger">
											<IconButton color="inherit" size="small" key={`t${tIndex}-ic${i}`}
												className={classes.removeButton}
												// className={classes.removeTreeBtn}
												// style={{ position: 'absolute', left: '-13px', top: '-13px' }}
												onClick={() => removeCondition(condition)}
											>
												<CancelIcon key={`t${tIndex}-ci${i}`} />
											</IconButton></Tooltip>
										<LogicTreeNestedCondition
											depth={depth + 1}
											key={`tree-${tIndex}-${i}`}
											tIndex={tIndex + '-' + i}
											conditionsData={condition.nestedCondition ? condition.nestedCondition : emptyNestedCondition}
											updateConditions={(data: NestedCondition) => changeNestedCondition(i, data)}
										/>
									</>
								)}
								{condition.type == CONDITION_TYPE.VARIABLE_CONDITION && (
									<>
										<DropDownButton options={selectableConditions} defaultOption={condition.varName} size="small" style={{ marginRight: 10 }}
											buttonClickAction={(selected: string) => changeConditionVariable(i, selected)} key={`t${tIndex}-vc${i}`} />
										<DropDownButton options={condition.valueOptions} defaultOption={condition.varValue} size="small" style={{ marginRight: 10 }}
											buttonClickAction={(selected: string) => changeConditionValue(i, selected)} key={`t${tIndex}-v${i}`} />
										<IconButton color="inherit" size="small" key={`t${tIndex}-ic${i}`}
											className={classes.removeButton}
											onClick={() => removeCondition(condition)}
										>
											<CancelIcon key={`t${tIndex}-ci${i}`} />
										</IconButton>
									</>
								)}
								{condition.type == CONDITION_TYPE.OBJECT_CONDITION && (
									<>
										<div style={{
											display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'stretch'
										}
										}>
											<DropDownButton options={selectableConditions} defaultOption={condition.objectEventType} size="small" style={{ width: '20%', marginRight: 10 }}
												buttonClickAction={(selected: string) => changeConditionVariable(i, selected)} key={`t${tIndex}-va${i}`} />

											<div style={{
												display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
											}
											}>
												{/* <PanelInputField props={{
													style: { marginRight: 10 },
													label: "Action", type: "readOnlyText",
													text: objectOptions.find((x: any) => x.id == condition!.objectId)?.name,
													//CONFIRM @Artur text: (objectOptions.map(e => e.id).indexOf(condition!.objectId) > 0) ? objectOptions.find((x: any) => x.id == condition!.objectId).name : '',
													onFocus: (e: any) => { }

												}} /> */}

												<DropDownButton options={objectOptions?.sort((a,b)=> a.name < b.name ? -1 : 0)} defaultOption={condition.objectId} size="small" style={{ marginRight: 10 }}
														buttonClickAction={(selected: string) => changeOConditionObjectId(i, selected)} key={`t${tIndex}-oco${i}`} />

												{/* //TODO: add dropdown to conditions like Actions */}
												{/* <DropDownButton options={objectOptions} defaultOption={condition?.objectId} size="small" style={{ marginRight: 10 }}
													buttonClickAction={(selected: string) =>
														// changeOConditionObjectId(i, selected)
														{console.error("not implemented: changeOConditionObjectId")}
													}
													key={`t${tIndex}-av${i}`} /> */}


												{(currentSpecificConditionState != i) &&

													<><Tooltip title="Click to select an object from the scene">
														<Link variant="caption" component="button" underline="always"
															onClick={(e: any) => {
																if (currentSpecificConditionState != i) {
																	setSpecificCondition(i);
																	AddObjectClickSpy.objectSelectionState = i;
																	AddObjectClickSpy.ptrToSetConditionToNode = setConditionToNode;

																	if (AddObjectClickSpy.actionOrConditionStatePointer) {
																		AddObjectClickSpy.actionOrConditionStatePointer(-1);
																		AddObjectClickSpy.actionOrConditionStatePointer = undefined;
																	}

																	AddObjectClickSpy.actionOrConditionStatePointer = setSpecificCondition;
																}
															}
															}
														><EditIcon fontSize={'small'}
														color='secondary'/> </Link></Tooltip>
													</>
												}
												{(currentSpecificConditionState == i) &&
													<Tooltip open={true} title="Waiting to select object in the scene">
														<div><IndicatorPulse /></div>
													</Tooltip>
												}

											</div>

											<IconButton color="inherit" size="small" key={`t${tIndex}-ic${i}`}
												className={classes.removeButton}
												onClick={() => removeCondition(condition)}
											>
												<CancelIcon key={`t${tIndex}-ci${i}`} />
											</IconButton>
										</div>
									</>

								)}
							</Grid>
						))}

						<Tooltip title="Add Trigger"><IconButton id={`t${tIndex}-add-button`}
							// variant="contained"
							color="primary"
							className={classes.addTriggerButton} size="medium"
							onClick={() => addCondtion()}
						>
							<AddCircleOutlineIcon />
							{/* Add Trigger */}
						</IconButton></Tooltip>
						{tConditions.length > 0 && <Tooltip title="Add a Nested Trigger"><IconButton id={`t${tIndex}-add-nested-button`}
							// color="secondary"
							className={classes.addNestedTriggerButton} size="medium"
							onClick={() => addNestedTree()}
						>
							<AddCircleOutlineIcon />
						</IconButton></Tooltip>}
					</Box>
				</Grid>
			</Grid>
		</Xwrapper>
	);
}

export function LogicTree(
	// <<<<<<< HEAD:src/modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/LogicTrees.tsx
	// { tIndex, conditionData, updateTree, tagGroup }:
	// 	{ tIndex: string, conditionData: ConditionTree, updateTree: any, tagGroup?: any }) {
	// const { variableValues, spaceModels } = useSelector<AppState, AppState['home']>(({ home }) => home);
	// =======
	{ tIndex, conditionData, updateTree, nodeUserData }:
		{ tIndex: string, conditionData: ConditionTree, updateTree: any, nodeUserData?: any }) {
	const variableValues  = useSelector<AppState, any[]>(({ home }) => home.variableValues);
	const spaceModels  = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
	const currentTagGroupId  = useSelector<AppState, string>(({ home }) => home.currentTagGroupId);

	const tagGroupsList  = useSelector<AppState, TagGroup[]>(({ home }) => home.tagGroupsList);

	// >>>>>>> origin/s13/arp/v1.2changes:src/modules/home/SpaceDetail/SpaceView/MatterPortScreen/LogicTrees.tsx
	const variableOptions = variableValues.length > 0 ? variableValues.filter((e: any) => e.allowControl == true).map((e: any) => ({ id: e.name, name: e.name })) : [];
	let objectOptions: any[] = [];
	if (spaceModels.size > 0) {
		spaceModels.forEach((model, key) => {
			objectOptions.push({
				id: key,
				name: model.nameToShow
			});
		});
		objectOptions.sort((a, b)=> a.name - b.name);
	}
	else {
		objectOptions.push({
			id: '',
			name: ''
		});
	}

	let selectableActions: any[] = Object.values(OBJECT_ACTIONS).map(action => ({ id: action, name: action, category: "Objects" }));
	if (variableValues.length > 0)
		selectableActions.push({ id: 'set-variable', name: 'Set variable' });

	let tagGroup: TagGroup | undefined;
	let nextTagGroup: TagGroup | undefined;
	let tagGroupIndex = 0;

	if (currentTagGroupId) {
		tagGroup = tagGroupsList.find((tagGroup: TagGroup) => tagGroup.id === currentTagGroupId);
		tagGroupIndex = tagGroup ? tagGroup.sortIndex + 1 : 0
	}
	// if (tagGroup?.sortIndex !== tagGroupsList.length - 1) {
	nextTagGroup = tagGroupsList?.[tagGroupIndex] || {};
	selectableActions.push({ id: 'move-to-next-step', name: 'Move to next step' });
	// selectableActions.push({ id: 'wait-in-seconds', name: 'Pause (in seconds)' });
	// }
	let selectableConditions: any[] = variableOptions.concat(Object.values(OBJECT_EVENT_TYPES).map(action => ({ id: action, name: action })));

	const classes = useStyles();
	const [tConditions, setTConditions] = useState<ICondition[]>([]);
	const [tActions, setTActions] = useState<IAction[]>([]);
	const [lines, setLines] = useState<ILine[]>([]);
	const [lastIndex, setLastIndex] = useState<number>(0);
	const [tRootLogic, setRootLogic] = React.useState<CONDITION_JOINER>(CONDITION_JOINER.ALL);
	const [objectSelectionState, setObjectSelectionState] = useState<number>(-1);

	let objectSelectionLoopIntervalReference: number = 0;

	let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.getAllIOTVariables(variableValues);

	let variablesOptions: any[] = [];
	allowedSystemVariablesAsInputSourceForNest.forEach((name: string) => {
		variablesOptions.push({
			id: name,
			name: name
		});
	});



	function setActionToNode(node: ISceneNode, i: number): void {
		AddObjectClickSpy.ptrToSetActionToNode = undefined;
		AddObjectClickSpy.actionOrConditionStatePointer = undefined;

		if (tActions[i]) {
			if (node) {
				if (node.userData) {
					let actionArrayTemp: IAction[] = tActions.map(e => e);
					actionArrayTemp[i].objectId = node.userData[UserDataProperties.id];
					setTActions(actionArrayTemp);
					//changeAction(objectSelectionState, 'set-object');

				}
			}
		}

		setObjectSelectionState(-1);
	}

	useEffect(() => {
		/*  Load actions from db data */
		let actionList: IAction[] = [];
		conditionData.actions.forEach((action: any, index) => {
			if (action.type == ACTION_TYPE.VARIABLE_ACTION) {
				actionList.push({
					id: 't' + tIndex + action.varName + action.varValue + index,
					varName: action.varName,
					varValue: action.varValue,
					type: ACTION_TYPE.VARIABLE_ACTION,
					valueOptions: getValueOptions(action.varName),
				});
			} else if (action.type == ACTION_TYPE.NEXTSTEP_ACTION) {
				actionList.push({
					id: 't' + tIndex + action.currentTagGroupId + index,
					type: ACTION_TYPE.NEXTSTEP_ACTION,
					currentTagGroupId: action.currentTagGroupId,
					waitInSeconds: action.waitInSeconds,
					valueOptions: [action.currentTagGroupId]
				});
			} else if (action.type == ACTION_TYPE.WAIT_ACTION) {
				actionList.push({
					id: 't' + tIndex + action.currentTagGroupId + index,
					type: ACTION_TYPE.WAIT_ACTION,
					currentTagGroupId: action.currentTagGroupId,
					valueOptions: [action.currentTagGroupId]
				});
			} else if (action.type == ACTION_TYPE.OBJECT_ACTION) {
				if (action.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
					actionList.push({
						id: 't' + tIndex + action.actionType + action.objectId + index,
						actionType: action.actionType,
						objectId: action.objectId,
						iotVariableName: action.iotVariableName,
						type: ACTION_TYPE.OBJECT_ACTION,
						valueOptions: [],
					});
				} else {
					actionList.push({
						id: 't' + tIndex + action.actionType + action.objectId + index,
						actionType: action.actionType,
						objectId: action.objectId,
						parameters: action.parameters,
						type: ACTION_TYPE.OBJECT_ACTION,
						valueOptions: [],
					});
				}
			}
		});
		setTActions(actionList);

		return () => {
			setLines([]);
			setTActions([]);
			setTConditions([]);
			selectableConditions = [];
			selectableActions = [];
			objectOptions = [];
		}
	}, []);

	useEffect(() => {
		updateTreeConditionData();
		let linesTemp: ILine[] = [];
		linesTemp.push({
			start: `t${tIndex}-decision`,
			end: `t${tIndex}-add-button`,
			startAnchor: 'right',
			endAnchor: 'left',
			labels: {
				start: (
					<div
						style={{
							fontSize: '.8em',
							fontFamily: 'fantasy',
							color: 'blue',
							marginBottom: 5,
						}}>
						Conditions
					</div>
				)
			}
		});
		tConditions.forEach((condition) => linesTemp.push({ start: `t${tIndex}-decision`, end: condition.id, startAnchor: 'right', endAnchor: 'left' }));
		setLines(linesTemp);


	}, [tConditions, tActions, tRootLogic]);

	function updateTreeConditionData() {
		let treeConditions: /* Condition[] */ any = [];
		let treeActions: /* Action[] */ any = [];

		tConditions.forEach((condition: ICondition) => {
			if (condition.type === CONDITION_TYPE.VARIABLE_CONDITION) {
				treeConditions.push({ type: CONDITION_TYPE.VARIABLE_CONDITION, varName: condition.varName, varValue: condition.varValue });
			}
			else if (condition.type === CONDITION_TYPE.NESTED_TREE) {
				treeConditions.push({ type: CONDITION_TYPE.NESTED_TREE, nestedCondition: condition.nestedCondition });
			}
			else {
				treeConditions.push({ type: CONDITION_TYPE.OBJECT_CONDITION, objectEventType: condition.objectEventType, objectId: condition.objectId });
			}
		});

		tActions.forEach((action: IAction) => {

			if (action.type === ACTION_TYPE.VARIABLE_ACTION) {
				treeActions.push({ type: ACTION_TYPE.VARIABLE_ACTION, varName: action.varName, varValue: action.varValue });
			} else if (action.type === ACTION_TYPE.NEXTSTEP_ACTION) {
				treeActions.push({ type: ACTION_TYPE.NEXTSTEP_ACTION, currentTagGroupId: action.currentTagGroupId, waitInSeconds: action.waitInSeconds });
			} else if (action.type === ACTION_TYPE.WAIT_ACTION) {
				treeActions.push({ type: ACTION_TYPE.WAIT_ACTION, currentTagGroupId: action.currentTagGroupId });
			}
			else {
				if (action.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
					treeActions.push({ type: ACTION_TYPE.OBJECT_ACTION, actionType: action.actionType, objectId: action.objectId, iotVariableName: action.iotVariableName });
				} else {
					treeActions.push({ type: ACTION_TYPE.OBJECT_ACTION, actionType: action.actionType, objectId: action.objectId, parameters: action.parameters });
				}
			}
		});

		let conditionRoot: NestedCondition = {
			conditionJoiner: tRootLogic,
			conditions: treeConditions
		};
		let conditionTree: ConditionTree = {
			id: tIndex,
			actions: treeActions,
			conditionRoot: conditionRoot
		};

		updateTree(conditionTree);
	}

	function getValueOptions(variable: string) {
		let varValuesFind = variableValues.length > 0 ? variableValues.find(v => v.name === variable) : undefined;

		if (varValuesFind) {
			return varValuesFind.values?.split(",").map((v: any) => ({ id: v.trim(), name: v.trim() }));
		} else {
			return [{ id: 0, name: 'No value' }];
		}
	}

	function updateRootConditions(data: NestedCondition) {
		let conditionList: ICondition[] = [];
		const conditionJoiner = data.conditionJoiner;
		setRootLogic(conditionJoiner ? conditionJoiner : CONDITION_JOINER.ALL);
		const conditions: Condition[] = data.conditions;
		conditions.forEach((condition: any, index) => {
			if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {
				conditionList.push({
					id: 't' + tIndex + condition.varName + condition.varValue + index,
					varName: condition.varName,
					varValue: condition.varValue,
					type: CONDITION_TYPE.VARIABLE_CONDITION,
					valueOptions: getValueOptions(condition.varName),
				});
			} else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {
				conditionList.push({
					id: 't' + tIndex + condition.objectEventType + index,
					objectEventType: condition.objectEventType,
					type: CONDITION_TYPE.OBJECT_CONDITION,
					objectId: condition.objectId,
					valueOptions: [],
				});
			} else if (condition.type == CONDITION_TYPE.NESTED_TREE) {
				conditionList.push({
					id: 't' + tIndex + '-nested-tree' + index,
					nestedCondition: condition.nestedCondition,
					type: CONDITION_TYPE.NESTED_TREE,
					valueOptions: [],
				});
			}
		});
		setTConditions(conditionList);
	}

	function addAction() {
		let last = lastIndex;
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		while (actionArrayTemp.some(e => e.id == `t${tIndex}-action${last}`))
			last++;

		actionArrayTemp.push({
			id: `t${tIndex}-action${last}`,
			actionType: selectableActions[0].name as OBJECT_ACTIONS,
			objectId: objectOptions[0].id,
			type: ACTION_TYPE.OBJECT_ACTION,
			valueOptions: selectableActions, /** options for Object id */
		});

		// Simulation.instance.propertiesPanel.changePropertiesPanelMode(PropertiesPanelMode.Adding);
		// console.log(Simulation.instance.lastSelectedNode)
		setLastIndex(last);
		setTActions(actionArrayTemp);
	}

	function removeAction(targetAction: IAction) {
		let triggerArrayTemp: IAction[] = tActions.filter((e) => e.id != targetAction.id);
		setTActions(triggerArrayTemp);
	}

	function changeAction(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);

		if (selected == 'set-variable' && variableValues.length > 0) {
			actionArrayTemp[index].type = ACTION_TYPE.VARIABLE_ACTION;
			actionArrayTemp[index].varName = variableValues[0].name;
			actionArrayTemp[index].varValue = getValueOptions(variableValues[0].name)[0].id;
			actionArrayTemp[index].valueOptions = getValueOptions(variableValues[0].name);
		}
		else if (selected == 'move-to-next-step' && nextTagGroup) {
			actionArrayTemp[index].type = ACTION_TYPE.NEXTSTEP_ACTION;
			actionArrayTemp[index].currentTagGroupId = nextTagGroup?.id;
			actionArrayTemp[index].waitInSeconds = 0;
		}
		else if (selected == 'wait-in-seconds') {
			actionArrayTemp[index].type = ACTION_TYPE.WAIT_ACTION;
		}
		else {
			actionArrayTemp[index].type = ACTION_TYPE.OBJECT_ACTION;
			actionArrayTemp[index].actionType = selected as OBJECT_ACTIONS;
			actionArrayTemp[index].objectId = objectOptions[0].id;
			actionArrayTemp[index].valueOptions = selectableActions;
		}

		setTActions(actionArrayTemp);
	}

	function changeNextStepActionWaitSeconds(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		try {
			let n = Number.parseInt(selected);
			actionArrayTemp[index].waitInSeconds = n < 10 ? n : 0;
		}catch(e: any){
			actionArrayTemp[index].waitInSeconds = 0;
		}
		setTActions(actionArrayTemp);
	}

	function changeVActionVariable(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);

		actionArrayTemp[index].varName = selected;
		actionArrayTemp[index].valueOptions = getValueOptions(selected);
		actionArrayTemp[index].varValue = getValueOptions(selected)[0].id;

		setTActions(actionArrayTemp);
	}

	function changeVActionValue(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].varValue = selected;
		setTActions(actionArrayTemp);
	}

	function changeOActionObjectId(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].objectId = selected;
		setTActions(actionArrayTemp);
	}

	function changeObjectActionParameters(index: number, parameters: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].parameters = parameters;
		setTActions(actionArrayTemp);
	}

	function changeOActionIOTVariableName(index: number, selected: string) {
		let actionArrayTemp: IAction[] = tActions.map(e => e);
		actionArrayTemp[index].iotVariableName = selected;
		setTActions(actionArrayTemp);
	}

	const LogicActions = () => {
		return (<Grid container alignItems="center">
					<Grid item xs={12} style={{ backgroundColor: "#e8e6e6", borderRadius: '16px' }}>
						<Box id="actions-box"
							style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
						>
							{tActions.map((action, i) => (
								<Grid container className={classes.treeNode} id={action.id} key={`t${tIndex}-ac${i}`}>
									<div style={{
										display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
										// marginTop: "8px",
										borderRadius: '16px',
										padding: "8px 8px", width: "100%"
									}
									}>
										{action.type == ACTION_TYPE.VARIABLE_ACTION && (
											<>
												<DropDownButton options={selectableActions} defaultOption={'set-variable'} size="small" style={{ marginRight: 10 }}
													buttonClickAction={(selected: string) => changeAction(i, selected)} key={`t${tIndex}-al${i}`} />
												<DropDownButton options={variableOptions} defaultOption={action.varName} size="small" style={{ marginRight: 10 }}
													buttonClickAction={(selected: string) => changeVActionVariable(i, selected)} key={`t${tIndex}-ava${i}`} />
												<DropDownButton options={action.valueOptions} defaultOption={action.varValue} size="small" style={{ marginRight: 10 }}
													buttonClickAction={(selected: string) => changeVActionValue(i, selected)} key={`t${tIndex}-av${i}`} />
											</>
										)}
										{action.type == ACTION_TYPE.NEXTSTEP_ACTION && (
											<><DropDownButton options={selectableActions} defaultOption={'move-to-next-step'} size="small" style={{ marginRight: 10 }}
												buttonClickAction={(selected: string) => changeAction(i, selected)} key={`t${tIndex}-al${i}`} />
												<Grid item xs={3}>
														<PanelInputField props={{ type: "textInput", label: 'Number of seconds to wait', text: action.waitInSeconds!, onChange: (event: any) => {
															changeNextStepActionWaitSeconds(i, event) }, key: `t${tIndex}-ap${i}` }} />
													</Grid></>
										)}
										{action.type == ACTION_TYPE.WAIT_ACTION && (
											<DropDownButton options={selectableActions} defaultOption={'wait-in-seconds'} size="small" style={{ marginRight: 10 }}
												buttonClickAction={(selected: string) => {
													changeAction(i, selected)}} key={`t${tIndex}-al${i}`} />
										)}
										{action.type == ACTION_TYPE.OBJECT_ACTION && (
											<>

												<DropDownButton options={selectableActions} defaultOption={action.actionType} size="small" style={{ marginRight: 10 }}
													buttonClickAction={(selected: string) => changeAction(i, selected)} key={`t${tIndex}-al${i}`} />

												<div style={{
													display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
												}
												}>
													<DropDownButton options={objectOptions} defaultOption={action.objectId} size="small" style={{ marginRight: 10 }}
														buttonClickAction={(selected: string) => changeOActionObjectId(i, selected)} key={`t${tIndex}-av${i}`} />
													{(objectSelectionState != i) &&
														<><Tooltip title="Click to select an object from the scene">
															<Link variant="caption" component="button" underline="always"

																onClick={(e: any) => {
																	if (objectSelectionState != i) {
																		/*
																		objectSelectionLoopIntervalReference = window.setInterval(listenFor3DSceneInteraction, 100);
																		window.onkeydown = (e:KeyboardEvent) => {
																			console.log(e)
																			if(e.key === "Escape") {
																				clearListeningFor3DSceneInteraction();
																			}
																		}*/

																		setObjectSelectionState(i);
																		AddObjectClickSpy.objectSelectionState = i;
																		AddObjectClickSpy.ptrToSetActionToNode = setActionToNode;

																		if (AddObjectClickSpy.actionOrConditionStatePointer) {
																			AddObjectClickSpy.actionOrConditionStatePointer(-1);
																			AddObjectClickSpy.actionOrConditionStatePointer = undefined;
																		}

																		AddObjectClickSpy.actionOrConditionStatePointer = setObjectSelectionState;
																	}
																}}
															><EditIcon fontSize={'small'}

															color='secondary'/>
															</Link>
														</Tooltip>
														</>
													}
													{(objectSelectionState == i) &&
														// <PanelInputField props={{
														// 	label: "Action", type: "readOnlyText", text: "Select an object in the scene", style: { width: '30%', marginRight: 10 },
														// 	onFocus: (e: any) => {
														// 	}
														// }} />
														<Tooltip open={true} title="Waiting to select object in the scene">
															<div><IndicatorPulse /></div>
														</Tooltip>
													}

												</div>
												{
													Simulation.instance.propertiesPanel.isActionParameterized(action.actionType!) &&
													<Grid item xs={3}>
														<PanelInputField props={{ type: "textInput", text: action.parameters!, onChange: (event: any) => { changeObjectActionParameters(i, event) }, key: `t${tIndex}-ap${i}` }} />
													</Grid>
												}

												{
													(action.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) &&
													<DropDownButton options={variablesOptions} defaultOption={action.iotVariableName} size="small" style={{ marginRight: 10 }}
														buttonClickAction={(selected: string) => changeOActionIOTVariableName(i, selected)} key={`t${tIndex}-aiot${i}`} />
												}
												{/* </div> */}
											</>
										)}
										<IconButton color="inherit" size="small" key={`aic${i}`}
											className={classes.removeButton}
											onClick={() => removeAction(action)}
										>
											<CancelIcon key={`t${tIndex}-aci${i}`} />
										</IconButton>
									</div>
								</Grid>
							))}
							{/* <IconButton id={`t${tIndex}-add-button2`} color="secondary"
							className={classes.addTriggerButton} size="medium"
							onClick={() => addAction()}
						>
							<AddCircleOutlineIcon />
						</IconButton> */}
							<Button id={`t${tIndex}-add-button2`} color="secondary"
								style={{ textAlign: "center", display: "block" }}
								// className={classes.addTriggerButton}
								size="small"
								variant="outlined"
								onClick={() => addAction()}
							>Add Action
							</Button>
						</Box>
					</Grid>
				</Grid>)

	}
	return (
		<Paper className={classes.paper} >
			{/* <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
				{[{ sub: "Something is", text: "Clicked" }, { sub: "Something is", text: "Hovered on" }, { sub: "", text: "Variable value is" }].map(x => {
					return (
						<Box style={{
							display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							borderRadius: '16px', border: '0.5px solid grey',
							margin: '8px'
						}} >
							<div style={{
								fontSize: '10px',
								// display: "block",
								paddingTop: '8px',
								paddingLeft: '8px',
								paddingRight: '8px'
							}}>
								{x.sub}
							</div>
							<div style={{
								fontSize: '14px',
								// display: "block",
								padding: '8px',
								paddingTop: '0px'
							}}>
								{x.text}
							</div>
						</Box>


					)
				})}
			</Box> */}
			<div style={{
				backgroundColor: "#3060722e",
				padding: "8px 8px", borderRadius: '16px', margin: '8px',
				display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'
			}}>
				<Typography className={'h7'}
				// className={classes.actionTitle}
				> If </Typography>
				<LogicTreeNestedCondition
					depth={1}
					tIndex={tIndex.toString()}
					conditionsData={conditionData.conditionRoot as NestedCondition}
					updateConditions={(data: NestedCondition) => updateRootConditions(data)}
				/>
			</div>
			{/* <Divider style={{ margin: '10px 0 10px 50px' }} /> */}

			<div style={{
				backgroundColor: "#3060722e", padding: "8px 8px", borderRadius: '16px', margin: '8px',
				display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'
			}}>
				<Typography className={'h7'}

				// className={classes.actionTitle}
				> Then</Typography>
				<LogicActions/>
			</div>
		</Paper>
	)
}

interface Props {
	tagGroup: any,
	openLogicTreesDrawer?: boolean,
	onCloseDrawer(): void
	setHasPendingChanges(value: boolean): void;
	// hasChanges: any;
}

export default function LogicTrees({
	tagGroup,
	openLogicTreesDrawer,
	onCloseDrawer,
	setHasPendingChanges,
	// hasChanges,
}: Props) {
	const [logic, setLogic] = useState<Logic>({ conditions: [] });
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const classes = useStyles();

	const dispatch = useDispatch();
	const { currentSpace, currentLesson, currentTagGroupId
	} = useSelector<AppState, AppState['home']>(({ home }) => home);

	useEffect(() => {

		// const getTagGroupData = async () => {
		// 	if (currentSpace?.id) {
		// 		if (currentLesson?.id) {
		// 			if (currentTagGroupId) {
		// 				//setTagGroupData(await (await firestore.doc(`Spaces/${currentSpace?.id}/lessons/${currentLesson}/tagGroups/${currentTagGroupId}`).get()).data);
		// 				let docRef = await firestore.doc(`Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups/${currentTagGroupId}`);
		// 				let doc = await docRef.get();
		// 				let data = await doc.data();
		// 				if (data) {
		// 					//setTagGroupData(data);
		// 					if (data.logic) {
		// 						setLogic({
		// 							conditions: (data.logic as Logic).conditions.map(e => {
		// 								if (!e.id) {
		// 									return ({ id: ShortUuid.generate(), ...e });
		// 								}
		// 								return e;
		// 							})
		// 						});
		// 					} else {
		// 						setLogic({ conditions: [] });
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// }

		//getTagGroupData();
		// From from tagGroup
		if (tagGroup[UserDataProperties.logic]) {

			setLogic({
				conditions: (tagGroup[UserDataProperties.logic] as Logic).conditions.map(e => {
					if (!e.id) {
						return ({ id: ShortUuid.generate(), ...e });
					}
					return e;
				})
			});

		}
		else {
			setLogic({ conditions: [] });
		}

		return () => {
			setLogic({ conditions: [] });
		}
	}, []);

	useEffect(() => {
		let hasChanges = detectChanges();
		setIsChanged(hasChanges);
		// setHasPendingChanges(hasChanges);
	}, [logic]);

	// React.useEffect(() => {
	// 	hasChanges.current = detectChanges
	// }, []);

	function addNewLogicTree() {
		let conditionTreesTemp: ConditionTree[] = logic.conditions.map(e => e);
		let newConditionTree: ConditionTree = {
			id: ShortUuid.generate(),
			actions: [],
			conditionRoot: {
				conditionJoiner: CONDITION_JOINER.ALL,
				conditions: []
			}
		};
		conditionTreesTemp.push(newConditionTree);
		setLogic({ conditions: conditionTreesTemp });
	}
	function saveLogicTree() {
		// Simulation.instance.propertiesPanel.updateLogic(logic);

		tagGroup.logic = logic;
		dispatch(onUpdateTagGroup(tagGroup));
		setIsChanged(false);
		setHasPendingChanges(false);
	}

	function isLogicEmpty(logic: Logic) {
		if (logic.conditions && logic.conditions.length == 0) {
			return true;
		}
		let actionsPresent = logic.conditions.find((condition: ConditionTree) => condition.actions.length > 0);
		let conditionsPresent = logic.conditions.find((condition: ConditionTree) => condition.conditionRoot.conditions.length > 0);

		return !actionsPresent && !conditionsPresent;

	}

	function detectChanges() {
		console.log(`[st] detecting changes ${JSON.stringify(logic)} ----- ${JSON.stringify(tagGroup[UserDataProperties.logic])}`);

		if (!tagGroup[UserDataProperties.logic]) {

			return !isLogicEmpty(logic);
		}

		return _.isEqual(tagGroup[UserDataProperties.logic], logic) === false;
	}

	function updateTree(data: ConditionTree, index: number) {
		let conditionTreesTemp: ConditionTree[] = logic.conditions.map(e => e);
		conditionTreesTemp[index] = data;
		setLogic({ conditions: conditionTreesTemp });
	}

	function removeTree(index?: string) {
		if (index) {
			let conditionTreesTemp: ConditionTree[] = logic.conditions.filter(e => e.id != index);
			setLogic({ conditions: conditionTreesTemp });
		}
	}
	function dashedContainer(text: string, onClick: any) {
		return <div style={{ border: "1px dashed grey", width: "100%" }} onClick={onClick}>{text}</div>
	}

	console.log(`[st] rendering LogicTrees`);

	return (
		<React.Fragment key={`Logic-Trees-for-${currentSpace}-${currentLesson}-${currentTagGroupId}`}>
			{/* <Drawer
			anchor="right"
			open={openLogicTreesDrawer ? true : false}
			onClose={onCloseDrawer}
		> */}
			<Prompt
				when={isChanged}
				message="You have unsaved changes. Are you sure to leave this page..?"
			/>
			<Box className={classes.drawer}>
				{logic.conditions.map((conditionTree: ConditionTree, index: number) => (
					<Box className={classes.treeBox} key={`tree-${conditionTree.id}-wrapper`}>
						<LogicTree
							// tagGroup={tagGroup}
							key={`tree-${conditionTree.id}`}
							tIndex={conditionTree.id ? conditionTree.id : 'x'}
							conditionData={conditionTree}
							updateTree={(data: ConditionTree) => updateTree(data, index)}
						/>
						<IconButton color="secondary" size="small" key={`remove-tree-${conditionTree.id}-button`}
							className={classes.removeTreeBtn}
							onClick={() => {
								console.log(`[st] deleting interaction`);

								let response = window.confirm("Are you sure you want to delete this interaction - including all its IFs and THENs?");
								if (response) {
									removeTree(conditionTree.id)
								}
							}}
						>
							<Tooltip title={'Delete Interaction?'} key={`tree-${conditionTree.id}-tooltip`}>
								<CancelIcon key={`remove-tree-${conditionTree.id}-button-icon`} />
							</Tooltip>
						</IconButton>
					</Box>
				))}
				{/* {dashedContainer("Add Interaction", () => addNewLogicTree())} */}
				<Box component="div">
					<Button
						variant='contained'
						color='primary'
						size='small'
						disabled={isChanged == false}
						className={classes.addLogicTreeButton}
						type='button'
						onClick={() => saveLogicTree()}
					>
						Save
					</Button>
					<Button
						variant='contained'
						color='secondary'
						size='small'
						style={{ float: 'right', marginLeft: 20 }}
						className={classes.addLogicTreeButton}
						type='button'
						onClick={() => addNewLogicTree()}
					>
						Add New
					</Button>
				</Box>
			</Box>
			{/* </Drawer> */}
		</React.Fragment>
	)
}
