import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia, Typography } from '@material-ui/core';
import { Scrollbar } from '@crema';
import AppCard from '@crema/core/AppCard';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import ConfirmationDialog from '@crema/core/ConfirmationDialog';
import { TagGroup } from 'types/models/home/HomeApp';

import VariableCard from './VariableCard';
import { onSetVariable } from 'redux/actions/Home';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';

const useStyles = makeStyles((theme) => ({

    bar: {
        position: 'absolute',
        top: "2px",
        width: '100%',
        height: '60px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '14px 14px'
    },
    bannerText: {
        color: 'white',
        textAlign: 'center'
    },
    cover: {
        width: 151,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 151,
        },
    },
    dialogRoot: {
        '& .MuiPaper-root': {
            backgroundColor: "black"
          }
    }

}));

interface Props {

    onClose: () => void;
    open: boolean;
    // evaluateVariableTriggerConditions: () => void;

}

const VariablesInputDialog: React.FC<Props> = ({ onClose, open, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { tagGroupsList, currentTagGroupId, currentSpace, variableValues } = useSelector<AppState, AppState['home']>(
        ({ home }) => home,
    );

    const [varValue, setVarValue] = useState(undefined);
    const [isvarInputDialogOpen, setVarInputDialog] = useState(open);
    let currentTagGroup = currentTagGroupId && tagGroupsList.find(tg => tg.id === currentTagGroupId);

    const evaluateVariableTriggerConditions = () =>
        Simulation.instance.evaluateVariableTriggerConditions(variableValues);

    useEffect(() => {
        currentTagGroupId && setVarInputDialog(true);
    }, [currentTagGroup])

    let variable = currentTagGroup && currentTagGroup?.variableNameForInput && currentSpace && currentSpace.variables &&
        currentSpace.variables.find(variable => variable.name.toLowerCase() === currentTagGroup?.variableNameForInput?.toLowerCase());
    // if (variable.values) {
    //     try{
    //         variable.values = variable.values.split(',');
    //     }
    //     catch(err: any) { alert(variable.values)}
    // }

    // let isvarInputDialogOpen = true;
    const onCloseLocal = () => setVarInputDialog(false);

    // currentTagGroupId && tagGroupsList.find(tg => tg.id === currentTagGroupId)?.bannerText ?
    //     return (<Box className={classes.bar}>
    //   <Typography variant="h6"> {bannerText} </Typography>
    // </Box>)
    //     :

    // alert(JSON.stringify( variable.values ))
    return ((console.log(`[st] rendering VariablesInputDialog`)), currentTagGroup && currentTagGroup.variableNameForInput && variable && variable.values ?

        <ConfirmationDialog
            open={isvarInputDialogOpen}
            onDeny={() => { onCloseLocal() }}
            onConfirm={() => {
                dispatch(onSetVariable({ name: currentTagGroup?.variableNameForInput, value: varValue }));
                // isvarInputDialogOpen = false
                evaluateVariableTriggerConditions();
                onCloseLocal();

            }}
            // onDeny={() => alert("oclod")}
            // onConfirm={onClose}
            title={""}
            dialogTitle={`Select: ${currentTagGroup.variableNameForInput}`}
            yesLabel={"Confirm"}
            noLabel={"Cancel"}
            dark={true}
        // bg={{ darkTranslucentBg: true }}
        >

            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {variable.values.split(',').map((v: any) => v.trim()).map((value: any) =>

                    <VariableCard
                        name={value} onClick={() => setVarValue(value)} isActive={varValue == value}
                    />
                )
                }
            </Box>
        </ConfirmationDialog>
        :
        null)


};

export default VariablesInputDialog;
