import { GenericInputEvent } from "../../../components/systemComponents/GenericInput";
import { IComponentEventSpy } from "../../sceneManagement/SceneComponent";

export class GenericInputDragEndSpy implements IComponentEventSpy<unknown> {
    public eventType = GenericInputEvent.DragEnd;
    constructor() {
    }
    onEvent(payload: any) {
    }
}
