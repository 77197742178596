import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {IQuizDetails} from '../../../../../../../../../types/models/home/HomeApp';
import {Typography} from '@material-ui/core';

interface Props {
  quizDetails: IQuizDetails;
  setQuizDetails(form: IQuizDetails): void;
  saveBtnClick:boolean;
}

export default function QuizTagForm({quizDetails, setQuizDetails,saveBtnClick}: Props) {
  
  useEffect(()=>{
    setQuizDetails({...quizDetails, optionsCount: quizDetails.optionsCount});
  },[quizDetails.question]);
  
  return (
    <>
      <TextField
        autoFocus
        fullWidth
        value={quizDetails?.question}
        onChange={(e) => {
          setQuizDetails({
            ...quizDetails,
            question: e.target.value,
          });
        }}
        margin='dense'
        id='quizQuestion'
        name='quizQuestion'
        label='Question'
        placeholder='Write Question...'
        multiline
        error={quizDetails.question === ''&&saveBtnClick}
        helperText={
          quizDetails.question === ''&&saveBtnClick &&
          'Required field.'
        }
      />
      <TextField
        type='number'
        inputProps={{
          maxLength: 1,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        value={quizDetails?.optionsCount}
        onChange={(e) => {
          if (e.target.value != '') {
            setQuizDetails({
              ...quizDetails,
              optionsCount: parseInt(e.target.value),
            });
          }
        }}
        margin='dense'
        id='optionsCount'
        name='optionsCount'
        label='Options Count'
        placeholder='Options Count...'
        multiline
        error={quizDetails.optionsCount === 0 && saveBtnClick}
        helperText={
          quizDetails.optionsCount === 0 && saveBtnClick &&
          'Required field.'
        }
      />
      <div style={{marginBottom: '10px'}}>
        {quizDetails.optionsCount > 0 && Array.from(Array(quizDetails.optionsCount).keys(), count => count + 1).map((item, index) => {
          return (
            <TextField
              key={`textField${index}`}
              id='quiz_question'
              name='quiz_question'
              fullWidth
              margin='dense'
              label={`Option ${item}`}
              placeholder='Write Option...'
              value={quizDetails.options?.[index]}
              onChange={(e) => {
                let newQuizOptions = [...quizDetails.options];
                newQuizOptions[index] = e.target.value;
                setQuizDetails({
                  ...quizDetails,
                  options: newQuizOptions,
                });
              }}
              multiline
              error={(quizDetails.options?.[index]?false:true)&&saveBtnClick}
              helperText={
                (quizDetails.options?.[index]?false:true)&&
                saveBtnClick &&
                'Required field.'
              }
            />
          );
        })}
      </div>

      <Typography component='legend' style={{fontSize: '12px'}}>
        Correct Answer
      </Typography>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {quizDetails.optionsCount > 0 && Array.from(Array(quizDetails.optionsCount).keys(), count => count + 1).map((item, index) => {
          return (
            <div
              key={`options${index}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Checkbox
                checked={quizDetails.answer.includes(item.toString())}
                color='primary'
                value={item}
                onChange={(e) => {
                  if (e.target.checked) {
                    let newQuizAnswer: string[] = [];
                    newQuizAnswer = [...quizDetails.answer, e.target.value];
                    setQuizDetails({
                      ...quizDetails,
                      answer: newQuizAnswer,
                    });
                  } else {
                    let filterQuizAnswer = quizDetails.answer.filter(
                      (val) => val !== e.target.value,
                    );
                    setQuizDetails({
                      ...quizDetails,
                      answer: filterQuizAnswer,
                    });
                  }
                }}
                inputProps={{'aria-label': 'secondary checkbox'}}
              />
              <Typography variant='body2'>{`Option ${item}`}</Typography>
            </div>
          );
        })}
      </div>
    </>
  );
}
