import { store } from "App";

class MpSdk {
    private static _instance: MpSdk;
    public mps: any;

    public static get Instance(): MpSdk {

        if (!this._instance) {
            this._instance = new MpSdk();
        }
        return this._instance;
        // .mps;
        // return this._instance?.mps || (this._instance = new this(), this._instance?.mps);

        // if (!MpSdk._instance) {
        //     MpSdk._instance = (new MpSdk()).mps;
        // }

        // return (MpSdk._instance).mps;
    }

    init(mpSdk: any) {
        // if(mpSdk) {
        this.mps = mpSdk;
        // this.mps.on(MpSdk.Instance.mps.Mattertag.Event.HOVER, this.hoverHandler);
        // this.mps.on(MpSdk.Instance.mps.Mattertag.Event.CLICK, this.clickHandler);
        // this.mps.on(MpSdk.Instance.mps.Mattertag.Event.LINK_OPEN, () => console.log(`[st] link opened`));
        // }

    }

    public hoverHandler = (tagSid: any) => {
        // console.log(`[st] hovering ${tagSid}`);

        // prevent the billboard from showing
        // var noBillboardTag = mattertagData[1];
        // this.mps.Mattertag.preventAction(tagSid, {
        //     opening: false,
        // }).then(() => {
        this.mps.Mattertag.getData().then((data: any) => {
            let tag = data.find((t: any) => t.sid == tagSid);
            if (tag) {
                let discAnchorPosition = this.mps.Mattertag.getDiscPosition(tag);
                // console.log(`[st] hovering 2 ${JSON.stringify(discAnchorPosition)}`);
            }
        }).catch(console.error);

        // }).catch(console.error);
    }


    public clickHandler = (tagSid: any) => {
        // console.log(`[st] hovering ${tagSid}`);

        // prevent the billboard from showing
        // var noBillboardTag = mattertagData[1];
        this.mps.Mattertag.navigateToTag(tagSid, MpSdk.Instance.mps.Mattertag.Transition.FLY)
            // .preventAction(tagSid, {
            //     opening: false,
            // }).then(() => {
            //     this.mps.Mattertag.getData().then((data: any) => {
            //         let tag = data.find((t: any) => t.sid == tagSid);
            //         if (tag) {
            //             let discAnchorPosition = this.mps.Mattertag.getDiscPosition(tag);
            //                // console.log(`[st] hovering 2 ${JSON.stringify(discAnchorPosition)}`);
            //         }
            //     }).catch(console.error);
            // })
            .catch(console.error);
    }


    public goToHomeSweep = async () => {
        let space = store.getState().home.currentSpace;
        this.mps && space && space.homeSweepId && this.mps.Sweep.moveTo(space.homeSweepId)
            .then(async () => {
                this.mps.Camera.zoomTo(0.7).then(() => { }).catch(console.error);

            }).catch(console.error);
    }

}
export default MpSdk;


// export namespace Mpsdk {
//     mps: any;
//     export function mpSdk() { return mps}
// }
// // Usage
// import { SingletonInstance } from "path/to/Singleton";

// SingletonInstance.someMethod();
// var x = SingletonInstance; // If you need to alias it for some reason