import React, {useContext, useEffect} from 'react';
import {firestore} from '../../../@crema/services/auth/firebase/firebase';
import Avatar from '@material-ui/core/Avatar';
import {useDispatch, useSelector} from 'react-redux';
import {onSignOutFirebaseUser} from '../../../redux/actions';
import {useAuthUser} from '../../../@crema/utility/AppHooks';
import AppContext from '../../../@crema/utility/AppContext';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import {blueGrey, orange} from '@material-ui/core/colors';
import {Fonts, ThemeMode} from '../../constants/AppEnums';
import AppContextPropsType from '../../../types/AppContextPropsType';
import {AuthUser} from '../../../types/models/AuthUser';
import {useHistory} from 'react-router-dom';
import { AppState } from 'redux/store';
import {getUserAvatar} from "../../../@crema/utility/Utils";


const useStyles = makeStyles((theme) => {
  return {
    crUserInfo: {
      backgroundColor: 'rgba(0,0,0,.08)',
      paddingTop: 9,
      paddingBottom: 9,
      minHeight: 56,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 70,
      },
    },
    profilePic: {
      fontSize: 18,
      backgroundColor: blueGrey[700],
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 16,
      fontWeight: Fonts.MEDIUM,
      color: (props: {themeMode: ThemeMode}) =>
        props.themeMode === ThemeMode.LIGHT ? '#313541' : 'white',
    },
    designation: {
      marginTop: -2,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    pointer: {
      cursor: 'pointer',
    },
  };
});

const UserInfo: React.FC<{}> = () => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();
//   const authUser: AuthUser | null = useAuthUser();
  const {authUser} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [userName, setUserName] = React.useState(null);

  useEffect(() => {
    if (authUser) {
      var docRef = firestore.collection('Users').doc(authUser.uid);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const obj = doc.data();
            setUserName(obj?.firstName);
            console.log(obj?.firstName, 'usename');
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
    }
  });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles({themeMode});

  return (
    <Box
      px={{xs: 4, xl: 7}}
      className={clsx(classes.crUserInfo, 'cr-user-info')}>
      <Box display='flex' alignItems='center'>
        {authUser && authUser.photoURL ? (
          <Avatar className={classes.profilePic} src={authUser.photoURL} />
        ) : (
          <Avatar className={classes.profilePic}>{getUserAvatar(authUser)}</Avatar>
        )}
        <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Box mb={0} className={clsx(classes.userName)}>
              {authUser && (authUser.displayName ? authUser.displayName : userName)}
            </Box>
            <Box
              ml={3}
              className={classes.pointer}
              color={themeMode === 'light' ? '#313541' : 'white'}>
              <ExpandMoreIcon onClick={handleClick} />
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    // console.log('clicked');
                    history.push('/myaccount');
                    handleClose();
                  }}>
                </MenuItem>
                <MenuItem onClick={() => dispatch(onSignOutFirebaseUser())}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfo;