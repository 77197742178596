import React, { useContext, useEffect } from 'react';
import { makeStyles, Theme, createStyles, fade } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';
// import { IconButton } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';

import { handleNextTagGroup, handleBackTagGroup } from 'redux/actions/Step';

import { Keys } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import { TagGroup, ACTIONS, OBJECT_TYPES } from 'types/models/home/HomeApp';
import clsx from 'clsx';
import { getCurrentTagGroup, getTagGroupsLength } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    // buttonContainer: {
    //   height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
    //   position: "absolute",
    // },
    forwardIcon: {

      width: 0,
      height: 0,
      borderTop: '60px solid transparent',
      borderBottom: '60px solid transparent',
      borderLeft: '30px solid #00000080',
      transition: 'all .2s',
      '& .fav-btn': {
        opacity: 0,
        visibility: 'hidden',
      },
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.1),
        transform: 'translateY(-4px)',
        '& .fav-btn': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    backIcon: {

      width: 0,
      height: 0,
      borderTop: '60px solid transparent',
      borderBottom: '60px solid transparent',
      borderRight: '30px solid #00000080',
      transition: 'all .2s',
      '& .fav-btn': {
        opacity: 0,
        visibility: 'hidden',
      },
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.1),
        transform: 'translateY(-4px)',
        '& .fav-btn': {
          opacity: 1,
          visibility: 'visible',
        },
      },

    },

    // leftButton: {
    //   // backgroundColor: " green",
    //   position: "absolute",
    //   // top: "-68px",
    //   // left: "41px",
    //   top: '50%',

    // },
    fabDisabled: {
      backgroundColor: "grey",
    }
  }),
);
interface Props { }

export default function LessonsPlayTools({ }: Props) {
  const classes = useStyles();

  const currentLessonId = useSelector<AppState, string>(({ home }) => home?.currentLesson?.id || '');
  const currentTagGroupId = useSelector<AppState, string>(({ home }) => home.currentTagGroupId);
  const dispatch = useDispatch();
  // const { authUser } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  const keyCodeHandlingEvent = async (event: any) => {
    //

    // switch (event.keyCode) {
    //   case Keys.F:

    //     handleNextTagGroup();
    //     //this.state.activePopover.hide();
    //     // Simulation.instance.cancelAddObject();
    //     //TODO also cancel adding tag
    //     break;

    //   case Keys.B:
    //     handleBackTagGroup();
    //     // await initMpSdk();
    //     // mpSdk && mpSdk.Camera.lookAtScreenCoords(-11, 1.4);
    //     break;
    //   default:
    //     break;
    // }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyCodeHandlingEvent);
    return () => {
      document.removeEventListener('keydown', keyCodeHandlingEvent);
    };
  }, []);


  let tagGroup: TagGroup | undefined;
  let tagGroupsLength = 0;
  let isLastStep = false;

  if (currentTagGroupId) {
    tagGroup = getCurrentTagGroup();
    tagGroupsLength = getTagGroupsLength();
    isLastStep = tagGroup?.sortIndex === tagGroupsLength - 1;
  }

  return (console.log(`[st] rendering LessonPlayTools`),
    <>
      {currentLessonId && (

        <div>
          <div
            style={{
              position: 'absolute', height: "100%", display: 'flex',
              flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              pointerEvents: 'none'
            }}
          >
            <Tooltip title={`Previous Step: ${((!tagGroup?.sortIndex || tagGroup.sortIndex == 0) ? 'None' : tagGroup.sortIndex)} of ${tagGroupsLength}`}>
              <Button
                style={{ pointerEvents: 'all' }}
                // className={clsx(
                disabled={tagGroup?.sortIndex === 0}
                //? classes.fabDisabled : ''}
                // )}
                onClick={(e) => {
                  if (tagGroup) {
                    dispatch(handleBackTagGroup());
                  }
                }} >
                <div className={classes.backIcon}></div>

              </Button></Tooltip>
          </div>

          <div
            style={{ position: 'absolute', right: '0%', height: "100%", display: 'flex',
            flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}
          >
            <Tooltip title={`Next Step: ${isLastStep ? 'None' : (tagGroup?.sortIndex || 0) + 2} of ${tagGroupsLength}`}>
              <Button
                style={{ pointerEvents: 'all' }}
                disabled={isLastStep}
                // className={clsx(
                //   // classes.rightButton,
                //   (isLastStep) ? classes.fabDisabled : '')}
                onClick={(e) => {
                  if (tagGroup?.sortIndex !== tagGroupsLength - 1) {
                    dispatch(handleNextTagGroup());
                  }
                }} >
                <div className={classes.forwardIcon}></div>
              </Button></Tooltip>
          </div>
        </div>

      )
      }
    </>
  );
}
