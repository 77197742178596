import React from 'react';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {ListItem, makeStyles} from '@material-ui/core';
import {Fonts} from '../../../shared/constants/AppEnums';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
// import {NotificationData} from '../../services/db/notifications/notification';
import {onGetNotificationList, onMarkReadNotification} from 'redux/actions';
import {NotificationModel} from 'types/models/NotificationModel';
import {Link, useHistory} from 'react-router-dom';
import {CremaTheme} from '../../../types/AppContextPropsType';
import { useDispatch } from 'react-redux';
import { useAuthUser } from '@crema/utility/AppHooks';

const useStyles = makeStyles((theme: CremaTheme) => ({
  textBase: {
    fontSize: 14,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  minWidth0: {
    minWidth: 0,
  },
  listItemRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 20px',
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textSm: {
    fontSize: 12,
  },
  notifBody: {
    display: 'flex',
  },
  badge: {
    position: 'absolute',
    top: '8px',
    right: '8px',
  },
  itemAvatarBadge: {
    position: 'relative',
  },
  badgeDot: {
    height: '10px',
    minWidth: '10px',
    borderRadius: '6px',
  },
}));

interface NotificationItemProps {
  item: NotificationModel;
}

const NotificationItem: React.FC<NotificationItemProps> = ({item}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useAuthUser();
  // console.log(item.data, 'NotificationItem');
  return (
    <ListItem
      className={clsx(classes.listItemRoot, 'item-hover')}
      onClick={() => {
        dispatch(
          onMarkReadNotification(user, item),
        );
        dispatch(onGetNotificationList(user));
        history.push({pathname: `/${item.link}`, state: {data: item.data}});
      }}>
      <div className={classes.notifBody}>
        <Box mr={4}>
          <ListItemAvatar className={classes.minWidth0}>
            <div className={classes.itemAvatarBadge}>
              <Avatar
                className={classes.avatar}
                alt='Remy Sharp'
                src={'https://via.placeholder.com/150'}
              />
              {!item.read && (
                <Badge
                  className={classes.badge}
                  badgeContent={''}
                  color='secondary'
                  variant='dot'
                  classes={{dot: classes.badgeDot}}
                />
              )}
            </div>
          </ListItemAvatar>
        </Box>
        <Box component='p' className={classes.textBase} color='text.secondary'>
          {/* <Box
            mr={2}
            component='span'
            display='inline-block'
            color='text.primary'
            fontWeight={Fonts.MEDIUM}>
            {item?.title || ''}
          </Box> */}
          {item.text}
        </Box>
      </div>
      <div style={{textAlign: 'right', width: '100%'}}>
        <Box
          mt={1}
          color='text.secondary'
          className={clsx(classes.truncate, classes.textSm)}>
          {item?.createdOn &&
           new Date(item?.createdOn).toDateString()}
        </Box>
      </div>
    </ListItem>
  );
};

export default NotificationItem;
