import * as THREE from 'three';
import {store} from '../../App';
import {ShowcaseTag} from '../../types/models/home/HomeApp';
import {RenderingSystem} from './RenderingSystem';

export class TagSystem {
    protected fontLoader: THREE.FontLoader;
    protected iotArray: any[];
    protected font:THREE.Font;

    constructor(protected renderingSystem:RenderingSystem) {
        this.fontLoader = new THREE.FontLoader();
        this.fontLoader.load('/fonts/helvetiker_regular.typeface.json', (font: THREE.Font) => {
            this.onFontLoaded(font);
            this.font = font;
        });
        this.iotArray = [];

    }

    protected getRandomNIoTNumber() {
        return Math.floor(Math.random() * 99);
    }

    protected RoundedRectShape(width: number, height: number, radius: number) {
        const roundedRectShape = new THREE.Shape();
        radius *= 0.001;

        roundedRectShape.moveTo(0, radius);
        roundedRectShape.lineTo(0, height - radius);
        roundedRectShape.quadraticCurveTo(0, height, radius, height);
        roundedRectShape.lineTo(0 + width - radius, height);
        roundedRectShape.quadraticCurveTo(width, height, width, height - radius);
        roundedRectShape.lineTo(width, radius);
        roundedRectShape.quadraticCurveTo(width, 0, width - radius, 0);
        roundedRectShape.lineTo(radius, 0);
        roundedRectShape.quadraticCurveTo(0, 0, 0, radius);

        return roundedRectShape;
    }

    protected createText(font: THREE.Font, labelText: string, color: number, zOffset = 0) {
        const matLite = new THREE.MeshBasicMaterial({
            color: color,
            transparent: false,
            opacity: 1,
            side: THREE.DoubleSide,
        });

        const message = labelText;

        const shapes = font.generateShapes(message, 100);
        const geometry = new THREE.ShapeGeometry(shapes);
        geometry.computeBoundingBox();

        const xMid = 0.5 * (geometry.boundingBox!.max.x - geometry.boundingBox!.min.x);
        geometry.translate(xMid, 0, zOffset);

        const text = new THREE.Mesh(geometry, matLite);
        text.scale.set(0.0005, 0.0005, 0.0005);
        //text.position.y += 0.05;

        return text;
    }

    protected onFontLoaded(font: THREE.Font): void {
        let dummyResponse = this.GetTags();

        const geometryIOT = new THREE.CylinderGeometry(0.1, 0.1, 0.05, 32).rotateX(THREE.MathUtils.degToRad(90)).translate(0, 0.1, 0);
        const geometryBiggerIOT = new THREE.CylinderGeometry(0.12, 0.12, 0.04, 32).rotateX(THREE.MathUtils.degToRad(90)).translate(0, 0.1, 0);

        // const geometryTag = new THREE.BoxGeometry(0.05, 0.05, 0.05);


        for (let i = 0; i < dummyResponse.length; i++) {
            let tag = dummyResponse[i];
            console.log(tag.label);
            //console.log(tag.position);
            let tagPosition = tag.position;
            //tagPosition.x += 12;
            let randomColor = 0xffffff;// * Math.random();

            if (i == 0) {

            } else if (i == 1) {
                randomColor = 0xea3939;
            } else {
                randomColor = 0x000000;
            }

            let tagMesh = null;

            if (tag.label === 'iot') {
                const material = new THREE.MeshPhongMaterial({color: 0xd35d09});
                tagMesh = new THREE.Mesh(geometryIOT, material);

                tagMesh.position.set(tagPosition.x, tagPosition.y, tagPosition.z);
                this.renderingSystem.base.add(tagMesh);

                const material2 = new THREE.MeshPhongMaterial({color: 0x000000});
                let biggerMesh = new THREE.Mesh(geometryBiggerIOT, material2);
                tagMesh.add(biggerMesh);

                let textMesh = this.createText(font, this.getRandomNIoTNumber().toString(), 0x000000);
                textMesh.position.set(tagPosition.x, tagPosition.y + 0.075, tagPosition.z + 0.035);
                this.renderingSystem.base.add(textMesh);

                this.iotArray.push({mesh: textMesh, position: tagPosition});
            } else {
                //const material = new THREE.MeshPhongMaterial( { color: randomColor } );

                let planeSize = new THREE.Vector2(0.041 * tag.label.length, 0.1);

                tagMesh = new THREE.Mesh(
                    //new THREE.PlaneBufferGeometry(2, 1),
                    new THREE.ShapeGeometry(this.RoundedRectShape(planeSize.x, planeSize.y, 20)).translate(-0.5 * planeSize.x, -0.5 * planeSize.y, 0),//.scale(1, this.oldRootScale.y, 1),
                    //new THREE.ShapeGeometry(Utils.RoundedRectShape(this.context.root.scale.x, this.context.root.scale.y, this.inputs.borderRadius)).translate(-this.context.root.scale.x*0.5, -this.context.root.scale.y*0.5, 0),//.scale(1, this.oldRootScale.y, 1),
                    new THREE.MeshBasicMaterial({
                        transparent: false,
                        color: 0xffffff,
                        polygonOffset: true,
                        polygonOffsetFactor: 100,
                        polygonOffsetUnits: 1,
                        side: THREE.DoubleSide,
                    }));

                //tagMesh = new THREE.Mesh(geometryTag, material);
                tagMesh.position.set(tagPosition.x, tagPosition.y, tagPosition.z);
                this.renderingSystem.base.add(tagMesh);
                let textMesh = this.createText(font, tag.label, randomColor);

                tagMesh.add(textMesh);
                textMesh.position.set(0, -0.025, 0);
            }
        }

    }

    public GetTags(): {label: string, position: {x: number, y: number, z: number}}[] {

        let spaceTags:any[] = [];
        let retrievedSpaceTags:Map<string, ShowcaseTag> = store.getState().home.spaceTags;
        //console.log(retrievedSpaceTags);
        // for(let key of Array.from( retrievedSpaceTags.keys()) ) {
        //     console.log(key);
        // }
        for (let [key, value] of retrievedSpaceTags) {
            // console.log(key);
            // console.log(value);

            let tempTag ={
                label: value.data.label,
                position: { 'x': value.data.anchorPosition.x, 'y': value.data.anchorPosition.y, 'z': value.data.anchorPosition.z }
            }

            spaceTags.push(tempTag);
        }


        let dummyResponse = [
            {label: 'Space Origin', position: {'x': 0, 'y': 0, 'z': 0}},
            // {label: 'Space Origin', position: {'x': 0, 'y': 0, 'z': 1}},
            // {label: 'iot', position: {'x': -11, 'y': 0, 'z': 0.5}},
            ...(spaceTags),
        ];

        return dummyResponse;
    }


}
