import React, { useContext } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import {
  ListItemText,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { TagGroup } from 'types/models/home/HomeApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';

import { firestore } from '@crema/services/auth/firebase/firebase';
import { setTagGroupsList } from 'redux/actions/Step';
import { ShowcaseContext } from '../..';
//import lodash from 'lodash';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
// import {OverflowMenu} from '../../PopMenu';
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
// import { ThreeDIcon } from '@crema/core/Icons/CustomIcons';
import { ReactComponent as ThreeDIcon2Svg } from 'assets/images/3dIcons/ThreeDIcon2.svg';
import { getCurrentTagGroup } from 'modules/home/SpaceDetail/utils';
import { ViewModeSecondary } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagsRoot: {
      // padding: '25px 10px ',
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'flex-start',
      width: "100%",
      height: "100%"
      // width: '320px',

    },
    heading: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      lineHeight: '48px',
    },
    root: {
      // width: '100%',
      // maxWidth: 360,
      marginBottom: '20px',
      paddingBottom: '20px',
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    createTagsLink: {
      paddingLeft: theme.spacing(4),
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonGroup: {
      alignContent: 'center',
      justifyContent: 'center',
      width: '80%',
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    listItem: {
      paddingLeft: '2px',
      paddingRight: '2px',
      fontSize: '12px',
      paddingTop: '8px',
      paddingBottom: '8px',
      // width: '300px',
    },
  }),
);

const InsertedObjects: React.FC = () => {

  const classes = useStyles();

  // const [models, setModels] = useState<any[]>([]);
  const currentSpaceId = useSelector<AppState, string>(({ home }) => home.currentSpace?.id || '');
  const currentLessonId = useSelector<AppState, string>(({ home }) => home.currentLesson?.id || '');
  const currentTagGroupId = useSelector<AppState, string>(({ home }) => home.currentTagGroupId);
  const spaceModels = useSelector<AppState, any>(({ home }) => {
    let spaceModels = new Map<string, any>();
    (Array.from(home.spaceModels.entries())).map((sm: any) => {
      spaceModels.set(sm[0], {id: sm[1].id, name: sm[1].name, nameToShow: sm[1].nameToShow})
    });
    return spaceModels;
  }
  );

  const tagGroupsList = useSelector<AppState, TagGroup[]>(({ home }) => home.tagGroupsList || []);
  const tagsViewModeSecondary = useSelector<AppState, string>(({ home }) => home.tagsViewModeSecondary || '');
  // const [visibleSpaceTags, setVisibleSpaceTags] = React.useState<ShowcaseTag[]>([]);
  let visibleSpaceModels: any[] = [];

  const dispatch = useDispatch();
  let sidebarTitle = '';

  visibleSpaceModels = spaceModels.size > 0 ? Array.from(spaceModels.values()) : [];
  sidebarTitle = '3D Objects';
  const theme = useTheme();

  return (console.log(`[st] rendering InsertedObjects`),
    <div className={classes.tagsRoot}>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        className={classes.root}
        style={{backgroundColor: tagsViewModeSecondary == ViewModeSecondary.LAYERS_3D ? theme.palette.background.default : theme.palette.background.paper}}
        >
        {visibleSpaceModels?.map((model: any) => {
          return (
            <ItemHover key={model.id}>
              <ListItem key={model.id} className={classes.listItem}>
                {/* {Object.keys(currentScene || {}).length !== 0 && ( */}
                {currentTagGroupId && (
                  <Checkbox
                    edge='start'
                      checked={
                      currentTagGroupId &&
                        tagGroupsList &&
                        tagGroupsList.length > 0 &&
                        (tagGroupsList
                          .filter(
                            (tagGroup: TagGroup) =>
                              tagGroup.id === currentTagGroupId,
                          )[0]
                          ?.tagIds || []).includes(model.id)
                        ? true
                        : false
                      // currentScene.tags
                      //   ?.map((o: any) => o?.data?.label)
                      //   .flat()
                      //   .indexOf(showcaseTag.data?.label) !== -1
                    }
                    tabIndex={-1}
                    onChange={async () => {
                      let currentTagGroup = getCurrentTagGroup();
                      let tgs: TagGroup[] = [];
                      if (!currentTagGroup) {
                        return;
                      }
                      if ((currentTagGroup?.tagIds || []).includes(model.id)) {
                        // tagGroupsList =
                        // // currentTagGroup?.tagIds = currentTagGroup?.tagIds.filter((tagId: string) => tagId !== showcaseTag.id);
                        // tagGroupsList.map(((tagGroup: TagGroup) => tagGroup.id == currentTagGroup.id && tagGroup?.tagIds.splice(
                        //   tagGroup?.tagIds.indexOf(showcaseTag.id)
                        // )))

                        //remove model from tagGroup
                        tagGroupsList.forEach((tagGroup) => {
                          if (tagGroup.id == currentTagGroupId) {
                            tagGroup.tagIds = (tagGroup?.tagIds || []).filter(
                              (tid) => tid != model.id,
                            );
                            currentTagGroup = tagGroup;
                          }
                          tgs.push(tagGroup);
                        });
                      } else {

                        //add model to tagGroup
                        tagGroupsList.forEach((tagGroup) => {
                          if (tagGroup.id == currentTagGroupId) {
                            (tagGroup?.tagIds || []).push(model.id);
                            currentTagGroup = tagGroup;
                          }
                          tgs.push(tagGroup);
                        });
                      }
                      dispatch(setTagGroupsList(tgs));

                      await firestore
                        .doc(
                          `Spaces/${currentSpaceId}/lessons/${currentLessonId}/tagGroups/${currentTagGroup.id}`,
                        )
                        .set(currentTagGroup);

                      // tagGroupDocs.docs.map(tagGroupDoc => tgs.push({ id: tagGroupDoc.id, name: tagGroupDoc.data().name,
                      //   tagIds: tagGroupDoc.data()?.tagIds }));

                      // if (
                      //   currentScene.tags
                      //     ?.map((o: any) => o?.data?.label)
                      //     .flat()
                      //     .indexOf(showcaseTag.data?.label) !== -1
                      // ) {
                      //   // let [filterMatterTag] = sceneCollapsableOpen.tags.filter((item: any) => (item.label === tagTableData.data?.label));
                      //   submitSelectTagsForScene(showcaseTag, false);
                      // } else {
                      //   submitSelectTagsForScene(showcaseTag, true);
                      // }
                    }}
                    disableRipple
                    inputProps={{ 'aria-labelledby': model.nameToShow }}
                  />
                )}
                {/* <ListItemIcon style={{ minWidth: '40px' }}>
                      <VisibilityIcon />
                    </ListItemIcon> */}
                <ListItemIcon style={{ minWidth: '40px' }}>
                <ThreeDIcon2Svg style={{fill: "grey", height: 23}}/>

                  {/* <ThreeDIcon
                  // style={{
                  //   // color: '#F57E7E',
                  //   color: theme.palette.secondary.main,
                  // width: '17px'}}
                  /> */}
                  {/*
                  <SportsVolleyballIcon
                  style={{
                    // color: '#F57E7E',
                    color: theme.palette.secondary.main,
                  width: '17px'}}
                  /> */}

                </ListItemIcon>

                <ListItemText
                  id={`${model.name}`}
                  disableTypography={true}
                  primary={model.nameToShow}
                  // className={classes.listItem}
                  style={{
                    cursor: 'pointer',
                    // fontSize: '12px',
                  }}
                  onClick={() => {
                    Simulation.instance.handleModelClick(model.id);
                    // handleTagClick(showcaseTag.id);
                  }}
                />
                {/* {currentSpace && canEdit(currentSpace.spaceMembership.role) && (
                <span style={{ marginLeft: 'auto' }}>
                  <LongMenu
                    mattertag={showcaseTag}
                    onClickTagsPopup={onClickTagsPopup}
                  />
                </span>
              )} */}
              </ListItem>
            </ItemHover>
          );
        })}
      </List>
    </div>
  );
};

export default InsertedObjects;
