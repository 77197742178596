import {ISceneNode, SceneComponent} from '../../mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import * as THREE from 'three';
import {MimickScene} from './MimickScene';
import {BaseComponent} from '../Components/BaseComponent';
import {GLTFComponent} from '../Components/GLTFComponent';

export class SceneNode implements ISceneNode {
    customSceneNode = true;
    
    constructor(protected mimickScene: MimickScene/*, object:any*/) {
        this.customComponents = [];
        
    }
    
    animatedPosition: THREE.Vector3;
    components: SceneComponent[];
    name: string;
    readonly position: THREE.Vector3;
    readonly quaternion: THREE.Quaternion;
    readonly scale: THREE.Vector3;
    unserializedUserData: {[p: string]: any};
    userData: {[p: string]: any};
    
    customComponents: BaseComponent[];
    
    addComponent(factory: string, initialInputs?: SceneComponent['inputs']): SceneComponent {
        // switch (factory) {
        //     case
        // }
        let component = this.mimickScene.componentInitTypeNameMap[factory]();
        console.log(component);
        
        return component;
    }
    
    addCustomComponent(component: BaseComponent): BaseComponent {
        // switch (factory) {
        //     case
        // }
        
        if (component instanceof GLTFComponent) {
            // @ts-ignore
            this['position'] = component.root.position;
            // @ts-ignore
            this['quaternion'] = component.root.quaternion;
            // @ts-ignore
            this['scale'] = component.root.scale;
        }
        
        this.customComponents.push(component);
        return component;
    }
    
    bindEvent(eventType: string, src: SceneComponent, srcEventType: string) {
    
    }
    
    componentIterator(): IterableIterator<SceneComponent> {
        return this.components.values();
    }
    
    start(): void {
        
        if (this.name === 'gltf') {
            console.log('hiiiii');
        } else {
            console.log('SceneNode::start not implemented :(');
        }
    }
    
    stop(): void {
        console.log('SceneNode::stop not implemented :(');
    }
}
