import {
  SpaceData,
  FilterDataSpaces,
  ILesson,
  IScene,
  ISceneData,
  ShowcaseTag,
} from 'types/models/home/HomeApp';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

export interface getTaskList {
  type: typeof GET_TASK_LIST;
  payload: any;
}
export interface createNewTask {
  type: typeof CREATE_NEW_TASK;
  payload: any;
}
export interface getTodoFolderList {
  type: typeof GET_TODO_FOLDER_LIST;
  payload: any;
}
export interface getTodoLabelList {
  type: typeof GET_TODO_LABEL_LIST;
  payload: any;
}
export interface getTodoStatusList {
  type: typeof GET_TODO_STATUS_LIST;
  payload: any;
}
export interface getTodoPriorityList {
  type: typeof GET_TODO_PRIORITY_LIST;
  payload: any;
}

export interface updateTaskFolder {
  type: typeof UPDATE_TASK_FOLDER;
  payload: any;
}
export interface updateTaskDetail {
  type: typeof UPDATE_TASK_DETAIL;
  payload: any;
}
export interface getTodoStaffList {
  type: typeof GET_TODO_STAFF_LIST;
  payload: any;
}

export type ToDoAppActionTypes =
  | getTaskList
  | createNewTask
  | getTodoFolderList
  | getTodoLabelList
  | getTodoStatusList
  | getTodoPriorityList
  | updateTaskFolder
  | updateTaskDetail
  | getTodoStaffList;
