//import React, {useState, useEffect, useRef, useCallback} from 'react';
import React, { useMemo, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
//import Lessons, { IOnClickPlayLessons, IShowcaseSceneTag } from '../../Lessons';

import { useFetch } from 'hooks/useFetch';
import Layout from '../layout';

import ThreeDAccordions from './ThreeDAccordions';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { PropertiesPanelUIData, PropertiesPanelMode } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
import PropertiesPanel from '../../ShowcaseOverlay/3DTools/PropertiesPanel';
import SidebarSkeleton from '../SidebarSkeleton';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Typography } from '@material-ui/core';
import Spacer from '@crema/core/Spacer';
import ThreeDAnnotations from './ThreeDAnnotations';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { ViewModeSecondary } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: '10px', paddingRight: '10px',
      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
      maxWidth: '350px', minWidth: '350px',
      backgroundColor: theme.palette.background.default,
      height: "100%", maxHeight: "100%", minHeight: "100%", overflow: 'scroll'
    },
    cardRoot: {
      backgroundColor: theme.palette.background.default,
    }

  }),
);

interface Props {

}

export default function ThreeDSidebar({

}: Props) {
  const classes = useStyles();
  // const [propertiesPanelUIData, setPropertiesPanelUIData] = React.useState<PropertiesPanelUIData>(new PropertiesPanelUIData());
  // Simulation.instance.propertiesPanel.setPropertiesPanelUIData = setPropertiesPanelUIData;
  const [addObjectExpanded, setAddObjectExpanded] = React.useState<boolean>(true);

  const tagsViewModeSecondary = useSelector<AppState, string>(({ home }) => home.tagsViewModeSecondary);
  // setTagsViewModeSecondary(ViewModeSecondary.LAYERS_3D


  return (console.log(`[st] rendering ThreeDSidebar`),

    <Layout>
      <Box
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          marginRight: '10px'
        }}
      //   className=
      //   // {(tagsViewModeSecondary == ViewModeSecondary.LAYERS_TAGS || tagsViewModeSecondary
      //   // == ViewModeSecondary.LAYERS_3D) ? 'two-column-sidebar-container' :
      //   'sidebar-container'
      // // }
      >
        <Spacer y={10} />
        <Typography variant="h6"
          style={{ textAlign: 'center', display: 'block' }}
        >
          3D Library
        </Typography>
        <Spacer y={5} />

        <Card className={classes.cardRoot}>

          {useMemo(() => <ThreeDAccordions />, [])}

        </Card>
      </Box>
    </Layout>

  );
}
