import { Mesh, MeshBasicMaterial, AnimationMixer, AnimationAction, Object3D } from 'three';
import { SceneComponent } from '../../SubSystems/sceneManagement/SceneComponent';

interface Inputs {
  loadingState: string;
  period: number;
  size: { x: number; y: number; z: number; };
  transitionInDuration: number;
  color: number;
  logo: Object3D | null;
}

export class LoadingIndicator extends SceneComponent {
  private mixer: AnimationMixer | null = null;
  private meshLoaderComponent:SceneComponent;
  private inverseScale:THREE.Vector3;

  inputs: Inputs = {
    loadingState: 'Idle',
    period: 4,
    size: { x: 1, y: 1, z: 1 },
    transitionInDuration: 0.2,
    color: 0x00aa00,
    logo: null,
  }

  onInit() {
    const root = this.context.root;
    const THREE = this.context.three;

    for (const component of root.componentIterator()) {
      if (component.componentType === 'mp.gltfLoader' ||
          component.componentType === 'mp.objLoader' ||
          component.componentType === 'mp.daeLoader') {

        //@ts-ignore
        this.bind('loadingState', component, 'loadingState');

        this.meshLoaderComponent = component;
        
        break;
      }
    }
    this.inverseScale = new THREE.Vector3(1.0 / root.scale.x, 1.0 / root.scale.y, 1.0 / root.scale.z);
    this.onInputsUpdated();
  }

  onDestroy() {
    this.mixer = null;
  }

  onInputsUpdated() {
    const THREE = this.context.three;

    if (!this.inputs.logo) {
      return;
    }

    this.mixer = new THREE.AnimationMixer(this.inputs.logo);
    
    switch(this.inputs.loadingState) {
      case 'Idle':
      case 'Loaded':
        this.inputs.logo.visible = false;
        break;
      case 'Loading':
        {
          this.inputs.logo.visible = true;

          const yAxis = new THREE.Vector3(0, 1, 0);
          const frame0 = new THREE.Quaternion().setFromAxisAngle(yAxis, 0);
          const frame1 = new THREE.Quaternion().setFromAxisAngle(yAxis, Math.PI);
          const frame2 = new THREE.Quaternion().setFromAxisAngle(yAxis, Math.PI*2);
          const track = new THREE.QuaternionKeyframeTrack('.quaternion', [
            0, this.inputs.period * 0.5, this.inputs.period
          ], [
            frame0.x, frame0.y, frame0.z, frame0.w,
            frame1.x, frame1.y, frame1.z, frame1.w,
            frame2.x, frame2.y, frame2.z, frame2.w
          ]);

          const clip = new THREE.AnimationClip(undefined, this.inputs.period, [track]);
          const action: AnimationAction = this.mixer.clipAction(clip, this.inputs.logo);
          action.play();

          const onEnterTime = this.inputs.transitionInDuration;
          const scaleTrack = new THREE.VectorKeyframeTrack('.scale', [0, onEnterTime],
            //[0, 0, 0, this.inputs.size.x, this.inputs.size.y, this.inputs.size.z], THREE.InterpolateSmooth);
          [0, 0, 0, this.inverseScale.x, this.inverseScale.y, this.inverseScale.z], THREE.InterpolateSmooth);
  
          //this.inputs.logo.scale.set(this.inverseScale.x, this.inverseScale.y, this.inverseScale.z);
          
          console.log(this.inputs.size.x);
          const onEnterClip = new THREE.AnimationClip(undefined, onEnterTime, [scaleTrack]);
          const onEnterAction: AnimationAction = this.mixer.clipAction(onEnterClip, this.inputs.logo);
          onEnterAction.loop = THREE.LoopOnce;
          onEnterAction.clampWhenFinished = true;
          onEnterAction.play();
        }
        break;
      case 'Error':
        console.warn(`[st] There was an error loading: ${(this.meshLoaderComponent.inputs as any).url}, resolution is hiding loader and node`);
        this.inputs.logo.parent?.remove(this.inputs.logo);
        break;
    }
  }

  onTick(delta: number) {
    if (this.mixer) {
      this.mixer.update(delta/1000);
    }
  }
}

export const loadingIndicatorType = 'mp.loadingIndicator';
export const makeLoadingIndicator = function() {
  return new LoadingIndicator();
}
