import {firestore} from '../../@crema/services/auth/firebase/firebase';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {
  ADD_SPACES_HIDE_MODAL,
  GET_LESSONS_LIST,
  SET_LESSON_DATA,
  SET_PRESENTATION_MODE,
  SET_TAG_GROUP_LIST,
} from '../../types/actions/Home.action';
import {ILesson, SpaceData, TagGroup} from '../../types/models/home/HomeApp';
import {AuthUser} from 'types/models/AuthUser';
import { makeid } from 'shared/helpers/helper';
// import {setCurrentLesson, setPresentationMode, setTagsViewModeSecondary} from './Home';
import { store } from 'App';
import { canEdit } from '@crema/utility/Utils';
import { history } from 'redux/store';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { defaultLayerNameSingular } from 'shared/constants/AppConst';
import { setCurrentTagGroupId } from './Step';
import { setTagsViewModeSecondary } from './Home';


export const onGetLessonsList = (spaceId: string): any => {
  // if (user == undefined || user.uid == undefined) return [];

  return (dispatch: Dispatch<AppActions>) => {
    let authUser = store.getState().auth.authUser;
    if (authUser != null) {
      dispatch(fetchStart());
      firestore
        .doc(`Spaces/${spaceId}`)
        .collection('lessons')
        .get()
        .then((querySnapshot) => {

          let newLessonList: any = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

          let sorted_lessons = newLessonList.sort(
            (a: any, b: any) => b?.createdOn - a?.createdOn,
          );
          // console.log(newLessonList, sorted_lessons, "newLessonList");
          dispatch(fetchSuccess());
          dispatch({ type: GET_LESSONS_LIST, payload: sorted_lessons });
          // dispatch({ type: SET_TAG_GROUP_ID, payload: '' });
        })
        .catch((error) => {
          console.log('Error getting documents: ', error);
        });
    } else {
      dispatch(fetchError('User is not logged in! Please try again'));
    }
  };
};


export const onGetLayer = (spaceId: string, did: string): any => {
  return (dispatch: Dispatch<AppActions>, getState: any) => {
    const { primaryUseCase }: SpaceData = getState()?.home || {};
    dispatch(fetchStart());

    firestore
      .doc(`Spaces/${spaceId}`)
      .collection('lessons')
      .where('did', '==', did)
      .get()
      .then((querySnapshot) => {
        const res: any = querySnapshot.docs.map((doc) => doc.data())[0];

        dispatch(fetchSuccess());
        if (res) {
          dispatch(setCurrentLesson(res));
          // dispatch(setTagsViewMode(ViewMode.LAYERS));
        } else {
          dispatch(fetchError(`${primaryUseCase?.layerTermSingular || defaultLayerNameSingular} not found`));
        }

      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  };
};

export const setCurrentLesson = (lesson: ILesson | null): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_LESSON_DATA, payload: lesson });
  };
};

export const onSaveLesson = ({
  name,
  authUser,
  spaceId,
}: {
  name: string;
  authUser: AuthUser | null;
  spaceId: string;
}) => {
  return (dispatch: Dispatch<AppActions | any>) => {
    dispatch(fetchStart());
    try {
      firestore
        .collection(`Spaces/${spaceId}/lessons`)
        .where('creatorId', '==', authUser?.uid)
        .where('name', '==', name)
        .get()
        .then(async (snap) => {
          if (snap.size == 0) {
            const newLessonRef = await firestore
              .doc(`Spaces/${spaceId}`)
              .collection('lessons')
              .doc();

            const {id: lessonDocId} = newLessonRef;
            let newLesson: ILesson = {
              createdAt: new Date(),
              name: name,
              id: lessonDocId,
              did: makeid(8),
              creatorId: authUser?.uid,
            };
            newLessonRef
              .set(newLesson)
              .then(() => {
                dispatch(fetchSuccess());
                let res: ILesson[] = [];
                firestore
                  .collection(`Spaces/${spaceId}/lessons`)
                  .where('creatorId', '==', authUser?.uid)
                  .get()
                  .then(async (querySnapshot) => {
                    res = querySnapshot.docs.map((doc) => {
                      return {
                        id: doc.id,
                        did: doc.data().did,
                        name: doc.data().name,
                        createdAt: new Date(
                          doc.data().createdAt.seconds * 1000,
                        ),
                        creatorId: doc.data().user?.uid,
                      };
                    });
                    await dispatch(fetchSuccess());
                    await dispatch({type: GET_LESSONS_LIST, payload: res});
                    dispatch({type: ADD_SPACES_HIDE_MODAL, payload: false});
                    // dispatch({type: SET_LESSON_DATA, payload: newLesson});
                    dispatch(setCurrentLesson(newLesson));
                    // dispatch(onGetScenesData(spaceId, lessonDocId, '', authUser));
                  })
                  .catch((error) => {
                    console.log('Error getting documents: ', error);
                  });
              })
              .catch((error) => {
                console.log(error);
                dispatch(fetchError(error.message));
              });
          } else {
            dispatch(fetchError('You already added this space!'));
          }
        });
    } catch (error: any) {
      dispatch(fetchError(error.message));
    }
  };
};

export function goToLesson(spaceDid: string, projectDid: string, lessonDid: string, openInPresentationMode: boolean, navigateToFunction: (path: string) => void ){
  navigateToFunction(`/home/space/${spaceDid}/project/${projectDid}/layers/${lessonDid}`);
  if(openInPresentationMode){
    store.dispatch(setPresentationMode(true));
  }
}

export function onExitPresentationMode() {

  if (canEdit()) {
    history.push({ pathname: history.location.pathname, search: "?mode=studio" })
  } else {
    store.dispatch(onStopLesson());
    goToLessonsList();
  }
  store.dispatch(setPresentationMode(false));
}

export function goToLessonsList(){
  store.dispatch(setCurrentLesson(null));
  history.push(`/home/space/${store.getState().home?.currentSpace?.did}/project/${store.getState().home?.currentSpace?.currentSpaceProject?.did}/layers`);
}

export const onStopLesson = (): any => {

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(setCurrentLesson(null));
    dispatch(setCurrentTagGroupId(''));
    dispatch(setTagsViewModeSecondary(''));
    dispatch({ type: SET_PRESENTATION_MODE, payload: false });
    Simulation.instance.resetAllNodesPositions();
  }
}

export const setPresentationMode = (value: boolean) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_PRESENTATION_MODE, payload: value });
  };
};
