import { CompressedPixelFormat, Object3D, Vector3, WebGLRenderer} from "three";
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import * as THREE from "three"

export class CraSubSystem {
    public renderer: WebGLRenderer;
    public three: typeof THREE;
    public effectComposer: EffectComposer;
    private _sdk:any;

    constructor() {

    }

    sdk():any {
        return this._sdk;
    }

    async initialize(_sdk:any) {
        this._sdk = _sdk;
        
        await this._sdk.Scene.configure((renderer:WebGLRenderer, three: typeof THREE, effectComposer: EffectComposer) => {

            this.renderer = renderer;
            this.three = three;
            this.effectComposer = effectComposer;
            
            // configure PBR
            renderer.physicallyCorrectLights = true;
          
            // configure shadow mapping
            renderer.shadowMap.enabled = true;
            //(renderer.shadowMap as any).bias = 0.0001;
            renderer.shadowMap.type = three.PCFSoftShadowMap;
          
            console.log("[CraSubSystem] configure callback")
            if (this.effectComposer) {
              console.log("[CraSubSystem] Effect Composer: ");
              console.log(this.effectComposer);
              //EulerIntegrator.instance!.context
              //this.renderPass = new RenderPass(this.context.scene, this.context.camera);
            }
          });
    }

    public getScreenResolution():THREE.Vector2 {
        var showcase = document.getElementById('sdk-iframe');
        var showcaseSize = {
            w: showcase!.clientWidth,
            h: showcase!.clientHeight,
        };

        return new THREE.Vector2(
            showcase!.clientWidth,
            showcase!.clientHeight
            );

        /*
        return new THREE.Vector2(
            this.renderer.context.canvas.width,
            this.renderer.context.canvas.height
            );
            */
    }
}