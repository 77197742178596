//import React, {useState, useEffect, useRef, useCallback} from 'react';
import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
//import Lessons, { IOnClickPlayLessons, IShowcaseSceneTag } from '../../Lessons';
import {
  Typography,
  Accordion,
  AccordionSummary,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
//import ModelsList from './ModelsList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Disable } from 'react-disable';

import modelMap from 'modules/home/models';
import TitlebarGridList from '@crema/core/TitlebarGridList';
import InsertedObjects from './InsertedObjects';
import PropertiesPanel from '../../ShowcaseOverlay/3DTools/PropertiesPanel';
import { PropertiesPanelMode, PropertiesPanelUIData } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { showMessage } from 'redux/actions';
import { canEdit, showExperimentAlert } from '@crema/utility/Utils';
import { useUserAdmin } from '@crema/utility/AppHooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addModelRoot: {
      paddingLeft: '10px',
      paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    userControl: {
      width: '100%',

      '& .MuiOutlinedInput-input': {
        paddingTop: 8,
        paddingBottom: 6,
        minHeight: 42,
      },
    },
    form: {
      width: '100%',
    },
    gridRoot: {
      paddingLeft: '10px',
      paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '16px',
    },
    grid: {
      padding: '10px',
      marginTop: '10px',
    },
    heading: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      lineHeight: '48px',
    },
    field: {
      width: '100%',
    },
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    buttonGroup: {
      alignContent: 'center',
      justifyContent: 'center',
      width: '80%',
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      color: '#fff',
      margin: theme.spacing(1),
      minWidth: 120,
    },
    topBorder: {
      borderTop: `solid 1px ${theme.palette.grey[200]}`,
    },
    rightBorder: {
      borderRight: `solid 1px ${theme.palette.grey[200]}`,
    },
    rightBorderTransparent: {
      borderRight: `solid 1px transparent`,
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    outlineBox: {
      border: '1px solid #0000001c',
      padding: "15px",
      paddingTop: "25px",
      width: '280px',
      borderRadius: '10px'
    },
    outlineBoxTitle: {
      // marginBottom: "-5px",
      backgroundColor: "white",
      color: "#5f5f5f",
      marginTop: "-45px",
      padding: "10px",
      width: "fit-content",
      textTransform: "uppercase",
      fontSize: "13px",
    }
  }),
);

interface Props {
  // mattertags: any;
  // mpSdk: any;
  // space: SpaceData;
  // modelObjectsSidebar: boolean;
  // addObject(objectName: string): void;
}

//class ObjectLibrarySideBar extends React.Component<Props, State> {

export default function ThreeDAnnotations({
  // addObject
}: Props) {
  const classes = useStyles();
  const isUserAdmin = useUserAdmin();
  const dispatch = useDispatch();
  // const [show3DLibrary, setShow3DLibrary] = React.useState(false);
  // const [propertiesPanelUIData, setPropertiesPanelUIData] = React.useState<PropertiesPanelUIData>(new PropertiesPanelUIData());

  //const colorReference = React.useRef(null);
  //const [propertiesColorValue, setPropertiesColorValue] = React.useState<string>();
  // useEffect(() => {
    //setActionTypesList(["ab", "zzz"]);
    //getData();
    //propertiesPanelReference.current.
  // }, []);

  // const addOb = (path: any) => addObject(path);
  // const libItems = libraryListData.map((x: any) => {return {imageName: x.name, onClick: () => addObject(x.path), imageSrc: ""}})
  // libraryListData.map(x => x['onClick'] =  () => addObject(x.path));
  // const libItems = [];

  // Simulation.instance.propertiesPanel.setPropertiesPanelUIData = setPropertiesPanelUIData;

  // const arr = Array.from(modelMap.values());
  // for (let i = 0; i < arr.length; i = i + 3) {
  //   libItems.push(arr.slice(i, i + 3));
  // }

  return (console.log(`[st] rendering Insert 3D`),
    <>
      <Disable disabled={!canEdit()}>

        <div id="annoContainer"
        // className={classes.gridRoot}
        >
          {
            // propertiesPanelUIData.propertiesPanelMode !== PropertiesPanelMode.None &&
            // <div className={classes.outlineBox}>
            //   <p className={classes.outlineBoxTitle}>Properties</p>
            //   <PropertiesPanel propertiesPanelUIData={propertiesPanelUIData} />
            // </div>
          }
          {/* <div style={{ height: "20px" }} />
          <Accordion >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >

              <Typography variant='h6'>Insert objects</Typography>
            </AccordionSummary>
            <div className={classes.outlineBox}>
              <p className={classes.outlineBoxTitle}>Insert</p> */}

              {modelMap.size > 0 && <TitlebarGridList tileData={
                Array.from(modelMap.values())
                // .filter(m => !m['access'] || (isUserAdmin && m['access']=="ADMIN"))
                .map(model => ({
                  id: model.payload.objects[0].name,
                  img: model.icon,
                  iconMui: model.iconMui,
                  title: model.payload.objects[0].name,
                  onClick: () => {

                    if(model.payload.objects[0].name == "Fire" || model.payload.objects[0].name == "Water Spray"){
                      showExperimentAlert("You found a beta feature! Play with it, add it your space. It doesn't work with Experiences/ Lessons just yet")
                    }

                    Simulation.instance.addObject(model.payload.objects[0].name);
                    dispatch(showMessage("Move cursor into the Space area to add!"));
                  },
                  onDelete: () => { }
                }))
              }
                hasOverflowMenu={false} />}
            </div>
          {/* </Accordion>
          <div style={{ height: "20px" }} />
          <div className={classes.outlineBox}>
            <p className={classes.outlineBoxTitle}>Inserted Objects</p>
            <ModelsList />
          </div>
        </div >*/}
      </Disable >
    </>
  );
}
