import { Tooltip, Box, Button, Checkbox, FormControl, FormControlLabel, InputBase, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { values } from "lodash";
import { uploadMediaFiles } from "modules/home/SpaceDetail/utils";
import React, { useEffect } from "react";
import ColorPicker from "../../../../../../@crema/core/ColorPicker";

const useStyles = makeStyles((theme) => ({

    bar: {
        position: 'absolute',
        right: "1%",
        top: "1%",
        width: '25%',
        // height: '30%',
        opacity: "0.9",
        backgroundColor: 'rgba(255, 255, 255)',
        borderRadius: "4px"
    },

    panelTitle: {
        textAlign: "center",
        paddingBottom: 5,
        opacity: "1",
        color: "black"
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        // width: 200,
        borderColor: 'green',
        borderWidth: 2,
        // backgroundColor: "grey",
        '& .MuiInputBase-root': {
            color: "black",
            opacity: "1",
            borderColor: 'green',
            borderWidth: 2,
        },
        '& .MuiInputBase-input': {
            color: "black",
            opacity: "1",
            lineHeight: 1.2,
						paddingTop: '12px',
            fontSize: '12px',
            backgroundColor: "#e0e0e0",
            borderRadius: "4px",
            paddingLeft: "5px",
            paddingRight: "5px",
            fontWeight: 500
        },
        '&  .MuiFormLabel-root': {
            color: "black"
        },
        '& .MuiOutlinedInput-input': {
            padding: '11px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: "translate(23px, -2px) scale(0.75)"
        }

    },
    fieldLabel: {
			  marginLeft: 9,
        fontWeight: 500,
        color: "black",
				width: '50px'
    },
    selectFormControl: {
        display: 'flex',
        margin: theme.spacing(1),
        minWidth: "90%",
        color: "black",
        '& .MuiFormLabel-root': {
            color: "black",

        }

    },
    selectBox: {
        display: 'flex',
        fontSize: '13px',
        fontWeight: 500,
        padding: "1px",
        color: "black",

    },
    selectMenuItem: {
        display: 'flex',
        fontSize: "13px",
        color: "black",
        fontWeight: 500,
    },
    checkbox: {
        padding: '0px 8px'
    }
}));

interface PanelInputFieldProps {
    props: any;
}


const PanelInputComp: React.FC<PanelInputFieldProps> = ({ props }) => {
    const classes = useStyles();
    return (console.log("rendering PanelInputField"), <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">

        <Typography variant="caption" className={classes.fieldLabel}> {props.label} </Typography>

        {props.type == "textInput" && <InputBase
            id={props.label}
            // label='Label'
            style={{ padding: 8 }}
            placeholder={props.placeholder}

            className={classes.textField}
            fullWidth

        />}



        {props.type == "color" &&

            <Box style={{ padding: 8 }}>
                <ColorPicker
                     //ref={(props as any).colorReference}
                    color = "#ff00ffff"
                    onColorChanged={() => { }}
                    visible={true}>
                </ColorPicker></Box>
        }
    </Box>)
}


export const PanelInputLabel: React.FC<PanelInputFieldProps> = ({ props }) => {
    const classes = useStyles();

    return <Typography variant="caption" className={classes.fieldLabel} > {props.label} </Typography>

}

export const PanelInputField: React.FC<PanelInputFieldProps> = ({ props }) => {
    const classes = useStyles();
    const [renderState, updateRenderState] = React.useState<boolean>(false);
    //const [checkState, setCheckState] = React.useState(false);

    function handleChange(e:any){
        if(props.onChange) {
            props.onChange(e.target.value)
        }
        props.text = e.target.value ;
        updateRenderState(!renderState);

    }

    function handleCheckBoxChange(e:any){
        if(props.onChange) {
            props.onChange(e.target.checked)
        }
        props.value = e.target.checked;
        updateRenderState(!renderState);

    }

    async function handleInputFileChange(e:any){
        let filesUrls: string[] = await uploadMediaFiles([...e.target.files]);
        if(props.onChange) {
            props.onChange(filesUrls[0]);
        }
        props.value = filesUrls[0];
        updateRenderState(!renderState);
    }

    return (
        <Tooltip title={props?.label}><div style={props.style ? props.style : null}>
        {props.type == "textInput" && <TextField
            id={props.label}
            // label='Label'
            style={{ padding: 8 }}
            placeholder={props.placeholder}

            className={classes.textField}
            fullWidth
            value={props.text}
            onChange={handleChange}
        />
        }

        {props.type == "readOnlyText" && <InputBase
            id={props.label}
            // label='Label'
            readOnly={true}
            className={classes.textField}
            fullWidth
            value={props.text}
            onFocus={props.onFocus}
        />
        }


        {props.type == "textInputMulti" && <InputBase
            id={props.label}
            // label='Label'
            style={{ padding: 8 }}
            placeholder={props.placeholder}

            className={classes.textField}
            fullWidth
            multiline={true}
            rowsMin={4}
            rows={4}
            value={props.text}
            onChange={handleChange}
        />
        }

        {props.type == "color" &&

            <Box style={{ padding: 8 }}>
                <ColorPicker
                    // ref={colorReference}
                    ref={(props as any).colorReference}
                    color={props.color}

                    onColorChanged={(props as any).onColorChanged}
                    visible={(props as any).showColorProperty} >
                </ColorPicker></Box>
        }

        {props.type == "select" && props.values && props.values.length > 0 &&
            <FormControl
                className={classes.selectFormControl}
            >
                {/* <InputLabel htmlFor={props.label}>{props.label}</InputLabel> */}
                <Select

                    className={classes.selectBox}
                    value={props.value}
                    onChange={props.handleChange}
                    inputProps={{
                        name: props.label,
                        id: props.label,
                    }}>
                    {props.values.map((val: any) => <MenuItem key={val} className={classes.selectMenuItem} value={val}>{val}</MenuItem>)}

                </Select>
            </FormControl>
        }

        {
            props.type == "checkbox" && ("value" in props) &&
            <div className={classes.checkbox} ><FormControlLabel
                control={<Checkbox checked={props.value} onChange={handleCheckBoxChange} />}
                label={props.label}
            /></div>
        }

        {props.type == "recorder" && props.recordedValue

        }

        {props.type == "fileInput" &&
            <Button
              variant="contained"
              component="label"
              size="small"
              color="primary"
              style={{height: "2em", marginLeft: 8}}
            >
              Upload
              <input
                type="file"
                hidden
                onChange={handleInputFileChange}
              />
            </Button>
        }

        </div></Tooltip>
    )

}

export default PanelInputField;