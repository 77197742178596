import { VariableType } from 'modules/home/SpaceDetail/SpaceView/Sidebar/Variables/VariableTypes';
import * as THREE from 'three';
import { SET_VARIABLE_VALUE } from 'types/actions/Home.action';
import GrowShrinkAnimation from '../../animation/GrowShrinkAnimation';
import MoveAnimation from '../../animation/MoveAnimation';
import RotateAnimation from '../../animation/RotateAnimation';
import ScaleAnimation from '../../animation/ScaleAnimation';
import { TemporalOutlineElement } from '../../components/PostProcess/OutlineComponent';
import { RotateToggle } from '../../components/tiny/RotateToggle';
import Utils from '../../Tools/Utils';
import {
    ComponentInteractionType,
    ISceneNode,
    PointerButtonMask,
    SceneComponent,
} from '../sceneManagement/SceneComponent';
import { UnserializedUserData, UserDataProperties, UserDataTypes } from '../ui-interop/PropertiesPanel';
import {
    ActionType,
    EventActionOutcome,
    TriggerActionOutcome,
    VariableValueActionPair,
} from '../ui-interop/PropertiesPanelBehaviorActions';
import Simulation from './Simulation';
import { store } from "App";
import _ from 'lodash';


interface IInputDragBeginEvent {
    buttons: PointerButtonMask;
    eventType: string;
    position: THREE.Vector2;
}

export interface IInteractionDragBeginEvent {
    collider: THREE.Object3D;
    point: THREE.Vector3;
    normal: THREE.Vector3;
    input: IInputDragBeginEvent;
}

interface IInputDragEndEvent {
    buttons: PointerButtonMask;
    delta: THREE.Vector2;
    eventType: string;
    fullDelta: THREE.Vector2;
    position: THREE.Vector2;
    timeSinceLastMove: number;
}

export interface ITransform {
    mesh: THREE.Object3D;
    position: THREE.Vector3;
    rotation: THREE.Euler;
    scale: THREE.Vector3;
}

export interface IInteractionDragEndEvent {
    collider: THREE.Object3D;
    input: IInputDragEndEvent;
    point: THREE.Vector3;
    normal: THREE.Vector3;
}

interface IInputDraggingEvent {
    buttons: PointerButtonMask;
    clientPosition: THREE.Vector2;
    delta: THREE.Vector2;
    eventType: string;
    position: THREE.Vector2;
}

export interface IInteractionDraggingEvent {
    collider: THREE.Object3D;
    input: IInputDraggingEvent;
    point: THREE.Vector3;
    normal: THREE.Vector3;
}

export interface IInteractionHoverEvent {
    collider: THREE.Object3D;
    point: THREE.Vector3;
    normal: THREE.Vector3;
    hover: boolean;
}

export class Behaviors {
    private draggingNode: ISceneNode | null = null;
    private intersectionPoint: THREE.Vector3;
    private dragStartNodePosition: THREE.Vector3;
    private dragStartOffset: THREE.Vector3;
    private directionFromCameraToNode: THREE.Vector3;
    private magnitudeToNode: number;

    private updateDrag(node: ISceneNode, intersection: THREE.Vector3): void {
        this.dragStartNodePosition = node.position.clone();
        this.intersectionPoint = intersection.clone();
        this.dragStartOffset = this.dragStartNodePosition.clone().sub(this.intersectionPoint);
        this.directionFromCameraToNode = this.dragStartNodePosition.clone().sub(Simulation.instance.camera.Position);
        this.magnitudeToNode = this.directionFromCameraToNode.length();
        this.directionFromCameraToNode.multiplyScalar(1.0 / this.magnitudeToNode);
    }

    processAllNodeDragBeginEvents(node: ISceneNode, e: IInteractionDragBeginEvent): void {
        if (node.userData[UserDataProperties.ClickEventActionList]) {
            (node.userData[UserDataProperties.ClickEventActionList] as EventActionOutcome[]).forEach(element => {
                let targetNode: ISceneNode | null = Simulation.instance.scene.findNodeByID(element.objectID);
                switch (element.actionType) {
                    /*
                    case ActionType.Drag:
                        console.log("Drag begin");
                        this.draggingNode = node;
                        this.dragStartNodePosition = node.position.clone();
                        this.intersectionPoint = e.point.clone();
                        this.dragStartOffset = this.dragStartNodePosition.clone().sub(this.intersectionPoint);
                        this.directionFromCameraToNode = this.dragStartNodePosition.clone().sub(Simulation.instance.camera.Position);
                        this.magnitudeToNode = this.directionFromCameraToNode.length();
                        this.directionFromCameraToNode.multiplyScalar(1.0 / this.magnitudeToNode);
                        //console.log(e);
                        //console.log(e.input.position)
                        break;*/
                }
            });
        }
    }

    processAllNodeDragEndEvents(node: ISceneNode, e: IInteractionDragEndEvent): void {
        if (node.userData[UserDataProperties.ClickEventActionList]) {
            (node.userData[UserDataProperties.ClickEventActionList] as EventActionOutcome[]).forEach(element => {
                let targetNode: ISceneNode | null = Simulation.instance.scene.findNodeByID(element.objectID);
                switch (element.actionType) {
                    /*
                    case ActionType.Drag:
                        console.log("Drag End");
                        this.draggingNode = null;

                        break;*/
                }
            });
        }
    }

    processAllNodeDragEvents(node: ISceneNode, e: IInteractionDraggingEvent): void {
        if (node.userData[UserDataProperties.ClickEventActionList]) {
            (node.userData[UserDataProperties.ClickEventActionList] as EventActionOutcome[]).forEach(element => {
                let targetNode: ISceneNode | null = Simulation.instance.scene.findNodeByID(element.objectID);
                switch (element.actionType) {
                    /*
                    case ActionType.Drag:
                        console.log("Dragging");
                        //console.log(e)
                        let view = new THREE.Vector3(0, 0, -1);
                        Simulation.instance.camera.Camera.getWorldDirection(view);

                        let mousePos = new THREE.Vector3(e.input.position.x, e.input.position.y, 0).unproject(Simulation.instance.camera.Camera);
                        //mousePos.normalize();
                        //mousePos.add(Simulation.instance.camera.Camera.position);
                        //mousePos.multiplyScalar(1.1);

                        view.normalize();
                        //console.log(this.magnitudeToNode)
                        view.multiplyScalar(this.magnitudeToNode);
                        console.log(mousePos);

                        node.position.x = mousePos.x;
                        node.position.y = mousePos.y;
                        node.position.z = mousePos.z;


                        let newPos = this.dragStartNodePosition.clone();
                        //newPos.sub(this.dragStartOffset);
                        newPos.add(view);
                        node.position.x = newPos.x;
                        node.position.y = newPos.y;
                        node.position.z = newPos.z;

                        this.dragStartNodePosition = node.position.clone();
                        this.directionFromCameraToNode = this.dragStartNodePosition.clone().sub(Simulation.instance.camera.Position);
                        //Utils.SetMeshesVisibility(meshes!, false);
                        break;*/
                }
            });
        }
    }

    // public static highlightModel (objectID: string, meshes: THREE.Object3D[] | null) {
    //     Simulation.instance.outlineComponent.temporalOutlines.set(objectID, new TemporalOutlineElement(3000, meshes!))
    // }


    public static runActionsOnNode(targetNode: ISceneNode, actionOutcome: EventActionOutcome | TriggerActionOutcome) {
        if (targetNode) {
            let meshes = Utils.FindAllMeshesAndLineSegments(targetNode);
            switch (actionOutcome.actionType) {
                case ActionType.Hide:
                    if (!targetNode.userData[UserDataProperties.alwaysShow]) {
                        Utils.DisableCollidersOnNode(targetNode);
                        Utils.SetVisibility(false, targetNode, meshes);
                        Simulation.instance.scene.hideTransformGizmo(targetNode);
                    }
                    break;
                case ActionType.Show:
                    Utils.EnableCollidersOnNode(targetNode);
                    Utils.SetVisibility(true, targetNode, meshes);
                    break;
                case ActionType.Highlight:
                    Simulation.instance.highlightModel(targetNode.userData[UserDataProperties.id], meshes);

                    //Simulation.instance.integrator.PlayGrowShrinkAnimation(element.objectID, meshes!);
                    break;
                case ActionType.GrowShrinkAnimate:
                    GrowShrinkAnimation.instance.PlayAnimation(targetNode, meshes!);
                    break;

                case ActionType.CallActivate:
                    if (targetNode.userData[UserDataProperties.type] === UserDataTypes.thermostatNest) {
                        var iot = Utils.GetNestThermostatComponent(targetNode);
                        iot?.activate();
                    }
                    break;

                case ActionType.Move_Parameterized: {
                    if (UnserializedUserData.StartPosition in targetNode.unserializedUserData) {

                    } else {
                        targetNode.unserializedUserData[UnserializedUserData.StartPosition] = [];
                        for (const selectMesh of meshes!) {
                            let data: ITransform = {
                                'mesh': selectMesh,
                                'position': selectMesh.position,
                                'rotation': selectMesh.rotation,
                                'scale': selectMesh.scale,
                            };
                            targetNode.unserializedUserData[UnserializedUserData.StartPosition].push(_.cloneDeep(data),
                            );
                        }
                    }

                    let vec3AndDuration = Utils.getVector3AndDuration(actionOutcome.parameter);
                    MoveAnimation.instance.PlayAnimation(targetNode, meshes!, {
                        deltaPosition: vec3AndDuration[0],
                        duration: vec3AndDuration[1],
                    });
                }
                    break;

                case ActionType.Rotate_Parameterized: {
                    if (UnserializedUserData.StartPosition in targetNode.unserializedUserData) {

                    } else {
                        targetNode.unserializedUserData[UnserializedUserData.StartPosition] = [];
                        for (const selectMesh of meshes!) {
                            let data: ITransform = {
                                'mesh': selectMesh,
                                'position': selectMesh.position,
                                'rotation': selectMesh.rotation,
                                'scale': selectMesh.scale,
                            };
                            targetNode.unserializedUserData[UnserializedUserData.StartPosition].push(_.cloneDeep(data),
                            );
                        }
                    }

                    let vec3AndDuration = Utils.getVector3AndDuration(actionOutcome.parameter);
                    RotateAnimation.instance.PlayAnimation(targetNode, meshes!, {
                        deltaRotation: vec3AndDuration[0],
                        duration: vec3AndDuration[1],
                    });
                }
                    break;
                case ActionType.Scale_Parameterized: {
                    if (UnserializedUserData.StartPosition in targetNode.unserializedUserData) {

                    } else {
                        targetNode.unserializedUserData[UnserializedUserData.StartPosition] = [];
                        for (const selectMesh of meshes!) {
                            let data: ITransform = {
                                'mesh': selectMesh,
                                'position': selectMesh.position,
                                'rotation': selectMesh.rotation,
                                'scale': selectMesh.scale,
                            };
                            targetNode.unserializedUserData[UnserializedUserData.StartPosition].push(_.cloneDeep(data),
                            );
                        }
                    }

                    let vec3AndDuration = Utils.getVector3AndDuration(actionOutcome.parameter, 1);
                    ScaleAnimation.instance.PlayAnimation(targetNode, meshes!, {
                        deltaScale: vec3AndDuration[0],
                        duration: vec3AndDuration[1],
                    });
                }
                    break;
            }
        }
    }

    public static runUnActionsOnNode(targetNode: ISceneNode, actionOutcome: EventActionOutcome | TriggerActionOutcome) {
        if (targetNode) {
            //let meshes = Utils.FindAllMeshesAndLineSegments(targetNode);
            switch (actionOutcome.actionType) {
                case ActionType.CallActivate:
                    if (targetNode.userData[UserDataProperties.type] === UserDataTypes.thermostatNest) {
                        var iot = Utils.GetNestThermostatComponent(targetNode);
                        iot?.Deactivate();
                    }
                    break;
            }
        }
    }

    processAllNodeClickEvents(node: ISceneNode): void {
        // alert()
        if (node.userData[UserDataProperties.ClickEventActionList]) {
            (node.userData[UserDataProperties.ClickEventActionList] as EventActionOutcome[]).forEach(element => {
                let targetNode: ISceneNode | null = Simulation.instance.scene.findNodeByID(element.objectID);
                if (targetNode) {
                    Behaviors.runActionsOnNode(targetNode, element);
                }
            });
        }

        if (node.userData[UserDataProperties.varActions]) {
            switch (node.userData[UserDataProperties.type]) {
                case UserDataTypes.leverToggle:
                    (node.userData[UserDataProperties.varActions] as VariableValueActionPair[]).forEach(element => {

                        let varSearch = Simulation.instance.Variables?.find(v => v.name === element.name);

                        if (varSearch) {
                            let rotateToggle = (node as any).components[1].instance as RotateToggle;

                            if (varSearch.type === VariableType.booleanVariableType || varSearch.type === VariableType.csvVariableType) {
                                let allowedValuesArray = (varSearch.values as string).split(',').map(x => x.trim());

                                let newValue = '';
                                newValue = allowedValuesArray[Math.min(rotateToggle.inputs.state, allowedValuesArray.length - 1)];
                                store.dispatch({
                                    type: SET_VARIABLE_VALUE,
                                    payload: { name: element.name, value: newValue },
                                });
                            } else {
                                store.dispatch({ type: SET_VARIABLE_VALUE, payload: element });
                            }
                        }
                    });
                    break;
                default:
                    (node.userData[UserDataProperties.varActions] as VariableValueActionPair[]).forEach(element => {
                        store.dispatch({ type: SET_VARIABLE_VALUE, payload: element });
                    });
                    break;
            }
        }


    }

    private static _instance: Behaviors | null = null;

    constructor() {

    }

    public static get instance(): Behaviors {
        if (!Behaviors._instance) {
            Behaviors._instance = new Behaviors();
        }

        return Behaviors._instance;
    }
}
