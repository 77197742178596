import React, { useMemo, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';
import { setHistoryHook as setHistoryHook } from './TagSidebar/Tags/ShowcaseUtils';
import SidebarTabs from './SidebarTabs';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';

export enum ViewMode {
  TAGS = 'tags',
  MODELS = 'models',
  STATUS = 'status',
  LAYERS = 'layers',
  VIEWER = 'viewer',
  VARIABLES = 'variables',
  PEOPLE = 'people',
  CATALOG = 'catalog',
  TABLE = 'table',
  LAYERS_TAGS = 'layers_tags',
  LAYERS_SIM = 'layers_sim'
}

export enum ViewModeSecondary {
  LAYERS = 'layers',
  LAYERS_TAGS = 'layers_tags',
  LAYERS_3D = 'layers_3D',
  LAYERS_PROP = 'layers_prop',
  // TAGS = 'tags',
  // MODELS = 'models',
}

export enum TagType {
  ALL = 'All',
  INFO = 'Info',
  QUIZ = 'Quiz',
  TASK = 'Task',
  MEDIA = 'Media',
  VIDEO = 'Video',
  IMAGES = 'Images',
  OTHER = 'Other',
  AUDIO = 'Audio',
  FILES = 'Files',
}
const useStyles = makeStyles((theme: Theme) => createStyles({}));
interface Props {
}

export default function Sidebar({
}: Props) {
  const classes = useStyles();

  const history = useHistory();
  const [isMinimized, setMinimized] = useState<boolean>(false);
  const currentSpaceId = useSelector<AppState, string>(({ home }) => home.currentSpace?.id || '');

  setHistoryHook(history);

  return (console.log(`[st] rendering Sidebar`),
    useMemo(() => <SidebarTabs
    isMinimized={isMinimized}
    setMinimized={setMinimized}
    />, [currentSpaceId])
  );


}


