import React, { useState, useEffect, useContext, useCallback } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { Box, makeStyles, IconButton } from '@material-ui/core';
import TableTag from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { Fonts } from 'shared/constants/AppEnums';
import { AppState } from 'redux/store';
import { CremaTheme } from 'types/AppContextPropsType';
import { ShowcaseTag } from 'types/models/home/HomeApp';
import DeleteIcon from '@material-ui/icons/Delete';
import TelegramIcon from '@material-ui/icons/Telegram';
import EditIcon from '@material-ui/icons/Edit';
import AddLinkIcon from '@mui/icons-material/AddLink';
import InsertLink from '@mui/icons-material/InsertLink';
import { ShowcaseContext } from '../../..';
import { showMessage, fetchError } from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { SET_IS_ADDING_TAG } from 'types/actions/Home.action';
import { handleTagClick } from 'modules/home/SpaceDetail/utils';

const useStyles = makeStyles((theme: CremaTheme) => ({
  borderBottomClass: {
    borderBottom: '0 none',
  },
  tableCell: {
    borderBottom: '0 none',
    fontSize: 13,
    padding: 8,
  },
}));

interface TableItemProps {
  tag: any;
  mpSdk: any;
  linkTag(newTag: any): void;
}

const TableItem: React.FC<TableItemProps> = ({ tag, mpSdk, linkTag }) => {
  const spaceTags = useSelector<AppState, Map<string, ShowcaseTag>>(({ home }) => home.spaceTags);
  const [isAdding, setIsAdding] = useState<Boolean>(false);
  const [tagId, setTagId] = useState<string | undefined>(undefined);
  const classes = useStyles();
  const showcaseContext = useContext(ShowcaseContext);
  const dispatch = useDispatch();
  const recordFields: string[] = Object.values(tag);

  const handleAddTag = useCallback(async () => {
    dispatch(showMessage("Move cursor into the Space area to add a tag!"));
    // showcaseContext.handleAddTag();

    dispatch({ type: SET_IS_ADDING_TAG, payload: true });
    setIsAdding(true);
  }, []);

  useEffect(() => {
    if (isAdding) {
      const newAddedTag = spaceTags.keys().next().value;
      setTagId(newAddedTag);
      setIsAdding(false);
      linkTag({ ...tag, tagId: newAddedTag });
    }
  }, [spaceTags]);

  return (console.log("rendering TableItem"),

    <TableTag
      key={tag.id}
      className={clsx(classes.borderBottomClass, 'item-hover')}>
      <TableCell component='th' scope='tag' className={classes.tableCell}>
        <IconButton aria-label="Link Tag" color="secondary" onClick={handleAddTag} >
          {tagId != undefined && (
            <InsertLink />
          )}
          {tagId == undefined && (
            <AddLinkIcon />
          )}
        </IconButton>
      </TableCell>
      <TableCell align='left' className={classes.tableCell}>
        <IconButton aria-label="Go To Tag" color="secondary" onClick={() => {
          if (tagId) {
            handleTagClick(tagId);
          }
          else {
            dispatch(fetchError("No tag was linked to this row"));
          }
        }} >
          <TelegramIcon />
        </IconButton>
      </TableCell>
      {/* <TableCell align='left' className={classes.tableCell}>
        <Box component='span' ml={{ xs: 3, xl: 5 }} fontWeight={Fonts.BOLD}>
          {tag.label}
        </Box>
      </TableCell> */}
      {recordFields.length > 0 && recordFields.map((field) => (
        <TableCell align='left' className={classes.tableCell}>
          <Box component='span' ml={{ xs: 3, xl: 5 }} fontWeight={Fonts.BOLD}>
            {JSON.stringify(field)}
          </Box>
        </TableCell>
      ))}
    </TableTag>
  );
};

export default TableItem;
