import { firestore } from "@crema/services/auth/firebase/firebase";
import { getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { Dispatch } from "react";
import {store} from "App";
import { AppActions } from "types";
import { SET_TAG_GROUP_LIST, GET_SPACES_LIST, SET_TAG_GROUP_ID } from "types/actions/Home.action";
import { AuthUser } from "types/models/AuthUser";
import { Logic, LogicEngine } from "types/models/dataAccess/Logic";
import { TagGroup, ACTIONS, OBJECT_TYPES } from "types/models/home/HomeApp";
import { fetchStart, fetchSuccess, fetchError } from "./Common";
import { logUserActivity } from "./Home";

export const onGetTagGroupsList = (
    authUser: AuthUser | null,
    currentSpace: any,
    currentLesson: any,
): any => {
    return (dispatch: Dispatch<AppActions>) => {
        if (authUser != null) {
            dispatch(fetchStart());
            firestore
                .collection(
                    `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups`,
                )
                .get()
                .then((tagGroupDocs: any) => {

                    let tgs: TagGroup[] = tagGroupDocs.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
                    // tagGroupDocs.docs.map((tagGroupDoc: any) =>
                    //   tgs.push({
                    //     id: tagGroupDoc.id,
                    //     name: tagGroupDoc.data().name,
                    //     tagIds: tagGroupDoc.data().tagIds,
                    //     sortIndex: tagGroupDoc.data().sortIndex,
                    //   }),
                    // );

                    tgs.sort((a: TagGroup, b: TagGroup) => a.sortIndex - b.sortIndex);
                    dispatch(fetchSuccess());
                    dispatch({ type: SET_TAG_GROUP_LIST, payload: tgs });
                    if (tgs && tgs.length > 0 && !store.getState().home.currentTagGroupId) {
                        // if(){
                        dispatch(setCurrentTagGroupId(tgs[0].id));
                        // }
                    }
                })
                .catch((error) => {
                    console.log('Error getting tag groups: ', error);
                });
        } else {
            // dispatch(fetchError('User is not logged in! Please try again'));
            dispatch({ type: GET_SPACES_LIST, payload: [] });
        }

    };
};


export const updateStepLogic = (logic: Logic): any => {

    return (dispatch: Dispatch<AppActions>) => {

        let spaceId = store.getState().home?.currentSpace?.id;
        let lessonId = store.getState().home?.currentLesson?.id;
        let currentTagGroupId = store.getState().home?.currentTagGroupId;
        let tagGroupsList = store.getState().home.tagGroupsList;

        let currentTagGroup = tagGroupsList.find(tg => tg.id == currentTagGroupId);

        if (currentTagGroup) {
            currentTagGroup.logic = logic;
            console.log(`[st] saving step logic ${JSON.stringify(logic)}`);

            spaceId && lessonId && currentTagGroup && dispatch(onUpdateTagGroup(currentTagGroup
                // spaceId, lessonId, currentTagGroup, tagGroupsList
            ));
        }
    }

}


export const handleGoToTagGroupByIndex = (sortIndex: number) => {

    return (dispatch: Dispatch<AppActions>) => {
        let tagGroup: TagGroup | undefined;
        let nextTagGroup: TagGroup | undefined;

        tagGroup = getCurrentTagGroup();

        if (sortIndex == store.getState().home.tagGroupsList.length) {
            // do nothing
            ;
        } else {
            nextTagGroup = store.getState().home.tagGroupsList.find(tg => tg.sortIndex == sortIndex);
            console.log(`[st] going to step: ${nextTagGroup?.name} - ${nextTagGroup?.bannerText}`);

            dispatch(setCurrentTagGroupId(nextTagGroup?.id || ''));
            dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tagGroup?.id));
        }

    }
};

export const handleNextTagGroup = () => {

    return (dispatch: Dispatch<AppActions>) => {
        let tagGroup: TagGroup | undefined;
        let nextTagGroup: TagGroup | undefined;

        tagGroup = getCurrentTagGroup();
        if (store.getState().home.presentationMode && tagGroup?.variableNameForInput) {
            let v = store.getState().home.variableValues.find(v => v.name == tagGroup?.variableNameForInput);
            if (v && !v.value) {
                dispatch(fetchError(`You need to select a value for ${v.name}  to continue!`));
                return;

            }

        }
        let nextTagGroupIndex = tagGroup ? tagGroup.sortIndex + 1 : 0;
        if (nextTagGroupIndex == store.getState().home.tagGroupsList.length) {
            // do nothing
            ;
        } else {
            nextTagGroup = store.getState().home.tagGroupsList.find(tg => tg.sortIndex == nextTagGroupIndex);
            console.log(`[st] going to next step ${nextTagGroup?.name} - ${nextTagGroup?.bannerText}`);

            dispatch(setCurrentTagGroupId(nextTagGroup?.id || ''));
            dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tagGroup?.id));
        }

    }
};

export const handleBackTagGroup = () => {
    console.log(`[st] handling prev`);
    return (dispatch: Dispatch<AppActions>) => {
        let tagGroup: TagGroup | undefined;
        let prevTagGroup: TagGroup | undefined;
        // if (currentTagGroupId) {
        tagGroup = store.getState().home.tagGroupsList.find((tagGroup: TagGroup) => tagGroup.id === store.getState().home.currentTagGroupId);
        let tagGroupIndex = tagGroup ? tagGroup.sortIndex - 1 : 0
        prevTagGroup = store.getState().home.tagGroupsList?.[tagGroupIndex] || {};
        console.log(`[st] going back to step ${prevTagGroup.name} - ${prevTagGroup.bannerText}`);
        dispatch(setCurrentTagGroupId(prevTagGroup?.id || ''));
        dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tagGroup?.id));
        // }
    }
};


export const setTagGroupsList = (tagGroupsList: TagGroup[]): any => {
    return (dispatch: Dispatch<AppActions>) => {
      dispatch({ type: SET_TAG_GROUP_LIST, payload: tagGroupsList });
    };
  };

export const onUpdateTagGroup = (tagGroup: TagGroup
    // spaceId: string, lessonId: string, tagGroup: TagGroup, tagGroupsList: TagGroup[]
  ): any => {
    return (dispatch: Dispatch<AppActions>) => {
      let { currentSpace, currentLesson, currentTagGroupId, tagGroupsList } = store.getState().home;

      // let tg = tagGroupsList?.find(tagGroup => tagGroup.id == currentTagGroupId);

      if (!currentSpace || !currentLesson || !tagGroup) {
        return;
      }

      dispatch(fetchStart());

      firestore
        .doc(
          `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups/${tagGroup.id}`,
        )
        .set(tagGroup).then(() => {
          dispatch(fetchSuccess());
          dispatch({ type: SET_TAG_GROUP_LIST, payload: tagGroupsList });
        })
        .catch((error) => {
          dispatch(fetchError("Hmm.. That didn't work. Can you try again?"));
          console.error('couldnt update tagGroup: ', error);
        });
    };
  };

  export const setCurrentTagGroupId = (tagGroupId: string): any => {
    return (dispatch: Dispatch<AppActions>) => {

      dispatch({ type: SET_TAG_GROUP_ID, payload: tagGroupId });
      let tg = getCurrentTagGroup();
      let logic = tg?.logic;
      console.log(`[st] executing logic for step ${JSON.stringify(tg?.name) }`);

      tg?.id && logic && LogicEngine.processStepLogicConditions(tg.id, logic);

    };
  };
