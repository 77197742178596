// import THREE, { FontLoader } from "three";
import * as THREE from 'three';
import {ARButton} from './jsm/webxr/ARButton.js';
import {TagsARInteraction} from './TagsARInteraction';
import {MimickScene} from './Mimick/MimickScene';
import {TagSystem} from './Systems/TagSystem';
import {RenderingSystem} from './Systems/RenderingSystem';
import {WindowingSystem} from './Systems/WindowingSystem';
import {CameraSystem} from './Systems/CameraSystem';
import {InputSystem} from './Systems/InputSystem';
import {RaycastSystem} from './Systems/RaycastSystem';
import Simulation from '../mp/core/craEngine/SubSystems/core/Simulation';
import {MimickPointer} from './Mimick/MimickPointer';
import {MimickCamera} from './Mimick/MimickCamera';
import {LightSystem} from './Systems/LightSystem';

export enum SpatialThinkSDKModes {
    Invalid,
    Unknown,
    Regular,
    AugmentedReality
}

export interface SpatialSDKType {
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    effectComposer: null;
    camera: THREE.Camera;
    Scene: MimickScene;
    Pointer: MimickPointer;
    Camera: MimickCamera;
    customSDK:boolean;
}

export class SpatialThinkSDK {
    protected renderingSystem:RenderingSystem;
    protected windowingSystem:WindowingSystem;
    protected cameraSystem:CameraSystem;
    protected raycastSystem:RaycastSystem;
    protected lightingSystem:LightSystem;

    protected mode: SpatialThinkSDKModes;

    //Misc
    protected font: THREE.Font | null = null;
    protected boxObject: THREE.Object3D | null = null;
    protected arInteraction:TagsARInteraction | null = null;

    protected mimickScene:MimickScene;
    protected mimickPointer:MimickPointer;
    protected mimickCamera:MimickCamera;

    protected tagSystem:TagSystem;



    private constructor() {
        this.windowingSystem = new WindowingSystem();
        this.cameraSystem = new CameraSystem(this.windowingSystem);
        this.renderingSystem = new RenderingSystem(this.cameraSystem);
        this.raycastSystem = new RaycastSystem(this.renderingSystem);
        this.lightingSystem = new LightSystem(this.renderingSystem);
        //document.body.appendChild(this.container);

        const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 1);
        light.position.set(0.5, 1, 0.25);
        this.renderingSystem.scene.add(light);

        const geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
        this.boxObject = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({color: Math.random() * 0xffffff}));
        this.boxObject.position.set(0, 0, -0.5);
        // this.renderingSystem.scene.add(this.boxObject);

        this.tagSystem = new TagSystem(this.renderingSystem);

         this.renderingSystem.startRenderer();
         this.renderingSystem.registerRenderCallback(this.render.bind(this))

        this.mimickScene = new MimickScene(this.renderingSystem);
        this.mimickPointer = new MimickPointer();
        this.mimickCamera = new MimickCamera();

    }

    public SetMode(mode: SpatialThinkSDKModes): void {
        this.mode = mode;

        this.arInteraction && this.arInteraction.dispose();


        switch (mode) {
            case SpatialThinkSDKModes.AugmentedReality:
                this.arInteraction = new TagsARInteraction(this.renderingSystem.base, this.renderingSystem);
                document.getElementById('app-sidebar')?.remove();
                document.getElementById('shortcut-panel')?.remove();
                document.getElementById('quick-access-toolbar')?.remove();
                document.body.appendChild(ARButton.createButton(this.renderingSystem.renderer, {requiredFeatures: ['hit-test']}));

                break;
            case SpatialThinkSDKModes.Regular:
                this.renderingSystem.disableXR();
                // Simulation.instance.initialize()
                break;
        }
    }

    protected render(timestamp: any, frame: any) {
        switch (this.mode) {
            case SpatialThinkSDKModes.Regular:
                break;
            case SpatialThinkSDKModes.AugmentedReality:

                this.arInteraction!.xrRender(frame);
                break;
        }
        // console.log(timestamp);
        // console.log(frame);
        // if (this.boxObject) {
        //     this.boxObject?.rotateY(0.01);
        // }
        this.boxObject?.rotateY(0.01);

    }

    public getSDK(): SpatialSDKType {
        return {
            scene: this.renderingSystem.scene,
            renderer: this.renderingSystem.renderer,
            effectComposer: null,
            camera: this.cameraSystem.camera,
            Scene: this.mimickScene,
            Pointer: this.mimickPointer,
            Camera: this.mimickCamera,
            customSDK: true
        };
    }



    private static _instance: SpatialThinkSDK | null = null;
    public static get instance(): SpatialThinkSDK {
        if (!SpatialThinkSDK._instance) {
            SpatialThinkSDK._instance = new SpatialThinkSDK();
        }

        return SpatialThinkSDK._instance;
    }
}
