import React, {useEffect, useState} from 'react';
import {onAddNewSection, onEditTaskDetails, onDeleteSelectedTask} from '../../../../redux/actions';
import AddNewList from './AddNewList';
import {DragDropContext} from 'react-beautiful-dnd';
import Box from '@material-ui/core/Box';
import AddTaskCard from './List/AddCard';
import List from './List';
import {Scrollbar} from '../../../../@crema';
import AppsContent from '../../../../@crema/core/AppsContainer/AppsContent';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { ANNOTATION_TYPE } from "../../../../types/models/home/HomeApp";
import {makeStyles} from '@material-ui/core';
import {TransitionProps} from '@material-ui/core/transitions';
import {
  ProjectObj,
  TaskSectionObj,
  TaskObj,
} from '../../../../types/models/apps/ProjectBoard';
import {onAddNewTask} from 'redux/actions';
import { getUniqueColor } from 'shared/constants/AppConst';
import {useParams} from 'react-router-dom';
import { ProjectParamsProps } from '..';

const useStyles = makeStyles(() => ({
  dialogBox: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 900,
      width: '100%',
    },
  },
  scrollRoot: {
    height: 595,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface ProjectDetailViewProps {
  projectDetail: ProjectObj;
}

interface Item {
  id: string;
  content: string;
}

// a little function to help us with reordering the result
const reorder = (
  list: Item[],
  startIndex: number,
  endIndex: number
): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ProjectDetailView: React.FC<ProjectDetailViewProps> = ({
  projectDetail
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams<ProjectParamsProps>();

  const [list, setList] = useState<TaskSectionObj | null>(null);

  const [isAddTaskOpen, setAddTaskOpen] = useState<boolean>(false);

  const [selectedTask, setSelectedTask] = useState<TaskObj | null>(null);

  useEffect(()=>{
    const taskId:string = params?.taskId;
    const sectionId:number = parseInt(params?.sectionId) ;

    const selectedList:TaskSectionObj = projectDetail.sectionList?.[sectionId];
    const selectedTask:TaskObj|null = selectedList && selectedList.taskList.find(t=>(t.id==taskId))||null;

    if(selectedList && selectedTask){
      onEditTaskDetail(selectedTask,selectedList);
    }

  },[params.taskId,params?.sectionId]);

  const onClickAddTask = (list: TaskSectionObj) => {
    setList(list);
    setAddTaskOpen(true);
    setSelectedTask(null);
    const newTask:TaskObj = {
      // id: '',
      title:'',
      attachments:[],
      label:[],
      date:null,
      comments:[],
      desc:'',
      collaborators:[],
      checkedList:[],
      // section:null,
      sectionId: list.id,
      type: "TASK",
      spaceId: projectDetail.spaceId,
      projectId: projectDetail.id
    };
    dispatch(onAddNewTask(newTask,(task:TaskObj) => {
      setSelectedTask({...newTask,id:task.id});
    }));
  };

  const onCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  const onClosedTask = () => {
    if(list && selectedTask && !selectedTask.title){
      dispatch(onDeleteSelectedTask(projectDetail.id, list?.id, selectedTask?.id||''));
    }
    onCloseAddTask();
  };


  const onAddSection = (name: string) => {
    dispatch(onAddNewSection(projectDetail.id, {id: projectDetail.sectionList.length, name, taskList: [], color: getUniqueColor(projectDetail.sectionList.map(s => s.color))}));
  };

  const onEditTaskDetail = (task: TaskObj, list: TaskSectionObj) => {
    setSelectedTask(task);
    setList(list);
    setAddTaskOpen(true);
  };

  const onDragEnd = (result: any) => {
    const {destination, source, draggableId} = result;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start: any = projectDetail.sectionList.find(
      (x) => x.id == source.droppableId,
    );

    const startIndex = projectDetail.sectionList.findIndex(
      (x) => x.id == source.droppableId,
    );

    const finish: any = projectDetail.sectionList.find(
      (x) => x.id == destination.droppableId,
    );

    const finishIndex = projectDetail.sectionList.findIndex(
      (x) => x.id == destination.droppableId,
    );

    if (start.id === finish.id) {
      const newTaskCollection:TaskObj[] = Array.from(start.taskList);
      const [removed]:TaskObj[] = newTaskCollection.splice(source.index, 1);
      newTaskCollection.splice(destination.index, 0, removed);
      start.taskList = newTaskCollection;
      // dispatch(onEditBoardList(boardDetail.id, start));
      return;
    }

    const startTaskCollection:TaskObj[] = Array.from(start.taskList);
    const finishTaskCollection:TaskObj[] = Array.from(finish.taskList);

    const [removed]:TaskObj[] = startTaskCollection.splice(source.index, 1);

    removed.sectionId = finishIndex;
    finishTaskCollection.splice(destination.index, 0, removed);
    start.taskList = startTaskCollection;
    finish.taskList = finishTaskCollection;
    projectDetail.sectionList[startIndex] = start;
    projectDetail.sectionList[finishIndex] = finish;

    dispatch(onEditTaskDetails({...removed,projectId:projectDetail.id},()=>{}));
    
  };

  return (
    <AppsContent style={{flex: 1}}>
      <Box className='project-row'>
        <DragDropContext onDragEnd={onDragEnd}>
        {projectDetail.sectionList &&
          projectDetail.sectionList.length > 0 &&
          projectDetail.sectionList.map(list => {
            return (
              <List
                key={list.id}
                list={list}
                projectId={projectDetail.id}
                onEditTaskDetail={onEditTaskDetail}
                onClickAddTask={onClickAddTask}
              />
            );
          })}
          </DragDropContext>
        <Box p={2} display='flex' flexDirection='column' className='project-col'>
          <AddNewList onAddList={onAddSection} />
        </Box>
      </Box>
      {isAddTaskOpen ? (
        <Dialog
          open={isAddTaskOpen}
          onClose={onClosedTask}
          aria-labelledby='simple-modal-title'
          TransitionComponent={Transition}
          aria-describedby='simple-modal-description'
          className={classes.dialogBox}>
           <Scrollbar className={classes.scrollRoot}>
        <AddTaskCard
          onCloseAddTask={onCloseAddTask}
          list={list!}
          project={projectDetail}
          selectedTask={selectedTask}
          submitTask={(taskTag) => {}}
          annotationType={""}
        />
        </Scrollbar>
        </Dialog>
      ) : null}
    </AppsContent>
  );
};

export default ProjectDetailView;
