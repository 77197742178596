import { GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {SpatialThinkSDK} from '../SpatialThinkSDK';
import {BaseComponent} from './BaseComponent';
import {RenderingSystem} from '../Systems/RenderingSystem';

export class GLTFComponent extends BaseComponent {
    public static loader: GLTFLoader | undefined = undefined;
    protected gltf:GLTF;
    constructor(renderingSystem :RenderingSystem, protected url:string) {
        super(renderingSystem);
        this.type = "GLTFComponent";
        if (!GLTFComponent.loader) {
            GLTFComponent.loader = new GLTFLoader();
        }
        
        GLTFComponent.loader.load(url, this.onLoadComplete.bind(this));
    }
    
    onLoadComplete(gltf:GLTF) {
        this.gltf = gltf;
        this.root.add(this.gltf.scene);
    }
}
