import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  PeopleObj, SpaceData, SpaceRole,
} from 'types/models/home/HomeApp';
import {
  Box,
  List,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { canEdit } from '@crema/utility/Utils';
import { Fonts } from 'shared/constants/AppEnums';
import { grey, red } from '@material-ui/core/colors';
import { onDeleteSpacePeople } from 'redux/actions/Space';
import { useAuthUser } from '@crema/utility/AppHooks';
import PeopleItem from './PeopleItem';
import Layout from '../layout';
import ConfirmationDialog from '@crema/core/ConfirmationDialog';
import IntlMessages from '@crema/utility/IntlMessages';
import AppCard from '@crema/core/AppCard';
import { Scrollbar } from '@crema';
import AppList from '@crema/core/AppList';
import { fetchError } from 'redux/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
      color: 'black',
      lineHeight: '48px',
    },
    tagsRoot: {
      // overflow: 'scroll',
      // height: '800px',
      // paddingLeft: '10px',
      // paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    // heading: {
    //   fontWeight: 500,
    //   color: theme.palette.text.primary,
    //   lineHeight: '48px',
    // },
    root: {
      width: '100%',
      // maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },

    listItem: {
      paddingLeft: '12px',
      paddingRight: '2px',
      fontSize: '12px',
      paddingTop: '2px',
      paddingBottom: '2px',
      background: "#f9f8f8",
      borderRadius: "5px",
      marginBottom: "7px",
      '&:hover': {
        border: "1px solid grey",
      },
    },
  }),
);

interface Props { }

export default function PeopleSidebar({ }: Props) {
  const currentSpace = useSelector<
    AppState,
    SpaceData | undefined
  >(({ home }) => home.currentSpace);
  const peopleList = useSelector<
    AppState,
    PeopleObj[]
  >(({ home }) => home.peopleList);
  const classes = useStyles();
  const dispatch = useDispatch();
  let authUser = useAuthUser();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<PeopleObj | undefined>(undefined);

  const onDeletePeople = () => {

    if (peopleList.find(p => p.role == SpaceRole.OWNER && p.userId !== selectedUser?.userId)) {
      currentSpace && selectedUser && dispatch(onDeleteSpacePeople(currentSpace.id, currentSpace.currentSpaceProject.id, selectedUser));
      setDeleteDialogOpen(false);
    } else {
      dispatch(fetchError("Cannot delete the last space Owner. You may delete the space from your Spaces Dashboard."))
    }
  };

  let currentUserSpaceRole: PeopleObj | undefined = peopleList.find((p) => (p.userId === authUser?.uid));

  return (console.log(`[st] rendering PeopleSidebar`),
    <Layout>
      <div className={
        // 'sidebar-container'
        ""
      }>
        <Typography
          variant='h6'
          align='center'
          className={classes.heading}
          gutterBottom>
          People
        </Typography>

        {/* <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}> */}
        {currentSpace && canEdit(currentSpace.spaceMembership.role) && (


          <List
            style={{ width: "100%", padding: 8 }}
          // style={{ padding: 3 }}
          >

            {peopleList.map((people: PeopleObj, index) => (

              <PeopleItem
                // listStyle='paddingX'
                key={people.email}
                item={people}
                setDeleteDialogOpen={setDeleteDialogOpen}
                setSelectedUser={setSelectedUser}
                currentPeopleSpaceRole={currentUserSpaceRole}
              />
            ))}
          </List>
        )}
        {isDeleteDialogOpen ? (
          <ConfirmationDialog
            open={isDeleteDialogOpen}
            onDeny={setDeleteDialogOpen}
            onConfirm={onDeletePeople}
            title={<IntlMessages id='sidebar.people.deleteMessage' />}
            dialogTitle={<IntlMessages id='common.deleteItem' />}
          />
        ) : null}

      </div>
    </Layout>);

}
