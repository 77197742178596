//import { ComponentInteractionType, ISceneNode, SceneComponent } from "mp";
import * as THREE from 'three';
import {Object3D} from 'three';
import {ComponentInteractionType, ISceneNode, SceneComponent} from '../../SubSystems/sceneManagement/SceneComponent';
import Emitter, {EmitterType} from '../../SubSystems/ParticleEngine/Emitter';
import ShaderStringCache from '../../SubSystems/ParticleEngine/Shaders/ShaderStringCache';
import GradientInterpolator from '../../SubSystems/ParticleEngine/Interpolators/GradientInterpolator';
import RegularEmitterDescriptor from '../../SubSystems/ParticleEngine/EmitterDescriptor/RegularEmitterDescriptor';
import RingEmitterDescriptor from '../../SubSystems/ParticleEngine/EmitterDescriptor/RingEmitterDescriptor';
import CircleEmitterDescriptor from '../../SubSystems/ParticleEngine/EmitterDescriptor/CircleEmitterDescriptor';
import VectorGradientInterpolator from '../../SubSystems/ParticleEngine/Interpolators/VectorGradientInterpolator';
import Utils from '../../Tools/Utils';
import { store } from 'App';

interface Inputs {
    particleCount: number;
    velocityMin: {x: number; y: number; z: number},
    velocityMax: {x: number; y: number; z: number},
    gravity: {x: number; y: number; z: number},
    chaosMax: {x: number; y: number; z: number},
    chaos: boolean;
    particleSystemLife: number;
    boxVisible: boolean;
}


export class FireParticleSystemComponent extends SceneComponent {
    //public members
    inputNode: ISceneNode;
    public ignoreInMeshFindPopulation:boolean = true;
    public scene: THREE.Scene;
    inputs: Inputs = {
        particleCount: 5000,
        velocityMin: {x: 0, y: 0, z: 0},
        velocityMax: {x: 0, y: 0, z: 0},
        gravity: {x: 0, y: 0, z: 0},
        chaosMax: {x: 0, y: 0, z: 0},
        chaos: false,
        particleSystemLife: 1.25,
        boxVisible: true
    };
    events = {
        [ComponentInteractionType.CLICK]: true,
        //[ComponentInteractionType.HOVER]: true,
        //[ComponentInteractionType.DRAG]: true,
    };
    //private members
    public root: Object3D | null = null;
    private moved: boolean = false;
    private emitter: Emitter;
    private boxMesh: THREE.Mesh;

    onInit() {
        // @ts-ignore
        const THREE = this.context.three;
        this.scene = this.context.scene;

        this.root = new THREE.Group();

        this.outputs.objectRoot = this.root;

        //BoxGeometry (makes a geometry)
        var geometry = new THREE.BoxGeometry(0.1, 0.1, 0.1);
        //Material to apply to the cube (green)

        let opacity = !store.getState().home.presentationMode ? 0.5 : 0.01; //check the latest value every time
        // let opacity = this.inputs.boxVisible ? 0.5 : 0.01;
        var material = new THREE.MeshBasicMaterial({color: 0xf35c14, transparent: true, opacity: opacity});
        //Applies material to BoxGeometry
        this.boxMesh = new THREE.Mesh(geometry, material);
        this.root.add(this.boxMesh);

        this.outputs.collider = this.boxMesh;

        this.emitter = new Emitter(this, {
            emitterType: EmitterType.GlowyAdditive,
            particleTexture: '/assets/images/fireParticleTexture.png',
            vertexShader: ShaderStringCache.fireVert,
            fragmentShader: ShaderStringCache.fireFrag,
            particleCount: 5000,
            alphaTest: 0.5,
            damping: 0.997,
            spawnInterval: 0.1,
            emissionRate: 100,
            life: this.inputs.particleSystemLife,
            randomLife: 0,
            timeIntervalScale: 1,
            brownianMotion: false,
            brownianVector: new THREE.Vector3(10, 10, 10),
            emitterDescriptor: new CircleEmitterDescriptor(0.5, 1, new THREE.Vector3(0, 0.5, 0), new THREE.Vector3(1, 2.5, 1), new THREE.Vector3(-1, 0, -1),
                0, 20, -20),
        });

        //this.emitter.universalForces.push(new THREE.Vector3(0, -1, 0));
        // this.root.position.set(0, 0, 2);
        // this.root.rotation.setFromVector3(new THREE.Vector3(0, 0, 45));
        this.emitter.addUniversalForce(new THREE.Vector3(0, -1, 0));
        this.emitter.addAttractor({position: new THREE.Vector3(0, 1, 0), mass: 5, radius: 5});
        // this.emitter.addAttractor({ position: new THREE.Vector3(0, 3.75, 4), mass: 40, radius:3 });


        this.emitter.alphaGradientInterpolator = new GradientInterpolator([
            {gradient: this.inputs.particleSystemLife, interpolationMapping: 0},
            {gradient: this.inputs.particleSystemLife * 0.5, interpolationMapping: 0.75},
            {gradient: this.inputs.particleSystemLife * 0.25, interpolationMapping: 1},
            {gradient: 0, interpolationMapping: 0}]);

        this.emitter.sizeGradientInterpolator = new GradientInterpolator([
            {gradient: this.inputs.particleSystemLife, interpolationMapping: 0.2},
            {gradient: this.inputs.particleSystemLife * 0.5, interpolationMapping: 0.01},
            {gradient: 0, interpolationMapping: 0.0}]);

        this.emitter.colorGradientInterpolator = new VectorGradientInterpolator([
            {
                gradient: this.inputs.particleSystemLife,
                interpolationMapping: Utils.getColorAsVector(new THREE.Color('#ffcd00')),
            },
            {
                gradient: this.inputs.particleSystemLife * 0.95,
                interpolationMapping: Utils.getColorAsVector(new THREE.Color('#ff7300')),
            },
            {
                gradient: this.inputs.particleSystemLife * 0.55,
                interpolationMapping: Utils.getColorAsVector(new THREE.Color('#ff0000')),
            },
            {gradient: 0, interpolationMapping: Utils.getColorAsVector(new THREE.Color('#000000'))}]);

        //window.addEventListener('pointerdown', this.onPointerDown);
    }

    public getEmitters():Emitter[] {
        return [this.emitter];
    }

    onTick(tickDelta: number) {
        let tickInSeconds = tickDelta * 0.001;
        this.emitter.simulate(tickInSeconds);
    }

    onEvent(eventType: string, eventData: unknown) {
        this.notify(eventType, eventData);
    }

    onInputsUpdated(oldInputs: Inputs) {
        // @ts-ignore
        if (oldInputs.boxVisible !== this.inputs.boxVisible) {
        //     // @ts-ignore
            this.onInit();
        }
    }

    onDestroy() {
        this.outputs.collider = null;
        this.outputs.objectRoot = null;
        this.emitter.dispose();
        //window.removeEventListener('pointerdown', this.onPointerDown);
    }

    // private onPointerDown() {
    //     this.moved = false;
    // }
}

export const fireParticleSystemType = 'st.fireParticleSystem';
export const makeFireParticleSystem = function() {
    return new FireParticleSystemComponent();
};
