import Utils from "../../Tools/Utils";
import { ISceneNode } from "../sceneManagement/SceneComponent";

export default class Serialization {
    public static SerializeNode(node: ISceneNode, id: string): any {
        /*
        if(node.userData['id'] !== id) {
            node.userData['id'] = id;
            console.log("Patching id for duplicate node")
        }*/
        let serializedNodeData = {
            annotationType: "3dObject",
            name: node.name,
            position: JSON.stringify(node.position),
            scale: JSON.stringify(node.scale),
            quaternion: JSON.stringify(node.quaternion),
            userData: Utils.SimpleClone(node.userData),
            id: id,
            // createdOn: new Date(),
            // createdBy: user.uid
        }

        return serializedNodeData;
    }

    public static DeserializeNode(doc: any) {
        return {
            ...doc.data(),
            ...{
                id: doc.id,
                /*nameToShow: Utils.ExtractModelDisplayName(doc.data().name, doc.id)*/
            }
        }
    }
}