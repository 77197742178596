import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';
import Home from './Home';
import Projects from './Projects';
import ToDoApp from './ToDoApp';
import ContactApp from './ContactApp';
import Ecommerce from './Ecommerce';
import Dashboard from './Dashboard';

const reducers = {
  settings: Settings,
  auth: Auth,
  common: CommonReducer,
  home: Home,
  projects: Projects,
  todoApp: ToDoApp,
  contactApp: ContactApp,
  ecommerce: Ecommerce,
  dashboard: Dashboard
};

export default reducers;
