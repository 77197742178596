import { AuthUser } from '../../types/models/AuthUser';
import { AuthType } from './AppEnums';
import mpc from 'shared/styles/MatterportColors.json' ;

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const initialUrl = '/home/spaces'; // this url will open after login
// export const initialUrl = '/ecommerce/products'; // this url will open after login

export const channels = {
  email: 'email',
  notification: 'notification',
};

export const templates = {
  task: 'task',
  shareSpace: 'shareSpace',
  signup: 'signup',
};

export interface UseCase {
  id: number;
  name: string;
  layerTermSingular: string;
  layerTermPlural: string;
}

export const defaultLayerNameSingular = "Experience";
export const defaultLayerNamePlural = "Experiences"

export const UseCases: UseCase[] = [
  { id: 0, name: "Training", layerTermSingular: "Lesson", layerTermPlural: "Lessons" },
  { id: 1, name: "Hotel/ Airbnb", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 2, name: "Home Owner/ Home Manual", layerTermSingular: "Walkthrough", layerTermPlural: "Walkthroughs" },
  { id: 3, name: "Layout Planning", layerTermSingular: "Walkthrough", layerTermPlural: "Walkthroughs" },
  { id: 4, name: "Interior Design", layerTermSingular: "Walkthrough", layerTermPlural: "Walkthroughs" },
  { id: 5, name: "Retail", layerTermSingular: "Tour", layerTermPlural: "Tours" },
  { id: 6, name: "Facilities Management", layerTermSingular: "Walkthrough", layerTermPlural: "Walkthroughs" },
  { id: 7, name: "Construction", layerTermSingular: "Walkthrough", layerTermPlural: "Walkthroughs" },
  { id: 8, name: "Office Manual", layerTermSingular: "Walkthrough", layerTermPlural: "Walkthroughs" },
  // { id: 9, name: "Monitoring & Remote Control", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 100, name: "Other", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
];

export const taskStatusColors = ['#F87E7D','#FFCD7A','#FEFF00','#7ED085','#5FB3F9','#D190DA','#D0AF8F','#BDBDBD','#FFCCD2','#FFE1AF','#FEFF85','#C6E7C8','#B9DDFC','#EBC7EF','#EEDCCA','#E5E5E5','#FFEBEE','#FFF3DF','#FFFEE6','#E7F5E9','#E2F2FE','#F4E4F5','#F2E8DE','#FFFFFF'];
// export const labelList = [
//   {id: '0', name: 'Scheduled'}, {id: 1, name: 'In Progress'}, {id: 2, name: 'Complete'}, {id: 3, name: 'Needs Attention'}];

export const getUniqueColor = (colorsToExclude: string[]) => taskStatusColors.find(c => !colorsToExclude.includes(c)) || taskStatusColors[0];

// export const MatterportColors = JSON.parse(`[{"num":1,"color":"Red","label":"1 red","rgb":"213,68,65","r":"213","g":"68","b":"65"},{"num":2,"color":"Orange","label":"2 orange","rgb":"245,67,54","r":"245","g":"67","b":"54"},{"num":3,"color":"Pink","label":"3 pink","rgb":"234,30,99","r":"234","g":"30","b":"99"},{"num":4,"color":"PinkWarm","label":"4 pinkWarm","rgb":"248,142,168","r":"248","g":"142","b":"168"},{"num":5,"color":"Lavender","label":"5 lavender","rgb":"157,75,147","r":"157","g":"75","b":"147"},{"num":6,"color":"Purple","label":"6 purple","rgb":"103,58,184","r":"103","g":"58","b":"184"},{"num":7,"color":"Teal","label":"7 teal","rgb":"3,104,125","r":"3","g":"104","b":"125"},{"num":8,"color":"Blue","label":"8 Blue","rgb":"3,170,245","r":"3","g":"170","b":"245"},{"num":9,"color":"SkyBlue","label":"9 skyblue","rgb":"0,189,213","r":"0","g":"189","b":"213"},{"num":10,"color":"Olive","label":"10 olive","rgb":"65,117,5","r":"65","g":"117","b":"5"},{"num":11,"color":"Green","label":"11 Green","rgb":"81,169,104","r":"81","g":"169","b":"104"},{"num":12,"color":"Mint","label":"12 mint","rgb":"55,215,122","r":"55","g":"215","b":"122"},{"num":13,"color":"Lime","label":"13 lime","rgb":"206,221,57","r":"206","g":"221","b":"57"},{"num":14,"color":"Yellow","label":"14 yellow","rgb":"252,206,0","r":"252","g":"206","b":"0"},{"num":15,"color":"Mustard","label":"15 mustard","rgb":"256,173,23","r":"256","g":"173","b":"23"},{"num":16,"color":"Brown","label":"16 brown","rgb":"256,105,0","r":"256","g":"105","b":"0"},{"num":17,"color":"Grey","label":"17 grey","rgb":"172,185,196","r":"172","g":"185","b":"196"},{"num":18,"color":"Black","label":"18 black","rgb":"96,125,140","r":"96","g":"125","b":"140"}]`);

export const LabelColorFromTag = (tagColorObj: any): string => {
  let c = tagColorObj;

  let r = (c.r * 256).toFixed(0);
  let g = (c.g * 256).toFixed(0);
  let b = (c.b * 256).toFixed(0);

  let colorEntry = mpc.colors.find((c: any) => c.r256 === r && c.g256 === g && c.b256 === b);
  // !colorEntry && console.log(`[st] ${JSON.stringify( c)}`);

  return colorEntry && colorEntry.color || mpc.colors[0].color;
}

export const defaultTaskLabelColor = "#e8e5e4";
export const defaultTaskCollaboratorColor = "#e8e5e4";