import { store } from "App";
import { GizmoTools } from "modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import { fetchError, showMessage } from "redux/actions";
import { TemporalOutlineElement } from "../../components/PostProcess/OutlineComponent";
import QueueScheduler from "../../Tools/QueueScheduler";
import Utils from "../../Tools/Utils";
import PlaceObjectBehaviors from "../core/PlaceObjectBehaviors";
import { SimulationMode } from "../core/RenderingAndPlaceObjectStateSystem";
import InputSubSystem from "../input/InputSubSystem";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import NodeStorage from "../storageAndSerialization/NodeStorage";
import PropertiesPanel, { PropertiesPanelMode, UserDataProperties, UserDataTypes } from "../ui-interop/PropertiesPanel";

export default class PropertiesPanelWithSimulationFunctionality extends PlaceObjectBehaviors {
    propertiesPanel: PropertiesPanel;

    private _propertyUpdateQueueScheduler:QueueScheduler<ISceneNode>;

    constructor() {
        super();
        this.propertiesPanel = new PropertiesPanel();
        this._propertyUpdateQueueScheduler = new QueueScheduler<ISceneNode>(this.selectNodeWithPropertiesUpdate.bind(this), 10);
    }

    public async handleModelClick(nodeId: string): Promise<boolean> {
        GizmoTools?.instance?.hideTools();
        this.propertiesPanel.hidePropertiesPanel();
        if (!this.spaceModels || this.spaceModels.size == 0 || !this.spaceModels.has(nodeId)){
            return false;
        }
        let node = this.spaceModels.get(nodeId).nodeRef;
        let meshes = Utils.FindAllMeshesAndLineSegments(node);
        //this.camera.LookAtNode(node, () => this.PropertyUpdateQueueScheduler.addQueueElement(node, true));
        this.camera.NavigateToNode(node, () => {
            this.PropertyUpdateQueueScheduler.addQueueElement(node, true);
            this.outlineComponent.temporalOutlines.set(nodeId, new TemporalOutlineElement(1750, meshes!));
        });
        Utils.EnableCollidersOnNode(node);

        Utils.SetVisibility(true, node, meshes);

        console.log(`Model Clicked ${nodeId}`);
        this.cancelPlaceMode();
        this.cancelAddObject();
        return true;
        //this.propertiesPanel.hidePropertiesPanel();
        //this.resetSimulationMode();
        //this.propertyUpdateQueueScheduler.addQueueElement(node, true);
    }

    async addObject(objectName: string) {
        if (this.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Adding ||
            this.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Editing) {
            console.log("TODO: Warning you can't add anything while in event add mode for the properties panel");
            store.dispatch("You can't add anything while in behavior add mode!");

        } else {
            if(await NodeStorage.loadNode(objectName)) {
                console.log("[Simulation] AddObject: " + objectName);
                store.dispatch(showMessage("Move cursor into the Space area to add!"));
                this.simulationMode = SimulationMode.ADD_OBJECT;

                this.alreadyLocked = this.lockViewState[0];

                InputSubSystem.input.setMatterPortCameraMovement(false);
                this.lockViewState[1](true);
            } else {
                console.error("[Simulation] Failed to execute AddObject: " + objectName);
                store.dispatch(fetchError("Failed to add object!"));
                //dispatch(showMessage("Failed to add object!"))
            }
        }
    }

    public async selectNodeWithPropertiesUpdate(node:ISceneNode):Promise<boolean> {

        let tempOldLastSelectedNode = this.lastSelectedNode;

        //this.root.lastSelectedNode = this.node;
        await this.selectNode(node)
        //this.root.lastSelectedNode.position
        /*
        var meshes = Utils.FindAllMeshes(this.node);
        if(meshes) {
            Simulation.instance.outlineComponent.SelectedObjects = meshes;
        }*/

        if (this.propertiesPanel.checkIfNodeHasProperties(node)) {

            let shouldShowPropertiesPanel = this.propertiesPanel.showPropertiesPanel();
            if (shouldShowPropertiesPanel) {
            } else {
                this.propertiesPanel.updateClickEventAction(node);
                this.selectNode(tempOldLastSelectedNode!);
            }
        } else {
            if (this.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.CanAdd) {
            } else {
                this.selectNode(tempOldLastSelectedNode!);
            }
        }

        return true;
    }

    resetSimulationMode(): void {

        if (this.getSimulationMode() == SimulationMode.ADD_OBJECT) {
            setTimeout(this.resumeMatterPortCameraMovement.bind(this), 1000);
            this.propertiesPanel.saveLastNode(true, this.scene.getLastNodeAdded());
            //this.propertiesPanel.refreshReactStateUI();
        } else if (this.getSimulationMode() == SimulationMode.PLACE_MODE) {
            this.cancelPlaceMode();
            if (this.lastSelectedNode) {
                this.propertiesPanel.saveLastNode(true, this.lastSelectedNode);
            }
        }
        this.simulationMode = SimulationMode.NONE;
    }

    get PropertyUpdateQueueScheduler(): QueueScheduler<ISceneNode> {
        return this._propertyUpdateQueueScheduler;
    }
}
