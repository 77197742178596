import { firestore } from "@crema/services/auth/firebase/firebase";
import { store } from "App";
import { getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { Behaviors } from "mp/core/craEngine/SubSystems/core/Behaviors";
import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import { UserDataProperties } from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel";
import { ActionType, TriggerActionOutcome } from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanelBehaviorActions";
import { showMessage } from "redux/actions";
import { handleGoToTagGroupByIndex } from "redux/actions/Step";
import { SET_VARIABLE_VALUE } from "types/actions/Home.action";

export interface Logic {
    conditions: ConditionTree[];
}
export interface ConditionTree {
    id?: string, // Identify Condition Tree
    conditionRoot: NestedCondition;
    actions: Action[];
}
export enum CONDITION_TYPE
{
    VARIABLE_CONDITION = "Variable Condition",
    OBJECT_CONDITION = "Object Condition",
    NESTED_TREE = "Nested Tree"
}

export interface Condition {
    type: CONDITION_TYPE;
    objectId?:string;
}
export interface VarCondition extends Condition {
  varName: string;
  varValue: string;
}
export interface ObjectCondition  extends Condition {
    objectEventType: OBJECT_EVENT_TYPES;
}

export interface TreeCondition  extends Condition {
    nestedCondition: NestedCondition
}

export enum OBJECT_EVENT_TYPES {
    CLICK = "Click",
    HOVER = "Hover - coming soon"
 }
export interface NestedCondition {
    conditionJoiner: CONDITION_JOINER;
    conditions: Condition[];
}

export interface Action {
    type: ACTION_TYPE;
}

export enum ACTION_TYPE {
    VARIABLE_ACTION = "Variable action",
    OBJECT_ACTION = "Object action",
    NEXTSTEP_ACTION = "NextStep action",
    WAIT_ACTION = "Wait Action"
}
export interface ObjectAction extends Action {
    actionType: OBJECT_ACTIONS;
    objectId: string;
    iotVariableName?: string;
    parameters?: string;
}

export interface VarAction extends Action {
    varName: string;
    varValue: string;
}
export interface NextStepAction extends Action {
    currentTagGroupId: string;
    waitInSeconds: number;
}
export enum CONDITION_JOINER { ALL = "And", ANY = "Or", NONE = "None"}
export enum OBJECT_ACTIONS { HIGHLIGHT = "Highlight", GROW_SHRINK = "Animate", SHOW = "Show", HIDE = "Hide", MOVE = "Move", ROTATE = "Rotate", SCALE = "Scale", CALL_ACTIVATE = "Show values on Digital Display" }

export const conditionsTest = async () => {

  let c1: VarCondition = { varName: "Material", varValue: "Copper", type: CONDITION_TYPE.VARIABLE_CONDITION };
  let c2: VarCondition = { varName: "Diameter", varValue: "1/2 inch", type: CONDITION_TYPE.VARIABLE_CONDITION };

  let orCondition: NestedCondition = { conditions: [c1, c2], conditionJoiner: CONDITION_JOINER.ALL }

  let a1: ObjectAction = { actionType: OBJECT_ACTIONS.SHOW, objectId: "someId", type: ACTION_TYPE.OBJECT_ACTION };
  let a2: VarAction = { varName: "System Pressure", varValue: "ON", type: ACTION_TYPE.VARIABLE_ACTION  };
  let a3: VarAction = { varName: "Lever State", varValue: "ON", type: ACTION_TYPE.VARIABLE_ACTION };

  let mainCondition1Nested: ConditionTree = { conditionRoot: orCondition, actions: [a1, a2] };

  let logic: Logic = {
    conditions: [
      mainCondition1Nested
    ]
  }
  console.log(`[st] ${JSON.stringify(logic)}`);
}

export class LogicEngine {
    public static async onVariablesStateChange(currentLessonId:string|undefined, currentTagGroupId:string|undefined, currentSpaceId:string|undefined,
        /*allModels: Map<string, any> = Simulation.instance.spaceModels,*/
                                         variableValues: any[] | undefined = Simulation.instance.Variables):Promise<void> {

        if(currentSpaceId) {
            if(currentLessonId && currentTagGroupId) {
                let docRef = await firestore.doc(`Spaces/${currentSpaceId}/lessons/${currentLessonId}/tagGroups/${currentTagGroupId}`);
                let doc = await docRef.get();
                let data = await doc.data();

                if(data ) {
                    if(data.logic) {
                        LogicEngine.processStepLogicConditions(data.id, data.logic as Logic, null, false, false);
                    }
                }
            }
        }
        /*
        allModels.forEach((model: any) => {
            let targetNode: ISceneNode =  model.nodeRef;//
            if(targetNode){
                //LogicEngine.processSceneNodeLogicConditions(targetNode, false, false, Simulation.instance.spaceModels, variableValues);
            }
        });*/
    }

    public static evaluateNestedCondition(nestedCondition:NestedCondition, targetNode:ISceneNode|null, clickEvent:boolean = false, hoverEvent:boolean = false,
                                        allModels: Map<string, any> = Simulation.instance.spaceModels,
                                        variableValues: any[] | undefined = Simulation.instance.Variables): boolean {
        let conditionMet:boolean = false;

        if(nestedCondition.conditionJoiner === CONDITION_JOINER.ALL) {
            if(nestedCondition.conditions) {
                conditionMet = true;

                for (const subConditionElement of nestedCondition.conditions) {
                    if(!LogicEngine.checkCondition(targetNode, allModels, variableValues, subConditionElement, clickEvent, hoverEvent)) {
                        conditionMet = false;
                        break;
                    }
                }
            }
        } else if (nestedCondition.conditionJoiner === CONDITION_JOINER.ANY) {
            if(nestedCondition.conditions) {
                conditionMet = false;

                for (const subConditionElement of nestedCondition.conditions) {
                    if(LogicEngine.checkCondition(targetNode, allModels, variableValues, subConditionElement, clickEvent, hoverEvent)) {
                        conditionMet = true;
                        break;
                    }
                }
            }
        } else if (nestedCondition.conditionJoiner === CONDITION_JOINER.NONE) {
            if(nestedCondition.conditions) {
                conditionMet = true;

                for (const subConditionElement of nestedCondition.conditions) {
                    if(LogicEngine.checkCondition(targetNode, allModels, variableValues, subConditionElement, clickEvent, hoverEvent)) {
                        conditionMet = false;
                        break;
                    }
                }
            }
        }

        return conditionMet;
    }

    public static processStepLogicConditions(tgId: string, stepLogic: Logic, targetNode:ISceneNode | null = null, clickEvent:boolean = false, hoverEvent:boolean = false,
                                            allModels: Map<string, any> = Simulation.instance.spaceModels,
                                            variableValues: any[] | undefined = Simulation.instance.Variables):void {

         if (getCurrentTagGroup()?.id !== tgId) {
             return;
         }

         if(stepLogic.conditions) {
            let conditionTree = stepLogic.conditions as ConditionTree[];
            conditionTree.forEach((condition: ConditionTree) => {

                //performActions(condition.actions);
                let nestedCondition = condition.conditionRoot as NestedCondition;
                if(LogicEngine.evaluateNestedCondition(nestedCondition, targetNode, clickEvent, hoverEvent, allModels, variableValues)) {
                    //Perform actions
                    LogicEngine.performActions(tgId, condition.actions, variableValues, allModels);
                } else {
                    LogicEngine.performUnActions(tgId, condition.actions, variableValues, allModels);
                }
            });

        }

    }

    public static checkCondition(targetNode: ISceneNode|null, allModels: Map<string, any>, variableValues: any[] | undefined, condition: Condition, clickEvent:boolean = false, hoverEvent:boolean = false):boolean {
        if (condition.type === CONDITION_TYPE.OBJECT_CONDITION) {
            let objectCondition = (condition as ObjectCondition);

            if(targetNode) {
                if(objectCondition.objectEventType === OBJECT_EVENT_TYPES.CLICK) {
                    if(clickEvent) {
                        if(objectCondition.objectId === targetNode.userData[UserDataProperties.id]) {
                            return true;
                        }
                    }
                } else if(objectCondition.objectEventType === OBJECT_EVENT_TYPES.HOVER) {
                    if(hoverEvent) {
                        if(objectCondition.objectId === targetNode.userData[UserDataProperties.id]) {
                            return true;
                        }
                    }
                }
            }
        } else if (condition.type === CONDITION_TYPE.VARIABLE_CONDITION) {
            let variableCondition = (condition as VarCondition);
            if(variableCondition.varName) {
                let relevantSystemVariable = variableValues!.find(vv => vv.name.toLowerCase() === variableCondition.varName.toLowerCase());

                if(relevantSystemVariable) {
                    if (relevantSystemVariable.hasOwnProperty("value")) {
                        if((relevantSystemVariable.value as string != null) && (relevantSystemVariable.value as string).length > 0) {
                            if(relevantSystemVariable.value === variableCondition.varValue) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else if (condition.type === CONDITION_TYPE.NESTED_TREE) {
            let nestedTreeCondition = (condition as TreeCondition);

            let nestedCondition = nestedTreeCondition.nestedCondition as NestedCondition;
            return LogicEngine.evaluateNestedCondition(nestedCondition, targetNode, clickEvent, hoverEvent, allModels, variableValues);
        }

        return false;
    }

    public static performUnActions(tgId: string, actions: Action[], variableValues:any[]|undefined, allModels: Map<string, any>):void {
        if (getCurrentTagGroup()?.id !== tgId) {
            return;
        }

        actions.forEach(action => {
            if(action.type === ACTION_TYPE.OBJECT_ACTION) {
                let objectAction = action as ObjectAction;

                let triggerActionOutcome: TriggerActionOutcome = {
                    actionType: ActionType.Show,
                    parameter: ""
                };

                switch (objectAction.actionType) {
                    case OBJECT_ACTIONS.SHOW:
                        triggerActionOutcome.actionType = ActionType.Show;
                        break;
                    case OBJECT_ACTIONS.HIDE:
                        triggerActionOutcome.actionType = ActionType.Hide;
                        break;
                    case OBJECT_ACTIONS.HIGHLIGHT:
                        triggerActionOutcome.actionType = ActionType.Highlight;
                        break;
                    case OBJECT_ACTIONS.GROW_SHRINK:
                        triggerActionOutcome.actionType = ActionType.GrowShrinkAnimate;
                        break;
                    case OBJECT_ACTIONS.CALL_ACTIVATE:
                        triggerActionOutcome.actionType = ActionType.CallActivate;
                        break;
                    case OBJECT_ACTIONS.MOVE:
                        triggerActionOutcome.actionType = ActionType.Move_Parameterized;
                        break;
                    case OBJECT_ACTIONS.ROTATE:
                        triggerActionOutcome.actionType = ActionType.Rotate_Parameterized;
                        break;
                    case OBJECT_ACTIONS.SCALE:
                        triggerActionOutcome.actionType = ActionType.Scale_Parameterized;
                        break;
                }

                //objectAction.objectId
                let model = allModels?.get(objectAction.objectId);

                if(model) {
                    let localNodeForAction = model.nodeRef as ISceneNode;
                    if(objectAction.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
                        localNodeForAction.userData[UserDataProperties.inputSource1] = objectAction.iotVariableName;
                    }
                    Behaviors.runUnActionsOnNode(localNodeForAction, triggerActionOutcome);
                }
            }
        });
    }

    public static performActions(tgId: string, actions: Action[], variableValues:any[]|undefined, allModels: Map<string, any>):void {
        if (getCurrentTagGroup()?.id !== tgId) {
            return;
        }

        actions.forEach(action => {
            if(action.type === ACTION_TYPE.OBJECT_ACTION) {
                console.log(`[st] logic obj action `);
                let objectAction = action as ObjectAction;

                let triggerActionOutcome: TriggerActionOutcome = {
                    actionType: ActionType.Show,
                    parameter: ""
                };

                switch (objectAction.actionType) {
                    case OBJECT_ACTIONS.SHOW:
                        triggerActionOutcome.actionType = ActionType.Show;
                        break;
                    case OBJECT_ACTIONS.HIDE:
                        triggerActionOutcome.actionType = ActionType.Hide;
                        break;
                    case OBJECT_ACTIONS.HIGHLIGHT:
                        triggerActionOutcome.actionType = ActionType.Highlight;
                        break;
                    case OBJECT_ACTIONS.GROW_SHRINK:
                        triggerActionOutcome.actionType = ActionType.GrowShrinkAnimate;
                        break;
                    case OBJECT_ACTIONS.CALL_ACTIVATE:
                        triggerActionOutcome.actionType = ActionType.CallActivate;
                        break;
                    case OBJECT_ACTIONS.MOVE:
                        triggerActionOutcome.actionType = ActionType.Move_Parameterized;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                    case OBJECT_ACTIONS.ROTATE:
                        triggerActionOutcome.actionType = ActionType.Rotate_Parameterized;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                    case OBJECT_ACTIONS.SCALE:
                        triggerActionOutcome.actionType = ActionType.Scale_Parameterized;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                }

                //objectAction.objectId
                let model = allModels?.get(objectAction.objectId);

                if(model) {
                    let localNodeForAction = model.nodeRef as ISceneNode;

                    if(objectAction.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
                        localNodeForAction.userData[UserDataProperties.inputSource1] = objectAction.iotVariableName;
                    }
                    Behaviors.runActionsOnNode(localNodeForAction, triggerActionOutcome);
                }
            } else if(action.type === ACTION_TYPE.VARIABLE_ACTION) {
                //This throws Error: Reducers may not dispatch actions.", don't know why, but setTimeout fixes it
                //store.dispatch({ type: SET_VARIABLE_VALUE, payload: { name: (action as any).varName, value: (action as any).varValue} });

                let variableAction = action as VarAction;

                //This is needed to check if the variable is actually unchanged
                if(variableAction.varName) {
                    let localSystemVariable = variableValues!.find(element => (element.name as string).toLowerCase() === (variableAction.varName as string).toLowerCase());


                    if(localSystemVariable) {
                        console.log(`CHANGED: set var ${variableAction.varName} to ${variableAction.varValue}`);
                        setTimeout(()=> store.dispatch({ type: SET_VARIABLE_VALUE, payload: { name: variableAction.varName, value: variableAction.varValue } }),
                        100);
                        /*
                        if(localSystemVariable.value === variableAction.varValue) {
                            console.log(`NO CHANGE: set var ${variableAction.varName} to ${variableAction.varValue}`);
                        } else {
                            setTimeout(()=> store.dispatch({ type: SET_VARIABLE_VALUE, payload: { name: variableAction.varName, value: variableAction.varValue } }),
                            100);
                            console.log(`CHANGED: set var ${variableAction.varName} to ${variableAction.varValue}`);
                        }*/
                    }
                } else {
                    console.log(`[st] A variable action was not saved properly`)
                }

            } else if (action.type === ACTION_TYPE.NEXTSTEP_ACTION) {
                let tg = getCurrentTagGroup();

                let nextTagGroupIndex = tg ? tg.sortIndex + 1 : 0;


                let nextStepAction = action as NextStepAction;
                // setTimeout(()=> {console.log(`[st] logic waking `)}, (nextStepAction.waitInSeconds || 0) * 1000);

                setTimeout(()=> {
                    if(store.getState().home.presentationMode){
                        store.dispatch(handleGoToTagGroupByIndex(nextTagGroupIndex));
                    } else {
                        store.dispatch(showMessage("Auto-moving to next step suppresed since you are in Studio mode. Press Next to continue"));
                    }

                }, (nextStepAction.waitInSeconds || 0) * 1000);

            } else if (action.type === ACTION_TYPE.WAIT_ACTION) {
                // console.log(`[st] logic sleeping `);

                // let nextStepAction = action as NextStepAction;
                // setTimeout(()=> {console.log(`[st] logic waking `)}, (nextStepAction.waitInSeconds || 0) * 1000);
                // sleep(3000);
                // let tg = getCurrentTagGroup();

                // let nextTagGroupIndex = tg ? tg.sortIndex + 1 : 0;
                // store.dispatch(handleGoToTagGroup(nextTagGroupIndex));
            }

        });
    }
}


