import { GizmoTools } from "../../../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import { GenericInputEvent } from "../../../components/systemComponents/GenericInput";
import Simulation from "../../core/Simulation";
import { IComponentEventSpy } from "../../sceneManagement/SceneComponent";
import { PropertiesPanelMode } from "../../ui-interop/PropertiesPanel";

export class GenericInputDragBeginSpy implements IComponentEventSpy<unknown> {
    public eventType = GenericInputEvent.DragBegin;
    constructor() {
    }
    onEvent(payload: any) {
        //Mouse was clicked in scene
        if (payload.event.buttons == 1) {
            Simulation.instance.resetSimulationMode();

            if(Simulation.instance.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Adding ||
            Simulation.instance.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Editing) {

            } else {
                Simulation.instance.scene.hideTransformGizmo();
                if (GizmoTools?.instance) {
                    GizmoTools?.instance?.hideTools();
                    Simulation.instance.propertiesPanel.hidePropertiesPanel();
                }
            }
        }
    }
}
