import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import {onSaveSpace} from '../../../../../../redux/actions';
import {
    Box,
    ClickAwayListener,
    Divider,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Popover,
    Portal,
    Select,
    Tooltip,
    Typography
} from '@material-ui/core';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {
    setTagsViewModeSecondary,
    setWorkflowsSidebarOpen,
} from 'redux/actions/Home';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { ShowcaseTag, SpaceRole, TagGroup } from 'types/models/home/HomeApp';
// import { _currentTagsSidMapGlobal } from '../TagSidebar/Tags/ShowcaseUtils';
//     setWorkflowsSidebarOpen,
//     setTagsViewMode,
//     setTagsViewModeSecondary,
// } from 'redux/actions/Home';
// import { TagGroup } from 'types/models/home/HomeApp';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import clsx from 'clsx';
import { ViewModeSecondary } from '..';
import EditableTextField from './EditableTextField';

// import LogoutIcon from '@material-ui/icons/Logout';
// import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CameraRoundedIcon from '@mui/icons-material/CameraRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { useHistory } from 'react-router';
import { ShowcaseContext } from '../..';
import { canEdit } from '@crema/utility/Utils';
import Alert from '@material-ui/lab/Alert/Alert';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { store } from 'App';
import Spacer from '@crema/core/Spacer';

const useStyles = makeStyles((theme: Theme) =>
    // root: {
    //   height: 216,
    //   flexGrow: 1,
    //   maxWidth: 400,
    // },
    createStyles({
        tagGroupsListing: {},
        tagGroupsListingRoot: {
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '15px',
            backgroundColor: theme.palette.background.paper,
        },
        tagGroupsListSubHeader: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        tagGroupsListItem: {
            cursor: 'pointer',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: '2px',
            paddingBottom: '2px',
            fontSize: '12px',
        },
        tagGroupsListItemActive: {
            backgroundColor: 'rgba(244, 247, 254, 0.8)',
            paddingTop: '2px',
            paddingBottom: '2px',
            fontSize: '12px',
        },
        tagsNested: {
            // paddingLeft: theme.spacing(6),
            fontSize: '12px',
            cursor: 'pointer',
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        tagGroupName: {
            '& .MuiTypography-body1': {
                fontSize: '12px',
            },
            paddingTop: '0px',
            paddingBottom: '0px',
            maxWidth: '150px',
        },
        tagsNestedText: {
            '& .MuiTypography-body1': {
                fontSize: '12px',
            },
            // paddingLeft: theme.spacing(6),
            cursor: 'pointer',
            paddingRight: '2px',
            fontSize: '12px',
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        deleteTagGroupIcon: {
            marginRight: 4,
            color: theme.palette.text.primary,
        },
        icon: {
            marginRight: 4,
            cursor: 'pointer',
            height: '18px',
            width: '18px',
            '& .MuiSvgIcon-root': {
                // width: '0.5em'
            }
        },
        iconActive: {
            // display: 'none',
            // '&:hover': {
            display: 'block',
            marginRight: 4,
            cursor: 'pointer',
            // height: '12px',
            // width: '12px',
            color: "blue",
            zIndex: 20,
            fontSize: '10px'

            // }

        },
        iconInactive: {
            // display: 'none',
            // '&:hover': {
            display: 'block',
            marginRight: 4,
            cursor: 'pointer',
            // height: '12px',
            // width: '12px',
            color: "grey",
            zIndex: 20,
            fontSize: '10px'
            // }
        },
        outlineBox: {
            // border: '1px solid #0000001c',
            padding: "15px",
            // paddingTop: "25px",
            // width: '280px',
            borderRadius: '10px',
            marginBottom: '12px'
        },
        outlineBoxTitle: {
            // marginBottom: "-5px",
            backgroundColor: "white",
            color: "black",
            marginTop: "-45px", //removes notched outline
            padding: "10px",
            width: "fit-content",
            // textTransform: "uppercase",
            fontSize: "13px",
        },
        altBgEven: {
            // backgroundColor: '##f4f7fecc'
            backgroundColor: '#3c48991c'
        },
        altBgOdd: {
            backgroundColor: '#f4f7fecc'
        },
        bgActive: {
            // backgroundColor: '#0e6ebf4a'// '#f0f8ff',
            backgroundColor: '#c8d9e9',
            border: '1px solid #0388e5bd'
        },
        bgInactive: {
            backgroundColor: '#3c48991c'
        },
        textField: {
            // marginLeft: theme.spacing(1),
            // marginRight: theme.spacing(1),
            fontSize: "13px",
            width: '250px',
            '& .MuiInputBase-input': {
                fontSize: "13px",
            },
            '& .MuiFormLabel-root': {
                fontSize: "13px",
            },
            '& .MuiInput-root': {
                // borderTopWidth: "0.5px",
                borderLeftWidth: "0.5px",
                borderRightWidth: "0.5px",
            },
            '& Mui-focused': {
                borderTopWidth: "0px",
            }
        },
        dense: {
            marginTop: theme.spacing(3),
        },
    }),
);

interface TagGroupsProps {
    onDuplicateTagGroups: (tg: TagGroup) => void;
    tg: TagGroup;
    isActive: boolean;
    // mpSdk: any;
    onClick: () => void;
    showTagsAnd3DForStep: (tgId: string) => void;
    handleEditTagGroup: (utg: TagGroup) => void;
    toggleJumpToTagId: (tagId: string, activeTg: TagGroup) => void
    getTagLabel: (tagId: string, spaceTags: any) => string
    handleTagInTagGroupClick: (tg: any, tagId: string) => void
    savePose: (activeTg: TagGroup) => void
    handleUpTagGroups: (tg: any) => void
    handleDownTagGroups: (tg: any) => void
    handleDeleteTagGroups: (tg: TagGroup) => void
    ref: any
}

const TagGroupCard: React.FC<TagGroupsProps> = ({
    onDuplicateTagGroups,
    tg,
    isActive,
    // mpSdk,
    onClick,
    showTagsAnd3DForStep,
    handleEditTagGroup,
    toggleJumpToTagId,
    getTagLabel,
    handleTagInTagGroupClick,
    savePose,
    handleDownTagGroups,
    handleUpTagGroups,
    handleDeleteTagGroups,
    ref
}) => {

    const spaceMembershipRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home.currentSpace?.spaceMembership.role);
    const variables = useSelector<AppState, any[]>(({ home }) => home.currentSpace?.variables || []);
    const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
    const spaceTags = useSelector<AppState, Map<string, ShowcaseTag>>(({ home }) => home.spaceTags);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const showcaseContext = useContext(ShowcaseContext);

    // useEffect(() => {})
    return (console.log(`[st] rendering TagGroup ${JSON.stringify(tg.name)}`),
        <>
            <ItemHover>
                <div
                    ref={ref}
                    className={
                        isActive
                            ? classes.tagGroupsListItem +
                            ' ' +
                            classes.tagGroupsListItemActive
                            : classes.tagGroupsListItem
                    }
                    // onMouseLeave={() => console.log(`[st] on m leave`)}
                    onClick={onClick}
                // onBlur={async () => {
                //     // editTagGroup &&
                //     // (currentTagGroupId === tg.id) && (bannerTextBeingEdited !== tg.bannerText) && handleEditTagGroupsInLesson(tg.name, bannerTextBeingEdited, tg?.variableNameForInput || '')
                //     // setEditTagGroup(null);
                // }}
                >



                    <div className={clsx(classes.outlineBox, (isActive) ? classes.bgActive : classes.bgInactive)}>

                        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} > */}


                        {/* <Typography variant='subtitle2' style={{ color: '#315F72' }}>{tg.name}</Typography> */}
                        {/* <Tooltip title="Edit">
                                <EditIcon
                                    className={classes.icon}
                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                    onClick={() => handleEditTagGroup(tg)}
                                />
                            </Tooltip> */}
                        {/* </div>  */}

                        <EditableTextField
                            save={(name) => { (name !== tg.name) && handleEditTagGroup({ ...tg, name: name }) }}
                            style={
                                {
                                    marginTop: '10px',
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: '25px',
                                    width: '250px',
                                    // '& .MuiInputBase-input': {
                                    //     fontSize: "13px",
                                    // },
                                    // '& .MuiFormLabel-root': {
                                    //     fontSize: "13px",
                                    // },
                                    // '& .MuiInput-root': {
                                    //     // borderTopWidth: "0.5px",
                                    //     borderLeftWidth: "0.5px",
                                    //     borderRightWidth: "0.5px",
                                    // },
                                    // '& Mui-focused': {
                                    //     borderTopWidth: "0px",
                                    // }
                                }
                            }
                            multiline={false}
                            initValue={tg.name || ''}
                            placeholder={'Step name or number'}

                        />

                        <Divider />


                        <Typography variant='body1' style={{ color: 'black', marginTop: '5px', marginBottom: '-15px', marginLeft: '0px', fontWeight: 500 }}>BANNER</Typography>


                        <EditableTextField
                            save={(bannerText) => { (bannerText !== tg.bannerText) && handleEditTagGroup({ ...tg, bannerText: bannerText }) }}
                            style={
                                {
                                    marginTop: '10px',
                                    fontSize: "13px",
                                    width: '250px',
                                    // color: '#315F72',
                                    '& .MuiInputBase-input': {
                                        fontSize: "13px",
                                    },
                                    '& .MuiFormLabel-root': {
                                        fontSize: "13px",
                                    },
                                    '& .MuiInput-root': {
                                        // borderTopWidth: "0.5px",
                                        borderLeftWidth: "0.5px",
                                        borderRightWidth: "0.5px",
                                    },
                                    '& Mui-focused': {
                                        borderTopWidth: "0px",
                                    }
                                }
                            }
                            multiline={true}
                            initValue={tg.bannerText || ''}
                            placeholder={'Add a helpful cue'}

                        />
                        {/* <TextField
                            id='outlined-dense-multiline'
                            // label='Banner Text'
                            className={clsx(classes.textField, classes.dense)}
                            // margin='dense'
                            variant='standard'
                            multiline
                            rowsMax='4'
                            // defaultValue={tg.bannerText}
                            // value={currentTagGroupId === tg.id ? bannerTextBeingEdited : tg.bannerText}
                            value={bannerTextBeingEdited}
                            // onFocus={() => focusOnTagGroup(tg)}

                            // onMouseOut={() => console.log(`[st] on m out`)}

                            placeholder={'Add a helpful cue'}
                            onChange={(event: any) => {
                                // setEditTagGroup(tg);
                                // alert(event.target.value)
                                setBannerTextBeingEdited(event.target.value);
                            }}
                            onBlur={() => {
                                console.log(`[st] onblur`)
                                updateBannerText(bannerTextBeingEdited)
                                // handleEditTagGroupsInLesson(editTagGroup.name, bannerTextBeingEdited, editTagGroup?.variableNameForInput || '')
                                //   editTagGroup && handleEditTagGroupsInLesson(editTagGroup.name, bannerTextBeingEdited, editTagGroup?.variableNameForInput || '')
                                //   setEditTagGroup(null);
                            }}
                        /> */}

                        {/* <Collapse
                in={currentTagGroupId === tg.id}
                timeout='auto'
                unmountOnExit> */}

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }} >
                            <Typography variant='body1' style={{ color: 'black', marginTop: '10px', fontWeight: 500 }}>{'TAGS'}</Typography>

                            <Spacer x={3} />
                            {/* <LittleButton title='Update Tags' icons={[]} */}
                            <Tooltip title="Add/ remove tags to this step">
                            <Button style={{ height: '1.8em' }}
                                color='primary'
                                size='small'
                                variant='outlined'
                                onClick={() => {
                                    dispatch(setTagsViewModeSecondary(ViewModeSecondary.LAYERS_TAGS));
                                }} ><span style={{fontWeight: 500 }}>Select Tags</span></Button></Tooltip>
                        </div>
                        <Spacer y={1} />
                        {tg?.tagIds && <List
                            component='div'
                            disablePadding
                        // style={
                        //   currentTagGroupId === tg.id
                        //     ? { backgroundColor: 'rgba(244, 247, 254, 0.9)' }
                        //     : {}
                        // }
                        >
                            {(tg?.tagIds || []).filter((tagId: string) => Array.from(spaceTags.keys()).includes(tagId)
                            ).map((tagId: string) => (
                                <ItemHover key={`itemhover-${tagId}`}>
                                    <ListItem
                                        key={`list-item-${tagId}`}
                                        className={classes.tagsNested}
                                        style={{ cursor: 'pointer' }}
                                        disableGutters
                                        onClick={() => { handleTagInTagGroupClick(tg, tagId) }}>

                                        <ListItemText
                                            className={classes.tagsNestedText}
                                            primary={getTagLabel(tagId, spaceTags)}
                                        />
                                        {spaceMembershipRole && canEdit(spaceMembershipRole) && <ItemHover>
                                            <Tooltip title="Focus on this tag by default for this step">
                                                <div
                                                    className={tg?.jumpToTagId == tagId ? classes.iconActive : classes.iconInactive}
                                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                                    onClick={() => toggleJumpToTagId(tagId, tg)}
                                                >Focus</div>
                                            </Tooltip>
                                        </ItemHover>}
                                    </ListItem>
                                </ItemHover>
                            ))}
                        </List>}
                        <Spacer y={1} />
                        <Divider orientation='horizontal' />

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }} >
                            <Typography variant='body1' style={{ color: 'black', marginTop: '10px', fontWeight: 500 }}>{'3D'}</Typography>
                            <Spacer x={4} />
                            {spaceMembershipRole && canEdit(spaceMembershipRole) &&
                            <Tooltip title="Add/ remove 3D objects to this step">
                                <Button style={{ height: '1.8em' }}
                                    color='primary'
                                    size='small'
                                    variant='outlined'
                                    onClick={() => {
                                        dispatch(setTagsViewModeSecondary(ViewModeSecondary.LAYERS_3D));
                                    }} ><span style={{fontWeight: 500 }}>Select 3D</span></Button></Tooltip>
                                    }
                        </div>
                        <Spacer y={1} />
                        {tg?.tagIds && <List
                            component='div'
                            disablePadding
                        // style={
                        //   currentTagGroupId === tg.id
                        //     ? { backgroundColor: 'rgba(244, 247, 254, 0.9)' }
                        //     : {}
                        // }
                        >
                            {(tg?.tagIds || []).filter((tagId: string) =>
                                Array.from(spaceModels.keys()).includes(tagId)
                            ).map((tagId: string) => (
                                <ItemHover key={`itemhover-${tagId}`}>
                                    <ListItem
                                        key={`list-item-${tagId}`}
                                        className={classes.tagsNested}
                                        style={{ cursor: 'pointer' }}
                                        disableGutters
                                        onClick={() => { handleTagInTagGroupClick(tg, tagId) }}>

                                        <ListItemText
                                            className={classes.tagsNestedText}
                                            primary={getTagLabel(tagId, spaceTags)}
                                        />
                                        {spaceMembershipRole && canEdit(spaceMembershipRole) && <ItemHover>
                                            <Tooltip title="Focus on this 3D object by default for this step">
                                                <div
                                                    className={tg?.jumpToTagId == tagId ? classes.iconActive : classes.iconInactive}
                                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                                    onClick={() => toggleJumpToTagId(tagId, tg)}
                                                >Focus</div>
                                            </Tooltip>
                                        </ItemHover>}
                                    </ListItem>
                                </ItemHover>
                            ))}
                        </List>}

                        <Spacer y={1} />
                        <Divider orientation='horizontal' />

                        <div style={{ width: "100%" }}>
                        <Tooltip title="Ask the user to select an option to proceed. To add logic to different options (or variables), add an interaction!">
                            <FormControl
                                fullWidth
                            >

                                    <InputLabel id="variable-for-input-label" style={{ fontSize: '13px' }}>
                                        {/* <Typography variant='body1' style={{ color: 'black', fontWeight: 500 }}> */}
                                        <span style={{ fontWeight: 500 }}>Ask User for Input</span>
                                        {/* </Typography> */}
                                    </InputLabel>

                                <Select
                                    labelId="variable-for-input-label"
                                    id="variable-for-input-select"
                                    value={tg?.variableNameForInput}
                                    style={{ fontSize: '13px' }}
                                    label="Ask User for Input"
                                    // displayEmpty
                                    onChange={(event) => handleEditTagGroup({ ...tg, variableNameForInput: (event.target.value || '') as string })}
                                >
                                    <MenuItem style={{ fontSize: '13px' }} value={''}><em>None</em></MenuItem>
                                    {variables?.map((v) => <MenuItem style={{ fontSize: '13px' }} value={v.name}>{v.name}</MenuItem>)}
                                    {(!variables || variables.length == 0)
                                        && <Alert severity="info">
                                            You have no variables. Try adding one from Variables in the sidebar. For example: add a variable like Temperature, with allowed values like 68, 70, and 72</Alert>}
                                </Select>
                            </FormControl>
                            </Tooltip>
                            {/* </div>
                            </div> */}

                        </div>
                        {/* <Divider /> */}

                        <Spacer y={1}/>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }} >
                        <Typography variant='body1' style={{ color: 'black', marginTop: '10px', fontWeight: 500 }}>INTERACTIONS ({`${((((tg || {}).logic || {}).conditions || {}).length || 0)})`}</Typography>

                        {/* <div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}> */}
                            {/* <div>{`${((((tg || {}).logic || {}).conditions || {}).length || 0)} `} </div> */}
                            {spaceMembershipRole && canEdit(spaceMembershipRole) &&
                            <Tooltip title="Add/ remove interactions for this step">
                                <Button style={{ height: '1.8em' }}
                                    color='primary'
                                    // size='small'
                                    variant='contained'
                                    onClick={() => {
                                        dispatch(setWorkflowsSidebarOpen(true));
                                    }} > <span style={{fontWeight: 500 }}>Edit</span></Button></Tooltip>
                            }
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <LittleButton title={`Go to Step Start Position`} disabled={!tg?.sweepAndPose?.sid} icons={[
                                <Tooltip title={`Go to the Step Start position`}>
                                    <SendRoundedIcon fontSize='small' htmlColor='black' /></Tooltip>
                            ]} onClickFunc={() => {
                                // goToPose(tg);
                                showTagsAnd3DForStep(tg.id);
                            }} />

                            {spaceMembershipRole && canEdit(spaceMembershipRole) &&
                                <LittleButton title={`${tg?.sweepAndPose?.sid ? `Re-record` : 'Record'} Step start position`} icons={[

                                    <Tooltip title={`${tg?.sweepAndPose?.sid ? `Re-record` : 'Record'} Step start position - current view and zoom will be recorded`}>
                                        <LocationOnRoundedIcon fontSize='small' htmlColor='black' /></Tooltip>

                                ]}
                                    onClickFunc={() => { savePose(tg) }} />}
                            {/* </div>


                        <div style={{ marginTop: '10px' }}> */}
                            <Tooltip title="Move up">

                                <KeyboardArrowUpIcon
                                    className={classes.icon}
                                    // style={{ marginRight: 0, cursor: 'pointer' }}
                                    onClick={() => handleUpTagGroups(tg)}
                                />
                            </Tooltip>
                            <Tooltip title="Move down">

                                <KeyboardArrowDownIcon
                                    className={classes.icon}
                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                    onClick={() => handleDownTagGroups(tg)}
                                />
                            </Tooltip>
                            <Tooltip title="Duplicate this Step">
                                <FileCopyIcon
                                    className={classes.icon}
                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                    onClick={() => onDuplicateTagGroups(tg)}
                                />
                            </Tooltip>
                            {/* <Tooltip title="Edit">
                                    <EditIcon
                                        className={classes.icon}
                                        // style={{ marginRight: 4, cursor: 'pointer' }}
                                        onClick={() => handleEditTagGroups(tg)}
                                    />
                                    </Tooltip> */}
                            <Tooltip title="Delete this step">
                                <DeleteIcon
                                    className={classes.icon + ' deleteTagGroupIcon'}
                                    // style={{ cursor: 'pointer' }}
                                    onClick={() => handleDeleteTagGroups(tg)}
                                />
                            </Tooltip>
                        </div>

                    </div>

                </div>
            </ItemHover>

        </>
    );
};

export default TagGroupCard;

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

export const LittleButton = ({ title, onClickFunc, icons,
    color, width, variant = '', disabled=false
}: { title: any; onClickFunc: any; icons: any[], color?: string, width?: string, variant?: any, disabled?: boolean }) => {
    const theme = useTheme();
    let style: any = {
        height: '30px',
        color: color || theme.palette.secondary.main,
        margin: '2px 1px',
    }
    if (width) {
        style.width = width
    }
    // if(variant='contained'){
    //     color= 'white'

    // }
    // color = color || 'secondary'
    return (<Button
        variant={variant || 'text'}
        disabled={disabled}
        color='secondary'
        size='small'
        style={style}
        type='button'
        disableElevation={true}
        onClick={onClickFunc}
    // {() => {
    //   // showcaseContext.addTagsToEmptyShowcase(Array.from(spaceTags.keys()));
    //   // spaceModels.size > 0 && Simulation.instance.filterVisibleNodes(Array.from(spaceModels.keys()));
    //   handleShowAllSpaceTags();
    // }}
    >
        {icons && icons.length > 0 ? icons : title}
    </Button>)
}