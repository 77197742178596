import React, { useMemo } from 'react';
import {
    IQuizDetails,
    ShowcaseTag,
} from 'types/models/home/HomeApp';
import {
    AttachmentObj
} from 'types/models/apps/ProjectBoard';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import AddTagForm, { ITaskTagForm } from './AddTagForm';
import { tagSidBeingAdded } from './ShowcaseUtils';
interface Props {
    // onAddTag(
    //     tagLabelInput: string,
    //     tagDescInput: string,
    //     annotationType: string,
    //     quizDetails: IQuizDetails,
    //     taskTag: ITaskTagForm | null,
    //     tagHtml: string,
    //     mediaLinkInput: string,
    //     attachments: AttachmentObj[],
    //     showTextToSpeech: boolean,
    // ): void;
    // onEditTag(
    //     tagLabelInput: string,
    //     tagDescInput: string,
    //     annotationType: string,
    //     quizDetails: IQuizDetails,
    //     taskTag: ITaskTagForm | null,
    //     tagHtml: any,
    //     editTagDetails: null | ShowcaseTag,
    //     mediaLinkInput: string,
    //     attachments: AttachmentObj[],
    //     showTextToSpeech: boolean,
    // ): void;
    // setTagSidBeingAdded(val: any): void;
    // tagSidBeingAdded: string;
}

export default function AddTag({
    // onAddTag,
    // tagSidBeingAdded,
    // onEditTag,
    // setTagSidBeingAdded,
}: Props) {

    const openTagForm = useSelector<AppState, boolean>(
        ({ home }) => home.openTagForm
    );

    return useMemo(() =>
    openTagForm ?  <AddTagForm
        // onAddTag={onAddTag}
        // tagSidBeingAdded={tagSidBeingAdded}
        // tagSid={tagSidBeingAdded}
        // onEditTag={onEditTag}
        // setTagSidBeingAdded={setTagSidBeingAdded}
    /> : null
    , [openTagForm])
}