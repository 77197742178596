import { IModel, ResultSuccess, Repo, transformFirestoreTypes } from "./Repo";
import {auth, firestore} from '@crema/services/auth/firebase/firebase';
import { AuthType } from "shared/constants/AppEnums";
import { AuthUser } from "../AuthUser";
import { SpacePermissions, UserSpaceMap } from "../home/HomeApp";
import { DocumentData } from "@firebase/firestore-types";

// import { Collection, getRepository } from 'fireorm';

// @Collection()
// export class UserModel implements IModel{
export interface UserModel extends IModel{
    firstName: string;
    lastName: string;
    email: string;
    authType: AuthType;
    photoURL?: string;
    phone?: string;
    organization?: string;
    spaceMap?: SpacePermissions[];
    permissions?: any;
}

export class UserRepo extends Repo<UserModel> {

    // mapDbToObject(docData: DocumentData, id: string): UserModel {


    //     console.log(`[st] model is ${Object.keys(docData)} `);
    //     let tt = transformFirestoreTypes(docData) as UserModel;

    //     console.log(`[st] model is ${tt}`);

    //     Object.keys(tt).forEach(key => {
    //         const val = docData[key];
    //         console.log(`[st] ${key} = ${val}`);
    //     }        );

    //     let userModel: UserModel = {
    //         id: id,
    //         email: docData.email,
    //         firstName: docData.firstName,
    //         lastName: docData.lastName,
    //         organization: docData.organization,
    //         authType: AuthType.FIREBASE,
    //         createdOn: new Date(docData.createdOn.seconds * 1000),
    //         createdBy: docData.createdBy,
    //         lastUpdatedOn: new Date(docData.lastUpdatedOn.seconds * 1000),
    //         lastUpdatedBy: docData.createdBy,
    //         spaceMap: docData.spaceMap
    //     } //Object.assign({}, dbObject) as UserModel;
    //     userModel.id = id;
    //     userModel.createdOn = new Date(
    //       docData.createdOn?.seconds * 1000,
    //     )
    //     userModel.lastUpdatedOn = new Date(
    //       docData.lastUpdatedOn?.seconds * 1000,
    //     )
    //     return userModel;

    // }

    // dbPath = "Users/dbid";

    collectionName = 'Users';

    getDbPath(id: string){
        console.log(`[st] get dbpath in User ${id}`);
        return `/${this.collectionName}/${id}`;
    }

    // async getAllUsersForSpace(spaceId: string): Promise<UserModel[]>{
    //     firestore.collection(this.collectionName).where('spaceMap')
    // }

    // create(object: AuthUserModel): ResultSuccess<AuthUserModel> {
    //     let user = firestore.doc(User.getDbPath(object.id)).set(object).then(() => {return object}).catch();

    // }//implements AuthUserModel {


    // static create(obj: AuthUserModel): ResultSuccess<AuthUserModel> {
    //     let user = firestore.doc(this.dbPath).set(this).then(() => {return this}).catch();

    // }
    // update(object: AuthUserModel): void | ResultSuccess<AuthUserModel> {
    //     throw new Error("Method not implemented.");
    // }
    // get(id: string): AuthUserModel {
    //     throw new Error("Method not implemented.");
    // }
    // delete(idOrObject: string | AuthUserModel): AuthUserModel {
    //     throw new Error("Method not implemented.");
    // }
    // firstName: string;
    // lastName: string;
    // email: string;
    // authType: AuthType;
    // photoURL?: string | undefined;
    // phone?: string | undefined;
    // organization?: string | undefined;

    // construtor(input: AuthUserModel){
    // }

    // dbPath = `/Users/${this.id}`;

    // static createUser(user: AuthUserModel, id: string): void{
    //     user.id = id;
    //     user.dbPath = `/Users/${user.id}`;

    //     firestore.doc(this.dbPath).set(createUserFields).then(() => )


    // }

    // create(user: AuthUserModel): ResultSuccess<AuthUserModel> {

    //     try {
    //         auth
    //           .createUserWithEmailAndPassword(user.email, password)
    //           .then((data: any) => {
    //             let user: AuthUserModel = {
    //               id: data.id,
    //               email: data.email,
    //               firstName: data.firstName,
    //               lastName: data.lastName,
    //               organization: data.organization,
    //               authType: AuthType.FIREBASE,
    //               createdOn: new Date(),
    //               createdBy: data.id,
    //               lastUpdatedOn: new Date(),
    //               lastUpdatedBy: data.id
    //             }


    //             dispatch(fetchSuccess());
    //             dispatch({type: UPDATE_AUTH_USER, payload: });
    //           })
    //           .catch(error => {
    //             dispatch(fetchError(error.message));
    //           });
    //       } catch (error) {
    //         dispatch(fetchError(error.message));
    //       }
    //     };




    //     // this.
    //     // // auth
    //     // // .createUserWithEmailAndPassword(this.email, this.password)
    //     // // .then(data => {});
    //     // return new ResultSuccess({id: "sfd"}, null);
    //     throw new Error("Method not implemented.");
    // }


}