import { Dispatch } from "react";
import { onAddNewNotification } from "redux/actions";
import { AppActions } from "types";
import emailjs from 'emailjs-com';
// const { messages } = useIntl();

const EMBEDLY_API_KEY = process.env.REACT_APP_EMBEDLY_API_KEY || "75db94366375495297fcc5782b2eeb35";

export default class EmbedlyService {

    public static embedlyExtract = (mediaSrc:string) => {

        const url: any = new URL('https://api.embed.ly/1/extract');
        const params: any = {
            url: mediaSrc,
            key: EMBEDLY_API_KEY,
        };

        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key]),
        );
        
        return fetch(url)
        .then((response)=>{
            console.log('SUCCESS!', response.status, response.text);
            return response.json();
        })
        .then((response) => {
            return response;
        }).catch(error => {
            console.error(error)
        })
    }

    public static embedlyOembed = (mediaSrc:string) => {

        const url: any = new URL('https://api.embedly.com/1/oembed');
        const params: any = {
            url: mediaSrc,
            key: EMBEDLY_API_KEY,
        };

        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key]),
        );
        
        return fetch(url)
        .then((response)=>{
            console.log('SUCCESS!', response.status, response.text);
            return response.json();
        })
        .then((response) => {
            return response;
        }).catch(error => {
            console.error(error)
        })
    }

}