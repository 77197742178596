export const catalogMap = new Map();

export const Chair1Model = {
  "version": "1.0",
  "payload": {
    "objects": [
      {
        "name": "chair1",
        "position": { "x": 0, "y": 0, "z": 0 },
        "rotation": { "x": 0, "y": 0, "z": 0 },
        "scale": { "x": 1, "y": 1, "z": 1 },
        "userData": {
          "type": "InteriorDesignModel",
          "hasPropertiesPanel": true,
          "hasColorProperty": false,
          "initialPlacementStyle": "default",
          "disableScale": true
        },
        "components": [
          {
            "type": "mp.objLoader",
            "inputs": {
              "url": "",
              "materialUrl": "",
              "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
              "localRotation": { "x": -90, "y": 0, "z": 0 },
              "localPosition": { "x": 0, "y": 0, "z": 0 }
            },
            "events": {
              "INTERACTION.CLICK": true,
              "INTERACTION.HOVER": true,
              "INTERACTION.DRAG": true,
              "INTERACTION.DRAG_END": true,
              "INTERACTION.DRAG_BEGIN": true
            }
          }
        ]
      }
    ]
  }
};


export const ObjModel = {
  "version": "1.0",
  "payload": {
    "objects": [
      {
        "name": "obj",
        "position": { "x": 0, "y": 0, "z": 0 },
        "rotation": { "x": 0, "y": 0, "z": 0 },
        "scale": { "x": 1, "y": 1, "z": 1 },
        "userData": {
          "type": "InteriorDesignModel",
          "hasPropertiesPanel": true,
          "hasColorProperty": false,
          "initialPlacementStyle": "default",
          "disableScale": true,
          "overrideUserData" : {
              "disableScale": true
          }
        },
        "components": [
          {
            "type": "mp.objLoader",
            "inputs": {
              "url": "",
              "materialUrl": "",
              "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
              "localRotation": { "x": -90, "y": 0, "z": 0 },
              "localPosition": { "x": 0, "y": 0, "z": 0 }
            },
            "events": {
              "INTERACTION.CLICK": true,
              "INTERACTION.HOVER": true,
              "INTERACTION.DRAG": true,
              "INTERACTION.DRAG_END": true,
              "INTERACTION.DRAG_BEGIN": true
            }
          },
          {
            "type": "mp.objLoader",
            "inputs": {
              "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj",
              "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
              "localRotation": { "x": 0, "y": 0, "z": 0 },
              "localPosition": { "x": 0, "y": 0, "z": 0 }
            },
            "events": {
              "INTERACTION.CLICK": false,
              "INTERACTION.HOVER": false,
              "INTERACTION.DRAG": false
            }
          },
          {
            "type": "mp.loadingIndicator",
            "inputs": {
              "size": { "x": 1, "y": 1, "z": 1 }
            }
          }
        ],
        "bindings": [
          [2, "logo", 1, "objectRoot"]
        ]
      }
    ]
  }
};

export const gltfModel =
{
  "version": "1.0",
  "payload": {
    "objects": [
      {
        "name": "gltf",
        "position": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "rotation": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "scale": {
          "x": 1,
          "y": 1,
          "z": 1
        },
        "userData": {
          "type": "InteriorDesignModel",
          "hasPropertiesPanel": true,
          "hasColorProperty": false,
          "initialPlacementStyle": "default",
          "disableScale": true,
          "overrideUserData" : {
              "disableScale": true
          }
        },
        "components": [
          {
            "type": "mp.gltfLoader",
            "inputs": {
              "url": "",
              "localScale": { "x": 1, "y": 1, "z": 1 },
              "localRotation": { "x": 0, "y": 0, "z": 0 }
            },
            "events": {
              "INTERACTION.CLICK": true,
              "INTERACTION.HOVER": true,
              "INTERACTION.DRAG": true,
              "INTERACTION.DRAG_END": true,
              "INTERACTION.DRAG_BEGIN": true
            }
          },
          {
            "type": "mp.objLoader",
            "inputs": {
              "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj",
              "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
              "localRotation": { "x": 0, "y": 0, "z": 0 },
              "localPosition": { "x": 0, "y": 0, "z": 0 }
            },
            "events": {
              "INTERACTION.CLICK": false,
              "INTERACTION.HOVER": false,
              "INTERACTION.DRAG": false
            }
          },
          {
            "type": "mp.loadingIndicator",
            "inputs": {
              "size": { "x": 1, "y": 1, "z": 1 }
            }
          }
        ],
        "bindings": [
          [2, "logo", 1, "objectRoot"]
        ]
      }
    ]
  }
}

export const DaeModel = {

  "version": "1.0",
  "payload": {
    "objects": [
      {
        "name": "dae",
        "position": { "x": 0, "y": 0, "z": 0 },
        "rotation": { "x": 0, "y": 0, "z": 0 },
        "scale": { "x": 1, "y": 1, "z": 1 },
        "userData": {
          "type": "InteriorDesignModel",
          "hasPropertiesPanel": true,
          "hasColorProperty": false,
          "initialPlacementStyle": "default",
          "disableScale": true,
          "overrideUserData" : {
              "disableScale": true
          }
        },
        "components": [
          {
            "type": "mp.daeLoader",
            "inputs": {
              "url": "/assets/alexDrawer/IKEA-Alex_drawer_white-3D.DAE",
              "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
              "localRotation": { "x": 0, "y": 0, "z": 0 },
              "localPosition": { "x": 0, "y": 0, "z": 0 }
            },
            "events": {
              "INTERACTION.CLICK": true,
              "INTERACTION.HOVER": true,
              "INTERACTION.DRAG": true,
              "INTERACTION.DRAG_END": true,
              "INTERACTION.DRAG_BEGIN": true
            }
          },
          {
            "type": "mp.objLoader",
            "inputs": {
              "url": "https://storage.googleapis.com/virtual-tc.appspot.com/3d/obj/Loading Icon/icon.obj",
              "localScale": { "x": 0.01, "y": 0.01, "z": 0.01 },
              "localRotation": { "x": 0, "y": 0, "z": 0 },
              "localPosition": { "x": 0, "y": 0, "z": 0 }
            },
            "events": {
              "INTERACTION.CLICK": false,
              "INTERACTION.HOVER": false,
              "INTERACTION.DRAG": false
            }
          },
          {
            "type": "mp.loadingIndicator",
            "inputs": {
              "size": { "x": 1, "y": 1, "z": 1 }
            }
          }
        ],
        "bindings": [
          [2, "logo", 1, "objectRoot"]
        ]
      }
    ]
  }

}

catalogMap.set(Chair1Model.payload.objects[0].name, (Chair1Model));
catalogMap.set(gltfModel.payload.objects[0].name, (gltfModel));
catalogMap.set(ObjModel.payload.objects[0].name, (ObjModel));
catalogMap.set(DaeModel.payload.objects[0].name, (DaeModel));

export default catalogMap;