import React, {useContext, useState} from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Scrollbar from '../../../../../@crema/core/Scrollbar';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layoutBox: {
      minWidth: '320px',
      maxWidth: '320px',

      height: '100%',
      // minHeight: 'calc(91vh)',
      // maxHeight: 'calc(91vh)',
      // overflow: 'scroll',
      // boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.12)',
      // border: '1px solid black',
      transition: 'all 0.5s ease',
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
        maxWidth: '100%',
        width: '100%',
      },
    },
  }),
);

interface Props {
  children?: React.ReactNode;
  width?: string
}

export default function Layout({children, width}: Props) {
  const classes = useStyles();
  // console.log(`[st] width is ${width}`);

  return (
    <Scrollbar>
      <div 
      className={'sidebar ' + classes.layoutBox} 
      style={width ? {maxWidth: width, minWidth: width} : {}}>
          {children}
      </div>
    </Scrollbar>
  )
}
