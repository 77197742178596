import React, { useEffect } from 'react';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bgTransparent: {
    backgroundColor: 'transparent',
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: "#315f72",
      color: 'white'
    },
    '& .MuiToggleButton-root': {
      color: "white",
      height: "0.9em",
      textShadow: "0 0 5px rgb(0 0 0)"

    },
    marginBottom: '10px',
  },
  btn: {
    '& .MuiToggleButton-root': {
      // color: "white",
      // height: "0.85em",
      // textShadow: "0 0 5px rgb(0 0 0)"
    }
  },
}));
export default function VariablesButtonBar(props: any) {
  const [value, setValue] = React.useState('');
  const [openSelect, setOpenSelect] = React.useState(false);

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
    props.handleChange(newValue);
  };
  const classes = useStyles(props);

  useEffect(() => { setValue(props.initialValue) }, [props])
  return (console.log(`[st] rendering VariablesButtonBar select is: ${openSelect} `),
    // <Grid container spacing={2} direction='column' alignItems='center' alignContent='flex-start' className={classes.gridContainer}>
    // <Grid item className={classes.gridContainerItem}>
    <div style={{
      // maxWidth:"500px", overflow: "wrap",
    }}
    // onMouseOver={() => setOpenSelect(true)}
    // onMouseLeave={() => setOpenSelect(false)}
    // onMouseOut={() => setOpenSelect(false)}

    >

      {/* <Select
        labelId="variable-for-input-label"
        id="variable-for-input-select"
        value={value}
        style={{ fontSize: '13px' }}
        label="Ask User for Input"
        // open={openSelect}
        // displayEmpty
        onChange={(e) => {
          handleChange(e, e.target.value as string);
        }} //TODO check
      >
        <MenuItem style={{ fontSize: '13px' }} value={''}><em>None</em></MenuItem>
        {props.children.map((v: any, index: any) =>
          <MenuItem style={{ fontSize: '13px' }} key={index} value={v}>
            <ToggleButton key={index} value={v} className={classes.btn}>
            {v}
          </ToggleButton>
          </MenuItem>)}
      </Select>  */}
      {/* //TODO complete this, remove scrollbar */}
      <ToggleButtonGroup
          className={classes.bgTransparent}
          size={props.size}
          value={value}
          exclusive
          onChange={handleChange}>
          {props.children.map((item: any, index: any) =>
          <ToggleButton key={index} value={item} className={classes.btn}>
            {item}
          </ToggleButton>)}
        </ToggleButtonGroup>
    </div>
    //   </Grid>

    // </Grid>
  );
}