import React, { useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import AppTableContainer from '@crema/core/AppTableContainer';
import { AppState } from 'redux/store';
import { ShowcaseTag } from 'types/models/home/HomeApp';
import {linkSpaceCustomDataFieldToTag} from 'redux/actions/Home';
import { fetchError } from 'redux/actions';

const useStyles = makeStyles(theme => ({
  borderBottomClass: {
    borderBottom: '0 none',
  },
}));

interface DataTableProps {
  tagsTableData: any[];
  mpSdk: any;
}

const DataTable: React.FC<DataTableProps> = ({ tagsTableData, mpSdk }) => {
  const classes = useStyles();
  const currentSpaceId = useSelector<AppState, string >(({ home }) => home.currentSpace?.id || '');
  const currentCustomData = useSelector<AppState, any>(({ home }) => home.currentCustomData);
  const dispatch = useDispatch();

  const linkTag = useCallback((newTag) => {
    if (!currentSpaceId) {
      dispatch(fetchError("Space was not loaded"));
      return;
    }
    if (!currentCustomData) {
      dispatch(fetchError("No custom data in firebase"));
      return;
    }

    // Add tagId to CustomData Fields after linked a tag
    dispatch(linkSpaceCustomDataFieldToTag(currentSpaceId, currentCustomData.id, newTag));
  }, [currentSpaceId, currentCustomData]);

  return (
    (console.log("rendering TableSidebar"), <AppTableContainer>
      {tagsTableData.length > 0 && (
        <Table className='table' stickyHeader>
          <TableHead className={classes.borderBottomClass}>
            <TableHeading headers={Object.keys(tagsTableData[0])} />
          </TableHead>
          <TableBody className={classes.borderBottomClass}>
            {tagsTableData && tagsTableData.map((row, index) => (
              <TableItem tag={row} key={`tag-itme-${index}`} mpSdk={mpSdk} linkTag={linkTag} />
            ))}
          </TableBody>
        </Table>
      )}
      {tagsTableData.length === 0 && (
        <h5 style={{ color: 'rgb(189, 189, 189)' }}>No tags data</h5>
      )}
    </AppTableContainer>)
  );
};

export default DataTable;