import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AppContext from '../../../@crema/utility/AppContext';
import AppContextPropsType from '../../../types/AppContextPropsType';

import { ThemeMode } from 'shared/constants/AppEnums';
import { initialUrl } from 'shared/constants/AppConst';

const useStyles = makeStyles(() => ({
  logoRoot: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  logo: {
    height: 36,
    marginRight: 10,
  },
}));

const AppLogo = () => {
  const { themeMode } = useContext<AppContextPropsType>(AppContext);
  const classes = useStyles();

  return (
    <Box className={classes.logoRoot}>
      <Hidden smUp>
        <Link to={initialUrl}>
          <img
            className={classes.logo}
            src={
              themeMode === ThemeMode.DARK
                ? '/assets/images/st.png'
                : '/assets/images/st.png'
              // themeMode === ThemeMode.DARK
              //   ? '/assets/images/logo-white.png'
              //   : '/assets/images/logo.png'
            }
            alt='logo'
          />
        </Link>
      </Hidden>
      <Hidden xsDown>
        <Link to={initialUrl}>
          <img
            className={classes.logo}
            src={
              themeMode === ThemeMode.DARK
                ? '/assets/images/st.png'
                : '/assets/images/st.png'
              // themeMode === ThemeMode.DARK
              //     ? '/assets/images/logo-white-with-name.png'
              //     : '/assets/images/logo-with-name.png'
            }
            alt='logo'
          />
        </Link>
      </Hidden>
    </Box>
  );
};

export default AppLogo;
