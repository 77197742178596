import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { onCreateTask } from '../../../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { GridContainer, Scrollbar } from '../../../../../../@crema';
import { useAuthUser } from '../../../../../../@crema/utility/AppHooks';
import IntlMessages from '../../../../../../@crema/utility/IntlMessages';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, Tooltip, Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { Button, Divider, Fab, Grid, makeStyles, TextField, IconButton } from '@material-ui/core';
import { Fonts } from '../../../../../../shared/constants/AppEnums';
import { TransitionProps } from '@material-ui/core/transitions';
import { useIntl } from 'react-intl';
import VariablesForm from './VariablesForm';
import { CremaTheme } from '../../../../../../types/AppContextPropsType';
import { AppState } from 'redux/store';
import { SET_SPACE_DATA, SET_VARIABLE_VALUE } from 'types/actions/Home.action';
import { firestore } from '@crema/services/auth/firebase/firebase';
import CancelIcon from '@material-ui/icons/Cancel';
import { VariableType, VariableTypeAllowedValues, getLabelForVariableType } from './VariableTypes';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { SpaceData } from 'types/models/home/HomeApp';

const useStyles = makeStyles((theme: CremaTheme) => ({
  card: {
    height: 'auto',
    padding: '20px 32px',
  },
  scrollRoot: {
    paddingTop: 4,
    // display: "flex",
    // max-height: calc(100% - 64px);
    // flexDirection: "column",
    // alignContent: "center",
    // alignItems: "center"
    // height: 520,
  },
  dialogBox: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 900,
      width: '100%',
    },
  },
  textField: {
    // width: '100%',
    fontWeight: Fonts.LIGHT,
    margin: theme.spacing(1),
    marginBottom: 20,
    marginTop: 16,
    // marginLeft: 5,
    // marginRight: 5
  },

  selectFormControl: {
    display: 'flex',
    minWidth: "20%",
    color: "black",
    '& .MuiFormLabel-root': {
      color: "black",
      marginLeft: 16,
      marginTop: 8,
    }
  },

  selectBox: {
    // width: '100%',
    minWidth: '20%',
    display: 'flex',
    margin: theme.spacing(1),
    fontWeight: Fonts.LIGHT,
    marginBottom: 20,
    marginTop: 16,
    // marginLeft: 5,
    // marginRight: 5
  },

  selectMenuItem: {
    display: 'flex',
    color: "black",
    // fontWeight: Fonts.LIGHT,
  },

  divider: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.up('xl')]: {
      marginTop: 32,
      marginBottom: 32,
    },
  },
  button: {
    // backgroundColor: theme.palette.primary.main,
    fontWeight: Fonts.LIGHT,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    width: 192,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.contrastText,
    },
  },
  btnRoot: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  saveBtnBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBotton: "40px"
  }
}));




const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface VariablesProps {
  isVariablesOpen: boolean;
  onCloseVariables: () => void;
  // variables: any[];
}

const Variables: React.FC<VariablesProps> = ({
  isVariablesOpen,
  onCloseVariables,
}) => {
  const dispatch = useDispatch();

  const user = useAuthUser();
  const currentSpace = useSelector<AppState, SpaceData | undefined>(({ home }) => home.currentSpace);
  const [localVariablesList, setLocalVariablesList] = useState<any[]>([]);
  // const [taskLabels, setTaskLabels] = useState<LabelObj[]>([]);

  const classes = useStyles();

  const { messages } = useIntl();

  useEffect(() => {
    setLocalVariablesList((currentSpace && currentSpace.variables) || []);
  }, []);


  const onAddNewVariable = () => {
    localVariablesList.push({ name: '', values: '', type: '' });
    setLocalVariablesList([...localVariablesList]);
  }

  const handleDeleteClick = async (
    event: React.MouseEvent,
    index: number
  ) => {
    let res = window.confirm(`Are you sure you want to delete variable: ${localVariablesList[index].name}?`);
    if (res) {
      localVariablesList.splice(index, 1);
      setLocalVariablesList([...localVariablesList]);
      //Simulation.instance.propertiesPanel.deleteItem(typeOfDropDown, index);
    }

  };

  console.log(`[st] rendering Variables`);
  return (
    <Dialog
      open={isVariablesOpen}
      onClose={() => onCloseVariables()}
      aria-labelledby='variables'
      TransitionComponent={Transition}
      aria-describedby='Add your system variables here'
      className={classes.dialogBox}>
      <Box pt={4} px={4} mb={10} >
        <Typography variant='h5'

        // fontWeight={Fonts.LIGHT}
        >
          Variables
        </Typography>
      </Box>

      <Scrollbar className={classes.scrollRoot} >
        <Box justifyContent="center" alignItems="center" flexDirection="column" display="flex"
        // display={{xs: 'block', sm: 'block', md: 'inline', lg: 'inline'}}
        // style={{ display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center" }}
        // py={5} px={{ xs: 5, lg: 8, xl: 10 }}
        >


          {localVariablesList.map((variable: any, index: number) => (
            < Box key={index} component='div'
              display='flex'
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              mb={5}
            >
              {/* <GridContainer>
                <Grid item xs={12} sm={6} md={3}> */}

              {/* <Box display={{ xs: 'block', sm: 'block', md: 'inline', lg: 'inline' }}> */}
              <TextField
                style={{
                  // width: "30%",
                  // paddingRight: "1%"
                }}
                className={classes.textField}
                variant='outlined'
                label="Variable Name"
                name='variableName'
                value={variable.name}
                onChange={event => {
                  // localVariablesList[index].name = event.target.value;
                  variable.name = event.target.value;
                  setLocalVariablesList([...localVariablesList]);
                }}
              />
              {/* </Box> */}
              {/* </Grid> */}

              {/* <Grid item xs={12} sm={6} md={3}> */}
              {/* <Box style={{
                // width: "30%",
                paddingRight: "1%"
              }} */}
              {/* // width={1} clone
              > */}
              {/* <Box display={{ xs: 'block', sm: 'block', md: 'inline', lg: 'inline' }}> */}
              <TextField

                className={classes.textField}
                variant='outlined'
                label="Allowed Values"
                name='variableValues'
                value={variable.values}
                onChange={event => {
                  // localVariablesList[index].values = event.target.value;
                  variable.values = event.target.value;
                  setLocalVariablesList([...localVariablesList]);
                }}
              // onChange={value => setFieldValue('variableValues', value)}
              />
              {/* </Box> */}
              {/* </Box>
              <Box style={{
                // width: "30%",
                paddingRight: "1%"
              }} */}
              {/* // width={1} clone
              > */}

              {/* <Box display={{ xs: 'block', sm: 'block', md: 'inline', lg: 'inline' }}> */}
              {/* <TextField

                  className={classes.textField}
                  variant='outlined'
                  label="Allowed Values"
                  name='variableValues'
                  value={variable.values}
                  onChange={event => {
                    // localVariablesList[index].values = event.target.value;
                    variable.values = event.target.value;
                    setLocalVariablesList([...localVariablesList]);
                  }}
                // onChange={value => setFieldValue('variableValues', value)}
                /> */}
              {/* </Box>/ */}
              {/* </Box> */}
              {/* </Grid> */}
              {/* </GridContainer> */}
              <FormControl className={classes.selectFormControl}>
                <InputLabel id="type-select-label">Type</InputLabel>
                <Select
                  labelId="type-select-label"
                  className={classes.selectBox}
                  variant='outlined'
                  label="Type"
                  name='variableType'
                  value={variable.type}
                  onChange={event => {
                    // localVariablesList[index].values = event.target.value;

                    if (variable.type !== event.target.value) {
                      variable.type = event.target.value;
                      setLocalVariablesList([...localVariablesList]);

                      if (variable.type === VariableType.booleanVariableType) {
                        variable.values = VariableTypeAllowedValues.getAllowedTypes(variable.type as VariableType).toString();
                      }
                    }
                  }}
                // onChange={value => setFieldValue('variableValues', value)}
                >
                  {Object.values(VariableType).map((val: any) =>
                    (val !== 'List Difference' || currentSpace?.did?.includes('q4NzdpbTsj6'))  //TODO: FIXME HARDCODED space id for list difference
                    && <MenuItem key={val} className={classes.selectMenuItem} value={val}>{getLabelForVariableType(val)}</MenuItem>
                    )}
                </Select>



              </FormControl>
              <Checkbox
                // edge='start'
                checked={variable.allowControl}
                // tabIndex={-1}
                onChange={(event) => {
                  variable.allowControl = event.target.checked
                  setLocalVariablesList([...localVariablesList]);

                }}
                // disableRipple
                inputProps={{ 'aria-labelledby': "Show?" }}
              /> Show in Control Panel?
              {/* <Box px={2} py={6}> */}
              <Tooltip arrow title="Delete">
                <IconButton color="primary" size='small' onClick={(e) => { handleDeleteClick(e, index) }} >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
              {/* </Box> */}
            </Box>))}

          {/* <Fab
            className={classes.btnRoot}
            onClick={() => onAddNewVariable()}>
            Add New
          </Fab> */}


          <Button
            variant='contained'
            color='primary'
            size='small'
            style={{ height: '90%', marginBottom: '10px' }}
            type='button'
            onClick={onAddNewVariable}>
            Add Variable
          </Button>

          <Divider className={classes.divider} />
        </Box>
        <Box px={8} py={4} className={classes.saveBtnBox}>
          <Button
            className={classes.btnRoot}
            color='secondary'
            variant='contained'
            // disabled={isSubmitting}
            // type='submit'
            onClick={async () => {
              if (currentSpace) {
                currentSpace.variables = localVariablesList;
                let toSaveVariableList = localVariablesList.map(x => {
                  return {
                    "name": x.name,
                    "type": x.type,
                    "values": x.values,
                    "allowControl": x.allowControl,
                  };
                })

                console.log(toSaveVariableList);
                await firestore.doc(`Spaces/${currentSpace.id}`).update({ variables: toSaveVariableList });
                dispatch({ type: SET_SPACE_DATA, payload: currentSpace });
                Simulation.instance.updateVariablesState(toSaveVariableList, undefined, undefined, undefined);
                onCloseVariables();
              }

            }}
          >
            <IntlMessages id='common.save' />
          </Button>
        </Box>
      </Scrollbar>
    </Dialog >
  );
};

export default Variables;
