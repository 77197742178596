import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import PanelInputField, { PanelInputLabel } from './PanelInputField';
import { IoTSpecialProperties, NodeDataProperties, PropertiesPanelDropDownType, PropertiesPanelMode, PropertiesPanelUIData, UserDataGizmoMinorMods, UserDataProperties, UserDataTypes } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
import { ChangeBooleanPropertyPassThrough, ChangeGenericActionOutcomeTextPassThrough, ChangeNodeColorPassThrough, ChangeTextPanelTextPassThrough, ChangeTextPassThrough, ChangeTextPassWithParamThrough } from 'mp/core/craEngine/Tools/InteropTypes/InteropClasses';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import CancelIcon from '@material-ui/icons/Cancel';
import { VariableTypeAllowedValues } from '../../Sidebar/Variables/VariableTypes';
import { EventActionOutcome, VariableValueTriggerPair, VariableLogicType, VariableValueActionPair, TriggerActionOutcome, ActionType } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanelBehaviorActions';
import { setSpaceViewDispatchHook } from '../../Sidebar/TagSidebar/Tags/ShowcaseUtils';
import { useAuthUser, useUserAdmin } from '@crema/utility/AppHooks';
import { UseCase } from 'shared/constants/AppConst';

const useStyles = makeStyles((theme) => ({

    propPanelBox: {
        // backgroundColor: '#f4f7fecc'
        backgroundColor: 'white'
    },
    panelSubTitle: {
        paddingTop: "10px",
        paddingLeft: 8,
        marginBottom: 10
    },
    behaviorSubTitle: {
        fontWeight: 500,
        paddingTop: "10px",
        paddingLeft: 8,
        marginBottom: 10,
        fontSize: "13px",
        color: "black"
    },
    behaviorPrompt: {
        fontWeight: 500,
        paddingTop: "10px",
        paddingLeft: 8,
        marginBottom: 10,
        fontSize: "13px",
        color: "#1a9e1c"
    }
}));

interface Props {
    propertiesPanelUIData: PropertiesPanelUIData
    /*
    setPropertiesPanelVisible(x:boolean):void;
    propertiesPanelVisible:boolean*/
}

const controlVarsMap = new Map();
const PropertiesPanel: React.FC<Props> = ({
    propertiesPanelUIData
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const variableValues = useSelector<AppState, any[]>(({ home }) => home.variableValues);
    const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
    const spacePrimaryUseCase = useSelector<AppState, UseCase | null>(({ home }) => home.currentSpace?.primaryUseCase || null);


    //const [propertiesPanelUIData, setPropertiesPanelUIData] = React.useState<PropertiesPanelUIData>(new PropertiesPanelUIData());
    // const [varTriggerList, setVarTriggerList] = useState<VarTriggerConfig[]>(nodeUserData[UserDataProperties.varTriggers] || []);
    const colorReference = React.useRef(null);
    const borderColorReference = React.useRef(null);
    const [accordionExpanded, setAccordionExpanded] = React.useState(true);
    const isUserAdmin = useUserAdmin();

    setSpaceViewDispatchHook(dispatch);

    let propertiesPanelMode = PropertiesPanelMode.None;

    //currentNode = spaceModels.get(propertiesPanelUIData.currentNodeID);
    propertiesPanelMode = propertiesPanelUIData.propertiesPanelMode;

    let nodeUserData = propertiesPanelUIData.userData; //!currentNode ? {} : currentNode[UserDataProperties.userData];

    let isNodeImageComponent: boolean = false;

    if (nodeUserData[UserDataProperties.type] === "imageRenderer") {
        isNodeImageComponent = true;
    }

    let varTriggerList: VariableValueTriggerPair[] = nodeUserData[UserDataProperties.varTriggers] || [];
    let varActionList: VariableValueTriggerPair[] = nodeUserData[UserDataProperties.varActions] || []; //uses same type as trigger, not a mistake

    const onColorChanged = (colorHex: string, alpha: number) => {
        Simulation.instance.propertiesPanel.changeColorOfNode(new ChangeNodeColorPassThrough(colorHex, alpha, false));
    }

    const onBorderColorChanged = (colorHex: string, alpha: number) => {
        Simulation.instance.propertiesPanel.changeColorOfNode(new ChangeNodeColorPassThrough(colorHex, alpha, true));
    }

    const onTextLabelChanged = (param: UserDataProperties, param2: string = "") => async (e: any) => {
        //console.log(e.target._valueTracker.getValue(1));
        switch (param) {
            case UserDataProperties.textProperty:
                Simulation.instance.propertiesPanel.changeTextOfNode(new ChangeTextPanelTextPassThrough(e, null));
                //nodeUserData[UserDataProperties.nameToShow] = e;
                break;
            case UserDataProperties.fontSize:
                Simulation.instance.propertiesPanel.changeTextOfNode(new ChangeTextPanelTextPassThrough(null, e));
                break;
            case UserDataProperties.nameToShow:
                Simulation.instance.propertiesPanel.changeNameOfNode(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.newModelURL:
                Simulation.instance.propertiesPanel.changeModelURLOfNode(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.localPosition:
                Simulation.instance.propertiesPanel.changeLocalPositionOfNodeWithParam(new ChangeTextPassWithParamThrough(e, param2));
                break;
            case UserDataProperties.rotationAxis:
                Simulation.instance.propertiesPanel.changeRotationAxisOfNodeWithParam(new ChangeTextPassWithParamThrough(e, param2));
                break;
            case UserDataProperties.rotationRange:
                Simulation.instance.propertiesPanel.changeRotationRangesOfNodeWithParam(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.borderRadius:
                Simulation.instance.propertiesPanel.changeBorderRadius(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.borderSize:
                Simulation.instance.propertiesPanel.changeBorderSize(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.textureSource:
                Simulation.instance.propertiesPanel.changeImageTextureSource(new ChangeTextPassThrough(e));
                break;
        }

        Simulation.instance.propertiesPanel.saveLastNode(true);
    }



    const onTextLabelChangedEventAction = (param: EventActionOutcome, index: number) => async (e: any) => {
        Simulation.instance.propertiesPanel.changeParameterOfEventAction(new ChangeGenericActionOutcomeTextPassThrough(e, index));
        Simulation.instance.propertiesPanel.saveLastNode(true);
    }

    const onTextLabelChangedTriggerAction = (param: TriggerActionOutcome, index: number) => async (e: any) => {
        Simulation.instance.propertiesPanel.changeParameterOfTriggerAction(new ChangeGenericActionOutcomeTextPassThrough(e, index));
        Simulation.instance.propertiesPanel.saveLastNode(true);
    }

    const onTextLabelNodeDataChanged = (name: NodeDataProperties) => async (e: any) => {
        //console.log(e.target._valueTracker.getValue());
        let validChange: boolean = false;
        switch (name) {
            case NodeDataProperties.scale:
                let newValue = e;

                if (!isNaN(newValue) && !isNaN(parseFloat(newValue))) {
                    if (propertiesPanelUIData.nodePointer) {
                        console.log(propertiesPanelUIData.nodePointer);
                        Simulation.instance.propertiesPanel.setUniformScaleOnNode(propertiesPanelUIData.nodePointer, newValue);
                    }

                    validChange = true;
                }
                //Simulation.instance.propertiesPanel.changeTextOfNode(new ChangeTextPassThrough(e.target._valueTracker.getValue(), null));
                break;
        }
        if (validChange) {
            Simulation.instance.propertiesPanel.saveLastNode(true);
        }
    }

    const handleDeleteClick = async (
        typeOfDropDown: PropertiesPanelDropDownType,
        event: React.MouseEvent,
        index: number
    ) => {
        Simulation.instance.propertiesPanel.deleteItem(typeOfDropDown, index);
    };

    const handleDropDownChange = async (
        typeOfDropDown: PropertiesPanelDropDownType,
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        index: number,
        paramIndex: number = 1
    ) => {
        switch (typeOfDropDown) {
            case PropertiesPanelDropDownType.ActionTypeDropDown:
                if (Simulation) {
                    Simulation.instance.propertiesPanel.dropDownChangeEvents<ActionType>(typeOfDropDown, event.target.value as ActionType, index);
                }
                break;
            case PropertiesPanelDropDownType.TriggerActionTypeDropDown:
                if (Simulation) {
                    Simulation.instance.propertiesPanel.dropDownChangeEvents<ActionType>(typeOfDropDown, event.target.value as ActionType, index);
                }
                break;
            case PropertiesPanelDropDownType.SystemVariablesTriggerDropDown:
                if (Simulation) {
                    if (paramIndex == 1) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueTriggerPair>(typeOfDropDown, { name: event.target.value, value: "", logic: VariableLogicType.blank } as VariableValueTriggerPair, index);
                    } else if (paramIndex == 2) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueTriggerPair>(typeOfDropDown, { name: "", value: event.target.value, logic: VariableLogicType.blank } as VariableValueTriggerPair, index);
                    } else if (paramIndex == 3) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueTriggerPair>(typeOfDropDown, { name: "", value: "", logic: event.target.value as VariableLogicType } as VariableValueTriggerPair, index);
                    }

                }
                break;
            case PropertiesPanelDropDownType.SystemVariablesActionDropDown:
                if (Simulation) {
                    if (paramIndex == 1) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueActionPair>(typeOfDropDown, { name: event.target.value, value: "" } as VariableValueActionPair, index);
                    } else if (paramIndex == 2) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueActionPair>(typeOfDropDown, { name: "", value: event.target.value } as VariableValueActionPair, index);
                    }

                }
                break;
            case PropertiesPanelDropDownType.InputSource1_DropDown:
                if (Simulation) {
                    //console.log(event.target);
                    Simulation.instance.propertiesPanel.dropDownChangeEvents<string>(typeOfDropDown, event.target.value as string, 0);
                }
                break;
        }

    };

    const onCheckboxPropertiesChange = (name: UserDataProperties) => async (e: any) => {
        /*
        switch (name) {
            case UserDataProperties.hasBorderProperty:
                nodeUserData[UserDataProperties.hasBorderProperty] = !nodeUserData[UserDataProperties.hasBorderProperty];
                await Simulation.instance.propertiesPanel.setBooleanPropertyOfNode(new ChangeBooleanPropertyPassThrough(nodeUserData[UserDataProperties.hasBorderProperty], UserDataProperties.hasBorderProperty));
                break;
        }*/

        nodeUserData[name] = !nodeUserData[name];
        if (Simulation.instance.propertiesPanel.setBooleanPropertyOfNode(
            new ChangeBooleanPropertyPassThrough(nodeUserData[name], name)
        )) {
            Simulation.instance.propertiesPanel.saveLastNode(true);
        }
    }

    const authUser = useAuthUser();
    const getModelConfigFields = () => {

        return (
            <Grid container alignItems="center"><Grid item xs={6}>
                <PanelInputLabel props={{ label: "Model URL:" }} />
            </Grid>
                <Grid item xs={6}>
                    <PanelInputField props={{
                        // label: "Model URL2:",
                        type: "textInput",
                        text: nodeUserData[UserDataProperties.newModelURL],
                        onChange: onTextLabelChanged(UserDataProperties.newModelURL)
                    }} />
                </Grid>

                <Grid item xs={6}>
                    <PanelInputLabel props={{ label: "Pivot:" }} />
                </Grid>
                <Grid item xs={2}>
                    <PanelInputField props={{
                        label: "Pivot:", type: "textInput",
                        text: nodeUserData[UserDataProperties.localPosition].x,
                        onChange: onTextLabelChanged(UserDataProperties.localPosition, 'x')
                    }} />
                </Grid>
                <Grid item xs={2}>
                    <PanelInputField props={{
                        label: "Pivot:", type: "textInput",
                        text: nodeUserData[UserDataProperties.localPosition].y,
                        onChange: onTextLabelChanged(UserDataProperties.localPosition, 'y')
                    }} />
                </Grid>
                <Grid item xs={2}>

                    <PanelInputField props={{
                        label: "Pivot:", type: "textInput",
                        text: nodeUserData[UserDataProperties.localPosition].z,
                        onChange: onTextLabelChanged(UserDataProperties.localPosition, 'z')
                    }} />
                </Grid>

                <Grid item xs={6}>
                    <PanelInputLabel props={{ label: "Axis:" }} />
                </Grid>
                <Grid item xs={2}>
                    <PanelInputField props={{
                        label: "Axis:", type: "textInput",
                        text: nodeUserData[UserDataProperties.rotationAxis].x,
                        onChange: onTextLabelChanged(UserDataProperties.rotationAxis, 'x')
                    }} />
                </Grid>
                <Grid item xs={2}>
                    <PanelInputField props={{
                        label: "Axis:", type: "textInput",
                        text: nodeUserData[UserDataProperties.rotationAxis].y,
                        onChange: onTextLabelChanged(UserDataProperties.rotationAxis, 'y')
                    }} />
                </Grid>
                <Grid item xs={2}>

                    <PanelInputField props={{
                        label: "Axis:", type: "textInput",
                        text: nodeUserData[UserDataProperties.rotationAxis].z,
                        onChange: onTextLabelChanged(UserDataProperties.rotationAxis, 'z')
                    }} />
                </Grid>

                <Grid item xs={6}>
                    <PanelInputLabel props={{ label: "Rotation Ranges:" }} />
                </Grid>
                <Grid item xs={6}>
                    <PanelInputField props={{
                        label: "Rotation Ranges:", type: "textInput",
                        text: nodeUserData[UserDataProperties.rotationRange],
                        onChange: onTextLabelChanged(UserDataProperties.rotationRange)
                    }} />
                </Grid></Grid>)
    }


    let showColorProperty = (UserDataProperties.hasColorProperty in nodeUserData && nodeUserData[UserDataProperties.hasColorProperty]);
    let showBorderColorProperty = (UserDataProperties.hasBorderProperty in nodeUserData && nodeUserData[UserDataProperties.hasBorderProperty]);
    let borderColorLabelText = "";
    switch (nodeUserData[UserDataProperties.type]) {
        case UserDataTypes.thermostatNest:
            borderColorLabelText = "Main Color";
            break;
        default:
            borderColorLabelText = "Border Color";
            break;
    }

    let inputSourceForNest = null;

    if (nodeUserData[UserDataProperties.type] === UserDataTypes.thermostatNest) {

        let clickAnObjectTextPromptElement = null;

        if (propertiesPanelMode === PropertiesPanelMode.Editing_IOT) {
            clickAnObjectTextPromptElement = <Typography variant="body1" className={classes.behaviorPrompt}> Click an IoT in the scene!</Typography>
        }


        let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.updateInputSourceForNestNode(nodeUserData, variableValues);
        //VariableTypeAllowedValues.updateInputSourceForNestNode(nodeUserData, variableValues);

        let otherNameToShow1 = "<None>";
        let otherNameToShow2 = "<None>";
        if (spaceModels.size > 0) {
            let node = spaceModels.get(nodeUserData[IoTSpecialProperties.diff1]);
            let node2 = spaceModels.get(nodeUserData[IoTSpecialProperties.diff2]);
            if (node) {
                otherNameToShow1 = node.userData[UserDataProperties.nameToShow];
            }

            if (node2) {
                otherNameToShow2 = node2.userData[UserDataProperties.nameToShow];
            }
        }

        inputSourceForNest =
            <Grid container alignItems="center">
                <Grid container alignItems="center">
                    <Grid item xs={7}>
                        <PanelInputField props={{
                            label: "System variable (csv)", type: "select", values: allowedSystemVariablesAsInputSourceForNest, value: nodeUserData[UserDataProperties.inputSource1],
                            handleChange: (e: any) => {
                                //console.log(e)
                                handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
                            }
                        }} />
                    </Grid>
                </Grid>

                {/*
            <Grid container alignItems="center">
            <Grid item xs={3}>
                <PanelInputLabel props={{ label: "Difference" }} />
            </Grid>
            </Grid>
            <Grid container alignItems="center">
            <Grid item xs={4}>
                <PanelInputField props={{ label: "Action", type: "readOnlyText", text: otherNameToShow1, onFocus: (e: any) => { Simulation.instance.propertiesPanel.editNodeIOTDiffSource(1); } }} />
            </Grid>
            <Fab color='inherit' size='small' onClick={(e) => { Simulation.instance.propertiesPanel.deleteNodeIOTDiffSource(1); }}>
                <CancelIcon />
            </Fab>
            <Grid item xs={4}>
                <PanelInputField props={{ label: "Action", type: "readOnlyText", text: otherNameToShow2, onFocus: (e: any) => { Simulation.instance.propertiesPanel.editNodeIOTDiffSource(2); } }} />
            </Grid>
            <Fab color='inherit' size='small' onClick={(e) => { Simulation.instance.propertiesPanel.deleteNodeIOTDiffSource(2); }}>
                <CancelIcon />
            </Fab>

            {clickAnObjectTextPromptElement}
            </Grid>*/}
            </Grid>
    }

    let bindingSourceForRotateToggle = null;

    if (propertiesPanelUIData.hasRotateToggle) {

        let allowedVariablesForRotateToggle = VariableTypeAllowedValues.updateInputSourceForGenericToggle(nodeUserData, variableValues);

        bindingSourceForRotateToggle =
            <>
                <Grid item xs={6}>
                    <PanelInputLabel props={{ label: "Variable" }} />
                </Grid>
                <Grid item xs={6}>
                    <PanelInputField props={{
                        type: "select", values: allowedVariablesForRotateToggle, value: nodeUserData[UserDataProperties.inputSource1],
                        handleChange: (e: any) => {
                            //console.log(e)
                            handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
                        }
                    }} />
                </Grid>
                {isUserAdmin() && getModelConfigFields()}
            </>
    }

    let bindingSourceForToggleComponent = null;

    if (propertiesPanelUIData.hasToggleComponent) {

        let allowedSystemVariablesAsBindingSourceForToggleComponent = VariableTypeAllowedValues.updateInputSourceForGenericToggle(nodeUserData, variableValues);

        bindingSourceForToggleComponent =
        // <div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'space-evenly'} }>
        //              <PanelInputLabel props={{ label: "Variable" }} />
        //              <PanelInputField props={{
        //                 // label: "Bind to",
        //                 type: "select", values: allowedSystemVariablesAsBindingSourceForToggleComponent, value: nodeUserData[UserDataProperties.inputSource1],
        //                 handleChange: (e: any) => {
        //                     //console.log(e)
        //                     handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
        //                 }
        //             }} />
        // </div>

            <Grid container alignItems="center" alignContent= "center">
                <Grid item xs={6}>
                    <PanelInputLabel props={{ label: "Variable" }} />
                </Grid>
                <Grid item xs={6}>
                    <PanelInputField props={{
                        // label: "Bind to",
                        type: "select", values: allowedSystemVariablesAsBindingSourceForToggleComponent, value: nodeUserData[UserDataProperties.inputSource1],
                        handleChange: (e: any) => {
                            //console.log(e)
                            handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
                        }
                    }} />
                </Grid>
            </Grid>


    }

    let imageComponentElementProperties = null;

    if (isNodeImageComponent) {
        imageComponentElementProperties =
            <>
                <Grid container alignItems="center">
                    <Grid container alignItems="center">
                        {/* <Grid item xs={6}>
                    <PanelInputLabel props={{ label: "Border Radius" }} />
                </Grid>
                <Grid item xs={6}>
                    <PanelInputField props={{ label: "Border Radius", type: "textInput",
                     text: nodeUserData[UserDataProperties.borderRadius],
                      onChange: onTextLabelChanged(UserDataProperties.borderRadius) } } />
                </Grid>
                <Grid item xs={6}>
                    <PanelInputLabel props={{ label: "Border Size" }} />
                </Grid>
                <Grid item xs={6}>
                    <PanelInputField props={{ label: "Border Size", type: "textInput",
                     text: nodeUserData[UserDataProperties.borderSize],
                      onChange: onTextLabelChanged(UserDataProperties.borderSize) } } />
                </Grid> */}
                        {/* <Grid item xs={6}>
                            <PanelInputLabel props={{ label: "Image Link" }} />
                        </Grid>
                        <Grid item xs={6}>
                            <PanelInputField props={{
                                label: "Image Link", type: "textInput",
                                text: nodeUserData[UserDataProperties.textureSource],
                                onChange: onTextLabelChanged(UserDataProperties.textureSource)
                            }} />
                        </Grid> */}

                        {/* // Image Upload  */}
                        <Grid item xs={6}>
                            <PanelInputLabel props={{ label: "Image" }} />
                        </Grid>
                        <Grid item xs={6}>
                            <PanelInputField props={{
                                label: "Image Upload", type: "fileInput",
                                //value: nodeUserData[UserDataProperties.textureSource],
                                onChange: onTextLabelChanged(UserDataProperties.textureSource)
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
    }


    let onClickActionButtonElement = null;
    let clickAnObjectTextPromptElement = null;
    let allActionTypes = Object.values(ActionType);

    if (propertiesPanelMode === PropertiesPanelMode.CanAdd) {
        onClickActionButtonElement =
            <Button variant='contained'
                color='secondary'
                size='small'
                style={{ height: '90%' }}
                type='button'
                onClick={() => Simulation.instance.propertiesPanel.changePropertiesPanelMode(PropertiesPanelMode.Adding)} >Add OnClick Action</Button>
    } else if (propertiesPanelMode === PropertiesPanelMode.Adding || propertiesPanelMode === PropertiesPanelMode.Editing) {
        onClickActionButtonElement =
            <Button variant='contained'
                color='primary'
                size='small'
                style={{ height: '90%' }}
                type='button'
                onClick={() => Simulation.instance.propertiesPanel.changePropertiesPanelMode(PropertiesPanelMode.CanAdd)} >Cancel</Button>

        clickAnObjectTextPromptElement =
            <Typography variant="body1" className={classes.behaviorPrompt}> Click an object in the scene!</Typography>

    }

    let eventActionElements: JSX.Element[] = [];

    if (UserDataProperties.ClickEventActionList in nodeUserData) {
        if (nodeUserData[UserDataProperties.ClickEventActionList]) {
            (nodeUserData[UserDataProperties.ClickEventActionList] as EventActionOutcome[]).map((actionOutcome: EventActionOutcome, index) => {

                let otherNode: any | null = null;
                let otherNameToShow = "<Node deleted from scene>";
                let hasParameter: boolean = false;
                let parameterString = "N"
                if (actionOutcome.objectID.length < 1) {
                    otherNameToShow = "[Select an object!]";
                } else {
                    if (spaceModels.size > 0) {
                        otherNode = spaceModels.get(actionOutcome.objectID);
                    }
                }

                if (otherNode) {
                    otherNameToShow = otherNode[UserDataProperties.userData][UserDataProperties.nameToShow];
                }

                /*
                if(Simulation.instance.propertiesPanel.isActionParameterized(actionOutcome.actionType)) {
                    hasParameter = true;
                    parameterString = actionOutcome.parameter;
                }*/

                let tempElement =
                    <Grid container alignItems="center">
                        <Grid item xs={4}>
                            <PanelInputField props={{
                                label: "Action", type: "select", values: allActionTypes, value: actionOutcome.actionType,
                                handleChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.ActionTypeDropDown, e, index); }
                            }} />
                        </Grid>

                        <Grid item xs={4}>
                            <PanelInputField props={{ label: "Action", type: "readOnlyText", text: otherNameToShow, onFocus: (e: any) => { Simulation.instance.propertiesPanel.editNodeClickActionEvent(index); } }} />
                        </Grid>

                        {
                            hasParameter &&
                            <Grid item xs={2}>
                                <PanelInputField props={{ label: "Parameter", type: "textInput", text: parameterString, onChange: onTextLabelChangedEventAction(actionOutcome, index) }} />
                            </Grid>
                        }

                        {
                            (!(propertiesPanelMode === PropertiesPanelMode.Adding || propertiesPanelMode === PropertiesPanelMode.Editing)) &&
                            <Grid item xs={2}>
                                <Typography variant="body1" className={classes.behaviorSubTitle}> </Typography>
                                <Button variant='text' color='inherit' size='small' onClick={(e) => { handleDeleteClick(PropertiesPanelDropDownType.ActionTypeDropDown, e, index) }}>
                                    <CancelIcon />
                                </Button>
                            </Grid>
                        }
                    </Grid>

                eventActionElements.push(tempElement);

            })
        }
    }

    let onClickTriggerActionButtonElement = null;

    /*
    if(!(UserDataProperties.TriggerActionList in nodeUserData)) {
        nodeUserData[UserDataProperties.TriggerActionList] = [];
    }*/

    onClickTriggerActionButtonElement =
        <Button variant='contained'
            color='secondary'
            size='small'
            style={{ height: '90%' }}
            type='button'
            onClick={() => Simulation.instance.propertiesPanel.addTriggerActionOutcome()} >Add Trigger Action</Button>

    let triggerActionElements: JSX.Element[] = [];

    if (UserDataProperties.TriggerActionList in nodeUserData) {

        if (nodeUserData[UserDataProperties.TriggerActionList]) {
            (nodeUserData[UserDataProperties.TriggerActionList] as TriggerActionOutcome[]).map((triggerActionOutcome: TriggerActionOutcome, index) => {

                let hasParameter: boolean = false;
                let parameterString = "N";

                /*
                if(Simulation.instance.propertiesPanel.isActionParameterized(triggerActionOutcome.actionType)) {
                    hasParameter = true;
                    parameterString = triggerActionOutcome.parameter;
                }*/

                let tempElement =
                    <Grid container alignItems="center">
                        <Grid item xs={5}>
                            <PanelInputField props={{
                                label: "Action", type: "select", values: allActionTypes, value: triggerActionOutcome.actionType,
                                handleChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.TriggerActionTypeDropDown, e, index); }
                            }} />
                        </Grid>

                        {
                            hasParameter &&
                            <Grid item xs={4}>
                                <PanelInputField props={{ label: "Parameter", type: "textInput", text: parameterString, onChange: onTextLabelChangedTriggerAction(triggerActionOutcome, index) }} />
                            </Grid>
                        }

                        <Grid item xs={2}>
                            <Typography variant="body1" className={classes.behaviorSubTitle}> </Typography>
                            <Button variant='text' color='inherit' size='small' onClick={(e) => { handleDeleteClick(PropertiesPanelDropDownType.TriggerActionTypeDropDown, e, index) }}>
                                <CancelIcon />
                            </Button>
                        </Grid>
                    </Grid>

                triggerActionElements.push(tempElement);

            })
        }
    }

    let varTriggerButton = <Button
        variant='contained'
        color='secondary'
        size='small'
        style={{ height: '90%' }}
        type='button'
        onClick={() => {
            let defaultVarName = '';
            let defaultValue = '';

            if (variableValues.length > 0) {
                defaultVarName = variableValues[0].name;
                let allowedValues = Simulation.instance.getVariableDefaultValues(defaultVarName);
                if (allowedValues.length > 0) {
                    defaultValue = allowedValues[0];
                }
            }

            if (defaultVarName.length > 0
                // && defaultValue.length
            ) {
                varTriggerList.push({ logic: VariableLogicType.and, name: defaultVarName, value: defaultValue });
                Simulation.instance.propertiesPanel.updateVarList(varTriggerList, UserDataProperties.varTriggers);
            }
        }}
    >
        Variable Triggers
    </Button>
    let eventVarTriggerElementsVars: JSX.Element[] = [];
    let vTriggers: any[] = [];

    if (nodeUserData[UserDataProperties.varTriggers]) {
        (nodeUserData[UserDataProperties.varTriggers] as VariableValueTriggerPair[]).map((varTrigger, index) => {
            let variableValuesMapOutput = variableValues.map((v: any) => v.name);
            vTriggers.push({ id: varTrigger.name + varTrigger.value + index, variable: varTrigger.name, value: varTrigger.value });

            if (!variableValuesMapOutput) {
                variableValuesMapOutput = [];
            }

            let varValuesFind = variableValues.find(v => v.name === varTrigger.name);

            if (varValuesFind) {
                varValuesFind = varValuesFind.values?.split(",").map((v: any) => v.trim());
            } else {
                varValuesFind = ''
            }

            //let varLogicFind = variableValues.find(v => v.name === varTrigger.name);
            //console.log(varTrigger.logic);

            if (!varTrigger.logic) {
                varTrigger.logic = VariableLogicType.and;
            } else {
                if (varTrigger.logic as VariableLogicType === VariableLogicType.blank) {
                    varTrigger.logic = VariableLogicType.blank;
                }
            }

            var variableLogicTypesArray = Object.values(VariableLogicType);//Utils.enumToDescriptedArray(VariableLogicType).map((vlt:any) => vlt.id);
            variableLogicTypesArray.shift();
            //if(varTrigger.)

            let tempElement =
                <Grid container alignItems="center">
                    <Grid item xs={5}>
                        <PanelInputField props={{
                            label: "Variable", type: "select", values: variableValuesMapOutput, value: varTrigger.name,
                            handleChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesTriggerDropDown, e, index); }
                        }} />
                    </Grid>
                    <Grid item xs={5}>
                        <PanelInputField props={{
                            label: "Value", type: "select", values: varValuesFind, value: varTrigger.value,
                            handleChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesTriggerDropDown, e, index, 2); }
                        }} />
                    </Grid>

                    {/*}
                    <Grid item xs={2}>
                        <PanelInputField props={{
                            label: "Logic", type: "select", values: variableLogicTypesArray, value: varTrigger.logic,
                            handleChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesTriggerDropDown, e, index, 3); }
                        }} />
                    </Grid>*/}
                    <Grid item xs={1}>
                        <Typography variant="body1" className={classes.behaviorSubTitle}> </Typography>
                        <Button variant='text' color='inherit' size='small' onClick={(e) => { handleDeleteClick(PropertiesPanelDropDownType.SystemVariablesTriggerDropDown, e, index) }}>
                            <CancelIcon />
                        </Button>
                    </Grid>
                </Grid>

            eventVarTriggerElementsVars.push(tempElement);

        })
    }
    //var actions
    let varActionButton = <Button
        variant='contained'
        color='secondary'
        size='small'
        style={{ height: '90%' }}
        type='button'
        onClick={() => {
            let defaultVarName = '';
            let defaultValue = '';

            if (variableValues.length > 0) {
                defaultVarName = variableValues[0].name;
                let allowedValues = Simulation.instance.getVariableDefaultValues(defaultVarName);
                if (allowedValues.length > 0) {
                    defaultValue = allowedValues[0];
                }
            }

            if (defaultVarName.length > 0 && defaultValue.length) {
                varActionList.push({ logic: VariableLogicType.and, name: defaultVarName, value: defaultValue });
                Simulation.instance.propertiesPanel.updateVarList(varActionList, UserDataProperties.varActions);
            }
        }}
    >
        Variable Actions
    </Button>
    let eventVarActionElementsVars: JSX.Element[] = [];

    if (nodeUserData[UserDataProperties.varActions]) {
        (nodeUserData[UserDataProperties.varActions] as VariableValueActionPair[]).map((varAction, index) => {
            let variableValuesMapOutput = variableValues.map(v => v.name);

            if (!variableValuesMapOutput) {
                variableValuesMapOutput = [];
            }

            let varValuesFind = variableValues.find(v => v.name === varAction.name);

            if (varValuesFind) {
                varValuesFind = varValuesFind.values?.split(",").map((v: any) => v.trim());
            } else {
                varValuesFind = '';
            }

            let tempElement =
                <Grid container alignItems="center">
                    <Grid item xs={5}>
                        <PanelInputField props={{
                            label: "Variable", type: "select", values: variableValuesMapOutput, value: varAction.name,
                            handleChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesActionDropDown, e, index); }
                        }} />
                    </Grid>
                    <Grid item xs={5}>
                        <PanelInputField props={{
                            label: "Value", type: "select", values: varValuesFind, value: varAction.value,
                            handleChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesActionDropDown, e, index, 2); }
                        }} />
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" className={classes.behaviorSubTitle}> </Typography>
                        <Button variant='text' color='inherit' size='small' onClick={(e) => { handleDeleteClick(PropertiesPanelDropDownType.SystemVariablesActionDropDown, e, index) }}>
                            <CancelIcon />
                        </Button>
                    </Grid>
                </Grid>

            eventVarActionElementsVars.push(tempElement);

        })
    }

    let showSizeOption: boolean = false;
    if (UserDataGizmoMinorMods.disableScale in nodeUserData) {
        if (nodeUserData[UserDataGizmoMinorMods.disableScale]) {
            showSizeOption = true;
        }
    }

    //setTimeout(_setPropertiesColorValueInternal, 1000);

    const [openLogicTreesDrawer, setOpenLogicTreesDrawer] = useState<boolean>(false);

    return (console.log("rendering PropertiesPanel"),

        <Box className={classes.propPanelBox}>
            {
                (propertiesPanelMode !== PropertiesPanelMode.None) &&
                <Grid>
                    <Box paddingTop={2} />
                    <Typography className={'h7'} >Object Properties</Typography>
                    <Divider orientation="horizontal" variant="middle" />
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <PanelInputLabel props={{ label: "Name" }} />
                        </Grid>

                        <Grid item xs={6}>
                            <PanelInputField props={{ label: "Name", text: nodeUserData[UserDataProperties.nameToShow], type: "textInput", onChange: onTextLabelChanged(UserDataProperties.nameToShow) }} />
                        </Grid>

                        <Grid item xs={6}>
                            {
                                showColorProperty &&
                                <PanelInputLabel props={{ label: "Color" }} />
                            }
                        </Grid>

                        <Grid item xs={6}>
                            <PanelInputField props={{ label: "Color", type: "color", color: nodeUserData[UserDataProperties.customColorProperty], colorReference: colorReference, onColorChanged: onColorChanged, showColorProperty: showColorProperty }} />
                        </Grid>

                        <Grid item xs={6}>
                            {
                                showBorderColorProperty &&
                                <PanelInputLabel props={{ label: borderColorLabelText }} />
                            }
                        </Grid>



                        <Grid item xs={6}>
                            <PanelInputField props={{ label: "Border Color", type: "color", color: nodeUserData[UserDataProperties.borderColorProperty], colorReference: borderColorReference, onColorChanged: onBorderColorChanged, showColorProperty: showBorderColorProperty }} />
                        </Grid>


                        {/*inputSourceForNest*/}

                        {bindingSourceForRotateToggle}
                        {bindingSourceForToggleComponent}
                        {imageComponentElementProperties}

                        <Tooltip title={`Applies to ${spacePrimaryUseCase?.layerTermPlural}. Checking this will make this object appear in ALL the steps`}>
                            <Grid item xs={6}>

                                <PanelInputLabel props={{ label: "Always Show" }} />

                            </Grid>
                        </Tooltip>
                        <Grid item xs={6}>
                            <PanelInputField props={{
                                // label: "Always Show",
                                value: nodeUserData[UserDataProperties.alwaysShow], type: "checkbox", onChange: onCheckboxPropertiesChange(UserDataProperties.alwaysShow)
                            }} />
                        </Grid>

                        {
                            (UserDataProperties.textProperty in nodeUserData) &&
                            <Grid item xs={6}>
                                <PanelInputLabel props={{ label: "Font Size" }} />
                            </Grid>
                        }

                        {
                            (UserDataProperties.textProperty in nodeUserData) &&
                            <Grid item xs={6}>
                                <PanelInputField props={{ label: "Font Size", type: "textInput", text: nodeUserData[UserDataProperties.fontSize], onChange: onTextLabelChanged(UserDataProperties.fontSize) }} />
                            </Grid>
                        }

                        {
                            (UserDataProperties.textProperty in nodeUserData) &&
                            <Grid item xs={3}>
                                <PanelInputLabel props={{ label: "Text" }} />
                            </Grid>
                        }

                        {
                            (UserDataProperties.textProperty in nodeUserData) &&
                            <Grid item xs={12}>
                                <PanelInputField props={{ label: "Text", type: "textInputMulti", text: nodeUserData[UserDataProperties.textProperty], onChange: onTextLabelChanged(UserDataProperties.textProperty) }} />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <Divider />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <Button
                                variant='contained'
                                color='secondary'
                                size='small'
                                style={{ height: '90%' }}
                                type='button'
                                onClick={() => setOpenLogicTreesDrawer(true)}
                            >
                                If-then blocks
                            </Button>
                            <LogicTrees
                                nodeUserData={nodeUserData}
                                openLogicTreesDrawer={openLogicTreesDrawer}
                                onCloseDrawer={() => setOpenLogicTreesDrawer(false)}
                                />
                        </Grid> */}

                        {/*
                        <Grid container alignItems="center">
                            {onClickActionButtonElement}
                            {clickAnObjectTextPromptElement}
                        </Grid>
                        <Grid item xs={12}>

                            <div style={{ backgroundColor: "#e8e6e6" }}>

                                {eventActionElements}

                            </div>
                        </Grid>


                        <Grid container alignItems="center">
                            {onClickTriggerActionButtonElement}
                        </Grid>

                        <Grid item xs={12}>

                            <div style={{ backgroundColor: "#e8e6e6" }}>

                                {triggerActionElements}

                            </div>
                        </Grid>

                        <Grid container alignItems="center">
                            {varTriggerButton}
                        </Grid>
                        <Grid item xs={12}>

                            <div style={{ backgroundColor: "#e8e6e6" }}>

                                {eventVarTriggerElementsVars}

                            </div>
                        </Grid>

                        <Grid container alignItems="center">
                            {varActionButton}
                        </Grid>


                        <Grid item xs={12}>

                            <div style={{ backgroundColor: "#e8e6e6" }}>

                                {eventVarActionElementsVars}

                            </div>
                        </Grid>*/}

                        {
                            showSizeOption &&
                            <Grid item xs={12}>
                                <Grid item xs={6}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={6}>
                                            <PanelInputLabel props={{ label: "Size" }} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <PanelInputField props={{ label: "Size", text: propertiesPanelUIData.nodePointer?.scale.x, type: "textInput", onChange: onTextLabelNodeDataChanged(NodeDataProperties.scale) }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>


                    {/* <Divider /> */}


                </Grid>
            }
        </Box >
    );
};

export default PropertiesPanel;
